import React, { FC, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IconNames } from '@blueprintjs/icons';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { Button } from '../Shared';
import { DateFormatEnum, customDateFormat } from 'helpers/date-format';
import { Database, Intent } from '../../types'
import { showToaster } from '../../actions';
import { deleteDatabase, setDatabase } from 'store/database/actions';
import { TooltipCell } from 'components/Shared/ReactTable/TooltipCell';
import { createColumnHelper } from '@tanstack/react-table';
import { sortTimestamp } from 'utils/fieldSortFns';

export interface DatabasesTableProps {
    databases: object[];
    canEdit: boolean;
    setCurrentEditDb: (dbName: string, dbDexc: string) => void;
}

const DatabasesTable: FC<DatabasesTableProps> = ({ databases, canEdit, setCurrentEditDb }) => {
    const columnHelper = createColumnHelper<Database>();
    const dispatch = useDispatch();
    const deleteDb = (dbName: string) => {
        const msg = `Delete Database ${dbName}?`;
        if (confirm(msg)) {
            dispatch(deleteDatabase(dbName));
        }
    };

    const RowActions = ({ row }) => {
        console.log('row', row);
        return (
            <div>
                <Button
                    className='delete-button'
                    icon={IconNames.TRASH}
                    title={`Delete ${row.original.database}`}
                    minimal={true}
                    onClick={() => deleteDb(row.original.database)}
                />

                <Button
                    className='delete-button'
                    icon={IconNames.EDIT}
                    title={`Edit ${row.original.database}`}
                    minimal={true}
                    onClick={() => { setCurrentEditDb(row.original.database, row.original.description) }}
                />
            </div>
        );
    }
       

    const getColumns = () => {
        const columns = [
            columnHelper.accessor('database', {
                header: 'Database',
                cell: ({ cell }) => <TooltipCell value={
                    <a
                        href="#"
                        className="lnkSetDatabase"
                        onClick={() => {
                            dispatch(setDatabase(cell.getValue()));
                            showToaster(Intent.INFO, `Database changed to: ${cell.getValue()}.`);
                        }}
                    >
                        {cell.getValue()}
                    </a>
                } />,
                sortingFn: (a, b) => {
                    return a.getValue<string>('database').localeCompare(b.getValue<string>('database'));
                }
            }),
            columnHelper.accessor('description', {
                header: 'Description',
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />,
            }),
            columnHelper.accessor('create_user', {
                header: 'Create User',
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />,
            }),
            columnHelper.accessor('create_timestamp', {
                header: 'Created',
                cell: ({ cell }) => <TooltipCell value={customDateFormat(cell.getValue(), DateFormatEnum.timestampDisplay, DateFormatEnum.timestamp)} />,
                // sortingFn: sortTimestamp,
                sortingFn: (a, b) => {
                    // not sure why the original timestamp compare did not work
                    if(a === null || a === undefined)
                        return (b === null || b === undefined) ? 0 : -1; // null is smaller
                    if(b === null || b === undefined)
                        return 1; // null is smaller
                    return a.getValue<string>('create_timestamp').localeCompare(b.getValue<string>('create_timestamp'))
                },
            }),
           
        ];

        if (canEdit) {
            columns.push(
                columnHelper.display({
                    header: 'Actions',
                    cell: ({ row }) => <RowActions row={row} />,
                    // tdClassNames: 'p-0',
                    size: 80,
                })
            );
        }

        return columns;
    }

    const columns = useMemo(() => getColumns(), [databases]);

    return (
        <ReactTable
            resultCount={databases.length}
            columns={columns}
            data={databases}
            defaultSorted={[{ id: "dbname", desc: false }]}
            className="-highlight"
        />
    );
};


export default DatabasesTable;
