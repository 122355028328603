import * as React from 'react';
import { connect } from 'react-redux';

import { mdmErrorToText } from "../../../helpers";
import { MDMWrapper, Button, ConfirmDialog } from '../../../components/Shared';
import { WorkflowApi } from '../../../api';
import { Intent, Process, ProcessDef } from '../../../types';
import { ProcessDetail } from './ProcessDetail';
import { showToaster } from '../../../actions';
import { ProcessEditModal } from './ProcessEditModal'

import './ProcessEditor.scss';

export interface ProcessEditorProps {
    dbName: string;
    showToaster: showToaster;
}

export interface ProcessEditorState {
    processes: Process[];
    currentDeleteProcess: Process;
    currentProcess: Process;
}

export class ProcessEditorBase extends React.Component<ProcessEditorProps, ProcessEditorState> {
    constructor(props) {
        super(props);

        this.state = {
            processes: [],
            currentDeleteProcess: null,
            currentProcess: null
        }
    }

    private refreshData() {
        const { dbName } = this.props;
        this.setState({ currentProcess: null }, () => {
            WorkflowApi.queryProcesses(dbName).then(processes => this.setState({ processes }));
        });
    }

    componentDidMount() {
        const { dbName } = this.props;
        WorkflowApi.queryProcesses(dbName).then(processes => this.setState({ processes }));
    }

    componentDidUpdate(oldProps) {
        if (oldProps.dbName !== this.props.dbName) {
            this.refreshData();
        }
    }

    deleteProcess = () => {
        const { description, process_id } = this.state.currentDeleteProcess;
        WorkflowApi.processDelete(process_id).then(response => {
            // remove the process from the state
            const newProcessList = this.state.processes.filter((p) => p.process_id != process_id);
            this.setState({ currentDeleteProcess: null, processes: newProcessList });
            this.props.showToaster(Intent.SUCCESS, `Removed process "${description}" and its associated workitems and workflows`);
        })
            .catch(err => {
                this.setState({ currentDeleteProcess: null });
                // error returns are complex and varied
                const message = mdmErrorToText(err);
                console.error(message);
                this.props.showToaster(Intent.DANGER, message);
            });

    }

    deleteProcessConfirm = () => {
        const { currentDeleteProcess } = this.state;
        return currentDeleteProcess &&
            <ConfirmDialog
                buttonName="Delete"
                body={`Are you sure you want to delete Process: ${currentDeleteProcess.description}?`}
                title={`Delete Process ${currentDeleteProcess.description}`}
                cancelClick={() => this.setState({ currentDeleteProcess: null })}
                okClick={() => this.deleteProcess()}
            />;
    }

    generateProcessItem = (process: Process) => {
        if (process) {
            return <ProcessDetail
                key={process.process_id}
                process={process}
                deleteProcess={() => this.setState({ currentDeleteProcess: process })}
                editProcess={() => this.setState({ currentProcess: process })}
            />
        }
        return null;
    }

    editProcess = (processDetails: ProcessDef) => {
        WorkflowApi.processModify(processDetails).then(() => {
            this.props.showToaster(Intent.SUCCESS, `Process #${processDetails.process_id} was edited successfully!`);
            this.refreshData();
        }).catch(e => {
            console.error(e);
            this.props.showToaster(Intent.DANGER, "Error editing Process, please check your browser's console.");
        })
    }

    createProcess = (processDetails: ProcessDef) => {
        WorkflowApi.processCreate(processDetails).then((result: Process) => {
            this.props.showToaster(Intent.SUCCESS, `Process #${result.process_id} was created successfully!`);
            this.refreshData();
        }).catch(e => {
            console.error(e);
            this.props.showToaster(Intent.DANGER, `Error ${e.error_code}: ${e.messages[0]}`);
        })
    }

    generateModal = () => {
        const { dbName } = this.props;
        const { currentProcess } = this.state;

        if (!currentProcess)
            return null;

        const isEdit = !!currentProcess.process_id;
        const saveAction = isEdit ? this.editProcess : this.createProcess

        return (
            <ProcessEditModal
                process={currentProcess}
                dbName={dbName}
                closeModal={() => this.setState({ currentProcess: null })}
                onSave={saveAction}
                isEdit={isEdit}
            />
        )
    }

    render() {
        const { dbName } = this.props;
        const createButton = (
            <Button
                className="minimal-button"
                id="btnCreateProcess"
                key={0}
                value="Create Process"
                onClick={() => {
                    const newProcess: Process = {
                        process_id: 0,
                        database: dbName,
                        description: "New Process"
                    }
                    this.setState({ currentProcess: newProcess })
                }} />
        )
        return <>
            {this.deleteProcessConfirm()}
            <MDMWrapper buttons={[createButton]} title={`Processes for ${dbName}`} documentationPath="advanced_workflow.html">
                {this.generateModal()}
                {this.state.processes.map((process) => this.generateProcessItem(process))}
            </MDMWrapper>
        </>
    }
}

export const ProcessEditor = connect(
    (state: any) => ({
        dbName: state.database.selected,
        username: state.username,
    }),
    {
        showToaster
    }
)(ProcessEditorBase as any);
