import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { Button, Card, Icon, Radio, RadioGroup, Spinner } from '@blueprintjs/core';

import { DatabaseApi } from 'api/DatabaseApi';
import { Intent } from 'types';
import { logout } from 'actions';
import { queryDatabases } from 'store/database/actions';
import { showToaster } from 'actions/ActionCreators';
import { UserApi, displayError, inSystemGroup } from 'api';
import { setLicenseInfo } from 'store/license/actions';
import './LicenseForm.scss'

interface LicenseFormProps {
    isPage?: boolean;
}

export const LicenseForm: React.FC<LicenseFormProps> = ({ isPage }) => {
    const dispatch = useDispatch();

    const [saving, setSaving] = useState(false);
    const [loadingDefaults, setLoadingDefaults] = useState(true);

    const [activationKey, setActivationKey] = useState("");
    const [publicKey, setPublicKey] = useState("");
    const [licenseUrl, setLicenseUrl] = useState("");
    const [productId, setProductId] = useState("4");

    useEffect(() => {
        let isMounted = true;
        const fetchDefaults = async () => {

            const defaultUrl = await UserApi.getUserState('qlmurl', "system");
            const defaultPublicKey = await UserApi.getUserState('qlmpublickey', "system");
            const defaultProductId = await UserApi.getUserState('product_id', "system");

            if (isMounted) {
                setPublicKey(defaultPublicKey);
                setLicenseUrl(defaultUrl);
                setProductId(defaultProductId);
                setLoadingDefaults(false);
            }
        }

        if (inSystemGroup()) {
            fetchDefaults();
        } else if (isMounted) {
            setLoadingDefaults(false);
        }

        return () => { isMounted = false; }; // clean-up function
    }, []);

    const setValuesInUserState = async () => {
        setSaving(true);

        const sanitizedPublicKey = publicKey.replace(/\s/g, "");

        try {
            await UserApi.setUserState('qlmurl', licenseUrl, null, 'system');
            await UserApi.setUserState('qlmpublickey', sanitizedPublicKey, null, 'system');
            await UserApi.setUserState('product_id', productId, null, 'system');
            await UserApi.setUserState('qlmactivationkey', activationKey, null, 'system');

            // Check if the newly set credentials are valid!
            const newLicenseInfo = await DatabaseApi.getLicenseInfo(true);

            if (newLicenseInfo.licensed) {
                // Set databases, we cant get databases without being licensed and the app needs those.
                dispatch(queryDatabases());

                dispatch(showToaster(Intent.SUCCESS, `License successful. ${newLicenseInfo.message}`));
                dispatch(setLicenseInfo(newLicenseInfo));

                if (isPage) {
                    setSaving(false);
                }
            } else {
                setSaving(false); // added this one
                displayError('License failed', newLicenseInfo.message, {});
            }
        } catch (e) {
            console.error(`Error when saving your license information: ${e}`)
            setSaving(false);
            displayError('Licensing Error', "Error when saving your license information", e);
        }
    }

    if (loadingDefaults || saving) {
        return (
            <Card className="license-card">
                <div className="fill flex-center">
                    <Spinner />
                </div>
            </Card>
        )
    }

    return (
        <Card className="license-card">
            {
                !inSystemGroup() &&
                <div className="flex-column">
                    <p className="text-small text-heavy">
                        Your MDM service does not have a valid license. Please let your system administrator know.
                    </p>
                    <Button onClick={() => dispatch(logout())}>Logout</Button>
                </div>
            }
            {
                inSystemGroup() &&
                <div className="flex-column license-form">
                    <h5 className="card-title">Update your MDM License</h5>
                    {
                        isPage ?
                            <p className="text-muted text-small">
                                Saving this form will replace the existing license information. The previous data will be lost.
                            </p>
                            :
                            <p className="text-muted text-small">
                                Your MDM service does not have a valid license. Please enter your license information to proceed.
                                If you have any questions, please contact <a href="https://www.redpointglobal.com/support/" target="mdm_support">Redpoint Global Support</a>.
                            </p>
                    }
                    <div className="bp5-form-group .modifier">
                        <label className="bp5-label" htmlFor="activation-key-input">
                            Activation Key
                        </label>
                        <div className="bp5-form-content">
                            <div className="bp5-input-group .modifier">
                                <Icon icon="key" />
                                <input id="activation-key-input" type="text" className="bp5-input"
                                    dir="auto" value={activationKey} onChange={e => setActivationKey(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="bp5-form-group .modifier">
                        <label className="bp5-label" htmlFor="license-url-input">
                            License Url
                        </label>
                        <div className="bp5-form-content">
                            <div className="bp5-input-group .modifier">
                                <Icon icon="link" />
                                <input id="license-url-input" type="text" className="bp5-input"
                                    dir="auto" value={licenseUrl} onChange={e => setLicenseUrl(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="bp5-form-group .modifier">
                        <label className="bp5-label" htmlFor="public-key-input">
                            Public Key
                        </label>
                        <div className="bp5-form-content">
                            <div className="bp5-input-group .modifier">
                                <textarea id="public-key-input" type="text" className="public-key-input bp5-input"
                                    dir="auto" value={publicKey} onChange={e => setPublicKey(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <RadioGroup
                        label="Product ID"
                        onChange={e => setProductId((e.target as HTMLInputElement).value)}
                        selectedValue={productId}
                    >
                        <Radio label="Production" value="4" />
                        <Radio label="Trial" value="2" />
                    </RadioGroup>

                    <Button disabled={!licenseUrl || !activationKey || !productId} intent="none" onClick={() => setValuesInUserState()}>
                        Submit
                    </Button>
                </div>
            }
        </Card >
    );
};
