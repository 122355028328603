import { AuthApi } from '../api/AuthApi';
import { logout } from '../actions/login';
import { showToaster } from '../actions';
import { Intent } from '../types';
import { MDMStore as store } from '../store/MDMStore';

// export const userIsAuthenticated = connectedRouterRedirect({
//   redirectPath: '/',
//   authenticatedSelector: state => state.login.data !== null,
//   authenticatingSelector: state => state.login.isLoading,
//   AuthenticatingComponent: Loading,
//   redirectAction: routerActions.replace,
//   wrapperDisplayName: 'UserIsAuthenticated'
// });

// export const userIsAuthenticated = (component) => {
//   return component;
// };

export const authenticate = (): void => {
  const storeState = store.getState() as any;
  if (storeState.login.data) {
    const user = storeState.login.data.model.user;
    // resolved means the user is authenticated, only act on error or reject
    AuthApi.verifyToken(user)
      .catch((err) => {
        store.dispatch<any>(showToaster(Intent.DANGER, 'Error when validating your session'));
        store.dispatch<any>(logout());
      });
  } else
    store.dispatch<any>(logout());
};
export const isLoggedIn = () => {
  const storeState = store.getState() as any;
  return !!storeState.login.data;
};
// export const userIsNotAuthenticated = (component) => {
//   const isBaseLocation = !window.location.pathname.includes('login');
//   const isNotLoggedIn = isBaseLocation && store.getState().login.data == null;
//   if (isNotLoggedIn) {
//     window.location.replace('/mdm/login');
//   }
//   return component;
// };


// export const userIsNotAuthenticated = connectedRouterRedirect({
//   redirectPath: (state, ownProps) => {
//     if ((window.location.pathname.match(/login/g) == null && state.login.data !== null)) window.location.replace('/mdm/login');
//     return state;
//   },
//   allowRedirectBack: false,
//   // Want to redirect the user when they are done loading and authenticated
//   authenticatedSelector: state => state.login.data === null && state.login.isLoading === false,
//   redirectAction: routerActions.replace,
//   wrapperDisplayName: 'UserIsNotAuthenticated'
// });