/**
 * Created by nbamford on 1/29/2017.
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from '@blueprintjs/core';

import { Button } from '../../Shared';
import { fetchStartableWorkflows, startWorkflow } from 'store/workflow/actions';
import { MDMWrapper } from 'components/Shared';
import { MDMDataTable, MDMTableColumn } from 'components/Shared';
import { IconNames } from '@blueprintjs/icons';

import { Workflow } from 'types';

export interface WorkflowStartableContainerProps {
    dbName: string;
    startable: Workflow[];
    fetchStartableWorkflows: (dbName: string) => void;
    startWorkflow: (button:HTMLButtonElement, dbName: string, workflowId: number) => void;
}

class WorkflowStartableContainerBase extends React.PureComponent<WorkflowStartableContainerProps> {
    header: JSX.Element;
    columns: MDMTableColumn[];

    constructor(props) {
        super(props);
        this.header = <h4>Select a workflow to start</h4>;
        this.columns = [
            { title: "Description", field: "description" },
            { title: "Start", field: "start", type: "action", actionBuilder: this.actionBuilder },
        ];
    }

    actionBuilder = (data) => {
        const { startWorkflow, dbName } = this.props;
        return (
            <Tooltip key={data.workitem_id} content="Start workflow">
                <Button
                    onClick={(event) => startWorkflow(event.currentTarget, dbName, data.workflow_id)}
                    icon={IconNames.PLUS} className="toolbar-narrow" />
            </Tooltip>
        );
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { dbName } = this.props
        if (dbName !== prevProps.dbName) {
            this.loadData()
        }
    }

    loadData = () => {
        const { dbName } = this.props;
        this.props.fetchStartableWorkflows(dbName);
    }

    render() {
        return (
            <div>
                <MDMWrapper title="Select a Workflow to Start" documentationPath="workflow_console.html">
                    <MDMDataTable columns={this.columns} data={this.props.startable} />
                </MDMWrapper>
            </div>
        );
    }
}

export const WorkflowStartableContainer = connect(
    (state: any) => ({ startable: state.workflow.startable, dbName: state.database.selected }), {
    fetchStartableWorkflows, startWorkflow
})(WorkflowStartableContainerBase as any);
