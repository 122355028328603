import * as React from 'react';
import { connect } from 'react-redux';
import { match, Redirect } from 'react-router-dom';
import { Card, Checkbox, Classes, Divider, FileInput, FormGroup, Button, H3, Position } from '@blueprintjs/core';

import { MDMWrapper, ConfirmDialog } from '../Shared';
import { inRoles, UserApi } from '../../api';
import { AuthorizedRoles } from '../../constants';
import { showToaster } from '../../actions/ActionCreators'
import { InfoTooltip } from '../../components/Shared/InfoTooltip';
import { setUserSettings } from '../../store/user/actions'
import { UserSettings as Settings } from 'store/user/types';
import { defaultUserSettings } from 'store/user/constants';
import { USER_SETTING_KEY } from 'constants/settings';
import { setDashboardLayout } from 'store/user/actions';
import { defaultDashboardLayout } from 'store/user/defaultDashboardLayout';
import { Intent, User } from 'types';
import { AppThunk } from 'store';
import { Layouts } from 'react-grid-layout';
import './UserSettings.scss';
import { Tooltip2 } from '@blueprintjs/popover2';

export interface UserSettingsProps {
    setUserSettings: (settings: { [key: string]: any }) => AppThunk;
    setDashboardLayout: (layouts: Layouts) => void;
    showToaster?: showToaster;
    loggedUsername: string;
    match: match;
}

export interface UserSettingsState {
    settings: Settings;
    displayName: string;
}

export class UserSettingsBase extends React.Component<UserSettingsProps, UserSettingsState> {
    constructor(props) {
        super(props);

        const { match: { params } } = props;
        const username = (params as any).username;

        this.state = {
            settings: null,
            displayName: username
        }
    }

    componentDidMount() {
        const { match: { params }, loggedUsername } = this.props;
        const username = (params as any).username;

        if (username !== loggedUsername && !inRoles(AuthorizedRoles.management)) {
            return <Redirect to="/" />
        }

        UserApi.getUserState(USER_SETTING_KEY, username)
            .then((fetchedVal: string) => {
                if (fetchedVal) {
                    try {
                        const settings = JSON.parse(fetchedVal);
                        this.setState({ settings });
                    } catch (e) {
                        console.error("Using default settings, got error: ", e);
                        this.setState({ settings: defaultUserSettings })
                    }
                } else {
                    this.setState({ settings: defaultUserSettings })
                }
            })
            .catch(err => console.error(err || "Couldn't get user settings."))

        UserApi.get(username).then((users: User[]) => {
            if (users && users.length) {
                this.setState({ displayName: users[0].full_name });
            }
        }).catch(err => console.error(err || "Couldnt get user data."))
    }


    generateInputLabel = (label: string, tooltipText?: string) => {
        return (
            <>
                <span>{label}</span>
                {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
            </>
        )
    }

    save = async () => {
        const { match: { params }, showToaster, loggedUsername, setUserSettings } = this.props;
        const username = (params as any).username;
        const { settings } = this.state;

        try {
            const stringSettings = JSON.stringify(settings);
            UserApi.setUserState(USER_SETTING_KEY, stringSettings, null, username)
                .then(res => {
                    console.log(res)
                    showToaster(Intent.SUCCESS, `Settings saved`);

                    // update our own settings locally as well.
                    if (username === loggedUsername) {
                        setUserSettings(settings);
                    }

                }).catch(e => {
                    console.error(e);
                    showToaster(Intent.ERROR, `Something went wrong, please try again in a few minutes`);
                })
        } catch (e) {
            console.error(e);
            showToaster(Intent.ERROR, `Error in your settings structure`);
        }
    }

    saveLayout = (layout: string, action: string) => {
        const { setDashboardLayout, showToaster } = this.props;

        UserApi.setUserState('dashboard_layout', layout)
            .then((success) => {
                if (success) {
                    showToaster(Intent.SUCCESS, `Success ${action} dashboard layout.`);
                    setDashboardLayout(defaultDashboardLayout);
                }
            }).catch(err => {
                console.error(err)
            })
    }

    downloadXml = () => {
        UserApi.getUserState('dashboard_layout')
            .then((fetchedLayout: string) => {
                const file = new Blob([fetchedLayout || JSON.stringify(defaultDashboardLayout)], { type: 'text/plain;charset=utf-8' });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(file);
                link.download = 'dashboard_layout.xml';
                link.target = '_blank';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                showToaster(Intent.SUCCESS, `Dashboard layout saved.`);
            })
            .catch((e) => {
                console.error(e);
                showToaster(Intent.ERROR, `Error reading your dashboard layout`);
            });
    }

    handleFileUpload = (file) => {
        let fileReader = new FileReader();

        fileReader.onloadend = (event) => {
            let buffer = event.target.result;
            this.saveLayout(buffer.toString(), 'loading');
        };

        fileReader.readAsText(file);
    }


    render() {
        const { settings, displayName } = this.state;
        const { match: { params }, setDashboardLayout } = this.props;

        if (!settings) {
            return <span>Loading...</span>
        }

        return (
            <MDMWrapper title={"User settings"} documentationPath="settings.html?q=setting">
                <Card className="user-settings-card">
                    <div className="flex-column" id="user-settings-detail-card">
                        <div className="text-medium">Workitem Settings</div>
                        <Divider className="user-settings-detail-divider" />
                    </div>

                    <div className="flex-column workflow-detail-form">
                        <FormGroup
                            label={
                                this.generateInputLabel(
                                    "Start my next task when I finish the current one",
                                    "If checked you will automatically start the next assigned task when you finish the current one."
                                )
                            }
                            inline={true}
                            className={`${Classes.TEXT_MUTED} mdm-user-settings-form-group`}
                            contentClassName="user-settings-input"
                        >
                            <Checkbox
                                large
                                checked={settings.claimNextAssigned}
                                className="user-settings-checkbox"
                                onChange={e => this.setState({ settings: { ...settings, claimNextAssigned: (e.target as any).checked } }, () => this.save())}
                            />
                        </FormGroup>

                    </div>
                    <div className="text-medium">Dashboard Layout</div>
                    <Divider className="user-settings-detail-divider" />
                    <div className="user-settings-dashboard">
                        <Tooltip2 hoverOpenDelay={500} content="Import from a dashboard save file...">
                            <FileInput
                                buttonText="Import"
                                text="Import from a dashboard save file..."
                                inputProps={{ accept: ".xml,.bpmn" }}
                                onInputChange={(e: any) => this.handleFileUpload(e.target.files[0])}
                            />
                        </Tooltip2>
                        <Tooltip2 hoverOpenDelay={500} content="Export current dashboard layout">
                            <Button icon="download" onClick={() => this.downloadXml()}>Export</Button>
                        </Tooltip2>
                        <Tooltip2 hoverOpenDelay={500} content="Reset to default dashboard layout" position={Position.BOTTOM}>
                            <Button
                                className="user-reset-btn"
                                onClick={ () => this.saveLayout(JSON.stringify(defaultDashboardLayout),"resetting") }
                                text="Reset"
                                icon="refresh"
                            />
                        </Tooltip2>
                    </div>
                </Card>
            </MDMWrapper>
        )
    }
}

export const UserSettings = connect(
    (state: any) => ({
        loggedUsername: state.username
    }),
    {
        showToaster,
        setUserSettings,
        setDashboardLayout
    },
)(UserSettingsBase);