import { displayMessageBox } from 'actions/ActionCreators';
import { mdmErrorToText } from 'helpers';
import { RecordApi } from '../api/RecordApi';
import { WorkflowApi } from '../api/WorkflowApi';

import {
    RECEIVE_RECORDS_HISTORY,
    RECEIVE_RECORD_ACTIVITY,
} from '../constants';

export const getRecordsHistory = (dbName: string, changesetId: number, table: string, key: string, filter?: any) => {
    return dispatch => {
        return RecordApi.getRecordHistory({databaseName: dbName, changesetId: changesetId, tableName: table, record_key: key, filter: filter})
        .then(
            (result: any) => dispatch({type: RECEIVE_RECORDS_HISTORY, payload: result.records}),
            (error: any) => {
                error = error.error || error;
                dispatch(displayMessageBox({
                    title: 'Query Record History Failed',
                    message: `Failed to query record history ${ mdmErrorToText(error )}`,
                    stack: error && error.stack
                }));
            }
        );
    };
};

export const getRecordActivity = (dbName: string, changesetId: any, table: string, key: string) => {
    return dispatch => {
        return WorkflowApi.recordActivity(dbName, changesetId, table, key)
        .then(
            (result: any) => {
                //console.log("getRecordActivity: " + JSON.stringify(result, null, 2));
                // currently we just return the history in a format RecordHistoryDialog understands
                const mappedHistory = result.history.map( (h:any) => ({timestamp: h.timestamp, record: h}));
                console.log("getRecordActivity: " + JSON.stringify(mappedHistory, null, 2));
                return dispatch({type: RECEIVE_RECORD_ACTIVITY, payload: mappedHistory});
            },
            (error: any) => {
                console.log("getRecordActivity error: ", error);
                error = (error && error.error) || error;
                dispatch(displayMessageBox({
                    title: 'Query Record History Failed',
                    message: `Failed to query record history ${ mdmErrorToText(error )}`,
                    stack: error && error.stack
                }));
            }
        );
    };
};
