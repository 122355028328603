import * as React from 'react';
import { Button, Classes, HTMLTable } from '@blueprintjs/core';

import './CompareRecordsTable.scss';

import { DataTypes, Field, Schema } from 'types';
import { RecordsTableRecordFieldView } from 'components/RecordsTable/RecordsTableRecordField';
import { RecordsTableEditRecordField } from 'components/RecordsTable/RecordsTableEditRecordField';
import { formatDateTimeDisplay } from 'helpers/date-format';
import { DateInput, TimePicker, TimePrecision } from '@blueprintjs/datetime';
import moment from 'moment';
import { Select } from '@blueprintjs/select';
import { MenuItem2 } from '@blueprintjs/popover2';

interface CompareField {
    field: string;
    oldValue: any;
    newValue: any;
    modified: boolean;
}
export interface CompareRecordTableProps {
    schema: Schema;
    oldRecord: object;
    newRecord: object;
    editable?: boolean;
    updateFieldCb: (fieldName: string, fileValue: string) => any;
}

const EnumSelect = Select.ofType<string>();

export class CompareRecordsTable extends React.Component<CompareRecordTableProps, {}> {

    getCompareFields(newFields: object, oldFields: object, schema: object): CompareField[] {
        // TODO: At least assert they have the same number of entries or keys, here
        return Object.entries(newFields).map((f, i) => {
            const field = f[0], newValue = f[1], oldValue = oldFields[f[0]];
            return {
                field: field,
                oldValue: oldValue,
                newValue: newValue,
                modified:oldValue !== newValue
            };
        });
    }

    onFieldChange = e => this.props.updateFieldCb(e.target.name, e.target.value);

    renderField(f: CompareField, field: Field, primaryKey: boolean, subField: boolean, i: number): JSX.Element {
        if (subField) {
            return (
                <div className="table-value scroll-container">
                    <RecordsTableEditRecordField
                        index={i}
                        field={field}
                        records={f.oldValue}
                        modifiedRecords={f.newValue || []}
                        updateFieldCb={(field, val) => this.props.updateFieldCb(field, val)}
                    />
                </div>
            );
        }

        // support for enum fields with dropdown
        if (field?.values?.length > 0) {
            return (
                <EnumSelect
                    items={field.values}
                    itemRenderer={(item, { handleClick, modifiers }) => {
                        return (
                            <MenuItem2
                                active={modifiers.active}
                                key={`${field.field}-${item}`}
                                onClick={handleClick}
                                text={item}
                            />
                        );
                    }}
                    filterable={false}
                    className='enum-field'
                    onItemSelect={(item) => this.onFieldChange({target: {name: f.field, value: item}})}
                >
                    <Button
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            display: 'flex',
                        }}
                        text={f.newValue || 'Select Value...'}
                        rightIcon="double-caret-vertical"
                        className={`${Classes.INPUT} ${Classes.FILL} ${f.modified ? `modified-value` : "cell-value"} flex-1`}
                    />
                </EnumSelect>
            );
        }

        if (field?.datatype?.type === DataTypes.date || field?.datatype?.type === DataTypes.datetime) {
            const format = field?.datatype?.type === DataTypes.date ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss';
            let timePickerProps = undefined;

            if (field?.datatype?.type === DataTypes.datetime) {
                timePickerProps = {
                    useAmPm: true,
                    precision: TimePrecision.SECOND
                }
            }

            return (
                <DateInput
                    key={`input-${i}`}
                    showActionsBar
                    className={`bp3-dateinput ${Classes.INPUT} ${Classes.FILL} ${f.modified ? `${Classes.INTENT_PRIMARY} modified-value` : "cell-value"}`}
                    name={f.field}
                    timePickerProps={timePickerProps}
                    formatDate={date => formatDateTimeDisplay(date, field) as string}
                    parseDate={date => {
                        console.log('date', date);
                        return moment(date, format).toDate()
                    }}
                    onChange={(date) => this.props.updateFieldCb(f.field, formatDateTimeDisplay(date, field, true) as string)}
                    value={moment(f.newValue, format).toDate()}
                    readOnly={primaryKey}
                />
            );
        }

        if (field?.datatype?.type === DataTypes.time) {   
            return (
                <TimePicker
                    key={`input-${i}`}
                    className={`bp3-timepicker-input ${Classes.INPUT} ${Classes.FILL} ${f.modified ? `${Classes.INTENT_PRIMARY} modified-value` : "cell-value"}`}
                    name={f.field}
                    dir="auto" 
                    precision={TimePrecision.SECOND}
                    useAmPm
                    value={moment(f.newValue, "HH:mm:ss").toDate()}
                    onChange={date => {
                        this.props.updateFieldCb(f.field, formatDateTimeDisplay(date, field, true) as string);
                    }}
                />
            );
        }

        if (field?.datatype?.type === DataTypes.boolean) {
            return (
                <select
                    key={`input-${i}`}
                    className='workitem-select-field'
                    value={f.newValue}
                    name={f.field}
                    id={f.field}
                    onChange={this.onFieldChange}
                >
                    <option value="">Select Value...</option>
                    <option value="true">true</option>
                    <option value="false">false</option>
                </select>
            );
        }

        return (
            <input
                className={`${Classes.INPUT} ${Classes.FILL} ${f.modified ? `${Classes.INTENT_PRIMARY} modified-value` : "cell-value"}`}
                readOnly={primaryKey}
                name={f.field}
                type="text"
                dir="auto"
                value={f.newValue}
                onChange={this.onFieldChange}
            />
        );
    }


    makeRows(fields: CompareField[]): JSX.Element[] {
        return fields.map((f, i) => {
            const primaryKey = (f.field === this.props.schema.primary_key);
            const field = this.props.schema.fields.find(field => field.field === f.field);
            const subField = field.datatype.type === DataTypes.table;
            const oldValue = formatDateTimeDisplay(f.oldValue, field);

            return(
                <tr className={f.modified ? "modified-row" : ""} key={i}>
                    <td>
                        <div className="field-cell-wrapper">
                            <div className={f.modified ? "modified-oval" : "modified-oval-space"} />
                            <div className="field-cell-values">
                                <div className="field-name">{f.field}</div>
                                <div className={`${f.modified ? "modified-value" : "cell-value"}`}>
                                    {subField ?
                                        <div className="table-value scroll-container">
                                            <RecordsTableRecordFieldView index={i} displayAll={true} schema={field.schema} records={f.oldValue}/>
                                        </div> :
                                    oldValue}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        {this.renderField(f, field, primaryKey, subField, i)}
                    </td>
                </tr>
            );
        });
    }

    render() {
        const fields = this.getCompareFields(this.props.newRecord, this.props.oldRecord, this.props.schema);
        return (
            <div>
            <HTMLTable className="compare-table">
                <thead className="compare-table-header">
                    <tr><td>Original</td><td>Proposed</td></tr>
                </thead>
                <tbody>
                    {this.makeRows(fields)}
                </tbody>
            </HTMLTable>
            </div>
        );
    }
}