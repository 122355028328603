import * as React from 'react';
import { connect } from 'react-redux';
import { Alert, Intent } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';

import { MDMWrapper, Button } from 'components/Shared';
import { deleteView, getAllViews } from 'store/view/actions';
import { ViewDetail } from './ViewDetail';
import { View } from 'types';

export interface ViewContainerPropTypes {
    dbName: string;
    deleteView: (database: string, viewID: number) => void;
    getAllViews: (database: string) => void;
    history: any;
    views: View[];
}

export interface ViewContainerState {
    showDeleteAlert: boolean;
    deleteView: View;
}

export class ViewContainerBase extends React.Component<ViewContainerPropTypes, ViewContainerState> {

    constructor(props) {
        super(props);
        this.state = { showDeleteAlert: false, deleteView: null };
    }

    componentDidMount() {
        this.props.getAllViews(this.props.dbName);
    }

    componentDidUpdate(prevProps: ViewContainerPropTypes) {
        const { dbName } = this.props;
        if (dbName !== prevProps.dbName)
            this.props.getAllViews(dbName);
    }

    onDeleteView = (viewID: number) => {
        const v = this.props.views.find(v => v.view_id === viewID);
        this.setState({ showDeleteAlert: true, deleteView: v });
    }

    deleteView(view: View) {
        this.props.deleteView(this.props.dbName, view.view_id);
        this.closeDeleteView();
        this.props.getAllViews(this.props.dbName);
    }

    closeDeleteView = () => {
        this.setState({ showDeleteAlert: false, deleteView: null });
    }

    editView = (viewID: number) => {
        this.props.history.push(`/views/edit/${viewID}`);
    }

    viewView = (viewID: number) => {
        this.props.history.push(`/views/view/${viewID}`);
    }

    newView = () => {
        this.props.history.push('/views/edit/');
    }

    getViewDetails() {
        return ((this.props.views && this.props.views.length > 0) ?
            this.props.views.map((v, i) => <ViewDetail key={i} description={v.description} viewID={v.view_id} createDate={new Date(v.create_timestamp)}
                deleteView={this.onDeleteView} editView={this.editView} viewView={this.viewView} />) :
            <div className="view-no-views">There are currently no views created</div>
        );
    }

    render() {
        const { deleteView } = this.state;
        const createViewButton = (
            <Button
                className="minimal-button"
                id="btnCreateTable"
                minimal
                key={0}
                value="Create View"
                onClick={this.newView}
            />
        )
        return (
            <>
                <MDMWrapper title="Views" buttons={[createViewButton]} documentationPath="views_and_subscriptions.html?q=views">
                    {this.getViewDetails()}
                </MDMWrapper>

                <Alert
                    icon={IconNames.TRASH}
                    intent={Intent.DANGER}
                    isOpen={this.state.showDeleteAlert}
                    confirmButtonText="Delete View"
                    cancelButtonText="Cancel"
                    onConfirm={() => this.deleteView(deleteView)}
                    onCancel={this.closeDeleteView}
                >
                    {`Are you sure you want to delete '${deleteView && (deleteView.description || deleteView.view_id.toString())}' ?`}
                </Alert>
            </>
        );
    }
}
export const ViewContainer = connect(
    (state: any) => ({ dbName: state.database.selected, views: state.view.computedViews }),
    { deleteView, getAllViews }
)(ViewContainerBase as any);
