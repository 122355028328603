/**
 * @format
 */
import * as moment from 'moment';
import * as React from 'react';
import { Icon, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Subscription, SubscriptionDeliveryDescription } from 'types';

require('./View.scss');

export interface ViewSubscriptionDetailProps {
    subscription: Subscription;
    showMenu?: boolean;
    deleteSubscription?: (subscriptionID: number) => void;
    enableSubscription?: (enable: boolean, subscriptionID: number, sync: string) => void;
    modifySubscription?: (subscriptionID: number) => void;
    onTextClick?: () => void;
}
export const ViewSubscriptionDetail: React.FC<ViewSubscriptionDetailProps> = ({
    subscription,
    showMenu,
    deleteSubscription,
    enableSubscription,
    modifySubscription,
    onTextClick
}) => {
    const state = subscription.state || { enabled: false, disabled_reason: '', in_ageout: false, retrying: false, sync_sequence: 0, target_sequence: 0, delivery_success_timestamp: null };
    const enabled = state.enabled;
    const enableItems = enabled ?
        [{ text: "Disable", sync: "nochange", enable: false }]
        :
        [
            { text: "Enable", sync: "nochange", enable: true },
            { text: "Enable from Start", sync: "start", enable: true },
            { text: "Enable from Now", sync: "now", enable: true }
        ];

    const disabledReasonDiv = enabled ? null :
        <div className="subscription-disable">
            <div className="text-small text-heavy">{state.disabled_reason || "Disabled for unknown reasons"}</div>
        </div>;
    const activities = [];
    if (enabled) {
        if (state.in_ageout) {
            activities.push('Running Age-out');
        }
        if (state.retrying) {
            activities.push('Retrying Delivery');
        }
        else if (state.sync_sequence < state.target_sequence) {
            activities.push("Processing changesets");
        }
        // MM/DD/YY HH:mm shorthand in localtime
        // TBD: Maybe there is better JS support for date formatting than I could find
        if (state.delivery_success_timestamp) {
            const d = moment(state.delivery_success_timestamp);
            const formattedDate = d.format('M/D/YY HH:mm');
            activities.push(`Last Delivery ${formattedDate}`);
        } else {
            activities.push("New");
        }
    }
    const activityDetail: JSX.Element[] = activities.map((s, i) => <div key={i}>{s}</div>);

    return (
        <div className="subscription-detail-wrapper">
            <div className="subscription-detail-title">
                <div className={onTextClick && "hover-subscription-details"} onClick={onTextClick} >
                    {
                        subscription.description &&
                        <div className="text-heavy">{subscription.description}</div>
                    }
                    <div className="text-medium text-heavy">
                        {SubscriptionDeliveryDescription[subscription.delivery.type]} ({state.enabled ? "Enabled" : "Disabled"})
                    </div>
                    {disabledReasonDiv}
                </div>
                <div className="management-actions users-actions">
                    {showMenu && <Popover position={Position.BOTTOM_RIGHT}>
                        <Icon icon={IconNames.MORE} />
                        <Menu>
                            {!!modifySubscription && <MenuItem
                                icon={IconNames.EDIT}
                                data-id="subscription-edit-menu"
                                onClick={e => modifySubscription(subscription.subscription_id)}
                                text={enabled ? "View" : "Edit"}
                            />}
                            {!!enableSubscription && enableItems.map(item => <MenuItem icon={item.enable ? IconNames.TICK : IconNames.DISABLE} text={item.text} key={item.text}
                                onClick={e => enableSubscription(item.enable, subscription.subscription_id, item.sync)}
                            />)}
                            {!!deleteSubscription && <MenuItem
                                icon={IconNames.DELETE}
                                onClick={e => deleteSubscription(subscription.subscription_id)}
                                text="Delete"
                            />}
                        </Menu>
                    </Popover>}
                </div>
            </div>
            <div className="subscription-detail">{subscription.delivery.webservice && subscription.delivery.webservice.url || ''}</div>
            <div className="subscription-detail">
                {`min: ${subscription.delivery.failure_strategy.retry_seconds_start}s` +
                    `   max: ${subscription.delivery.failure_strategy.retry_seconds_max}s`}
            </div>
            <div className="subscription-detail">
                {activityDetail}
            </div>
        </div>);
};
