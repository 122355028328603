import * as React from 'react';
import { connect } from 'react-redux';
import { Classes, Dialog, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { isMDMName } from '../../utils';
import { createDatabase, showCreateDatabase } from '../../actions/ActionCreators';
import { Button } from '../Shared';
import './DialogCreateDatabase.scss';

export interface DialogCreateDatabaseProps {
    createDatabase: (newDbName: string, dbDesc: string) => void;
    showcreatedb: boolean;
    showCreateDatabase: (show: boolean) => void;
}

export interface DialogCreateDatabaseState {
    newDbName: string;
    dbDesc: string;
    nameValid: boolean;
}
export class DialogCreateDbBase extends React.Component<
    DialogCreateDatabaseProps,
    DialogCreateDatabaseState
> {
    constructor(props) {
        super(props);
        this.state = {
            newDbName: '',
            dbDesc: '',
            nameValid: true,
        };
    }

    onFieldChange() {
        return event => {
            const name = event.target.name;
            const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            let nameValid = this.state.nameValid;

            if (name === 'newDbName') {
                if (value && !isMDMName(value)) {
                    nameValid = false;
                } else if (!this.state.nameValid) {
                    nameValid = true;
                }
            }

            // check MDM name
            const newState = { ...this.state, [event.target.name]: value, nameValid };
            this.setState(newState);
        };
    }
    createDatabase = () => {
        this.props.showCreateDatabase(false);
        this.props.createDatabase(this.state.newDbName, this.state.dbDesc);
        this.setState({ ...this.state, newDbName: '', dbDesc: '' });
    }

    close = () => {
        this.props.showCreateDatabase(false);
        this.setState({ ...this.state, newDbName: '', dbDesc: '' });
    }

    render() {
        return (
            <Dialog isOpen={this.props.showcreatedb} icon={IconNames.ADD} title="Create Database" onClose={e => this.close()}>
                <div className={`${Classes.DIALOG_BODY} dialog-create-database`}>
                    <FormGroup label="Database Name" labelFor="dbnaminput">
                        <input
                            className={`${Classes.INPUT} ${Classes.FILL}`}
                            id="dbNameinput"
                            name="newDbName"
                            onChange={this.onFieldChange()}
                            value={this.state.newDbName}
                            autoFocus
                        />
                    </FormGroup>
                    {
                        !this.state.nameValid &&
                        <span className="text-red db-name-warning">Database name can't include special characters.</span>
                    }
                    <FormGroup label="Description" labelFor="descinput">
                        <input
                            id="descinput"
                            className={`${Classes.INPUT} ${Classes.FILL}`}
                            name="dbDesc"
                            onChange={this.onFieldChange()}
                            value={this.state.dbDesc}
                        />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button disabled={!this.state.nameValid || !this.state.newDbName} id='create-db-button' onClick={this.createDatabase} value="Create" icon={IconNames.PLUS} />
                        <Button onClick={this.close} value="Cancel" icon={IconNames.CROSS} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const DialogCreateDatabase: any = connect(
    (state: any) => ({
        showcreatedb: state.showcreatedb
    }),
    { createDatabase, showCreateDatabase }
)(DialogCreateDbBase as any);