import * as React from 'react';
import { AuditFilter, EventFilter } from 'api';
import { Button, ButtonGroup, Checkbox, Collapse, FormGroup, HTMLInputProps, HTMLSelect, InputGroup, MenuItem, Position } from '@blueprintjs/core';
import * as moment from 'moment';
import { DateRangeInput } from '@blueprintjs/datetime';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { highlightText, filterItem } from '../../utils/bpSelectHelpers';


type LogFilterFormProps = {
    onFilterChange: (filters: AuditFilter | EventFilter) => void;
    currentType: 'event' | 'audit';
    onTypeChange: (type: string | 'audit' | 'event') => void;
}

const AreaSelect = Select.ofType<string>();
const ActionSelect = Select.ofType<string>();


const LogFilterForm: React.FC<LogFilterFormProps> = ({ onFilterChange, currentType, onTypeChange }) => {
    const [days, setDays] = React.useState<number>(1);
    const [startDate, setStartDate] = React.useState<Date>(moment().subtract(1, 'days').toDate());
    const [endDate, setEndDate] = React.useState<Date>(moment().toDate());
    const [severities, setSeverities] = React.useState<string[]>([]);
    const [selectedArea, setSelectedArea] = React.useState<string>('');
    const [selectedAction, setSelectedAction] = React.useState<string>('');
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    const changeTime = (newDay: number) => {
        const end = moment().toDate();
        const start = moment().subtract(newDay, 'days').toDate();

        setEndDate(end);
        setStartDate(start);
        setDays(newDay);
    };

    const onRangeChange = ([newStart, newEnd]) => {
        setStartDate(moment(newStart).toDate());
        setEndDate(moment(newEnd).toDate());
    };

    const onEventChange = (field: string, e: React.ChangeEvent<HTMLInputElement>) => onFilterChange({
        [field]: e.target.value.length ? e.target.value : undefined
    });

    const onTextChange = (field: string, value: string) => onFilterChange({
        [field]: value.length ? value : undefined
    });
  
    const formatDate = (date: Date) => date.toLocaleString();
    const parseDate = (str: string) => new Date(str);

    React.useEffect(() => {
        onFilterChange({
            timestamp_min: moment(startDate).valueOf(),
            timestamp_max: moment(endDate).valueOf(),
        })
    }, [startDate, endDate])

    const handleSeverityChange: React.FormEventHandler<HTMLInputElement>= (e: unknown) => {
        const event = e as React.ChangeEvent<HTMLInputProps>;
        const { checked, value } = event.target ;
        let newSeverities = [...severities];

        if (checked && newSeverities.indexOf(value as string) === -1) {
            newSeverities.push(value as string);
        }

        if (!checked) {
            newSeverities = newSeverities.filter(severity => severity !== value);
        }

        setSeverities(newSeverities);

        onFilterChange({
            severity: newSeverities.length ? newSeverities.join(',') : undefined
        });
    }

    const renderAreaItem: ItemRenderer<string> = (item, { handleClick, modifiers, query }) => {
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={`area-select-${item}`}
                onClick={handleClick}
                text={highlightText(item, query)}
            />
        );
    }

    const renderActionItem: ItemRenderer<string> = (item, { handleClick, modifiers, query }) => {
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={`area-select-${item}`}
                onClick={handleClick}
                text={highlightText(item, query)}
            />
        );
    }

    const renderNewAreaItem = (query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) => (
        <MenuItem
            aria-label="created-area-item"
            key={`area-select-${query}`}
            onClick={handleClick}
            text={query}
        />
    );

    const renderNewActionItem = (query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) => (
        <MenuItem
            key={`action-select-${query}`}
            onClick={handleClick}
            text={query}
        />
    )

    const onAreaSelect = (item: string) => {
        if (item === 'Select Area') {
            onTextChange('area', '');
            setSelectedArea('');
        } else {
            onTextChange('area', item);
            setSelectedArea(item);
        }
    }

    const onActionSelect = (item: string) => {
        if (item === 'Select Action') {
            onTextChange('action', '');
            setSelectedAction('');
        } else {
            onTextChange('action', item);
            setSelectedAction(item);
        }
    }

    const createNewItem = (query: string) => query;

    return (
        <div className="log-form-container">
            <div className="log-form-row">
                <FormGroup label="Log Type" labelFor="log-type-select">
                    <HTMLSelect
                        id="log-type-select"
                        options={[
                            { label: 'Audit', value: 'audit', selected: currentType === 'audit' },
                            { label: 'Event', value: 'event', selected: currentType === 'event' }
                        ]}
                        onChange={(e) => onTypeChange(e.target.value)}
                        value={currentType}
                    />
                </FormGroup>
                <FormGroup
                    label="Between dates:"
                    labelFor="filter-range"
                >
                        <DateRangeInput
                            formatDate={formatDate}
                            onChange={onRangeChange}
                            parseDate={parseDate}
                            value={[startDate, endDate]}
                            contiguousCalendarMonths={false}
                            allowSingleDayRange={true}
                            popoverProps={{ position: Position.RIGHT_TOP }}
                            style={{ marginRight: 10 }}
                        />
                        
                </FormGroup>
                <FormGroup label="&nbsp;">
                    <ButtonGroup>
                            <Button
                                text="24 Hours"
                                active={days === 1}
                                onClick={() => changeTime(1)}
                            />
                            <Button
                                text="7 Days"
                                active={days === 7}
                                onClick={() => changeTime(7)}
                            />
                            <Button
                                text="30 Days"
                                active={days === 30}
                                onClick={() => changeTime(30)}
                            />
                        </ButtonGroup>
                </FormGroup>
                <FormGroup label="&nbsp;">
                    <Button
                        text={!isExpanded ? "more filters" : "less filters"}
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </FormGroup>
            </div>
            <Collapse isOpen={isExpanded}>
                <div className="log-form-row">
                    <FormGroup label="Area" style={{ marginRight: 10 }}>
                        <AreaSelect
                            items={[
                                'Select Area',
                                'data',
                                'pii',
                                'schema',
                                'user',
                                'view',
                                'workflow',
                            ]}
                            itemRenderer={renderAreaItem}
                            itemPredicate={filterItem}
                            onItemSelect={onAreaSelect}
                            createNewItemFromQuery={createNewItem}
                            createNewItemRenderer={renderNewAreaItem}
                        >
                            <Button
                                rightIcon="double-caret-vertical"
                            >
                                <span aria-label={selectedArea ? selectedArea : 'Select Area'}>
                                    {selectedArea ? selectedArea : 'Select Area'}
                                </span>
                            </Button>
                        </AreaSelect>
                    </FormGroup>
                    <FormGroup label="Action" style={{ marginRight: 10 }}>
                        <ActionSelect
                            items={[
                                'Select Action',
                                'discard',
                                'commit',
                                'create',
                                'update',
                                'delete',
                                'truncate',
                                'expunge',
                                'mask',
                                'purge',
                            ]}
                            itemRenderer={renderActionItem}
                            itemPredicate={filterItem}
                            onItemSelect={onActionSelect}
                            createNewItemFromQuery={createNewItem}
                            createNewItemRenderer={renderNewActionItem}
                        >
                            <Button
                                rightIcon="double-caret-vertical"
                            >
                                <span aria-role="button">{selectedAction ? selectedAction : 'Select Action'}</span>
                            </Button>
                        </ActionSelect>
                    </FormGroup>
                    {currentType === 'event' && (
                        <FormGroup label="Severity" labelFor="severity-select" style={{ marginRight: 10 }}>
                            <Checkbox
                                label="Info"
                                inline
                                value="info"
                                onChange={handleSeverityChange}
                            />
                            <Checkbox
                                label="Warning"
                                inline
                                value="warning"
                                onChange={handleSeverityChange}
                            />
                            <Checkbox
                                label="Error"
                                inline
                                value="error"
                                onChange={handleSeverityChange}
                            />
                            <Checkbox
                                label="Fatal"
                                inline
                                value="fatal"
                                onChange={handleSeverityChange}
                            />
                        </FormGroup>
                    )}
                </div>
                <div className="log-form-row">
                    <FormGroup label="User" labelFor="log-filter-user" style={{ marginRight: 10, flex: 1 }}>
                        <InputGroup
                            id="log-filter-user"
                            type="text"
                            placeholder="User"
                            onChange={(e) => onTextChange('user', e.target.value) }
                        />
                    </FormGroup>
                    <FormGroup label="Target" labelFor="log-filter-target" style={{ marginRight: 10, flex: 1 }}>
                        <InputGroup
                            id="log-filter-target"
                            type="text"
                            placeholder="Target"
                            onChange={(e) => onTextChange('target', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup label="Filter Message" labelFor="log-filter-message" style={{ marginRight: 10, flex: 4 }}>
                        <InputGroup
                            id="log-filter-message"
                            type="text"
                            placeholder="Filter Message"
                            onChange={(e) => onTextChange('message', e.target.value)}
                        />
                    </FormGroup>
                </div>
            </Collapse>
        </div>
    );
}

export default LogFilterForm;