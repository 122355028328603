import * as React from 'react';
import { Button, MenuItem, IOptionProps } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select'
require('./CustomSelect.scss');

interface ICustomSelectProps {
    className?: string;
    onChange: (selectedOption: ISelectedItem) => void;
    placeholder?: string;
    filterable?: boolean;
    disabled?: boolean;
    options: IOptionProps[];
    fill?: boolean;
    value?: string;
    name?: string;
    buttonClassName?: string;
}

interface ICustomSelectState {
    selectedItem?: ISelectedItem;
}

interface ISelectedItem {
    label?: string;
    value: string | number;
    index: number;
    name?: string;
    disabled?: boolean;
}

export class CustomSelect extends React.Component<ICustomSelectProps, ICustomSelectState> {

    constructor(props) {
        super(props);

        this.state = {
            selectedItem: props.value ? this.getSelectedItem() : null
        };
    }

    componentDidUpdate(prevProps) {
        const { value, options } = this.props;

        if (prevProps.value !== value || prevProps.options.length !== options.length) {
            const selectedItem = this.getSelectedItem()
            this.setState({ selectedItem })
        }
    }

    getSelectedItem = () => {
        const { value, options } = this.props;
        const itemIndex = options.findIndex(option => option.value === value);
        const selectedItem = itemIndex !== -1 ? { ...options[itemIndex], index: itemIndex } : null;
        return selectedItem;
    }

    onItemSelect = (item: ISelectedItem): void => {
        const { onChange, name, value } = this.props;
        const selectedItem = { ...item, value: item.value, name }
        if (value)
            onChange(selectedItem)
        else
            this.setState({ selectedItem: item }, () => onChange(selectedItem))
    }

    renderMenuItem: ItemRenderer<ISelectedItem> = (option, { handleClick, modifiers }) => {
        const { selectedItem } = this.state;
        const isActive = selectedItem && selectedItem.index === option.index;

        if (!modifiers.matchesPredicate) {
            return null;
        }

        return (
            <MenuItem
                className="custom-dropdown-item"
                active={isActive}
                key={option.value}
                onClick={handleClick}
                text={option.label}
                disabled={option.disabled && !isActive}
            />
        );
    };

    render() {
        const { placeholder, disabled, options, filterable, buttonClassName, fill, className } = this.props;
        const { selectedItem } = this.state;
        const itemsWithIndex = options ? options.map((item, index) => { return { ...item, index } }) : [];

        return (
            <Select
                className={`${fill ? "fill" : ""} ${className || ''}`}
                onItemSelect={this.onItemSelect}
                items={itemsWithIndex}
                itemRenderer={this.renderMenuItem}
                filterable={filterable || false}
                popoverProps={{
                    minimal: true,
                    fill
                }}
            >
                <Button
                    className={buttonClassName || "fill-width simple-input flex-space-between"}
                    rightIcon="chevron-down"
                    text={selectedItem ? selectedItem.label : placeholder || "Select an option..."}
                    disabled={disabled || false}
                />
            </Select>
        )
    }
}