import * as React from 'react';
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import { Schema } from 'types';
require('./RecordsTable.scss');

export type getRowFunction = (record: object, index: number) => JSX.Element;
export type getRowsFunction = (records: object[], getRowFunc: getRowFunction) => JSX.Element[];
export interface RecordsTableRecordFieldProps {
    index?: number;
    columns: JSX.Element[];
    getRow?: getRowFunction;
    getRows?: getRowsFunction;
    records: object[];
    onDrag?: any;
}

export const getHeaders = (schema: Schema): JSX.Element[] =>
    schema ? schema.fields.map((field, index) => <div className="th" key={`rt-rf-th-${index}-${field.field}`}>{field.field}</div>) : [];

export const getCell = (val: React.ReactNode, index: number): JSX.Element => {
    return <div className="td" key={`rt-rf-td-${index}`}><span>{val}</span></div>;
}

export const getRow = (record: object, index: number): JSX.Element[] =>
    Object.values(record).map((v, i) => getCell(v, i));

export const getRows = (records: object[], getRowFunc?: getRowFunction): JSX.Element[] => {
    if (!records) {
        return []
    }
    return records.map((rec, index) => getRowFunc ?
        getRowFunc(rec, index) :
        <div className="tr" key={`rt-rf-tr-${index}`}>{getRow(rec, index)}</div>
    );
}

const getData = (records: object[], getRowsFunc: getRowsFunction, getRowFunc: getRowFunction): JSX.Element[] =>
    (getRowsFunc ? getRowsFunc(records, getRowFunc) : getRows(records, getRowFunc));

export const RecordsTableRecordField: React.FC<RecordsTableRecordFieldProps> = ({ columns, getRow, getRows, records, index, onDrag }) => {
    return <div className="record-field-record-table">
        <div className="thead">
            <div className="tr-header">{columns}</div>
        </div>
        {
            (index && onDrag) ?
                <DragDropContext onDragEnd={(dragResult) => {
                    const {source, destination} = dragResult;
                    onDrag(source.index, destination.index);
                }} >
                    <Droppable droppableId={index.toString()} key={`droppable-${index}`}>
                        {(droppableProvided, _droppableSnapshot) => (
                            <>
                                <div className="tbody" ref={droppableProvided.innerRef}>
                                    {getData(records, getRows, getRow)}
                                </div>
                                {droppableProvided.placeholder}
                            </>
                        )}
                    </Droppable>
                </DragDropContext>
                :
                <div className="tbody">
                    {getData(records, getRows, getRow)}
                </div>
        }
    </div>;
};
// Simplest implementation of RecordsTableRecordField
export interface RecordsTableRecordFieldViewProps {
    displayAll?: boolean;
    records: object[];
    schema: Schema;
}
export const RecordsTableRecordFieldView: React.FC<RecordsTableRecordFieldViewProps> = ({ displayAll, schema, ...props }) => {
    const columns = displayAll ?
        schema.fields.map(f => f.field) :
        schema.fields.filter(f => f.display).map(f => f.field);
    return (
        <RecordsTableRecordField
            columns={columns.map((c, i) => <div className="th" key={`rt-rfv-th-${i}`}>{c}</div>)}
            getRow={(record, index) => <div className="tr" key={`rt-rfv-tr-${index}`}>{columns.map((c, i) => getCell(record[c], i))}</div>}
            {...props}
        />
    );
};