import * as React from 'react';
import { Icon, Intent, HTMLSelect, Label, Classes, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from 'components/Shared';
import { GroupType, MatchRecord } from './WorkflowMatchConstants';
import { MatchGroupContainer } from './WorkflowMatchGroup/WorkflowMatchGroupContainer';
import { NotesTable } from 'components/NotesTable/NotesTable';
import { WorkflowMatchTableGroups } from './WorkflowMatchGroup/WorkflowMatchTable';
import { History, Schema, MDMRecord, WorkflowAction, Workflow } from 'types';
import { WorkflowWidgets } from '../WorkflowWidgets';

export interface WorkflowMatchFormProps {
    approveTask: (action: WorkflowAction, matchGroups: string[][], note: string, button:HTMLButtonElement) => void;
    discardTask: (button:HTMLButtonElement, note: string) => void;
    history: History[];
    schema: Schema;
    records: MDMRecord[];
    acceptedGroups: string[][];
    proposedGroups: string[][];
    workflow: Workflow;
}

export interface WorkflowMatchFormState {
    customRecords: MatchRecord[];
    grouperShowing: boolean;
    groupType: GroupType;
    note: string;
}

const WorkflowMatchOverrideFormFC: React.FC<WorkflowMatchFormProps> = ({records, proposedGroups, acceptedGroups, schema, ...props}) => {
    const [customRecords, setCustomRecords] = React.useState<MatchRecord[]>([]);
    const [grouperShowing, setGrouperShowing] = React.useState<boolean>(false);
    const [groupType, setGroupType] = React.useState<GroupType>(GroupType.ACCEPTED);
    const [note, setNote] = React.useState<string>('');

    // Fields synthesized to facilitate grouping
    const hiddenFields = ['_id', '_group'];

    React.useEffect(() => {
        const records = itemStateToRecords();
        setCustomRecords(records);
    }, [records]);

    React.useEffect(() => {
        setCustomRecords(itemStateToRecords());
    }, [acceptedGroups, proposedGroups]);

    React.useEffect(() => {
        setCustomRecords(itemStateToRecords());
    }, [schema]);

    const itemStateToRecords = (): MatchRecord[] => {
        // Transform the records into a map by primary_key, with only the display (and primary_key) fields, 
        // with the computed fields, _id (primary key) and _group(three member array with accepted, proposed, and custom group #'s)
        const reducedRecords = records.reduce((newRecords: any, record: any) => {
            // add displayed non-table fields and the _id/_group fields
            let reducedRecord = schema.fields.reduce((acc, cur) => {
                return cur.display ?
                    { ...acc, [cur.field]: record[cur.field] } : acc;
            }, { _group: [], _id: record[schema.primary_key] });

            // find the number of table fields we will present
            const tableFields = schema.fields.filter(f => f.schema && f.schema.fields.some(f2 => f2.display));

            // find the objects in each field and clip number and displayed rows
            const reducedNestedFields = tableFields.reduce((fields, field) => {
                // get the rows of nested table (clipped length)
                const sourceRecords = record[field.field] ?? []; // [ {...},{...}, ...]
                const outputRecords = [];
                // limit fields count as you go through each row in th
                sourceRecords.forEach(record => {
                    // let counter = MAX_NESTED_ELEMENTS;
                    let reducedElements = {};
                    // add visible element values (but limit to 1st N)
                    field.schema.fields.forEach(f => {
                        
                        reducedElements = { ...reducedElements, [f.field]: record[f.field] };
                        
                    });
                    // generate an array of those objects with their limited fields
                    outputRecords.push(reducedElements);
                });
                return { ...fields, [field.field]: outputRecords };
            }, {});

            // glue the nested values in
            if (Object.keys(reducedNestedFields).length > 0) {
                reducedRecord = { ...reducedRecord, ...reducedNestedFields };
            }
            newRecords[record[schema.primary_key]] = reducedRecord;
            return newRecords;

        }, {});

        // build the accepted and custom groups numbbers by incrementing a counter. The default is for the custom to start out
        // with the accepted grouping (as given to us by the server)
        const itemStateAcceptedGroups = acceptedGroups || [];
        let counter = 1;
        itemStateAcceptedGroups.sort().forEach((group) => {
            group.forEach((recordId) => {
                reducedRecords[recordId]._group[GroupType.ACCEPTED] = counter;
                reducedRecords[recordId]._group[GroupType.CUSTOM] = counter;
            });
            ++counter;
        });

        // build the proposed groups the same way
        const itemStateProposedGroups = proposedGroups || [];

        counter = 1;

        itemStateProposedGroups.sort().forEach((group) => {
            group.forEach((recordId) => {
                reducedRecords[recordId]._group[GroupType.PROPOSED] = counter;
            });
            ++counter;
        });
        
        return Object.values(reducedRecords);
    }

    // Turn the records custom groups into a map from group # to an array of record primary keys,
    // then return the values (an array of group arrays containing primary keys)
    const buildCustomGroups = (): string[][] => {
        const groups = customRecords.reduce((acc, cur) => {
            const group = cur._group[GroupType.CUSTOM];
            acc[group] = group in acc ? [...acc[group], cur._id] : [cur._id];
            return acc;
        }, {});
        return Object.values(groups);
    }

    const setRecordCustomGroup = (recordId, newGroupId) => {
        const recs = customRecords.map((customRecord) => {
            if (customRecord._id.toString() !== recordId.toString()) {
                return customRecord;
            }
            const newRec = ({
                ...customRecord, _group: (customRecord._group as number[]).map((g, index) => index === GroupType.CUSTOM ? newGroupId :
                    g)
            })
            return newRec;
        })
        setCustomRecords(recs as any);
    }

   /*  const noteChangedFn = (value) => {
        setNote(value);
    } */

    const submitFn = (button:HTMLButtonElement) => {
        let matchGroups = undefined;
        let action = undefined;
        switch (groupType) {
            case GroupType.ACCEPTED:
            default:
                action = WorkflowAction.choose_accepted;  // MDM Development Design Documents / MDM Workflow Definition / Override Match Accepted
                break;
            case GroupType.PROPOSED:
                action = WorkflowAction.choose_proposed;
                break;
            case GroupType.CUSTOM:
                action = WorkflowAction.custom;
                matchGroups = buildCustomGroups();
                break;
        }
        props.approveTask(action, matchGroups, note, button);
    }

    const discardFn = (button:HTMLButtonElement) => props.discardTask(button, note);


    const acceptCustom = () => setGrouperShowing(false);

    const cancelCustom = () => setCustomRecords(itemStateToRecords());

    const setCustom = () => setGrouperShowing(true);

    const onNoteChange = (newNote: string) => setNote(newNote);

    const onMatchTypeChange = (e: React.ChangeEvent<any>) => {
        const val = parseInt(e.target.value, 10);
        setGroupType(val);
    }

    const onTableSortChange = (type: GroupType) => setGroupType(type);

    const getButtons = () => grouperShowing ?
        [
            <Button value="CANCEL" key="cancel" intent={Intent.DANGER} onClick={cancelCustom} />,
            <Button value="ACCEPT" key="back" intent={Intent.SUCCESS} onClick={acceptCustom} />
        ] :
        [
            <Button value="DISCARD" key="workflowdiscard" intent={Intent.DANGER} onClick={(e) => discardFn(e.currentTarget)} />,
            <Label
                className={`${Classes.INLINE} workflow-match-custom-instruction workflow-match-custom-label`}
                htmlFor="sort-select"
                key="sortselect"
            >
                Sort Groups By
                <HTMLSelect id="sort-select" className="workflow-title-select" iconProps={{ className: "workflow-title-select-icon" }} value={groupType} onChange={onMatchTypeChange}
                    options={[
                        { label: 'Accepted', value: GroupType.ACCEPTED },
                        { label: 'Proposed', value: GroupType.PROPOSED },
                        { label: 'Custom', value: GroupType.CUSTOM },
                    ]}
                />
            </Label>,
            groupType === GroupType.CUSTOM ?
                <Button value="EDIT CUSTOM GROUPS" key="editcustom" intent={Intent.PRIMARY} onClick={setCustom} /> : null,
            <Button value="SUBMIT" key="workflowsubmit" intent={Intent.SUCCESS} onClick={(e) => submitFn(e.currentTarget)} />
        ]

    return (
        <div className="workflow-match-wrapper">
            <div className="workflow-match-menu-wrapper">
                <div className="workflow-menu">
                    <div className="workflow-title">Match Review
                        <Tooltip content="Documentation" position={Position.RIGHT}>
                            <div className="mdm-wrapper-docs-button flex-center">
                                <Icon
                                    icon='help'
                                    onClick={() => { window.open(`/mdm-help/workflow_review_auto_match_steward.html`, 'mdm-help'); }}
                                    color="#636567"
                                    size={20}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    <div className="workflow-title-buttons">
                        {getButtons()}
                    </div>
                </div>
                <WorkflowWidgets
                    tableName={props.workflow.tableName}
                    workitemId={props.workflow.workitem_id}
                />
                {
                    grouperShowing &&
                    <div className="workflow-match-custom-instruction">
                        <Icon icon={IconNames.ISSUE} className="workflow-match-custom-icon" />
                        Drag Records To Edit Groups
                    </div>
                }
            </div>
            <div className="workflow-match">
                {grouperShowing ?
                    <MatchGroupContainer
                        rowsDraggable={true}
                        records={customRecords}
                        schema={schema}
                        hiddenFields={hiddenFields}
                        setCustomGroup={setRecordCustomGroup}
                    /> :
                    <WorkflowMatchTableGroups
                        showSubTables={false}
                        hiddenFields={hiddenFields}
                        schema={schema}
                        records={customRecords}
                        groupType={groupType}
                        onTableSortChange={onTableSortChange}
                    />
                }
                <NotesTable history={props.history || []} note={note} updateNote={onNoteChange} />
            </div>
        </div>
    );

}

export default  WorkflowMatchOverrideFormFC;