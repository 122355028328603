/**
 * Created by joshg on 5/17/2017.
 */
import { PURGE } from 'redux-persist';
import { SET_USERNAME } from '../constants';

export const username = (state = '', action) => {
    switch (action.type) {
        case SET_USERNAME:
            return action.username;

        case PURGE:
            return '';

        default:
            return state;
    }
};
