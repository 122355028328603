import * as React from 'react';
import { ITreeNode, Tooltip } from "@blueprintjs/core";

import { displayMessageBox } from '../../actions/ActionCreators';
import { v4 as uuidv4 } from 'uuid';
import { TableApi, ViewApi } from 'api';
import { MDMStore } from 'store';
import { Process } from 'types';
import { mdmErrorToText } from "helpers";

import { IPermNode } from './IPermNode';
import { PermDatabaseChildNode } from './PermDatabaseChildNode';
import { PermTableNode } from './PermTableNode';
import { PermViewNode } from './PermViewNode';
import { PermProcessNode } from './PermProcessNode';
import { WorkflowApi } from '../../api';


export class PermDatabaseNode implements IPermNode {
    id: string;
    name: string;
    type: string;
    isOpen: false;
    canBeSelected: boolean;
    isLeaf: boolean;
    openIcon: string;
    closedIcon: string;
    children: IPermNode[];
    changeset: string | number;
    parent: IPermNode;

    constructor(name, changeset) {
        this.id = uuidv4();
        this.name = name;
        this.type = 'database';
        this.isOpen = false;
        this.canBeSelected = true;
        this.isLeaf = false;
        this.openIcon = 'database';
        this.closedIcon = 'database'; // unused
        this.children = [];
        this.changeset = changeset;
    }

    async getProcesses() {
        const self = this;
        const parent = self.parent;
        try {
            const result: Process[] = await WorkflowApi.queryProcesses(parent.name.toString());
            const children: PermProcessNode[] = result.map(proc => {
                let label: string | JSX.Element = `${proc.process_id}: ${proc.description}`;
                if (proc.description.length > 24) {
                    label = (<Tooltip content={proc.description}>
                        {`${proc.process_id}: ${proc.description.substring(0, 24)}`}
                    </Tooltip>);
                }
                return new PermProcessNode(label, proc.process_id, proc.description, self);
            });
            self.children = children.sort((a, b) => a.process_id - b.process_id);
        }
        catch (error) {
            error = error.error || error;
            MDMStore.dispatch<any>(displayMessageBox({
                title: 'List Processes Failed',
                message: `Failed to list processes: ${mdmErrorToText(error)}`,
                stack: error && error.stack,
            }));
        }
        return true;
    }

    async getTables() {
        // this is weird. this is only called by the Table child of the database
        const self = this;
        const parent = this.parent;
        try {
            const tableNames = await TableApi.listTables(parent.name as string, parent.changeset);
            self.children = tableNames.map(t => new PermTableNode(t, parent));
        }
        catch (error) {
            error = error.error || error;
            MDMStore.dispatch<any>(displayMessageBox({
                title: 'Query Tables Failed',
                message: `Failed to query tables: ${mdmErrorToText(error)}`,
                stack: error && error.stack,
            }));
        }
        return true;
    }


    async getViews() {
        const self = this;
        const parent = this.parent;

        try {
            const result = await ViewApi.getAllComputedViews(parent.name as string);

            const children: PermViewNode[] = result.map(view => {
                let label: string | JSX.Element = `${view.view_id}: ${view.description}`;
                if (view.description.length > 24) {
                    label = (
                        <Tooltip content={view.description}>
                            {`${view.view_id}: ${view.description.substring(0, 24)}`}
                        </Tooltip>
                    );
                }
                return new PermViewNode(label, view.view_id, view.description, parent);
            });

            self.children = children.sort((a, b) => a.view_id - b.view_id);
        }
        catch (error) {
            console.error(error);
            error = error.error || error;
            MDMStore.dispatch<any>(displayMessageBox({
                title: 'List Views Failed',
                message: `Failed to list views: ${mdmErrorToText(error)}`,
                stack: error && error.stack,
            }));
        }
        return true;
    }

    async getChildrenFn() {
        if (this.children.length === 0) {
            this.children = [
                new PermDatabaseChildNode('Tables', 'list', this),
                new PermDatabaseChildNode('Views', 'list', this),
                new PermDatabaseChildNode('Processes', 'list', this),
            ];
            this.children[0].getChildrenFn = this.getTables;
            this.children[1].getChildrenFn = this.getViews;
            this.children[2].getChildrenFn = this.getProcesses;
        }
        return await false;
    }

    fullNameFn() {
        return this.name;
    }

    myName() {
        return this.name;
    }

    header() {
        return `Database: ${this.name}`;
    }

    getBPNode() {
        const childrenBP = this.children.map(child => child.getBPNode());
        return {
            id: this.id,
            isExpanded: this.isOpen,
            hasCaret: true,
            iconName: this.openIcon,
            label: this.name,
            childNodes: childrenBP
        } as ITreeNode;
    }
}
