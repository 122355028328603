import React from 'react';
import { render } from 'react-dom';

import { authenticate } from 'api';
import { App } from 'components/App';
import { MDMStore as store } from 'store';

import '../helpers/styles/global.scss';
import '../helpers/styles/rbm-patch.scss';
import '../helpers/styles/rbm-complete.scss';
import '../helpers/styles/rpmdm.scss';
import '../helpers/styles/loading.scss';
//  Move this to global.scss -- except I get missing resources. Why?
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

// tslint:disable-next-line:no-default-export
render((<App />), document.getElementById('root'));

// Check for valid user on storerehydrate
let unsubscribe = null;
const onStoreRehydrate = () => {
    // Ensure that we are actually logged in, else force logout
    const storeState = store.getState();
    if (storeState.storerehydrated) {
        unsubscribe();
        authenticate();
    }
};
unsubscribe = store.subscribe(() => onStoreRehydrate());

