import * as React from 'react';
import { connect } from 'react-redux';

import { REFRESH_BADGES_KEY } from '../../constants/settings';

import { inSystemGroup } from '../../api';
import { AuthApi } from '../../api/AuthApi';
import { DatabaseApi } from '../../api/DatabaseApi';
import { UserApi } from '../../api/UserApi';
import { PermApi } from '../../api/PermApi';

import { WorkflowApi } from '../../api/WorkflowApi';

import { Card, Divider, Switch } from '@blueprintjs/core';
import { Intent } from '../../types';
import { MDMWrapper, Button, ConfirmDialog } from '../Shared';
import { setBadgeFetchFlag } from '../../actions/systemSettings'
import { showToaster } from '../../actions/ActionCreators'
import './DebugConfig.scss';

// import { getPostTraceMessages, setPostTraceMessages } from 'rp-mdm-common';

export interface DebugConfigProps {
    showToaster?: showToaster;
    fetchBadges: boolean;
    setBadgeFetchFlag?: (boolean) => void;
}

export interface DebugConfigState { }

export class DebugConfigBase extends React.Component<DebugConfigProps, DebugConfigState> {
    constructor(props) {
        super(props);
        this.state = {}
    }

    pingAuth = () => {
        const { showToaster } = this.props;
        AuthApi.ping().then(resData => {
            showToaster(Intent.SUCCESS, `Authentication ${resData.message}`);
        }).catch(error => {
            showToaster(Intent.ERROR, "Authentication service not working!");
        });
    }

    pingUser = () => {
        const { showToaster } = this.props;
        UserApi.ping().then(resData => {
            showToaster(Intent.SUCCESS, `User/Group ${resData.message}`);
        }).catch(error => {
            showToaster(Intent.ERROR, "User/Group service not working!");
        });
    }

    pingPerm = () => {
        const { showToaster } = this.props;
        PermApi.ping().then(resData => {
            showToaster(Intent.SUCCESS, `Permission ${resData.message}`);
        }).catch(error => {
            showToaster(Intent.ERROR, "Permission service not working!");
        });
    }

    pingCore = () => {
        const { showToaster } = this.props;
        DatabaseApi.ping().then(resData => {
            showToaster(Intent.SUCCESS, `Core ${resData.message}`);
        }).catch(error => {
            showToaster(Intent.ERROR, "Core service not working!");
        });
    }

    testDb = () => {
        const { showToaster } = this.props;
        DatabaseApi.list().then((res) => {
            showToaster(Intent.SUCCESS, `Contacted Database`);
        });
    }

    toggleBadgesSetting = () => {
        const { setBadgeFetchFlag, fetchBadges, showToaster } = this.props;
        const newValue = !fetchBadges;
        setBadgeFetchFlag(newValue);
        const expiry = new Date();
        expiry.setDate(expiry.getDate() + 10000);
        
        UserApi.setUserState(REFRESH_BADGES_KEY, newValue.toString(), expiry)
            .then(() => {
                showToaster(Intent.SUCCESS, `${REFRESH_BADGES_KEY} set to ${newValue}`);
            })
    }

    // toggleTrace = () => {
    //     const trace = getPostTraceMessages();
    //     setPostTraceMessages(!trace);
    //     console.log(`Setting traceMessage to ${!trace}`);
    // }

    invalidworkflow = async () => {
        const result = await WorkflowApi.continueTask('mydb', 1234, 5678 );
        console.log(result);
    }

    render() {
        const { fetchBadges } = this.props;

        return (
            <MDMWrapper title="Debug Configuration">
                <Card className="settings-card">
                    <div className="flex-column flex-space-between fill">
                        <div className="flex-column setting-switch-container">
                            {
                                inSystemGroup() &&
                                <>
                                    <Switch
                                        checked={fetchBadges}
                                        className="bp3-align-right"
                                        labelElement={<span className="text-medium">Enable Badge Periodic Fetch</span>}
                                        onChange={this.toggleBadgesSetting}
                                    />

                                    <Divider className="debug-divider" />
                                </>
                            }

                            <div className="flex-column flex-center">
                                <Button
                                    className="minimal-button debug-button"
                                    minimal
                                    value="Test Core Service"
                                    onClick={this.pingCore}
                                />
                                <Button
                                    className="minimal-button debug-button"
                                    minimal
                                    value="Test User Service"
                                    onClick={this.pingUser}
                                />
                                <Button
                                    className="minimal-button debug-button"
                                    minimal
                                    value="Test Auth Service"
                                    onClick={this.pingAuth}
                                />
                                <Button
                                    className="minimal-button debug-button"
                                    minimal
                                    value="Test Permission Service"
                                    onClick={this.pingPerm}
                                />
                                <Button
                                    className="minimal-button debug-button"
                                    minimal
                                    value="Test Database Connection"
                                    onClick={this.testDb}
                                />
                            </div>

                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="flex-column flex-space-between fill">
                        <div className="flex-column flex-center">
                            {/* <Button
                                className="minimal-button debug-button"
                                minimal
                                value="Toggle traceMessage"
                                onClick={this.toggleTrace}
                            /> */}
                            <Button
                                className="minimal-button debug-button"
                                minimal
                                value="Continue Task 500 error Test"
                                onClick={this.invalidworkflow}
                            />
                            <Button
                                className="minimal-button debug-button"
                                minimal
                                value="Bad Db Create"
                                onClick={() => {
                                    const result = DatabaseApi.create("--  --", "bad name");
                                    console.log("Result = " + JSON.stringify(result));
                                }}
                            />
                        </div>
                    </div>
                </Card>
            </MDMWrapper>
        )
    }
}

export const DebugConfig = connect(
    (state: any) => ({
        fetchBadges: state.systemSettings.fetchBadges,
        tracePosts: state.systemSettings.tracePosts,
    }),
    {
        showToaster,
        setBadgeFetchFlag,
    },
)(DebugConfigBase);