import * as React from 'react';
import { Checkbox } from "@blueprintjs/core";
import { ConfirmDialog } from '../Shared/ConfirmDialog';
import { inSystemGroup } from '../../api';

export interface PermDetailsTabProps {
    group: any;
    type: string;
    deleteGroupFn: (name: string) => boolean;
    upsertGroupFn: (name: string, permissions: string[]) => boolean;
}

interface PermDetailsTabState {
    // Render using these values
    group: any;
    type: string;
    confirmDeleteModalOpen: boolean;
    confirmOverrideModalOpen: boolean;
}

export class PermDetailsTab extends React.Component<PermDetailsTabProps, PermDetailsTabState> {
    // set so Perms know if these are mine or childrens
    titleCount: number;
    updateInfo: { [prop: string]: boolean; };

    constructor(props) {
        super(props);
        this.titleCount = 0;
        this.updateInfo = {};
        this.state = {
            group: {...this.props.group},
            type: this.props.type,
            confirmDeleteModalOpen: false,
            confirmOverrideModalOpen: false,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        return { group: {...nextProps.group}, type: nextProps.type };
    }

    writeHeader = () => {
        ++this.titleCount;
        switch(this.titleCount) {
            case 1:
                return <h4>Permissions for this Entity</h4>;
            case 2:
                return <h4>Permissions for Children of this Entity</h4>;
            default:
                // all the remaining get no title
                return null;
        }
    }

    // events here
    inheritDeleteCanceled = () => {
        this.setState({ confirmDeleteModalOpen: false});
    }

    inheritDeleteOk = () => {
        this.setState({ confirmDeleteModalOpen: false});
        this.props.deleteGroupFn(this.props.group.group);
    }

    inheritOverrideCanceled = () => {
        this.setState({ confirmOverrideModalOpen: false});
    }

    inheritOverrideOk = () => {
        this.setState({ confirmOverrideModalOpen: false});
        const perms: string[] = Object.keys(this.updateInfo);
        this.props.upsertGroupFn(this.props.group.group, perms);
    }

    updateInherited = () => {
        // logic is inverted because we just clicked on it
        // Was inherited.  Better make it real
        if(this.state.group.inherited) {
            this.setState( { confirmOverrideModalOpen: true });
        }
        // This code should never run since we disable it
        else {
            // Now its inherited. Ask user if they really meant it
            this.setState( { confirmDeleteModalOpen: true });
        }
    }

    updateGroup = (field: string) => {
        // need to reflect the change that occurred when the user clicked on the checkbox
        // before sending the current permission set to the group updater
        if(this.updateInfo[field]) {
            delete this.updateInfo[field];
        }
        else {
            this.updateInfo[field] = true;
        }

        const perms: string[] = Object.keys(this.updateInfo);
        this.props.upsertGroupFn(this.props.group.group, perms);
        //this.updateInterval = setTimeout( this.reallyUpdate, 5000 );
    }

    onePerm = (name: string) => {
        const checked = this.state.group.perms.indexOf(name) >= 0;
        const disabled = this.state.group.inherited;

        if(checked) {
            this.updateInfo[name] = true;
        }
        return <Checkbox checked={checked} disabled={disabled} onChange={ () => { this.updateGroup(name); } } >
            {name}
        </Checkbox>;
    }


    inherited = () => {
        if(this.state.type !== 'system') {
            const name=this.props.group.group;
            return <div>
                {this.state.confirmDeleteModalOpen &&
                <ConfirmDialog
                    buttonName="Delete"
                    body={ `You are about to delete the permission group ${name}
                    This has significant consequences. Are you sure you want to delete it?` }
                    title={`Delete Group ${name}`}
                    cancelClick={this.inheritDeleteCanceled}
                    okClick={this.inheritDeleteOk}
                /> }

                {this.state.confirmOverrideModalOpen &&
                <ConfirmDialog
                    buttonName="Override"
                    body={`Are you sure you want to override permissions for ${name}?`}
                    title={`Override Group ${name}`}
                    cancelClick={this.inheritOverrideCanceled}
                    okClick={this.inheritOverrideOk}
                /> }
                <Checkbox checked={!this.state.group.inherited} disabled={!inSystemGroup()} onChange={() => {this.updateInherited();} } >
                    Override Inherited Permissions
                </Checkbox>
            </div>;
        }
        return undefined;
    }

    systemPerms = () => {
        if(this.state.type === 'system') {
            return <div>
                {this.writeHeader()}
                <h6>System</h6>
                {this.onePerm('create_database')}
            </div>;
        }
        return undefined;
    }

    databasePerms = () => {
        if(this.state.type === 'system' || this.state.type === 'database' ) {
            return <div>
                {this.writeHeader()}
                <h6>Database</h6>
                {this.onePerm('delete_database')}
                {this.onePerm('create_table')}
                {this.onePerm('query_table')}
                {this.onePerm('create_changeset')}
                {this.onePerm('query_changeset')}
                {this.onePerm('create_process')}
                {this.onePerm('query_process')}
                {this.onePerm('create_view')}
                {this.onePerm('query_view')}
            </div>;
        }
        return undefined;
    }

    tablePerms = () => {
        switch(this.state.type) {
        case 'system':
        case 'database':
        case 'table':
            return <div>
                {this.writeHeader()}
                <h6>Table</h6>
                {this.onePerm('read_table')}
                {this.onePerm('modify_table')}
                {this.onePerm('delete_table')}
                {this.onePerm('query_record')}
                {this.onePerm('create_record')}
                {this.onePerm('modify_record')}
                {this.onePerm('delete_record')}
                {this.onePerm('mask_record')}
                {this.onePerm('purge_record')}
            </div>;
        default:
            return undefined;
        }
    }

    // Column here { query_column, modify_column }

    changesetPerms = () => {
        if(this.state.type === 'system' || this.state.type === 'database' ) {
            return <div>
                {this.writeHeader()}
                <h6>Changeset</h6>
                {this.onePerm('query_operation')}
                {this.onePerm('undo_operation')}
                {this.onePerm('commit_changeset')}
                {this.onePerm('discard_changeset')}
                {this.onePerm('manage_conflict')}
            </div>;
        }
        return undefined;
    }

    // export here { read_export, modify_export, delete_export }

    processPerms = () => {
        switch(this.state.type) {
        case 'system':
        case 'database':
        case 'process':
            return <div>
                {this.writeHeader()}
                <h6>Process</h6>
                {this.onePerm('read_process')}
                {this.onePerm('modify_process')}
                {this.onePerm('delete_process')}
                {this.onePerm('create_workflow')}
                {this.onePerm('query_workflow')}
            </div>;
        default:
            return undefined;
        }
    }

    workflowPerms = () => {
        switch(this.state.type) {
        case 'system':
        case 'database':
        case 'process':
        case 'workflow':
            return <div>
                {this.writeHeader()}
                <h6>Workflow</h6>
                {this.onePerm('read_workflow')}
                {this.onePerm('modify_workflow')}
                {this.onePerm('delete_workflow')}
            </div>;
        default:
            return undefined;
        }
    }

    viewPerms = () => {
        switch(this.state.type) {
        case 'system':
        case 'database':
        case 'view':
            return <div>
                {this.writeHeader()}
                <h6>View</h6>
                {this.onePerm('read_view')}
                {this.onePerm('modify_view')}
                {this.onePerm('delete_view')}
                {this.onePerm('create_subscription')}
                {this.onePerm('query_subscription')}
            </div>;
        default:
            return undefined;
        }
    }

    subscriptionPerms = () => {
        switch(this.state.type) {
        case 'system':
        case 'database':
        case 'view':
        case 'subscription':
            return <div>
                {this.writeHeader()}
                <h6>Subscription</h6>
                {this.onePerm('read_subscription')}
                {this.onePerm('modify_subscription')}
                {this.onePerm('delete_subscription')}
            </div>;
        default:
            return undefined;
        }
    }

    // Future dev:  column, export permissions
    render() {
        if(this.props.group.group === '+') {
            return <div>Select a Group</div>;
        }

        this.titleCount = 0;
        this.updateInfo = {};

        return (<div>
            {this.inherited()}
            {this.systemPerms()}
            {this.databasePerms()}
            {this.tablePerms()}
            {this.changesetPerms()}
            {this.processPerms()}
            {this.workflowPerms()}
            {this.viewPerms()}
            {this.subscriptionPerms()}
        </div>);
    }
}