import { AppThunk } from '../MDMStore';
import { PermApi } from 'api';
import { SET_PERMISSIONS_LOADING, SET_PERMISSIONS } from 'store/user/types';

export const loadPermissions = (dbName: string): AppThunk => {
    return dispatch => {
        dispatch({ type: SET_PERMISSIONS_LOADING });
        PermApi.QueryEffective({ type: "database", entity: dbName })
            .then((result: any) => {
                dispatch({ type: SET_PERMISSIONS, payload: { permissions: result.groups } });
            })
            .catch(err => {
                console.error(err);
                dispatch({ type: SET_PERMISSIONS, payload: { permissions: [] } });
            });
    };
};
