import * as React from 'react';
import { Icon, Intent, Position, Tooltip } from '@blueprintjs/core';

import { Button } from 'components/Shared/Button';
import { WFNotesTable } from 'components/NotesTable/WFNotesTable';
import { History, Workflow } from 'types';
import { WorkflowWidgets } from '../WorkflowWidgets';

require('helpers/styles/workflow.scss');
require('./WorkflowNote.scss');

export interface WorkflowNoteFormProps {
    history: History[];
    workflow: Workflow;
    completeTask: (button:HTMLButtonElement, note: string) => void;
    discardTask: (button:HTMLButtonElement, note: string) => void;
    saveTask: (button:HTMLButtonElement, note: string) => void;
}
export interface WorkflowNoteFormState {
    note: string;
}

export class WorkflowNoteForm extends React.Component<WorkflowNoteFormProps, WorkflowNoteFormState> {
    constructor(props: WorkflowNoteFormProps) {
        super(props);
        this.state = {
            note: ''
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: WorkflowNoteFormProps, prevState) {
    }

    private onNoteChange = (newNote: string) => {
        this.setState({ note: newNote });
    }

    private completeTask = (button) => {
        this.props.completeTask(button, this.state.note);
    }

    private discardTask = (button:HTMLButtonElement) => {
        this.props.discardTask(button, this.state.note);
    }

    private saveTask = (button:HTMLButtonElement) => {
        this.props.saveTask(button, this.state.note);
        // Reset the note to empty. Doesn't make sense to leave it.
        this.setState({ note: '' });
    }

    render() {
        const { workflow } = this.props;

        return (
            <div className="workflow-wrapper">
                <div className="workflow-menu-wrapper">
                    <div className="workflow-menu">
                        <div className="workflow-title">
                            Send a Note
                            <Tooltip content="Documentation" position={Position.RIGHT}>
                                <div className="mdm-wrapper-docs-button flex-center">
                                    <Icon icon='help' onClick={() => { window.open(`/mdm-help/workflow.html`, 'mdm-help'); }} color="#636567" iconSize={20} />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="workflow-title-buttons">
                            <Button value="SAVE" onClick={(e) => this.saveTask(e.currentTarget)} />
                            <Button value="DISCARD" intent={Intent.DANGER} onClick={(e) => this.discardTask(e.currentTarget)} />
                            <Button value="SUBMIT" intent={Intent.SUCCESS} onClick={(e) => this.completeTask(e.currentTarget)} />
                        </div>
                    </div>
                    <WorkflowWidgets tableName={workflow.tableName} workitemId={workflow.workitem_id} />
                </div>
                <WFNotesTable history={this.props.history} note={this.state.note} updateNote={this.onNoteChange} sortForward={true} />
            </div>
        );
    }
}