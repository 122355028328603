import * as React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { inSystemGroup } from '../../api/inRoles'
import { OverviewStat } from './OverviewStat';
import { WorkflowApi } from 'api';
import { Workitem } from 'types';
import './Overview.scss';
import { useQuery } from '@tanstack/react-query';

interface OverviewProps {
    permissions?: string[];
    permissionsLoading: boolean;
}

export const Overview: React.FC<any> = (props: OverviewProps) => {
    const { permissions, permissionsLoading } = props;
    const allowedToQuery = !permissionsLoading && permissions && permissions.includes('query_workflow') || inSystemGroup()
    const database = useSelector((state: any) => state.database.selected);
    const username = useSelector((state: any) => state.username);
    const { data: assigned } = useQuery(
        [`assigned-workitems-count`],
        async () => {
            try {
                const res: Workitem[] = await WorkflowApi.getAssignedWorkflows(database, username);
        
                return res?.length ?? 0;
            } catch (error) {
                console.error(error);
                return 0;
            }
        },
        {
            enabled: !!database && !!username,
            initialData: 0
        }
    );
    const { data: startedByMe } = useQuery(
        [`started-workitems-count`],
        async () => {
            try {
                const res: Workitem[] = await WorkflowApi.getStartedWorkflows(database, username);
        
                return res?.length ?? 0;
            } catch (error) {
                console.error(error);
                return 0;
            }
        },
        {
            enabled: !!allowedToQuery && !!database && !!username,
            initialData: 0
        }
    );

    const { data: activeWorkitems } = useQuery(
        [`active-workitems-count`],
        async () => {
            try {
                const res: Workitem[] = await WorkflowApi.queryTasks(database,  { finished: false }, { variables: true }, false);
        
                return res?.length ?? 0;
            } catch (error) {
                console.error(error);
                return 0;
            }
        },
        {
            enabled: !!allowedToQuery && !!database,
            initialData: 0
        }
    );

    return (
        <div className="overview-container" >
            {
                allowedToQuery &&
                <div className="overview-stats">
                    <div className="overview-title">Here are some workitem statistics:</div>
                    <div className="overview-stat-holder">
                        <OverviewStat amount={assigned} label1='Assigned' label2='to you' />
                        <OverviewStat amount={startedByMe} label1='Workflows Started' label2='by you' />
                        <OverviewStat amount={activeWorkitems} label1='Active' label2='Workitems' />
                    </div>
                </div>
            }
        </div>
    );
};
