import { Icon, Intent, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react'

const RecordHistoryValueArray = ({ data = [] }) => {
    const [keys, setKeys] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    

    React.useEffect(() => {
        const [firstItem] = data;
        const keys = Object.keys(firstItem).slice(0, 3);

        setKeys(keys);
    }, [data])

    const togglePopOver = () => setOpen(v => !v);

    const renderContent: () => JSX.Element = () => (
        <div className="ReactTable" style={{ padding: 5, width: 400 }}>
            <table className="rt-table">
                <thead className="rt-thead">
                    <tr className="rt-tr">
                        {keys.map(key => (
                            <th key={`key-header-${key}`} className="rt-th">{key}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="rt-tbody">
                    {data.map((d, i) => (
                        <tr key={`key-row-${i}`} className="rt-tr">
                            {keys.map(key => (
                                <td key={`key-item-${key}`}  className="rt-td">{d[key]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    if (!data || !data.length) return null;

    return (
        <div>
            {data.length} record(s)&nbsp;
            <Popover isOpen={open} content={renderContent()} >
                <Icon onClick={togglePopOver} icon={IconNames.EYE_OPEN} intent={Intent.PRIMARY} />
            </Popover>
        </div>
    )
}

export default RecordHistoryValueArray