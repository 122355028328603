import { STORE_REHYDRATED } from '../constants';

export const storerehydrated = (state = false, action) => {
    switch (action.type) {
        case STORE_REHYDRATED:
            return true;
        default:
            return state;
    }
};
