import { createSelector } from 'reselect';
import { Field, MatchWorkflow, ReviewWorkflow, Workflow } from 'types';

const emptyWorkflow: Workflow = {
    changesetId: '',
    continue: false,
    databaseName: '',
    formId: '',
    node_id: '',
    schema: { primary_key: '', type: null, fields: new Array<Field>() },
    tableName: '',
    workitem_id: 0,
    variables: {},
};

const emptyReviewWorkflow: ReviewWorkflow = {
    record: {},
    oldRecord: {},
    workflow: emptyWorkflow,
};

const emptyMatchWorkflow: MatchWorkflow = {
    records: [],
    acceptedGroups: [],
    proposedGroups: [],
    workflow: emptyWorkflow
};

const workflowSelector = state => state.workflow.taskActionResult;

export const getWorkflow = (payload): Workflow => {
    return {
        changesetId: payload.variables.changeset,
        continue: payload.continue,
        databaseName: payload.database,
        formId: payload.form,
        node_id: payload.node_id,
        schema: payload.schema,
        tableName: payload.variables.table,
        workitem_id: payload.workitem_id,
        variables: payload.variables,
    };
};

export const getCurrentWorkflow = createSelector(workflowSelector, (payload): Workflow => {
    if (!payload) return emptyWorkflow;
    return getWorkflow(payload);
});

export const getReviewWorkflow = createSelector(workflowSelector, (payload): ReviewWorkflow => {
    if (!payload) return emptyReviewWorkflow;

    const workflow: ReviewWorkflow = {
        record: payload.record,
        oldRecord: payload.old_record,
        workflow: getWorkflow(payload),
    };

    return workflow;
});

export const getMatchWorkflow = createSelector(workflowSelector, (payload): MatchWorkflow => {
    if (!payload) return emptyMatchWorkflow;
    return {
        workflow: getWorkflow(payload),
        records: payload.records,
        acceptedGroups: payload.accepted_match_groups,
        proposedGroups: payload.proposed_match_groups,
    };
});
