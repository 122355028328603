/**
 * Created by nbamford on 1/29/2017.
 */
import { connect } from 'react-redux';
import * as React from 'react';
import { Intent } from '@blueprintjs/core';

import { Button } from '../../Shared';
import { completeTask, discardAssignedTask as discardTask } from '../../../store/workflow/actions';
import { getCurrentWorkflow } from '../../../store/workflow/selectors';
import { QueryRecordsContainer } from '../../SelectRecords/QueryRecordsContainer';

import { Workflow } from 'types';

interface WorkflowFindRecordContainerProps {
    workflow: Workflow;
    completeTask: (workflow: Workflow, history: any, button:HTMLButtonElement, recordId: string) => void;
    discardTask: (workflow: Workflow, button:HTMLButtonElement) => void;
    history: any;
}
interface WorkflowFindRecordContainerState {
    selectedRecordId: string;
}
class WorkflowFindRecordContainerBase extends React.Component<WorkflowFindRecordContainerProps, WorkflowFindRecordContainerState> {
    constructor(props) {
        super(props);
        this.state = { selectedRecordId: '' };
    }

    setSelected = (recordId) => {
        this.setState({ selectedRecordId: recordId });
    }
    completeTask(button:HTMLButtonElement, id:string) {
        this.props.completeTask(this.props.workflow, this.props.history, button, id);
    }
    discardTask = (button:HTMLButtonElement) => {
        this.props.discardTask(this.props.workflow, button);
    }

    render() {
        const QRC:any = QueryRecordsContainer;
        const workflow = this.props.workflow;
        const nextDisabled = this.state.selectedRecordId.length === 0;
        const buttons = [
            <Button key="nextbtn" value="Next" disabled={nextDisabled} intent={nextDisabled ? Intent.PRIMARY : Intent.SUCCESS} 
                onClick={(event) => this.completeTask(event.currentTarget, this.state.selectedRecordId)} />,
            <Button key="discardbtn" value="Discard" intent={Intent.DANGER} onClick={(event) => this.discardTask(event.currentTarget)} />
        ];
        return (
            <div>
                <QRC buttons={buttons} workflow={workflow} title="Choose Record to Edit" match={false} tooltipText={"Double-click to edit record"}
                    setSelected={this.setSelected} doubleClickCb={(id) => this.completeTask(null, id)} />
            </div>
        );
    }
}
export const WorkflowFindRecordContainer = connect(
    state => ({ workflow: getCurrentWorkflow(state) }),
    {
        completeTask, discardTask
    })(WorkflowFindRecordContainerBase);
