
import * as React from 'react';
import { Checkbox, Classes, Divider, FormGroup, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from 'components/Shared';
import { displayField } from '../../../utils';
import { MatchGroupContainer } from './WorkflowMatchGroup/WorkflowMatchGroupContainer';
import { NotesTable } from 'components/NotesTable/NotesTable';
import { WorkflowWidgets } from '../WorkflowWidgets'
import { MatchRecord } from './WorkflowMatchConstants';
import { FilterField, History, MDMRecord, Schema, Workflow } from 'types';
//import { THUMBS_DOWN } from '@blueprintjs/icons/lib/esm/generated/iconNames';


export interface WorkflowApproveMatchFormProps {
    completeTask: (groups: string[][], note: string) => void;
    discardTask: (note: string) => void;
    rejectTask: (note: string) => void;
    history: History[];
    matchRecords: object;
    schema: Schema;
    workflow: Workflow;
}
export interface WorkflowApproveMatchFormState {
    customRecords: MatchRecord[];
    note: string;
}

export class WorkflowApproveMatchForm extends React.PureComponent<WorkflowApproveMatchFormProps, WorkflowApproveMatchFormState> {

    hiddenFields: string[];

    constructor(props: WorkflowApproveMatchFormProps) {
        super(props);
        this.hiddenFields = ['_id', '_group'];
        this.state = { customRecords: this.makeCustomRecords(props.workflow, props.matchRecords), note: '' };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.matchRecords !== this.props.matchRecords) {
            this.setState({ customRecords: this.makeCustomRecords(this.props.workflow, this.props.matchRecords) });
        }
    }

    needField(fieldName: string, primary_key: string) {
        return fieldName === primary_key || this.hiddenFields.indexOf(fieldName) > -1;
    }


    // Map the records to only contain display fields or tables with displayable fields or primary_key
    // The caller is responsible for setting _id and _group

    makeCustomRecords = (workflow: Workflow, records: object): MatchRecord[] => {
        const primaryKey = workflow.schema.primary_key;

        const matchRecords = Object.values(records).map(rec => {
            return ({
                _id: rec._id, _group: rec._group,
                ...workflow.schema.fields.reduce((acc, cur) => {
                    return ( displayField(cur) || cur.field === primaryKey ) ? { ...acc, [cur.field]: rec[cur.field] } : acc;
                }, {})
            });
        });

        return (matchRecords as any);
    }

    setRecordCustomGroup = (recordId, newGroupId) => {
        // const primaryKey = this.props.workflow.schema.primary_key;

        // const records = this.state.customRecords.map(r => r[primaryKey].toString() === recordId.toString() ?
        //     { ...r, _group: newGroupId } : r);
        // this.setState({ customRecords: records });
        console.error("Form should not allow record dragging");
    }

    // Turn the records custom groups into a map from group # to an array of record primary keys,
    // then return the values (an array of group arrays containing primary keys)
    buildCustomGroups = (): string[][] => {
        const primaryKey = this.props.workflow.schema.primary_key;
        const groups = this.state.customRecords.reduce((acc, cur) => {
            const group = cur._group as number;
            acc[group] = acc.hasOwnProperty(group) ? [...acc[group], cur[primaryKey]] : [cur[primaryKey]];
            return acc;
        }, {});
        return Object.values(groups);
    }

    completeTask = () => this.props.completeTask(this.buildCustomGroups(), this.state.note);
    discardTask = () => this.props.discardTask(this.state.note);
    rejectTask = () => this.props.rejectTask(this.state.note);
    onNoteChange = (newNote: string) => this.setState({ note: newNote });

    render() {
        const { workflow } = this.props;

        return (
            <div className="workflow-match-wrapper">
                <div className="workflow-match-menu-wrapper">
                    <div className="workflow-menu">
                        <div className="workflow-title">Approve Match</div>
                        <div className="workflow-title-buttons">

                        <Button value="DISCARD" key="workflowdiscard" onClick={this.discardTask} />
                        {/*  I have removed this option. While it "works" it is not useful unless we preserve more information
                         for the returned workitem [ records, groups, maybe original search results ]
                        <Button value="REJECT" key="workflowreject" intent={Intent.DANGER} onClick={this.rejectTask} /> */}
                        <Button value="SUBMIT" key="workflowsubmit" intent={Intent.SUCCESS} onClick={this.completeTask} />
                        </div>
                    </div >

                    <WorkflowWidgets tableName={workflow.tableName} workitemId={workflow.workitem_id} />
                </div>
                <div className="workflow-match">
                    <MatchGroupContainer
                        rowsDraggable={false}
                        records={this.state.customRecords}
                        schema={this.props.workflow.schema}
                        hiddenFields={this.hiddenFields}
                        setCustomGroup={this.setRecordCustomGroup} />
                    <NotesTable history={this.props.history || []} note={this.state.note} updateNote={this.onNoteChange} />
                </div>
            </div>
        );
    }
}