/**
 * @format
 */
import * as React from 'react';
import { Button as BPButton, Classes, FormGroup, IDialogProps, Dialog, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { DatePicker } from '@blueprintjs/datetime';
import { Suggest } from '@blueprintjs/select';
import { connect } from 'react-redux';
import * as moment from 'moment'
import SelectSearch, { fuzzySearch } from 'react-select-search';

import { Button } from '../../Shared';
import { UserApi, WorkflowApi } from 'api';
import { setAssignedWorkflows, setAvailableWorkflows } from 'store/workflow/actions';
import { showToaster } from 'actions';
import { User, Users, Workflow, Workitem, Intent } from 'types';

import '../../../helpers/styles/select-search.scss';

export interface WorkitemEditModalProps {
    dbName: string;
    username: string;

    isOpen: boolean;
    workitem: Workitem;
    onClose: () => void;

    showToaster?: showToaster;
    setAssignedWorkflows: (workflows: Workflow[]) => void;
    setAvailableWorkflows: (workflows: Workflow[]) => void;
    saveCallback?: () => void;
}

interface DropdownUser extends User {
    name: string;
    value: string;
}

export interface WorkitemEditModalState {
    assignedUser: string,
    selectedDate: Date,
    users: DropdownUser[],
    popoverOpen: boolean,
    loadingUsers: boolean,
    editedAssignee: boolean,
    editedDueDate: boolean,
}

export class WorkitemEditModalBase extends React.Component<
    WorkitemEditModalProps & IDialogProps,
    WorkitemEditModalState
> {
    constructor(props) {
        super(props);

        this.state = {
            assignedUser: null,
            popoverOpen: false,
            selectedDate: props.workitem?.due_date ?
                new Date(props.workitem.due_date) :
                null,
            users: [],
            loadingUsers: true,
            editedAssignee: false,
            editedDueDate: false
        };
    }

    componentDidMount() {
        UserApi.getAll()
            .then((users: Users) => {
                if (users) {
                    const parsedUsers = users
                        .map(user => ({ ...user, name: user.username, value: user.username }))
                        .sort((a, b) => {
                            if (a.username < b.username)
                                return -1;
                            if (a.username > b.username)
                                return 1;
                            return 0;
                        })
                    this.setState({ users: parsedUsers, loadingUsers: false });
                } else {
                    this.setState({ users: [], loadingUsers: false });
                }
            }).catch(err => {
                console.error("Error getting users in workitem edit modal", err);
                this.setState({ loadingUsers: false });
            });
    }

    componentDidUpdate(prevProps) {
        const { workitem } = this.props;
        const { users } = this.state;

        if (workitem && prevProps.workitem?.assigned_user !== workitem.assigned_user) {
            const currentUser = users.find(user => user.username === workitem.assigned_user);
            if (currentUser) {
                this.setState({ assignedUser: currentUser.username });
            }
        }

        if (workitem && prevProps.workitem?.due_date?.toString() !== workitem.due_date?.toString()) {
            this.setState({ selectedDate: new Date(workitem.due_date) });
        } else if (!workitem?.due_date && prevProps?.workitem?.due_date) {
            this.setState({ selectedDate: null });
        }
    }

    private onClose = () => {
        this.setState({ assignedUser: null, selectedDate: null, editedAssignee: false, editedDueDate: false, popoverOpen: false });
    }

    handleDateChange = (date: Date) => this.setState({ selectedDate: date, editedDueDate: true });

    updateAssignedAvailable = async () => {
        const { dbName, username, setAssignedWorkflows, setAvailableWorkflows } = this.props;
        const assignedWorkflows = await WorkflowApi.getAssignedWorkflows(dbName, username);
        const availableWorkflows = await WorkflowApi.getAvailableWorkflows(dbName, username);

        setAssignedWorkflows(assignedWorkflows);
        setAvailableWorkflows(availableWorkflows);
    }

    handleSave = async () => {
        const { workitem, onClose, showToaster, saveCallback } = this.props;
        const { selectedDate, assignedUser } = this.state;
        const { node_id, workitem_id, database } = this.props.workitem

        let setDateFailed = false;
        let setAssignedUserFailed = false;

        // We might want to round everything for our comparions
        // if (abs(diff) <  60 * 1000) { ... } /* less than a minute */
        if (selectedDate && selectedDate.valueOf() != new Date(workitem.due_date).valueOf()) {
            try {
                // Call setDueDate on Workitem.
                await WorkflowApi.updateDueDate(database, workitem_id, selectedDate);
                const datePortion = moment(selectedDate).format('MMM DD');
                const timePortion = moment(selectedDate).format('hh:mm a');
                showToaster(Intent.INFO, `Workitem (${workitem.workitem_id}) due ${datePortion} at ${timePortion}.`);
            } catch (err) {
                console.error("Error updating due date", err);
                setDateFailed = true;
            }
        }

        if (assignedUser !== workitem.assigned_user) {
            try {
                // Call assign on the target user, if it changed from the original workitem's assigned user.
                await WorkflowApi.assignTask(database, workitem_id, node_id, assignedUser);
                showToaster(Intent.INFO, `Workitem (${workitem.workitem_id}) assigned to ${assignedUser}.`);
            } catch (err) {
                showToaster(Intent.ERROR, `Could not assign workitem to ${assignedUser}.`);
                setAssignedUserFailed = true;
            }
        }

        if (!setDateFailed && !setAssignedUserFailed) {
            onClose();
            this.updateAssignedAvailable();
            if (saveCallback) {
                saveCallback();
            }
        }
    }

    public render() {
        const { isOpen, onClose, workitem } = this.props;
        const { assignedUser, editedAssignee, editedDueDate, users, popoverOpen } = this.state;

        return (
            <Dialog
                autoFocus={false}
                enforceFocus={false}
                isCloseButtonShown={false}
                canOutsideClickClose={false}
                isOpen={isOpen}
                onClose={e => this.onClose()}
                title={`Edit Workitem ${workitem?.workitem_id}`}
            >
                {
                    workitem && (
                        <div className={Classes.DIALOG_BODY}>
                            <FormGroup
                                label="Assigned To"
                                labelFor="assigned-to-input"
                                inline={true}
                                className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                                contentClassName="user-dialog-input"
                            >
                                <SelectSearch
                                    autoFocus={false}
                                    enforceFocus={false}
                                    options={users}
                                    search
                                    emptyMessage="No results"
                                    value={assignedUser}
                                    onChange={(selectedVal) => {
                                        this.setState({ assignedUser: selectedVal.toString(), editedAssignee: true })
                                    }}
                                    filterOptions={fuzzySearch}
                                    placeholder="Choose a user to assign."
                                />
                            </FormGroup>

                            <FormGroup
                                label="Due Date"
                                labelFor="due-date-input"
                                inline={true}
                                className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                                contentClassName="user-dialog-input"
                            >
                                <DatePicker
                                    className="flex-center"
                                    onChange={newDate => this.handleDateChange(newDate)}
                                    highlightCurrentDay
                                    value={this.state.selectedDate}
                                    includeTime
                                    timePrecision="minute"
                                />
                            </FormGroup>
                        </div>
                    )
                }
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            disabled={!editedAssignee && !editedDueDate}
                            id="user-password-save-btn"
                            icon={IconNames.FLOPPY_DISK}
                            onClick={this.handleSave}
                            value="Save"
                        />
                        <Button icon={IconNames.CROSS} onClick={onClose} intent={'danger'} value="Cancel" />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const WorkitemEditModal: any = connect(
    (state: any) => ({
        dbName: state.database.selected,
        username: state.username,
    }), {
    showToaster,
    setAssignedWorkflows,
    setAvailableWorkflows
})(WorkitemEditModalBase as any);