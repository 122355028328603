import { AuthUrl } from '../proxies/ajax/AuthUrl';
import { axiosPost, axiosGet } from '../helpers/axiosUtils';
import { displayError } from './displayError';
import { AuthConfig } from 'store/auth/types';

export class AuthApi {
    static getVersion() {
        return axiosGet(AuthUrl.version);
    }

    static ping() {
        return axiosGet(AuthUrl.ping);
    }

    static setPassword(username: string, password: string) {
        const body = { payload: { username, password } };
        return axiosPost(AuthUrl.setPassword, body).then(() => true);
    }

    static verifyToken(username: string) {
        return axiosPost(AuthUrl.validateToken, {}, 'username').then(responseUsername => {
            if (!responseUsername || username !== responseUsername)
                throw new Error('invalid username');
            return true;
        });
    }

    static getConfig(): Promise<AuthConfig> {
        return axiosPost(AuthUrl.getConfig, {}).then(response => response);
    }
}
