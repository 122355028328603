import { SET_CHANGESET, RESET_CHANGESET } from '../constants';

const initialState = {
    changeset: 'current'
}

export const changeset = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHANGESET:
            return action.changeset;
        case RESET_CHANGESET:
            return initialState;
        default:
            return state;
    }
};