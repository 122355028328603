
import { AuthorizedRoles, RoleEnum } from '../../constants';
import { CustomIconName } from '../../types';

export interface Path {
    name: string;
    path: string;
    icon: CustomIconName;
    authorizedRoles?: RoleEnum[];
}

export interface MenuSection {
    title?: string;
    icon?: CustomIconName;
    paths: Path[];
}

export type Menu = MenuSection[];

export const generateMenu = (): Menu => [
    {
        paths: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                icon: CustomIconName.DASHBOARD
            },
            {
                path: '/logs',
                name: 'Logs',
                icon: CustomIconName.LOGS,
                authorizedRoles: AuthorizedRoles.management
            }
        ],
    },
    {
        title: 'Workflow',
        icon: CustomIconName.SWAP,
        paths: [
            {
                path: '/wf/start',
                name: 'Start New',
                icon: CustomIconName.PLUS,
                authorizedRoles: AuthorizedRoles.workflow
            },
            {
                path: '/wf/search',
                name: 'Workitem Search',
                icon: CustomIconName.NEW_DOCUMENT,
                authorizedRoles: AuthorizedRoles.workflow,
            },
            {
                path: '/wf/assigned',
                name: 'Assigned to Me',
                icon: CustomIconName.LAYER,
                authorizedRoles: AuthorizedRoles.workflow
            },
            {
                path: '/wf/available',
                name: 'Available',
                icon: CustomIconName.LAYERS,
                authorizedRoles: AuthorizedRoles.workflow
            },
            // Hide these but allow navigation to them
            // {
            //     path: '/wf/workfloweditor',
            //     name: 'Workflow Editor',
            //     icon: CustomIconName.FLOW_ARROW,
            //     authorizedRoles: AuthorizedRoles.management
            // },
            // {
            //     path: '/wf/processeditor',
            //     name: 'Process Editor',
            //     icon: CustomIconName.PROCESS,
            //     authorizedRoles: AuthorizedRoles.management
            // },
        ],
    },
    {
        title: 'Data Hub',
        icon: CustomIconName.GRAPH,
        paths: [
            {
                path: '/databases',
                name: 'Databases',
                icon: CustomIconName.DATABASE,
                authorizedRoles: AuthorizedRoles.steward
            },
            {
                path: '/tables',
                name: 'Tables',
                icon: CustomIconName.TABLE,
                authorizedRoles: AuthorizedRoles.steward
            },
            {
                path: '/records',
                name: 'Data',
                icon: CustomIconName.STACKED_CHART,
                authorizedRoles: AuthorizedRoles.steward
            },
            {
                path: '/changesets',
                name: 'Changesets',
                icon: CustomIconName.EXCHANGE,
                authorizedRoles: AuthorizedRoles.steward
            },
            {
                path: '/views',
                name: 'Views',
                icon: CustomIconName.EYE_OPEN,
                authorizedRoles: AuthorizedRoles.steward,
            },
        ],
    },
    {
        title: 'Compliance',
        icon: CustomIconName.BADGE,
        paths: [
            {
                path: '/erasure',
                name: 'Erasure',
                icon: CustomIconName.ERASER,
                authorizedRoles: AuthorizedRoles.compliance,
            },
            {
                path: '/verify',
                name: 'Verify Erasure',
                icon: CustomIconName.TICK_ERASER,
                authorizedRoles: AuthorizedRoles.compliance,
            },
        ],
    },
    {
        title: 'Administration',
        icon: CustomIconName.COG,
        paths: [
            {
                path: '/users',
                name: 'Users',
                icon: CustomIconName.PERSON,
            },
            {
                path: '/groups',
                name: 'Groups',
                icon: CustomIconName.PEOPLE,
                authorizedRoles: AuthorizedRoles.management
            },
            {
                path: '/permissions',
                name: 'Permissions',
                icon: CustomIconName.KEY,
                authorizedRoles: AuthorizedRoles.management
            },
        ],
    },
];
