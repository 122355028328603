import * as React from 'react';
import * as copy from 'copy-to-clipboard';
import { Collapse, Classes, Dialog, Intent, IconName } from '@blueprintjs/core';
import { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { IconNames } from '@blueprintjs/icons';

import { Button } from 'components/Shared';
import { displayMessageBox } from 'actions/ActionCreators';
import { noop } from 'utils';
import { Severity } from 'types';
import { ErrorMessageEnum } from 'types/errorMessages';
import { MDMStore } from 'store';
import { logout } from 'actions';

require('components/MessageBox/MessageBox.scss');

export interface MessageBoxProps {
    renderBody?: () => React.ReactNode | React.ReactNode[];
    renderButtons?: () => React.ReactNode | React.ReactNode[];
    onClose?: (e?: React.SyntheticEvent<MouseEvent<HTMLElement>>) => void;
    message?: string;
    show?: boolean;
    stack?: string;
    title?: string;
    severity?: Severity;
    logout?: logout;
}

export interface MessageBoxState {
    isOpen: boolean;
    isCollapseOpen: boolean;
    forceLogout: boolean;
}

export class MessageBoxBase extends React.PureComponent<MessageBoxProps, MessageBoxState> {
    private stack: HTMLElement;

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isCollapseOpen: false,
            forceLogout: this.logoutRequiredMessage(props.message)
        };
    }

    logoutRequiredMessage = (message: string) => {
        return message &&
            (
                message.includes(ErrorMessageEnum.MDM_WEBSERVICE_RESTARTED) ||
                message.includes(ErrorMessageEnum.MDM_WEBSERVICE_RESTARTED_USERPROXY)
            )
    }

    public componentDidMount() {
        this.setState({ isOpen: this.props.show });
    }

    componentDidUpdate(prevProps) {
        const { message } = this.props;
        if (this.props.message != prevProps.message) {
            this.setState({ forceLogout: this.logoutRequiredMessage(message) })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.isOpen !== nextProps.show)
            return { isOpen: nextProps.show }

        return null;
    }

    private copyToClipboard = e => {
        copy(this.props.stack);
    }

    renderStack = () => {
        const { stack } = this.props;
        if (!stack) {
            return null;
        }
        return (
            <div className='messagebox-stacktrace'>
                <Button intent={Intent.NONE} icon={this.state.isCollapseOpen ? IconNames.SYMBOL_TRIANGLE_UP : IconNames.SYMBOL_TRIANGLE_DOWN} value={
                    `${this.state.isCollapseOpen ? 'Hide' : 'Show'} Additional Information`
                } onClick={this.toggleCollapse} />
                <Collapse isOpen={this.state.isCollapseOpen} className={this.state.isCollapseOpen ? 'messagebox-stack messagebox-stack-open' : 'messagebox-stack'}>
                    <span ref={stack => this.stack = stack} className='stack-copyable'>
                        {this.props.stack}
                    </span>
                </Collapse>
            </div>
        )
    }

    private renderBody() {
        const { renderBody } = this.props;
        if (renderBody) {
            return renderBody();
        }

        return (
            <>
                <div className={Classes.DIALOG_BODY}>
                    <p className='messagebox-message'>
                        {this.props.message}
                    </p>
                    {this.renderStack()}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        {this.renderButtons()}
                    </div>
                </div>
            </>
        )
    }

    private renderButtons() {
        const { renderButtons, stack } = this.props;
        const { forceLogout } = this.state;

        if (renderButtons) {
            return renderButtons();
        }

        return (
            <>
                {
                    stack &&
                    <Button
                        intent={Intent.NONE}
                        onClick={this.copyToClipboard}
                        icon={IconNames.DUPLICATE}
                        value='Copy to Clipboard'
                    />
                }
                <Button
                    id="default-confirm-button"
                    intent={Intent.PRIMARY}
                    onClick={this.toggleDialog}
                    value={forceLogout ? 'Logout' : 'OK'}
                />
            </>
        )
    }

    private determineIcon(): IconName {
        switch (this.props.severity) {
            case Severity.debug:
                return IconNames.WRENCH;
            case Severity.warning:
                return IconNames.WARNING_SIGN;
            case Severity.danger:
                return IconNames.ERROR;
            case Severity.info:
                return IconNames.INFO_SIGN;
            default:
                return IconNames.WARNING_SIGN;
        }
    }

    private toggleCollapse = e => this.setState({ isCollapseOpen: !this.state.isCollapseOpen });

    private toggleDialog = e => {
        const { onClose, logout } = this.props;
        const { forceLogout } = this.state;
        MDMStore.dispatch<any>(displayMessageBox({ show: false }, false));

        if (onClose) {
            onClose(e)
        }

        if (forceLogout) {
            logout("The MDM web service restarted, please log in again.")
        }
    }

    public render() {
        const { show, title } = this.props;
        const { forceLogout, isOpen } = this.state;

        return (
            <Dialog
                canOutsideClickClose={!forceLogout}
                icon={this.determineIcon()}
                isOpen={show && isOpen}
                onClose={this.toggleDialog}
                title={title}
            >
                { this.renderBody()}
            </Dialog>
        );
    }
}

export const MessageBox: any = connect(
    (state: any) => ({}),
    { logout, displayMessageBox }
)(MessageBoxBase as any);
