import * as React from 'react';
import { useState } from 'react';
import { CoreProps } from 'react-grid-layout'

import { inSystemGroup } from '../../api/inRoles'
import './Panel.scss';

export interface IPanelProps extends CoreProps {
    // contents: React.ReactNode;
    title?: string;
    className?: string;
    children?: React.ReactNode;
    shouldScroll?: boolean;

    requiredPermissions?: string[];
    allPermissions?: string[];
    permissionsLoading?: boolean;
}

export const Panel = React.forwardRef((props: IPanelProps, ref) => {
    const { requiredPermissions, allPermissions, className, children, title, shouldScroll, permissionsLoading, ...defaultPanelProps } = props as any;

    const generateTitle = () => title ? <div className="custom-panel-title text-medium">{title}</div> : null;

    let hasAllPermissions = () => requiredPermissions.every(perm => allPermissions.includes(perm));

    let scrollClass = shouldScroll ? " scroll-y" : "";

    const [hidden, setHidden] = useState(false);

    const [firstElement, ...rest] = children;

    if (
        hidden ||
        permissionsLoading ||
        (requiredPermissions?.length && allPermissions?.length && !hasAllPermissions() && !inSystemGroup())
    ) {
        // Invisible grid element that doesnt render its children/contents
        return <div key={title} data-grid={{ x: 0, y: 0, w: 0, h: 0, static: true }} />
    }

    return (
        <div ref={ref} {...defaultPanelProps} className={`custom-panel wrapper ${className}`} >

            {generateTitle()}

            <div className={`custom-panel-content ${scrollClass}`} onMouseDown={e => e.stopPropagation()}>
                {React.cloneElement(firstElement, { hidePanel: () => setHidden(true), showPanel: () => setHidden(false) })}
            </div>

            {rest}
        </div>
    );
});