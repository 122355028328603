
import { MAXIMIZE_MENU, MINIMIZE_MENU } from '../constants';

export type toggleMenu = (m: boolean) => (d: Function) => void;
export const toggleMenu = (isMinimized) => dispatch => {
    isMinimized ?
        dispatch({
            type: MAXIMIZE_MENU
        })
        :
        dispatch({
            type: MINIMIZE_MENU
        });
};