import React, { useMemo } from 'react';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { Tooltip } from '@blueprintjs/core';
import { IBaseRecordsTableProps, RecordsTable, RecordsTableRecordFieldCell } from 'components/RecordsTable';
import { DataTypes } from 'types';
import { sortRecordFields } from 'utils';
import { formatDateTimeDisplay } from 'helpers/date-format';
import { createColumnHelper } from '@tanstack/react-table';

export interface  IQueryRecordsTableProps extends IBaseRecordsTableProps {
    onSelect?: (id: string) => void;
    onDoubleClick?: (id: string) => void;
    loading?: boolean;
    resultCount?: number;
}

// convert QueryRecordsTable to a function component
export const QueryRecordsTable: React.FC<IQueryRecordsTableProps> = ({ resultCount, onDoubleClick, onSelect, loading, schema, data, defaultPageSize }) => {
    const primaryKey = schema?.primary_key;
    const [selectedRecord, setSelectedRecord] = React.useState(null);

    let timer;
    const delay = 200;
    let prevent = false;

    const getColumns = () => {
        const colHelper = createColumnHelper();
        const columns = [];

        for (const cur of schema.fields) {
            columns.push(colHelper.accessor(cur.field, {
                header: cur.field,
                enableResizing: true,
                enableSorting: true,
                sortingFn: sortRecordFields,
                cell: ({ cell, row }) => {
                    const primaryKey = schema.primary_key;
                    let value = cell.getValue<string | Date>();
                    value = formatDateTimeDisplay(value, cur); 
                    const highlight = selectedRecord && (row.original[primaryKey] === selectedRecord[primaryKey]);
                    return (
                        <Tooltip minimal>
                            <div style={highlight ? { fontWeight: 'bold' } : { background: 'white' }}>
                                {cur.datatype.type === DataTypes.table ? (
                                    <RecordsTableRecordFieldCell
                                        records={cell.getValue<any[]>() || []}
                                        schema={cur.schema}
                                    />
                                ) : value}
                            </div>
                        </Tooltip>
                    );
                }
            }));
        }

        return columns;
    }

    const columns = useMemo(() => getColumns(), [schema.fields, selectedRecord]);

    return (
        <ReactTable
            resultCount={resultCount}
            // className={onDoubleClick ? "-highlight" : ""}
            loading={loading}
            columns={columns}
            data={data}
            resizable
            sortable
            showPageSizeOptions={true}
            // {...getTableProps({
            //     ...props,
            //     getCell: buildCell
            // })}
            getTdProps={({ row }) => {
                return {
                    onClick: e => {
                        timer = setTimeout(() => {
                            if (!prevent && row) {
                                onSelect && setSelectedRecord(row.original);
                                onSelect && onSelect(row.original[primaryKey]);
                            }
                            prevent = false;
                        }, delay);
                    },
                    onDoubleClick: e => {
                        clearTimeout(timer);
                        prevent = true;
                        if (row) {
                            setSelectedRecord(null);
                            onDoubleClick && onDoubleClick(row.original[primaryKey]);
                        }
                    }
                };
            }}
        />
    );
}