import * as React from 'react';
import { Button, Icon, Position, Tooltip, Intent } from '@blueprintjs/core';

// TODO - use a real signature for tooltip
export const MDMWrapper: React.FC<{ children: any, title: string, buttons?: JSX.Element[], documentationPath?: any }> = ({ buttons, children, title, documentationPath }) =>
    <div className="mdm-container">
        <div className="mdm-container-menu">
            <div className="mdm-container-title">
                <div>{title}</div>
                {
                    documentationPath &&
                    <Tooltip content="Documentation" position={Position.RIGHT}>
                        <div className="mdm-wrapper-docs-button flex-center">
                            <Icon icon='help' onClick={() => { window.open(`/mdm-help/${documentationPath}`, 'mdm-help'); }} color="#636567" iconSize={20} />
                        </div>
                    </Tooltip>
                }
            </div>
            {buttons ? <div className="mdm-container-title-buttons">{buttons}</div> : null}
        </div>
        {children}
    </div>;
