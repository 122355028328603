
import * as React from 'react';
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import { getMatchGroupColorClass, GroupType, MatchRecord } from '../WorkflowMatchConstants';
import { WorkflowMatchTableDraggable } from './WorkflowMatchTableDraggable';
import { Schema } from '../../../../types';

export interface MatchGroupContainerProps {
    hiddenFields: string[];
    records: MatchRecord[];
    schema: Schema;
    rowsDraggable?: boolean;
    setCustomGroup: (recordId: string, group: number) => void;
}
type CustomGroup = {
    [key: number]: MatchRecord[];
};
export interface MatchGroupContainerState {
    customGroups: CustomGroup;
}

export class MatchGroupContainer extends React.PureComponent<MatchGroupContainerProps, MatchGroupContainerState> {

    // Turn the records into a map of custom group # -> records
    private getCustomGroups = (records: MatchRecord[]) => records.reduce((acc, cur, index) => {
        const group = Array.isArray(cur._group) ? cur._group[GroupType.CUSTOM] : cur._group;
        acc.hasOwnProperty(group) ? acc[group] = [...acc[group], cur] : acc[group] = [cur];
        return acc;
    }, {})

    constructor(props) {
        super(props);
        this.state = {
            customGroups: this.getCustomGroups(props.records)
        };
    }
    componentDidUpdate(prevProps: MatchGroupContainerProps) {
        if (this.props.records !== prevProps.records)
            this.setState({
                customGroups: this.getCustomGroups(this.props.records)
            });
    }
    // find a group number one higher than any current group
    makeNewGroupId = () => Object.keys(this.state.customGroups).reduce((acc, cur) => Number(cur) > acc ? Number(cur) : acc, 0) + 1;

    private getGroup = (groupIndex: number, records: MatchRecord[], schema: Schema) => {
        return (
            <div key={groupIndex} className="workflow-match-group">
                <div
                    style={{ minWidth: '0.2rem', borderRadius: '1px', minHeight: '100%', marginRight: '1rem' }}
                    className={getMatchGroupColorClass(groupIndex)} />
                <div className="workflow-match-group-table-container">
                    <div style={{ marginBottom: '1rem' }}>Group {groupIndex}</div>
                    <WorkflowMatchTableDraggable
                        uniqueId={groupIndex.toString()}
                        schema={schema}
                        records={records}
                        hiddenFields={this.props.hiddenFields}
                        rowsDraggable={this.props.rowsDraggable}
                    />
                </div>
            </div>
        )
    }

    handleDragEnd = (result: DropResult) => {
        if (!result.destination || result.draggableId == null || result.destination.droppableId == null) {
            return;
        }

        const groups = this.state.customGroups;
        const recordId = result.draggableId;
        const newGroup = result.destination.droppableId === "new" ? this.makeNewGroupId() :
            parseInt(Object.keys(groups)[result.destination.droppableId], 10);

        this.props.setCustomGroup(recordId, newGroup);
    }

    render() {
        return (
            <DragDropContext
                onDragEnd={this.handleDragEnd}
            >
                <div className="workflow-match-group-container">
                    {
                        Object.entries(this.state.customGroups).map((entry, index) => {
                            return (
                                <Droppable droppableId={index.toString()} key={index}>
                                    {(droppableProvided, _droppableSnapshot) => (
                                        <div>
                                            <div ref={droppableProvided.innerRef}>
                                                {this.getGroup(index + 1, entry[1], this.props.schema)}
                                            </div>
                                            {droppableProvided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )
                        }).concat( this.props.rowsDraggable ?
                            <Droppable droppableId="new" key="new">
                                {(droppableProvided, droppableSnapshot) => (
                                    <div>
                                        <div ref={droppableProvided.innerRef}>
                                            <div key="empty" className="workflow-match-group-empty">Drag record here to create new group</div>
                                        </div>
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            : null
                        )
                    }
                </div>
            </DragDropContext>
        );
    }
}