import { UserUrl } from '../proxies/ajax/UserUrl';
import { axiosPost, axiosGet } from '../helpers/axiosUtils';
import { displayError } from './displayError';

export class UserApi {
    static getVersion() {
        return axiosGet(UserUrl.version);
    }

    static ping() {
        return axiosGet(UserUrl.ping);
    }

    static upsert(username: string, fullName: string, email: string, description: string, authority: string = "mdm") {
        const body = {
            payload: {
                username,
                full_name: fullName,
                email,
                description,
                authority
            }
        };
        return axiosPost(UserUrl.upsert, body).then(() => true).catch(err => {
            displayError('Upsert User Failed', `Failed to upsert user`, err);
            throw err;
        });
    }

    static get(username: string) {
        const body = { payload: { username } };
        return axiosPost(UserUrl.get, body, 'user').catch(err => {
            displayError('Get User Failed', `Failed to get user`, err);
            throw err;
        });
    }

    static getAll = () => axiosPost(UserUrl.getall, {}, 'users').catch(err => {
        displayError('Get All Users Failed', `Failed to get all users`, err);
        throw err;
    })

    static deleteUser(username: string) {
        const body = { payload: { username } };
        return axiosPost(UserUrl.delete, body).then(() => true).catch(err => {
            displayError('Delete User Failed', `Failed to delete user`, err);
            throw err;
        });
    }

    static getUserGroups(username: string) {
        const body = { payload: { username } };
        return axiosPost(UserUrl.groups, body, 'groups').catch(err => {
            displayError('Get User Group Failed', `Failed to get user group`, err);
            throw err;
        });
    }

    static setUserGroups(username: string, groups: string[]) {
        const body = { payload: { username, group: groups } };
        return axiosPost(UserUrl.setgroups, body).then(() => true).catch(err => {
            displayError('Set User Group Failed', `Failed to set user group`, err);
            throw err;
        });
    }

    static getUserState(key: string, targetUser?: string) {
        const body = { payload: { key } };

        if (targetUser)
            body.payload["targetUser"] = targetUser;

        return axiosPost(UserUrl.getstate, body, 'state').catch(err => {
            displayError('Get User State Failed', `Failed to get state group`, err);
            throw err;
        });
    }

    static getSystemUserState(key: string, systemUsername: string = 'system') {
        const body = { payload: { key, targetUser: systemUsername } };
        return axiosPost(UserUrl.getstate, body, 'state').catch(err => {
            displayError('Get User State Failed', `Failed to get state group`, err);
            throw err;
        });
    }

    static setUserState(key: string, state: string, expiry?: Date, targetUser?: string) {
        const body = { payload: { key, state } };

        if (expiry) {
            body.payload["expiry"] = expiry;

            
        } else {
            const defaultExpiry = new Date();
            defaultExpiry.setDate(defaultExpiry.getDate() + 10000);
            body.payload["expiry"] = defaultExpiry;

        }
        
        console.log('payload', body.payload)

        if (targetUser)
            body.payload["targetUser"] = targetUser;

        return axiosPost(UserUrl.setstate, body).then(() => true).catch(err => {
            displayError('Set User State Failed', `Failed to set user state`, err);
            throw err;
        });
    }
}
