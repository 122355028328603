export class RecordUrl {
    public static create        = '/ajax/record/create';
    public static upsert        = '/ajax/record/upsert';
    public static replace       = '/ajax/record/replace';
    public static modify        = '/ajax/record/modify';
    public static delete        = '/ajax/record/delete';
    public static versions      = '/ajax/record/version';
    // Start moving UI core API calls over to a new UI specific interface
    public static query         = '/ajax/ui/record/query';
    public static previous      = '/ajax/record/previous';
    public static mask          = '/ajax/record/mask';
    public static purge         = '/ajax/record/purge';
    public static verifyPurge   = '/ajax/record/verify_purged_masked';
}