import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getExpandedRowModel,
    flexRender,
    PaginationState,
    createColumnHelper,
    ExpandedState
} from '@tanstack/react-table';
import { Button, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';
import { Pagination } from 'rp-mdm-common';

import ApiPaginator from './ApiPaginator'
import "./react-table-6.scss";
import "./react-table-overrides.scss";
import "./react-table-7-paginator.scss";
import Resizer from './Resizer';

type ReactTableProps = {
    className?: string;
    data: any[];
    columns: any[];
    loading?: boolean;
    getTdProps?: (cell) => any;
    trClassName?: string;
    SubComponent?: (row) => any;
    showPagination?: boolean;
    showPaginationTop?: boolean;
    showPaginationBottom?: boolean;
    sortable?: boolean;
    resizable?: boolean;
    defaultSorted?: any[];
    showPageSizeOptions?: boolean;
    defaultPageSize?: number;
    resultCount?: number;
    paginate?: (page: Pagination) => void;
    refreshTag?: string;
    rowsDraggable?: boolean;
    idField?: string;
    emptyText?: string;
};

const ReactTable: FC<ReactTableProps> = (props) => {
    const {
        className = "",
        data,
        columns,
        loading = false,
        getTdProps = () => { },
        trClassName = "",
        SubComponent,
        showPagination = true,
        showPaginationTop = true,
        showPaginationBottom = true,
        sortable = true,
        resizable = true,
        defaultSorted,
        showPageSizeOptions = false,

        defaultPageSize = 10,
        // Props for endpoint pagination
        resultCount,
        paginate,
        refreshTag,

        // Prop to be able to drag rows from one table to another
        rowsDraggable = false,
        idField = null,

        // Prop to display a message when no data is available
        emptyText = 'No data available',
    } = props;

    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: defaultPageSize ?? 10,
        });

    const [expanded, setExpanded] = React.useState<ExpandedState>();

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const [subNano] = useState(nanoid());

    /* If the table is expandable then add the expander column */
    if (SubComponent) {
        const colHelper = createColumnHelper();
        columns.unshift(colHelper.display({
            header: "",
            size: 35,
            id: subNano,
            enableResizing: false,
            cell: ({ row, getValue }) => {
                if (!row.getCanExpand()) return null;
                return (
                    //Applying the toggle expander props i.e onClick, style and title
                    <div
                        onClick={() => row.getToggleExpandedHandler()}
                        title="Click here to see more information"
                        className="rt-td rt-expandable p-0 no-padding full-width flex-center"
                    >
                        <div
                            className={classnames(
                                "rt-expander",
                                row.getIsExpanded() ? "-open" : ""
                            )}
                        >
                            •
                        </div>
                        {getValue()}
                    </div>
                );
            }
        }));
    }

    const {
        nextPage,
        previousPage,
        setPageSize,
        setPageIndex,
        getPageOptions,
        getState,
        getCanNextPage,
        getCanPreviousPage,
        getRowModel,
        getHeaderGroups,
        getFlatHeaders,
        getTotalSize,
    } = useReactTable(
        {
            columns,
            columnResizeMode: "onChange",
            data,
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getExpandedRowModel: getExpandedRowModel(),
            autoResetPageIndex: false,
            enableSorting: sortable,
            enableColumnResizing: resizable,
            onExpandedChange: setExpanded,
            onPaginationChange: (paginationState) => {
                setPagination(paginationState);
            },
            state: {
                pagination,
                expanded,
            },
            // manualPagination: true,
        },
    );

    const [inputValue, setInputValue] = React.useState(() => getState().pagination.pageIndex + 1);

    useEffect(() => {
        setInputValue(getState().pagination.pageIndex + 1);
    }, [getState().pagination.pageIndex]);

    const colVars = useMemo(() => {
        const headers = getFlatHeaders();
        const colSizes: { [key: string]: number } = {}
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i]!
            colSizes[`--header-${header.id}-size`] = header.getSize()
            colSizes[`--col-${header.column.id}-size`] = header.column.getSize()
        }
        return colSizes;
    }, [getState().columnSizingInfo]);


    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const onChangeInInput = event => {
        let inputValue = event.target.value;

        if (inputValue === '') {
            setInputValue(inputValue);
            return; // Don't update the page index if the input is empty
        }

        // get absolute value (for negative numbers)
        inputValue = Math.abs(Number(event.target.value) - 1);

        setInputValue(inputValue);

        const page = event.target.value ? Math.abs(Number(event.target.value) - 1) : 0
        setPageIndex(page);
    }

    const componentPaginator = (
        <div className="react-table-7-paginator">
            {
                showPageSizeOptions &&
                <div className="flex-center bp3-text-muted">
                    Items per page {" "}
                    <select className="paginator-input" value={getState().pagination.pageSize} onChange={onChangeInSelect}>
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            }
            <div className="flex-center margin-left bp3-text-muted">
                Go to page {" "}
                <input
                    value={inputValue}
                    className="paginator-input"
                    type="number"
                    min={1}
                    max={getPageOptions().length}
                    onChange={onChangeInInput}
                />
                <span > of <strong>{getPageOptions().length}</strong></span>
            </div>
            <div className="margin-left flex-center">
                <Button className="component-pagination-button" onClick={previousPage} disabled={!getCanPreviousPage()}>
                    <Icon icon={IconNames.CHEVRON_LEFT} />
                </Button>
                <Button className="component-pagination-button button-margin-left" onClick={nextPage} disabled={!getCanNextPage()}>
                    <Icon icon={IconNames.CHEVRON_RIGHT} />
                </Button>
            </div>
        </div>
    )

    const getSortIcon = (column) => {
        const sort = column.getIsSorted();

        if (sort === 'asc') {
            return <Icon icon={IconNames.CHEVRON_UP} />;
        }

        if (sort === 'desc') {
            return <Icon icon={IconNames.CHEVRON_DOWN} />;
        }

        return null;
    }

    // should reset when columns or refreshTag changes
    useEffect(() => {
        setPageIndex(0)
    }, [refreshTag, setPageIndex, columns]);

    return (
        //Applying the table props
        <>
            {/* PAGINATIONS */}
            <div className="full-width flex-space-between flex-center-vertically">
                <div>
                    {resultCount !== undefined && <ApiPaginator resultCount={resultCount} paginate={paginate} refreshTag={refreshTag} />}
                </div>
                <div>
                    {showPagination && showPaginationTop && componentPaginator}
                </div>
            </div>

            {/* TABLE */}
            <div className={classnames(className, "ReactTable")}>
                <div className="rt-table" style={{ ...colVars, width: getTotalSize(), minWidth: "100%", }}>
                    <div className={`rt-thead -header ${rowsDraggable && 'draggable-th'}`}>
                        {getHeaderGroups().map((headerGroup) => (
                            //Applying the header row props
                            <div key={headerGroup.id} className="rt-tr">
                                {//Looping over the headers in each row
                                    headerGroup.headers.map((header) => {
                                        /*Checking if column can be resized and applying resizable props along with
                                        header cell props and finally rendering the header cell
                                        */

                                        return (
                                            <div
                                                key={header.id}
                                                className="rt-th rt-resizable-header"
                                                style={{
                                                    maxWidth: `calc(var(--header-${header?.id}-size) * 1px)`,
                                                    cursor: header.column.getCanSort() ? 'pointer' : 'default',
                                                    userSelect: header.column.getCanSort() ? 'none' : 'auto',
                                                }}
                                            >
                                                <div
                                                    className="rt-resizable-header-content justify-between flex"
                                                    onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : null}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext(),
                                                    )}
                                                    {header.column.getCanSort() ? getSortIcon(header.column) : null}
                                                </div>
                                                {header.column.getCanResize() ? (
                                                    <Resizer header={header} state={getState()} />
                                                ) : null}
                                            </div>
                                        )
                                    })}
                            </div>
                        ))}
                    </div>

                    <div className="rt-tbody">
                        {getState().columnSizingInfo.isResizingColumn ? (
                            <MemoizedTableBody
                                data={data}
                                getRowModels={getRowModel}
                                trClassName={trClassName}
                                getTdProps={getTdProps}
                                emptyText={emptyText}
                                rowsDraggable={rowsDraggable}
                                idField={idField}
                                SubComponent={SubComponent}
                            />
                        ) : (
                            <TableBody
                                data={data}
                                getRowModels={getRowModel}
                                trClassName={trClassName}
                                getTdProps={getTdProps}
                                emptyText={emptyText}
                                rowsDraggable={rowsDraggable}
                                idField={idField}
                                SubComponent={SubComponent}
                            />
                        )}
                    </div>
                </div>
                {/*Loading overlay*/}
                <div className={classnames("-loading", loading ? "-active" : "")}>
                    <div className="-loading-inner">Loading...</div>
                </div>
            </div>

            <div className="full-width flex-end flex-center-vertically">
                <div>
                    {showPagination && showPaginationBottom && componentPaginator}
                </div>
            </div>
        </>
    );
};

const TableBody = ({ getRowModels, trClassName, getTdProps, data, emptyText, rowsDraggable, idField, SubComponent }) => {
    const generateRow = (row, ind, max) => {
        const rowPK = (idField !== null && row?.original) ? row.original[idField] || row.original[`_${idField}`] : uuidv4();
        //Preparing row for rendering
        // prepareRow(row);
        const tableRow = (
            <React.Fragment key={row.id}>
                {/*Applying the row props and adding classname for alternate rows*/}
                <div
                    className={classnames(
                        "rt-tr",
                        ind % 2 === 0 ? "-odd" : "-even",
                        ind === max - 1 ? "last-row" : "",
                        trClassName
                    )}
                >
                    {row.getVisibleCells().map((cell) => {
                        return (
                            <div
                                key={cell.id}
                                {...getTdProps(cell)}
                                className={classnames("rt-td", cell.column.columnDef.tdClassNames)}
                                style={{
                                    maxWidth: `calc(var(--col-${cell.column.id}-size) * 1px)`,
                                }}
                            >
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext(),
                                )}
                            </div>
                        );
                    })}
                </div>
                {/* Checking row expansion */}
                {row.getIsExpanded() ? SubComponent({ row }) : null}
            </React.Fragment>
        );


        if (rowsDraggable) {
            return (
                <Draggable
                    index={ind}
                    key={rowPK?.toString ? rowPK.toString() : rowPK}
                    draggableId={rowPK?.toString ? rowPK.toString() : rowPK}
                    // eslint-disable-next-line react/no-children-prop
                    children={(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            {tableRow}
                        </div>
                    )}
                />
            )
        }

        return tableRow;
    };

    if (data.length === 0) {
        return (
            <div className="rt-tr empty-row">
                <div className="rt-td empty-row-text" style={{ textAlign: 'center' }}>
                    {emptyText}
                </div>
            </div>
        );
    }


    return (
        <div className="rt-tbody">
            {getRowModels().rows.map((row, ind) => {
                return generateRow(row, ind, getRowModels().length);
            })}
        </div>
    )
}

const MemoizedTableBody = React.memo(TableBody);

export default ReactTable;