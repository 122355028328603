export class DatabaseUrl {
    public static list          = '/ajax/databases/list/:start/:count';
    public static create        = '/ajax/databases/create';
    public static update        = '/ajax/databases/update';
    public static deleteDb      = '/ajax/databases/delete/:databaseName';
    public static version       = '/ajax/core/version';  // /ajax/databases/version?
    public static ping          = '/ajax/core/ping';

    public static license       = '/ajax/core/license';
    public static setLicense    = '/ajax/core/setLicense';
}
