import * as React from 'react';
import { inRoles } from '../../api';
import { Redirect } from 'react-router';

export interface RouteAuthProps {
  allowedRoles: string[];
}

export class RouteAuthWrapper extends React.Component<React.PropsWithChildren<RouteAuthProps>> {
  render() {
    if (inRoles(this.props.allowedRoles))
      return this.props.children
    return <Redirect to="/" />
  }
}

export const RouteAuth = (WrappedComponent, allowedRoles) => {
  return class WithAuthorization extends React.Component {
    render() {
      if (inRoles(allowedRoles)) {
        return <WrappedComponent {...this.props} />
      }
      return <Redirect to="/" />
    }
  }
}

export default RouteAuth;