import * as React from 'react';
import { Icon, Intent, Position, Tooltip } from '@blueprintjs/core';

import { Button } from '../../Shared/Button';
import { WFNotesTable } from '../../NotesTable/WFNotesTable';
import { History, Schema, Workflow } from 'types';
import { WorkflowWidgets } from '../WorkflowWidgets';

require('helpers/styles/workflow.scss');
require('./WorkflowNote.scss');

export interface WorkflowNoteReviewFormProps {
    history: History[];
    oldRecord: object;
    newRecord: object;
    schema: Schema;
    workflow: Workflow;
    approveTask: (button:HTMLButtonElement, note?: string) => void;
    rejectTask: (button:HTMLButtonElement, note?: string) => void;
    discardTask: (button:HTMLButtonElement, note?: string) => void;
}
export interface WorkflowNoteReviewFormState {
    note: string;
}

export class WorkflowNoteReviewForm extends React.Component<WorkflowNoteReviewFormProps, WorkflowNoteReviewFormState> {
    constructor(props: WorkflowNoteReviewFormProps) {
        super(props);
        this.state = {
            note: ''
        };
    }

    onNoteChange = (newNote: string) => {
        this.setState({ note: newNote });
    }

    approveTask = (button:HTMLButtonElement) => {
        this.props.approveTask(button, this.state.note);
    }

    discardTask = (button:HTMLButtonElement) => {
        this.props.discardTask(button, this.state.note);
    }

    rejectTask = (button:HTMLButtonElement) => {
        this.props.rejectTask(button, this.state.note);
    }

    render() {
        const { workflow } = this.props
        return (
            <div className="workflow-wrapper">
                <div className="workflow-title">Data Change Review
                    <Tooltip content="Documentation" position={Position.RIGHT}>
                        <div className="mdm-wrapper-docs-button flex-center">
                            <Icon icon='help' onClick={() => { window.open(`/mdm-help/workflow_edit_existing_record_steward.html`, 'mdm-help'); }} color="#636567" iconSize={20} />
                        </div>
                    </Tooltip>
                </div>
                <div className="workflow-menu-wrapper">
                    <div className="workflow-menu">
                        <div className="workflow-title-buttons">
                            <Button value="DISCARD" onClick={(e) => this.discardTask(e.currentTarget)} />
                            <Button value="REJECT" intent={Intent.DANGER} onClick={(e) => this.rejectTask(e.currentTarget)} />
                            <Button value="COMPLETE" intent={Intent.SUCCESS} onClick={(e) => this.approveTask(e.currentTarget)} />
                        </div>
                    </div>
                    <WorkflowWidgets tableName={workflow.tableName} workitemId={workflow.workitem_id} />
                </div>
                <WFNotesTable history={this.props.history} note={this.state.note} updateNote={this.onNoteChange} sortForward={true} />
            </div>
        );
    }
}