import { SET_DASHBOARD_LAYOUT, SET_USER_GROUPS, SET_USER_ROLES, SET_USER_SETTINGS, UserActionTypes, UserSettings } from './types';
import { Layouts } from 'react-grid-layout';

import { AppThunk } from '../MDMStore';

export const setUserGroups = (groups: string[]): AppThunk => {
    return dispatch => {
        dispatch({ type: SET_USER_GROUPS, payload: { groups } });
    };
};

export const setDashboardLayout = (newLayouts: Layouts): AppThunk => {
    return dispatch => {
        dispatch({ type: SET_DASHBOARD_LAYOUT, payload: { newLayouts } });
    };
};

export const setUserRoles = (roles: string[]): AppThunk => {
    return (dispatch => {
        dispatch({ type: SET_USER_ROLES, payload: { roles: roles || null } });
    });
};

export const setUserSettings = (settings: UserSettings): AppThunk => {
    return (dispatch => {
        dispatch({ type: SET_USER_SETTINGS, payload: { settings } });
    });
};
