import * as React from 'react';
// import { hoistNonReactStatics } from 'hoist-non-react-statics';

export interface DebounceOptions {
    useLeading?: boolean;
}

type DebouncedFunction = Function;

function debounce(func: Function, wait: number, options: DebounceOptions): DebouncedFunction {
    let timeout;
    console.error('first', this);
    // function cancel() {
    //     if (timeout != null) clearTimeout(timeout);
    // }
    // this.cancel = cancel;
    return () => {
        console.error('second', this);
        const context = this, args = arguments;
        const later = () => {
            timeout = null;
            if (!options.useLeading) func.apply(context, args);
        };
        const callNow = options.useLeading && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function getDisplayName(component) {
    return component.displayName || component.name;
}

export function createDebouncedComponent<T = {}>(wait: number = 250, options: DebounceOptions = { useLeading: false }) {
    return (component: React.ComponentClass<T>) => {
        // We declare the Generics as <any, any> to avoid conflicts
        // There's a subset of props we can't directly copy
        return class DebouncedComponent extends React.Component<T, any> {
            public debouncedSetState: DebouncedFunction;

            public static displayName = `Debounced(${getDisplayName(component)}`;

            constructor(props, context) {
                super(props, context);
                this.state = {};
                this.debouncedSetState = debounce(nextState => this.setState(nextState), wait, options);
                console.log(this.debouncedSetState);
            }

            public shouldComponentUpdate(nextProps, nextState) {
                return this.state !== nextState;
            }

            public componentDidMount() {
                this.debouncedSetState({ props: this.props });
            }

            public componentWillReceiveProps(nextProps) {
                this.debouncedSetState({ props: nextProps });
            }

            public componentWillUnmount() {
                //this.debouncedSetState.cancel();
            }

            public render() {
                return React.createElement(component, this.props);
            }
        };

        // return hoistNonReactStatics(DebouncedComponent, component);
    };
}