import { RECEIVE_GROUPS } from '../constants';

export const groups = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_GROUPS:
            return action.groups;
        default:
            return state;
    }
};
