/**
 * Created by nbamford on 3/17/2017.
 */

import * as React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from '.';

export interface MDMTableColumn {
    title: string;
    field: string;
    type?: string;
    prompt?: string;
    actionBuilder?: (data: any) => JSX.Element;
}
export interface MDMTableProps {
    data: object[];
    columns: MDMTableColumn[];
    callbacks?: any;
}
export const MDMDataTable:React.FC<MDMTableProps> = ({columns, data, callbacks}) => {

    /**
     * Given a JSON object and the name of the field extract that data
     * Note that you can use periods to separate sub objects
     * @param value
     * @param field
     */
    const getValue = (value, field) => {
        if(field.includes('.')) {
            const base = field.substring(0, field.indexOf("."));
            const subField = field.substring(field.indexOf(".") + 1);
            return ( value.hasOwnProperty(base)) ? getValue(value[base], subField) : null;
        }
        return value[field];
    };

    const getCell = (col, value) => {
        switch (col.type) {
            case "edit":
                return(<td key={col.field}><a role="button" onClick={() => callbacks.editFunction(value)}>{value[col.field] || ''}</a></td>);
            case "delete":
                return (<td key="delete"><Button title="Delete user!" className="toolbar-narrow"
                                                     onClick={ ()=> {
                                        if (confirm(`Delete ${col.prompt} ${value[col.field]}?`))
                                            callbacks.deleteFunction(value);
                                    }}
                        icon={IconNames.TRASH} /></td>);
            case "action":
                return (<td key="action">{col.actionBuilder(value)}</td>);
            case "text":
            default:

                return  <td key={col.field}>{ getValue(value, col.field) || ''}</td>;
        }
    };

    const columnHeaders = columns.map((col) =>
        <td style={{ border: '1px solid #ccc' }} key={col.title}>{col.title}</td>);

    const tableData = data.map((item, index) =>
        <tr key={index}>
            {columns.map((col) => getCell(col, item))}
        </tr>
    );

    const headerStyle = { fontWeight: 500 };

    return (
        <HTMLTable bordered={true} className="workflow-container" striped={true} cellSpacing="0" >
            <thead style={ headerStyle }>
                <tr>
                    {columnHeaders}
                </tr>
            </thead>
            <tbody>
                {tableData}
            </tbody>
        </HTMLTable>
    );
};
