export class UserUrl {
    public static get               = '/ajax/user/get';
    public static upsert            = '/ajax/user/upsert';
    public static getall            = '/ajax/user/query';
    public static delete            = '/ajax/user/delete';
    public static groups            = '/ajax/user/groups';
    public static setgroups         = '/ajax/user/setgroups';
    public static addgroups         = '/ajax/user/addgroups';
    public static deletegroups      = '/ajax/user/deletegroups';
    public static getstate          = '/ajax/user/getstate';
    public static setstate          = '/ajax/user/setstate';
    public static resetstate        = '/ajax/user/resetstate';
    public static version           = '/ajax/user/version';
    public static ping              = '/ajax/user/ping';
}
