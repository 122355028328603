import * as React from 'react';
import { useEffect, useState } from 'react'
import { Classes, Dialog, Spinner, IconName, Label, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { connect } from 'react-redux';
import { Workitem } from 'types';
import { queryChangesets, RecordApi } from 'api'

import './WorkitemHistoryModal.scss';
import { formatTimeHHMM } from 'utils';

export interface WorkitemHistoryModalProps {
    workitem: Workitem;
    dbName: string;
    onClose: () => void;
}
export interface WorkitemHistoryModalState { }

interface RecordDifference {
    prev: any;
    current: any;
}

export const WorkitemHistoryModal: React.FC<WorkitemHistoryModalProps> = (props) => {
    const { workitem, onClose, dbName } = props;
    const isOpen = !!workitem;

    const [loading, setLoading] = useState(true);
    const [involvedRecords, setInvolvedRecords] = useState(null);
    const [recordDifference, setRecordDifference] = useState<RecordDifference>(null);
    const [noRecord, setNoRecord] = useState(false);

    const querySingleRecord = async (primaryKey) => {
        return await RecordApi.queryRecords(
            {
                database: dbName,
                table: workitem.variables.table,
                changeset: workitem.variables.changeset,
                errorHandler: null,
                filter: {
                    primary_key: primaryKey
                }
            })
    };

    const queryRecordHistory = async (primaryKey) => {
        try {
            const record = await querySingleRecord(primaryKey);
            let prevRecords = await RecordApi.queryPrevious(dbName, workitem.variables.changeset, workitem.variables.table, primaryKey);
            let prev = prevRecords.length ? prevRecords[0] : null;
            setRecordDifference({ current: record, prev });
        } catch(e) {
            // already reported -- I guess this counts as no record
            setNoRecord(true);
        }
        setLoading(false);
    }

    const queryInvolvedRecords = async (primaryKeys: string) => {
        const parsedKeys = primaryKeys.substring(1, primaryKeys.length - 1).split(',').map(idWithQuotes => idWithQuotes.substring(1, idWithQuotes.length - 1));
        const queryPromises = parsedKeys.map(pk => querySingleRecord(pk));
        const records = await Promise.all(queryPromises);
        setLoading(false);
        setInvolvedRecords([].concat.apply([], records));
    }

    const stateCleanup = () => {
        setLoading(true);
        setInvolvedRecords(null);
        setRecordDifference(null);
        setNoRecord(false);
    }

    useEffect(() => {
        if (!!workitem) {
            if (workitem.variables.primary_key) {
                queryRecordHistory(workitem.variables.primary_key);
            } else if (workitem.variables.match_keys) {
                queryInvolvedRecords(workitem.variables.match_keys);
            } else {
                // No record associated to this workitem
                setLoading(false);
                setNoRecord(true);
            }
        } else {
            stateCleanup();
        }
    }, [workitem]);

    const generateRecordsSection = () => {
        if (!isOpen) {
            return null;
        }

        if (loading) {
            return (
                <div className="fill flex-center flex-column">
                    <Spinner />
                </div>
            )
        }

        if (noRecord) {
            return <div>No records associated to workitem {workitem.workitem_id}</div>
        }

        if (involvedRecords) {
            return (
                <div>
                    <div className="text-medium text-heavy">Records involved in matching:</div>
                    {
                        involvedRecords.map((record, index) => (
                            <div key={`record-${index}`} className="workitem-history-record">
                                <pre>{JSON.stringify(record, null, 2)}</pre>
                            </div>
                        ))
                    }
                </div>
            )
        }

        if (recordDifference) {
            return (
                <div>
                    <div>
                        <div className="text-medium text-heavy">Current Record:</div>
                        <div className="workitem-history-record">
                            <pre>{JSON.stringify(recordDifference.current, null, 2)}</pre>
                        </div>
                    </div>

                    {
                        recordDifference.prev ?

                            <div>
                                <div className="text-medium text-heavy">Previous Record:</div>
                                <div className="workitem-history-record previous-record">
                                    <pre>{JSON.stringify(recordDifference.prev, null, 2)}</pre>
                                </div>
                            </div>

                            :
                            <div className="workitem-history-record">No previous Record</div>
                    }

                </div>
            )
        }
    }

    const generateHistorySection = () => {
        const historyWithUserActivity = workitem?.history
            .filter(workitemHistory => !!(workitemHistory as any).username);

        if (!historyWithUserActivity?.length) {
            return null;
        }



        return (
            <div className="history-entries">
                {
                    // Do action, message and note need to be escaped? what happens to HTML characters
                    historyWithUserActivity.map((historyObject, index) => {
                        const noteDiv = historyObject.note ?
                            <div className="history-name">{`Note: ${historyObject.note}`}</div> : null;
                        return <div key={`history-action-${index}`}>
                            <time style={{ display: 'block' }} className="history-date">
                                {formatTimeHHMM(historyObject.timestamp)}
                            </time>
                            <div className="history-name">{`${historyObject.action || historyObject.message} by user ${historyObject.username}`}</div>
                            {noteDiv}
                        </div>
                    })
                }
            </div>
        )
    }

    return (
        <Dialog className="workitem-history-dialog" isOpen={isOpen} icon={IconNames.DATABASE} title="Workitem Activity" onClose={onClose}>
            <div className={`${Classes.DIALOG_BODY} workitem-history-body`}>
                {generateHistorySection()}
                {generateRecordsSection()}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={onClose}>Close</Button>
                </div>
            </div>
        </Dialog>
    );
}

export default connect((state: any) => (
    { dbName: state.database.selected }),
    {}
)(WorkitemHistoryModal);
