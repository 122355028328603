/**
 * @format
 */
import { axiosPost } from '../helpers/axiosUtils';
import { ViewUrl } from '../proxies/ajax/ViewUrl';
import { Subscription, SubscriptionDelivery, SubscriptionOptions, SubscriptionSync } from 'types';
import { displayError } from './displayError';

export class ViewApi {
    static createComputedView(database: string, view: object) {
        const data = { payload: { database: database, view: view } };
        return axiosPost(ViewUrl.create, data, 'view_id', null, false).catch(err => {
            displayError('Create view failed', `Failed to create view`, err);
            throw err;
        });
    }

    static deleteComputedView(database: string, viewID: number) {
        const data = { payload: { database: database, view_id: viewID } };
        return axiosPost(ViewUrl.delete, data, null, null, false).then(() => true).catch(err => {
            displayError('Delete View Failed', `Failed to delete view`, err);
            throw err;
        });
    }

    static getAllComputedViews(database: string) {
        const data = { payload: { database: database } };
        return axiosPost(ViewUrl.query, data, 'views', null, false).catch(err => {
            displayError('Query Views Failed', `Failed to get views`, err);
            throw err;
        });
    }

    static validateComputedView(database: string, view: object) {
        const data = { payload: { database: database, view: view } };
        return axiosPost(ViewUrl.validate, data, null, null, false).catch(err => {
            displayError('Validate View Failed', `Failed to validate view`, err);
            throw err;
        });
    }

    static modifyComputedView(database: string, view: object) {
        const data = { payload: { database: database, view: view } };
        return axiosPost(ViewUrl.modify, data, null, null, false).catch(err => {
            displayError('Update View Failed', `Failed to update view`, err);
            throw err;
        });
    }

    static queryViewSubscriptions(database: string, viewID?: number) {
        const data = { payload: { database } };
        if (viewID) {
            data.payload['view_id'] = viewID;
        }
        return axiosPost(ViewUrl.subscriptionQuery, data, null, null, false).catch(err => {
            displayError('Query View subscriptions Failed', `Failed to query view subscriptions`, err);
            throw err;
        });
    }

    static createViewSubscription(database: string, enabled: boolean, subscription: Subscription) {
        const data = {
            payload: {
                database: database,
                enabled: enabled,
                subscription: subscription,
            }
        };
        return axiosPost(ViewUrl.subscriptionCreate, data, null, null, false).catch(err => {
            displayError('Create View Subscription Failed', `Failed to create view sibscription`, err);
            throw err;
        });
    }

    static enableViewSubscription(
        database: string,
        viewID: number,
        subscriptionID: number,
        enabled: boolean,
        syncFrom: SubscriptionSync
    ) {
        const syncFromValue = (syncFrom === SubscriptionSync.nochange) ? null : syncFrom;
        const data = {
            payload: {
                database: database,
                view_id: viewID,
                subscription_id: subscriptionID,
                enabled: enabled,
                sync_from: syncFromValue
            }
        };
        return axiosPost(ViewUrl.subscriptionEnable, data, null, null, false).catch(err => {
            displayError('Enable View Subscription Failed', `Failed to enable view subsription`, err);
            throw err;
        });
    }

    static modifyViewSubscription(
        database: string,
        viewID: number,
        subscriptionID: number,
        description: string,
        delivery: SubscriptionDelivery,
        options: SubscriptionOptions
    ) {
        const data = {
            payload: {
                database: database,
                view_id: viewID,
                subscription_id: subscriptionID,
                description: description,
                delivery: delivery,
                options: options,
            }
        };
        return axiosPost(ViewUrl.subscriptionModify, data, null, null, false).catch(err => {
            displayError('Modify View Subscription Failed', `Failed to modify view subsription`, err);
            throw err;
        });
    }

    static deleteViewSubscription(database: string, viewID: number, subscriptionID: number) {
        const data = {
            payload: {
                database,
                view_id: viewID,
                subscription_id: subscriptionID,
            }
        };
        return axiosPost(ViewUrl.subscriptionDelete, data, null, null, false).catch(err => {
            displayError('Delete View Subscription Failed', `Failed to delete view subsription`, err);
            throw err;
        });
    }
}
