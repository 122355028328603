import * as React from 'react';
import { Icon, IconSize, Intent, Text, } from "@blueprintjs/core";

import { ConfirmDialog } from '../Shared/ConfirmDialog';
import { PermNewGroupDialog } from './PermNewGroupDialog';
import { IconNames } from '@blueprintjs/icons';


export interface PermDetailsTitleProps {
    //groupNames: string[];
    availableGroupNames: string[];
    name: string;
    emptyTab: boolean;
    deletable: boolean;
    deleteGroupFn?: (name: string) => boolean;
    upsertGroupFn: (name: string, permissions: string[]) => boolean;
}

interface PermDetailsTitleState {
    deleteAlertOpen?: boolean;
    newGroupDialogOpen?: boolean;
}

export class PermDetailsTitle extends React.Component<PermDetailsTitleProps, PermDetailsTitleState> {
    constructor(props) {
        super(props);
        this.state = {
            deleteAlertOpen: false,
            newGroupDialogOpen: false,
        };
    }

    trashClick = () => {
        this.setState( {deleteAlertOpen: !this.state.deleteAlertOpen});
    }
    // Assumes that the delete group function will pop up a different dialog if it fails
    // and pull the groups
    trashConfirmClick = () => {
        this.props.deleteGroupFn(this.props.name);
        this.setState({deleteAlertOpen: false});
    }

    newGroupDialogDone = (name:string) => {
        this.setState({ newGroupDialogOpen: false });
        this.props.upsertGroupFn(name, []);
    }

    newGroupClick = () => {
        this.setState({ newGroupDialogOpen: true });
    }

    render() {
        let deleteIcon:JSX.Element = undefined;

        // empty tab render
        if(this.props.emptyTab) {
            return <div>
                <Icon icon={IconNames.NEW_OBJECT} onClick={ () => {this.newGroupClick();} } />
                <PermNewGroupDialog
                    isOpen={this.state.newGroupDialogOpen}
                    availableGroupNames={this.props.availableGroupNames}
                    doneFn={this.newGroupDialogDone}
                />
            </div>;
        }

        // normal tab render
        if(this.props.deletable) {
            deleteIcon = <Icon icon={IconNames.TRASH}
                size={IconSize.STANDARD}
                onClick={this.trashClick}
            />;
        }

        return <div>
            {this.state.deleteAlertOpen ? <ConfirmDialog
                buttonName="Delete"
                title={`Delete Group ${name}`}
                body={ `You are about to delete the permission group ${name}
                This has significant consequences. Are you sure you want to delete it?` }
                cancelClick={this.trashClick}
                okClick={this.trashConfirmClick}
                /> : undefined }
            <Text>{`${this.props.name} `}
            {deleteIcon}
            </Text>
        </div>;
    }
}
