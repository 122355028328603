/**
 * Created by nbamford on 3/28/2017.
 */
import * as React from 'react';

import { displayError, RecordApi } from 'api';
import { MDMWrapper } from 'components/Shared';
import { QueryRecordsForm } from 'components/QueryRecordsForm';
import { QueryRecordsTable } from './QueryRecordsTable';
import { FilterField, QueryInclude, Workflow } from 'types';
import { WorkflowWidgets } from 'components/Workflow/WorkflowWidgets';
import { Pagination } from 'rp-mdm-common';

export interface QueryRecordsContainerProps {
    buttons?: JSX.Element[];
    doubleClickCb: () => void;
    match: boolean;
    setSelected: (id: string) => void;
    title: string;
    tooltipText: string;
    workflow: Workflow;
}
export interface QueryRecordsContainerState {
    records: object[];
    fields: any[];
    loading: boolean;
}
export class QueryRecordsContainer extends React.Component<QueryRecordsContainerProps, QueryRecordsContainerState> {
    include: QueryInclude;
    constructor(props) {
        super(props);
        this.include = {
            match: this.props.match,
            conflict: true,
            version: false,
            mask: false
        };
        this.state = {
            records: [],
            fields: [],
            loading: false,
        };
    }

    onSelect = (id) => {
        this.props.setSelected && this.props.setSelected(id);
    }

    displayRecordsError = displayError('Query Records Failed', 'Failed to find(query) records');

    queryRecords = async (fields: FilterField[]) => {
        try {
            this.setState({ loading: true });
        
            const records = await RecordApi.queryRecords({
                errorHandler: this.displayRecordsError,
                changeset: this.props.workflow.changesetId,
                database: this.props.workflow.databaseName,
                filter: { fields: fields },
                include: this.include,
                table: this.props.workflow.tableName,
            });
            
            this.setState({ records, loading: false });
        } catch (error) {
            this.displayRecordsError(error);
        }
    }

    private getRecordsInForm = async (fields) => {
        this.setState({ fields },
            () => this.queryRecords(fields),
        );
    }

    paginate = (pagination: Pagination) => {

        this.setState({ loading: true }, () => {
            RecordApi.queryRecords(
                {
                    errorHandler: this.displayRecordsError,
                    changeset: this.props.workflow.changesetId,
                    database: this.props.workflow.databaseName,
                    filter: { fields: this.state.fields },
                    include: this.include,
                    table: this.props.workflow.tableName,
                },
                pagination
            ).then(records => this.setState({ records: records, loading: false }));
        });

    }

    render() {
        return (
            <MDMWrapper title={this.props.title || "Query Records"} buttons={this.props.buttons}>
                <WorkflowWidgets tableName={this.props.workflow.tableName} workitemId={this.props.workflow.workitem_id} />

                <QueryRecordsForm schema={this.props.workflow.schema} queryRecords={this.getRecordsInForm} initialOpenState={true}
                    initialField={true} showMasked={false} />
                <QueryRecordsTable
                    resultCount={this.state.records.length}
                    data={this.state.records}
                    defaultPageSize={10}
                    noDataText={'No records found'}
                    onDoubleClick={this.props.doubleClickCb}
                    onSelect={this.onSelect}
                    schema={this.props.workflow.schema}
                    tooltipText={this.props.tooltipText}
                    loading={this.state.loading}
                />
            </MDMWrapper>
        );
    }
}