import { RECEIVE_RECORD_ACTIVITY } from '../constants';

export const recordActivity = (state = [], action) => {
    switch(action.type) {
        case RECEIVE_RECORD_ACTIVITY:
            // just the history array of the workitem(s)
            //console.log("reducer/RecordActivity " + JSON.stringify(action.payload, null, 2));
            return action.payload;
        default:
            return state;
    }
};
