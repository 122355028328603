export class PermUrl {
    public static delete            = '/ajax/permission/delete';
    public static purge             = '/ajax/permission/purge';
    public static query             = '/ajax/permission/query';
    public static query_effective   = '/ajax/permission/query_effective';
    public static query_parent      = '/ajax/permission/query_parent';
    public static upsert            = '/ajax/permission/upsert';
    public static validate          = '/ajax/permission/validate';
    public static delete_owner      = '/ajax/permission/delete_owner';
    public static get_owner         = '/ajax/permission/get_owner';
    public static query_owner       = '/ajax/permission/query_owner';
    public static set_owner         = '/ajax/permission/set_owner';
    public static version           = '/ajax/permission/version';
    public static ping              = '/ajax/permission/ping';
}
