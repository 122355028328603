import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Responsive, WidthProvider, Layouts } from 'react-grid-layout';
import { Spinner } from "@blueprintjs/core";

// import { DashboardApi } from '../../api/DashboardApi';

import { Panel } from './Panel';
import { defaultDashboardLayout } from 'store/user/defaultDashboardLayout';
import { UserApi } from '../../api/UserApi';
import { setDashboardLayout } from '../../store/user/actions';
import MyWorkitemsPanel from './PanelContents/MyWorkitems';
import ComputedViews from './PanelContents/ComputedViews';
// import DatabaseStats from './PanelContents/DatabaseStats';
// import WorkflowActivity from './PanelContents/WorkflowActivity';
import 'react-grid-layout/css/styles.css';
import './Stack.scss'

const ResponsiveGridLayout = WidthProvider(Responsive);

interface StackProps {
    history: any;
    permissions: string[];
    permissionsLoading: boolean;
}

export const Stack: React.FC<StackProps> = ({ history, permissions, permissionsLoading }) => {

    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const isMinimized: boolean = useSelector((state: any) => state.menu);
    const layouts: Layouts = useSelector((state: any) => state.user.dashboardLayout);
    const workitemRef = React.createRef();
    const computedViews = React.createRef();

    const parseFetchedLayout = (stringLayout: string) => {
        if (!stringLayout)
            return defaultDashboardLayout

        try {
            let parsedLayout = JSON.parse(stringLayout);
            const requiredLayout = parsedLayout?.lg;

            if (!requiredLayout) {
                return defaultDashboardLayout
            }

            // If any of the DEFAULT panels does not exist in the fetched layouts, set the default layouts again.
            let panelMissmatch = false;
            defaultDashboardLayout.lg.forEach(defaultLayout => {
                const panelExists = requiredLayout.find(layout => layout.i === defaultLayout.i);
                if (!panelExists) {
                    panelMissmatch = true;
                }
            });

            return panelMissmatch ? defaultDashboardLayout : parsedLayout;

        } catch (err) {
            console.error("Fetched layout is not a valid JSON");
            return defaultDashboardLayout;
        }
    }

    useEffect(() => {
        UserApi.getUserState('dashboard_layout')
            .then((fetchedLayout: string) => {
                if (mounted === false) {
                    setMounted(true)
                }
                const layouts = parseFetchedLayout(fetchedLayout);
                dispatch(setDashboardLayout(layouts));
            })
            .catch(err => console.error(err || "Couldnt get panel layout."))
    }, []);

    useEffect(() => {
        resizeGrid();
    }, [isMinimized]);

    const resizeGrid = () => {
        for (let t = 0; t <= 300; t += 10) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'))
            }, t)
        }
    }

    const handleLayoutChange = (allLayouts) => {
        resizeGrid();

        UserApi.setUserState('dashboard_layout', JSON.stringify(allLayouts) || '')
            .then((success) => {
                if (success) {
                    dispatch(setDashboardLayout(allLayouts))
                }
            }).catch(err => {
                console.error(err)
            })
    }

    if (!layouts) {
        return (
            <div className="fill flex-center flex-column">
                <span className="text-medium">Loading Views...</span>
                <Spinner />
            </div>
        )
    }

    return (
        <ResponsiveGridLayout
            className="layout"
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            onLayoutChange={(layout, allLayouts) => handleLayoutChange(allLayouts)}
            rowHeight={30}
            layouts={layouts}
            measureBeforeMount={false}
            compactionType="vertical"
            useCSSTransforms={true}
        >
            <Panel key="my-workitems" title="Workitems" ref={workitemRef} shouldScroll>
                <MyWorkitemsPanel history={history} />
            </Panel>     
            {/* <Panel
                ref={computedViews}
                key="computed-views"
                title="View Subscriptions"
                shouldScroll
                isDraggable
                allPermissions={permissions}
                permissionsLoading={permissionsLoading}
                requiredPermissions={["query_subscription", "query_view"]}
            >
                <ComputedViews history={history} />
            </Panel> */}
            
            {/*
              ***********Disabled until we have a source of statistics **************
            <Panel
                key="database-stats"
                title="Database Size Statistics"
                allPermissions={permissions}
                permissionsLoading={permissionsLoading}
                requiredPermissions={["query_table"]}
            >
                <DatabaseStats />
            </Panel >
            <Panel
                key="workflow-activity"
                title="Workflow Activity"
            >
                <WorkflowActivity />
            </Panel> */}
        </ResponsiveGridLayout >
    );
};
