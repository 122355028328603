/* Paths the web service (or a user task) should call to get data from the services
 *  Back end services could/should call the services directly
 *  This means we have only one exposed service for these activities
 */
export class WorkflowUrl {
    // process operations
    public static processCreate     = '/ajax/workflow/process/create';
    public static processDelete     = '/ajax/workflow/process/delete';
    public static processModify     = '/ajax/workflow/process/modify';
    public static processRead       = '/ajax/workflow/process/read';
    public static processQuery      = '/ajax/workflow/process/query';
    // POST {db,table,key} record
    public static recordActivity = '/ajax/record/activity';
    // POST: { db, username }  tasks
    public static taskAssigned      = '/ajax/workflow/assigned';
    // POST: {db, username }   tasks
    public static taskAvailable     = '/ajax/workflow/available';
    // POST: {db, workitem,node_id} task
    public static taskClaim         = '/ajax/workflow/claim';
    // POST: {many things} task
    public static taskComplete      = '/ajax/workflow/complete';
    // POST: {db, workitem,wait,node_id } task
    public static taskContinue      = '/ajax/workflow/continue';
    public static taskQuery         = '/ajax/workflow/task/query';
    public static taskAssign         = '/ajax/workflow/task/assign';
    // POST: {db,id}
    public static taskAbandon       = '/ajax/workflow/task/abandon';
    // POST: {db,id} workitem '/ajax/workflow/readworkitemstate
    public static readWorkitemState = '/ajax/workflow/readworkitemstate';
    public static workflowDelete    = '/ajax/workflow/delete';
    // POST {db, procId) workflows for the process
    public static workflowQuery     = '/ajax/workflow/query';
    // POST: {db, workitem,node_id} task
    public static workflowRelease   = '/ajax/workflow/release';
    // POST: {db, workflow, wait } workflow
    public static workflowStart     = '/ajax/workflow/start';
    // POST: {db, username }   workflows
    public static workflowStartable = '/ajax/workflow/startable';
    // POST: {db, username }   workflows
    public static workflowStarted   = '/ajax/workflow/started';
    public static workflowUpdate   = '/ajax/workflow/update';
    public static workflowCreate   = '/ajax/workflow/create';

    public static workitemQuery   = '/ajax/workflow/workitem/query';
    public static workitemUpdate  = '/ajax/workflow/workitem/update';

    // Internal
    // clear

    // Availble but not exposed via the ajax interface
    // Detailed information about a workitem especially its note history
    //'query_workitem'   : '/ajax/workflow/query_workitem',

    // Get everything the UI needs to know about a workitem task that needs user action.
    //'taskinfo'  : '/ajax/workflow/taskinfo/:databaseName/:workflowId/:nodeId/',
}