import { PURGE } from 'redux-persist';
import { SET_AUTH_CONFIG, SET_CAN_EDIT_WORKITEMS, AuthState, AuthActionTypes } from './types';

const defaultState: AuthState = {
    config: null,
    canEditWorkitems: false,
};

export const authReducer = (state = defaultState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case SET_AUTH_CONFIG:
            return { ...state, config: action.payload.config };
        case SET_CAN_EDIT_WORKITEMS:
            //console.log("action in canEdit reducer: ", action.payload);
            return {...state, canEditWorkitems: action.payload.canEdit };
        case PURGE:
            return defaultState;
        default:
            return state;
    }
};
