import * as React from 'react';
import { Classes } from '@blueprintjs/core';

export interface ConfirmationInputProps {
    confirmationInputErrorText?: string;
    confirmationInputText?: string;
    enterPressed?: () => void;
    caseSensitive?: boolean;
    onError: (e) => void;
    onSuccess: (e) => void;
}

export class ConfirmationInput extends React.PureComponent<ConfirmationInputProps> {
    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
           if (this.props.enterPressed) {
               this.props.enterPressed();
           }
        }
    }
    private onInput = e => {
        const caseSensitive = this.props.caseSensitive == null ? false : this.props.caseSensitive;
        const confirmationInputText = this.props.confirmationInputText ? this.props.confirmationInputText : 'CONFIRM';
        if (caseSensitive) {
            if (e.target.value === confirmationInputText) {

                this.props.onSuccess(e);
            } else {

                this.props.onError(e);
            }
        } else {
            if (e.target.value.toLowerCase() === confirmationInputText.toLowerCase()) {

                this.props.onSuccess(e);
            } else {

                this.props.onError(e);
            }
        }
    }
    public render() {
        return (
            <div className='confirmation-input-wrapper'>
                <div style={{ padding: '.25rem' }} className='confirmation-input-error-text'>{ this.props.confirmationInputErrorText || `Please type ${this.props.confirmationInputText || 'CONFIRM'} to confirm.`}</div>
                <input autoFocus style={{ margin: '.25rem' }} className={`${Classes.INPUT} confirmation-input`} onKeyPress={this._handleKeyPress} onInput={this.onInput} />
            </div>
        );
    }
}