import { Layouts } from 'react-grid-layout';

export const defaultDashboardLayout: Layouts = {
  "lg": [
    {
      "w": 4,
      "h": 11,
      "x": 0,
      "y": 0,
      "i": "my-workitems",
      "minW": 4,
      "minH": 6,
      "moved": false,
      "static": false
    },
    // {
    //   "w": 4,
    //   "h": 11,
    //   "x": 4,
    //   "y": 0,
    //   "i": "computed-views",
    //   "minW": 3,
    //   "minH": 6,
    //   "moved": false,
    //   "static": false
    // },
    // {
    //   "w": 10,
    //   "h": 11,
    //   "x": 0,
    //   "y": 11,
    //   "i": "database-stats",
    //   "moved": false,
    //   "static": false,
    //   "minW": 5,
    //   "minH": 10
    // },
    // {
    //   "w": 4,
    //   "h": 11,
    //   "x": 0,
    //   "y": 0,
    //   "i": "workflow-activity",
    //   "minW": 4,
    //   "minH": 6,
    //   "moved": false,
    //   "static": false
    // }
  ],
  "md": [
    {
      "w": 4,
      "h": 7,
      "x": 0,
      "y": 0,
      "i": "my-workitems",
      "minW": 4,
      "minH": 6,
      "moved": false,
      "static": false
    },
    // {
    //   "w": 4,
    //   "h": 7,
    //   "x": 4,
    //   "y": 0,
    //   "i": "computed-views",
    //   "minW": 3,
    //   "minH": 6,
    //   "moved": false,
    //   "static": false
    // },
    // {
    //   "w": 10,
    //   "h": 11,
    //   "x": 0,
    //   "y": 13,
    //   "i": "database-stats",
    //   "minW": 5,
    //   "minH": 10,
    //   "moved": false,
    //   "static": false
    // },
    // {
    //   "w": 8,
    //   "h": 6,
    //   "x": 0,
    //   "y": 7,
    //   "i": "workflow-activity",
    //   "minW": 4,
    //   "minH": 6,
    //   "moved": false,
    //   "static": false
    // }
  ]
};
