export class LogUrl {
    public static addEvent     = '/ajax/log/status/add';
    public static clearEvent   = '/ajax/log/status/clear';  // Not permitted in a production environment
    public static queryEvent   = '/ajax/log/status/query';

    public static addAudit     = '/ajax/log/audit/add';
    public static clearAudit   = '/ajax/log/audit/clear';  // Not permitted in a production environment
    public static queryAudit   = '/ajax/log/audit/query';

    public static ping         = '/ajax/log/ping';
    public static version      = '/ajax/log/version';
}
