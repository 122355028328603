import { connect } from 'react-redux';
import * as React from 'react';

import { cleanObject } from '../../../utils';
import { claimTask, discardAssignedTask } from 'store/workflow/actions';
import { IOptionProps } from '@blueprintjs/core';
import { MDMWrapper } from '../../../components/Shared';
import { WorkflowSearchTable } from './WorkflowSearchTable';
import { WorkflowApi } from '../../../api'
import { WorkitemQueryBuilder } from './WorkItemQueryBuilder'
import { WorkitemEditModal } from '../WorkitemEditModal/WorkitemEditModal';
import { Process, Workflow, Workitem, WorkitemQueryFilter } from '../../../types';
import { ConfirmDialog } from '../../Shared/ConfirmDialog';
import WorkitemHistoryModal from './WorkitemHistoryModal'

export interface WorkflowSearchProps {
    canEditWorkitems: boolean;
    available: Workitem[];
    dbName: string;
    username: string;
    started: object[];
    discardAssignedTask: (workitem: Workflow, notes?: string) => void;
    claimTask: (workItemId: string, taskID: string) => void;
}

export interface WorkflowSearchState {
    workflowDropdownoptions: IOptionProps[];
    workitems: Workitem[];
    loading: boolean;
    queriedOnce: boolean;
    queryOnFinished: boolean;
    processes: Process[];
    currentEditWorkitem: Workitem;
    currentDeleteWorkitem: Workitem;
    currentHistoryWorkitem: Workitem;
    filter: WorkitemQueryFilter;
}

class WorkflowSearchBase extends React.Component<WorkflowSearchProps, WorkflowSearchState> {

    constructor(props) {
        super(props);
        this.state = {
            workflowDropdownoptions: [],
            workitems: [],
            loading: false,
            queryOnFinished: false,
            queriedOnce: false,
            processes: [],
            currentEditWorkitem: null,
            currentDeleteWorkitem: null,
            currentHistoryWorkitem: null,
            filter: null
        }
    }

    refreshData = () => {
        const { dbName } = this.props;

        WorkflowApi.queryWorkflows(dbName)
            .then(workflows => {
                const workflowOptions = workflows.map(workflow => {
                    const label = `${workflow.workflow_id} : ${workflow.description}`;
                    const value = workflow.description;
                    return { label, value }
                })
                this.setState({ workflowDropdownoptions: [...new Set(workflowOptions)] });
            });

        WorkflowApi.queryProcesses(dbName).then(processes => {
            this.setState({ processes })
        });
    }

    componentDidMount() {
        this.refreshData()
    }

    async componentDidUpdate(prevProps) {
        const { dbName } = this.props;

        if (prevProps.dbName !== dbName) {
            this.setState({ workitems: [], queriedOnce: false }, () => {
                this.refreshData()
            })
        }

    }

    queryRecords = () => {
        const { filter } = this.state;
        const { dbName } = this.props;
        this.setState({ loading: true, queryOnFinished: filter.finished }, () => {
            // This is inverted and bad OO design.  The filter should have a method to clean itself. E.g. filter.clean()
            const cleanFilter = cleanObject(filter);

            WorkflowApi.queryTasks(dbName, cleanFilter, { variables: true, history: true }).then(workitems => {
                this.setState({ workitems, loading: false, queriedOnce: true });
            });
        })
    }

    matchProcessDescription = (processId: number): string => {
        const { processes } = this.state;
        const process = processes.find(process => process.process_id === processId);
        return process ? process.description : '';
    }

    generateTable = () => {
        const { claimTask, available, canEditWorkitems, username } = this.props;
        const { workitems, loading, queryOnFinished, queriedOnce, processes } = this.state;

        if ((!workitems.length && !queriedOnce) || !processes.length)
            return null;
        const formattedWorkitems = workitems
            .map(workitem => {
                return ({
                    ...workitem,
                    process_label: `${workitem.process_id}: ${this.matchProcessDescription(workitem.process_id)}`,
                    workflow_label: `${workitem.workflow_id}: ${workitem.workflow_description || 'unnamed'}`,
                })
            })

        return <WorkflowSearchTable
            loggedUsername={username}
            deleteTask={this.deleteWorkitem}
            editTask={this.editWorkitem}
            viewWhatWasDone={this.viewWhatWasDone}
            canEditWorkitems={canEditWorkitems}
            claimTask={claimTask}
            workitems={formattedWorkitems}
            loading={loading}
            finished={queryOnFinished}
            availableWorkitems={available}
        />
    }

    editWorkitem = (workitem: Workitem) => this.setState({ currentEditWorkitem: workitem });
    deleteWorkitem = (workitem: Workitem) => this.setState({ currentDeleteWorkitem: workitem });
    viewWhatWasDone = (workitem: Workitem) => this.setState({ currentHistoryWorkitem: workitem });

    render() {
        const { workflowDropdownoptions, currentEditWorkitem, currentDeleteWorkitem } = this.state;
        const { discardAssignedTask, dbName } = this.props;
        return (
            <MDMWrapper title="Workitem Search" documentationPath="search-for-workitem.html">
                <WorkitemHistoryModal
                    workitem={this.state.currentHistoryWorkitem}
                    onClose={() => this.setState({ currentHistoryWorkitem: null })}
                />
                <WorkitemEditModal
                    isOpen={!!currentEditWorkitem}
                    workitem={currentEditWorkitem}
                    onClose={() => this.setState({ currentEditWorkitem: null })}
                    saveCallback={this.queryRecords}
                />
                <ConfirmDialog
                    isOpen={!!currentDeleteWorkitem}
                    body={`Are you sure you want to discard workitem #${currentDeleteWorkitem?.workitem_id}?`}
                    title='Discard Task'
                    cancelClick={() => this.setState({ currentDeleteWorkitem: null })}
                    okClick={() => {
                        this.setState({ currentDeleteWorkitem: null });
                        const workitem: Workflow = {
                            changesetId: null,
                            continue: false,
                            databaseName: dbName,
                            formId: null,
                            node_id: currentDeleteWorkitem.node_id,
                            schema: null,
                            tableName: null,
                            workitem_id: currentDeleteWorkitem.workitem_id,
                            variables: {}
                        }
                        discardAssignedTask(workitem);
                        this.refreshData();
                        this.queryRecords();
                    }}
                />
                <WorkitemQueryBuilder
                    workflowDropdownoptions={workflowDropdownoptions}
                    queryWorkitems={
                        (filter: WorkitemQueryFilter) => {
                            this.setState({ filter }, () => {
                                this.queryRecords();
                            })
                        }
                    }
                />
                {this.generateTable()}
            </MDMWrapper>
        );
    }
}

export const WorkflowSearch = connect(
    (state: any) => ({
        canEditWorkitems: state.auth.canEditWorkitems,
        dbName: state.database.selected,
        username: state.username,
        available: state.workflow.available,
    }), { claimTask, discardAssignedTask })(WorkflowSearchBase as any);