import * as React from 'react';
import { Classes, Dialog } from '@blueprintjs/core';
import { DatePicker3 as DateTimePicker} from '@blueprintjs/datetime2';
import { IconNames } from '@blueprintjs/icons';

import { Button } from '../Shared';

export interface ChangesetHistoryDialogProps {
    date: Date;
    handleDateChange: (date: Date) => void;
    isOpen: boolean;
    onClose: () => void;
}
export const ChangesetHistoryDialog: React.FC<ChangesetHistoryDialogProps> = ({date, handleDateChange, isOpen, onClose}) =>
    <Dialog icon={IconNames.CALENDAR} isOpen={isOpen} title="Select Date/Time for Historical View" onClose={onClose} >
        <div className={Classes.DIALOG_BODY}>
            <DateTimePicker
                value={date}
                timePrecision='minute'
                timePickerProps={{
                    useAmPm: true,
                }}
                onChange={handleDateChange} />
        </div>
        <div
            className={Classes.DIALOG_FOOTER}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
                style={{minWidth: '6rem'}}
                onClick={onClose}
                value="OK"
            />
            </div>
        </div>
     </Dialog>;
