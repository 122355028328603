import * as React from 'react';
import { Alert, Intent, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';

import { IPermNode } from './IPermNode';
import { PermDetailsTab } from './PermDetailsTab';
import { PermDetailsTitle } from './PermDetailsTitle';
import { PermApi } from '../../api/PermApi';
import { toTitlecase } from '../../utils';

import { mdmErrorToText } from 'helpers';
import { GroupApi } from '../../api/GroupApi';
import { isEqual } from 'lodash'

export interface PermDetailsProps {
    groups: any[];
    header?: string;
    node: IPermNode;
    systemGroupName: string;
    refreshFn: (node: IPermNode) => void;
}

interface FunctionHolder {
    upsertGroupFn: (name: string, permissions: string[]) => boolean;
    deleteGroupFn: (name: string) => boolean;
}

interface PermDetailsState {
    failureAlertOpen: boolean;
    failureText: string;
    availableGroupNames: string[];
    selectedTabId: string;
}


export class PermDetails extends React.Component<PermDetailsProps, PermDetailsState> implements FunctionHolder {
    constructor(props) {
        super(props);
        this.state = {
            failureAlertOpen: false,
            failureText: "Uninitialized",
            availableGroupNames: [],
            selectedTabId: undefined,
        };
    }

    componentDidMount() {
        this.updateAvailableGroups();
    }

    componentDidUpdate(prevProps) {
        const { groups } = this.props;
        if (!isEqual(prevProps.groups, groups))
            this.updateAvailableGroups();
    }

    updateAvailableGroups = () => {
        const {systemGroupName} = this.props;
        // Probably not the right place for this
        // Better name filtering?
        GroupApi.listAllGroups()
            .then((groups: any[]) => {
                let availableGroupNames = groups.map(group => group.group).filter(g => g !== systemGroupName);
                this.props.groups.forEach(group => {
                    const index = availableGroupNames.indexOf(group.group);
                    if (index >= 0) {
                        availableGroupNames[index] = '';
                    }
                });
                availableGroupNames = availableGroupNames.filter(g => g.length);
                // TODO - sort( (a,b) => a.localeCompare(b))?
                this.setState({ availableGroupNames: availableGroupNames.sort() });
            })
            .catch(err => alert("Server call to get groups failed. Couldn't retrieve groups: " + mdmErrorToText(err)));
    }

    /***********************************/
    /*  Function Holder Functions      */
    /***********************************/

    upsertGroupFn = (name: string, permissions: string[]) => {
        let success = true;
        if (name) {
            PermApi.Upsert({
                group: name,
                type: this.props.node.type,
                entity: this.props.node.fullNameFn(),
                perms: permissions,
            })
                .then(res => {
                    this.setState({
                        selectedTabId: name
                    });
                    return this.props.refreshFn(this.props.node);
                })
                .catch(err => {
                    console.error(err)

                    this.setState({
                        failureAlertOpen: true,
                        failureText: `Failed to create/update
                        group ${name} for
                        ${this.props.node.type} / ${this.props.node.fullNameFn()}.
                        Server returned ${err.error && err.error.messages && err.error.messages.join("\n") || "unknown server failure"}`,
                        selectedTabId: undefined,
                    });
                    success = false;
                });
        }
        else {
            const tab = this.props.groups.length ? this.props.groups[0].group : '+';
            this.setState({
                selectedTabId: tab
            }
            );
        }
        return success;
    }

    deleteGroupFn = (name: string) => {
        console.log(`Delete Permission Group ${this.props.node.type} / ${this.props.node.fullNameFn()}
        delete group ${name}`);
        let success = true;
        PermApi.Delete({
            entity: this.props.node.fullNameFn(),
            group: name,
            type: this.props.node.type
        }).then(res => {
            const tab = this.props.groups.length ? this.props.groups[0].group : '+';
            this.setState({
                selectedTabId: tab
            }
            );
            return this.props.refreshFn(this.props.node);
        })
            .catch(err => {
                this.setState({
                    failureAlertOpen: true,
                    failureText: `Failed to delete
                    group ${name} for
                    ${this.props.node.type} / ${this.props.node.fullNameFn()}.
                    Server returned ${err.err && err.error.messages && err.error.messages.join("\n") || 'internal error'}`
                });
                success = false;
            });
        return success;
    }

    makeATab(fnHolder: FunctionHolder, group: any, id: number) {
        const title = <PermDetailsTitle
            availableGroupNames={this.state.availableGroupNames}
            name={group.group}
            emptyTab={group.group === '+'}
            deletable={!group.inherited}
            deleteGroupFn={this.deleteGroupFn}
            upsertGroupFn={this.upsertGroupFn}
        />;

        const panel = (group.group === '+') ? undefined :
            <PermDetailsTab
                nodeName={`${toTitlecase(this.props.node.type)}: ${this.props.node.name}`}
                group={group}
                type={this.props.node.type}
                upsertGroupFn={this.upsertGroupFn}
                deleteGroupFn={this.deleteGroupFn}
            />;
        const t2 = <Tab
            id={group.group}
            key={id}
            title={title}
            panel={panel}
        />;
        return t2;
    }

    onChangeTab = (newTabId: string | number, prevTabId: string | number, event: any /*MouseEvent<HTMLElement>*/) => {
        this.setState({ selectedTabId: String(newTabId) });
    }

    render() {
        const self = this;
        const header = this.props.header ? this.props.header :
            `${toTitlecase(this.props.node.type.replace("_", " "))}: ${this.props.node.name}`;
        const tabs = this.props.groups.map((group, i) => {
            return self.makeATab(this, group, i);
        });
        //const selectedTabId = this.props.groups.length ? this.props.groups[0].group : undefined;
        // If there are group names available then
        if (this.state.availableGroupNames.length) {
            tabs.push(this.makeATab(this, { group: "+", inherited: false, perms: [] }, tabs.length));
        }
        return <div className="perm-details">
            <Alert
                icon={IconNames.WARNING_SIGN}
                intent={Intent.WARNING}
                isOpen={this.state.failureAlertOpen}
                onConfirm={() => { self.setState({ failureAlertOpen: false }); }}
            >
                <p>{this.state.failureText}</p>
            </Alert>
            <h4>{header}</h4>
            <Tabs
                large={true}
                id="permissions-details"
                selectedTabId={this.state.selectedTabId}
                onChange={this.onChangeTab} >
                {tabs}
            </Tabs>
        </div>;
    }
}
