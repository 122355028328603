import { isMDMName } from '../../utils';
import { MDMDatatype } from 'components/Shared';
import { DataTypes } from 'types';

export class TableEditFns {

    static nestedTableValidate = (field, original) => {
        // when we first create a table there may be no schema or fields
        const nestedFields = field.schema.fields;

        const fieldNames = nestedFields.map(field => field.field);
        const isDuplicate = fieldNames.some((name, idx) => fieldNames.indexOf(name) !== idx);
        if (isDuplicate) {
            return { ok: false, error: `Nested table "${field.field}" has repeated fields.` };
        }

        nestedFields.forEach((subField) => {
            const previous = original && original.schema.fields[subField.field];
            const invalid = TableEditFns.fieldValidate(subField, field, previous);
            if (invalid) {
                return {
                    ok: false,
                    error: invalid.error
                };
            }
        });

        return null;
    }

    // TODO - refactor fieldValidate to just return undefined/error

    static fieldValidate = (field, parent, previous, modify?: boolean) => {
        let ok = true;
        let error = '';

        const name = (parent) ? `${parent.field}.${field.field}` : field.field;

        if (!isMDMName(field.field)) {
            ok = false;
            error = `'${name}' is not a valid name. Only letters, numbers and underscores.`;
        }

        if (!MDMDatatype.valid(field.datatype)) {
            ok = false;
            if (!field.datatype.size) {
                error = `Field '${name}' has no size specified.`;
            } else {
                error = `Field '${name}' has an invalid data type, size, or sign.`;

                const type = field.datatype.type;
                const size = Number(field.datatype.size);
                if (type === DataTypes.text || type === DataTypes.binary) {
                    if (size < MDMDatatype.MIN_TEXT_SIZE) {
                        error = `Field '${name}' has a size under the minimum of ${MDMDatatype.MIN_TEXT_SIZE}.`;
                    } else if (size > MDMDatatype.MAX_TEXT_SIZE) {
                        error = `Field '${name}' exceeds the max size of ${MDMDatatype.MAX_TEXT_SIZE}.`;
                    }
                }
            }
        }
        // Only applies to edits of text fields
        if (previous && field.datatype.size < previous.datatype.size) {
            ok = false;
            error = `Field '${name}' cannot be decreased below ${previous.datatype.size}.`;
        }
        // Can't make a new field non-nullable (since it mightn't exist in previous data)
        if (!previous && modify && !field.nullable) {
            ok = false;
            error = `New field '${name}' must be nullable, since previous versions of the table wouldn't contain it`;
        }
        return ok ? undefined : { ok: ok, error: error };
    }
}