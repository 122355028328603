import * as React from 'react';
import { CustomIconName } from '../../../types';
import { getIcon } from './IconImporter';
import { getFocusedIcon } from './FocusedIconImporter';
import { getHeaderIcon } from './HeaderIconImporter';

interface CustomIconProps {
  icon: CustomIconName,
  focused?: boolean,
  headerIcon?: boolean,
  className?: string
}

export default class CustomIcon extends React.Component<CustomIconProps> {

  render() {
    const { icon, focused, headerIcon, className, ...props } = this.props;

    if (!icon && !headerIcon)
      return null;

    if (headerIcon)
      return <img className={className} src={getHeaderIcon(icon)} alt={icon} {...props} />

    return <img className={className} src={focused ? getFocusedIcon(icon) : getIcon(icon)} alt={icon} {...props} />
  }
}
