/**
 * @format
 */
import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { Checkbox,  Icon, IOptionProps, PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, CustomSelect } from 'components/Shared';
import { DataTypes, FilterField, Schema } from 'types';
import { hasPIIFields } from 'utils';
import { IconNames } from '@blueprintjs/icons';

import { Popover2 as Popover } from '@blueprintjs/popover2';
import { toTitlecase } from 'utils';
import { ReactNode } from 'react';

import './QueryRecordsForm.scss';

export interface QueryRecordsFormProps {
    initialOpenState?: boolean;
    showMaskedRecordsCheckbox?: boolean;
    initialField?: boolean;
    queryRecords: (filter: FilterField[], showMasked?: boolean) => void;
    updateCount?: (newCount: number) => void;
    schema: Schema;
    showMasked?: boolean
    needsQuery?: boolean
}

export interface QueryRecordsFormState {
    fields: FilterField[];
    masked: boolean;
    open: boolean;
}

const getNestedField = (schema: Schema, field: string) => {
    const fields = field.split('.');
    let currentSchema = schema;
  
    for (let i = 0; i < fields.length; i++) {
      const fieldSchema = currentSchema.fields.find(f => f.field === fields[i]);
      if (fieldSchema.datatype.type === 'table' && i < fields.length - 1) {
        currentSchema = fieldSchema.schema;
      } else {
        return fieldSchema;
      }
    }
  
    return null;
  };
  
  

export const QueryRecordsForm: FC<QueryRecordsFormProps> = ({
    schema,
    updateCount,
    showMasked,
    showMaskedRecordsCheckbox,
    initialField,
    queryRecords,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ReactElement<any, any> => {
    const operations = [
        { value: '=', label: "Equal to" },
        { value: '<>', label: "Not equal to" },
        { value: '>', label: "Greater than" },
        { value: '<', label: "Lesser than" },
        { value: '>=', label: "Greater or equal than" },
        { value: '<=', label: "Lesser or equal than" },
        { value: 'inrange', label: "In range" },
        { value: 'startswith', label: "Starts with" },
        { value: 'endswith', label: "Ends with" },
        { value: 'contains', label: "Contains" },
        { value: 'oneof', label: "One of" },
        { value: 'regex', label: "Regular expression matching" },
    ];
    const [isMasked, setIsMasked] = useState(false);

    const mapSchema = (schema: Schema): IOptionProps[] => { 
        if (!schema) return [];
        const result = [];

        schema.fields
            .filter(f => f.datatype.type !== 'table')
            .sort((a, b) => a.field.toLowerCase().localeCompare(b.field.toLowerCase()))
            .forEach((f) => {
                result.push({ label: f.field, value: f.field });
            });

        schema.fields
            .filter(f => f.datatype.type === 'table')
            .sort((a, b) => a.field.toLowerCase().localeCompare(b.field.toLowerCase()))
            .forEach((f) => {
                f.schema.fields
                    .sort((a, b) => a.field.toLowerCase().localeCompare(b.field.toLowerCase()))
                    .forEach((g) => {
                        const name = `${f.field}.${g.field}`;
                        result.push({ label: name, value: name });
                    });
            });

        if (schema.matching && schema.matching.enabled && schema.matching.display) {
            schema.matching.match_types.forEach((matchType: { type: string }) => {
                const name = toTitlecase(matchType.type) + " Group ID";
                result.push({ label: name, value: `MATCHGROUP_${matchType.type}_ID` });
            });
        }

        return result;
    }

    const fieldOptions = useMemo(() => mapSchema(schema), [schema]);

    const formSchema = yup.object().shape({
        fields: yup.array().of(
          yup.object().shape({
            field: yup.string().required().min(1),
            operation: yup.string().required().min(1),
            values: yup.string().required().min(1).test('values-test', ``, function (value) {
                
              const { field } = this.parent;
              if (!field) {
                return false;
              }

              if (field.startsWith("$match.")) {
                return true;
              }

              const fieldSchema = getNestedField(schema, field);


              let isValid = false;
              switch (fieldSchema.datatype.type) {
                case DataTypes.text:
                  isValid = value.trim().length > 0;
                  break;
                case DataTypes.integer:
                case DataTypes.money:
                case DataTypes.float:
                  isValid = !isNaN(Number(value));
                  break;
                case DataTypes.date:
                case DataTypes.datetime:
                case DataTypes.time:
                  isValid = !isNaN(Date.parse(value));
                  break;
                case DataTypes.boolean:
                  isValid = value === 'true' || value === 'false';
                  break
                case DataTypes.binary:
                  // Add your validation logic for binary type
                  isValid = true;
                  break;
                default:
                  isValid = true;
                  break;
              }

              if (!isValid) {
                return this.createError({ message: `Invalid value. Must be a valid ${fieldSchema.datatype.type}` });;
              }

                return true;
            })
          })
        )
      });

    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(formSchema),
        reValidateMode: 'onChange',
        mode: 'onChange',
        shouldFocusError: false,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fields',
    });

    const queryValues = watch('fields', []);

    const isMatchField = (field: string) => field.startsWith('$match.');

    const filterOperations = (field: string) => {
        if (!field) {
            return operations;
        }

        const fieldSchema = getNestedField(schema, field);

        if (!fieldSchema) {
            return operations;
        }

        if (isMatchField(field)) {
            return [{ value: '=', label: "Equal to" }];
        }

        return operations.filter(op => {
            switch (op.value) {
                case '>':
                case '<':
                case '>=':
                case '<=':
                case 'inrange':
                    return fieldSchema.datatype.type === DataTypes.integer || fieldSchema.datatype.type === DataTypes.money || fieldSchema.datatype.type === DataTypes.float;
                case 'startswith':
                case 'endswith':
                case 'contains':
                    return fieldSchema.datatype.type === DataTypes.text;
                case 'oneof':
                    return fieldSchema.datatype.type === DataTypes.text;
                case 'regex':
                    return fieldSchema.datatype.type === DataTypes.text;
                default:
                    return true;
            }
        });
    }

    useEffect(() => {
       if(updateCount) updateCount(fields.length);
    }, [fields, updateCount]);

    useEffect(() => {
        if (initialField && fields.length === 0) {
            append({ field: '', operation: '', values: '' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateTable = () => {
        if (!fields || !fields.length) {
            return (
                <div className="no-data-text-container">
                    <span className="text-medium">No queries added to the data</span>
                </div>
            )
        }

        return (
            <table className="fill-width">
                <thead>
                    <tr>
                        <th>Query field</th>
                        <th>Operation</th>
                        <th>Values</th>
                    </tr>
                </thead>
                <tbody className="query-form-tbody">
                    {fields.map((field, idx) => (
                        <tr key={field.id}>
                            <td className="select-td">
                                <Controller
                                    control={control}
                                    name={`fields.${idx}.field`}
                                    render={({ field }) => (
                                        <CustomSelect
                                            placeholder="Choose a field..."
                                            onChange={(item) => field.onChange(item.value)}
                                            value={field.value}
                                            options={fieldOptions}
                                            fill
                                        />
                                    )}
                                />
                            </td>
                            <td className="operation-td">
                                <Controller
                                    control={control}
                                    name={`fields.${idx}.operation`}
                                    render={({ field }) => (
                                        <>
                                            <CustomSelect
                                                placeholder="Choose an operation..."
                                                onChange={item => field.onChange(item.value)}
                                                value={field.value}
                                                name="operation"
                                                options={operations.filter(op => filterOperations(fields[idx].field).map(o => o.value).includes(op.value))}
                                                fill
                                            />

                                        </>
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    control={control}
                                    name={`fields.${idx}.values`}
                                    render={({ field }) => { 
                                        let hasError = false;
                                        const fld = schema.fields.find(f => f.field === fields[idx].field);
                                        const hasValues = fld?.values?.length > 0;
                                        if (errors.fields?.[idx]?.values) {
                                            hasError = true;
                                        }

                                        if (hasValues) {
                                            return (
                                                <CustomSelect 
                                                    placeholder="Choose a value..."
                                                    onChange={selectedOption => field.onChange(selectedOption.value)}
                                                    value={field.value}
                                                    name="values"
                                                    options={fld.values.map(v => ({label: v, value: v} as IOptionProps))}
                                                    fill
                                                />
                                            )
                                        }

                                        return (
                                            <>
                                                <input
                                                    className={`w-full rounded ${hasError ? 'has-error' : ''}`}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    // on enter
                                                    onKeyDown={e => { if (e.key == 'Enter') handleSubmit(query) }}
                                                />
                                                <span className="text-error text-xs">{errors.fields?.[idx]?.values?.message}</span>
                                            </>
                                            
                                        )
                                    }}
                                />

                            </td>
                            <td className="delete-row">
                                <Button
                                    onClick={() => remove(idx)}
                                    icon={IconNames.CROSS}
                                    minimal
                                    className="border-none"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    const onCancel = () => {
        // fields.clear();
    }

    const checkIfHardSearch = () => {
        let indexed = 0;
        let filters = 0

        queryValues.forEach((searchField) => {
            if (searchField?.field !== '') {
                if (searchField.field.startsWith("$match.")) {
                    ++filters;
                    ++indexed;
                }
                else if (searchField.field.includes(".")) {
                    const parts = searchField.field.split(".");
                    if (parts.length === 2) {
                        const field = schema.fields.find(f => f.field === parts[0]);
                        const subField = field.schema.fields.find(f => f.field === parts[1]);
                        ++filters;
                        indexed += (subField && subField.index) ? 1 : 0;
                    }
                }
                else {
                    const field = schema.fields.find(f => f.field === searchField.field);
                    ++filters;
                    indexed += (field && field.index) ? 1 : 0;
                }
            }
        })

        return filters > 0 && indexed === 0;
    }

    const query = (values: { fields: { field: string, operation: string, values: string }[] }) => { 
        queryRecords(
            values.fields.filter(field => {
                return field.field && field.operation;
            }).map(values => ({
                field: values.field,
                operation: values.operation,
                values: values.values.split(',').map(v => v.trim())
            } as FilterField)),
            showMasked ? isMasked : false,
        );
    }

    const countValidQueryFields = () => queryValues.filter(field => field.field && field.operation).length

    const onGenerateSearchButton = () => {
        const isHardSearch = checkIfHardSearch();
        const hasValidQueries = countValidQueryFields() > 0;

        if (isHardSearch) {
            const popoverContent = (
                <span className="popover-content">
                    This search is unindexed and may be slow or timeout
                </span>
            )

            return (
                <Popover
                    interactionKind={PopoverInteractionKind.HOVER}
                    position={PopoverPosition.BOTTOM}
                    content={popoverContent}
                    hoverOpenDelay={0}
                    defaultIsOpen
                >
                    <Button
                        className="minimal-button search-button"
                        onClick={handleSubmit(query)}
                        disabled={!hasValidQueries}
                    >
                        <Icon icon={IconNames.WARNING_SIGN} />
                        Search
                    </Button>
                </Popover>
            )
        }

        return (
            <Button
                className="minimal-button search-button"
                onClick={handleSubmit(query)}
                disabled={Object.keys(errors).length !== 0}
            >
                Search
            </Button>
        );
    }

    const generateMaskedOption = (): ReactNode => {
        if (!showMaskedRecordsCheckbox || !showMasked || !hasPIIFields(schema))
            return null;

        return (
            <Checkbox
                className="masked-records-checkbox"
                checked={isMasked}
                label="Include masked records"
                onChange={() => setIsMasked(!isMasked)}
            />
        )
    }

    console.log('errors', errors)
    return (
        <div className="query-form-panel-body">

            {generateMaskedOption()}
            {generateTable()}

            <div className="query-buttons-container flex-space-between">
                <Button
                    className="add-query-button"
                    title="Add query term"
                    onClick={() => append({ field: '', operation: '', values: '' })}
                    icon={IconNames.PLUS}
                    minimal
                >
                    &nbsp;&nbsp;Add Query
                </Button>

                <div className="flex-center-vertically fill-height">
                    <Button
                        className="cancel-button"
                        title="Cancel"
                        onClick={onCancel}
                        minimal
                    >
                        Cancel
                    </Button>

                    {onGenerateSearchButton()}
                </div>
            </div>
        </div>
    );
}
