import * as R from 'ramda';
import { string } from 'prop-types';
import { ToastPosition } from "react-toastify";
import { ARROW_LEFT } from '@blueprintjs/icons/lib/esm/generated/iconContents';

export type ErrorHandler = (error: any) => void;
// This should really be undefined, but the app is so riddled with
// instances of it and it's so prevalent in the middleware that I have to go with it for now
export const CHANGESET_CURRENT = "current";
export enum ChangesetStatus {
    COMMITTED = "committed",
    COMMITING = "committing",
    ERROR = "error",
    PENDING = "pending",
    QUEUED = "queued"
}
export enum ChangesetCategory {
    DATA = "data",
    METADATA = "metadata"
}
export interface Changeset {
    changeset: number;
    description: string;
    // "baseline":integer,
    category: string;
    status: ChangesetStatus;
    // "error_code":"string",
    // "error_message":"string",
    create_user: string;
    commit_user?: string;
    create_timestamp: string;
    commit_timestamp?: string;
    workitem_id?: number;
}
export interface ChangesetFilter {
    changeset?: number;
    status?: ChangesetStatus;
    create_timestamp?: {
        start: string;
        end: string;
    };
    description_contains?: string;
    category?: ChangesetCategory;
}
// Refactor out if this file becomes too big
export interface FilterField {
    field: string;
    operation: string;
    values: Array<string>;
}
export interface QueryFilter {
    fields?: FilterField[];
    committed_after?: any;
    match?: {
        type?: string;
        matched?: boolean;
        group?: string;
        override?: boolean;
    };
    deleted?: boolean;
    masked?: boolean;
    conflict?: string;
    version_lo?: number;
    primary_key?: string;
}
export interface QueryInclude {
    match?: boolean;
    conflict?: boolean;
    version?: boolean;
    mask?: boolean;
}
export interface OperationSummary {
    table: string;
    operation: string;
    count: number;
    limit_exceeded: boolean;
}
export interface Role {
    id: string;
    display: string;
}

export type Roles = Role[];

export interface Group {
    group: string;
    description?: string;
    roles?: string[];
}

export type Groups = Group[];

export interface User {
    username?: string;
    authority?: string;
    // TODO: standardize on capitalization
    full_name?: string;
    password?: string;
    email?: string;
    description?: string;
    groups?: string[];
}

export type Users = User[];

export enum Severity {
    debug,
    info,
    warning,
    danger
}

export interface Database {
    database: string;
    description: string;
    create_timestamp?: string;
    create_user?: string;
    state: string;
}
// TODO: support for complex sub types, record and table
// TODO: enum for error code? Will we still be able to handle something that's
// not in the enum?
export interface MDMServerError {
    error_code: string;
    messages: string[];
    original_message: string;
    stack_trace: string;
}
export enum DataTypes {
    binary = "binary",
    boolean = "boolean",
    date = "date",
    datetime = "datetime",
    float = "float",
    integer = "integer",
    money = "money",
    text = "text",
    time = "time",
    table = "table"
}
export interface DataType {
    type: DataTypes;
    size?: number;
    signed?: boolean;
}

export interface NewField extends Field {
    isNew?: boolean;
}

export interface NewSchema extends Schema {
    fields: NewField[];
}

export interface Field {
    display: boolean;
    field: string;
    nullable: boolean;
    pii: boolean;
    index: boolean;
    datatype: DataType;
    schema?: Schema;
    semantic_type?: string;
    links_to?: string;
    link_display_field?: string;
    values?: string[];
}
export interface MaskHash {
    description: string;
    fields: { field: string }[];
}

export enum SchemaType {
    versioned = "versioned",
    timeseries = "timeseries",
}
export interface Schema {
    primary_key: string;
    sequence_field?: string;
    type: SchemaType;
    fields: Field[];
    matching?: { enabled: boolean, display: boolean, match_types?: any[] };
    mask_hashes?: MaskHash[];
}
export interface Table {
    database: string;
    table: string;
    description: string;
    schema: Schema;
    // "indexes":[same as table-create],
    // "create_user":"string",
    // "create_timestamp":"string",
    // "rpmdm_version":{optional version document},
    // "rpmdm_conflict":{optional conflict document}
}
export interface History {
    action?: string;
    type: string;
    timestamp: string;
    node_id?: string;
    old_state?: string;
    new_state?: string;
    error_code?: string;
    error_text?: string;
    message: string;
    username: string;
    note: string;
    record?: {
        message?: string;
        username?: string;
        action?: string;
        note?: string;
    }
}


export interface Schema {
    primary_key: string;
    fields: Field[];
}
// This madness allows us to define our json records with an unknown number of
// columns, and still definie subdocument types
export interface RecordFields<T> {
    [key: string]: T;
}
export type MDMRecordBase<T> = RecordFields<T> & {
    rpmdm_match?: {
        [matchType: string]: {
            type: string;
            group?: {
                id: string;
            };
        }
    }
};
export type MDMRecord = MDMRecordBase<string>;

export interface MDMToasterProps {
    timestamp: number;
    intent: Intent;
    message: string;
    position: ToastPosition;
}
export interface ValidationErr {
    message: string;
    projection?: string;
    aggregation_rule?: number;
    stack_trace?: string;
}

export interface ValidationResult {
    valid?: boolean;
    errors?: ValidationErr[];
}

export enum SubscriptionDeliveryType {
    azurequeue = "azurequeue",
    azuretopic = "azuretopic",
    countmock = "countmock",
    file = "file",
    mock = "mock",
    webservice = 'webservice',
}
export const SubscriptionDeliveryDescription = {
    "azurequeue": "Azure Service Bus Queue",
    "azuretopic": "Azure Service Bus Topic",
    "countmock": "Mocked (counts only)",
    "file": "File",
    "mock": "Mocked Service (record delivery)",
    "webservice": "Web Service",
};
export interface Subscription {
    subscription_id?: number;
    description: string;
    view_id: number;
    delivery: SubscriptionDelivery;
    options: SubscriptionOptions;
    state?: SubscriptionState;
}
export interface SubscriptionState {
    enabled: boolean;
    disabled_reason: string;
    in_ageout: boolean;
    retrying: boolean;
    target_sequence: number;
    sync_sequence: number;
    delivery_success_timestamp: string;
}
export interface SubscriptionDelivery {
    max_changes: number;
    type: SubscriptionDeliveryType;
    age_out: {
        window_start: string;
        window_end: string;
    };
    webservice: {
        url: string;
    };
    failure_strategy: {
        retry_seconds_start: number;
        retry_seconds_max: number;
    };
    file: {
        target_folder: string;
        pattern: string;
        frequency: number;
        format: string;
        with_csv_header: boolean;
    };
    // Azure Service Bus support
    azure: {
        connection_string: string;
        queue: string;
        topic: string;
    };
}
export interface SubscriptionOptions {
    delete_full_record: boolean;
    modify_old_record: boolean;
    modify_new_record: boolean;
    modify_diff: boolean;
}
export enum SubscriptionSync {
    nochange = "nochange",
    now = "now",
    start = "start"
}
// export const SubscriptionSyncDescription = {
//     "nochange": "Sync Unchanged",
//     "start": "Start",
//     "now": "Now"
// };
export interface View {
    description: string;
    create_timestamp: number;
    create_user: string;
    view_id: number;
    state: {
        validation?: ValidationResult
    };
}

// This is not nearly all of the fields that are contained in a workflow
export interface Workflow {
    changesetId: string;
    continue: boolean;
    databaseName: string;
    formId: string;
    node_id: string;
    schema: Schema;
    tableName: string;
    workitem_id: number;
    variables: {
        [key: string]: string;
    };
    start_timestamp?: string;
    end_timestamp?: string;
    minWidth?: number;
}

export interface WorkitemVariables {
    start_groups: string;
    start_time: string;
    start_user: string;
    match_keys: string;
    action: any;
    match_type: string;
    primary_key: null | string;
    table: string;
    changeset: number;
    due_date: string;
}

export interface Workitem {
    database: string;
    active: boolean;
    nodes: string[];
    connections: string[];
    variables: WorkitemVariables;
    history: any[];
    workitem_id: number;
    process_id: number;
    process_label: string;
    workflow_id: number;
    workflow_description: string;
    start_timestamp: string;
    started_by_user: string;
    end_status: string;
    end_timestamp: string;
    due_date: string;
    error_code: string;
    error_message: string;
    error_stack_trace: string;
    node_id: string;
    node_name: string;
    assigned_user: string;
    last_user: string;
}

export interface ReviewWorkflow {
    oldRecord?: object;
    record: object;
    workflow: Workflow;
}
export interface MatchWorkflow {
    acceptedGroups: string[][];
    proposedGroups: string[][];
    records: object[];
    workflow: Workflow;
}

// https://redpoint.atlassian.net/wiki/spaces/MDM/pages/104300672/MDM+Workflow+Definition#MDMWorkflowDefinition-Forms
export enum WorkflowAction {
    approve = 'approve',
    reject = 'reject',
    save = 'save',
    submit = 'submit',
    discard = 'discard',
    choose_proposed = 'choose_proposed',
    custom = 'custom',
    choose_accepted = 'choose_accepted'
}
export interface Process {
    database: string;
    process_id: number;
    description: string;
}
export interface ProcessDef {
    database: string;
    description: string;
    process: string; // the XML
    process_id?: number;
    variables?: any;     // TODO - get the actual type
}
export interface WorkflowDef {
    database: string;
    process_id: number;
    workflow_id: number;
    description: string;
    ui_visible: boolean;
    completion_hours: number;
    variables: {
        [key: string]: string;
    };
}
export interface WorkitemQueryFilter {
    finished: boolean;
    workflow_description?: string;
    workflow_contains?: string;
    assigned_to_user?: string;
    assigned?: boolean;
    available_to_user?: string;
    started_by_user?: string;
    start_timestamp?: { start: string, end: string };
    end_timestamp?: { start: string, end: string };
    end_status?: string;
}

export interface WorkitemQueryInclude {
    nodes?: boolean;
    connections?: boolean;
    variables?: boolean;
    history?: boolean;
}

export enum Intent {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    DANGER = "danger",
    INFO = "info",
    PRIMARY = "info"
}

export enum CustomIconName {
    DASHBOARD = "dashboard",
    EXCHANGE = "exchange",
    HELP = "help",
    PLUS = "plus",
    NEW_DOCUMENT = "new-document",
    LAYER = "layer",
    LAYERS = "layers",
    DATABASE = "database",
    TABLE = "table",
    STACKED_CHART = "stacked-chart",
    EYE_OPEN = "eye-open",
    ERASER = "eraser",
    TICK_ERASER = "tick-eraser",
    PERSON = "person",
    PEOPLE = "people",
    KEY = "key",
    ARROW_RIGHT = "arrow-right",
    ARROW_LEFT = "arrow-left",
    SWAP = "swap",
    GRAPH = "graph",
    COG = "cog",
    BADGE = "badge",
    WRITE = "write",
    CIRCLE_ARROW_RIGHT = "circle-arrow-right",
    CHEVRON_DOWN = "chevron-down",
    FLOW_ARROW = "flow-arrow",
    PROCESS = "process",
    LOGS = "logs"
}


export interface PanelContents {
    showPanel?: () => void;
    hidePanel?: () => void;
}