import * as React from 'react';
import { Button, Collapse } from '@blueprintjs/core';
import { QueryRecordsForm } from 'components/QueryRecordsForm';
import { IconNames } from '@blueprintjs/icons';
import { Schema } from 'types';
import TableListDropdown from 'components/Shared/TableListDropdown';

interface RecordQueryBuilderWrapperProps {
    currentTable: string;
    tables: string[];
    schema: Schema;
    onTableChange: (options: any) => void
    queryRecords: (fields, masked: boolean) => void
    updateQueryFilterOpen: (showing?: boolean) => boolean
}

export interface RecordQueryBuilderWrapperState {
    queryCount: number;
}


const RecordQueryBuilderWrapper: React.FC<RecordQueryBuilderWrapperProps> = (props) => {
    const { currentTable, schema, onTableChange, queryRecords, updateQueryFilterOpen } = props;
    
    const [queryCount, setQueryCount] = React.useState(0);

    const toggledClassName = updateQueryFilterOpen() ? "important-blue" : "important-gray"
    // This has been problematic in keeping it in sync with reality
    //const queryCountMessage = `${queryCount > 0 ? queryCount : ''} ${queryCount > 1 ? 'Queries' : 'Query'}`
    const queryCountMessage = 'Query';

    const renderToggleButton = () => {
        if (!currentTable)
            return null;
        return (
            <Button
                className={`simple-input query-toggle-button flex-start ${toggledClassName}`}
                title="Query"
                onClick={() => updateQueryFilterOpen(!updateQueryFilterOpen())}
                icon={IconNames.SEARCH}
                outlined
                minimal
            >
                {queryCountMessage}
            </Button>
        )
    }

    return (
        <>
            <div className="table-select-widget flex-center-vertically">
                <span className="table-select-text">I want to view data for</span>

                <TableListDropdown 
                    currentTable={currentTable}
                    onTableChange={onTableChange}
                />

                {renderToggleButton()}

            </div>

            {currentTable &&
                <Collapse isOpen={updateQueryFilterOpen()}>
                    <QueryRecordsForm
                        initialOpenState={true}
                        initialField={true}
                        queryRecords={queryRecords}
                        schema={schema}
                        showMasked={true}
                        table={currentTable}
                        showMaskedRecordsCheckbox={true}
                        updateCount={setQueryCount}
                    />
                </Collapse>}
        </>

    )
}

export default RecordQueryBuilderWrapper;