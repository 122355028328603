import { PURGE } from 'redux-persist';

export const SET_AUTH_CONFIG = 'SET_AUTH_CONFIG';
export const SET_CAN_EDIT_WORKITEMS = 'SET_CAN_EDIT_WORKITEMS';

export interface AuthState {
    config: AuthConfig;
    canEditWorkitems: boolean;
}

export interface AuthConfig {
    systemUsername: string;
    systemGroupName: string;
    systemGroupDescription: string;
    everyoneGroupName: string;
    everyoneGroupDescription: string;
    mdmVersion: string;
    idleLogoutMilliseconds: string;
}

interface SetAuthSettingsAction {
    type: typeof SET_AUTH_CONFIG;
    payload: { config: AuthConfig };
}

interface SetCanEditWorkitems {
    type: typeof SET_CAN_EDIT_WORKITEMS;
    payload: { canEdit: boolean };
}

interface ResetAuth {
    type: typeof PURGE;
}

export type AuthActionTypes = SetCanEditWorkitems | SetAuthSettingsAction | ResetAuth;
