import * as React from 'react';
import * as moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactTable } from 'components/Shared/ReactTable';
import { AuditItem, EventItem } from 'rp-mdm-common';
import { DateFormatEnum, customDateFormat, formatDateTimeDisplay } from 'helpers/date-format';
import { DataTypes, Field } from 'types';
import { customRandom } from 'nanoid';

type LogsTableProps = {
    logs: AuditItem[] | EventItem[];
    paginate: () => void;
}

const LogsTable: React.FC<LogsTableProps> = ({ logs, paginate }) => {
    const colHelper = createColumnHelper<AuditItem | EventItem>();
    const columns = [
        colHelper.accessor('timestamp', {
            header: 'Timestamp',
            enableResizing: true,
            cell: ({ cell }) => <div>{customDateFormat(cell.getValue(), DateFormatEnum.timestampDisplay)}</div>
        }),
        colHelper.accessor('username', {
            header: 'User',
            enableResizing: true
        }),
        colHelper.accessor('target', {
            header: 'Target',
            enableResizing: true
        }),
        colHelper.accessor('action', {
            header: 'Action',
            enableResizing: true
        }),
        colHelper.accessor('area', {
            header: 'Area',
            enableResizing: true
        }),
        colHelper.accessor('message', {
            header: 'Message',
            enableResizing: true
        }),
        colHelper.accessor('parameters', {
            header: 'Parameters',
            enableResizing: true,
            cell: ({ cell }) => <div className="text-wrap">{cell.getValue()}</div>
        })
    ];

    return (
        <ReactTable
            data={logs}
            columns={columns}
            paginate={paginate}
            resultCount={logs.length}
        />
    );
}

export default LogsTable;