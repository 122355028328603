import * as React from 'react';
import { Icon, Intent, Position, Tooltip} from '@blueprintjs/core';

import { Button } from '../../Shared/Button';
import { CompareRecordsTable } from '../../CompareRecordsTable/CompareRecordsTable';
import { WorkflowReviewRecordTable } from './WorkflowReviewRecordTable';
import { NotesTable } from '../../NotesTable/NotesTable';
import { History, Schema, Workflow } from 'types';
import { WorkflowWidgets } from '../WorkflowWidgets';

require('helpers/styles/workflow.scss');
require('./WorkflowReviewRecord.scss');

export interface WorkflowReviewRecordFormProps {
    history: History[];
    oldRecord: object;
    newRecord: object;
    schema: Schema;
    workflow: Workflow;
    approveTask: (button:HTMLButtonElement, record: object, note?: string) => void;
    rejectTask: (button:HTMLButtonElement, record: object, note?: string) => void;
    discardTask: (button:HTMLButtonElement, note?: string) => void;
}
export interface WorkflowReviewRecordFormState {
    numChanges: number;
    modifiedRecord: object;
    note: string;
}

export class WorkflowReviewRecordForm extends React.Component<WorkflowReviewRecordFormProps, WorkflowReviewRecordFormState> {
    constructor(props: WorkflowReviewRecordFormProps) {
        super(props);
        this.state = {
            numChanges: this.getChanges(props.oldRecord, props.newRecord),
            modifiedRecord: { ...props.newRecord },
            note: ''
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: WorkflowReviewRecordFormProps, prevState) {
        const { oldRecord, newRecord, schema } = this.props;
        if (oldRecord !== prevProps.oldRecord || newRecord !== prevProps.newRecord || schema !== prevProps.schema) {
            const changes = this.getChanges(oldRecord, newRecord);
            this.setState({ modifiedRecord: { ...newRecord }, numChanges: changes });
        }
    }

    getChanges(oldRecord: object, newRecord: object): number {
        return oldRecord ? Object.keys(oldRecord).reduce((prev, key) => {
            return prev + (oldRecord[key] !== newRecord[key] ? 1 : 0);
        }, 0) : 0;
    }

    onFieldChange = (key: string, newValue: any) => {
        const modifiedRecord = { ...this.state.modifiedRecord, [key]: newValue };
        const numChanges = this.getChanges(this.props.oldRecord, modifiedRecord);
        this.setState({ modifiedRecord: modifiedRecord, numChanges: numChanges });
    }

    onNoteChange = (newNote: string) => {
        this.setState({ note: newNote });
    }

    approveTask = (button:HTMLButtonElement) => {
        this.props.approveTask(button, this.state.modifiedRecord, this.state.note);
    }

    discardTask = (button:HTMLButtonElement) => {
        this.props.discardTask(button, this.state.note);
    }

    rejectTask = (button:HTMLButtonElement) => {
        this.props.rejectTask(button, this.state.modifiedRecord, this.state.note);
    }

    render() {
        const { workflow } = this.props
        const numChanges = this.state.numChanges;
        const multiRecordDisplay: boolean = !!this.props.oldRecord;
        const changesDisplay = <div className="changes-wrapper"><div className="changes-oval" />
            {multiRecordDisplay ? <div>{`${numChanges} Proposed Change${numChanges !== 1 ? "s" : ""}`}</div> : <div>Proposed New Record</div>}
        </div>;

        return (
            <div className="workflow-wrapper">
                <div className="workflow-title">Data Change Review
                    <Tooltip content="Documentation" position={Position.RIGHT}>
                        <div className="mdm-wrapper-docs-button flex-center">
                            <Icon icon='help' onClick={() => { window.open(`/mdm-help/workflow_edit_existing_record_steward.html`, 'mdm-help'); }} color="#636567" iconSize={20} />
                        </div>
                    </Tooltip>
                </div>
                <div className="workflow-menu-wrapper">
                    <div className="workflow-menu">
                        {changesDisplay}
                        <div className="workflow-title-buttons">
                            <Button value="DISCARD" onClick={(e) => this.discardTask(e.currentTarget)} />
                            <Button value="REJECT" intent={Intent.DANGER} onClick={(e) => this.rejectTask(e.currentTarget)} />
                            <Button value="APPROVE" intent={Intent.SUCCESS} onClick={(e) => this.approveTask(e.currentTarget)} />
                        </div>
                    </div>
                    <WorkflowWidgets tableName={workflow.tableName} workitemId={workflow.workitem_id} />
                </div>
                {multiRecordDisplay ? <CompareRecordsTable oldRecord={this.props.oldRecord} newRecord={this.state.modifiedRecord}
                    schema={this.props.schema} updateFieldCb={this.onFieldChange} /> :
                    <WorkflowReviewRecordTable tableName={workflow.tableName} schema={this.props.schema} record={this.props.newRecord} modifiedRecord={this.state.modifiedRecord}
                        updateFieldCb={this.onFieldChange}
                    />}
                <NotesTable history={this.props.history} note={this.state.note} updateNote={this.onNoteChange} />
            </div>
        );
    }
}