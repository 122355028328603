import * as React from 'react';
import { connect } from 'react-redux';
import { WorkflowApi } from '../../api';
import { setPeriodicData } from '../../actions'
import { setAssignedWorkflows, setAvailableWorkflows } from 'store/workflow/actions'
import { Workflow } from 'types';

const MAX_FETCH_IGNORE_MS = 120 * 1000; // 2 minutes

export interface PeriodicfetchProps {
    dbName: string,
    username: string,
    available: any[];
    assigned: any[];
    lastUpdated: number | null;
    fetchBadges: boolean;
    setPeriodicData?: setPeriodicData;
    setAssignedWorkflows: (workflows: Workflow[]) => void;
    setAvailableWorkflows: (workflows: Workflow[]) => void;
}

export interface PeriodicFetchState {
    fetched: boolean;
    badgeValue: any;
    dataRefreshRate: number;
    fetching: boolean;
    lastFetchTime: number;
}

export class PeriodicFetchBase extends React.Component<PeriodicfetchProps, PeriodicFetchState> {

    private timer: NodeJS.Timer;
    private isComponentMounted: boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            badgeValue: null,
            dataRefreshRate: 5000,
            fetching: false,
            lastFetchTime: 0
        };
    }

    componentDidUpdate(prevProps) {
        const { dbName, available, assigned, lastUpdated, fetchBadges } = this.props;
        const assignedChanged = prevProps.assigned.length !== assigned.length;
        const availableChanged = prevProps.available.length !== available.length;

        if (prevProps.dbName !== dbName)
            this.setState({ fetched: true }, this.refreshWorkflowData);

        if (prevProps.fetchBadges !== fetchBadges) {
            fetchBadges ? this.startTimer() : clearInterval(this.timer);
        }
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
        clearInterval(this.timer);
    }

    componentDidMount() {
        this.isComponentMounted = true;
        if (!this.state.fetched) {
            this.setState({ fetched: true }, this.refreshWorkflowData);
        }

        this.startTimer();
    }

    refreshWorkflowData = async () => {
        const { dbName, username, setAssignedWorkflows, setAvailableWorkflows, setPeriodicData } = this.props;
        let assignedWorkflows;
        let availableWorkflows;

        if (window.location.href.indexOf("/login") > -1) {
            console.log("in login page!!")
            clearInterval(this.timer);
            return;
        }

        // things can get into a messed up state so limit how long we will not fetch
        if(!this.state.fetching || Date.now() - this.state.lastFetchTime > MAX_FETCH_IGNORE_MS) {
            // We can only do this if we are mounted
            if(this.isComponentMounted)
                this.setState({fetching: true, lastFetchTime: Date.now()});

            try {


                assignedWorkflows = await WorkflowApi.getAssignedWorkflows(dbName, username, false);
                availableWorkflows = await WorkflowApi.getAvailableWorkflows(dbName, username, false);
                if(this.isComponentMounted)
                    this.setState({fetching: false });
                        
                setAssignedWorkflows(assignedWorkflows);
                setAvailableWorkflows(availableWorkflows);
                setPeriodicData(assignedWorkflows.length, availableWorkflows.length);
            }
            catch (e) {
                clearInterval(this.timer);
                return;
            }
        }

    }

    startTimer() {
        const { fetchBadges } = this.props;
        if (fetchBadges)
            this.timer = setInterval(this.refreshWorkflowData, this.state.dataRefreshRate);
    }

    render() {
        return null;
    }
}

export const PeriodicFetch: any = connect(
    (state: any) => ({
        dbName: state.database.selected,
        username: state.username,
        available: state.workflow.available,
        assigned: state.workflow.assigned,
        lastUpdated: state.workflow.lastUpdated,
        fetchBadges: state.systemSettings.fetchBadges,
    }),
    { setPeriodicData, setAssignedWorkflows, setAvailableWorkflows }
)(PeriodicFetchBase);