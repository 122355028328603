import * as React from 'react';
import { Icon, Position, Tooltip, Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Pagination } from 'rp-mdm-common';
import './ApiPaginator.scss'

interface ApiPaginatorProps {
  resultCount: number;
  limit?: number;
  paginate?: (pagination: Pagination) => void;
  refreshTag?: string;
}

interface ApiPaginatorState {
  paginationLimit: number;
  currentPaginationIndex: number;
}

class ApiPaginator extends React.Component<ApiPaginatorProps, ApiPaginatorState> {

  constructor(props) {
    super(props);
    this.state = {
      paginationLimit: props.limit || 100,
      currentPaginationIndex: 0
    };
  }

  componentDidUpdate(prevProps) {
    const { refreshTag } = this.props;
    if (prevProps.refreshTag && prevProps.refreshTag !== refreshTag) {
      this.setState({ currentPaginationIndex: 0 });
    }
  }

  paginate = (pagination?: Pagination) => {
    const { resultCount, paginate } = this.props;
    const { currentPaginationIndex, paginationLimit } = this.state;
    const newPaginationStart = currentPaginationIndex + resultCount
    const customPagination = pagination || { start: newPaginationStart, count: paginationLimit }

    this.setState({ currentPaginationIndex: newPaginationStart }, () => {
      paginate(customPagination);
    })
  }

  resetPagination = () => {
    const { paginationLimit } = this.state;
    const { paginate } = this.props;
    const customPagination = { start: 0, count: paginationLimit };

    this.setState({ currentPaginationIndex: 0 }, () => {
      paginate(customPagination);
    })
  }

  generateFetchNextButton = () => {
    const { resultCount, paginate } = this.props;
    const { paginationLimit, currentPaginationIndex } = this.state;

    if (!paginate || (resultCount < paginationLimit && currentPaginationIndex == 0))
      return null;

    const reachedEnd = resultCount < paginationLimit

    const popoverContent = (
      <span className="popover-content">
        {reachedEnd ? "Restart" : `Fetch next ${paginationLimit}`}
      </span>
    )

    return (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER}
        content={popoverContent}
        position={Position.BOTTOM}
      >
        {
          reachedEnd ? (
            <button className="minimal-button paginate-button" onClick={() => this.resetPagination()}>
              <div className="flex-center fill">
                <Icon iconSize={12} icon={IconNames.RESET} />
              </div>
            </button>
          ) : (
            <button className="minimal-button paginate-button" onClick={() => this.paginate()}>
              <div className="flex-center fill">
                <Icon iconSize={12} icon={IconNames.FAST_FORWARD} />
              </div>
            </button>
          )
        }
      </Popover>
    )
  }



  generateText = () => {
    const { resultCount } = this.props;
    const { currentPaginationIndex } = this.state;

    if (!resultCount)
      return (
        <>
          <span className="widget-number">{resultCount.toLocaleString()}</span>
          <span className="widget-text">Results</span>
        </>
      )

    const startNumber = currentPaginationIndex + 1
    return (
      <>
        <span className="widget-text">Results</span>
        <span className="widget-number">{(startNumber).toLocaleString()}</span>
        <span className="widget-text">to</span>
        <span className="widget-number">{(currentPaginationIndex + resultCount).toLocaleString()}</span>
      </>
    )
  }

  render() {
    return (
      <div className="api-paginator">
        {this.generateText()}
        {this.generateFetchNextButton()}
      </div>
    )
  }
}

export default ApiPaginator;