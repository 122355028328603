import * as React from 'react';
import { ITreeNode, Tooltip } from "@blueprintjs/core";

import { displayMessageBox } from '../../actions/ActionCreators';
import { v4 as uuidv4 } from 'uuid';
import { IPermNode } from './IPermNode';
import { MDMStore } from 'store';
import { mdmErrorToText } from "helpers";
import { PermSubscriptionNode } from './PermSubscriptionNode';




export class PermViewNode implements IPermNode {
    id:string;
    name: JSX.Element|string;
    type:string;
    isOpen: false;
    canBeSelected: boolean;
    isLeaf: boolean;
    openIcon: string;
    closedIcon: string;
    children: IPermNode[];
    parent: IPermNode;
    view_id: number;
    description: string;

    constructor(name: JSX.Element|string, view_id:number, description:string, parent:IPermNode) {
        this.id = uuidv4();
        this.name = name;
        this.type = 'view';
        this.isOpen = false;
        this.canBeSelected = true;
        this.isLeaf = false;
        this.openIcon = 'eye-open';
        this.closedIcon = 'eye-open'; // unused
        this.children = [];
        this.parent = parent; // database
        this.view_id = view_id;
        this.description = description;
    }

    async getChildrenFn() {
        const self = this;
        const parent = this.parent;

        try {
            // Get views from Core Service
            const result = await {
                payload: {
                    subscriptions: [
                        { view_id: 1,  subscription_id: 1, description: "Description 1 / 1"},
                        { view_id: 1,  subscription_id: 2, description: "Description 1 / 2"},
                        { view_id: 1,  subscription_id: 3, description: "Description 1 / 3"},
                        { view_id: 2,  subscription_id: 4, description: "Description 2 / 4"},
                        { view_id: 2,  subscription_id: 5, description: "Description 2 / 5"},
                        { view_id: 3,  subscription_id: 6, description: "Description 3 / 6"},
                    ]
                }
            };
            const subscriptions = result.payload.subscriptions.filter( subscription => {
                return subscription.view_id === self.view_id;
            }).map( subscription => {
                let label:string|JSX.Element = `${subscription.subscription_id}: ${subscription.description}`;
                if(subscription.description.length > 24) {
                    label = (<Tooltip content={subscription.description}>
                        {`${subscription.subscription_id}: ${subscription.description.substring(0, 24)}`}
                        </Tooltip>);
                }
                return new PermSubscriptionNode(
                    label,
                    subscription.subscription_id,
                    subscription.description,
                    this
                );
            });
            self.children =  subscriptions.sort((a, b) => a.subscription_id - b.subscription_id);
        }
        catch(error) {
            console.error(error);
            error = error.error || error;
                MDMStore.dispatch<any>(displayMessageBox({
                    title: 'List Views Failed',
                    message: `Failed to list views: ${ mdmErrorToText(error) }`,
                    stack: error && error.stack,
                }));
        }
        return true;
    }

    fullNameFn() {
        // const parentName = this.parent ? this.parent.fullNameFn() : "";
        // return parentName ? `${parentName}.${String(this.view_id)}` : String(this.view_id);
        return `${this.parent.fullNameFn()}.${this.view_id.toString()}`;
    }

    myName() {
        return this.view_id.toString();
    }

    getBPNode() {
        const childrenBP = this.children.map( child => child.getBPNode() );
        return {
            id: this.id,
            hasCaret: true,
            isExpanded: this.isOpen,
            label: this.name,
            iconName: this.openIcon,
            childNodes: childrenBP,
        } as ITreeNode;
    }

    header() {
        return `View: ${this.description}`;
    }
}
