import * as React from 'react';
import { connect } from 'react-redux';

import { Workflow } from 'types';
import { getCurrentWorkflow } from 'store/workflow/selectors';
import { clearTaskResult } from 'store/workflow/actions';
import { Spinner } from "@blueprintjs/core";

// Workitem Tasks to Complete/
import { WorkflowRecordContainer } from '../WorkflowRecord/WorkflowRecordContainer';
import { WorkflowFindRecordContainer } from '../WorkflowFindRecord/WorkflowFindRecordContainer';
import WorkflowReviewRecordContainer from '../WorkflowReviewRecord/WorkflowReviewRecordContainer';
import { WorkflowMatchOverrideContainer } from '../WorkflowMatch/WorkflowMatchOverrideContainer';
import { WorkflowMatchManualContainer } from '../WorkflowMatch/WorkflowMatchManualContainer';
import { WorkflowApproveMatchContainer } from '../WorkflowMatch/WorkflowApproveMatchContainer';
import { WorkflowNoteContainer } from '../WorkflowNote/WorkflowNoteContainer';
import { WorkflowNoteReviewContainer } from '../WorkflowNote/WorkflowNoteReviewContainer';

export interface WorkitemTaskContainerProps {
    history: any;
    currentWorkflow: Workflow;
    clearTaskResult: () => void;
}

export const WorkitemTaskContainerBase: React.FC<WorkitemTaskContainerProps> = (props) => {
    const { currentWorkflow, history, clearTaskResult } = props;

    React.useEffect(() => {
        return () => {
            clearTaskResult();
        }
    }, []);

    // Decide what Workitem View to render based on the current "continue task" in our store.
    const getCurrentPage = () => {
        switch (currentWorkflow?.formId) {
            case 'CreateRecord':
                return <WorkflowRecordContainer history={history} isEdit={false} />
            case 'EditRecord':
                return <WorkflowRecordContainer history={history} isEdit={true} />
            case 'FindRecord':
                return <WorkflowFindRecordContainer history={history} />
            case 'ReviewRecord':
                return <WorkflowReviewRecordContainer />
            case 'OverrideMatchProposed':
                return <WorkflowMatchOverrideContainer history={history} />
            case 'OverrideMatchNew':
            case 'SetMatchGroups': // same screen but with different action on submit
                return <WorkflowMatchManualContainer history={history} />
            case 'ApproveMatch':
                return <WorkflowApproveMatchContainer history={history} />;
            case 'Note':
                return <WorkflowNoteContainer history={history} />
            case 'NoteReview':
                return <WorkflowNoteReviewContainer history={history} />
            default:
                return <div className="flex-center flex-column">
                    <span className="text-medium">Redirecting...</span>
                    <Spinner />
                </div>
        }
    };

    return <div>
        {getCurrentPage()}
    </div>
}

export const WorkitemTaskContainer = connect((state: any) => ({
    currentWorkflow: getCurrentWorkflow(state),
}), {
    clearTaskResult
})(WorkitemTaskContainerBase);