import { GroupUrl } from '../proxies/ajax/GroupUrl';
import { axiosPost } from '../helpers/axiosUtils';
import { displayError } from './displayError';

export class GroupApi {

    static listAllGroups = () => axiosPost(GroupUrl.list, {}, 'groups').catch(err => {
        displayError('List group failed', `Failed to list groups`, err);
        throw err;
    })

    static upsertGroup(group, description, roleList) {
        const body = {
            payload: {
                group,
                description,
                roles: roleList
            }
        };

        return axiosPost(GroupUrl.upsert, body).then(() => true).catch(err => {
            displayError('Upsert group failed', `Failed to upsert group`, err);
            throw err;
        });
    }

    static deleteGroup(group: string) {
        const body = { payload: { group } };
        return axiosPost(GroupUrl.delete, body).then(() => true).catch(err => {
            displayError('Delete group failed', `Failed to delete group`, err);
            throw err;
        });
    }
}