import { PermUrl } from '../proxies/ajax/PermUrl';
import { axiosPost, axiosGet } from '../helpers/axiosUtils';

export interface QueryFilterData {
    group?: string;
    type?: string;
    entity?: string;
}

export interface BasicPermissionData {
    entity: string;
    type: string;
}

export interface QueryEffectiveData {
    entity: string;
    group?: string;
    type: string;
}

export interface PermissionDeleteData {
    entity: string;
    group: string;
    type: string;
}

export interface ValidatePermissionData {
    type: string;
    entity: string;
    permission: string;
    username?: string;
}

export interface UpsertPermissionData {
    group: string;
    type: string;
    entity: string;
    perms: string[];
}


export interface SetOwnerPermissionData {
    type: string;
    entity: string;
    owner: string;
}

export interface QueryOwnerData {
    owner?: string;
    type?: "database" | "table" | "column";
    entity?: string;
    limit: number;
}
//*************************************

export class PermApi {
    static getVersion() {
        return axiosGet(PermUrl.version);
    }

    static ping() {
        return axiosGet(PermUrl.ping);
    }

    static Query = (filter: QueryFilterData): Promise<any> => {
        return axiosPost(PermUrl.query, { payload: { filter } });
    }

    static QueryEffective = (body: QueryEffectiveData): Promise<any> => {
        return axiosPost(PermUrl.query_effective, { payload: body }).catch((err) => console.log("err: ", err));
    }

    static QueryParent = (body: BasicPermissionData): Promise<any> => {
        return axiosPost(PermUrl.query_parent, { payload: body });
    }

    static Validate = (body: ValidatePermissionData): Promise<any> => {
        return axiosPost(PermUrl.validate, { payload: body });
    }

    static Upsert = (body: UpsertPermissionData): Promise<any> => {
        return axiosPost(PermUrl.upsert, { payload: body }).then(() => true);
    }

    static Delete = (body: PermissionDeleteData): Promise<any> => {
        return axiosPost(PermUrl.delete, { payload: body }).then(() => true);
    }

    static Purge = (body: BasicPermissionData): Promise<any> => {
        return axiosPost(PermUrl.purge, { payload: body });
    }

    static SetOwner = (body: SetOwnerPermissionData): Promise<any> => {
        return axiosPost(PermUrl.set_owner, { payload: body });
    }

    static GetOwner = (body: BasicPermissionData): Promise<any> => {
        return axiosPost(PermUrl.get_owner, { payload: body });
    }

    static DeleteOwner = (body: BasicPermissionData): Promise<any> => {
        return axiosPost(PermUrl.delete_owner, { payload: body });
    }

    static QueryOwner = (queryOwnerData: QueryOwnerData): Promise<any> => {
        const body = {
            filter: {
                owner: queryOwnerData.owner,
                type: queryOwnerData.type,
                entity: queryOwnerData.entity
            },
            limit: queryOwnerData.limit
        };

        return axiosPost(PermUrl.query_owner, { payload: body });
    }
}
