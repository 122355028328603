import * as React from 'react';
import { Classes, Dialog, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from 'components/Shared';

export interface TableEditWarnDialogProps {
    msg: string;
    onCancel: () => void;
    onClose: () => void;
    show: boolean;
    title: string;
}

export const TableEditWarnDialog: React.FC<TableEditWarnDialogProps> = ({msg, onCancel, onClose, show, title}) =>
    <Dialog
        icon={IconNames.WARNING_SIGN}
        isOpen={show}
        onClose={onCancel}
        title={title}
    >
        <div>
            <div className={Classes.DIALOG_BODY}>
                {msg}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button value="OK" intent={Intent.PRIMARY} onClick={onClose} />
                    <Button value="Cancel" intent={Intent.DANGER} onClick={onCancel} />
                </div>
            </div>
        </div>
    </Dialog>;