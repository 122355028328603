


export const  DateToYYYYMMDDHHMM = (date?):string => {
    const when = date || new Date();
    // tslint:disable-next-line:prefer-template
    const result = when.getFullYear() + '/' +
                    ('0'+ (when.getMonth() + 1)).slice(-2) +
                    '/' + ('0' + when.getDate()).slice(-2) + " " +
                    ('0' + when.getHours()).slice(-2) + ':' +
                    ('0' + when.getMinutes()).slice(-2);
    return result;
};
