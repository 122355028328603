import * as React from 'react';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Spinner, Overlay } from "@blueprintjs/core";
import axios from 'axios';

import { Button } from 'components/Shared';
import { UserStatus, axiosPost } from 'helpers';
import { Intent } from 'types'
import { isLoggedIn, AuthApi } from 'api';
import { Loading } from './Loading';
import { login } from 'actions/login';
import { showToaster } from 'actions/ActionCreators';
import { setAuthConfig } from 'store/auth/actions';
import { AuthConfig } from 'store/auth/types';

const productLogo = require('images/blue-product-logo.svg');
const redpointLogo = require('images/redpoint-logo.svg')

import './Login.scss';
import { persistor } from 'store/MDMStore';

export interface LoginProps {
    login: (a: any) => void;
    setAuthConfig: (config: AuthConfig) => void;
    loading: boolean;
    history: any;
    showToaster?: showToaster;
}

export interface LoginState {
    isLoggingIn: boolean;
    username: string;
    password: string;
    loginFailed: boolean;
}

export const loginButtonStyle = {
    backgroundColor: '#3d4249',
    minWidth: '11rem',
    minHeight: '3.7rem'
};

export class LoginBase extends React.PureComponent<LoginProps, LoginState> {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isLoggingIn: false,
            loginFailed: false,
            showLicensingModal: false,
        };
    }
    public refs: {
        username: any;
        password: any;
    };

    public componentDidMount() {
        if (process.env.NODE_ENV !== 'test' && !this.props.loading) {
            this.refs.username.focus();
            this.update();
        }
        // Scroll to the top
        window.scrollTo(0, 0);
    }
    public componentWillUnmount() {
        // TODO - remove asynchronous logic out of components because you will break
        // a bunch of stuff if the user closes the page before your fetch/post finishes
        // C.f. The doFetchPost below
    }

    public onClick = async e => {
        const { showToaster, login, setAuthConfig, history } = this.props;

        const username = (this.refs.username.value || '').toLowerCase().trim();
        const password = this.refs.password.value;
        this.setState({
            isLoggingIn: true
        });
        e.preventDefault();

        await persistor().purge();

        // @ts-ignore
        delete axios.defaults.headers.cookie;

        const postBody = { payload: { username, password } };

        axiosPost('/login', postBody, null, null, false, true)
            .then(async (model) => {
                if (model.lasterror)
                    throw (model.message);

                const config = await AuthApi.getConfig();
                setAuthConfig(config);
                login({ name: username, model, push: history.push });
            })
            .catch(err => {
                console.error(err);
                this.setState({ loginFailed: true, isLoggingIn: false });
                showToaster(Intent.DANGER, err.message || 'Failed to login. An error occurred.');
            });
    }

    private onEnter = e => {
        if (e.key === 'Enter' && !!(this.state.username && this.state.password)) {
            this.onClick(e);
        }
    }

    private update = (e?: React.SyntheticEvent<HTMLInputElement>) => this.setState({ password: this.refs.password.value, username: this.refs.username.value });

    generateInactivityWarning = (): ReactNode => {
        const logoutMessage = UserStatus.getLogoutMessage();
        if (logoutMessage)
            return <div className="logout-warning">{logoutMessage}</div>
        return null;
    }

    generateHeader = (): ReactNode => {
        return (
            <div className="fill-width flex-center-vertically heading-container">
                <img className="product-logo" src={productLogo} alt={'Redpoint Logo'} />
                <div>
                    <span className='login-heading'>Sign in to</span>
                    <span className='login-heading'>Master Data Management</span>
                </div>
            </div>
        )
    }

    public render() {
        const { loading } = this.props;

        if (loading) return <Loading />

        if (isLoggedIn()) return <Redirect to="/" />

        return (
            <>
                <Overlay isOpen={this.state.isLoggingIn} onClose={() => this.setState({ isLoggingIn: false })}>
                    <div className="fill flex-center">
                        <div className="flex-column">
                            <div className="logging-in-message">Logging In</div>
                            <Spinner intent="primary" size={70} />
                        </div>
                    </div>
                </Overlay>
                <img className="login-company-logo" src={redpointLogo} alt={'Redpoint Logo'} />

                <div className='login-wrapper'>
                    <div className='login-form'>
                        {this.generateInactivityWarning()}
                        {this.generateHeader()}
                        {/* <span className='login-heading'>Sign into Master Data Management</span> */}
                        <form method='post' action='/login'>
                            <div className='login-input-wrapper'>
                                <span className='login-input-note'>Username</span>
                                <input tabIndex={0} onChange={this.update} ref='username' className='login-input' id='inputEmail' name='username' type='text' required autoFocus />
                            </div>
                            <div className='login-input-wrapper'>
                                <span className='login-notes login-notes-flex'>
                                    <span className='login-input-note'>Password</span>
                                </span>
                                <input autoComplete='current-password' tabIndex={0} onChange={this.update} onKeyPress={this.onEnter} ref='password' className='login-input' id='inputPassword' name='password' type='password' required />
                            </div>
                        </form>

                        <div className='login-option-group'>
                            <Button
                                tabIndex={0}
                                style={loginButtonStyle}
                                disabled={!(this.state.username && this.state.password) || this.state.isLoggingIn}
                                className='login-button' onClick={this.onClick}
                                id='login-button'
                                value='Sign In' />
                        </div>
                    </div>

                </div>
            </>
        );
    }

}

export const Login = connect(
    (state: any) => ({ loading: state.login.isLoading }),
    { login, setAuthConfig, showToaster }
)(LoginBase);