import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import { Login } from 'components/Login/Login';
import { MDMContainer } from 'components/MDMContainer';
import { persistor, MDMStore } from 'store';
import ScrollToTopOnMount from 'components/Shared/ScrollToTopOnMount';
import ToastHandler from 'components/Toaster/ToastHandler'

const qc = new QueryClient();

export const App: React.FC = () =>
    <Provider store={MDMStore}>
        <PersistGate loading={null} persistor={persistor()}>
            <QueryClientProvider client={qc}>
                <BrowserRouter basename='/mdm'>
                    <ToastHandler />
                    <ScrollToTopOnMount>
                        <Switch>
                            <Route exact path='/login' component={Login} />
                            <Route path='/' component={({ history }) => <MDMContainer history={history} />} />
                        </Switch>
                    </ScrollToTopOnMount>
                </BrowserRouter>
            </QueryClientProvider>
        </PersistGate>
    </Provider>;