import React, { FC } from "react";
import { useSelector } from "react-redux";
import { CustomSelect } from "components/Shared";
import { useGetTablesList } from "api";
import { RootState } from "store";

export interface TableListDropdownProps {
    currentTable: string;
    onTableChange: (options: any) => void
}

const TableListDropdown: FC<TableListDropdownProps> = ({
    currentTable,
    onTableChange,
}) => {
    const { dbName, changeset } = useSelector<RootState, { dbName: string, changeset: any }>(state => ({
        dbName: state.database.selected,
        changeset: state.changeset
    }));
    const { data } = useGetTablesList('table-list', {
        dbName,
        changeset: changeset.changeset
    }, {
        initialData: []
    });
    const tableOptions = data.map((table) => ({ label: table, value: table }))

    return (
        <CustomSelect
            buttonClassName="underline-select"
            placeholder="Select a table..."
            name="tableName"
            onChange={onTableChange}
            value={currentTable}
            options={tableOptions}
        />
    )
}

export default TableListDropdown;