import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';
import { Checkbox, Alignment, FormGroup, InputGroup, Classes, Tooltip, HTMLSelect } from '@blueprintjs/core';
import { Process, WorkflowDef, Table, SchemaType } from '../../../types';
import { toTitlecase, toSnakeCase } from '../../../utils';
import { WorkflowFormMultiSelect } from './WorkflowFormMultiSelect'
import { TooltipTexts } from './TooltipTexts'

export interface WorkflowDetailFormProps {
    workflow: WorkflowDef;
    editable: boolean;
    process: Process;
    processes: Process[];
    groups?: string[];
    workflowDetails?: any;
    onInputChange: (key: string, value: any) => void;
    tables: Table[];

}

export const WorkflowDetailForm: React.FC<WorkflowDetailFormProps> = ({ workflow, editable, process, processes, groups = [], onInputChange, tables }) => {
    const uniqueId = uuidv4();


    const generateTableInput = (table: string) => {
        const onDropdownChange = (newVal: string) => {
            let allVars = { ...workflow.variables };
            allVars[toSnakeCase('table')] = newVal;
            onInputChange("variables", allVars);
        }

        let dropdownOptions = tables.filter(t => t.schema.type === SchemaType.versioned).map(t => ({ label: t.table, value: t.table }));
        dropdownOptions.unshift({ label: '<None>', value: '' })

        const input = editable ?
            <HTMLSelect
                value={table}
                onChange={(e) => onDropdownChange((e.target as any).value)}
                options={dropdownOptions}
            /> :
            <InputGroup
                value={table}
                name="description"
                readOnly
            />

        return (
            <FormGroup
                key="Table-dd"
                label="Table"
                labelFor='wf-table'
                inline={true}
                className={`${Classes.TEXT_MUTED} mdm-wf-form-group`}
                contentClassName="wf-input"
            >
                {input}
            </FormGroup>
        )
    }

    const generateTextinput = (title: string, value: string, tooltipText?: string): React.ReactNode => {
        const label = generateInputLabel(title, tooltipText)

        const onTextChange = (newVal: string) => {
            let allVars = { ...workflow.variables };
            allVars[toSnakeCase(title)] = newVal;
            onInputChange("variables", allVars);
        }

        return (
            <FormGroup
                key={title}
                label={label}
                labelFor={title}
                inline={true}
                className={`${Classes.TEXT_MUTED} mdm-wf-form-group`}
                contentClassName="wf-input"
            >
                <InputGroup
                    id={`${uniqueId}-${title}-input`}
                    value={value}
                    name={title}
                    readOnly={!editable}
                    onChange={e => onTextChange(e.target.value)}
                />
            </FormGroup>
        )
    }

    const generateInputLabel = (title: string, tooltipText: string) => {
        if (tooltipText)
            return (
                <>
                    <span>{title}</span>
                    <Tooltip content={tooltipText} className="tooltip">
                        <span className="tooltip-target">?</span>
                    </Tooltip>
                </>
            )

        return <span>{title}</span>;
    }

    const generateGroupInput = (title: string, items: string[], selectedItems: string[], tooltip?: string): React.ReactNode => {

        const onGroupSelect = (newGroup: string) => {
            const newStartGroups = [...selectedItems, newGroup].filter(item => item).join(',')
            let allVars = { ...workflow.variables };
            allVars['start_groups'] = newStartGroups;
            onInputChange("variables", allVars);
        }

        const onGroupRemove = (removedGroup: string) => {
            const newStartGroups = selectedItems.filter(item => item && item !== removedGroup).join(',')
            let allVars = { ...workflow.variables };
            allVars['start_groups'] = newStartGroups;
            onInputChange("variables", allVars);
        }

        return (
            <WorkflowFormMultiSelect
                key={title}
                label={title}
                items={items}
                selectedItems={selectedItems}
                tooltip={tooltip}
                disabled={!editable}
                onSelect={onGroupSelect}
                onRemove={onGroupRemove}
            />
        )
    }

    const mapVariables = (variables) => {
        let variableEntries = [];

        for (let [key, value] of Object.entries(variables)) {
            if (!key.includes('claim_groups')) {

                const title = toTitlecase(key);
                let formRow = null;

                switch (key) {
                    case 'start_groups':
                        formRow = generateGroupInput(title, groups, value.toString().split(','), TooltipTexts.startGroups);
                        break;
                    case 'table':
                        formRow = generateTableInput(value.toString())
                        break;
                    default:
                        formRow = generateTextinput(title, value.toString())
                        break;
                }

                variableEntries.push(formRow);
            }
        }

        return variableEntries;
    }

    const generateProcessInput = (process: Process, processes: Process[]) => {
        if (!process || !processes)
            return null;

        if (editable)
            return (
                <HTMLSelect
                    value={process.process_id}
                    onChange={e => onInputChange('process_id', parseInt(e.target.value))}
                    options={processes.map(p => ({ label: p.description, value: p.process_id }))}
                />
            );

        return (
            <InputGroup
                id={process.process_id.toString()}
                value={process.description}
                name="description"
                readOnly
            />
        );
    }

    const { variables, ui_visible } = workflow;
    const variableInputs = mapVariables(variables)
    const completionHours = workflow.completion_hours !== null ? workflow.completion_hours.toString() : 0;

    return (
        <div className="flex-column workflow-detail-form">
            <FormGroup
                label="Process"
                labelFor='wf-process'
                inline={true}
                className={`${Classes.TEXT_MUTED} mdm-wf-form-group`}
                contentClassName="wf-input"
            >
                {generateProcessInput(process, processes)}
            </FormGroup>

            <FormGroup
                label="Description"
                labelFor='wf-description'
                inline={true}
                className={`${Classes.TEXT_MUTED} mdm-wf-form-group`}
                contentClassName="wf-input"
            >
                <InputGroup
                    id={uniqueId + "-description"}
                    value={workflow.description}
                    name="description"
                    readOnly={!editable}
                    onChange={e => onInputChange('description', e.target.value)}
                />
            </FormGroup>

            {variableInputs}

            <FormGroup
                label={generateInputLabel("Visible", TooltipTexts.visible)}
                labelFor="wf-visible"
                inline={true}
                className={`${Classes.TEXT_MUTED} mdm-wf-form-group`}
                contentClassName="wf-input"
            >
                <Checkbox
                    checked={ui_visible}
                    alignIndicator={Alignment.RIGHT}
                    onChange={() => { onInputChange("ui_visible", !ui_visible) }}
                    disabled={!editable}
                    inline
                />
            </FormGroup>
            <FormGroup
                label={generateInputLabel("Completion Hours", TooltipTexts.completionHours)}
                labelFor="wf-visible"
                inline={true}
                className={`${Classes.TEXT_MUTED} mdm-wf-form-group`}
                contentClassName="wf-input"
            >
                <InputGroup
                    value={completionHours !== null ? completionHours.toString() : "0"}
                    name="completion-hours"
                    readOnly={!editable}
                    onChange={e => onInputChange('completion_hours', parseInt(e.target.value))}

                />
            </FormGroup>
        </div>
    )
}
