// Put the form, container, and button bar (?) in here
import { connect, useSelector } from 'react-redux';
import * as React from 'react';
import TablesTable from './TablesTable';

import { MDMWrapper, Button, ConfirmDialog } from 'components/Shared';
import { displayError, RecordApi, TableApi, getSchema, useGetTables, useDeleteTableMutation, useTruncateTableMutation, useExpungeTableMutation, useImportTableMutation, useExportReadyTables } from 'api';
// import { Pagination } from 'rp-mdm-common';
import { Schema, Table } from 'types';
import { TableImportDialog } from './TableImportDialog';
import { toast } from "react-toastify";
import { RootState } from 'store';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// import { doFetchPost } from 'helpers';

// debug hack
let sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

interface TableToDelete {
    table: string;
    deletionType: 'delete' | 'expunge' | 'truncate'
}
interface TableMainContainerProps {
    dbName: string;
    history: any;
}
interface TableMainContainerState {
    changesetPendingMetadata: object[];
    tables: Table[];
    showImportDialog: boolean;
    tableToDelete: TableToDelete;
}

const TablesMainForm = () => {
    const history = useHistory();
    const dbName: string = useSelector<RootState, string>(state => state.database.selected);
    const qc = useQueryClient();
    const [showImportDialog, setShowImportDialog]  = React.useState<boolean>(false);
    const [tableToDelete, setTableToDelete] = React.useState<TableToDelete | null>(null);

    const openImportDialog = () => setShowImportDialog(true);
    const closeImportDialog = () => setShowImportDialog(false);

    const { data: tables = [] } = useGetTables('tables-main-form', {
        dbName,
        changeset: 'current'
    }, {
        initialData: []
    });
    const { mutateAsync: deleteTableFn } = useDeleteTableMutation({
        onSuccess: () => {
            toast.success(`Deleted table ${dbName}.${tableToDelete.table}`);
        },
        onError: () => {
            toast.error(`Unable to delete table ${dbName}.${tableToDelete.table}`);
        },
        onSettled: () => {
            qc.invalidateQueries(['tables-main-form']);
            setTableToDelete(null);
        }
    });
    const { mutateAsync: truncateTableFn } = useTruncateTableMutation({
        onSuccess: () => {
            toast.success(`Truncated table ${dbName}.${tableToDelete.table}`);
        },
        onError: () => {
            toast.error(`Unable to truncate table ${dbName}.${tableToDelete.table}`);
        },
        onSettled: () => {
            qc.invalidateQueries(['tables-main-form']);
            setTableToDelete(null);
        }
    });
    const { mutateAsync: expungeTableFn } = useExpungeTableMutation({
        onSuccess: () => {
            toast.success(`Expunged table ${dbName}.${tableToDelete.table}`);
        },
        onError: () => {
            toast.error(`Unable to expunge table ${dbName}.${tableToDelete.table}`);
        },
        onSettled: () => {
            qc.invalidateQueries(['tables-main-form']);
            setTableToDelete(null);
        }
    });
    const { mutateAsync: importTableSchemaFn } = useImportTableMutation({
        onSettled: () => {
            qc.invalidateQueries(['tables-main-form']);
        }
    });

    const showEditTable = (tableName?: string) => {
        const tablesPath = tableName ? `/table/${tableName}` : '/table'
        history.push(tablesPath);
    }

    const createTableButton = (
        <Button
            key="table-button-1"
            className="minimal-button"
            minimal
            id="btnCreateTable"
            value="Create Table"
            onClick={() => showEditTable()}
        />
    );
        
    const importTableButton = (
        <Button
            key="table-button-2"
            minimal
            className="minimal-button"
            value="Import Schema"
            onClick={openImportDialog}
        />
    )

    const getDialogContents = () => {
        let title = 'Data Loss Warning';
        let content = '';
        switch (tableToDelete?.deletionType) {
            case 'delete':
                title = "Deletion Warning";
                content = `Are you sure you want to mark table ${tableToDelete?.table} as deleted?`;
                break;

            case 'expunge':
                content = `THIS ACTION CANNOT BE REVERSED. This will destroy table ${tableToDelete?.table}. All records and record history in the table and all versions of the table schema will be erased forever. Proceed?`;
                break;

            case 'truncate':
                content = `Are you sure you want to destroy all records in Table ${tableToDelete?.table}?`;
                break;

            default:
                break;
        }

        return {
            content,
            title,
        }
    }

    const saveSchemaToFile = (schema, tableName) => {
        if (!('Blob' in window)) {
            alert('We cannot export your schema. Your browser does not support the HTML5 Blob.');
            return;
        }

        const textToWrite = JSON.stringify(schema, null, 4);
        const textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });

        const downloadLink = document.createElement('a');
        downloadLink.download = `${tableName}.json`;
        downloadLink.innerHTML = 'Download File';
        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
        downloadLink.style.display = 'none';
        downloadLink.click();
        toast.success(`Downloaded schema for table [${tableName}]`); 
    }

    const downloadTable = async (tableName?: string) => {
        try {
            if (!tableName) throw new Error('No table name specified to download?');
    
            const schema = await getSchema(dbName, 'current', tableName);
    
            if (!schema) throw new Error(`Unable Download table ${tableName}`);

            saveSchemaToFile(schema, tableName);
        } catch (err) {
            return toast.error(err.messages && err.messages[0] || err.message || `Unable to download table ${tableName}`);
        }
    }

    const showDataTab = (tableName: string) => history.push(`/records/${tableName}`);

    const exportTable = async (tableName: string) => {
        try {
            const uuid = await TableApi.exportTable(dbName, tableName)
            toast.success(`Exporting table ${tableName}. Wait for the download button to be enabled.`);
        } catch (err) {
            toast.error(err.messages && err.messages[0] || `Unable to export table ${tableName}`);
        }
    }

    const importTableSchema = async (tableName: string, description: string, jsonContents: Schema) => {
        try {
            await importTableSchemaFn({
                dbName,
                tableName,
                description,
                jsonContents
            });
    
            toast.success(`Created table ${dbName}.${tableName}`);
            closeImportDialog();
        } catch (err) {
            toast.error(`Unable to import table`);
        }
    }

    const getExportedTable = async (uuid: string) => {
        try {
            const fileContents = await TableApi.exportTableGet(uuid);
        } catch (err) {
            toast.error(err.messages && err.messages[0] || `Unable to export table file with ${uuid}`);
        }
    }

    const okClick = () => {
        const { deletionType, table } = tableToDelete;
        if (deletionType === 'delete') {
            return deleteTableFn({table, dbName })
        }
        if (deletionType === 'truncate') {
            return truncateTableFn({table, dbName })
        }
        if (deletionType === 'expunge') {
            return expungeTableFn({table, dbName })
        }
    }
        
    return (
        <>
            <MDMWrapper title="Tables" buttons={[importTableButton, createTableButton]} documentationPath="create_table.html">
                <TablesTable
                    deleteTable={(tableName) => setTableToDelete({ table: tableName, deletionType: "delete" })}
                    truncateTable={(tableName) => setTableToDelete({ table: tableName, deletionType: "truncate" })}
                    expungeTable={(tableName) => setTableToDelete({ table: tableName, deletionType: "expunge" })}
                    downloadTable={downloadTable}
                    showDataTab={showDataTab}
                    showEditTable={showEditTable}
                    exportTable={exportTable}
                    getExportedTable={getExportedTable}
                    tables={tables}
                />
            </MDMWrapper>

            <ConfirmDialog
                buttonName={tableToDelete?.deletionType?.toUpperCase()}
                body={getDialogContents().content}
                title={getDialogContents().title}
                isOpen={!!tableToDelete}
                cancelClick={() => setTableToDelete(null)}
                okClick={okClick}
                forDeletion
            />

            <TableImportDialog
                closeDialog={closeImportDialog}
                onSave={importTableSchema}
                isOpen={showImportDialog}
            />
        </>
    );
    
}

export default TablesMainForm;