import * as React from 'react';
import { Icon, Intent, Position, Tooltip } from '@blueprintjs/core';

import { Button } from 'components/Shared/Button';
import { EditRecordForm } from 'components/EditRecordForm/EditRecordForm';
import { NotesTable } from 'components/NotesTable/NotesTable';
import { History, Schema, Workflow } from 'types';
import { WorkflowWidgets } from '../WorkflowWidgets';

require('helpers/styles/workflow.scss');
require('./WorkflowRecord.scss');

export interface WorkflowRecordFormProps {
    history: History[];
    schema: Schema;
    record: object;
    workflow: Workflow;
    completeTask: (button:HTMLButtonElement, record: object, note: string) => void;
    discardTask: (button:HTMLButtonElement, note: string) => void;
    saveTask: (button:HTMLButtonElement, record: object, note: string) => void;
}
export interface WorkflowRecordFormState {
    modifiedRecord: object;
    note: string;
}

export class WorkflowRecordForm extends React.Component<WorkflowRecordFormProps, WorkflowRecordFormState> {
    constructor(props: WorkflowRecordFormProps) {
        super(props);
        this.state = {
            modifiedRecord: { ...props.record },
            note: ''
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: WorkflowRecordFormProps, prevState) {
        const { schema, record } = this.props;
        if (record !== prevProps.record || schema !== prevProps.schema) {
            this.setState({ modifiedRecord: { ...record } });
        }
    }

    private onFieldChange = (key: string, newValue: any) => {
        const modifiedRecord = { ...this.state.modifiedRecord, [key]: newValue };
        this.setState({ modifiedRecord: modifiedRecord });
    }

    private onNoteChange = (newNote: string) => {
        this.setState({ note: newNote });
    }

    private completeTask = (button:HTMLButtonElement) => {
        this.props.completeTask(button, this.state.modifiedRecord, this.state.note);
    }

    private discardTask = (button:HTMLButtonElement) => {
        this.props.discardTask(button, this.state.note);
    }

    private saveTask = (button:HTMLButtonElement) => {
        this.props.saveTask(button, this.state.modifiedRecord, this.state.note);
        // Reset the note to empty. Doesn't make sense to leave it.
        this.setState({ note: '' });
    }

    private onDocumentationOpen = () => {
        let link = 'workflow_enter_record';

        if (this.props.record) {
            link = 'workflow_edit_existing_record';
        }

        window.open(`/mdm-help/${link}.html`, 'mdm-help');
    }

    render() {
        const { workflow } = this.props;
        const proceedOkay = this.props.schema && (this.state.modifiedRecord[this.props.schema.primary_key] != null);

        return (
            <div className="workflow-wrapper">
                <div className="workflow-menu-wrapper">
                    <div className="workflow-menu">
                        <div className="workflow-title">{`${this.props.record ? 'Edit' : 'New'} Record`}
                            <Tooltip content="Documentation" position={Position.RIGHT}>
                                <div className="mdm-wrapper-docs-button flex-center">
                                    <Icon icon='help' onClick={this.onDocumentationOpen} color="#636567" iconSize={20} />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="workflow-title-buttons">
                            <Button disabled={!proceedOkay} value="SAVE" onClick={(e) => this.saveTask(e.currentTarget)} />
                            <Button value="DISCARD" intent={Intent.DANGER} onClick={(e) => this.discardTask(e.currentTarget)} />
                            <Button disabled={!proceedOkay} value="SUBMIT" intent={Intent.SUCCESS} onClick={ (e) => this.completeTask(e.currentTarget) } />
                        </div>
                    </div>
                    <WorkflowWidgets tableName={workflow.tableName} workitemId={workflow.workitem_id} />
                </div>
                <EditRecordForm
                    tableName={workflow.tableName}
                    modifiedRecord={this.state.modifiedRecord}
                    schema={this.props.schema}
                    record={this.props.record}
                    updateFieldCb={this.onFieldChange}
                />
                <NotesTable history={this.props.history} note={this.state.note} updateNote={this.onNoteChange} />
            </div>
        );
    }
}