/**
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Alert, Icon, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { DEFAULT_AUTHORITY } from '../../constants/authorities';
import { Button } from '../Shared';
import { displayMessageBox, showToaster } from '../../actions';
import { setUserGroups } from '../../store/user/actions'
import { mdmErrorToText } from '../../helpers';
import { MDMWrapper } from '../../components/Shared';
import { MDMStore as store } from '../../store';
import { UserEditModal, UserEditModalProps } from './UserEditModal';
import { inSystemGroup } from '../../api';
import { User as UserType, Users, Intent } from '../../types';
import { UserApi, GroupApi, AuthApi } from '../../api';

import './User.scss';

const emptyUser: UserType = {
    username: '',
    full_name: '',
    authority: DEFAULT_AUTHORITY,
    password: '',
    email: '',
    description: '',
    groups: ['everyone'],
}

export interface UserProps {
    username?: string;
    systemUsername?: string;
    history: any;
    setUserGroups: (groups: string[]) => (dispatch: any) => void;
}

export interface UserState {
    users?: Users;
    allGroups?: any[];
    currentUser?: UserType;
    oldUser?: UserType;
    isDialogOpen?: boolean;
    dialogTitle?: UserEditModalProps['title'];
    isEdit?: boolean;
    showDeleteAlert: boolean;
    deleteUser: UserType;
    userSavability?: boolean;
    isUniqueUsername?: boolean;
}

class UserBase extends React.Component<UserProps, UserState> {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            allGroups: [],
            currentUser: { ...emptyUser },
            oldUser: { ...emptyUser },
            isDialogOpen: false,
            dialogTitle: 'Add User',
            isEdit: false,
            showDeleteAlert: false,
            deleteUser: null,
            userSavability: false,
            isUniqueUsername: true,
        };
    }

    public componentDidMount() {
        this.loadUsers();
    }

    private determineIfSaveable() {
        const { currentUser } = this.state;
        const hasUserName = currentUser.username !== '';

        const hasPassword = this.state.isEdit || currentUser.password !== '';
        // You can only change the password if you're using Local Authority.
        const requiresPassword = currentUser.authority === DEFAULT_AUTHORITY || !currentUser.authority;

        const hasEmail = currentUser.email !== '';
        const isUniqueUsername =
            this.state.isEdit ||
            this.state.users.find(u => u.username === currentUser.username) == null;

        this.setState({
            userSavability: hasUserName && (hasPassword || !requiresPassword) && hasEmail && isUniqueUsername,
            isUniqueUsername: isUniqueUsername,
        });
    }

    private loadUsers = () => {
        UserApi.getAll()
            .then((users: Users) => {
                this.setState({ users: users || [] });
                this.getAllGroups();
            })
            .catch(err => {
                store.dispatch<any>(
                    displayMessageBox({
                        title: 'Server Call Failed',
                        message: "Couldn't get users: " + mdmErrorToText(err),
                    }),
                );
            })
    }

    private onDeleteUser = (user: UserType) => {
        this.setState({ showDeleteAlert: true, deleteUser: user });
    }
    private closeDeleteUser = () => {
        this.setState({ showDeleteAlert: false, deleteUser: null });
    }

    private editUser = inputUser => {
        let editUser = inputUser;
        // Create empty user
        if (editUser) {
            UserApi.getUserGroups(editUser.username)
                .then(groups => {
                    editUser.groups = groups || ['everyone'];
                    editUser.authority = editUser.authority || DEFAULT_AUTHORITY;
                    this.setState({
                        dialogTitle: 'Edit User',
                        isEdit: true,
                        isDialogOpen: true,
                        currentUser: editUser,
                        oldUser: editUser
                    });
                })
                .catch(err => {
                    store.dispatch<any>(
                        displayMessageBox({
                            title: 'Server Communication Failed',
                            message: "Couldn't retrieve user groups: " + mdmErrorToText(err),
                        }),
                    );
                });
        } else {
            editUser = { ...emptyUser };
        }
    }

    private getAllGroups = (editUser?: UserType) => {
        GroupApi.listAllGroups()
            .then((groups: any[]) => {
                const allGroups = groups ? groups.map(group => group.group) : [];
                editUser
                    ? this.setState({ currentUser: editUser, allGroups: allGroups })
                    : this.setState({ allGroups: allGroups });
            })
            .catch(err => {
                store.dispatch<any>(
                    displayMessageBox({
                        title: 'Server Call Failed',
                        message: `Couldn't retrieve groups: ${mdmErrorToText(err)}`,
                    }),
                );
            });
    }

    private deleteUser = deleteUser => {
        if (deleteUser) {
            UserApi.deleteUser(deleteUser.username)
                .then(() => {
                    this.loadUsers();
                })
                .catch(err => {
                    store.dispatch<any>(
                        displayMessageBox({
                            title: 'Delete Failed',
                            message: `Couldn't delete user: ${mdmErrorToText(err)}`,
                        }),
                    );
                })
        }
        this.closeDeleteUser();
    }

    private groupsEqual = (newList: string[], oldList: string[]) => {
        if (newList.length === oldList.length)
            return newList.reduce((accum, current, index) => (accum && current === oldList[index]), true);

        return false;
    }

    private saveUser = async (isEdit: boolean) => {
        const { username } = this.props;
        const user = { ...this.state.currentUser };
        const oldUser = { ...this.state.oldUser };
        const updateUser = !isEdit ||
            user.username !== oldUser.username ||
            user.full_name !== oldUser.full_name ||
            user.description !== oldUser.description ||
            user.email !== oldUser.email ||
            user.authority !== oldUser.authority

        const updateGroups = !isEdit || !this.groupsEqual(user.groups, oldUser.groups);

        const requiresPassword = user.authority === DEFAULT_AUTHORITY || !user.authority;
        const updatePassword = requiresPassword && (!isEdit || user.password); // Always save the password for a new user

        let actionString = isEdit ? 'edited' : 'created';

        if (!updateUser && !updateGroups && !updatePassword) {
            console.log("saveUser with nothing to update.");
        }

        // We have run into problems with these completing out of order when
        // using Promise.all
        try {
            if (updateUser)
                await UserApi.upsert(user.username, user.full_name, user.email, user.description, user.authority);

            if (updatePassword)
                await AuthApi.setPassword(user.username, user.password);

            if (updateGroups)
                await UserApi.setUserGroups(user.username, user.groups);
        } catch (err) {
            if (err && err.messages)
                err.messages.map(errMessage => store.dispatch<any>(showToaster(Intent.ERROR, errMessage)));
            else
                store.dispatch<any>(showToaster(Intent.ERROR, `User ${actionString} failed.`));
            return;
        }

        if (isEdit && updateGroups && user.username === username)
            this.props.setUserGroups(user.groups);

        store.dispatch<any>(showToaster(Intent.SUCCESS, `User ${actionString} successfully.`));

        this.loadUsers();
        this.toggleDialog();
    }

    generateUserActions = (userObject) => {

        const { username, history, systemUsername } = this.props;
        const inSystem = inSystemGroup();
        const isLoggedUser = userObject.username === username

        if (!inSystem && !isLoggedUser)
            return null;

        return (
            <div className="management-actions users-actions">
                <Popover
                    position={Position.BOTTOM_RIGHT}
                    content={
                        <Menu>
                            {
                                (inSystem || isLoggedUser) &&
                                <MenuItem
                                    icon={IconNames.COG}
                                    onClick={() => { history.push(`/settings/${userObject.username}`) }}
                                    text="User Settings"
                                />
                            }

                            {
                                (inSystem || isLoggedUser) &&
                                < MenuItem
                                    icon={IconNames.EDIT}
                                    onClick={e => this.editUser(userObject)}
                                    text="Edit Credentials"
                                />
                            }

                            {
                                inSystem && userObject.username !== systemUsername &&
                                <MenuItem
                                    icon={IconNames.TRASH}
                                    onClick={e => this.onDeleteUser(userObject)}
                                    text="Delete"
                                />
                            }
                        </Menu>
                    }
                >
                    <Icon icon={IconNames.MORE} />
                </Popover>
            </div>
        )
    }

    private renderUsers() {
        return this.state.users
            .sort((a, b) => {
                const nameA = a.username?.toLocaleLowerCase() || "";
                const nameB = b.username?.toLocaleLowerCase() || "";
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            })
            .map((item, index) => {
                const useColumn = item.username.length > 16 || item.email.length > 30 || item.full_name.length > 28;
                return (
                    useColumn ?
                    <div key={index} className="management-item users-item">
                        <div className="user-item-col">
                            <div className="user-icon-name">
                                <div className="user-icon"><Icon icon={IconNames.USER} /></div>
                                <div className="management-name user-name">{item.username}</div>
                            </div>

                            <div className="user-email">{item.email}</div>
                            <div className="user-description">{item.full_name}</div>
                        </div>
                        {this.generateUserActions(item)}
                    </div>
                        :
                    <div key={index} className="management-item users-item">

                            <div className="user-icon"><Icon icon={IconNames.USER} /></div>
                            <div className="management-name user-name">{item.username}</div>

                        <div className="management-description">{item.email}</div>
                        <div className="management-roles">{item.full_name}</div>

                        {this.generateUserActions(item)}
                    </div>
                )
            });
    }

    private toggleDialog = () =>
        this.setState({
            currentUser: emptyUser,
            isEdit: false,
            dialogTitle: 'Add User',
            isDialogOpen: !this.state.isDialogOpen,
        })

    public render() {
        const wrapperButtons = [];

        if (inSystemGroup())
            wrapperButtons.push(
                <Button
                    id="createuser-button"
                    className="minimal-button"
                    key={0}
                    value="Create User"
                    onClick={this.toggleDialog}
                />
            )

        return (
            <MDMWrapper title="User Management" buttons={wrapperButtons} documentationPath="users_and_groups.html">
                <Alert
                    icon={IconNames.TRASH}
                    intent={Intent.DANGER}
                    className="alert-confirm-delete"
                    isOpen={this.state.showDeleteAlert}
                    confirmButtonText="Delete User"
                    cancelButtonText="Cancel"
                    onConfirm={() => this.deleteUser(this.state.deleteUser)}
                    onCancel={this.closeDeleteUser}
                >
                    <p className="alert-text">
                        Are you sure you want to delete user <br />
                        {`"${this.state.deleteUser && this.state.deleteUser.username}"?`}
                    </p>
                </Alert>
                <div className="management-list users-list">
                    {this.state.users ? this.renderUsers() : null}
                </div>
                <UserEditModal
                    systemUsername={this.props.systemUsername || 'system'}
                    isOpen={this.state.isDialogOpen}
                    user={this.state.currentUser}
                    title={this.state.dialogTitle}
                    onClose={this.toggleDialog}
                    isEdit={this.state.isEdit}
                    onSave={this.saveUser}
                    isItSaveable={this.state.userSavability}
                    groups={this.state.allGroups}
                    isUniqueUsername={this.state.isUniqueUsername}
                    onInput={(key, value) => {
                        this.setState(
                            { currentUser: { ...this.state.currentUser, [key]: value } },
                            () => this.determineIfSaveable(),
                        );
                    }}
                />
            </MDMWrapper>
        );
    }
}

const mapStateToProps = state => ({
    username: state.username,
    systemUsername: state.auth.config?.systemUsername
})

export const User: any = connect(mapStateToProps, { setUserGroups })(UserBase as any)