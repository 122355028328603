import * as React from 'react';
import { Classes, Checkbox, FormGroup, Icon, Intent, InputGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { isMDMName } from '../../utils';

import { Button, MDMTextEnum } from '../Shared';
import { MDMType, MDMSize, MDMSigned } from '../../components/Shared';
import { Field, NewField, Table } from 'types';


// TODO - convert individual functions into a set of functions?
/**
 * TableEditField
 *
 */
export interface TableEditFieldProps {
    alwaysIndex?: boolean;
    cantDelete?: boolean;
    field: NewField;
    idPrefix?: string;
    index: number;
    isPrimaryKey: boolean;
    last?: boolean;
    notNullable?: boolean;
    onChange: (index: number, field: Field, isPrimaryKey: boolean) => void;
    onDelete: (index: number, isPrimaryKey: boolean) => void;
    onMove: (direction: string, index: number) => void;
    originalField?: Field;
    piiDisabled?: boolean;
    subField?: boolean;
    tables: Table[];
    fieldError?: string;
    isTimeSeries?: boolean;
}
export interface TableEditFieldState {
    fieldNameError: string;
}


export const TableEditField = ({
    alwaysIndex = false,
    cantDelete = false,
    field,
    idPrefix = '',
    index,
    isPrimaryKey,
    last = false,
    notNullable = false,
    onChange,
    onDelete,
    onMove,
    originalField,
    piiDisabled = false,
    subField = false,
    tables,
    fieldError = '',
    isTimeSeries = false,
}: TableEditFieldProps) => {
    const [fieldNameError, setFieldNameError] = React.useState<string>('');
console.log(
    'TableEditField',
    'field', field,
)
    React.useEffect(() => {
        checkFieldName(field.field);
    }, [field.field]);

    const checkFieldName = (fieldName: string) => {
        if (fieldName && !isMDMName(fieldName)) {
            setFieldNameError('Field name must start with a letter and contain only letters, numbers, and underscores');
        } else {
            setFieldNameError('');
        }
    }


    const isPkField = (table: Table) => {
        // ASSUMPTION: table is a valid table with a valid primary key and valid pk field
        return table.schema.fields.find((f) => {
            return f && f.field === table.schema.primary_key;
        });
    }


    const datatypeChange = (name: string, value: any) => {
        if (name === 'link_display_field') {
            return simpleChange(name, value);
        }

        let newField: NewField, newSigned: boolean;
 
        // While links_to is the actual name of the data element being changed we can't
        // just blindly change just that value.
        if (name === 'links_to') {
             // Research the value of the linked field
            let table = tables.find((t) => t && t.table === value);
            if (!value || !table) {
                table = tables[0];
            }

            const pkField = isPkField(table);

            newField = {
                ...field,
                links_to: table.table,
                link_display_field: table.schema.primary_key,
                datatype: {
                    type: pkField.datatype.type,
                    size: pkField.datatype.size,
                    signed: pkField.datatype.signed
                }
            };
        }

        else if (name === 'text') {
            newField = { ...field, values: value };

        } else {
            // Special case. If you are editing a field and you've set the size to the original
            // size then the sign becomes the original sign.  You can only change its size if its bigger
            newSigned = value.signed;
            if (originalField
                && originalField.datatype.type === 'integer'
                && originalField.datatype.size === value.size) {
                newSigned = originalField.datatype.signed;
            }
            newField = {
                ...field,
                datatype: { type: value.type, size: value.size, signed: newSigned as boolean },
                links_to: ''
            };
        }
        // Cleanup index and display based on choices
        if (value.type === 'table') {
            newField.index = false;
            newField.display = false;
            newField.nullable = false;
            newField.pii = false;
        }
        onChange(index, newField, isPrimaryKey);
    }

    const simpleChange = (name: string, value: any) => {
        const newField = { ...field, [name]: value };
        onChange(index, newField, isPrimaryKey);
    }

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const newField = { ...field, [name]: checked };
        onChange(index, newField, isPrimaryKey);
    };

    const moveUp = () => {
        onMove('up', index);
    }

    const moveDown = () => {
        onMove('down', index);
    }

    const deleteField = () => {
        onDelete(index, isPrimaryKey);
    }

    const isLink = field.datatype.type === 'table';

    const isLinkTo = (table: Table) => {
        return field.links_to === table.table;
    }

    const isLinkDisplayField = (table: Table) => {
        return field.link_display_field === table.schema.primary_key;
    }

    const isLinkDisplayFieldDisabled = (table: Table) => {
        return field.links_to !== table.table;
    }

    const isMDMName = (name: string) => {
        return /^[a-zA-Z][a-zA-Z0-9_]*$/.test(name);
    }

    const isLinkDisplayFieldValid = (table: Table) => {
        return isMDMName(field.link_display_field);
    }

    const datatype = field.datatype;
    const isComplex = datatype.type === 'table';


    // If you are editing a field AND it is not nullable you check it but you can't uncheck it
    notNullable = originalField && originalField.nullable ? true : notNullable;
    // Prevent making a table nullable. Confusing to users since we don't document what it means
    if(datatype.type === 'table') notNullable = true;

    const isDisabledFieldInput = (cantDelete && !isPrimaryKey) || (!!originalField && !field.isNew)

    const indexDisabled = isComplex || alwaysIndex;
    const indexChecked = field.index || alwaysIndex;

    // generate idChange function
    const idChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const newField = { ...field, [name]: value };
        onChange(index, newField, isPrimaryKey);
    }

    const keyIcon = (
        <div className="pk-margin">
            <Icon icon="key" />
        </div>
    )

    // MDMDatatype returns an array which is unacceptable as an Component
    const helpText = fieldError || '';

    return (
    <tr key={idPrefix + index} >
        <td>
            <FormGroup
                helperText={helpText}
                intent={!fieldError ? Intent.NONE : Intent.DANGER}
            >
                <InputGroup
                    fill
                    rightElement={isPrimaryKey ? keyIcon : null}
                    name='field'
                    value={field.field}
                    onChange={idChange}
                    disabled={isDisabledFieldInput}
                />
            </FormGroup>
        </td>
        <td><Checkbox disabled={notNullable} name='nullable' checked={field.nullable} onChange={onCheckboxChange} /></td>
        <td><Checkbox disabled={piiDisabled} name='pii' checked={field.pii} onChange={onCheckboxChange} /></td>
        <td><Checkbox disabled={indexDisabled} name='index' checked={indexChecked} onChange={onCheckboxChange} /></td>
        <td><Checkbox disabled={isComplex} name='display' checked={field.display} onChange={onCheckboxChange} /></td>
        <MDMType
            id={'table-edit-datatype-' + index}
            datatype={datatype}
            disabled={!!fieldError}
            linksTo={field.links_to || ''}
            onSelect={datatypeChange}
            originalField={originalField}
            subField={subField}
            tables={tables}
            isPrimaryKey={isPrimaryKey}
            isTimeSeries={isTimeSeries}
        />
        <MDMSize
            id={'table-edit-datasize-' + index}
            datatype={datatype}
            links_to={field.links_to || ''}
            onChange={datatypeChange}
            originalField={originalField}
            tables={tables}
        />
        <MDMSigned
            id={'table-edit-datasign-' + index}
            datatype={datatype}
            linksTo={field.links_to || ''}
            linkDisplayField={field.link_display_field || ''}
            onChange={datatypeChange}
            originalField={originalField}
            tables={tables}
            values={field.values || []}
        />
        <td className="actions-column">
            <Button disabled={cantDelete} icon={IconNames.TRASH} onClick={() => onDelete(index, isPrimaryKey)} />
            <Button icon={IconNames.ARROW_UP} onClick={() => onMove('up', index)} disabled={index === 0} />
            <Button icon={IconNames.ARROW_DOWN} onClick={() => onMove('down', index)} disabled={last} />
        </td>
    </tr>);



}

export default TableEditField;

// export class TableEditField2 extends React.Component<TableEditFieldProps, TableEditFieldState> {

//     constructor(props) {
//         super(props);
//         this.state = {
//             fieldNameError: '',
//         };
//     }

//     // componentWillMount() {
//     //     this.checkFieldName(this.props.field.field);
//     // }

//     // componentDidUpdate(prevProps) {
//     //     const prevFieldName = prevProps.field.field;
//     //     if (prevFieldName != this.props.field.field)
//     //         this.checkFieldName(this.props.field.field);
//     // }

//     isPkField = (table) => {
//         // ASSUMPTION: table is a valid table with a valid primary key and valid pk field
//         return table.schema.fields.find((f) => {
//             return f && f.field === table.schema.primary_key;
//         });
//     }

//     datatypeChange = (name, value) => {
//         const { isPrimaryKey } = this.props;

//         if (name === 'link_display_field') {
//             return this.simpleChange(name, value);
//         }

//         let newField, newSigned;

//         // While links_to is the actual name of the data element being changed we can't
//         // just blindly change just that value.
//         if (name === 'links_to') {
//             // Research the value of the linked field
//             let table = this.props.tables.find((t) => t && t.table === value);
//             if (!value || !table) {
//                 table = this.props.tables[0];
//             }

//             const pkField = this.isPkField(table);

//             newField = {
//                 ...this.props.field,
//                 links_to: table.table,
//                 link_display_field: table.schema.primary_key,
//                 datatype: {
//                     type: pkField.datatype.type,
//                     size: pkField.datatype.size,
//                     signed: pkField.datatype.signed
//                 }
//             };
//         }
//         else {
//             // Special case. If you are editing a field and you've set the size to the original
//             // size then the sign becomes the original sign.  You can only change its size if its bigger
//             newSigned = value.signed;
//             if (this.props.originalField
//                 && this.props.originalField.datatype.type === 'integer'
//                 && this.props.originalField.datatype.size === value.size) {
//                 newSigned = this.props.originalField.datatype.signed;
//             }
//             newField = {
//                 ...this.props.field,
//                 datatype: { type: value.type, size: value.size, signed: newSigned },
//                 links_to: ''
//             };
//         }
//         // Cleanup index and display based on choices
//         if (value.type === 'table') {
//             newField.index = false;
//             newField.display = false;
//             newField.nullable = false;
//             newField.pii = false;
//         }
//         this.props.onChange(this.props.index, newField, isPrimaryKey);
//     }

//     simpleChange = (name, value) => {
//         const { isPrimaryKey } = this.props;
//         const newField = { ...this.props.field, [name]: value };
//         this.props.onChange(this.props.index, newField, isPrimaryKey);
//     }

//     checkboxChange = (event) => {
//         this.simpleChange(event.target.name, !this.props.field[event.target.name]);
//     }

//     idChange = (event) => {
//         // this.checkFieldName(event.target.value);
//         this.simpleChange(event.target.name, event.target.value);
//     }

//     render() {
//         const { field, isPrimaryKey, originalField, piiDisabled, cantDelete } = this.props;
//         const datatype = field.datatype;
//         const isComplex = datatype.type === 'table';


//         // If you are editing a field AND it is not nullable you check it but you can't uncheck it
//         let notNullable = originalField && originalField.nullable ? true : this.props.notNullable;
//         // Prevent making a table nullable. Confusing to users since we don't document what it means
//         if(datatype.type === 'table') notNullable = true;

//         const isDisabledFieldInput = (cantDelete && !isPrimaryKey) || (!!originalField && !field.isNew)

//         const indexDisabled = isComplex || this.props.alwaysIndex;
//         const indexChecked = field.index || this.props.alwaysIndex;

//         const keyIcon = (
//             <div className="pk-margin">
//                 <Icon icon="key" />
//             </div>
//         )

//         // MDMDatatype returns an array which is unacceptable as an Component
//         const helpText = this.props.fieldError || '';

//         return <tr key={this.props.idPrefix + this.props.index} >
//             <td>
//                 <FormGroup
//                     helperText={helpText}
//                     intent={!this.props.fieldError ? Intent.NONE : Intent.DANGER}
//                 >
//                     <InputGroup
//                         fill
//                         rightElement={isPrimaryKey ? keyIcon : null}
//                         name='field'
//                         value={field.field}
//                         onChange={this.idChange}
//                         disabled={isDisabledFieldInput}
//                     />
//                 </FormGroup>
//             </td>
//             <td><Checkbox disabled={notNullable} name='nullable' checked={field.nullable} onChange={this.checkboxChange} /></td>
//             <td><Checkbox disabled={piiDisabled} name='pii' checked={field.pii} onChange={this.checkboxChange} /></td>
//             <td><Checkbox disabled={indexDisabled} name='index' checked={indexChecked} onChange={this.checkboxChange} /></td>
//             <td><Checkbox disabled={isComplex} name='display' checked={field.display} onChange={this.checkboxChange} /></td>
//             <MDMType
//                 id={'table-edit-datatype-' + this.props.index}
//                 datatype={datatype}
//                 disabled={!!this.props.fieldError}
//                 linksTo={field.links_to || ''}
//                 onSelect={this.datatypeChange}
//                 originalField={originalField}
//                 subField={this.props.subField}
//                 tables={this.props.tables}
//                 isPrimaryKey={isPrimaryKey}
//                 isTimeSeries={this.props.isTimeSeries}
//             />
//             <MDMSize
//                 id={'table-edit-datasize-' + this.props.index}
//                 datatype={datatype}
//                 links_to={field.links_to || ''}
//                 onChange={this.datatypeChange}
//                 originalField={originalField}
//                 tables={this.props.tables}
//             />
//             <MDMSigned
//                 id={'table-edit-datasign-' + this.props.index}
//                 datatype={datatype}
//                 linksTo={field.links_to || ''}
//                 linkDisplayField={field.link_display_field || ''}
//                 onChange={this.datatypeChange}
//                 originalField={originalField}
//                 tables={this.props.tables}
//             />
//             <td>
//                 <Button disabled={cantDelete} icon={IconNames.TRASH} onClick={() => this.props.onDelete(this.props.index, isPrimaryKey)} />
//                 <Button icon={IconNames.ARROW_UP} onClick={() => this.props.onMove('up', this.props.index)} disabled={this.props.index === 0} />
//                 <Button icon={IconNames.ARROW_DOWN} onClick={() => this.props.onMove('down', this.props.index)} disabled={this.props.last} />
//             </td>
//         </tr>;
//     }
// }