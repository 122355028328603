import { MINIMIZE_MENU, MAXIMIZE_MENU } from '../constants';

const initialState = false;

export function menu(state = initialState, { type }) {
    switch (type) {
        case MINIMIZE_MENU:
            return true;
        case MAXIMIZE_MENU:
            return false;
        default:
            return state;
    }
}