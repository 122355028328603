/**
 * @format
 */
import * as React from 'react';
import { Alert, Icon } from '@blueprintjs/core';
import * as immutable from 'object-path-immutable';
import { ViewApi } from '../../api/ViewApi';
import { IconNames } from '@blueprintjs/icons';

import { displayMessageBox, showToaster } from 'actions/ActionCreators';
import { MDMStore as store } from 'store';
import { Severity, Subscription, SubscriptionDeliveryType, SubscriptionSync, Intent } from 'types';
import { SubscriptionDialog } from './SubscriptionDialog';
import { ViewSubscriptionDetail } from './ViewSubscriptionDetail';
import { mdmErrorToText } from "helpers";

export interface ViewSubscriptionContainerPropTypes {
    dbName: string;
    viewID: number;
    badgeRefreshRate: number;
}
export interface ViewSubscriptionContainerState {
    showDeleteAlert: boolean;
    deleteSubscription: Subscription;
    isDialogOpen: boolean;
    dialogTitle: string;
    subscriptions: Subscription[];
    currentSubscription: Subscription;
    currentSubscriptionEnabled: boolean;
}
//TODO: default value should be in types, or some consts file
const emptySubscription: Subscription = {
    description: '',
    view_id: -1,
    delivery: {
        max_changes: 100,
        type: SubscriptionDeliveryType.webservice,
        age_out: {
            window_start: '23:00:00',
            window_end: '23:59:59',
        },
        webservice: {
            url: '',
        },
        failure_strategy: {
            retry_seconds_start: 2,
            retry_seconds_max: 60,
        },
        file: {
            target_folder: '',
            format: 'CSV',
            frequency: 60,
            pattern: 'data.csv',
            with_csv_header: true
        },
        azure: {
            connection_string: '',
            queue: '',
            topic: ''
        }
    },
    options: {
        delete_full_record: false,
        modify_old_record: false,
        modify_new_record: false,
        modify_diff: false,
    },
};
export class ViewSubscriptionContainer extends React.Component<
    ViewSubscriptionContainerPropTypes,
    ViewSubscriptionContainerState
> {
    private timer;

    constructor(props) {
        super(props);
        this.state = {
            currentSubscription: { ...emptySubscription },
            currentSubscriptionEnabled: true,
            deleteSubscription: null,
            isDialogOpen: false,
            dialogTitle: "",
            showDeleteAlert: false,
            subscriptions: [],
        };
    }
    private querySubscriptions = () => {
        ViewApi.queryViewSubscriptions(this.props.dbName, this.props.viewID)
            .then(res => this.setState({ subscriptions: (res as any).subscriptions }));
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    pollSubscriptions = () => {
        if (!this.state.isDialogOpen) {
            this.querySubscriptions();
        }
    }
    startTimer() {
        this.timer = setInterval(this.pollSubscriptions, this.props.badgeRefreshRate);
    }
    componentDidMount() {
        clearInterval(this.timer);
        this.startTimer();
        this.querySubscriptions();
    }
    onCreateSubscription = () => {
        const newSubscription = { ...emptySubscription, view_id: this.props.viewID };
        this.setState({ isDialogOpen: true, currentSubscription: newSubscription, dialogTitle: "Create New Subscription" });
    }
    onDeleteSubscription = (subscriptionID: number) => {
        const s = this.state.subscriptions.find(s => s.subscription_id === subscriptionID);
        this.setState({ showDeleteAlert: true, deleteSubscription: s, dialogTitle: "Working..." });
    }
    onModifySubscription = (subscriptionID: number) => {
        const s = this.state.subscriptions.find(s => s.subscription_id === subscriptionID);
        const title = s.state.enabled ? "View Subscription" : "Edit Subscription";
        this.setState({ isDialogOpen: true, currentSubscription: s, dialogTitle: title });
    }
    deleteSubscription(subscription: Subscription) {
        ViewApi.deleteViewSubscription(this.props.dbName, this.props.viewID, subscription.subscription_id)
            .then(res => {
                store.dispatch<any>(
                    showToaster(
                        Intent.SUCCESS,
                        `Subscription ${subscription.subscription_id} deleted`,
                    ),
                );
                this.toggleDialog();
            })
            .catch(err =>
                store.dispatch<any>(
                    displayMessageBox(
                        {
                            message: err.message,
                            stack: err.stack,
                            title: 'Delete Subscription Failed',
                            severity: Severity.danger,
                        },
                        true,
                    ),
                ),
            );
        this.closeDeleteSubscription();
    }
    enableSubscription = (enabled: boolean, subscriptionID: number, syncFrom: SubscriptionSync) => {
        ViewApi.enableViewSubscription(this.props.dbName, this.props.viewID, subscriptionID, enabled, syncFrom)
            .then(_res => {
                this.querySubscriptions();
                store.dispatch<any>(
                    showToaster(
                        Intent.SUCCESS,
                        `Subscription ${subscriptionID} ${enabled ? 'enabled' : 'disabled'}`,
                    ),
                );
            })
            .catch(err =>
                store.dispatch<any>(
                    displayMessageBox(
                        {
                            message: mdmErrorToText(err),
                            stack: err.stack,
                            title: `${enabled ? 'Enable' : 'Disable'} Subscription Failed`,
                            severity: Severity.danger,
                        },
                        true,
                    ),
                ),
            );
    }
    closeDeleteSubscription = () => {
        this.setState({ showDeleteAlert: false, deleteSubscription: null });
        this.querySubscriptions();
    }

    modifySubscription = () => {
        const subscription = this.state.currentSubscription;
        ViewApi.modifyViewSubscription(
            this.props.dbName,
            this.props.viewID,
            subscription.subscription_id,
            subscription.description,
            subscription.delivery,
            subscription.options
        )
            .then(res => {
                store.dispatch<any>(
                    showToaster(
                        Intent.SUCCESS,
                        `Subscription ${subscription.subscription_id} updated`,
                    ),
                );
                this.toggleDialog();
            })
    }

    createSubscription = () => {
        ViewApi.createViewSubscription(
            this.props.dbName,
            this.state.currentSubscriptionEnabled,
            this.state.currentSubscription,
        )
            .then((res: any) => {
                store.dispatch<any>(
                    showToaster(Intent.SUCCESS, `Subscription ${res.subscription_id} created`),
                );
                this.toggleDialog();
            })
            .catch(err =>
                store.dispatch<any>(
                    displayMessageBox(
                        {
                            message: err.message,
                            stack: err.stack,
                            title: 'Create Subscription Failed',
                            severity: Severity.danger,
                        },
                        true,
                    ),
                ),
            );
    }

    getSubscriptionDetails() {
        return this.state.subscriptions && this.state.subscriptions.length > 0 ? (
            this.state.subscriptions.map((s, i) => {

                return <ViewSubscriptionDetail
                    key={i}
                    showMenu
                    subscription={s}
                    deleteSubscription={this.onDeleteSubscription}
                    enableSubscription={this.enableSubscription}
                    modifySubscription={this.onModifySubscription}
                />
            })
        ) : (
            <div className="view-no-subscriptions">There are currently no subscriptions</div>
        );
    }

    toggleDialog = () => {
        this.setState({ isDialogOpen: false });
        this.querySubscriptions();
    }

    render() {
        return (
            <div className="view-subscription-container">
                <Alert
                    icon={IconNames.TRASH}
                    intent={Intent.DANGER}
                    isOpen={this.state.showDeleteAlert}
                    confirmButtonText="Delete Subscription"
                    cancelButtonText="Cancel"
                    onConfirm={() => this.deleteSubscription(this.state.deleteSubscription)}
                    onCancel={this.closeDeleteSubscription}
                >
                    <p>
                        {`Are you sure you want to delete '
                            ${this.state.deleteSubscription &&
                            (this.state.deleteSubscription.description ||
                                this.state.deleteSubscription.subscription_id.toString())}
                        ' ?`}
                    </p>
                </Alert>
                <SubscriptionDialog
                    enabled={this.state.currentSubscriptionEnabled}
                    isOpen={this.state.isDialogOpen}
                    dialogTitle={this.state.dialogTitle}
                    onClose={this.toggleDialog}
                    onCancel={this.toggleDialog}
                    onSave={(edit: boolean) =>
                        edit ? this.modifySubscription() : this.createSubscription()
                    }
                    subscription={this.state.currentSubscription}
                    onSubscriptionChange={(path, value) =>
                        this.setState({
                            currentSubscription: immutable.set(
                                this.state.currentSubscription,
                                path,
                                value,
                            ),
                        })
                    }
                    onEnableChange={newVal => this.setState({ currentSubscriptionEnabled: newVal })}
                />
                <div className="view-subscription-header">
                    <div>Subscriptions</div>
                    <div>
                        <button
                            className="view-add-subscription-button"
                            onClick={this.onCreateSubscription}
                        >
                            <Icon icon={IconNames.PLUS} />
                        </button>
                    </div>
                </div>
                {this.getSubscriptionDetails()}
            </div>
        );
    }
}
