import dashboard from '../../../../images/dashboard.svg'
import plus from '../../../../images/plus.svg'
import newDocument from '../../../../images/new-document.svg'
import layer from '../../../../images/layer.svg'
import layers from '../../../../images/layers.svg'
import database from '../../../../images/database.svg'
import table from '../../../../images/table.svg'
import eyeOpen from '../../../../images/eye-open.svg'
import eraser from '../../../../images/eraser.svg'
import person from '../../../../images/person.svg'
import people from '../../../../images/people.svg'
import key from '../../../../images/key.svg'
import stackedChart from '../../../../images/stacked-chart.svg'
import tickEraser from '../../../../images/tick-eraser.svg'
import arrowRight from '../../../../images/arrow-right.svg'
import arrowLeft from '../../../../images/arrow-left.svg'
import exchange from '../../../../images/exchange.svg'
import write from '../../../../images/write.svg'
import circleArrowRight from '../../../../images/circle-arrow-right.svg'
import chevronDown from '../../../../images/chevron-down.svg'
import flowArrow from '../../../../images/flow-arrow.svg'
import process from '../../../../images/process.svg'
import logs from '../../../../images/event-log-icon.svg'
import blank from '../../../../images/blank.svg'

import { CustomIconName } from '../../../types'

export function getIcon(iconName) {
    switch(iconName) {
        case CustomIconName.PLUS: return plus;
        case CustomIconName.NEW_DOCUMENT: return newDocument;
        case CustomIconName.LAYER: return layer;
        case CustomIconName.LAYERS: return layers;
        case CustomIconName.DATABASE: return database;
        case CustomIconName.TABLE: return table;
        case CustomIconName.EYE_OPEN: return eyeOpen;
        case CustomIconName.ERASER: return eraser;
        case CustomIconName.PERSON: return person;
        case CustomIconName.PEOPLE: return people;
        case CustomIconName.KEY: return key;
        case CustomIconName.DASHBOARD: return dashboard;
        case CustomIconName.STACKED_CHART: return stackedChart;
        case CustomIconName.TICK_ERASER: return tickEraser;
        case CustomIconName.ARROW_RIGHT: return arrowRight;
        case CustomIconName.ARROW_LEFT: return arrowLeft;
        case CustomIconName.EXCHANGE: return exchange;
        case CustomIconName.WRITE: return write;
        case CustomIconName.CIRCLE_ARROW_RIGHT: return circleArrowRight;
        case CustomIconName.CHEVRON_DOWN: return chevronDown;
        case CustomIconName.FLOW_ARROW: return flowArrow;
        case CustomIconName.PROCESS: return process;
        case CustomIconName.LOGS: return logs;
        default: return blank;
    }
}
