import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from '@blueprintjs/core';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';

import { RootState } from 'store';
import { WorkflowApi } from 'api';
import { formatTimeHHMM, sortDatesNullsFirst } from 'utils';
import { Workitem } from 'types';
import { claimTaskAction, continueTaskAction } from 'store/workflow/actions';
import { OVERDUE, DUE_CONCERN } from 'UiConstants';
import { getCurrentWorkflow } from 'store/workflow/selectors';
import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import './MyWorkitems.scss'

type QueryParams = {
    dbName: string;
    username: string;
}

type MutationParams = {
    database: string;
    workitemId: number;
    nodeId: string;
}

export const MyWorkitems = () => {
    const dispatch = useDispatch();
    const { dbName, username } = useSelector((state: RootState) => ({
        username: state.username,
        dbName: state.database.selected,
        currentWorkflow: getCurrentWorkflow(state),
    }));
    const qc = useQueryClient();

    const { data: assigned = [] } = useQuery(
        [
            `assigned-workitems`,
            {
                dbName,
                username,
            }
        ],
        async ({ queryKey }) => {
            const [, queryParams] = queryKey;
            const { dbName, username } = queryParams as QueryParams;
            return WorkflowApi.getAssignedWorkflows(dbName, username);
        },
        {
            enabled: !!dbName && !!username,
        }
    );
    const { data: available = [] } = useQuery(
        [
            `available-workitems`,
            {
                dbName,
                username,
            }
        ],
        async ({ queryKey }) => {
            const [, queryParams] = queryKey;
            const { dbName, username } = queryParams as QueryParams;
            return WorkflowApi.getAvailableWorkflows(dbName, username);
        },
        {
            enabled: !!dbName && !!username
        }
    );
    const { mutateAsync: claimTask } = useMutation(async (payload: MutationParams) => {
        const { database, workitemId, nodeId } = payload;
        const result = await WorkflowApi.claimTask(database, workitemId, nodeId);
        dispatch(claimTaskAction(result, database, workitemId, nodeId));
        toast.success(`Claimed workitem ${workitemId}`);
    });
    const { mutateAsync: continueTask } = useMutation(async (payload: MutationParams) => {
        const { database, workitemId, nodeId } = payload;
        const result = await WorkflowApi.continueTask(database, workitemId, nodeId);
        dispatch(continueTaskAction(result));
        toast.success(`Claimed workitem ${workitemId}`);
    });

    const handleActionPress = useCallback(async (workitem: Workitem) => {
        if (workitem.assigned_user) {
            await continueTask({
                database: dbName,
                workitemId: workitem.workitem_id,
                nodeId: workitem.node_id
            });
        } else {
            await claimTask({
                database: dbName,
                workitemId: workitem.workitem_id,
                nodeId: workitem.node_id
            });
        }

        qc.invalidateQueries([`assigned-workitems`]);
        qc.invalidateQueries([`available-workitems`]);
        qc.invalidateQueries([`active-workitems-count`]);
        qc.invalidateQueries([`assigned-workitems-count`]);
        qc.invalidateQueries([`started-workitems-count`]);
    }, [claimTask, continueTask, dbName, qc]);

    const generateWarningBar = (dueDateTimestamp: string) => {
        if (!dueDateTimestamp) {
            return <div className="due-warning due-color-gray" />
        }

        const dueDate = new Date(dueDateTimestamp).getTime();
        const now = new Date().getTime();
        const diff = (dueDate - now) / 36e5;

        let colorClass;

        if (diff < OVERDUE) {
            colorClass = "due-color-red"
        } else if (diff < DUE_CONCERN) {
            colorClass = "due-color-yellow"
        } else {
            colorClass = "due-color-green"
        }

        return <div className={`due-warning ${colorClass}`} />
    }

    const getColumns = useCallback(() => {
        const cols = createColumnHelper<Workitem>();

        return [
            cols.accessor('workitem_id', {
                header: 'ID',
                cell: ({ cell, row }) => {
                    return (
                        <div className="fill-height flex-center">
                            {generateWarningBar(row.original.due_date)}
                            <div className="id-container">
                                <span>{cell.getValue()}</span>
                                <Tooltip content={row.original.assigned_user ? "Click to Continue" : "Click to Claim"} position="bottom">
                                    <Button
                                        minimal
                                        icon={row.original.assigned_user ? "user" : "circle-arrow-down"}
                                        className="id-icon"
                                        onClick={() => handleActionPress(row.original)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    );
                },
                size: 80,
            }),
            cols.accessor('workflow_description', {
                header: 'Workflow',
                cell: ({ cell }) => (
                    <div>{cell.getValue()}</div>
                )
            }),
            cols.accessor('due_date', {
                header: 'Due Date',
                cell: ({ row }) => {
                    return row.original.due_date ? formatTimeHHMM(row.original.due_date) : null;
                },
                sortingFn: sortDatesNullsFirst,
            }),
        ];
    }, [handleActionPress]);

    const columns = useMemo(() => getColumns(), [getColumns]);
    const data = useMemo(() => [...assigned, ...available], [assigned, available]);

    if (!assigned?.length && !available?.length) {
        return (
            <div className="fill flex-center">
                <span className="text-medium">No Workitems</span>
            </div>
        )
    }

    return (
        <ReactTable
            defaultSorted={[{ id: "due_date", desc: false }]}
            className="my-workitems-panel-table -highlight"
            columns={columns}
            sortable={false}
            resizable={false}
            data={data}
            showPagination
            defaultPageSize={50}
            showPageSizeOptions={true}
        />
    );
}

export default MyWorkitems;