/* Copyright(C) 2021 Redpoint Global
 *  All Rights Reserved
 */

export const Authorities = [
    { key: "mdm", description: "MDM" },
    { key: "activedir", description: "Active Directory" },
    // { key: "auth0", description: "Auth0" },
    { key: "keycloak", description: "Key Cloak" },
    // { key: "oauth2", description: "OAuth 2.0" },
];

export const DEFAULT_AUTHORITY = Authorities[0].key;