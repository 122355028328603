import React, { Component } from 'react';
import { HTMLTable } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { arraySwap } from 'utils';
import { Button } from '../Shared';
import { isMDMName } from '../../utils';
import { normalizeId } from '../../utils';
import { TableEditField } from './TableEditField';

import { DataTypes, Field, NewField, Schema, NewSchema, SchemaType, Table } from 'types';

// TODO -- allow multiple levels of undo

export interface TableEditMainTabProps {
    originalFields?: Field[];
    piiUse: object;
    schema: NewSchema;
    showTableEditWarnDialog: (msg: string, title: string) => void;
    tables: Table[];
    update: (fields: NewField[], newPrimaryKey?: string | null) => void;
}
export class TableEditMainTab extends Component<TableEditMainTabProps> {

    warnBigChange = (type: string, action: string, actioning: string, schema: Schema, newField: NewField) => {
        // If this was a subtable AND there are fields defined in the subtable AND this is a versioned table
        if (type === 'table' && schema.type === SchemaType.versioned &&
            newField.schema && newField.schema.fields && newField.schema.fields.length > 0) {
            this.props.showTableEditWarnDialog(
                `${actioning} a Nested Table`,
                `You are about to ${action} a nested table. All contained field schema will be lost.`
            );
        }
    }

    onFieldUpdate = (index: number, newField: NewField, isPrimaryKey: boolean) => {
        const { schema } = this.props;
        const oldType = schema.fields[index].datatype.type;
        if (newField.datatype.type !== oldType) {
            this.warnBigChange(oldType, 'modify', 'Modifying', schema, newField);
            if (newField.datatype.type === DataTypes.table) {
                // Tables currently don't have min/max count
                // if(!newField.hasOwnProperty('min_count')) newField.min_count=0;
                // if(!newField.hasOwnProperty('max_count')) newField.max_count=0;
                if (newField?.schema !== undefined) {
                    newField.schema = { fields: [] } as Schema;
                }
            }
        }

        const fields = schema.fields;
        const newPrimaryKey = isPrimaryKey ? newField.field : null

        const newFields = [...fields.slice(0, index), newField, ...fields.slice(index + 1)];
        this.props.update(newFields, newPrimaryKey);
    }

    onFieldDelete = (index: number, isPrimaryKey: boolean) => {
        // Be extra warn-y if you delete a table or record
        const field = this.props.schema.fields[index];
        const type = field.datatype.type;
        this.warnBigChange(type, 'delete', 'Deleting', this.props.schema, field);
        const newFields = this.props.schema.fields.filter((f, i) => f && i !== index);

        const newPrimaryKey = isPrimaryKey ? '' : null

        // remove undefined elements because I make assumptions
        this.props.update(newFields, newPrimaryKey);
    }

    onFieldMove = (direction: string, index: number) => {
        const other = (direction === 'up' && index !== 0) ?
            // swap the index and index - 1
            index - 1 :
            (direction === 'down' && index !== this.props.schema.fields.length - 1) ?
                index + 1 : false;

        if (other !== false) {
            const newFields = arraySwap(this.props.schema.fields, index, other);
            this.props.update(newFields);
        }
    }

    /**
     * Verify the newName is not the name of a different field
     * @param index
     * @param newName
     * @return {{ok: boolean, error: string}}
     */
    getFieldError = (newName: string): string => {
        if (!isMDMName(newName)) {
            return `Name must use letters, numbers and underscores only.`;
        }

        const { schema } = this.props;
        const normalizedName = normalizeId(newName);
        const repeatCount = schema.fields.filter(field => normalizeId(field.field) === normalizedName).length;

        if (repeatCount > 1) {
            return 'Field already exists.'
        }

        return null;
    }

    newField = () => {
        const nullable = this.props.originalFields ? true : false;
        const fieldNumber = this.props.schema.fields.reduce((last, field) => {
            let val = last;
            if (field.field.startsWith('newfield') && field.field.length > 'newfield'.length) {
                const test = Number(field.field.substring('newfield'.length));
                if (!Number.isNaN(test) && test + 1 > val) {
                    val = test + 1;
                }
            }
            return val;
        }, 1);
        const newField = {
            field: 'newfield' + fieldNumber,
            nullable: nullable,
            pii: false,
            index: false,
            display: true,
            datatype: { type: DataTypes.text, size: 64, signed: true },
            isNew: true
        };
        const newFields = this.props.schema.fields.concat(newField);
        this.props.update(newFields);
    }
    isPrimaryKey = (field: string, index: number): boolean =>
        this.props.schema.primary_key === field && this.props.schema.fields.findIndex(f => f.field === field) === index
    isSequenceField = (field: string, index: number): boolean =>
        this.props.schema.sequence_field === field && this.props.schema.fields.findIndex(f => f.field === field) === index
    render() {
        const schema = this.props.schema;
        const lastFieldIndex = schema.fields.length - 1;
        const isTimeSeries = schema.type === "timeseries";
        const rows = schema.fields.map((f, index) => {
            // timestamp name is reserved and required for time series tables
            const isTimeSeriesField = isTimeSeries && f.field === "timestamp";
            const isPrimaryKey = f.field && this.isPrimaryKey(f.field, index);
            const isSequenceField = f.field && this.isSequenceField(f.field, index);
            const alwaysIndex = isTimeSeriesField || isPrimaryKey;
            const piiDisabled = isPrimaryKey
                || f.datatype.type === 'table'
                || this.props.piiUse?.[f.field] !== undefined
                || !!(this.props.originalFields && this.props.originalFields[f.field] && this.props.originalFields[f.field].pii)
                || isTimeSeriesField;
            const isReferenced = isPrimaryKey || isSequenceField || isTimeSeriesField || piiDisabled;
            // If the field already existed, you can't change it from nullable to not nullable
            const notNullable = isReferenced || (this.props.originalFields && this.props.originalFields[f.field] && this.props.originalFields[f.field].nullable);

            const fieldError = this.getFieldError(f.field);

            // TODO - I won't let you delete if you are the primary key or sequence field
            // But should it be a separate flag
            return <TableEditField
                key={index}
                field={f}
                originalField={this.props.originalFields ? this.props.originalFields[f.field] : isTimeSeriesField ? {} : undefined}
                idPrefix='table-create-main-'
                index={index}
                last={index === lastFieldIndex}
                onChange={this.onFieldUpdate}
                onDelete={this.onFieldDelete}
                onMove={this.onFieldMove}
                piiDisabled={piiDisabled}
                tables={this.props.tables}
                notNullable={notNullable}
                cantDelete={isReferenced}
                alwaysIndex={alwaysIndex}
                isPrimaryKey={isPrimaryKey}
                fieldError={fieldError}
                isTimeSeries={isTimeSeries}
            />;
        });
        return <HTMLTable striped={true} bordered={true} style={{ width: "100%" }}>
            <thead><tr>
                <th>Field</th>
                <th>Nullable</th>
                <th>PII</th>
                <th>Index</th>
                <th>Display</th>
                <th>Type</th>
                <th>Details</th>
                <th></th>
                <th>Actions</th>
            </tr></thead>
            <tbody>
                {rows}
                <tr>
                    <td colSpan={9}>
                        <Button style={{ marginLeft: '75px' }} className='toolbar-narrow' onClick={this.newField} title='Add a field to table' icon={IconNames.PLUS} />
                    </td>
                </tr>
            </tbody>
        </HTMLTable>;
    }
}
