import * as React from 'react';
import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import * as jsonParse from 'json-parse-better-errors';

import { Button } from 'components/Shared';
import { clearViewValidationErrors, createView, getAllViews, modifyView, validateView } from 'store/view/actions';
import { debounce } from 'lodash';
import { ViewEditForm } from './ViewEditForm';
import { ViewSubscriptionContainer } from './ViewSubscriptionContainer';
import { ValidationResult, View } from 'types';

import { BADGE_REFRESH_MS } from "../../UiConstants";

export interface ViewEditContainerPropTypes {
    dbName: string;
    history: any;
    badgeRefreshRate: number;
    match: any;
    views: View[];
    viewValidation: ValidationResult;
    clearViewValidationErrors: () => void;
    createView: (database: string, view: object) => void;
    getAllViews: (database: string) => void;
    modifyView: (database: string, view: object) => void;
    validateView: (database: string, view: object) => void;
}

export interface ViewEditContainerState {
    edit: boolean;
    readOnly: boolean;
    viewName: string;
    viewID: number;
    viewJSON: string;
    parseError: string;
}

export class ViewEditContainerBase extends React.Component<ViewEditContainerPropTypes, ViewEditContainerState> {
    constructor(props) {
        super(props);
        const viewID = props.match.params.viewID && parseInt(props.match.params.viewID, 10) || undefined;
        const viewState = this.getViewState(viewID, props.views, props.match.url);
        this.state = {
            ...viewState,
            parseError: ''
        };
        this.parseJson(viewState.viewJSON);
        this.debouncedParseJson = debounce(this.parseJson, 250);
    }

    componentDidMount() {
        this.props.getAllViews(this.props.dbName);
    }

    componentDidUpdate(prevProps) {
        const { match, views, dbName } = this.props;
        const viewID = parseInt(match.params.viewID, 10);

        if (prevProps.views.length !== this.props.views.length) {
            const viewID = this.props.match.params.viewID && parseInt(this.props.match.params.viewID, 10) || undefined;
            const viewState = this.getViewState(viewID, this.props.views, this.props.match.url);

            this.setState({ ...this.state, ...viewState });
        }

        if (dbName != prevProps.dbName) {
            this.props.getAllViews(dbName);
            this.closeForm();
        }

        if (!!viewID && this.state.viewID !== viewID ||
            prevProps.match.url !== match.url) {
            const viewState = this.getViewState(viewID, views, match.url);
            const newState = { ...this.state, ...viewState };
            this.setState(newState);
        }
    }

    debouncedParseJson: (newJSON: string) => void;

    getViewState(viewID: number, views: View[], path: string) {
        const view = views && views.find(v => v.view_id === viewID);
        // Remove any stack traces-- they're useless to the user and dominate the
        // edit form when present.
        view && view.state && view.state.validation &&
            view.state.validation.errors && view.state.validation.errors.forEach(e => delete e.stack_trace);
        return {
            edit: !!view,
            readOnly: !!path.startsWith("/views/view"),
            viewID: viewID,
            viewName: view ? view['description'] : '',
            viewJSON: view ? JSON.stringify(view, null, 2) : ''
        };
    }

    parseJson(newJSON: string) {
        let parseError = '';
        // Clear any validation errors
        this.props.clearViewValidationErrors();
        try {
            const result = jsonParse(newJSON);
        } catch (error) {
            parseError = `JSON parse error: ${error.message}`;
        }
        this.setState({ parseError: parseError });
    }

    getJSON(jsonString: string) {
        try {
            return JSON.parse(jsonString);
        } catch (e) {
            console.log(e);
        }
    }

    closeForm = () => {
        this.props.getAllViews(this.props.dbName);
        this.props.history.push('/views');
    }

    updateViewJSON = (newJSON: string) => {
        const newState = { ...this.state, viewJSON: newJSON };
        this.setState(newState, () => this.debouncedParseJson(newJSON));
    }

    updateViewName = (newName: string) => {
        const newState = { ...this.state, viewName: newName };
        this.setState(newState);
    }

    editView = () => {
        this.props.history.push(`/views/edit/${this.state.viewID}`);
    }

    saveView = () => {
        const newJSON = this.getJSON(this.state.viewJSON) || {};
        newJSON["view_id"] = this.state.viewID;
        newJSON["description"] = this.state.viewName;
        if (this.state.edit)
            this.props.modifyView(this.props.dbName, newJSON);
        else
            this.props.createView(this.props.dbName, newJSON);
        this.closeForm();
    }

    validateView = () => {
        const newJSON = this.getJSON(this.state.viewJSON);
        newJSON["description"] = this.state.viewName;
        this.props.validateView(this.props.dbName, newJSON);
    }

    render() {
        // Get validation errors (if any)
        const validation = this.props.viewValidation;
        const readOnly = this.state.readOnly;
        const status = validation && validation.valid ? 'Computed view JSON valid' : '';
        // Always prefer parse errors to validation errors
        const error = this.state.parseError ||
            (validation.errors ? validation.errors.map(e => e.message).join('\n') : '');
        const titleSaveButton = readOnly ? <Button className="edit-view-button" value="EDIT VIEW" onClick={this.editView} /> :
            <Button id="view-save-btn" disabled={!!this.state.parseError || !this.state.viewName} value="SAVE VIEW" intent={Intent.SUCCESS} onClick={this.saveView} />;
        return (
            <div className="view-container">
                <div className="view-title-buttons">
                    {titleSaveButton}
                    <Button className="view-container-cancel-button" value={readOnly ? "CLOSE" : "CANCEL"} onClick={this.closeForm} />
                </div>
                <div className="view-edit-container">
                    <div className="view-edit-title">{readOnly ? 'VIEW NAME' : this.state.edit ? 'EDIT VIEW' : 'CREATE NEW VIEW'}</div>
                    <div className="view-edit-name">
                        <input type="text" disabled={readOnly} placeholder={readOnly ? '' : "Add a view name"} style={{ border: 'none', outline: 'none' }}
                            data-name="viewName" value={this.state.viewName} onChange={e => this.updateViewName(e.target.value)} />
                    </div>
                    <div className={readOnly ? '' : "view-edit-divider"} />
                    <div className="view-details-container">
                        <ViewEditForm viewJSON={this.state.viewJSON} error={error} status={status} readOnly={readOnly}
                            updateViewJSON={this.updateViewJSON} validateViewJSON={this.validateView} />
                        {readOnly ? <ViewSubscriptionContainer dbName={this.props.dbName} viewID={this.state.viewID} badgeRefreshRate={this.props.badgeRefreshRate} /> : null}
                    </div>
                </div>
            </div>
        );
    }
}

export const ViewEditContainer = connect(
    (state: any) => ({
        dbName: state.database.selected,
        viewValidation: state.view.validation,
        views: state.view.computedViews,
        badgeRefreshRate: state.badgeRefreshRate || BADGE_REFRESH_MS
    }),
    { clearViewValidationErrors, createView, getAllViews, modifyView, validateView }
)(ViewEditContainerBase);

