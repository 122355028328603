import React, { ChangeEvent, Component, FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    HTMLSelect,
    Classes,
    Dialog,
 } from '@blueprintjs/core';

import { DataType, DataTypes, Table, Field } from 'types';
import { Button } from './Button';
import { IconNames } from '@blueprintjs/icons';
/**
  MDMDatatype
     Provide a mechanism to display and interact the type/size/signed values.
      Also support the link to pseudo type
     onChange: called with ( { type, size, signed } )
  */

// reason for the placeholder is to make indexes start at 1 for valid types
const LINKS_TO_TYPE = "link to";
const types: string[] = ["PLACEHOLDER DO NOT USE",
    "text", "money", "integer", "float", "date", "time", "datetime", "boolean", "table",
    LINKS_TO_TYPE];
const subTypes: string[] = ["PLACEHOLDER DO NOT USE",
    "text", "money", "integer", "float", "date", "time", "datetime", "boolean",
    LINKS_TO_TYPE];
const idTypes: string[] = ["Zero index",
    "text", "integer"];

export class MDMDatatype {
    static MIN_TEXT_SIZE = 1;
    static DEFAULT_TEXT_SIZE = 64;
    static MAX_TEXT_SIZE = 1000000;

    static valid(datatype: DataType): boolean {
        const type = datatype.type;
        const size = Number(datatype.size);
        const signed = datatype.signed;

        switch (type) {
            case DataTypes.text:
            case DataTypes.binary:
                return size >= MDMDatatype.MIN_TEXT_SIZE && size <= MDMDatatype.MAX_TEXT_SIZE;

            case DataTypes.integer:
                if ([1, 2, 4].includes(size)) {
                    return true;
                }
                if (size === 8 && signed) {
                    return true;
                }

                return false;

            case DataTypes.float:
                return ([4, 8].includes(size));

            case DataTypes.money:
            case DataTypes.date:
            case DataTypes.time:
            case DataTypes.datetime:
            case DataTypes.boolean:
                return true;

            // To truly validate a complex field you need additional information
            case 'table':
                return undefined;
            default:
                return false;
        }
    }

    static fixSizeAndSign(type: DataTypes, datatype: DataType): DataType {
        switch (type) {
            case DataTypes.text:
            case DataTypes.binary:
                if (datatype.size < MDMDatatype.MIN_TEXT_SIZE) return { type: type, size: MDMDatatype.MIN_TEXT_SIZE, signed: false };
                if (datatype.size > MDMDatatype.MAX_TEXT_SIZE) return { type: type, size: MDMDatatype.MAX_TEXT_SIZE, signed: false };
                return { type: type, size: datatype.size, signed: datatype.signed };

            case DataTypes.integer:
                if (datatype.signed) {
                    return { type: type, size: [1, 2, 4, 8].includes(datatype.size) ? datatype.size : 4, signed: true };
                }
                return { type: type, size: [1, 2, 4].includes(datatype.size) ? datatype.size : 4, signed: false };

            case DataTypes.float:
                return { type: type, size: [4, 8].includes(datatype.size) ? datatype.size : 4, signed: true };

            default:
                return { type: type, size: 0, signed: false };
        }
    }
}

export interface MDMTypeProps {
    id: string;
    datatype: DataType;
    disabled: boolean;
    isPrimaryKey: boolean;
    linksTo?: string;
    onSelect: (type: string, datatype: DataType | string) => void;
    originalField: Field;
    subField: boolean;
    tables: Table[];
    isTimeSeries?: boolean;
}
export class MDMType extends Component<MDMTypeProps> {

    onSelect = (key: DataTypes | string) => {
        const newType = key;
        if (newType === LINKS_TO_TYPE) {
            this.props.onSelect('links_to', this.props.linksTo);
        }
        else {
            const attributes = MDMDatatype.fixSizeAndSign(newType as DataTypes, this.props.datatype);
            const size = key === DataTypes.text ? 64 : attributes.size;
            const newDatatype: DataType = { type: newType as DataTypes, size: size, signed: attributes.signed };
            this.props.onSelect('datatype', newDatatype);
        }
    }

    render() {
        const { originalField, datatype, subField, tables, isPrimaryKey, linksTo, disabled, isTimeSeries, id } = this.props;
        if (originalField) {
            // While not exactly a type, "link to" should be displayed on link fields.
            const displayedType = originalField.links_to ? 'link to' : datatype.type

            // Field Types may not be changed. Render as a dropdown with a single value.
            // Could disable as well
            return <td>
                <HTMLSelect
                    id={id}
                    disabled={true}
                    fill={true}
                    options={[displayedType]}
                />
            </td>;
        }

        // pop the placeholder
        // If there are no tables then there is no 'link to'
        let options = (subField ? subTypes : types).slice(1);
        options = tables.length === 0 ? options.slice(0, -1) : options;

        // I am sure there is a more elegant way
        if (isPrimaryKey) {
            options = idTypes.slice(1);
        } else if (isTimeSeries) {
            options = options.filter(option => option !== "table")
        }

        const type = linksTo ? LINKS_TO_TYPE : datatype.type;
        return (
            <td>
                <HTMLSelect
                    id={id}
                    disabled={disabled}
                    fill={true}
                    value={type}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => this.onSelect(e.target.value)}
                    options={options}
                />
            </td>);
    }
}

export interface MDMSizeProps {
    id: string;
    datatype: DataType;
    links_to?: string;
    onChange: (key: string, datatype: DataType | string) => void;
    originalField: Field;
    tables: Table[];
}
export class MDMSize extends Component<MDMSizeProps> {

    onChange = (event) => {
        const value = event.target.value;
        let newDatatype;

        if (event.target.name === '_100ksize') {
            newDatatype = { type: this.props.datatype.type, size: value, signed: this.props.datatype.signed };
            this.props.onChange('datatype', newDatatype);
        }
    }

    onSelect = (key: string) => {
        const newDatatype = { type: this.props.datatype.type, size: Number(key), signed: this.props.datatype.signed };
        this.props.onChange('datatype', newDatatype);
    }

    onTableSelect = (tableName: string) => {
        this.props.onChange('links_to', tableName);
    }

    render() {
        const { originalField, tables, links_to, id } = this.props;
        let items, size;

        if (links_to) {
            items = tables.map(t => t.table);
            return <td>
                <HTMLSelect
                    id={id}
                    disabled={!!originalField}
                    value={links_to}
                    fill={true}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => this.onTableSelect(e.target.value)}
                    options={items}
                />
            </td>;
        }

        switch (this.props.datatype.type) {
            case DataTypes.text:
            case DataTypes.binary:
                // TODO add range validation
                return <td>
                    <input className={Classes.INPUT} name='_100ksize' type="input" value={this.props.datatype.size}
                        onChange={this.onChange} />
                </td>;

            case DataTypes.integer:
                items = [];
                // only this size or greater if editing
                size = (this.props.originalField) ? this.props.originalField.datatype.size : 1;
                // if(this.props.originalField && this.props.datatype.signed != this.props.originalField.datatype.signed) {
                //     size *= 2; // double it.  Could do size <<= 1 or size += size but *2 seems clearer
                // }
                if (size <= 1) items.push(1);
                if (size <= 2) items.push(2);
                if (size <= 4) items.push(4);
                if (this.props.datatype.signed)
                    items.push(8);

                return <td>
                    <HTMLSelect
                        fill={true}
                        value={this.props.datatype.size}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => this.onSelect(e.target.value)}
                        options={items}
                    />
                </td>;

            case DataTypes.float:
                items = [];
                size = (this.props.originalField) ? this.props.originalField.datatype.size : 4;
                if (size <= 4) items.push(4);
                items.push(8);
                return <td>
                    <HTMLSelect
                        fill={true}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => this.onSelect(e.target.value)}
                        options={items}
                    />
                </td>;

          
            default:
                return <td></td>;
        }
    }

}

export interface MDMSignedProps {
    id: string;
    datatype: DataType;
    linksTo?: string;
    linkDisplayField?: string;
    onChange: (key: string, type: DataType | string) => void;
    originalField: Field;
    tables: Table[];
    values: string[];
}
export const MDMSigned: FC<MDMSignedProps> = (props) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        // size 8 and unsigned is not permitted
        const size = (!event.target.checked && props.datatype.size === 8) ? 4 : props.datatype.size;
        const newDatatype = { type: props.datatype.type, size: size, signed: Boolean(event.target.checked) };
        props.onChange('datatype', newDatatype);
    };

    const onDisplaySelect = (key: string) => {
        const table = props.tables.find(t => t && t.table === props.linksTo);
        if (table) {
            console.log(`Selected ${table.table}.${key}`);
        }
        props.onChange('link_display_field', key);
    };

    const onEnumChange = (values) => {
        props.onChange('text', values);
    }

    // TODO - Use the scalar fields in the links_to table for the MenuItems
    if (props.linksTo) {
        // TODO - wait for link display column to exist
        //return <td></td>;

        const table = props.tables.find((t) => t && t.table === props.linksTo);
        if (!table) {
            return <td></td>;
        }
        const scalarFields = table.schema.fields
            .filter(f => !(f.datatype.type === DataTypes.table))
            .map(f => f.field);

        return (
            <td>
                <HTMLSelect
                    id={props.id}
                    disabled={!!props.originalField}
                    value={props.linkDisplayField}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => onDisplaySelect(e.target.value)}
                    options={scalarFields}
                />
            </td>
        );
    }

    if (props.datatype.type === DataTypes.integer) {
        // 8 must be signed (and disabled)
        // changing sign is disable size <= original size
        if (props.datatype.size === 8 ||
            props.originalField && props.datatype.size <= props.originalField.datatype.size) {
            return <td><Checkbox checked={props.datatype.signed} disabled={true}>Signed</Checkbox></td>;
        }
        return <td><Checkbox checked={props.datatype.signed} onChange={onChange} >Signed</Checkbox></td>;
    }

    if (props.datatype.type === DataTypes.text && props.datatype.size <= 64 && props.datatype.size > 0) {
        return <MDMTextEnum datatype={props.datatype} onChange={onEnumChange} values={props?.values || []} />;
    }

    return <td></td>;
};


export interface MDMTextEnumProps {
    // id: string;
    datatype: DataType;
    onChange: (values: string[]) => void;
    values: string[];
}

export const MDMTextEnum: FC<MDMTextEnumProps> = ({ datatype, onChange, values: initialValues }) =>  {
    const [isOpen, setIsOpen] = useState(false);
    const [values, setValues] = useState<string[]>([]);
    const [value, setValue] = useState<string>('');
    const [error, setError] = useState<string>('');

    const inputRef = useRef<HTMLInputElement>(null);

    // set initial values on mount
    useEffect(() => {
        setValues(initialValues);
    }, [initialValues]);

    // focus input on open
    useEffect(() => {
        if (isOpen) {
            inputRef.current?.focus();
        }
    }, [isOpen]);


    const addNewValue = () => {
        const newValues = [...values];
        const size = datatype.size ?? 64;

        if (value.length === 0) {
            setError('Value cannot be empty');
            return;
        }

        // sanitize value before adding
        let sanitizedValue = value.trim();
        
        // allow all characters except for these special characters < > & ' "
        sanitizedValue = sanitizedValue.replace(/<|>|&|'|"/g, '')


        // check for duplicates
        if (newValues.includes(sanitizedValue)) {
            setError(`"${sanitizedValue}" already exists`);
            return;
        }

        // if sanitized value is empty, return
        if (sanitizedValue.length === 0) {
            setError('Value cannot be empty');
            return;
        }

        // remove characters beyond 64
        if (sanitizedValue.length > size) {
            setError(`Value must be equal to or less than ${size} characters`);
            return;
        }

        newValues.push(sanitizedValue);
        setValues(newValues);
        setValue('');
        setError('');
        inputRef.current?.focus();
    }

    const onSubmit = useCallback(() => {
        onChange(values);
        setIsOpen(false);
        setValues(initialValues);
        setError('');
        setValue('');
    }, [values, onChange, initialValues])

    return (
        <td>
            <Button disabled={datatype.size === 0} onClick={() => setIsOpen(true)}>Values ({initialValues.length})</Button>
            <Dialog isOpen={isOpen}>
                <div className="enum-values-dialog">
                    {values.map((v, i) => (
                        <div className="enum-entry" key={`item-${v}-${i}`}>
                            <div className="enum-value">
                                <span title={v}>{v}</span>
                            </div>
                            <div className="enum-controls"> 
                                <Button
                                    icon={IconNames.ARROW_UP}
                                    minimal

                                    title="Move Up"
                                    onClick={() => {
                                        // move up in list without temp value
                                        if (i > 0) {
                                            const newValues = [...values];
                                            [newValues[i - 1], newValues[i]] = [newValues[i], newValues[i - 1]];
                                            setValues(newValues);
                                        }
                                    }}
                                />
                                <Button
                                    icon={IconNames.DOUBLE_CHEVRON_UP}
                                    minimal
                                    title="Move to Top"
                                    onClick={() => {
                                        if (i > 0) {
                                            const newValues = [...values];
                                            newValues.unshift(newValues.splice(i, 1)[0]);
                                            setValues(newValues);
                                        }
                                    }}
                                />

                                <Button
                                    icon={IconNames.ARROW_DOWN}
                                    minimal
                                    title="Move Down"
                                    onClick={() => {
                                        // move down in list
                                        if (i < values.length - 1) {
                                            const newValues = [...values];
                                            [newValues[i], newValues[i + 1]] = [newValues[i + 1], newValues[i]];
                                            setValues(newValues);
                                        }
                                    }}
                                />
                                <Button
                                    icon={IconNames.DOUBLE_CHEVRON_DOWN}
                                    minimal
                                    title="Move to Bottom"
                                    onClick={() => {
                                        if (i < values.length - 1) {
                                            const newValues = [...values];
                                            newValues.push(newValues.splice(i, 1)[0]);
                                            setValues(newValues);
                                        }
                                    }}
                                />
                                <Button
                                    icon={IconNames.TRASH}
                                    minimal
                                    title="Delete"
                                    onClick={() => {
                                        // delete
                                        const newValues = [...values];
                                        newValues.splice(i, 1);
                                        setValues(newValues);
                                    }}
                                />
                            </div>
                        </div>
                    ))}

                    <div className="enum-list-controls">
                        <div className="input-container">
                            <input
                                ref={inputRef}
                                type="text"
                                onChange={(e) => setValue(e.target.value)}
                                // add to values on enter
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        addNewValue()
                                    }
                                }}
                                value={value}
                                // make resizable
                                style={{ resize: 'vertical' }}
                            />
                            <div className="enum-error">{error}</div>
                        </div>
                        <div className="input-actions">
                            <Button
                                icon={IconNames.PLUS}
                                title="Add Value"
                                onClick={() => {
                                    addNewValue();
                                }}
                            />
                            <Button
                                icon={IconNames.SORT}
                                title="Sort"
                                onClick={() => {
                                    // sort but reverse it every click
                                    const newValues = [...values];
                                    newValues.sort((a, b) => a.localeCompare(b));
                                    if (JSON.stringify(newValues) === JSON.stringify(values)) {
                                        newValues.reverse();
                                    }
                                    setValues(newValues);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="enum-action-container">
                    <Button onClick={onSubmit} disabled={value.length > 0} title={value.length > 0 ? 'Add or remove the current value first' : ''}>
                        OK
                    </Button>
                    <Button onClick={() => {
                        setIsOpen(false);
                        setValues(initialValues);
                        setError('');
                        setValue('');
                    }}>
                        Cancel
                    </Button>
                </div> 
            </Dialog>
        </td>
    );
            
} 