/**
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Callout, FormGroup, HTMLSelect } from '@blueprintjs/core';

import { displayError, getSchema, TableApi } from 'api';
import { displayMessageBox, showToaster } from 'actions';
import { ErasureDialog, ErasureDialogType } from './ErasureDialog';
import ErasureRecordsTable from './ErasureRecordsTable';
import { ErrorBoundary } from 'components/Shared';
import { FilterField, QueryFilter, Schema, Table, Intent } from 'types';
import { Pagination } from 'rp-mdm-common';
import { hasPIIFields } from 'utils';
import { mdmErrorToText } from "helpers";
import { MDMWrapper } from 'components/Shared';
import { QueryRecordsForm } from 'components/QueryRecordsForm';
import { RecordApi } from '../../api/RecordApi';

import { RECORD_QUERY_MAX } from "../../UiConstants";

import './Erasure.scss';

export interface ErasureProps {
    changeset: any;
    changesets: any[];
    dbName: string;
    displayMessageBox: any;
    getRecordsHistory: Function;
    showToaster: showToaster;
}
export interface ErasureState {
    dialogType: ErasureDialogType;
    fields: FilterField[];
    hasQueried: boolean;
    isErasureDialogOpen: boolean;
    records: object[];
    schema: Schema;
    selectedTable: string;
    selectedRecord: object;
    selectedRecordKey: any;
    tables: Table[];
    loading: boolean;
}
export class ErasureBase extends React.Component<ErasureProps, ErasureState> {
    constructor(props: ErasureProps) {
        super(props);
        this.state = {
            dialogType: ErasureDialogType.Mask,
            selectedRecordKey: null,
            fields: [],
            isErasureDialogOpen: false,
            records: [],
            schema: null,
            selectedTable: '',
            selectedRecord: {},
            hasQueried: false,
            tables: [],
            loading: false
        };
    }

    selectDefaultTable = () => {
        const tables = this.state.tables || [];
        let count = tables.filter(t => hasPIIFields(t.schema)).reduce((acc, t) => acc + 1, 0);
        // Automatically select the table if there is only one
        if (!this.state.selectedTable && count === 1) {
            const table = this.state.tables.find(t => hasPIIFields(t.schema)).table;
            getSchema(this.props.dbName, this.props.changeset.changeset, table)
                .then(schema => this.setState({ selectedTable: table, schema, records: [] }));
        }
    }

    quryTablesForCurrentDb = () => {
        TableApi.queryTables(this.props.dbName, this.props.changeset.changeset)
            .then(tables => this.setState({ selectedTable: '', tables: tables }))
            .catch(err => console.error(err));
        this.selectDefaultTable();
    }

    public componentDidMount() {
        this.quryTablesForCurrentDb()
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.dbName !== this.props.dbName) {
            this.setState({ records: [] }, () => {
                this.quryTablesForCurrentDb();
            })
        }

        this.selectDefaultTable();
    }

    displayRecordsError = displayError('Query Records Failed', 'Failed to find(query) records');

    private getRecords = async (dbName: string, table: string, pagination?: Pagination) => {
        if (table) {

            const queryParams = {
                errorHandler: this.displayRecordsError,
                database: dbName,
                changeset: this.props.changeset.changeset,
                table: table,
                filter: { fields: this.state.fields },
                include: {
                    match: true
                },
            }

            this.setState({ loading: true }, () => {
                RecordApi.queryRecords(queryParams, pagination).then((records) => {
                    if (records.length >= RECORD_QUERY_MAX)
                        showToaster(
                            Intent.PRIMARY,
                            `Only the first ${RECORD_QUERY_MAX.toLocaleString()} records will be shown. Please refine results by editing your query.`
                        );
                    this.setState({ records: records, loading: false });
                })
            })
        }
    }

    private paginateCurrentRecords = (pagination: Pagination) => {
        const { dbName } = this.props;
        const { selectedTable } = this.state
        this.getRecords(dbName, selectedTable, pagination)
    }

    private queryRecords = async (fields?: FilterField[]) =>
        this.setState({
            hasQueried: true,
            fields: fields != null ? fields : undefined
        }, () => this.getRecords(this.props.dbName, this.state.selectedTable))

    private onMaskPurge = (recordKeys: any[]) => {
        this.state.dialogType === ErasureDialogType.Mask ?
            RecordApi.maskPIIFields(this.props.dbName, this.state.selectedTable, recordKeys)
                .then(() => {
                    this.setState({ isErasureDialogOpen: false });
                    this.props.showToaster(Intent.SUCCESS, `Records Masked.`);
                    this.getRecords(this.props.dbName, this.state.selectedTable);
                })
                .catch(err => {
                    this.setState({ isErasureDialogOpen: false });
                    this.props.displayMessageBox({
                        title: 'Mask Records Failed',
                        message: `Failed to mask records: ${mdmErrorToText(err)}`,
                        stack: err && err.stack,
                    })
                }) :
            RecordApi.purgeRecords(this.props.dbName, this.state.selectedTable, recordKeys)
                .then(() => {
                    this.setState({ isErasureDialogOpen: false });
                    this.props.showToaster(Intent.SUCCESS, `Records Purged.`);
                    this.getRecords(this.props.dbName, this.state.selectedTable);
                })
                .catch(err => {
                    this.setState({ isErasureDialogOpen: false });
                    this.props.displayMessageBox({
                        title: 'Purge Records Failed',
                        message: `Failed to purge records: ${mdmErrorToText(err)}`,
                        stack: err && err.stack,
                    })
                });
    }

    displaySchemaError = displayError('Get Schema Failed');

    private onTableChange = e => {
        const value = e.target.value;
        if (value !== '' && value !== this.state.selectedTable) {
            getSchema(this.props.dbName, this.props.changeset.changeset, value)
                .then(schema => this.setState({ selectedTable: value, schema: schema, records: [] }));
        }
    }

    private getRecord = async (pk: string) => {
        const records = await RecordApi.queryRecords({
            errorHandler: displayError,
            database: this.props.dbName,
            changeset: this.props.changeset.changeset,
            table: this.state.selectedTable,
            include: { match: true },
            filter: { fields: [{ field: this.state.schema.primary_key, operation: "EQ", values: [pk] }] }
        });
        return records.length ? records[0] : {};
    }
    private openErasureDialog = (type: ErasureDialogType, id: string) => {
        // If closing dialog, query records in case of changes
        id != null && this.getRecord(id)
            .then(record =>
                this.setState({
                    ...this.state,
                    selectedRecordKey: id,
                    selectedRecord: record,
                    dialogType: type,
                    isErasureDialogOpen: true
                })
            );
    }

    generateQueryRecordsForm = () => {
        const { schema, selectedTable } = this.state;

        if (!selectedTable)
            return <div> *Please select a table in order to query its records. </div>

        return (
            <QueryRecordsForm
                queryRecords={this.queryRecords}
                schema={schema}
                showMasked={false}
                table={selectedTable}
                initialField={true}
                initialOpenState={true}
                needsQuery
            />
        )
    }

    public render() {
        // state.tables can be null if you get here without sufficient permissions either
        // by bad navigation or manually typing in the URL
        const tables = this.state.tables || [];

        const tablesWithPIIFields = tables.filter(t => hasPIIFields(t.schema)).map(t => ({ value: t.table, label: t.table }));

        const options = [{ value: "", label: "Please select a table" }]
            .concat(tablesWithPIIFields)

        if (!tablesWithPIIFields || !tablesWithPIIFields.length)
            return (
                <MDMWrapper title="Erasure" documentationPath="mask_or_purge_record.html">
                    <div className="flex-column">
                        <span className="text-medium">You must enable the PII option on your table to use the feature. </span>
                        <br />
                        <span className="text-small">*You may do this by going to the "Tables" page and editing the schema of a table you want to see here. </span>
                    </div>
                </MDMWrapper>
            )

        return (
            <ErrorBoundary>
                <ErrorBoundary>
                    <ErasureDialog
                        isOpen={this.state.isErasureDialogOpen}
                        onClose={() => this.setState({ isErasureDialogOpen: false }, () => this.queryRecords())}
                        onMaskPurge={keys => this.onMaskPurge(keys)}
                        primaryKey={this.state.schema && this.state.schema.primary_key}
                        queryRecords={(filter: QueryFilter) => RecordApi.queryRecords({
                            errorHandler: this.displayRecordsError,
                            database: this.props.dbName,
                            changeset: this.props.changeset.changeset,
                            table: this.state.selectedTable,
                            filter: filter
                        })}
                        record={this.state.selectedRecord}
                        schema={this.state.schema}
                        type={this.state.dialogType}
                    />
                </ErrorBoundary>

                <MDMWrapper title="Erasure" documentationPath="mask_or_purge_record.html">
                    <Callout>
                        <FormGroup inline={true} label="Target Table">
                            <HTMLSelect
                                onChange={this.onTableChange}
                                value={this.state.selectedTable}
                                options={options}
                            />
                        </FormGroup>
                    </Callout>
                    <br />

                    {this.generateQueryRecordsForm()}

                    {this.state.hasQueried ? (
                        <ErasureRecordsTable
                            loading={this.state.loading}
                            paginate={this.paginateCurrentRecords}
                            records={this.state.records}
                            schema={this.state.schema}
                            noDataText={'No Records Were Found'}
                            openMaskDialog={(id) => this.openErasureDialog(ErasureDialogType.Mask, id)}
                            openPurgeDialog={(id) => this.openErasureDialog(ErasureDialogType.Purge, id)}
                        />
                    ) : null}
                </MDMWrapper>
            </ErrorBoundary>
        );
    }
}

export const Erasure = connect(
    (state: any) => ({
        dbName: state.database.selected,
        changeset: state.changeset,
        changesets: state.changesets,
    }),
    {
        displayMessageBox,
        showToaster,
    },
)(ErasureBase as any);
