/* Copyright(C) 2020 Redpoint Global
 *  All Rights Reserved
 */

import React, {FC, useMemo, useState} from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 as Popover } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { createColumnHelper } from '@tanstack/react-table';
import numeral from 'numeral';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { Table } from 'types';
import { inRoles } from 'api';
import { Button } from '../Shared';
import TableExportDownloadButton from './TableExportDownloadButton';
import { useHasRole } from 'api/inRoles';

export interface TablesTableProps {
    tables: object[];
    deleteTable: (table: string) => void;
    truncateTable: (table: string) => void;
    expungeTable: (table: string) => void;
    downloadTable: (table: string) => void;
    showDataTab: (table: string) => void;
    showEditTable: (table: string) => void;
    exportTable: (table: string) => void;
    getExportedTable: (table: string) => void;
    // onExportDownload: () => void;
    // hasPendingExports: boolean;
}

export interface TablesState {
    canEdit: boolean;
}

const TablesTable: FC<TablesTableProps> = ({
    tables,
    // hasPendingExports,
    showDataTab,
    showEditTable,
    downloadTable,
    deleteTable,
    expungeTable,
    exportTable,
    truncateTable,
    // onExportDownload,
}) => {
    const handleDelete = (tableName: string) => {
        const msg = `Delete Table ${tableName}?`;
        if (confirm(msg)) {
            deleteTable(tableName);
        }
    }

    const handleTruncate = (tableName: string) => {
        const msg = `Destroy all records in Table ${tableName}?`;
        if (confirm(msg)) {
            truncateTable(tableName);
        }
    }

    const handleExpunge = (tableName: string) => {
        const msg = `THIS ACTION CANNOT BE REVERSED. This will destroy table ${tableName}. All records and record history in the table and all versions of the table schema will be erased forever. Proceed?`;
        if (confirm(msg)) {
            expungeTable(tableName);
        }
    }

    const canEdit = useHasRole(['dba', 'system']);

    const getColumns = () => {
        const colHelper = createColumnHelper<any>();
        return [
            colHelper.accessor('table', {
                header: 'Table',
                cell: ({ cell, row }) => {
                    return (
                        <a href="#" className="lnkChooseTable" onClick={() => {
                            showDataTab(cell.getValue());
                        }
                        }>{cell.getValue()}</a>
                    )
                },
                sortingFn: (a, b) => {
                    // a and b are ReactTable rows not Table data elements
                    return a.getValue<string>('table').localeCompare(b.getValue<string>('table'));
                }
            }),
            colHelper.accessor('count', {
                header: 'Records',
                cell: ({ cell }) => {
                    const prettyCount = numeral(cell.getValue()).format('0,0');
                    return (<div className="table-number-field">{prettyCount}</div>);
                }
            }),
            colHelper.accessor('description', { header: 'Description' }),
            colHelper.display({
                header: 'Schema',
                cell: ({ row }) => {
                    return (
                        <div className="schema-operation-div" >
                            <Button className="schema-button" icon={IconNames.EDIT} minimal={true}
                                title="Edit Schema"
                                disabled={!canEdit}
                                onClick={() => showEditTable(row.original.table)} />
                            <Button className="schema-button" icon={IconNames.DOWNLOAD} minimal={true}
                                title="Export Schema"
                                onClick={() => downloadTable(row.original.table)} />
                        </div>
                    );
                }
            }),
            colHelper.display({
                header: 'Action',
                cell: ({ row }) => {
                    const tableName = row.original.table;
                    return (
                        <div>
                            <Popover
                                usePortal={true}
                                minimal
                                position="bottom"
                                disabled={!canEdit}
                                content={
                                    <Menu>
                                        <MenuItem onClick={() => handleDelete(tableName)} text="Mark as Deleted" />
                                        <MenuItem className="text-red" onClick={() => handleExpunge(tableName)} text="Drop" />
                                    </Menu>
                                }
                            >
                                <Button
                                    className='delete-button'
                                    icon={IconNames.TRASH}
                                    minimal
                                    title={`Delete ${tableName}`}
                                    disabled={!canEdit}
                                />
                            </Popover>
                            <Button
                                className='export-button'
                                icon={IconNames.ARCHIVE}
                                minimal
                                title={`Export ${tableName}`}
                                disabled={!canEdit}
                                onClick={() => exportTable(tableName)}
                            />
                            <TableExportDownloadButton
                                table={tableName}
                                // enabled={hasPendingExports}
                                // onExportDownload={onExportDownload}
                            />
                        </div>
                    )
                }
            }),
        ];
    };


    const columns = useMemo(() => getColumns(), []);


    return (
        <ReactTable
            resultCount={tables.length}
            columns={columns}
            data={tables}
            defaultSorted={[{ id: "tblname", desc: false }]}
            className="-highlight"
        />
    );
}

export default TablesTable;