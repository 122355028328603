/**
 * @format
 */
import * as React from 'react';
import { Tag, Classes, FormGroup, IDialogProps, Dialog, InputGroup, Intent, MenuItem, HTMLSelect } from '@blueprintjs/core';
/* NOTE@Emma: This will need to be moved to /select once v2 is stable */
import { MultiSelect, IItemRendererProps } from '@blueprintjs/select';

import { Authorities, DEFAULT_AUTHORITY } from '../../constants/authorities';
import { Button } from '../../components/Shared';
import { IconNames } from '@blueprintjs/icons';
import { inSystemGroup } from '../../api';
import { User as UserType } from '../../types';
import { UserPasswordModal } from './UserPasswordModal';

export interface UserEditModalProps {
    isOpen: boolean;
    isItSaveable?: boolean;
    isEdit: boolean;
    systemUsername?: string;
    onInput?: (key: string, value: UserType[keyof UserType]) => void;
    //onNameInput?: (e?:React.SyntheticEvent<HTMLInputElement>) => void;
    //onDescriptionInput?: (e?:React.SyntheticEvent<HTMLInputElement>) => void;
    onClose?: (e?: React.SyntheticEvent<any>) => void;
    onSave?: (isEdit: boolean, e?: React.SyntheticEvent<HTMLButtonElement | MouseEvent>) => void;
    title?: string;
    user: UserType;
    groups: string[];
    isUniqueUsername: boolean;
}

export interface UserEditModalState {
    passwordDialogVisible: boolean;
    password: string;
    confirmPassword: string;
}

export class UserEditModal extends React.Component<
    UserEditModalProps & IDialogProps,
    UserEditModalState
> {
    constructor(props) {
        super(props);
        this.state = {
            passwordDialogVisible: false,
            password: props.user ? props.user.password : '',
            confirmPassword: '',
        };
    }
    private renderGroupItem = (item, {
        handleClick
    }: IItemRendererProps) => {
        return (
            <MenuItem
                onClick={handleClick}
                disabled={item === 'everyone'}
                icon={this.isGroupSelected(item) ? IconNames.TICK : IconNames.BLANK}
                key={item}
                text={item}
                shouldDismissPopover={false}
            />
        );
    }

    private onInput = e => this.props.onInput.call(this, e.target.name, e.target.value);

    private handleTagRemove = (_tag: string, index: number) => {
        if (_tag !== 'everyone') {
            const newGroups = this.props.user.groups.filter(g => g !== _tag);
            this.onInput({ target: { name: 'groups', value: newGroups } });
        }
    }

    private isGroupSelected(g: string) {
        return this.props.user.groups.indexOf(g) !== -1;
    }

    private handleGroupSelect = (g: string) => {
        const newGroups = this.props.user.groups.includes(g)
            ? this.props.user.groups.filter(_g => _g !== g)
            : [...this.props.user.groups, g];

        /* We send through a mock event, groups are mapped as strings in users for some reason */
        this.onInput({ target: { name: 'groups', value: newGroups } });
    }

    private handleAuthoritySelect = (authority: string) => {
        this.onInput({ target: { name: 'authority', value: authority } });
    }

    private handleSetPassword = newPassword => {
        this.props.onInput.call(this, 'password', newPassword);
        this.hidePasswordDialog();
    }
    private hidePasswordDialog = () => {
        this.setState({ passwordDialogVisible: false });
    }
    private showPasswordDialog = () => {
        this.setState({ passwordDialogVisible: true });
    }

    generateRoleInput = () => {
        const GroupEditMultiSelect = MultiSelect.ofType<string>();
        const { groups, user, systemUsername } = this.props;

        return (
            <GroupEditMultiSelect
                items={groups}
                selectedItems={user.groups}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={this.handleGroupSelect}
                itemRenderer={this.renderGroupItem}
                tagRenderer={t => t}
                tagInputProps={{
                    onRemove: this.handleTagRemove,
                    disabled: !inSystemGroup() || user.username === systemUsername,
                }}
                className="management-multiselect"
            />
        )
    }

    public render() {
        const { isOpen, isEdit, onClose, onSave, isItSaveable, title, user, systemUsername } = this.props;

        return (
            <Dialog icon={IconNames.USER} isOpen={isOpen} onClose={onClose} title={title || 'Add User'}>
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="User Name"
                        labelInfo="(required)"
                        labelFor="user-input"
                        helperText={!this.props.isUniqueUsername && "Username must be unique"}
                        intent={this.props.isUniqueUsername ? undefined : Intent.DANGER}
                        inline={true}
                        className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                        contentClassName="user-dialog-input"
                    >
                        <InputGroup
                            disabled={isEdit}
                            id="user-input"
                            intent={this.props.isUniqueUsername ? undefined : Intent.DANGER}
                            onChange={this.onInput}
                            value={user.username}
                            name="username"
                        />
                    </FormGroup>
                    <FormGroup
                        label="Email (required)"
                        labelFor="email-input"
                        inline={true}
                        className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                        contentClassName="user-dialog-input"
                    >
                        <InputGroup
                            autoComplete="new-password"
                            id="email-input"
                            onChange={this.onInput}
                            name="email"
                            type="email"
                            value={user.email}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Full Name"
                        labelFor="fullname-input"
                        inline={true}
                        className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                        contentClassName="user-dialog-input"
                    >
                        <input
                            id="fullname-input"
                            onChange={this.onInput}
                            name="full_name"
                            type="text"
                            value={user.full_name}
                            className={Classes.INPUT}
                        />
                    </FormGroup>
                    <FormGroup
                        label="User Description"
                        labelFor="description-input"
                        inline={true}
                        className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                        contentClassName="user-dialog-input"
                    >
                        <input
                            id="description-input"
                            onChange={this.onInput}
                            name="description"
                            type="text"
                            value={user.description}
                            maxLength={140}
                            className={Classes.INPUT}
                        />
                    </FormGroup>
                    <FormGroup
                        label="User Groups"
                        inline={true}
                        className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                        contentClassName="user-dialog-input group-labels flex-center-vertically"
                    >
                        {this.generateRoleInput()}
                    </FormGroup>

                    {
                        user.username !== systemUsername &&

                        <FormGroup
                            label="Authentication Authority"
                            labelFor="authentication-authority"
                            inline={true}
                            className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                            contentClassName="user-dialog-input"
                        >
                            <HTMLSelect
                                disabled={!inSystemGroup()}
                                options={Authorities.map(auth => ({ label: auth.description, value: auth.key }))}
                                value={user.authority || DEFAULT_AUTHORITY}
                                onChange={e => this.handleAuthoritySelect(e.target.value)}
                            />
                        </FormGroup>
                    }

                    {
                        (user.authority === DEFAULT_AUTHORITY || !user.authority) &&
                        <FormGroup
                            inline={true}
                        >
                            <Button
                                icon={IconNames.EYE_OPEN}
                                value={`${isEdit ? 'Change' : 'Set'} Password`}
                                onClick={this.showPasswordDialog}
                                title="Set password"
                            />
                        </FormGroup>
                    }
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            icon={IconNames.FLOPPY_DISK}
                            className="user-save-button"
                            disabled={!isItSaveable}
                            onClick={() => onSave(isEdit)}
                            value="Save"
                        />
                        <Button icon={IconNames.CROSS} onClick={onClose} intent={Intent.DANGER} value="Cancel" />
                    </div>
                </div>
                <UserPasswordModal
                    isOpen={this.state.passwordDialogVisible}
                    onClose={this.hidePasswordDialog}
                    onSave={this.handleSetPassword}
                />
            </Dialog>
        );
    }
}
