export const systemSettingsActionTypes = {
    SET_BADGE_FETCH_FLAG: 'SET_BADGE_FETCH_FLAG',
    SET_TRACE_FLAG: 'SET_TRACE_FLAG',
};

export interface SystemSettings {
    fetchBadges: boolean;
    tracePosts: boolean;
}

const defaultState = {
    fetchBadges: true,
    tracePosts: false,
};

export const systemSettings = (state = defaultState, action): SystemSettings => {
    switch (action.type) {
        case systemSettingsActionTypes.SET_BADGE_FETCH_FLAG:
            return { ...state, fetchBadges: action.payload.fetchBadges };
        case systemSettingsActionTypes.SET_TRACE_FLAG:
            return { ...state, tracePosts: action.payload.tracePosts };
        default:
            return state;
    }
};
