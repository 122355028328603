
// For Sorting Due Dates, so that the most overdue items (oldest) appear first, and nulls at the bottom when in ASCENDING sort.
export const sortDatesNullsFirst = (a, b) => {
    if (a === null || a === undefined || a === '') {
        return 1;
    }

    if (b === null || b === undefined || b === '') {
        return -1;
    }

    const a1 = new Date(a).getTime();
    const b1 = new Date(b).getTime();

    if (a1 > b1)
        return 1;
    else if (a1 < b1)
        return -1;
    else
        return 0;
};

export const sortTimestamp = (a, b) => Date.parse(a) - Date.parse(b);

export const sortRecordFields = (a, b, columnId) => {
    // this is called for every column of the row. If a and b are objects, assume they are subtables
    // There must be a better way to know if a and b are subtables.
    const aVal = a.getValue(columnId) as any;
    const bVal = b.getValue(columnId) as any;
    if(aVal === undefined || aVal === null) return (bVal === undefined || bVal === null) ? 0 : -1;   // null is smaller than anything
    if(bVal === undefined || bVal === null) return 1;
    // This is sort of a trial and error finding how to sort the subtables.
    // Sometimes they are objects, sometimes they are arrays.
    if(Array.isArray(aVal) && Array.isArray(bVal))
        return aVal.length - bVal.length;
    if(typeof aVal === 'object' && aVal.props?.records && typeof bVal === 'object' && bVal.props?.records)
        return aVal.props?.records?.length - bVal.props?.records?.length;
    // Compare strings via localeCompare and everything else as a value
    return (typeof aVal === 'string') ? aVal.localeCompare(bVal) : aVal - bVal;
};
