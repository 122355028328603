import * as React from 'react';
import { Process } from '../../../types';

export interface ProcessDetailProps {
    process: Process;
    deleteProcess: (processID: number) => void;
    editProcess: (processID: number) => void;
}

export const ProcessDetail: React.FC<ProcessDetailProps> = ({ process, deleteProcess, editProcess }) => {
    const { process_id, description } = process;

    return (
        <div className="management-item process-detail" >
            <div>
                <div className="process-detail-description">{`${process_id}: ${description}`}</div>

                <div className="management-actions users-actions">
                    <a role="button" id="process-edit-btn" onClick={e => editProcess(process_id)}>Edit</a>
                    <span>  |</span>
                    <a role="button" id="process-delete-btn" onClick={e => deleteProcess(process_id)}> Delete</a>
                </div>
            </div>
        </div>
    )
}