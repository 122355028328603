
export class UserStatus {
    private static _isIdle = false;
    private static _logoutMessage = null;

    static isIdle() {
        return UserStatus._isIdle;
    }

    static setIdle(isIdle: boolean) {
        UserStatus._isIdle = isIdle;
    }

    static setLogoutMessage(newMessage: string) {
        UserStatus._logoutMessage = newMessage;
    }

    static getLogoutMessage() {
        return UserStatus._logoutMessage;
    }

    static clearLogoutMessage() {
        UserStatus._logoutMessage = null;
    }
}