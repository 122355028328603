

export const cleanObject = (obj) => {
    const newObj = {...obj};
    for (const propName in newObj) {
        if (newObj[propName] === null || newObj[propName] === undefined || newObj[propName] === "") {
            delete newObj[propName];
        }
    }
    return newObj;
};
