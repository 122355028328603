/**
 * Created by nbamford on 1/29/2017.
 */

// 3/28/2017 update
import * as React from 'react';
import { connect } from 'react-redux';

import { completeMatchTask, discardAssignedTask as discardTask } from '../../../store/workflow/actions';
import { getMatchWorkflow } from '../../../store/workflow/selectors';
import { displayError, WorkflowApi } from 'api';
import WorkflowMatchOverrideForm from './WorkflowMatchOverrideForm';
import { History, MatchWorkflow, Workflow, WorkflowAction } from 'types';

export interface WorkflowMatchOverrideContainerProps {
    dbName: string;
    completeMatchTask: (workflow: Workflow, action: WorkflowAction, matchGroups: string[][], note: string) => any;
    discardTask: (workflow: Workflow, note: string) => void;
    history: any;
    matchWorkflow: MatchWorkflow;
}
export interface WorkflowMatchOverrideContainerState {
    workItemHistory: History[];
}

export class WorkflowMatchOverrideContainerBase extends React.PureComponent<WorkflowMatchOverrideContainerProps, WorkflowMatchOverrideContainerState> {
    constructor(props) {
        super(props);
        this.state = { workItemHistory: [] };
    }

    componentDidMount() {
        WorkflowApi.readWorkitemState(this.props.dbName, this.props.matchWorkflow.workflow.workitem_id)
            .then(history => this.setState({ workItemHistory: history }))
            .catch(error => displayError(
                'Read Task State Failed',
                `Failed to read state for Workitem ${this.props.matchWorkflow.workflow.workitem_id}`,
                error
            ));
    }

    approveTask = (action: WorkflowAction, matchGroups: string[][], note: string) => {
        this.props.completeMatchTask(this.props.matchWorkflow.workflow, action, matchGroups, note);
    }

    discardTask = (note: string) => {
        this.props.discardTask(this.props.matchWorkflow.workflow, note);
    }

    render() {
        const { matchWorkflow } = this.props;
        const { workItemHistory } = this.state;    

        return (
            <WorkflowMatchOverrideForm
                approveTask={this.approveTask} records={matchWorkflow.records as any} acceptedGroups={matchWorkflow.acceptedGroups}
                proposedGroups={matchWorkflow.proposedGroups} schema={matchWorkflow.workflow.schema} history={workItemHistory}
                workflow={matchWorkflow.workflow} discardTask={this.discardTask}
            />
        );
    }
}

export const WorkflowMatchOverrideContainer = connect(
    (state: any) => ({
        dbName: state.database.selected,
        matchWorkflow: getMatchWorkflow(state)
    }),
    { completeMatchTask, discardTask }
)(WorkflowMatchOverrideContainerBase);