import * as React from 'react';
import { connect } from 'react-redux';

import { completeMatchTask, discardAssignedTask as discardTask } from '../../../store/workflow/actions';
import { displayError, WorkflowApi, RecordApi } from '../../../api';
import { getCurrentWorkflow } from '../../../store/workflow/selectors';
import { WorkflowApproveMatchForm } from './WorkflowApproveMatchForm';

import { FilterField, History, MDMRecord, QueryInclude, Workflow, WorkflowAction } from 'types';

export interface WorkflowApproveMatchPropTypes {
    currentWorkflow: Workflow;
    dbName: string;
    completeMatchTask: (workflow: Workflow, action: WorkflowAction, matchGroups: string[][], note: string) => void;
    discardTask: (workflow: Workflow, note: string) => void;
    // rejectTask: (workflow: Workflow, note: string) => void;
    history: any;
}
interface WorkflowApproveMatchState {
    matchIds: string[][];
    matchRecords: object;
    queryRecords: MDMRecord[];
    workitemHistory: History[];
}
export class WorkflowApproveMatchBase extends React.Component<WorkflowApproveMatchPropTypes, WorkflowApproveMatchState> {

    options: QueryInclude;

    constructor(props) {
        super(props);
        this.state = {
            queryRecords: [],
            matchRecords: {},
            workitemHistory: [],
            matchIds: []
        };
        // what records we are going to see
        this.options = {
            match: true,
            conflict: true,
            version: false,
        };
    }

    componentDidMount() {
        const workflow = this.props.currentWorkflow;
        // I guess we need to load match records here?
        // not sure why these live in the workflow vs the workitem but it does
        const matchStr = workflow.variables.match_keys;
        const matchKeys = JSON.parse(matchStr);
        let groupId = 0;
        matchKeys.forEach( group => {
            ++groupId;
            group.forEach(id => {
                this.addMatchRecord(id, groupId).then( (record:any) => {
                    console.log("Got record(s) " + JSON.stringify(record));
                });
            })
        });
        WorkflowApi.readWorkitemState(this.props.dbName, workflow.workitem_id)
            .then(history => this.setState({ workitemHistory: history }))
            .catch(error => displayError(
                'Read Task State Failed',
                `Failed to read state for Workitem ${workflow.workitem_id}`,
                error
            ));
    }

    displayRecordsError = displayError('Get Records Failed', 'Failed to find(query) records');

    queryRecords = async (filterFields: FilterField[]) => {
        const workflow = this.props.currentWorkflow;
        const records = RecordApi.queryRecords({
            changeset: workflow.changesetId,
            database: workflow.databaseName,
            errorHandler: this.displayRecordsError,
            filter: { fields: filterFields },
            include: this.options,
            table: workflow.tableName
        });
        return await records;
    }

    completeTask = (matchGroups: string[][], note: string) => {
        this.props.completeMatchTask(this.props.currentWorkflow, WorkflowAction.submit, matchGroups, note);
    }

    discardTask = (note: string) => {
        this.props.discardTask(this.props.currentWorkflow, note);
    }

    rejectTask = (note: string) => {
        this.props.completeMatchTask(this.props.currentWorkflow, WorkflowAction.reject, null, note);
    }

    displayMatchRecordsError = displayError('Get Match Records Failed', 'Failed to find(query) records');
    /**
     * Add a record as well as all records in its group to the collection of selected records.
     * Remember we use a map (by PK) instead of an array so that we can't get duplicates.
     * @param record
     */
    addMatchRecord = async (id:string, groupId: number) => {
        const workflow = this.props.currentWorkflow;
        const primaryKey = workflow.schema.primary_key;

        let records = await this.queryRecords([ { field:primaryKey, operation:'=', values:[id] } ]);
        if (!records || records.length != 1)
            return null;
        const record = records[0];
        record['_id'] = id;
        record['_group'] = groupId.toString();
        this.setState({ matchRecords: { ...this.state.matchRecords, [record[primaryKey]]: record } });
        return record;
    }

    render() {
        const workflow = this.props.currentWorkflow;
        return (
            <WorkflowApproveMatchForm
                history={this.state.workitemHistory}
                matchRecords={this.state.matchRecords}
                schema={workflow.schema}
                workflow={workflow}
                completeTask={this.completeTask}
                discardTask={this.discardTask}
                rejectTask={this.rejectTask}
            />
        );
    }
}
export const WorkflowApproveMatchContainer = connect(
    (state: any) => ({ currentWorkflow: getCurrentWorkflow(state), dbName: state.database.selected }),
    { completeMatchTask, discardTask }
)(WorkflowApproveMatchBase);
