import * as React from 'react';
import { connect } from 'react-redux';

import { showToaster } from '../../../actions';
import { fetchAvailableWorkflows } from 'store/workflow/actions';
import { MDMWrapper, Button, ConfirmDialog } from '../../../components/Shared';
import { WorkflowApi, GroupApi, TableApi } from '../../../api';
import { WorkflowDetail } from './WorkflowDetail';
import { Process, WorkflowDef, Intent, Changeset, Table } from '../../../types';
import { WorkflowEditModal } from './WorkflowEditModal'
import './WorkflowEditor.scss';

export interface WorkflowEditorProps {
    dbName: string;
    showToaster: showToaster;
    changeset: any;
}

export interface WorkflowEditorState {
    groups: string[];
    processes: Process[];
    workflows: WorkflowDef[];
    currentDeleteWorkflow: WorkflowDef;
    currentNewWorkflow: WorkflowDef;
    currentWorkflow: WorkflowDef;
    isEdit: boolean,
    tables: Table[],
}

export class WorkflowEditorBase extends React.Component<WorkflowEditorProps, WorkflowEditorState> {

    constructor(props) {
        super(props);

        this.state = {
            workflows: [],
            processes: [],
            groups: [],
            currentDeleteWorkflow: null,
            currentNewWorkflow: null,
            currentWorkflow: null,
            isEdit: false,
            tables: []
        }
    }

    refreshData = () => {
        const { dbName, changeset } = this.props;
        TableApi.queryTables(dbName, changeset.changeset)
            .then(tables => this.setState({ tables: tables }));

        WorkflowApi.queryProcesses(dbName).then(processes => {
            WorkflowApi.queryWorkflows(dbName).then(workflows => {
                let sortedWorkflows = workflows.sort((a, b) => a.workflow_id - b.workflow_id)
                this.setState({ workflows: sortedWorkflows, processes })
            });
        });
    }

    componentDidMount() {
        GroupApi.listAllGroups()
            .then((groups: any[]) => {
                const allGroups = groups ? groups.map(group => group.group) : [];
                this.setState({ groups: allGroups });
            })
        this.refreshData();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.dbName !== this.props.dbName)
            this.setState({ workflows: null, processes: null }, () => {
                this.refreshData();
            })
    }

    deleteWorkflow = (workflowId: number) => {
        const { dbName } = this.props;
        const { workflows, currentDeleteWorkflow } = this.state;
        WorkflowApi.deleteWorkflow(dbName, workflowId)
            .then(res => {
                const filteredWorkflows = workflows.filter(workflow => workflow.workflow_id !== workflowId);
                this.props.showToaster(Intent.INFO, `Workflow ${currentDeleteWorkflow.workflow_id}:${currentDeleteWorkflow.description} deleted.`)
                this.setState({ workflows: filteredWorkflows, currentWorkflow: null, currentDeleteWorkflow: null });
            })
            .catch(err => {
                console.error(err);
                this.props.showToaster(Intent.DANGER, `Error deleting workflow ${currentDeleteWorkflow.workflow_id}:${currentDeleteWorkflow.description}.`)
            })
    }

    deleteWorkflowConfirm = () => {
        const { currentDeleteWorkflow } = this.state;
        return currentDeleteWorkflow &&
            <ConfirmDialog
                buttonName="Delete"
                body={`Are you sure you want to delete workflow: ${currentDeleteWorkflow.description}?`}
                title={`Delete Workflow ${currentDeleteWorkflow.description}`}
                cancelClick={() => this.setState({ currentDeleteWorkflow: null })}
                okClick={() => this.deleteWorkflow(currentDeleteWorkflow.workflow_id)}
            />;
    }

    generateWorkflowItem = (workflow: WorkflowDef) => {
        const { groups, processes, tables } = this.state;
        const process = processes.find(process => process.process_id === workflow.process_id);

        if (process) {
            return <WorkflowDetail
                tables={tables}
                key={workflow.workflow_id + "-wf-detail"}
                groups={groups}
                workflow={workflow}
                process={process}
                processes={processes}
                deleteWorkflow={() => { this.setState({ currentDeleteWorkflow: { ...workflow } }) }}
                editWorkflow={() => { this.setState({ isEdit: true, currentWorkflow: { ...workflow } }) }}
            />
        }

        return null;
    }

    editWorkflow = (newWorkflow: WorkflowDef) => {
        WorkflowApi.updateWorkflow(newWorkflow.database, newWorkflow.process_id, newWorkflow.workflow_id, newWorkflow.description, newWorkflow.completion_hours, newWorkflow.variables, newWorkflow.ui_visible)
            .then(() => {
                const sortedWorkflows = this.state.workflows.map(workflow => workflow.workflow_id === newWorkflow.workflow_id ? newWorkflow : workflow);
                this.setState({ workflows: sortedWorkflows, currentWorkflow: null });
                this.props.showToaster(Intent.INFO, `Workflow ${newWorkflow.workflow_id}:${newWorkflow.description} updated.`);
            })
            .catch(err => {
                console.error(err);
                this.props.showToaster(Intent.DANGER, `Error updating workflow ${newWorkflow.workflow_id}:${newWorkflow.description}.`);
            })
    }

    createWorkflow = (newWorkflow: WorkflowDef) => {
        WorkflowApi.createWorkflow(newWorkflow.database, newWorkflow.process_id, newWorkflow.description, newWorkflow.completion_hours, newWorkflow.variables, newWorkflow.ui_visible)
            .then((res: any) => {
                const newWorkflowId = res.workflow_id
                newWorkflow.workflow_id = newWorkflowId;
                this.setState({ workflows: [...this.state.workflows, newWorkflow], currentWorkflow: null })
                this.props.showToaster(Intent.INFO, `Workflow ${newWorkflowId}:${newWorkflow.description} saved.`)
            })
            .catch(err => {
                console.error(err);
                this.props.showToaster(Intent.DANGER, `Error saving workflow ${newWorkflow.description}.`)
            })
    }

    generateModal = () => {
        const { dbName } = this.props;
        const { groups, processes, currentWorkflow, isEdit, tables } = this.state;

        if (!currentWorkflow)
            return null;

        const saveAction = isEdit ? this.editWorkflow : this.createWorkflow

        const currentProcess = processes.find(process => process.process_id === currentWorkflow.process_id);

        return (
            <WorkflowEditModal
                tables={tables}
                processes={processes}
                dbName={dbName}
                groups={groups}
                isOpen={!!currentWorkflow}
                workflow={currentWorkflow}
                process={currentProcess}
                closeModal={() => this.setState({ currentWorkflow: null })}
                onSave={saveAction}
                onInputChange={(key, value) => {
                    const editedWorkflow = { ...currentWorkflow, [key]: value };

                    // remove all claim group variables when the process_id changes, since we need to reassign them
                    if (key === 'process_id') {
                        let noClaimGroupVars = { ...editedWorkflow.variables };
                        Object.keys(noClaimGroupVars).map(key => {
                            if (key.endsWith("$claim_groups"))
                                delete noClaimGroupVars[key]
                        })
                        editedWorkflow.variables = noClaimGroupVars;
                    }

                    this.setState({ currentWorkflow: editedWorkflow });
                }}
            />
        )

    }

    generateEmptyWf = (database: string, processId: number): WorkflowDef => {

        return {
            database: database,
            process_id: processId,
            ui_visible: true,
            workflow_id: 0,
            description: "New Workflow",
            completion_hours: 168,
            variables: {
                start_groups: "",
                table: ""
            }
        }
    }

    helpClick = () => {
        window.open('/mdm-help/advanced_workflow.html?q=workflow+editor');
    }

    render() {
        const { workflows, processes } = this.state;
        const { dbName } = this.props;

        const noWorkflows = !workflows

        const createButton = (
            <Button
                key='create-button'
                className="minimal-button"
                id="btnCreateWorkflow"
                value="Create Workflow"
                disabled={noWorkflows}
                onClick={() => {
                    if (!processes.length) {
                        this.props.showToaster(Intent.DANGER, `Please create a process before creating a Workflow.`)
                        return null;
                    } else {
                        const emptyWorkflow = this.generateEmptyWf(dbName, processes[0].process_id);
                        this.setState({ isEdit: false, currentWorkflow: emptyWorkflow })
                    }
                }}
            />)

        return <>
            {this.deleteWorkflowConfirm()}
            <MDMWrapper
                buttons={[createButton]}
                title={`Workflows for ${dbName}`}
                documentationPath='advanced_workflow.html?q=workflow+editor'>
                {this.generateModal()}
                <div className="flex-column workflow-details-container">
                    {!noWorkflows && workflows.map(workflow => this.generateWorkflowItem(workflow))}
                </div>
            </MDMWrapper>
        </>
    }
}

export const WorkflowEditor = connect(
    (state: any) => ({
        available: state.workflow.available,
        dbName: state.database.selected,
        username: state.username,
        changeset: state.changeset.changeset
    }), { fetchAvailableWorkflows, showToaster })(WorkflowEditorBase as any);
