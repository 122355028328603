/**
 * Ajax endpoints for view subscriptions
 */

export class TableUrl {
    static create:string   = '/ajax/table/create';
    static modify:string   = '/ajax/table/modify';
    static delete:string   = '/ajax/table/delete';
    static list:string     = '/ajax/table/list';
    static query:string    = '/ajax/table/query';
    static queryVersions:string    = '/ajax/table/query_versions';
    static truncate:string = '/ajax/table/truncate';
    static expunge:string  = '/ajax/table/expunge';
    static export:string = '/ajax/table/export';
    static export_get:string = '/ajax/table/export_get';
    static export_query:string = '/ajax/table/export_query';
}