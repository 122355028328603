/**
 * Created by nbamford on 1/29/2017.
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';

import { completeTask, saveTask, discardAssignedTask as discardTask } from '../../../store/workflow/actions';
import { displayError, WorkflowApi } from 'api';
import { getReviewWorkflow } from '../../../store/workflow/selectors';
import { WorkflowRecordForm } from './WorkflowRecordForm';

import { History, ReviewWorkflow, Workflow } from 'types';

export interface WorkflowRecordProps {
    isEdit: boolean;
    completeTask: (workitem: Workflow, history: any, button:HTMLButtonElement, recordId: string, data: object, note: string) => void;
    discardTask: (workflow: Workflow, button:HTMLButtonElement, note: string) => void;
    history: any;
    match: match;
    saveTask: (workitem: Workflow, button:HTMLButtonElement, recordId: string, data: object) => void;
    workflow: ReviewWorkflow;
}
export interface WorkflowRecordState {
    workitemHistory: History[];
}
export class WorkflowRecordContainerBase extends React.PureComponent<WorkflowRecordProps, WorkflowRecordState> {

    constructor(props) {
        super(props);
        this.state = { workitemHistory: [] };
    }

    componentDidMount() {
        WorkflowApi.readWorkitemState(this.props.workflow.workflow.databaseName, this.props.workflow.workflow.workitem_id)
            .then(history => this.setState({ workitemHistory: history }))
            .catch(error => displayError(
                'Read Task State Failed',
                `Failed to read state for Workitem ${this.props.workflow.workflow.workitem_id}`,
                error
            ));
    }

    private completeTask = (button, record, note) => {
        // Don't set primary key on edit record
        // const isEdit = this.props.isEdit || (this.props.match.params as any).t === 'edit';
        const isEdit = this.props.isEdit;
        const recordId = isEdit ? undefined : record[this.props.workflow.workflow.schema.primary_key];
        const data = { record: record };
        if (note)
            data['$notes'] = note;
        this.props.completeTask(this.props.workflow.workflow, this.props.history, button, recordId, data, note);
    }
    private saveTask = (button, record, note: string) => {
        // Don't set primary key on edit record
        const isEdit = this.props.isEdit;
        const recordId = isEdit ? undefined : record[this.props.workflow.workflow.schema.primary_key];
        const data = { record: record };
        if (note)
            data['$notes'] = note;
        this.props.saveTask(this.props.workflow.workflow, button, recordId, data);
    }

    private discardTask = (button:HTMLButtonElement, note: string) => {
        this.props.discardTask(this.props.workflow.workflow, button, note);
    }
    render() {
        const workflow = this.props.workflow;
        return (
            <div>
                <WorkflowRecordForm workflow={workflow.workflow} record={workflow.record} schema={workflow.workflow.schema} history={this.state.workitemHistory}
                    completeTask={this.completeTask} discardTask={this.discardTask} saveTask={this.saveTask} />
            </div>
        );
    }
}
//asNotes={true}notes={this.props.workitemHistory} getButtons={this.getButtons}
export const WorkflowRecordContainer = connect(
    (state: any) => ({ workflow: getReviewWorkflow(state) }),
    { completeTask, discardTask, saveTask }
)(WorkflowRecordContainerBase as any);

