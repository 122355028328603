import { SET_AUTH_CONFIG, SET_CAN_EDIT_WORKITEMS, AuthConfig } from './types';

import { AppThunk } from '../MDMStore';

export const setCanEditWorkitems = (canEdit: boolean): AppThunk => {
    //console.log("in action to set");
    return dispatch => {
        dispatch({ type: SET_CAN_EDIT_WORKITEMS, payload: { canEdit } });
    };
};

export const setAuthConfig = (config: AuthConfig): AppThunk => {
    return dispatch => {
        dispatch({ type: SET_AUTH_CONFIG, payload: { config } });
    };
};
