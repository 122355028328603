/**
 * @format
 */
 import * as React from 'react';
 import { connect } from 'react-redux';

 import { Button, Icon, Position, Tooltip, Intent } from '@blueprintjs/core';
 import { EventItem } from 'rp-mdm-common';
 import { LogApi } from '../../api/LogApi';
 import { MDMWrapper } from 'components/Shared';

 import './LogDisplay.scss';

 export interface LogDisplayProps {
     history: any;
     permissions: string[];
 }
 export interface LogDisplayState {
     items: EventItem[];
 }

 class LogDisplayBase extends React.Component<LogDisplayProps, LogDisplayState> {
     constructor(props) {
         super(props);
         this.state = {
             items: []
         }
     }

     refresh() {
         LogApi.queryEvent({/*"severity_max":"fatal"*/}, {start: 0, count: 100}).then( records => {
             //console.log(JSON.stringify(payload, null, 4));
             this.setState({items: records || [] });
         });
     }

     skipBlankValues(key, value) {
         if(!value) return undefined;
         return value;
     }

     public render() {
         const { history } = this.props;
         let itemString = "";
         this.state.items.forEach( (item, index) =>
             itemString = itemString + (index + 1) + ': ' + JSON.stringify(item, this.skipBlankValues)+ "\r\n");
         const refreshButton = [<Button key={1} onClick={e => this.refresh()} >Refresh</Button>];
         return (
             <MDMWrapper title="Event Log" buttons={refreshButton} documentationPath="index.html" >
                 <pre>
                     { itemString }
                 </pre>
             </MDMWrapper>
         );
     }
 }

 export const LogDisplay = connect(
     (state: any) => ({
         items: []
     })
 )(LogDisplayBase);
