import * as React from 'react';
import { connect } from 'react-redux';


import { displayMessageBox, showToaster } from 'actions';
import { clearTaskResult } from 'store/workflow/actions';
import { getCurrentWorkflow } from 'store/workflow/selectors';
import { UserSettings } from 'store/user/types';
import { setCanEditWorkitems } from 'store/auth/actions';
import { fetchAvailableWorkflows, fetchAssignedWorkflows, continueTask, setAssignedWorkflows, setAvailableWorkflows, setContinueTask } from 'store/workflow/actions';
import { PermApi, WorkflowApi } from '../../api';
import { setPeriodicData } from '../../actions'
import { Intent, Workflow } from 'types';

export interface WorkitemRedirectHandlerProps {
    available: Workflow[];
    assigned: Workflow[];
    currentWorkflow: Workflow;
    dbName: string;
    history: any;
    settings: UserSettings;
    username: string;
    showToaster?: showToaster;
    lastUpdated: number | null;
    displayMessageBox: (options) => void;
    continueTask: (database: string, workitemId: number, nodeId: string) => void;
    clearTaskResult: () => void;
    fetchAvailableWorkflows: (dbName: string, username: string) => void;
    fetchAssignedWorkflows: (dbName: string, username: string) => void;
    setCanEditWorkitems: (canEdit: boolean) => void;

    setPeriodicData?: setPeriodicData;
    setAssignedWorkflows: (workflows: Workflow[]) => void;
    setAvailableWorkflows: (workflows: Workflow[]) => void;
    setContinueTask: (taskResult: any) => void;
}

export interface WorkitemRedirectHandlerState {
    canEditWorkitems: boolean;
}

export class WorkitemRedirectHandlerBase extends React.PureComponent<WorkitemRedirectHandlerProps, WorkitemRedirectHandlerState> {

    constructor(props) {
        super(props);
        this.state = {
            canEditWorkitems: false
        }
    }

    setModifyWorkitemPermission = async () => {
        const { dbName, setCanEditWorkitems } = this.props;
        try {
            if (dbName) {
                const permissionResponse = await PermApi.Validate({ type: "database", entity: dbName, permission: "modify_workflow" });
                setCanEditWorkitems(!!permissionResponse?.allow)
            } else {
                setCanEditWorkitems(false)
            }
        } catch (err) {
            console.error(err);
        }
    }

    componentDidMount = () => {
        this.setModifyWorkitemPermission()
    }


    continueNext = async (assignedWorkflows: Workflow[]) => {
        const { dbName, setContinueTask, showToaster } = this.props;
        for (const assigned of assignedWorkflows) {
            try {
                const taskResult = await WorkflowApi.continueTask(dbName, assigned.workitem_id, assigned.node_id);
                showToaster(Intent.INFO, `Next assigned task (${assigned.workitem_id}) started automatically.`);
                return setContinueTask(taskResult);
            } catch (err) {
                console.error(`Workitem ${assigned.workitem_id} was not claimable, attempting to claim next`);
                console.error(err);
            }
        }
    }

    refreshWorkflowsAndNavigate = async (shouldClaimNext: boolean, currentWorkflowBecameEmpty: boolean) => {
        const { dbName, username, setAssignedWorkflows, setAvailableWorkflows, setPeriodicData, showToaster, history } = this.props;

        const assignedWorkflows = await WorkflowApi.getAssignedWorkflows(dbName, username);
        const availableWorkflows = await WorkflowApi.getAvailableWorkflows(dbName, username);

        setAssignedWorkflows(assignedWorkflows);
        setAvailableWorkflows(availableWorkflows);
        setPeriodicData(assignedWorkflows.length, availableWorkflows.length);

        if (shouldClaimNext) {
            if (assignedWorkflows.length) {
                return this.continueNext(assignedWorkflows);
            } else {
                showToaster(Intent.SUCCESS, "You've cleared your Assigned Workitems, congratulations!");
                history.push('/wf/available');
            }
        } else if (currentWorkflowBecameEmpty) {
            if (assignedWorkflows.length) {
                history.push('/wf/assigned');
            } else {
                history.push('/wf/available');
            }
        }
    }

    async componentDidUpdate(prevProps: WorkitemRedirectHandlerProps) {
        const { history, currentWorkflow, settings, lastUpdated, dbName } = this.props;

        const currentWorkflowBecameEmpty = prevProps.currentWorkflow.workitem_id != currentWorkflow.workitem_id
            && currentWorkflow.workitem_id === null && prevProps.currentWorkflow.workitem_id !== null;

        const { claimNextAssigned } = settings;
        const shouldClaimNext = currentWorkflow.continue === false && claimNextAssigned && currentWorkflowBecameEmpty;

        if (lastUpdated !== prevProps.lastUpdated) {
            await this.refreshWorkflowsAndNavigate(shouldClaimNext, currentWorkflowBecameEmpty)
        }

        if (currentWorkflow !== prevProps.currentWorkflow && currentWorkflow.formId) {
            switch (currentWorkflow.formId) {
                case 'CreateRecord':
                case 'EditRecord':
                case 'FindRecord':
                case 'ReviewRecord':
                case 'OverrideMatchProposed':
                case 'OverrideMatchNew': // manual match
                case 'SetMatchGroups': // manual match with approval
                case 'ApproveMatch': // approve a manual match
                case 'Note':
                case 'NoteReview':
                    history.push('/wf/workitemTask');
                    break;
                default:
                    this.props.displayMessageBox({
                        title: 'Unknown Form Id',
                        message: `Invalid/Unknown form ID ${currentWorkflow.formId}`,
                    });
            }
        }

        // update per-database permissions when Database changes
        if (prevProps.dbName !== dbName) {
            this.setModifyWorkitemPermission();
        }
    }

    render() {
        return null;
    }
}
export const WorkitemRedirectHandler: any = connect(
    (state: any) => ({
        currentWorkflow: getCurrentWorkflow(state),
        lastUpdated: state.workflow.lastUpdated,
        username: state.username,
        dbName: state.database.selected,
        settings: state.user.settings,
        available: state.workflow.available,
        assigned: state.workflow.assigned,
    }), {
    continueTask,
    setContinueTask,
    clearTaskResult,
    displayMessageBox,
    fetchAvailableWorkflows,
    fetchAssignedWorkflows,
    showToaster,
    setPeriodicData,
    setAssignedWorkflows,
    setAvailableWorkflows,
    setCanEditWorkitems
})(WorkitemRedirectHandlerBase as any);