import * as React from 'react';
import { Classes, Dialog } from '@blueprintjs/core';

import { EditRecordForm } from 'components/EditRecordForm/EditRecordForm';
import { Button } from '../Shared';
import { Schema } from 'types';
import { IconNames } from '@blueprintjs/icons';

export interface DialogEditRecordProps {
    schema: Schema;
    tableName: string;
    saveRecord: (record: object) => void;
}

export interface DialogEditRecordState {
    record: object;
    modifiedRecord: object;
    open: boolean;
}

export class DialogEditRecord extends React.Component<DialogEditRecordProps, DialogEditRecordState> {

    constructor(props) {
        super(props);
        this.state = {
            record: {},
            modifiedRecord: {},
            open: false,
        };
    }

    private handleChange = (field: string, value: any) =>
        this.setState({ modifiedRecord: { ...this.state.modifiedRecord, [field]: value } })

    public open = (data) => this.setState({
        open: true, modifiedRecord: data ? { ...data } : {}, record: data
    })
    public close = () => this.setState({ open: false });

    private saveRecord = () => {
        this.props.saveRecord(this.state.modifiedRecord);
        this.close();
    }

    public render() {
        //const saveable = this.props.schema && (this.state.modifiedRecord[this.props.schema.primary_key] != null);
        const saveable = this.props.schema;
        const edit = !!this.state.record;
        return (
            <Dialog style={{ width: "800px", maxWidth: "90%" }} icon={edit ? IconNames.EDIT : IconNames.ADD} isOpen={this.state.open} title={`${edit ? 'Edit' : 'Add'} Record`}>
                <div className={Classes.DIALOG_BODY}>
                    <EditRecordForm tableName={this.props.tableName} modifiedRecord={this.state.modifiedRecord} schema={this.props.schema} record={this.state.record}
                        updateFieldCb={this.handleChange} />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            icon={IconNames.FLOPPY_DISK}
                            className="user-save-button"
                            disabled={!saveable}
                            onClick={() => this.saveRecord()}
                            value="Save"
                        />
                        <Button icon={IconNames.CROSS} onClick={this.close} intent={'danger'} value="Cancel" />
                    </div>
                </div>
            </Dialog>
        );
    }
}


