import { PURGE } from 'redux-persist'; 
import { DISPLAY_MESSAGEBOX } from '../constants';

export const messageboxoptions = (state = {display: false, options: {}}, action) => {
    switch (action.type) {
        case DISPLAY_MESSAGEBOX:
            return {display: action.display, options: action.options};

        case PURGE:
            return { display: false, options: {} };

        default:
            return state;
    }
};