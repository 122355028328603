/**
 * @format
 */
import * as React from 'react';

import './OverviewStat.scss';

export interface OverviewStatProps {
    amount: number | string;
    label1: string;
    label2: string;
}


export const OverviewStat: React.FC<OverviewStatProps> = ({ amount, label1, label2 }) => {
    const parseAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return (<div className='overview-stat'>
        <div className='stat-amount'>{parseAmount}</div>
        <div className='stat-labels'>
            <div className='stat-label'>{label1}</div>
            <div className='stat-label'>{label2}</div>
        </div>
    </div>);
}