/** Copyright (C) 2019 RedPoint Global. All Rights Reserved
 * 
 * When sending information to the back end do not encode null, undefined or unnecessary size and signed flags
 * for data types that do not have sizes or signed/unsigned values.
 * 
 */
import { DataType, DataTypes } from 'types';

export function encodeDataType(datatype:DataType):DataType {
    let hasSize = false;
    let hasSigned = false;
    // datatype.type.hasSize() and .hasSign() instead?
    switch(datatype.type) {
        // boolean, date, datetime, money, time, table have no size or sign

        case DataTypes.integer:
            hasSize = hasSigned = true;
            break;
        case DataTypes.binary:
        case DataTypes.float:
        case DataTypes.text:
            hasSize = true;
            break;
        default: // just for lint
            break;
    }
    let result:DataType =  { type: datatype.type };
    !hasSize || (result = { ...result, ...{ size: Number(datatype.size) }});
    !hasSigned || (result = { ...result, ...{ signed: Boolean(datatype.signed) }});

    return result;
}
