import { createSelector } from "reselect";

import { groupsSelector } from '../user/selectors';

const permissionsSelector = state => state.permissions.permissions;

export const getPermissionsByLoggedUserGroups = createSelector(
    [permissionsSelector, groupsSelector],
    (permissions, groups) => {
        const groupPermissions = permissions.reduce((acc, current) => {

            // check system admin
            if (groups.includes(current.group)) {
                return [...new Set([...acc, ...current.perms])];
            }
            return acc;
        }, []);
        return groupPermissions;
    }
);
