import * as React from 'react';
import { Icon, InputGroup, IOptionProps } from '@blueprintjs/core';
import { DateRangeInput } from "@blueprintjs/datetime";
import { IconNames } from '@blueprintjs/icons';
import { CustomSelect } from '../../Shared';

export interface QueryRowProps {
    dropdownFilters: IOptionProps[]
    dropdownValue: string
    value: any
    workflowDropdownoptions: any[]

    onRemove: (filterToRemove: any) => void
    onValueChange: (name: string, value: any) => void
    onDropdownChange: (prevValue: string, nextValue: string) => void
    executeQuery: () => void
}

export interface QueryRowState {
}

export enum EndStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    ABORTED = 'aborted'
}

export class QueryRow extends React.Component<QueryRowProps, QueryRowState> {

    constructor(props) {
        super(props);
        this.state = {
            filterValue: null,
            workflows: [],
            hasFetchedWorkflows: false
        }
    }

    filterDeleted = () => {
        const { dropdownValue } = this.props;
        this.props.onRemove(dropdownValue);
    }

    generateInputField = () => {
        const { dropdownValue, value, onValueChange } = this.props;

        if (dropdownValue === 'assigned' || dropdownValue === 'finished') {
            return (
                <CustomSelect
                    onChange={selectedOption => onValueChange(dropdownValue, selectedOption.value === 'true')}
                    name="filter"
                    value={value.toString()}
                    options={[
                        { value: 'true', label: "True" },
                        { value: 'false', label: "False" },
                    ]}
                    fill
                />
            )
        } else if (dropdownValue === 'start_timestamp' || dropdownValue === 'end_timestamp' || dropdownValue === 'due_date') {
            const currentStart = value.start ? new Date(value.start) : null
            const currentEnd = value.end ? new Date(value.end) : null
            return (
                <DateRangeInput
                    formatDate={date => date.toLocaleString()}
                    onChange={dates => {
                        const dateObj = {
                            start: dates[0] ? new Date(dates[0]).toISOString() : null,
                            end: dates[1] ? new Date(dates[1]).toISOString() : null,
                        }
                        onValueChange(dropdownValue, dateObj)
                    }}
                    parseDate={str => new Date(str)}
                    value={[currentStart, currentEnd]}
                />
            )
        } else if (dropdownValue === 'end_status') {
            return (
                <CustomSelect
                    onChange={selectedOption => onValueChange(dropdownValue, selectedOption.value)}
                    name="filter"
                    value={value.toString()}
                    options={[
                        { value: EndStatus.SUCCESS, label: "Success" },
                        { value: EndStatus.ERROR, label: "Error" },
                        { value: EndStatus.ABORTED, label: "Aborted" },
                    ]}
                    fill
                />
            )
        } else if (dropdownValue === 'workflow_description') {
            return (
                <CustomSelect
                    className="workflows-dropdown"
                    onChange={selectedOption => onValueChange(dropdownValue, selectedOption.value)}
                    name="filter"
                    value={value.toString()}
                    options={this.props.workflowDropdownoptions}
                    fill
                />
            )
        }

        return (
            <InputGroup
                id={`values`}
                type="text"
                onChange={e => onValueChange(dropdownValue, e.target.value)}
                value={value}
                name="values"
                onKeyPress={(e) => e.key === 'Enter' && this.props.executeQuery()}
            />
        );
    }

    render() {
        const { dropdownValue, onDropdownChange } = this.props;

        const filterDropdown = (
            <CustomSelect
                placeholder="Choose a filter..."
                onChange={selectedOption => onDropdownChange(dropdownValue, selectedOption.value.toString())}
                name="filter"
                value={dropdownValue}
                options={this.props.dropdownFilters}
                fill
            />
        );

        const inputField = this.generateInputField();

        const btnDeleteRow = (
            <div className="fill flex-center">
                <Icon
                    style={{ cursor: 'pointer' }}
                    icon={IconNames.CROSS}
                    onClick={this.filterDeleted}
                />
            </div>
        );
        return (
            <tr key={'idx'} className="filter-row">
                <td className="operation-td">{filterDropdown}</td>
                <td className="input-td">{inputField}</td>
                <td className="delete-row">{btnDeleteRow}</td>
            </tr>
        );
    }
}