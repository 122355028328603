import { useDispatch, useSelector } from 'react-redux';
import React, { FC } from 'react';
import { approveTask, rejectTask, discardAssignedTask as discardTask } from '../../../store/workflow/actions';
import { displayError, WorkflowApi } from 'api';
import { getReviewWorkflow } from '../../../store/workflow/selectors';
import { WorkflowReviewRecordForm } from './WorkflowReviewRecordForm';
import { useHistory } from 'react-router-dom';

const WorkflowReviewRecordContainer: FC = () => {
    const history = useHistory();
    const [workitemHistory, setWorkitemHistory] = React.useState<any[]>([]);
    const dispatch = useDispatch();
    const workflow = useSelector(getReviewWorkflow);

    React.useEffect(() => {
        WorkflowApi.readWorkitemState(workflow.workflow.databaseName, workflow.workflow.workitem_id)
            .then((history) => setWorkitemHistory(history))
            .catch((error) =>
                displayError(
                    'Read Task State Failed',
                    `Failed to read state for Workitem ${workflow.workflow.workitem_id}`,
                    error
                )
            );
    }, [workflow.workflow.databaseName, workflow.workflow.workitem_id]);

    const handleApproveTask = (button:HTMLButtonElement, record: object, note?: string) => {
        const workflowData = workflow.workflow;
        const recordId: string = record[workflowData.schema.primary_key];
        const data = { record: record };
        if (note) data['$notes'] = note;
        dispatch(approveTask(workflowData, button, recordId, data, history));
    };

    const handleRejectTask = (button:HTMLButtonElement, record: object, note?: string) => {
        const workflowData = workflow.workflow;
        const data = { record: record };
        if (note) data['$notes'] = note;
        dispatch(rejectTask(workflowData, button, data));
    };

    const handleDiscardTask = (button:HTMLButtonElement, note: string) => {
        const data = {};
        if (note) data['$notes'] = note;
        const workflowData = workflow.workflow;
        dispatch(discardTask(workflowData, button, note));
    };

    const workflowState = useSelector(getReviewWorkflow);

    return (
        <div>
            <WorkflowReviewRecordForm
                workflow={workflowState.workflow}
                oldRecord={workflowState.oldRecord}
                newRecord={workflowState.record}
                schema={workflowState.workflow.schema}
                history={workitemHistory}
                approveTask={handleApproveTask}
                discardTask={handleDiscardTask}
                rejectTask={handleRejectTask}
            />
        </div>
    );
};

export default WorkflowReviewRecordContainer;