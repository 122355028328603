
import {
    RECEIVE_COMPUTED_VIEW_VALIDATION,
    RECEIVE_COMPUTED_VIEWS,
    CLEAR_COMPUTED_VIEWS,
} from './types';

import { ViewApi } from '../../api/ViewApi';
import { displayMessageBox } from "actions/ActionCreators";
import { mdmErrorToText } from "helpers";
import { showToaster } from 'actions/ActionCreators';
import { Intent } from 'types';

// Thunk Action creators
export const createView = (database: string, view: object) => {
    return (dispatch, getState) => {
        if (!database)
            dispatch(showToaster(Intent.DANGER, `No database specified for create computed view`));
        else {
            ViewApi.createComputedView(database, view).then(
                result => {
                    dispatch(showToaster(Intent.SUCCESS, `Computed view ${result} created`));
                    dispatch(getAllViews(database));
                },
                error => dispatch(displayMessageBox({
                    title: "Create Failed",
                    message: `Failed to create computed view: ${mdmErrorToText(error)}`,
                    stack: error && error.stack,
                }))
            );
        }
    };
};

export const deleteView = (database: string, viewID: number) => {
    return (dispatch, getState) => {
        if (!database)
            dispatch(showToaster(Intent.DANGER, `No database specified for delete computed view`));
        else {
            ViewApi.deleteComputedView(database, viewID).then(
                result => {
                    dispatch(showToaster(Intent.SUCCESS, `Computed view ${viewID} deleted`));
                    dispatch(getAllViews(database));
                },
                error => dispatch(displayMessageBox({
                    title: "Delete Failed",
                    message: `Failed to delete computed view ${viewID}: ${mdmErrorToText(error)}`,
                    stack: error && error.stack,
                }))
            );
        }
    };
};

export const getAllViews = (database: string) => {
    return (dispatch, getState) => {
        if (!database)
            dispatch(showToaster(Intent.DANGER, `No database specified for get all computed views`));
        else {
            ViewApi.getAllComputedViews(database).then(
                result =>
                    dispatch({ type: RECEIVE_COMPUTED_VIEWS, payload: { computedViews: result } }),
                error => {
                    dispatch({ type: CLEAR_COMPUTED_VIEWS });

                    dispatch(displayMessageBox({
                        title: "Get Views Failed",
                        message: `Failed to get computed views: ${mdmErrorToText(error)}`,
                        stack: error && error.stack,
                    }));
                }


            );
        }
    };
};

export const validateView = (database: string, view: object) => {
    return (dispatch, getState) => {
        if (!database)
            dispatch(showToaster(Intent.DANGER, `No database specified for validate computed view`));
        else {
            ViewApi.validateComputedView(database, view).then(
                result =>
                    dispatch({ type: RECEIVE_COMPUTED_VIEW_VALIDATION, payload: { validation: result } }),
                error => dispatch(displayMessageBox({
                    title: "Validate Failed",
                    message: `Failed to validate computed view: ${mdmErrorToText(error)}`,
                    stack: error && error.stack,
                }))
            );
        }
    };
};

export const clearViewValidationErrors = () => {
    return dispatch => dispatch({ type: RECEIVE_COMPUTED_VIEW_VALIDATION, payload: { validation: {} } });
};

export const modifyView = (database: string, view: object) => {
    return (dispatch, getState) => {
        if (!database)
            dispatch(showToaster(Intent.DANGER, `No database specified for modify computed view`));
        else
            ViewApi.modifyComputedView(database, view).then(
                result =>
                    dispatch(showToaster(Intent.SUCCESS, `Computed view modified`)),
                error => dispatch(displayMessageBox({
                    title: "Modify Failed",
                    message: `Failed to modify computed view: ${mdmErrorToText(error)}`,
                    stack: error && error.stack,
                }))
            );
    };
};
