/**
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Callout, HTMLSelect, HTMLTable, Icon, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as moment from 'moment';

import { getSchema, TableApi } from 'api';
import { RecordApi } from '../../api/RecordApi';
import { Button } from 'components/Shared';
import { displayMessageBox } from 'actions';
import { MDMWrapper } from 'components/Shared';
import { mdmErrorToText } from "helpers";
import { hasPIIFields } from 'utils';
import { Schema, Table } from 'types';

import './VerifyErasure.scss';

export interface VerifyErasureProps {
    dbName: string;
    displayMessageBox: any;
    changeset: any;
}

export type PurgeOrMask = 'purge' | 'mask';

interface ErasedRecord {
    action: string;
    timestamp: string;
    username: string;
    table: string;
}

export interface VerifyErasureState {
    erasedRecords: ErasedRecord[];
    noRecordsFound: boolean;
    fields: { field: string; value: string }[];
    piiFields: any[];
    schema: Schema;
    selectedTable: string;
    tables: Table[];
    hasEnteredValues: boolean;
    selectedMaskHashIndex: number;
}

export class VerifyErasureBase extends React.Component<VerifyErasureProps, VerifyErasureState> {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            erasedRecords: [],
            noRecordsFound: false,
            piiFields: [],
            schema: null,
            selectedTable: '',
            selectedMaskHashIndex: -1,
            hasEnteredValues: false,
            tables: []
        };
    }

    public componentDidMount() {
        const { dbName, changeset } = this.props;
        TableApi.queryTables(dbName, changeset.changeset).then(tables => this.setState({ tables: tables, selectedTable: '' }));
    }

    public componentDidUpdate(prevProps: VerifyErasureProps) {
        const { dbName, changeset } = this.props;
        if (
            dbName !== prevProps.dbName ||
            changeset.changeset !== prevProps.changeset.changeset
        ) {
            TableApi.queryTables(dbName, changeset.changeset).then(tables => {
                this.setState({ tables: tables, selectedTable: '', selectedMaskHashIndex: -1, erasedRecords: [], noRecordsFound: false })
            });
        }
    }

    private verifyPurgedOrMasked = () => {
        const { dbName } = this.props;
        const { selectedTable, fields } = this.state;

        RecordApi.verifyPurgedOrMasked(dbName, selectedTable, fields)
            .then((records: any) => {
                this.setState({ erasedRecords: records, noRecordsFound: !records.length });
            })
            .catch(err => {
                const error = err.error || err;
                this.props.displayMessageBox({
                    title: 'Verification Failed',
                    message: `Failed to retrieve verification status.: ${mdmErrorToText(error)}`,
                    stack: error && error.stack
                });
            });
    }

    private clearResults = () => this.setState({ erasedRecords: [], noRecordsFound: false });

    generateMessages = () => {
        const { erasedRecords, noRecordsFound } = this.state;
        if (erasedRecords.length) {
            return erasedRecords.map((erasedRecord, index) => (
                <div className="verification-message yes-message" key={`erased-message-${index}`}>
                    <div className="verification-icon">
                        <Icon icon={IconNames.THUMBS_UP} />
                    </div>
                    <div>
                        A matching record was <strong>{erasedRecord.action}</strong> on{' '}
                        <strong>{moment(erasedRecord.timestamp).format('MMMM Do, YYYY')}</strong> by
                    user <strong>{erasedRecord.username}.</strong>
                    </div>
                </div>
            ))
        } else if (noRecordsFound) {
            return (
                <div className="verification-message no-message">
                    <div className="verification-icon">
                        <Icon icon={IconNames.THUMBS_DOWN} />
                    </div>
                    <div>No record was found.</div>
                </div>
            )
        }
    }

    private onTableChange = newTable => {
        const { dbName, changeset } = this.props;
        if (newTable !== '') {
            getSchema(dbName, changeset.changeset, newTable)
                .then(schema => this.setState({ selectedTable: newTable, schema: schema, selectedMaskHashIndex: -1 },
                    () => this.clearResults()
                ));
        }
    }

    private onMaskChange = (newMaskIndex) => {
        const { schema } = this.state;
        if (newMaskIndex > -1) {
            const maskHashFields = schema.mask_hashes[newMaskIndex].fields;

            if (maskHashFields)
                this.setState({
                    selectedMaskHashIndex: newMaskIndex,
                    fields: maskHashFields.map(f => ({ field: f.field, value: '' }))
                });

            this.clearResults();
        }
    }

    private setFieldValue = (value, index) => {
        const { fields } = this.state;
        let newFields = [...fields];
        newFields[index].value = value;
        this.setState({ fields: newFields });
    }

    generateFieldsTable = () => {
        const { selectedMaskHashIndex, schema } = this.state;
        if (selectedMaskHashIndex === -1)
            return null;

        return (
            <HTMLTable id="erasure-fields-table" className="opacity-transition" striped={true} >
                <thead>
                    <tr>
                        <td className="text-small text-heavy">Field</td>
                        <td className="text-small text-heavy">Value</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        schema.mask_hashes[selectedMaskHashIndex].fields.map((fl, idx) => {
                            return (
                                <tr className="verify-field" key={idx}>
                                    <td>
                                        <div className="fill-height flex-center-vertically field-label">
                                            <span>{fl.field}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            onChange={e => this.setFieldValue(e.target.value, idx)}
                                            className={Classes.INPUT}
                                            type="text"
                                            placeholder="Field Value"
                                            value={this.state.fields[idx].value}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </HTMLTable>
        )
    }

    public render() {
        const { schema, selectedMaskHashIndex, fields } = this.state;
        const hasFilledField = !!fields.find(field => field.value !== '')
        const tables = this.state.tables || [];

        return (
            <MDMWrapper title="Verify Erasure" documentationPath="verify_erasure.html">
                <p style={{ margin: '1rem' }}>
                    Select a hash mask and fill in PII fields in order to see if the record was
                    masked or purged.
                </p>
                <Callout style={{ margin: '1rem' }}>
                    <div className="target-margin flex-center-vertically">
                        <div className="target-width">Target Table</div>
                        <HTMLSelect
                            id="target-table-dropdown"
                            onChange={e => this.onTableChange(e.target.value)}
                            value={this.state.selectedTable}
                            options={[{ value: '', label: "Please select a table" }].concat(
                                tables
                                    .filter(t => hasPIIFields(t.schema))
                                    .map(t => ({ label: t.table, value: t.table }))
                            )}
                        />
                    </div>

                    <div className="target-margin flex-center-vertically">
                        <div className="target-width">Target Mask Hash</div>
                        <HTMLSelect
                            onChange={e => this.onMaskChange(e.target.value)}
                            value={this.state.selectedMaskHashIndex}
                            options={[{ value: -1, label: "Please select a Mask Hash" }].concat(
                                (this.state.schema && this.state.schema.mask_hashes) ?
                                    this.state.schema.mask_hashes.map((maskHash, idx) => ({ value: idx, label: maskHash.description })) : []
                            )}
                        />
                    </div>
                </Callout>
                <div className="verify-form">
                    {this.generateFieldsTable()}
                    <div style={{ margin: '1rem' }}>
                        <Button
                            className="minimal-button"
                            disabled={!hasFilledField}
                            onClick={this.verifyPurgedOrMasked}
                            value="Verify"
                        />
                    </div>
                </div>
                {this.generateMessages()}
            </MDMWrapper>
        );
    }
}

export const VerifyErasure = connect(
    (state: any) => ({
        dbName: state.database.selected,
        changeset: state.changeset,
        changesets: state.changesets,
    }),
    {
        displayMessageBox,
    },
)(VerifyErasureBase);
