import { ITreeNode } from "@blueprintjs/core";
import { v4 as uuidv4 } from 'uuid';
import { IPermNode } from './IPermNode';


export class PermDatabaseChildNode implements IPermNode {
    id:string;
    name:string;
    type:string;
    isOpen: false;
    canBeSelected: boolean;
    isLeaf: boolean;
    openIcon: string;
    closedIcon: string;
    children: IPermNode[];
    parent: IPermNode;

    constructor(name:string, icon:string, parent:IPermNode) {
        this.id = uuidv4();
        this.name = name;
        this.type = 'none';
        this.isOpen = false;
        this.canBeSelected = false;
        this.isLeaf = false;
        this.openIcon = icon;
        this.closedIcon = 'none'; // unused
        this.children = [];
        this.parent = parent;
    }

    async getChildrenFn() {
        alert('this needs to be overidden');
        return await false;
    }

    fullNameFn() {
        return this.parent.fullNameFn();
    }

    myName() {
        return this.parent.myName();
    }

    header() {
        return "Node Should not be selectable";
    }

    getBPNode() {
        const childrenBP = this.children.map( child => child.getBPNode() );
        return {
            id: this.id,
            isExpanded: this.isOpen,
            hasCaret: true,
            label: this.name,
            iconName: this.openIcon,
            childNodes: childrenBP
        } as ITreeNode;
    }
}
