import { RecordUrl } from '../proxies/ajax/RecordUrl';
import { axiosPost } from '../helpers/axiosUtils';
import { ErrorHandler, MDMRecord, QueryFilter, QueryInclude } from 'types';
import { Pagination } from 'rp-mdm-common';
import { useMutation } from '@tanstack/react-query';

interface BaseRecordOptions {
    databaseName: string;
    changesetId: any;
    tableName: string;
}

export interface DeleteRecordOptions extends BaseRecordOptions {
    record_keys: string[];
}

export interface DeleteRecordOptions extends BaseRecordOptions {
    records: any[];
}

export interface RecordHistoryOptions extends BaseRecordOptions {
    record_key: string;
    filter?: any;
}

export interface RecordQueryParams {
    errorHandler: ErrorHandler;
    database: string;
    changeset: number | string;
    table: string;
    primary_key?: string;
    include?: QueryInclude;
    filter?: QueryFilter;
    pagination?: Pagination;
}

export interface RecordsResult {
    start: number;
    count: number;
    records: MDMRecord[];
}

export class RecordApi {

    static deleteRecord(options) {
        const keys = options.primary ? [options.primary] : [];

        const body = {
            payload: {
                database: options.databaseName,
                changeset: options.changesetId,
                table: options.tableName,
                record_keys: keys
            }
        };

        return axiosPost(RecordUrl.delete, body).then(() => true);
    }

    static upsertRecord(options) {
        const records = options.item ? [options.item] : [];

        const body = {
            payload: {
                database: options.databaseName,
                changeset: options.changesetId,
                table: options.tableName,
                records
            }
        };

        return axiosPost(RecordUrl.upsert, body).then(() => true);
    }

    
    static upsertRecords(options) {
        const body = {
            payload: {
                database: options.databaseName,
                changeset: options.changesetId,
                table: options.tableName,
                records: options.records
            }
        };

        return axiosPost(RecordUrl.upsert, body).then(() => true);
    }

    static getRecordHistory(options: RecordHistoryOptions) {
        // server requires changeset id is a number or null to mean latest mainline version
        const changeset = (options.changesetId === 'current') ? null : options.changesetId;

        const body = {
            payload: {
                changeset: changeset,
                database: options.databaseName,
                table: options.tableName,
                record_key: options.record_key,
                filter: options.filter
            }
        };

        return axiosPost(RecordUrl.versions, body);
    }

    static maskPIIFields(databaseName: string, tableName: string, keys: any[]) {
        const body = {
            payload: {
                database: databaseName,
                table: tableName,
                keys
            }
        };

        return axiosPost(RecordUrl.mask, body);
    }

    static purgeRecords(databaseName: string, tableName: string, keys: any[]) {
        const body = {
            payload: {
                database: databaseName,
                table: tableName,
                keys
            }
        };

        return axiosPost(RecordUrl.purge, body).then(() => true);
    }

    static verifyPurgedOrMasked(database: string, table: string, fields: { field: string, value: string }[]) {
        const body = { payload: { database, table, fields } };

        return axiosPost(RecordUrl.verifyPurge, body, 'records');
    }

    static queryRecords = (params: RecordQueryParams, pagination?: Pagination, showError = true): Promise<MDMRecord[]> => {
        const body = {
            payload: {
                database: params.database,
                changeset: params.changeset === 'current' ? undefined : params.changeset,
                table: params.table,
                primary_key: params.primary_key,
                include: params.include,
                filter: params.filter
            }
        };

        if (params.filter && params.filter.masked)
            body.payload.include.mask = params.filter && params.filter.masked;

        return axiosPost(RecordUrl.query, body, null, pagination, showError).then(payload => {
            if (!payload || !payload.records)
                return [];

            const records = payload.records.map((record) => {
                Object.keys(record).forEach((k) => {
                    const v = record[k];
                    record[k] = ((v === null) ? '' : v);
                });
                return record;
            });
            return records;
        });
    }

    static queryPrevious = (database, changeset, table, primaryKey): Promise<MDMRecord[]> => {
        const body = {
            payload: {
                database,
                changeset,
                table,
                record_key: primaryKey
            }
        };

        return axiosPost(RecordUrl.previous, body).then(payload => {
            return payload.records;
        });
    }
}


export const useUpsertRecords = (options?: any) => {
    return useMutation(async (payload: {
        databaseName: string;
        changesetId: any;
        tableName: string;
        records: any[];
    }) => {
        return RecordApi.upsertRecords(payload);
    }, options);
}