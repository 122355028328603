import * as React from 'react';
import { connect } from 'react-redux';
import { ButtonGroup, Callout, Intent, Tabs, Tab } from '@blueprintjs/core';
import { TableApi } from '../../api/TableApi';
import { TableEditWarnDialog } from './TableEditWarnDialog';
import { isMDMName } from '../../utils';
import { hasPIIFields, normalizeId, encodeDataType } from 'utils';
import { MDMWrapper, MDMFormGroup, Button, ConfirmDialog } from 'components/Shared';
import { TableEditHashTab } from './TableEditHashTab';
import { TableEditMainTab } from './TableEditMainTab';
import { TableEditSubTab } from './TableEditSubTab';
import { TableEditFns } from './TableEditFns';
import { toast } from "react-toastify";

import { DataTypes, Field, NewField, MaskHash, Schema, NewSchema, SchemaType, Table } from 'types';

export interface TableEditContainerProps {
    match: any;
    dbName: string;
    history: any;
}

export interface TableEditContainerState {
    name: string;
    description: string;
    tabKey: string;
    title: string;
    isEdit: boolean;
    originalSeq: string;
    originalFields: any;
    originalMasks: MaskHash[];
    schema: NewSchema;
    warnDialogState: {
        msg: string;
        show: boolean;
        title: string;
    }
    tables: Table[];
    undo: any;
    isEditingHash: boolean;
    pendingSchema: any;
    editTable: string;
}

export class TableEditContainer extends React.Component<TableEditContainerProps, TableEditContainerState> {
    constructor(props) {
        super(props);

        const editTable = props.match?.params?.tableName

        this.state = {
            editTable,
            name: editTable || "",
            description: '',
            schema: { ...this.blankSchema() },
            warnDialogState: {
                msg: '',
                show: false,
                title: '',
            },
            tabKey: 'main',
            title: '',
            isEdit: false,
            tables: [],
            // We need to keep track of the original state of affairs when editing so as to guide the user
            // as well as prevent the user from doing things that aren't possible when editing

            //TODO: I'm forcing in PII here, but ideally the schema edit table should conform to the schema
            // type and not simply rely on positioning in the record.
            originalSeq: '',
            originalFields: undefined,
            // You can't delete existing mask_hashes so we need to know which ones are original
            originalMasks: [],
            undo: null,
            isEditingHash: false,
            pendingSchema: null,
        };
    }
    showTableEditWarnDialog = (title: string, msg: string) => this.setState({ warnDialogState: { show: true, title: title, msg: msg } });

    closeTableEditWarnDialog = () => this.setState({ warnDialogState: { show: false, title: '', msg: '' } });

    cancelTableEditWarnDialog = () => {
        this.undoDelete();
        this.closeTableEditWarnDialog();
    }

    componentDidUpdate = (prevProps) => {
        const { dbName } = this.props;
        if (prevProps.dbName !== dbName) {
            this.props.history.push('/tables')
        }
    }

    async componentDidMount() {
        const { editTable } = this.state;
        const { dbName } = this.props

        TableApi.queryTables(dbName, 'current')
            .then(tables => {
                const table = tables.find(t => t.table === editTable)
                this.setState({
                    title: table ? `Edit Table ${table.table}` : 'Create Table',
                    isEdit: !!table,
                    description: table ? table.description : '',
                    schema: table ? table.schema : { ...this.blankSchema() },
                    tables: tables,
                    // We need to keep track of the original state of affairs when editing so as to guide the user
                    // as well as prevent the user from doing things that aren't possible when editing

                    // only non-null if we are editing AND

                    //TODO: I'm forcing in PII here, but ideally the schema edit table should conform to the schema
                    // type and not simply rely on positioning in the record.
                    originalSeq: table ? table.schema.sequence_field : '',
                    originalFields: table ? table.schema.fields.reduce((xs, x) => {
                        const fieldMap = (x.schema && x.schema.fields) ?
                            x.schema.fields.reduce((fs, f) => {
                                fs[f.field] = f;
                                return fs;
                            }, {}) : {};
                        const newField = { ...x, schema: { ...x.schema, fields: fieldMap }, pii: x.pii || false };
                        xs[newField.field] = newField;
                        return xs;
                    }, {}) : undefined,
                    // You can't delete existing mask_hashes so we need to know which ones are original
                    originalMasks: table ? table.schema.mask_hashes : [],
                });
            });
    }

    blankSchema(): Schema {
        return {
            'primary_key': 'id',
            'sequence_field': '',
            'type': SchemaType.versioned,
            'fields': [
                // debug?
                {
                    field: 'id', pii: false, nullable: false, index: true, display: true,
                    datatype: { type: DataTypes.integer, size: 8, signed: true },
                },
            ],
            'matching': {
                'enabled': false,
                'display': false
            }
        };
    }

    updateDescription = (value) => {
        this.setState({ description: value });
        return { ok: true, error: '' };
    }
    /**
     * This is only used by create. We need to check the table against all other existing table names
     *
     * @param value
     */
    validateTableName = (value) => {
        let ok: boolean = !!isMDMName(value);
        let message = '';
        if (ok) {
            const testValue = normalizeId(value);
            if (this.state.tables.find((t) => {
                return (t && testValue === normalizeId(t.table));
            })) {
                ok = false;
                message = 'Table already exists';
            }
        }
        else {
            message = 'Name must use letters, numbers and underscores only';
        }
        // Do we always want to do this?  I think its fine
        this.setState({ name: value, title: 'Create Table ' + value });

        return { ok: ok, error: message };
    }

    validatePrimaryKey = (id) => {
        const schema = this.state.schema;
        // Look it up in the fields
        const index = schema.fields.findIndex((f) => f && f.field === id);
        // JWG - I think we should do some actually validation here of type/pii state
        if (index >= 0) {
            let newSchema = { ...schema, primary_key: id };
            // sequence can't be the primary key
            if (schema.sequence_field === id) {
                newSchema = { ...newSchema, sequence_field: '' };
            }
            // the primary key field may not be nullable or pii so make it so
            // NOTE: what if there's already a mask using this field?
            const fields = schema.fields;
            newSchema = {
                ...newSchema, fields: fields.map((f, i) =>
                    i === index ? { ...f, nullable: false, pii: false } : f)
            };
            this.setState({ schema: newSchema });
        }
        return { ok: index >= 0, error: 'Invalid Primary Key?' }; // should always be true
    }
    validateMatching = (key: string, enable: boolean) => {
        const matching = { ...this.state.schema.matching, enabled: enable };
        this.setState({ schema: { ...this.state.schema, matching } });
        return { ok: true, error: '' };
    }
    validateDisplay = (key: string, display: boolean) => {
        const matching = { ...this.state.schema.matching, display: display };
        this.setState({ schema: { ...this.state.schema, matching } });
        return { ok: true, error: '' };
    }
    validateSequenceField = (id) => {
        const schema = this.state.schema;

        // Sequence field may be '' (nothing).  Special case to deal with this
        if (id === '0') {
            const newSchema = { ...schema, sequence_field: '' };
            this.setState({ schema: newSchema });
            return { ok: true, error: '' };
        }
        // Look it up in the fields and that field has a reasonable type
        const index = this.state.schema.fields.findIndex((f) => f &&
            f.field === id && f.datatype.type !== DataTypes.table);
        if (index >= 0 && id !== this.state.schema.primary_key) {
            // unclear if you can update 2 things at once
            const fields = schema.fields;
            let newSchema = { ...schema, sequence_field: id };
            newSchema = {
                ...newSchema, fields: fields.map((f, i) =>
                    i === index ? { ...f, nullable: false, pii: false } : f)
            };
            this.setState({ schema: newSchema });
            return { ok: true, error: '' };
        }
        // this should never happen
        return { ok: false, error: 'Invalid Sequence Value?' };
    }

    validateMasking(): boolean {
        return hasPIIFields(this.state.schema) ?
            this.state.schema.mask_hashes && this.state.schema.mask_hashes.length ?
                true : false
            : true;
    }

    tabSelect = (key) => {
        this.setState({ tabKey: key });
    }

    typeChanged = newType => {
        let newSchema;
        if (newType === SchemaType.timeseries) {
            const timeStampField = { field: 'timestamp', nullable: false, pii: false, index: true, display: true, datatype: { type: DataTypes.datetime } };
            const timestampFieldIndex = this.state.schema.fields.findIndex(field => field.field === 'timestamp');

            // TBD: What to do with table fields? Guess: Complain they are invalid but don't clear them?
            const fields = this.state.schema.fields;
            let filteredFields = timestampFieldIndex === -1 ?
                [...fields, timeStampField] :
                fields.map((f, i) => i === timestampFieldIndex ? timeStampField : f)

            // Remove nested tables since the are not allowed in timeseries tables.
            filteredFields = filteredFields.filter(field => field.datatype.type !== "table");

            newSchema = { ...this.state.schema, type: newType, fields: filteredFields, primary_key: null, sequence_field: '' };
            this.setState({ pendingSchema: newSchema });
        } else {
            newSchema = { ...this.state.schema, type: newType };
            this.setState({ schema: newSchema });
        }
    }

    updateFields = (newFields, newPrimaryKey = null) => {
        const { schema } = this.state;
        const newSchema: Schema = { ...schema, fields: newFields };
        newSchema.primary_key = newPrimaryKey !== null ? newPrimaryKey : schema.primary_key

        if (schema.sequence_field) {
            const seq = schema.sequence_field;
            if (newFields.reduce((sum, f) => { return sum || (f.field === seq); }, false) === false) {
                newSchema.sequence_field = '';
            }
        }

        this.setState({ schema: newSchema, undo: schema.fields });
    }

    // TODO - review this behavior.  Consider an undo/redo stack?
    undoDelete = () => {
        const newSchema = { ...this.state.schema, fields: this.state.undo };
        this.setState({ schema: newSchema, undo: undefined });
    }

    validateFields = (schema: Schema, originalFields: Field[]) => {
        // this bit is the most complex
        // 1. All field names must be MDM identifiers
        // 1a. All field datatypes must be consistent for type/size/sign
        // 2. pk and seq fields may not be nullable
        // 3. pk field may not be record or table
        // 4. seq field must be int/datetime/float
        // 5. linked to fields must be to an existing table
        // 6. subschema fields must follow the same field rules
        let ok = true;
        let error = '';
        const duplicates = {};
        const fields = schema.fields;
        let invalid;
        const isTimeseries = (this.state.schema.type === SchemaType.timeseries);
        fields.forEach((f) => {
            // Check subtable, if necessary
            if (f.schema && originalFields) {
                const originalSubFields = originalFields[f.field] && originalFields[f.field].schema && originalFields[f.field].schema.fields;
                const retVal = this.validateFields(f.schema, originalFields[f.field] && originalSubFields);
                ok = retVal.ok;
                error = retVal.error;
            }

            if (ok) {
                if (duplicates[f.field]) {
                    ok = false;
                    error = f.field + ' is not unique.';
                }
                duplicates[f.field] = true;

                const type = f.datatype.type;
                if (f.field === schema.primary_key) {
                    if (f.nullable) {
                        ok = false;
                        error = f.field + ' is the primary key and may not be nullable.';
                    }
                    if (type !== DataTypes.integer && type !== DataTypes.text) {
                        ok = false;
                        error = f.field + ' is the primary key and can only be integer or text.';
                    }
                    if (f.pii) {
                        ok = false;
                        error = f.field + ' is the primary key and may not be marked as PII.';
                    }
                }

                if (f.field === schema.sequence_field) {
                    if (f.nullable) {
                        ok = false;
                        error = f.field + ' is the sequence field and may not be nullable.';
                    }
                    if (f.pii) {
                        ok = false;
                        error = f.field + ' is the sequence field and may not be marked as PII.';
                    }
                    if (![DataTypes.integer, DataTypes.datetime, DataTypes.float].includes(type)) {
                        ok = false;
                        error = f.field + ' is the sequence field and must be of type integer, float or datetime.';
                    }
                }
                const original = originalFields && originalFields[f.field];
                if ([DataTypes.table].includes(type)) {
                    if (isTimeseries) {
                        ok = false;
                        error = 'Timeseries tables do not support subtables. ' + f.field + ' is declared as a sub-table';
                    }
                    else {
                        invalid = TableEditFns.nestedTableValidate(f, original);

                        if (invalid) {
                            ok = false;
                            error = invalid.error;
                        }
                    }
                }
                else {
                    invalid = TableEditFns.fieldValidate(f, null, original, !!originalFields);
                    if (invalid) {
                        ok = false;
                        error = invalid.error;
                    }
                }
            }
        });
        return { ok: ok, error: error };
    }

    validateTable = (): string[] => {
        const { name, schema, tables, isEditingHash, originalFields, editTable } = this.state;

        const normalizedName = normalizeId(name);
        const fields = schema.fields;

        let errors: string[] = [];

        // Check if table has a name with a valid structure.
        if (!isMDMName(name)) {
            const invalidNameMessage = name ? `"${name}" is not a valid name. Only use letters, numbers and underscores.` : 'You must name your table.'
            errors.push(invalidNameMessage);
        }

        // Check if no other table has the same name if we are NOT editing an existing table.
        const tableNameExists = tables.find((table) => (table && normalizedName === normalizeId(table.table)))
        if (!!tableNameExists && !editTable) {
            errors.push(`The table ${name} already exists. Choose a different name.`);
        }

        // Validations for Versioned Tables
        if (schema.type === SchemaType.versioned) {
            const hasPrimaryKey = schema.primary_key && fields.find((f) => f && f.field === schema.primary_key)

            // Validate if it has a primary key
            // Validate if sequence field is not the same as primary key and has the right type
            if (!hasPrimaryKey) {
                errors.push('Primary Key is not set')

            } else if (schema.sequence_field === schema.primary_key) {
                errors.push('Sequence Field cannot be the same as the Primary Key.')

            } else if (schema.sequence_field && schema.sequence_field.length) {
                const sequenceIndex = schema.fields.findIndex((f) => {
                    return f && f.field === schema.sequence_field;
                });
                if (sequenceIndex < 0) {
                    errors.push('Sequence Field is not an existing field.');
                }
                else if (!['integer', 'float', 'datetime'].includes(fields[sequenceIndex].datatype.type)) {
                    errors.push(`Sequence Field ${schema.sequence_field} must be Integer, Float or Datetime.`)
                }
            }
        }

        if (!this.validateMasking()) {
            errors.push('You have enabled PII. Click the Masking Hashes tab to make hashes that will uniquely identify masked/purged records.');
        }

        if (isEditingHash) {
            errors.push('You are in the middle of creating a hash, Click "Done" or "Cancel" to be able to submit.');
        }

        const fieldValidation = this.validateFields(schema, originalFields);
        if (fieldValidation && fieldValidation.error)
            errors.push(fieldValidation.error);

        return errors
    }

    private setHashes = (hashes: MaskHash[]) => {
        this.setState({
            schema: {
                ...this.state.schema,
                mask_hashes: hashes
            }
        });
    }

    private getPIIInfo = () => {
        const piiUse = {};
        if (this.state.schema && this.state.schema.mask_hashes) {
            this.state.schema.mask_hashes.forEach(hash => {
                hash.fields.forEach(f => {
                    piiUse[f.field] = true;
                    if (f.field.indexOf('.') >= 0) {
                        piiUse[f.field.substring(0, f.field.indexOf('.'))] = true;
                    }
                });
            });
        }
        return piiUse;
    }

    submitCreateTable = () => {
        const tableName = this.state.name;
        const schema = { ...this.state.schema };
        schema.fields = schema.fields.filter((f) => typeof f === 'object')
            .map((f) => {
                // coerce everything to the expected type
                f.field = String(f.field);
                f.nullable = Boolean(f.nullable);
                f.pii = Boolean(f.pii);
                f.index = Boolean(f.index);
                f.display = Boolean(f.display);
                f.values = f.values || [];
                // Only transmit
                f.datatype = encodeDataType(f.datatype);
                if (!f.links_to) delete f.links_to;
                if (!f.link_display_field) delete f.link_display_field;
                if (f.schema) {
                    f.schema.fields = f.schema.fields.filter((f) => typeof f === 'object')
                        .map((f) => {
                            f.field = String(f.field);
                            f.nullable = Boolean(f.nullable);
                            f.index = Boolean(f.index);
                            f.display = Boolean(f.display);
                            f.datatype = encodeDataType(f.datatype);
                            if (!f.links_to) delete f.links_to;
                            if (!f.link_display_field) delete f.link_display_field;
                            return f;
                        });
                }
                return f;
            });
        if (!schema.primary_key) delete schema.primary_key; // server doesn't like blank/null/empty/empty-string sequence
        if (!schema.sequence_field) delete schema.sequence_field; // server doesn't like blank/null/empty/empty-string sequence
        if (schema.matching && typeof schema.matching.enabled === 'undefined') delete schema.matching;
        const description = this.state.description;
        if (this.state.isEdit)
            TableApi.modifyTable(this.props.dbName, description, tableName, schema)
                .then(changesetID => {
                    if (changesetID) {
                        toast.success(`Modified table ${this.props.dbName}.${tableName}`);
                        this.props.history.push('/tables');
                    }
                });
        else
            TableApi.createTable(this.props.dbName, description, tableName, schema)
                .then(changesetID => {
                    if (changesetID) {
                        toast.success(`Created table ${this.props.dbName}.${tableName}`);
                        this.props.history.push('/tables');
                    }
                });
    }

    setIsEditingHash = (newEditState: boolean) => {
        this.setState({ isEditingHash: newEditState });
    }

    render() {
        const { pendingSchema, isEdit, schema, description, tabKey, title } = this.state
        const fields = schema.fields;
        const submitText = isEdit ? 'Update Table' : 'Create Table'

        const tableName = (!isEdit) ?
            <MDMFormGroup id='create-table-name'
                label='Name'
                maxLength={100}
                //placeholder='E.g. "_My_Users" or "IÃ°avÃ¶llr"' -- unicode not supported yet
                placeholder='E.g. "_My_Users"'
                value={this.state.name}
                validate={this.validateTableName}
            /> : '';

        const validationErrors = this.validateTable();
        const isValid = validationErrors.length === 0;

        const alert = isValid ? '' :
            <Callout
                icon={null}
                intent={Intent.WARNING}
            >
                {validationErrors.map((err, i) => <p key={`validation-err-${i}`}>{err}</p>)}
            </Callout>;

        const undo = (this.state.undo) ? <Callout icon={null} intent={Intent.PRIMARY} >
            <p><Button onClick={this.undoDelete} >Undo the last field update</Button></p>
        </Callout> : '';

        const typeValues = [{ id: 'versioned', value: 'versioned' }, { id: 'timeseries', value: 'timeseries' }];
        const pkValues = fields
            .filter((f) => f && ['integer', 'text'].includes(f.datatype.type) && f.pii === false && !f.links_to)
            .map((f) => { return { id: f.field, value: f.field }; });

        // Limit to valid sequence types. sequence may not be the primary key.
        // When editing a table the sequenceValues cannot include nullable fields

        const sequenceValues = fields
            .filter((f) => {
                if (isEdit) {
                    return (f &&
                        ['integer', 'datetime', 'float'].includes(f.datatype.type) &&
                        f.field !== schema.primary_key &&
                        !f.nullable
                    );
                }
                return (f && ['integer', 'datetime', 'float'].includes(f.datatype.type) && f.field !== schema.primary_key);
            })
            .map((f) => { return { id: f.field, value: f.field }; });

        const subFields = [];
        fields.forEach((f, index) => {
            if (f && f.datatype.type === 'table') {
                subFields.push(index);
            }
        });

        // The sequence field is fixed if its set and this is edit
        const versionedSchema = this.state.schema.type === SchemaType.versioned;
        const hasPII = hasPIIFields(this.state.schema);
        const piiUse = this.getPIIInfo();
        // main tab and one for each record/table

        const tabs = [
            <Tab key='main' id='main' title='Main Table' panel={
                <TableEditMainTab originalFields={this.state.originalFields} schema={this.state.schema} update={this.updateFields}
                    showTableEditWarnDialog={this.showTableEditWarnDialog} tables={this.state.tables} piiUse={piiUse} />
            } />
        ];
        subFields.forEach((index) => {
            const field = this.state.schema.fields[index];
            tabs.push(
                <Tab key={index} id={index} title={field.field} panel={
                    <TableEditSubTab originalFields={this.state.originalFields} schema={this.state.schema} update={this.updateFields} tables={this.state.tables} piiUse={piiUse} index={index} />
                } />);
        });
        if (versionedSchema) {
            tabs.push(<Tab key='masktab' id='masktab' title='Masking Hashes' disabled={!hasPII} panel={
                <TableEditHashTab
                    setIsEditingHash={this.setIsEditingHash}
                    schema={this.state.schema}
                    masks={this.state.originalMasks}
                    setHashes={this.setHashes}
                />}
            />);
        }

        const versionedTableDropdowns = (versionedSchema) &&
            (<div>
                <TableEditWarnDialog
                    {...this.state.warnDialogState}
                    onCancel={this.cancelTableEditWarnDialog}
                    onClose={this.closeTableEditWarnDialog}
                />
                <MDMFormGroup
                    id='create-primary-key'
                    label='Primary Key'
                    tooltipText="You can only select fields of type Integer and Text."
                    readOnly={this.state.isEdit}
                    type='dropdown'
                    value={schema.primary_key}
                    values={pkValues}
                    validate={this.validatePrimaryKey}
                />
                <MDMFormGroup
                    id='create-sequence'
                    label='Sequence Field'
                    placeholder='<No Sequence>'
                    tooltipText="You can only select fields of type Integer, Float or Datetime."
                    readOnly={Boolean(this.state.originalSeq)}
                    type='dropdown'
                    value={schema.sequence_field}
                    values={sequenceValues}
                    validate={this.validateSequenceField}
                />
                <MDMFormGroup
                    id='create-matching'
                    label=''
                    type='enablebox'
                    value={this.state.schema.matching.enabled}
                    validate={this.validateMatching}
                />
                <MDMFormGroup
                    id='create-matching'
                    label=''
                    type='displaybox'
                    value={this.state.schema.matching.display}
                    validate={this.validateDisplay}
                    disabled={!this.state.schema.matching.enabled}
                />
            </div>)

        return (
            < MDMWrapper title={title} documentationPath="select_table_tables_page.html">
                <div>
                    {undo}
                    <div style={{ marginTop: '3rem' }} />
                    {tableName}
                    <MDMFormGroup id='create-type'
                        label='Type'
                        readOnly={isEdit}
                        type='dropdown'
                        value={schema.type}
                        values={typeValues}
                        onSelect={this.typeChanged}
                    />
                    {versionedTableDropdowns}
                    <MDMFormGroup id='create-description'
                        label='Description'
                        type='textarea'
                        value={description}
                        validate={this.updateDescription}
                    />
                    <div style={{ clear: 'both', height: '20px' }} />
                    <Tabs id='create-table-tabs' selectedTabId={tabKey} onChange={this.tabSelect} >
                        {tabs}
                    </Tabs>
                    <ButtonGroup>
                        <Button id='tblEditSubmit' disabled={!isValid} value={submitText} onClick={() => this.submitCreateTable()} />
                        <Button id='tblEditCancel' value='Cancel' onClick={() => this.props.history.push('/tables')} />
                    </ButtonGroup>
                    {alert}
                </div>

                <ConfirmDialog
                    buttonName="Change"
                    body={`Make this a Timeseries Table? This will remove the primary key, sequence field and nested tables.`}
                    title={`Switch to Timeseries`}
                    isOpen={!!pendingSchema}
                    cancelClick={() => this.setState({ pendingSchema: null })}
                    okClick={() => this.setState({ schema: pendingSchema, pendingSchema: null })}
                />
            </MDMWrapper >
        );

    }
}

export const TableEditForm = connect(
    (state: any) => ({
        dbName: state.database.selected
    }),
)(TableEditContainer);