import * as React from 'react';

//import { v4 as uuidv4 } from 'uuid';
import { Divider, Tooltip } from '@blueprintjs/core';
import { WorkflowDef } from '../../../types';
import { toTitlecase } from '../../../utils';
import { WorkflowFormMultiSelect } from './WorkflowFormMultiSelect'
import { TooltipTexts } from './TooltipTexts'

export interface ClaimGroupDisplayProps {
    workflow: WorkflowDef;
    groups?: string[];
}

export const ClaimGroupDisplay: React.FC<ClaimGroupDisplayProps> = ({ workflow, groups = [] }) => {
    //const uniqueId = uuidv4();

    const generateGroupInput = (title: string, items: string[], selectedItems: string[], tooltip?: string): React.ReactNode =>
        <WorkflowFormMultiSelect key={title} label={title} items={items} selectedItems={selectedItems} tooltip={tooltip} disabled onSelect={()=> {}} onRemove={()=> {}}/>

    const mapClaimGroups = (claimGroups) => {
        const claimGroupEntries = [];

        for (const [key, value] of Object.entries(claimGroups)) {
            const tokens = key.split('$');
            const title = toTitlecase(tokens[0]);
            const groupInput = generateGroupInput(title, groups, value.toString().split(','))
            claimGroupEntries.push(groupInput);
        }

        return claimGroupEntries;
    }

    const { variables } = workflow;
    const claimGroups = {};

    for (const [key, value] of Object.entries(variables)) {
        if(key.endsWith('$claim_groups'))
            claimGroups[key] = value;
    }

    if(!Object.keys(claimGroups).length)
        return null;

    return (
        <div className="flex-column workflow-detail-form">
            <Divider className="wf-detail-divider"/>
            <div>
                <div className="text-medium claim-groups-title">
                    Claim Groups
                    <Tooltip content={TooltipTexts.claimGroups} className="tooltip">
                        <span className="tooltip-target">?</span>
                    </Tooltip>
                </div>
                {mapClaimGroups(claimGroups)}
            </div>
        </div>
    )
}
