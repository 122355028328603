import { changeset } from './changeset';
import { groups } from './groups';
import { login } from './login';
import { menu } from './menu';
import { messageboxoptions } from './messageboxoptions';
import { recordActivity } from './recordActivity';
import { recordHistory } from './recordHistory';
import { roles } from './roles';
import { showcreatedb } from './showcreatedb';
import { showtoaster } from './showtoaster';
import { storerehydrated } from './storerehydrated';
import { table } from './settable';
import { username } from './username';
import { periodicData } from './periodicData';
import { systemSettings } from './systemSettings';

import { userReducer } from '../store/user/reducer';
import { workflowReducer } from '../store/workflow/reducer';
import { viewReducer } from '../store/view/reducer';
import { authReducer } from '../store/auth/reducer';
import { permReducer } from '../store/permissions/reducer';
import { licenseReducer } from '../store/license/reducer';
// import { databases } from './QueryDatabases';
import { database } from '../store/database/reducer';

export const reducers = {
    user: userReducer,
    workflow: workflowReducer,
    view: viewReducer,
    auth: authReducer,
    permissions: permReducer,
    license: licenseReducer,

    recordActivity,
    recordHistory,
    changeset,
    database,
    groups,
    login,
    menu,
    messageboxoptions,
    roles,
    showtoaster,
    showcreatedb,
    storerehydrated,
    table,
    username,
    periodicData,
    systemSettings
};