import { useSelector } from 'react-redux';
import { MDMStore, RootState } from 'store';

// NOTE: better type for this would be a good idea
export function inRoles(roleList: any[]): boolean {
    const store = MDMStore.getState() as any;
    const { user, auth } = store;
    const systemGroup = auth.config?.systemGroupName || 'system';

    const inSystemGroup = user.groups.find(group => group === systemGroup);
    if (inSystemGroup)
        return true;

    if (user.roles == null)
        return false;

    if (!Array.isArray(user.roles))
        return false;

    return roleList.reduce((acc, role) => {
        const inRole = user.roles.indexOf(role) >= 0;
        return acc + (inRole ? 1 : 0);
    }, 0) > 0;
}

export const useHasRole: (roleList: string[])=> boolean = (roleList) => {
    const { user, auth } = useSelector((state: RootState) => ({ user: state.user, auth: state.auth })) as any;
    const systemGroup = auth.config?.systemGroupName || 'system';

    if (user.groups.includes(systemGroup)) return true;
    if (!Array.isArray(user.roles)) return false;

    return roleList.some(role => user.roles.includes(role));
}

export function inSystemGroup(): boolean {
    const store = MDMStore.getState() as any;
    const { user, auth } = store;
    const systemGroup = auth.config?.systemGroupName || 'system';

    if (!user.groups) return false;
    const inSystemGroup = user.groups.find(group => group === systemGroup);
    return !!inSystemGroup;
}