import * as React from 'react';
import { Alert, Classes, FormGroup, Icon, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { MultiSelect, IItemRendererProps, ItemPredicate } from '@blueprintjs/select';

import { Button } from '../Shared';
import { DataTypes, Field, MaskHash, Schema } from 'types';
import { Intent as ToastIntent } from 'types';
import { showToaster } from 'actions';
import { MDMStore } from 'store';

require('./TableEdit.scss');

export interface TableEditHashTabProps {
    masks: MaskHash[] | null;
    schema: Schema;
    setHashes: (hashes: MaskHash[]) => void;
    setIsEditingHash: (newEditState: boolean) => void;
}
export interface TableEditHashTabState {
    currentEditHash: number;
    deleteHash: number;
    edit: boolean;
    hashes: MaskHash[];
    isEditHashOpen: boolean;
    showDeleteAlert: boolean;
    piiFields: string[];
}

const emptyHash: MaskHash = {
    description: '',
    fields: []
};

interface SubsetValidation {
    isSubset: boolean;
    isSuperset: boolean;
}

export class TableEditHashTab extends React.Component<TableEditHashTabProps, TableEditHashTabState> {

    constructor(props) {
        super(props);
        this.state = {
            currentEditHash: -1,
            deleteHash: -1,
            edit: false,
            hashes: props.schema.mask_hashes || [],
            isEditHashOpen: false,
            piiFields: this.getPIIFields(props.schema),
            showDeleteAlert: false
        };
    }

    componentDidUpdate(prevProps: TableEditHashTabProps) {
        const { schema } = this.props;
        if (prevProps.schema !== schema)
            this.setState({
                hashes: schema.mask_hashes ? [...schema.mask_hashes] : [],
                piiFields: this.getPIIFields(schema)
            });
    }

    private getPIIFields(schema: Schema): string[] {
        const isComplex = ((f: Field): boolean => f.datatype.type === DataTypes.table);
        const fields = [];

        // complex fields marked with pii actually mean all of their subfields are pii
        schema.fields.forEach(f => {
            if (isComplex(f)) {
                f.pii ? f.schema.fields.forEach(x => fields.push(`${f.field}.${x.field}`)) :
                    f.schema.fields.forEach(x => x.pii && fields.push(`${f.field}.${x.field}`));
            }
            else if (f.pii) {
                fields.push(f.field);
            }
        });
        return fields;
    }

    private renderHashField = (item, {
        handleClick
    }: IItemRendererProps) => {
        return (
            <MenuItem
                onClick={handleClick}
                icon={this.state.hashes[this.state.currentEditHash].fields.map(f => f.field).includes(item) ? IconNames.TICK : IconNames.BLANK}
                key={item}
                text={item}
                shouldDismissPopover={false}
            />);
    }

    private cancelEdit = () => {
        const newState = {
            ...this.state,
            currentEditHash: -1,
            isEditHashOpen: false,
            edit: false,
            hashes: this.props.schema.mask_hashes
        };
        this.setState(newState, () => this.props.setIsEditingHash(false));
    }

    private hashExists = (hash, table, index) => {
        let isDuplicate = false;
        table && table.forEach((h, i) => {
            if (i !== index && hash.fields.length === h.fields.length) {
                if (!isDuplicate) {
                    let hasAll = true;
                    hash.fields.forEach(testField => {
                        if (!h.fields.find(f => f.field === testField.field)) {
                            hasAll = false;
                        }
                    });
                    isDuplicate = hasAll;
                }
            }
        });
        return isDuplicate;
    }

    private doneEdit = () => {
        const { hashes, currentEditHash } = this.state;
        const newHash = hashes[currentEditHash];

        const isDuplicate = this.validateNoDuplicateHash(newHash, currentEditHash);

        if (isDuplicate) {
            const message = `Duplicate hash.`;
            MDMStore.dispatch<any>(showToaster(ToastIntent.ERROR, message));
            return;
        }

        // do not insert empty hashes. Treat as cancel
        if (newHash.fields.length === 0) {
            hashes.splice(currentEditHash, 1);
        } else if (this.hashExists(newHash, hashes, currentEditHash)) {
            const message = `Mask hash must be unique. Duplicate hash ${newHash.description} removed.`;
            MDMStore.dispatch<any>(showToaster(ToastIntent.WARNING, message));
            hashes.splice(currentEditHash, 1);
        }
        newHash.description = newHash.description.trim();
        this.setState({ currentEditHash: -1, isEditHashOpen: false, edit: false },
            () => {
                this.props.setHashes(hashes);
                this.props.setIsEditingHash(false);
            });
    }

    private closeDeleteAlert = () => {
        this.setState({ deleteHash: -1, showDeleteAlert: false });
    }

    private addHash = (e) => {
        const { hashes } = this.state;

        const newHashes = [...hashes, { ...emptyHash }];
        this.setState({
            currentEditHash: newHashes.length - 1,
            isEditHashOpen: true,
            edit: false,
            hashes: newHashes
        }, () => this.props.setIsEditingHash(true));
    }

    private deleteHash = (index) => {
        this.setState({ deleteHash: -1, showDeleteAlert: false }, () => {
            const hashes = [...this.state.hashes];
            hashes.splice(index, 1);
            this.props.setHashes(hashes);
        });
    }

    private updateHash = (newHash: MaskHash, index: number) => {
        const newHashes = [...this.state.hashes];
        newHashes.splice(index, 1, newHash);
        this.setState({ hashes: newHashes });
    }

    private updateHashDescription = description => {
        const { hashes, currentEditHash } = this.state;
        const newHash: MaskHash = {
            ...hashes[currentEditHash],
            description: description
        };
        this.updateHash(newHash, currentEditHash);
    }

    private validateNoDuplicateHash = (newHash, index): boolean => {
        const newHashFields = newHash.fields.map(field => field.field).sort((a: string, b: string) => a.localeCompare(b));

        const otherHashes = [...this.state.hashes];
        otherHashes.splice(index, 1);

        for (const hash of otherHashes) {
            const hashFields = hash.fields.map(field => field.field).sort((a: string, b: string) => a.localeCompare(b));
            const equal = newHashFields.length === hashFields.length && newHashFields.every((value, index) => value === hashFields[index])
            if (equal) {
                return true;
            }
        }
        return false;
    }

    private addHashField = (field: string) => {
        const { hashes, currentEditHash } = this.state;
        const hash = hashes[currentEditHash];
        // selecting a field twice is the same as deselecting it
        if (hash.fields.find(f => f.field === field))
            this.removeHashField(field);
        else {
            const newHash: MaskHash = {
                ...hash,
                fields: [...hash.fields].concat({ field: field })
            };

            this.updateHash(newHash, currentEditHash);
        }
    }

    private removeHashField = (field: string) => {
        const fields = [...this.state.hashes[this.state.currentEditHash].fields];
        const index = fields.findIndex(f => f.field === field);
        if (index !== -1)
            fields.splice(index, 1);
        const newHash: MaskHash = {
            ...this.state.hashes[this.state.currentEditHash],
            fields: fields
        };
        this.updateHash(newHash, this.state.currentEditHash);
    }

    // If you are an existing mask hash you are forever
    //private canEditHash = h => !this.hashExists(h, this.props.masks, -1);
    // You can not remove the final mask hash
    private canEditHash = h => true;

    renderHashMenu = (hash, index) => {
        if (!this.canEditHash(hash)) {
            return null
        }

        return (
            <div className="management-actions">
                <Popover
                    position={Position.BOTTOM_RIGHT}
                    content={
                        <Menu>
                            <MenuItem
                                icon={IconNames.EDIT}
                                onClick={e =>
                                    this.setState({
                                        isEditHashOpen: true,
                                        currentEditHash: index,
                                        edit: true
                                    }, () => this.props.setIsEditingHash(true))
                                }
                                text="Edit"
                            />
                            <MenuItem
                                icon={IconNames.TRASH}
                                onClick={e => this.setState({ deleteHash: index, showDeleteAlert: true })}
                                text="Delete"
                            />
                        </Menu>
                    }
                >
                    <Icon icon={IconNames.MORE} />
                </Popover>
            </div>
        )
    }

    filterPiiFields: ItemPredicate<string> = (query, field, _index, exactMatch) => {
        const normalizedTitle = field.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return normalizedTitle.indexOf(normalizedQuery) >= 0;
        }
    };

    private renderHashes() {
        const { isEditHashOpen, currentEditHash, hashes } = this.state;

        const managementItems = this.state.hashes.map((hash, index) => {
            if (isEditHashOpen && currentEditHash === index) {
                return (
                    <div key={index} className="management-item">
                        <FormGroup
                            inline={true}
                            className="user-dialog-label"
                            contentClassName={`${Classes.TEXT_MUTED} hash-label`}
                            label="Description"
                            labelFor="descinput"
                        >
                            <input id="descinput" className={Classes.INPUT} type="text" placeholder="Enter description"
                                value={hash.description}
                                onChange={e => this.updateHashDescription(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup
                            inline={true}
                            className="user-dialog-label"
                            contentClassName={`${Classes.TEXT_MUTED} hash-label`}
                            label="Hash Fields"
                        >
                            <MultiSelect
                                resetOnSelect
                                itemPredicate={this.filterPiiFields}
                                items={this.state.piiFields}
                                selectedItems={hash.fields.map(f => f.field)}
                                noResults={<MenuItem disabled={true} text="No PII fields found" />}
                                onItemSelect={item => this.addHashField(item)}
                                itemRenderer={this.renderHashField}
                                tagRenderer={t => t}
                                tagInputProps={{ onRemove: this.removeHashField }}
                                className="mdm-edit-schema-multiselect"
                            />
                        </FormGroup>
                        <div className="hash-button-group">
                            <Button
                                style={{ marginBottom: '1rem' }}
                                value="DONE"
                                intent={Intent.SUCCESS}
                                disabled={hash.description.trim().length === 0 || hash.fields.length === 0}
                                onClick={this.doneEdit}
                            />
                            <Button
                                className="button-margin"
                                value="CANCEL"
                                intent={Intent.PRIMARY}
                                onClick={this.cancelEdit}
                            />
                        </div>
                    </div>
                )
            }
            return (
                <div key={index} className="management-item">
                    {hash.description ? <div className="management-name">{hash.description}</div> :
                        <div className={`hash-no-details ${Classes.TEXT_MUTED}`}>No description</div>}
                    <div className="management-roles">
                        <div className="management-role-text">fields</div>
                        {hash.fields.map((f, index) =>
                            <div key={index} className="management-role">
                                {f.field}
                            </div>
                        )}
                    </div>
                    {this.renderHashMenu(hash, index)}
                </div>
            )
        });
        return <div>{managementItems}</div>;
    }

    render() {
        const { deleteHash, hashes, showDeleteAlert, isEditHashOpen } = this.state;
        return (
            <div>
                <Alert
                    icon={IconNames.TRASH}
                    intent={Intent.DANGER}
                    isOpen={showDeleteAlert}
                    confirmButtonText="Delete Mask Hash"
                    cancelButtonText="Cancel"
                    onConfirm={() => this.deleteHash(deleteHash)}
                    onCancel={this.closeDeleteAlert}
                >
                    <p>
                        {`Are you sure you want to delete Mask Hash '
                            ${deleteHash > -1 && hashes[deleteHash] && hashes[deleteHash].description}
                        ' ?`}
                    </p>
                </Alert>
                <div className="edit-hash-description">Mask hashes are used to encrypt personal information in records for GDPR (General Data Protection Regulation) compliance.</div>
                <Button
                    style={{ margin: '1rem' }}
                    icon={IconNames.PLUS}
                    value="Create Mask Hash"
                    disabled={isEditHashOpen}
                    onClick={this.addHash}
                />
                <div className="management-list">
                    {hashes ? this.renderHashes() : null}
                </div>
            </div>
        );
    }
}