import * as React from 'react';
import { Collapse, FormGroup, HTMLSelect, Position, TextArea } from '@blueprintjs/core';
import { DateRangeInput } from "@blueprintjs/datetime";
import { Button } from '../Shared';
import { ChangesetFilter, ChangesetStatus, ChangesetCategory } from 'types';
import { IconNames, } from '@blueprintjs/icons';

require('./ChangesetMain.scss');

export interface IChangesetMainContainerProps {
    filter: ChangesetFilter;
    handleFilterChange: (event: any) => any;
    handleFilterDateChange: (dateObj: any) => any;
    handleFilterStatusChange: (status: string) => any;
    handleShowAdvancedFilterChange: () => any;
    showAdvancedFilter: boolean;
}
export const ChangesetFilterForm: React.FC<IChangesetMainContainerProps> = (props: IChangesetMainContainerProps) => {

    const timestamp = props.filter.create_timestamp;
    const startDate = (timestamp && timestamp.start) ? new Date(timestamp.start) : null;
    const endDate = (timestamp && timestamp.end) ? new Date(timestamp.end) : null;
    return (
        <div className="filter-wrapper">
            <FormGroup
                className="mdm-changeset-form-group"
                inline={true}
                label="Filter by:"
                labelFor="filter-select"
                contentClassName="filter-input"
            >
                <HTMLSelect id="filter-select" fill={true} value={props.filter.status} onChange={e => props.handleFilterStatusChange(e.target.value)}
                    options={[
                        { label: 'All', value: '' },
                        { label: "Pending", value: ChangesetStatus.PENDING },
                        { label: "Queued", value: ChangesetStatus.QUEUED },
                        { label: "Committing", value: ChangesetStatus.COMMITING },
                        { label: "Committed", value: ChangesetStatus.COMMITTED },
                        { label: "Error", value: ChangesetStatus.ERROR }
                    ]}
                />
            </FormGroup>
            <Button className="filter-form-button" icon={props.showAdvancedFilter ? IconNames.CARET_DOWN : IconNames.CARET_RIGHT} onClick={props.handleShowAdvancedFilterChange}>
                {props.showAdvancedFilter ? "Hide" : "Show"} Advanced Filter Options
            </Button>
            <Collapse isOpen={props.showAdvancedFilter}>
                <FormGroup
                    className="mdm-changeset-form-group"
                    inline={true}
                    label="Between dates:"
                    labelFor="filter-range"
                    contentClassName="filter-input"
                >
                    <DateRangeInput
                        formatDate={date => date.toLocaleString()}
                        onChange={dates => {
                            const dateObj = {
                                start: dates[0] ? new Date(dates[0]).toISOString() : null,
                                end: dates[1] ? new Date(dates[1]).toISOString() : null,
                            }
                            props.handleFilterDateChange(dateObj)
                        }}
                        parseDate={str => new Date(str)}
                        value={[startDate, endDate]}
                        contiguousCalendarMonths={false}
                        allowSingleDayRange={true}
                        popoverProps={{ position: Position.RIGHT_TOP }}
                        className="changeset-date"
                    />
                </FormGroup>
                <FormGroup
                    className="mdm-changeset-form-group"
                    inline={true}
                    label="Description contains:"
                    labelFor="filter-desc"
                    contentClassName="filter-input"
                >
                    <TextArea id="filter-desc" fill={true} onBlur={props.handleFilterChange}
                        name="description_contains" defaultValue={props.filter.description_contains} />
                </FormGroup>
                <FormGroup
                    className="mdm-changeset-form-group"
                    inline={true}
                    label="Category:"
                    labelFor="filter-cat"
                    contentClassName="filter-input"
                >
                    <HTMLSelect
                        id="filter-cat" fill={true} value={props.filter.status} onChange={props.handleFilterChange}
                        name="category"
                        options={[
                            { label: 'All', value: '' },
                            { value: ChangesetCategory.DATA, label: 'Data' },
                            { value: ChangesetCategory.METADATA, label: 'Metadata' }
                        ]}
                    />
                </FormGroup>
            </Collapse>
        </div>
    );
};
`   `