import { PURGE } from 'redux-persist';
import { RECEIVE_QUERY_DATABASES, DATABASE_DELETED, SET_DBNAME, DatabaseState } from './types';

const initialState: DatabaseState = {
    all: [],
    selected: null,
    queriedAll: false,
}

export const database = (state = initialState, action) => {
    switch (action.type) {
        case DATABASE_DELETED:
            const filteredDatabases = state.all.filter(databaseObj => databaseObj.database !== action.payload);
            return { ...state, all: filteredDatabases };

        case RECEIVE_QUERY_DATABASES:
            return { ...state, all: action.payload, queriedAll: true };

        case SET_DBNAME:
            return { ...state, selected: action.dbName };

        case PURGE:
            return initialState;

        default:
            return state;
    }
};