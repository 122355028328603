import * as moment from 'moment';
import { Field, Schema } from 'types';

export enum DateFormatEnum {
    date = 'YYYY-MM-DD',
    timestamp = 'YYYY-MM-DDTHH:mm:ss',
    time = 'HH:mm:ss',
    dateDisplay = 'MM/DD/YYYY',
    datetimeDisplay12 = 'MM/DD/YYYY hh:mm:ss A',
    timeDisplay = 'hh:mm:ss A',
    datetimeDisplay24 = 'MM/DD/YYYY HH:mm:ss',
    timestampDisplay = 'YYYY-MM-DD HH:mm:ss'
}

export const formatDateTimeDisplay = (value: string | Date, field: Field, reverse: boolean = false) => {
    if (!value) return '';
    if (!field) return value

    let parseFormat = undefined;
    let displayFormat = undefined;

    switch (field?.datatype?.type) {
        case 'date':
            parseFormat = DateFormatEnum.date;
            displayFormat = DateFormatEnum.dateDisplay;
            break;
            
        case 'datetime':
            parseFormat = DateFormatEnum.timestamp;
            displayFormat = DateFormatEnum.datetimeDisplay12;
            break;

        case 'time':
            parseFormat = DateFormatEnum.time
            displayFormat = DateFormatEnum.timeDisplay;
            break;

        default:
            return value;
    }

    if (reverse) return moment(value, displayFormat).format(parseFormat);


    return moment(value, parseFormat).format(displayFormat);
}

export const customDateFormat = (value: string | number | Date, format: DateFormatEnum, currentFormat?: DateFormatEnum) => {
    if (!value) return moment(value).format(format);
    if (!currentFormat) return moment(value).format(format);

    return moment(value, currentFormat).format(format);
}
