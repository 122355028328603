import { Layouts } from 'react-grid-layout';

export const SET_DASHBOARD_LAYOUT = 'SET_DASHBOARD_LAYOUT';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_PERMISSIONS_LOADING = 'SET_PERMISSIONS_LOADING';

export const CLEAR_ROLES = 'CLEAR_ROLES';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

export interface UserState {
    username: string;
    dashboardLayout: Layouts;
    groups: string[];
    roles: string[];
    settings: UserSettings;
}

export interface UserSettings {
    claimNextAssigned: boolean;
}

interface SetDashboardLayoutAction {
    type: typeof SET_DASHBOARD_LAYOUT;
    payload: { newLayouts: Layouts };
}

interface SetUserGroupsAction {
    type: typeof SET_USER_GROUPS;
    payload: { groups: string[] };
}

interface SetUserRolesAction {
    type: typeof SET_USER_ROLES;
    payload: { roles: string[] };
}

interface SetUserSettingsAction {
    type: typeof SET_USER_SETTINGS;
    payload: { settings: UserSettings };
}

interface SetPermissions {
    type: typeof SET_PERMISSIONS;
    payload: { permissions: Permissions };
}

interface SetPermissionsLoading {
    type: typeof SET_PERMISSIONS_LOADING;
    payload: { loading: boolean };
}

interface ClearRoles {
    type: typeof CLEAR_ROLES;
}

interface ClearGroups {
    type: typeof CLEAR_GROUPS;
}

interface ClearUserData {
    type: typeof CLEAR_USER_DATA;
}

export type UserActionTypes = SetUserGroupsAction | SetDashboardLayoutAction | SetUserRolesAction | SetUserSettingsAction | SetPermissions | SetPermissionsLoading | ClearRoles | ClearGroups | ClearUserData;
