import * as React from 'react';

require('./View.scss');

export interface ViewDetailProps {
    description: string;
    createDate: Date;
    viewID: number;
    deleteView: (viewID: number) => void;
    editView: (viewID: number) => void;
    viewView: (viewID: number) => void;
}
export const ViewDetail: React.FC<ViewDetailProps> = (
    {description, createDate, viewID, deleteView, editView, viewView}
) =>
    <div className="view-detail-wrapper">
        <div className="view-detail-description">{`${viewID}: ${description}`}</div>
        <div className="view-detail-create-date">
            {`Created on ${createDate.getMonth() + 1}/${createDate.getDate()}/${createDate.getFullYear() % 100}`}
        </div>
        <div className="view-detail-links">
            <a role="button" id="view-view-btn" onClick={e => viewView(viewID)}>View</a>|
            <a role="button" id="view-edit-btn" onClick={e => editView(viewID)}>Edit</a>|
            <a role="button" id="view-delete-btn" onClick={e => deleteView(viewID)}> Delete</a>
        </div>
    </div>;
