/**
 * Created by nbamford on 1/29/2017.
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';

import { completeTask, saveTask, discardAssignedTask as discardTask } from '../../../store/workflow/actions';
import { displayError, WorkflowApi } from 'api';
import { getReviewWorkflow } from '../../../store/workflow/selectors';
import { WorkflowNoteForm } from './WorkflowNoteForm';

import { History, ReviewWorkflow, Workflow } from 'types';

export interface WorkflowNoteProps {
    isEdit: boolean;
    completeTask: (workitem: Workflow, history: any, button:HTMLButtonElement, recordId: string, data: object, note: string) => void;
    discardTask: (workflow: Workflow, button:HTMLButtonElement, note: string) => void;
    history: any;
    match: match;
    saveTask: (workitem: Workflow, button:HTMLButtonElement, recordId: string, data: object) => void;
    workflow: ReviewWorkflow;
}
export interface WorkflowNoteState {
    workitemHistory: History[];
}
export class WorkflowNoteContainerBase extends React.PureComponent<WorkflowNoteProps, WorkflowNoteState> {

    constructor(props) {
        super(props);
        this.state = { workitemHistory: [] };
    }

    private refreshWorkitem() {
        WorkflowApi.readWorkitemState(this.props.workflow.workflow.databaseName, this.props.workflow.workflow.workitem_id)
            .then(history => this.setState({ workitemHistory: history }))
            .catch(error => displayError(
                'Read Task State Failed',
                `Failed to read state for Workitem ${this.props.workflow.workflow.workitem_id}`,
                error
            ));
    }

    componentDidMount() {
        this.refreshWorkitem();
    }

    private completeTask = (button:HTMLButtonElement, note:string) => {
        const data = {};
        if (note)
            data['$notes'] = note;
        // For reasons that seem historical, notes is passed via data vs the note argument. The logic eludes me.
        this.props.completeTask(this.props.workflow.workflow, this.props.history, button, null, data, null);
    }
    private saveTask = (button:HTMLButtonElement, note: string) => {
        const data = {};
        if (note)
            data['$notes'] = note;
        this.props.saveTask(this.props.workflow.workflow, button, null, data);
        this.refreshWorkitem();
    }
    private discardTask = (button:HTMLButtonElement, note: string) => {
        this.props.discardTask(this.props.workflow.workflow, button, note);
    }
    render() {
        const workflow = this.props.workflow;
        return (
            <div>
                <WorkflowNoteForm workflow={workflow.workflow} history={this.state.workitemHistory}
                    completeTask={this.completeTask} discardTask={this.discardTask} saveTask={this.saveTask} />
            </div>
        );
    }
}
//asNotes={true}notes={this.props.workitemHistory} getButtons={this.getButtons}
export const WorkflowNoteContainer = connect(
    (state: any) => ({ workflow: getReviewWorkflow(state) }),
    { completeTask, discardTask, saveTask }
)(WorkflowNoteContainerBase as any);

