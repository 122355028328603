import { MDMServerError } from 'types';
import { Pagination } from 'rp-mdm-common';

export async function doFetchPost(url: string, postData: object, pagination?: Pagination): Promise<any> {
    let err: Error = undefined;
    let response = undefined;

    const envelope = {
        payload: postData,
        pagination: pagination || { start: 0, count: 100 },
    };

    const res = await fetch(url,
        {
            method: 'POST',
            body: JSON.stringify(envelope),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            mode: 'cors' as RequestMode,
        });

    if (!res.ok) {
        return Promise.reject(new Error(`${res.status}: ${res.statusText}`));
    }
    response = await res.json();

    if (!response.success) {
        const mdmErr: MDMServerError = response.error;

        if (!mdmErr) {
            const errMessage = response.payload ? response.payload.message : `Unknown error when calling ${url}`;
            err = new Error(errMessage);
            return Promise.reject(err);
        }

        err = new Error(mdmErr.messages.join(' '));
        if (mdmErr.stack_trace)
            console.error("Error found in: ", mdmErr.stack_trace);

        return Promise.reject(err);
    }
    return response.payload;
}