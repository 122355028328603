import { displayError, TableApi } from 'api';
import { ErrorHandler, Schema, SchemaType } from 'types';

const emptySchema: Schema = {
    fields: [],
    primary_key: '',
    type: SchemaType.versioned
};

export const getSchema = async (dbName: string, changesetId: number | string, tableName: string, errorHandler?: ErrorHandler): Promise<Schema> => {
    let schema: Schema = null;
    let tables = [];

    try {
        tables = await TableApi.queryTables(dbName, changesetId, tableName);
        const table = tables.length > 0 ? tables[0] : undefined;
        schema = table ? table.schema : { ...emptySchema };
    } catch (e) {
        console.error(e);
        displayError('Get Schema Failed', `Failed to get schema for table ${tableName}`);
    }


    return schema;
};