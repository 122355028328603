/**
 * @format
 */
import { Card, Divider } from '@blueprintjs/core';
import * as React from 'react';
import { MDMWrapper } from '../../components/Shared';
import { AuthApi, DatabaseApi, UserApi, PermApi, LogApi } from '../../api'
import { toTitlecase } from '../../utils';
import './VersionScreen.scss';

const versionInfo = require('../../../version_info.json');
//const pkgInfo = require('../../../package.json');

export interface VersionProps { }

export interface VersionState {
    isLoading: boolean;
    authVersionData: { [key: string]: string; };
    permVersionData: { [key: string]: string; };
    userVersionData: { [key: string]: string; };
    coreVersionData: { [key: string]: string; };
    logVersionData:  { [key: string]: string; };
}

interface InfoLine {
    label: string;
    value: string;
}

export class VersionScreen extends React.PureComponent<VersionProps, VersionState> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            authVersionData: null,
            permVersionData: null,
            userVersionData: null,
            coreVersionData: null,
            logVersionData: null,
        };
    }

    componentDidMount(): void {
        this.getVersions();
    }

    getVersions = async () => {
        try {
            this.setState({ isLoading: true });
            const promises = [
                DatabaseApi.getVersion(),
                AuthApi.getVersion(),
                UserApi.getVersion(),
                PermApi.getVersion(),
                LogApi.getVersion(),
            ];

            const  [coreResult, authResult, userResult, permResult, logResult] = await Promise.allSettled(promises);

            this.setState({
                coreVersionData: coreResult.status === 'fulfilled' ? coreResult.value : null,
                authVersionData: authResult.status === 'fulfilled' ? authResult.value : null,
                userVersionData: userResult.status === 'fulfilled' ? userResult.value : null,
                permVersionData: permResult.status === 'fulfilled' ? permResult.value : null,
                logVersionData: logResult.status === 'fulfilled' ? logResult.value : null,
            });
        } catch (error) {
            console.error('Error getting versions', error)
        } finally {
            this.setState({ isLoading: false });
        }
    }

    generateInfoPanel = (title: string, lines: InfoLine[] | null, hasData: boolean = false) => {
        const { isLoading } = this.state;
        return (
            <Card className="version-card">
                <div className="flex-column" id="version-detail-card">
                    <div className="flex-space-between">
                        <div className="text-medium">
                            {title}
                        </div>
                    </div>
                    <Divider className="version-detail-divider" />
                    {!hasData && !isLoading && <div className="text-small">Failed to get a response</div>}

                    {!isLoading && lines && lines.map((line, index) => {
                        return (
                            <div key={`${title}-line-${index}`}>
                                <span className="info-label">{line.label}: </span>
                                <span className="info-val text-small">{line.value}</span>
                            </div>
                        )
                    })}

                    {isLoading && <div className="text-small">Loading...</div>}
                </div>
            </Card>
        )
    }

    renderUiPanel = () => {
        const { version, hash } = versionInfo;
        // const { version } = pkgInfo;

        const infoLines = [
            { label: 'Version', value: version },
            { label: 'Hash', value: hash },
        ]

        return this.generateInfoPanel('UI Version Info', infoLines, true)
    }

    generateInfoLines = (responseObject) => {
        let infoLines: InfoLine[] = []
        for (const [key, value] of Object.entries(responseObject)) {
            const title = toTitlecase(key.toString())
            infoLines.push({ label: title, value: value.toString() })
        }
        return infoLines
    }


    renderAuthPanel = () => {
        const { authVersionData } = this.state;
        return this.generateInfoPanel(
            'Authentication Service Version',
            authVersionData ?
                this.generateInfoLines(authVersionData) :
                null,
                !!authVersionData
        )
    }

    renderCorePanel = () => {
        const { coreVersionData } = this.state;
        return this.generateInfoPanel(
            'Core Service Version',
            coreVersionData ?
                this.generateInfoLines(coreVersionData) :
                null,
                !!coreVersionData
        )
    }

    renderPermissionPanel = () => {
        const { permVersionData } = this.state;
        return this.generateInfoPanel(
            'Permission Service Version',
            permVersionData ?
                this.generateInfoLines(permVersionData) :
                null,
                !!permVersionData
        )
    }

    renderUserPanel = () => {
        const { userVersionData } = this.state;
        return this.generateInfoPanel(
            'User Service Version',
            userVersionData ?
                this.generateInfoLines(userVersionData) :
                null,
            !!userVersionData
        )
    }

    renderLogPanel = () => {
        const { logVersionData } = this.state;
        return this.generateInfoPanel(
            'Log Service Version',
            logVersionData ?
                this.generateInfoLines(logVersionData) :
                null,
            !!logVersionData
        )
    }

    public render() {
        return (
            <MDMWrapper title="MDM Version Information">
                <div className="flex-column workflow-details-container">
                    {this.renderUiPanel()}
                    {this.renderCorePanel()}
                    {this.renderAuthPanel()}
                    {this.renderPermissionPanel()}
                    {this.renderUserPanel()}
                    {this.renderLogPanel()}
                </div>
            </MDMWrapper>
        );
    }
}

