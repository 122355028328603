import { LicenseState, SET_LICENSE_INFO, CLEAR_LICENSE_INFO } from './types';

const defaultState: LicenseState = {
    licensed: false,
    remainingDays: 0,
    loading: true,
};

export const licenseReducer = (state = defaultState, action): LicenseState => {
    switch (action.type) {
        case SET_LICENSE_INFO:
            return { ...state, licensed: action.payload.licenseInfo.licensed, remainingDays: action.payload.licenseInfo.remainingDays, loading: false };
        case CLEAR_LICENSE_INFO:
            return { ...defaultState };
        default:
            return state;
    }
};
