import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';
import {  FormGroup, Classes, MenuItem, Tooltip } from '@blueprintjs/core';
import { MultiSelect, IItemRendererProps } from '@blueprintjs/select';
import { IconNames } from '@blueprintjs/icons';

export interface WorkflowFormMultiSelectProps {
    label: string;
    items: string[];
    disabled?: boolean;
    tooltip?: string;
    selectedItems?: string[];
    onSelect: (newElement:string) => void;
    onRemove: (removedElement:string) => void;
}

export const WorkflowFormMultiSelect: React.FC<WorkflowFormMultiSelectProps> = ({ label, items, selectedItems = [], tooltip, disabled = false, onSelect, onRemove }) => {
    const uniqueId = uuidv4();

    const renderGroupItem = ( item,  { handleClick }: IItemRendererProps) => {
        return (
            <MenuItem
                onClick={handleClick}
                disabled={selectedItems.includes(item)}
                icon={IconNames.BLANK}
                key={`${item}-${uniqueId}`}
                text={item}
                shouldDismissPopover={false}
            />
        );
    }

    const generateInputLabel = (title:string, tooltip: string) =>  {
        if(tooltip)
            return (
                <>
                    <span>{title}</span>
                    <Tooltip content={tooltip} className="tooltip">
                        <span className="tooltip-target">?</span>
                    </Tooltip>
                </>
            )
        return <span>{title}</span>;
    }

    const labelObj = generateInputLabel(label, tooltip)
    const GroupMultiSelect = MultiSelect.ofType<string>();

    return (
        <FormGroup
            key={`fg-${uniqueId}`}
            label={labelObj}
            inline={true}
            className={`${Classes.TEXT_MUTED} mdm-wf-form-group`}
            contentClassName="group-labels flex-center-vertically"
        >
            <GroupMultiSelect
                items={items}
                selectedItems={selectedItems}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={(newElement) => onSelect.call(this, newElement)}
                itemRenderer={renderGroupItem}
                tagRenderer={t => t}
                placeholder="Select a group..."
                tagInputProps={{
                    onRemove: onRemove,
                    disabled: disabled,
                }}
                className="workflow-multiselect"
            />
        </FormGroup>
    )
}
