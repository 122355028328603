export const DEFAULT_CHANGESET = 'DEFAULT_CHANGESET';

export const SHOW_CREATE_DATABASE = 'SHOW_CREATE_DATABASE';

export const DISPLAY_MESSAGEBOX = 'DISPLAY_MESSAGEBOX';

export const RECEIVE_CREATE_DATABASE = 'RECEIVE_CREATE_DATABASE';

export const REQUEST_DISCARD_TASK = 'REQUEST_DISCARD_TASK';

export const REQUEST_QUERY_RECORDS = 'REQUEST_QUERY_RECORDS';

export const RECEIVE_RECORDS_HISTORY = "RECEIVE_RECORDS_HISTORY";
export const RECEIVE_RECORD_ACTIVITY = "RECEIVE_RECORD_ACTIVITY";

export const RECEIVE_QUERY_WORKITEM_STATE = "RECEIVE_QUERY_WORKITEM_STATE";
export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';

export const RECEIVE_ROLES = "RECEIVE_ROLES";

export const RESET_CHANGESET = 'RESET_CHANGESET';
export const SET_CHANGESET = 'SET_CHANGESET';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_CURRENT_TABLE = 'SET_CURRENT_TABLE';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_USER_ROLES = 'SET_USER_ROLES';

export const CLEAR_PERIODIC_DATA = 'CLEAR_PERIODIC_DATA';

export type SET_PERIODIC_DATA = 'SET_PERIODIC_DATA';
export const SET_PERIODIC_DATA: SET_PERIODIC_DATA = 'SET_PERIODIC_DATA';

export type SHOW_TOASTER = 'SHOW_TOASTER';
export const SHOW_TOASTER: SHOW_TOASTER = 'SHOW_TOASTER';

export enum RoleEnum {
    MATCH_REVIEW = 'match_review',
    MANAGEMENT = 'management',
    DBA = 'dba',
    STEWARD = 'steward',
    WORKFLOW = 'workflow',
    PURGE_MASK = 'purge_mask'
}

export const USER_ROLES = {
    roles: [
        { id: RoleEnum.MATCH_REVIEW, display: 'Match Review' },
        { id: RoleEnum.MANAGEMENT, display: 'Management' },
        { id: RoleEnum.DBA, display: 'DBA' },
        { id: RoleEnum.STEWARD, display: 'Steward' },
        { id: RoleEnum.WORKFLOW, display: 'Workflow' },
        { id: RoleEnum.PURGE_MASK, display: 'Purge Mask' },
    ]
};

export const AuthorizedRoles = {
    compliance: [RoleEnum.DBA, RoleEnum.MANAGEMENT, RoleEnum.MATCH_REVIEW],
    dba: [RoleEnum.DBA],
    management: [RoleEnum.DBA, RoleEnum.MANAGEMENT],
    steward: [RoleEnum.DBA, RoleEnum.STEWARD],
    workflow: [RoleEnum.DBA, RoleEnum.WORKFLOW],
};

export type USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGING_IN: USER_LOGGING_IN = 'USER_LOGGING_IN';

export type USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_IN: USER_LOGGED_IN = 'USER_LOGGED_IN';

export type USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGGED_OUT: USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export type MINIMIZE_MENU = 'MINIMIZE_MENU';
export const MINIMIZE_MENU: MINIMIZE_MENU = 'MINIMIZE_MENU';

export type MAXIMIZE_MENU = 'MAXIMIZE_MENU';
export const MAXIMIZE_MENU: MAXIMIZE_MENU = 'MAXIMIZE_MENU';

export const STORE_REHYDRATED = 'STORE_REHYDRATED';
