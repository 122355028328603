// MM/DD/YYYY HH:mm
// Present information in the local timezone
export const formatTimeHHMM = timestamp => {
    //const timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC' };
    const timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit'} as const;
    const locale = 'en-US';
    const date = new Date(timestamp);
    //return `${date.toLocaleDateString(locale, { timeZone: 'UTC' })} ${date.toLocaleTimeString(locale, timeOptions)}`;
    return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale, timeOptions)}`;
};
