/**
 *  @format
 */
import * as React from 'react';

export const DMDataTypeSelect = ({ value, onChange }) => (
    <select
        className="form-control mdm_select"
        value={DataTypeUtils.ReverseDetails(value)}
        onChange={e => onChange(DataTypeUtils.GetDataTypeDetailed(e.target.value))}
    >
        <option>Integer(1)</option>
        <option>Integer(2)</option>
        <option>Integer(4)</option>
        <option>Integer(8)</option>
        <option>UInteger(1)</option>
        <option>UInteger(2)</option>
        <option>UInteger(4)</option>
        <option>Float</option>
        <option>Double</option>
        <option>Date</option>
        <option>Time</option>
        <option>DateTime</option>
        <option>Text(32)</option>
        <option>boolean</option>
    </select>
);

export class DataTypeUtils {
    // tslint:disable-next-line:cyclomatic-complexity
    static ReverseDetails(datatype) {
        //"datatype": { "type":"string", "size":int, "signed":int }
        const name = datatype.type;
        const size = datatype.size;
        const signed = datatype.signed;

        if (name === 'integer' && size === 1 && signed === true) {
            return 'Integer(1)';
        }
        if (name === 'integer' && size === 2 && signed === true) {
            return 'Integer(2)';
        }
        if (name === 'integer' && size === 4 && signed === true) {
            return 'Integer(4)';
        }
        if (name === 'integer' && size === 8 && signed === true) {
            return 'Integer(8)';
        }
        if (name === 'integer' && size === 1 && signed === false) {
            return 'UInteger(1)';
        }
        if (name === 'integer' && size === 2 && signed === false) {
            return 'UInteger(2)';
        }
        if (name === 'integer' && size === 4 && signed === false) {
            return 'UInteger(4)';
        }
        if (name === 'float' && size === 4) {
            return 'Float';
        }
        if (name === 'float' && size === 8) {
            return 'Double';
        }
        if (name === 'date') {
            return 'Date';
        }
        if (name === 'time') {
            return 'Time';
        }
        if (name === 'datetime') {
            return 'DateTime';
        }
        if (name === 'text' && size <= 256) {
            return 'Text(32)';
        }

        if (name === 'boolean') {
            return 'boolean';
        }
        if (name === 'Dec(10,5)') {
            return 'decimal';
        }
        return 'NA';
    }

    static GetDataTypeDetailed(id) {
        let name;
        let size;
        let scale;
        let signed;

        if (id === 'Integer(1)') {
            name = 'integer';
            size = 1;
            signed = true;
        }
        if (id === 'Integer(2)') {
            name = 'integer';
            size = 2;
            signed = true;
        }
        if (id === 'Integer(4)') {
            name = 'integer';
            size = 4;
            signed = true;
        }
        if (id === 'Integer(8)') {
            name = 'integer';
            size = 8;
            signed = true;
        }

        if (id === 'UInteger(1)') {
            name = 'integer';
            size = 1;
            signed = false;
        }
        if (id === 'UInteger(2)') {
            name = 'integer';
            size = 2;
            signed = false;
        }
        if (id === 'UInteger(4)') {
            name = 'integer';
            size = 4;
            signed = false;
        }
        if (id === 'UInteger(8)') {
            name = 'integer';
            size = 8;
            signed = false;
        }
        if (id === 'Float') {
            name = 'float';
            size = 4;
        }
        if (id === 'Double') {
            name = 'float';
            size = 8;
        }
        if (id === 'Date') {
            name = 'date';
        }
        if (id === 'Time') {
            name = 'time';
        }
        if (id === 'DateTime') {
            name = 'datetime';
        }
        if (id === 'Text(32)') {
            name = 'text';
            size = '32';
        }
        if (id === 'Text(256)') {
            name = 'text';
            size = '256';
        }
        if (id === 'boolean') {
            name = 'boolean';
        }
        if (id === 'Dec(10,5)') {
            name = 'decimal';
            size = 10;
            scale = 5;
        }

        const datatype = {
            type: name,
            size: size,
            scale: scale,
            signed: signed,
        };

        return datatype;
    }
}
