import React, { FC, useEffect, useState } from 'react';
import {  Position } from '@blueprintjs/core';
import { Popover2, Popover2InteractionKind } from '@blueprintjs/popover2';

import { RecordsTableRecordFieldView } from './RecordsTableRecordField';
import { Schema } from 'types';

export interface RecordsTableRecordFieldCellProps {
    records: object[];
    schema: Schema;
}

export const RecordsTableRecordFieldCell: FC<RecordsTableRecordFieldCellProps> = ({ records, schema }): JSX.Element => {
    const [rowStep, setRowStep] = useState<3 | 5 | 10 | 'all'>(3);
    const [colStep, setColStep] = useState<3 | 5 | 10 | 'all'>(3);
    const [shownRecords, setShownRecords] = useState<object[]>([]);
    const [modifiedSchema, setModifiedSchema] = useState<Schema>(schema);

    const getNextStep = (step: 3 | 5 | 10 | 'all') => {
        switch (step) {
            case 3:
                return 5;
            case 5:
                return 10;
            case 10:
                return 'all';
            case 'all':
                return 3;
        }
    }
    const nextRowStep = () => setRowStep(getNextStep(rowStep));
    const nextColStep = () => setColStep(getNextStep(colStep));

    const formatInlineRecord = (record) => {
        let orderedRecords = []
        schema.fields.forEach(({ field }) => {
            orderedRecords.push(record[field])
        });

        return orderedRecords.join(' | ');
    }

    const checkRecordsAreShort = (records: any) => {
        for (const record of records) {
            const isLong = Object.values(record).reduce((acc: number, current: string | number) => {
                const newAcc = (current ? current.toString().length : 0) + acc;
                if (newAcc > 35) {
                    return false;
                }
                return newAcc;
            }, 0);
    
            if (isLong === false) {
                return false;
            }
        }
        return true;
    }

    const cartesianRecords = () => {
        return <a href='#' onClick={e => e.preventDefault()} className="">{`${records.length} records`}</a>
    }

    useEffect(() => {
        if (records.length && rowStep !== 'all') {
            setShownRecords(records.slice(0, rowStep));
        } else if(records.length && rowStep === 'all') {
            setShownRecords(records);
        }


        if (records.length && colStep !== 'all') {
            const showFields = [ ...schema.fields ].slice(0, colStep);
            
            setModifiedSchema({ ...schema, fields: showFields });
        } else if(records.length && colStep === 'all') {
            setModifiedSchema(schema);
        }
    }, [records, schema, rowStep, colStep]);

    const renderColButton = () => {
        if (colStep !== 'all' && schema.fields.length < colStep) return null;
        if (colStep === 'all' && schema.fields.length > 10) return null;

        return (
            <button
                className="bp3-button bp3-small bp3-icon-chevron-right"
                onClick={nextColStep}
                title='Show more columns'
            />
        );
    }

    const renderRowButton = () => {
        if (rowStep !== 'all' && records.length < rowStep) return null;
        if (rowStep === 'all' && records.length > 10) return null;

        return (
            <button
                className="bp3-button bp3-small bp3-icon-chevron-down"
                onClick={nextRowStep}
                title="Show more rows"
            />
        )
    }

    return (
        <Popover2
            content={(
                <div className="popup-table-container">
                    <div className="flex-row flex items-center">
                        <div className="flex-column flex content-center flex-1 table-container">
                            <RecordsTableRecordFieldView
                                schema={modifiedSchema}
                                records={shownRecords}
                            />
                        </div>
                        {renderColButton()}
                    </div>
                    <div className="flex-row justify-center flex-1 pt-2">
                        {renderRowButton()}
                    </div>
                </div>
            )}
            position={Position.RIGHT}
            disabled={!records.length}
            interactionKind={Popover2InteractionKind.HOVER}
            hoverOpenDelay={80}
            hoverCloseDelay={80}>
            {records.length ? cartesianRecords() : <div>0 records</div>}
        </Popover2>
    );
}