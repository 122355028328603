import * as React from 'react';

import * as moment from 'moment';
require('./NotesTable.scss');

export interface NoteBoxProps {
    timestamp: string;
    username: string;
    note: string;
}

export const NoteBox: React.FC<NoteBoxProps> = ({timestamp, username, note}) => {
    return <div className="note-box">
        <div className="note-box-body">
            <div className="note-box-header">
                <div>{`${moment(new Date(timestamp)).fromNow()} by ${username}`}</div>
                <div></div>
            </div>
            <div className="note-box-content">
                {note}
            </div>
        </div>
    </div>;
};
