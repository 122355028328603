import {
    RECEIVE_APPROVE_TASK,
    RECEIVE_ASSIGNED_WORKFLOWS,
    RECEIVE_AVAILABLE_WORKFLOWS,
    RECEIVE_CLAIM_TASK,
    RECEIVE_COMPLETE_TASK,
    RECEIVE_CONTINUE_TASK,
    RECEIVE_DISCARD_ASSIGNED_TASK,
    RECEIVE_DISCARD_AVAILABLE_TASK,
    RECEIVE_REJECT_TASK,
    RECEIVE_RELEASE_TASK,
    RECEIVE_SAVE_TASK,
    RECEIVE_START_WORKFLOW,
    RECEIVE_STARTABLE_WORKFLOWS,
    CLEAR_WORKFLOWS,
    CLEAR_TASK_RESULT,
    RECEIVE_ABANDONED_TASK,
} from './types';

import { emptyTaskActionResult } from './constants';

const defaultState = {
    started: [],
    startable: [],

    assigned: [],
    available: [],
    taskActionResult: emptyTaskActionResult,
    lastUpdated: null
};

function removeRemoved(sourceWorkflows, workflowMatching) {
    const result = sourceWorkflows.filter((workitem: any) => {
        return !(workitem.database === workflowMatching.databaseName &&
            workitem.workitem_id.toString() === workflowMatching.workitemId.toString() &&
            workitem.node_id.toString() === workflowMatching.nodeId.toString());
    });
    return result;
}

function getWorkitemMatching(sourceWorkflows, workflowMatching) {
    return sourceWorkflows.find(workitem => workitem.database === workflowMatching.databaseName &&
        workitem.workitem_id.toString() === workflowMatching.workitemId.toString() &&
        workitem.node_id.toString() === workflowMatching.nodeId.toString());
}


export const workflowReducer = (state: any = defaultState, action) => {
    switch (action.type) {

        // This counts as a completed task.
        case RECEIVE_DISCARD_ASSIGNED_TASK:
            const assignedTasks = removeRemoved(state.assigned, action.payload.workflowMatching);
            return { ...state, assigned: assignedTasks, lastUpdated: Date.now(), taskActionResult: emptyTaskActionResult };

        // Remove from assigned, add to available.
        case RECEIVE_RELEASE_TASK: {
            const { workflowMatching } = action.payload;

            const newAvailableWorkitem = getWorkitemMatching(state.assigned, workflowMatching);
            const availableWorkitems = [...state.available, newAvailableWorkitem];

            const assignedWorkitems = removeRemoved(state.assigned, workflowMatching);

            return { ...state, assigned: assignedWorkitems, available: availableWorkitems, lastUpdated: Date.now() };
        }

        case RECEIVE_STARTABLE_WORKFLOWS:
            return { ...state, startable: action.payload.workflows };

        case RECEIVE_ASSIGNED_WORKFLOWS:
            return { ...state, assigned: action.payload.workflows };

        case RECEIVE_AVAILABLE_WORKFLOWS:
            return { ...state, available: action.payload.workflows };

        case RECEIVE_ABANDONED_TASK:
            return {
                ...state,
                available: [
                    ...state.available.filter((workitem: any) => {
                        console.log(workitem, action.payload.workitemId);
                        return workitem.workitem_id.toString() !== action.payload.workitemId.toString();
                    })
                ],
                assigned: [
                    ...state.assigned.filter((workitem: any) => {
                        console.log(workitem, action.payload.workitemId);
                        return workitem.workitem_id.toString() !== action.payload.workitemId.toString();
                    })
                ],
            };


        case RECEIVE_DISCARD_AVAILABLE_TASK:
            const availableWorkitems = removeRemoved(state.available, action.payload.workflowMatching);
            return { ...state, available: availableWorkitems, lastUpdated: Date.now() };

        case RECEIVE_CLAIM_TASK: {
            const { workflowMatching } = action.payload;

            const newAssignedWorkitem = getWorkitemMatching(state.available, workflowMatching);
            const assignedWorkitems = [...state.available, newAssignedWorkitem];

            const availableWorkitems = removeRemoved(state.available, workflowMatching);

            return { ...state, available: availableWorkitems, assigned: assignedWorkitems, lastUpdated: Date.now() };
        }

        case RECEIVE_START_WORKFLOW:
        case RECEIVE_CONTINUE_TASK:
        case RECEIVE_SAVE_TASK:
            return { ...state, taskActionResult: action.payload?.taskResult || emptyTaskActionResult, lastUpdated: Date.now() };

        // These three count as completed tasks.
        case RECEIVE_APPROVE_TASK:
        case RECEIVE_COMPLETE_TASK:
        case RECEIVE_REJECT_TASK:
            return { ...state, taskActionResult: action.payload?.taskResult || emptyTaskActionResult, lastUpdated: Date.now() };

        case CLEAR_TASK_RESULT: {
            return { ...state, taskActionResult: emptyTaskActionResult };
        }

        case CLEAR_WORKFLOWS: {
            return defaultState;
        }

        default:
            return state;
    }
};
