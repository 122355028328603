/**
 * @format
 * Created by nbamford on 1/26/2017.
 */

import { displayMessageBox } from 'actions/ActionCreators';
import { getWorkflow } from './selectors';
import { mdmErrorToText } from 'helpers';
import { Workflow, WorkflowAction } from 'types';
import { toast } from "react-toastify";

// Todo - remove unused constants from here AND constants
import {
    RECEIVE_APPROVE_TASK,
    REQUEST_APPROVE_TASK,
    RECEIVE_ASSIGNED_WORKFLOWS,
    RECEIVE_AVAILABLE_WORKFLOWS,
    RECEIVE_CLAIM_TASK,
    REQUEST_COMPLETE_TASK,
    RECEIVE_COMPLETE_TASK,
    RECEIVE_CONTINUE_TASK,
    RECEIVE_DISCARD_ASSIGNED_TASK,
    RECEIVE_DISCARD_AVAILABLE_TASK,
    RECEIVE_REJECT_TASK,
    RECEIVE_RELEASE_TASK,
    RECEIVE_SAVE_TASK,
    REQUEST_SUBMIT_MATCH_OVERRIDE,
    RECEIVE_STARTABLE_WORKFLOWS,
    RECEIVE_START_WORKFLOW,
    CLEAR_TASK_RESULT,
    RECEIVE_ABANDONED_TASK,
} from './types';
import { WorkflowApi } from 'api/WorkflowApi';
import { workflow } from '../../../__mocks__/shared/workflow';

export const approveTask = (workflow: Workflow, recordId: string, data: object, history: any, note?: string) => {
    return dispatch => {
        dispatch({ type: REQUEST_APPROVE_TASK });
        WorkflowApi.completeTask(workflow, WorkflowAction.approve, recordId, data, note).then(
            result => {
                toast.success(`Approved workitem ${workflow.workitem_id}`);
                dispatch({ type: RECEIVE_APPROVE_TASK, payload: { taskResult: result } });
                // If we have no continuation, go back to available
                if (!getWorkflow(result).workitem_id) history.push('/wf/available');
            },
            error => {
                dispatch(
                    displayMessageBox({
                        title: 'Approve Task Failed',
                        message: `Failed to approve Workitem ${workflow.workitem_id
                            }: ${mdmErrorToText(error)}`,
                        stack: error && error.stack,
                    }),
                );
                dispatch({ type: RECEIVE_APPROVE_TASK, payload: null });
            },
        );
    };
};


export const claimTaskAction = (taskResult, databaseName, workitemId, nodeId) => {
    return ({
        type: RECEIVE_CLAIM_TASK,
        payload: {
            workflowMatching: {
                databaseName,
                workitemId,
                nodeId
            },
            taskResult
        }
    });
}

export const claimTask = (database, workitemId, nodeId) => {
    return dispatch => {
        WorkflowApi.claimTask(database, workitemId, nodeId).then(
            result => {
                toast.success(`Claimed workitem ${workitemId}`);
                dispatch(claimTaskAction(result, database, workitemId, nodeId));
            },
            error =>
                dispatch(
                    displayMessageBox({
                        title: 'Claim Task Failed',
                        message: `Failed to claim Workitem ${workitemId}: ${mdmErrorToText(
                            error,
                        )}`,
                        stack: error && error.stack,
                    }),
                ),
        );
    };
};

export const completeMatchTask = (workflow: Workflow, action: WorkflowAction, matchGroups: string[][], note: string) => {
    return (dispatch) => {
        dispatch({ type: REQUEST_SUBMIT_MATCH_OVERRIDE });
        WorkflowApi.completeMatchTask(workflow, action, matchGroups, note).then(
            (result) => {
                toast.success(`Submitted match workitem ${workflow.workitem_id}`);
                dispatch({ type: RECEIVE_COMPLETE_TASK, payload: { taskResult: result } });
            },
            (error) => {
                dispatch(displayMessageBox({
                    title: "Complete Match Task Failed",
                    message: `Failed to submit match workitems: ${workflow.workitem_id} : ${mdmErrorToText(error)}`,
                    stack: error && error.stack,
                }));
                // this should not be dispatched, since the complete match failed
                // dispatch({ type: RECEIVE_COMPLETE_TASK, payload: null });
            }
        );
    };
};


export const completeTask = (workitem: Workflow, history: any, recordId?: string, data?: object, note?: string) => {
    return dispatch => {
        dispatch({ type: REQUEST_COMPLETE_TASK });
        WorkflowApi.completeTask(workitem, WorkflowAction.submit, recordId, data, note).then(
            result => {
                toast.success(`Submitted workitem ${workitem.workitem_id}`);
                dispatch({ type: RECEIVE_COMPLETE_TASK, payload: { taskResult: result } });
                // If we have no continuation, go back to available
                if (!getWorkflow(result).workitem_id) history.push('/wf/available');
            },
            error => {
                dispatch(
                    displayMessageBox({
                        title: 'Submit Task Failed',
                        message: `Failed to submit Workitem ${workitem.workitem_id
                            }: ${mdmErrorToText(error)}`,
                        stack: error && error.stack,
                    }),
                );
                dispatch({ type: RECEIVE_COMPLETE_TASK, payload: null });
            },
        );
    };
};

export const continueTaskAction = (taskResult) => {
    return ({
        type: RECEIVE_CONTINUE_TASK,
        payload: { taskResult },
    });
}

export const continueTask = (database, workitem_id, nodeId) => {
    return dispatch => {
        //dispatch({ type: REQUEST_CONTINUE_TASK });
        WorkflowApi.continueTask(database, workitem_id, nodeId).then(
            result => {
                dispatch({
                    type: RECEIVE_CONTINUE_TASK,
                    payload: { taskResult: result },
                });
            },
            error => {
                const message = mdmErrorToText(error);
                dispatch(
                    displayMessageBox({
                        title: 'Continue Task Failed',
                        message: `Failed to continue Workitem ${workitem_id}: ${message}`,
                        stack: error && error.stack,
                    })
                );
            },
        );
    };
};

export const setContinueTask = (taskResult: any) => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_CONTINUE_TASK,
            payload: { taskResult },
        });
    };
};


export const discardAssignedTask = (workitem: Workflow, note?: string) => {
    return dispatch => {
        //dispatch({ type: REQUEST_DISCARD_TASK });
        WorkflowApi.completeTask(workitem, WorkflowAction.discard, undefined, undefined, note).then(
            result => {
                toast.success(`Discarded workitem ${workitem.workitem_id}`);
                dispatch({
                    type: RECEIVE_DISCARD_ASSIGNED_TASK, payload: {
                        workflowMatching: {
                            databaseName: workitem.databaseName,
                            workitemId: workitem.workitem_id,
                            nodeId: workitem.node_id,
                        }
                    }
                });
                //history.push('/wf/available');
            },
            error => {
                dispatch(
                    displayMessageBox({
                        title: 'Discard Task Failed',
                        message: `Failed to discard Workitem ${workitem.workitem_id
                            }: ${mdmErrorToText(error)}`,
                        stack: error && error.stack,
                    }),
                );
                // dispatch({ type: RECEIVE_DISCARD_ASSIGNED_TASK, payload: { result: {} } });
            },
        );
    };
};

export const discardAvailableTask = (workitem: Workflow, note?: string) => {
    return dispatch => {
        //dispatch({ type: REQUEST_DISCARD_TASK });
        WorkflowApi.completeTask(workitem, WorkflowAction.discard, undefined, undefined, note).then(
            result => {
                toast.success(`Discarded workitem ${workitem.workitem_id}`);
                dispatch({
                    type: RECEIVE_DISCARD_AVAILABLE_TASK, payload: {
                        workflowMatching: {
                            databaseName: workitem.databaseName,
                            workitemId: workitem.workitem_id,
                            nodeId: workitem.node_id,
                        },
                        result
                    }
                });
                //history.push('/wf/available');
            },
            error => {
                dispatch(
                    displayMessageBox({
                        title: 'Discard Task Failed',
                        message: `Failed to discard Workitem ${workitem.workitem_id
                            }: ${mdmErrorToText(error)}`,
                        stack: error && error.stack,
                    }),
                );
                // dispatch({ type: RECEIVE_DISCARD_AVAILABLE_TASK, payload: { result: {} } });
            },
        );
    };
};

export const getAssignedWorkflowsAction = (workflows) => ({
    type: RECEIVE_ASSIGNED_WORKFLOWS,
    payload: { workflows }
});

// Thunk Action creators
export const fetchAssignedWorkflows = (database, userName) => {
    return (dispatch) => {
        if (!database) {
            dispatch({ type: RECEIVE_ASSIGNED_WORKFLOWS, payload: { workflows: [] } });
        }
        else {
            WorkflowApi.getAssignedWorkflows(database, userName).then(
                (workflows) =>
                    dispatch(getAssignedWorkflowsAction(workflows)),
                (error) => {
                    error = error.error || error;
                    dispatch(displayMessageBox({
                        title: "Fetch Workflows Failed",
                        message: `Failed to fetch workflows: ${mdmErrorToText(error)}`,
                        stack: error && error.stack,
                    }));
                }
            );
        }
    };
};

export const setAssignedWorkflows = (workflows: Workflow[]) => {
    return (dispatch) => {
        dispatch({ type: RECEIVE_ASSIGNED_WORKFLOWS, payload: { workflows } });
    };
};

export const fetchAvailableWorkflows = (database, userName) => {
    return (dispatch) => {
        if (!database)
            dispatch({ type: RECEIVE_AVAILABLE_WORKFLOWS, payload: { workflows: [] } });
        else
            WorkflowApi.getAvailableWorkflows(database, userName).then(
                (workflows) => dispatch({ type: RECEIVE_AVAILABLE_WORKFLOWS, payload: { workflows } }),
                (error) => {
                    error = (error && error.error) || error;
                    dispatch(displayMessageBox({
                        title: 'Cannot Fetch Workflows',
                        message: `Failed to fetch workflows: ${mdmErrorToText(error) || 'Unable to communicate with the database'}`,
                    }));
                }
            );
    };
};

export const setAvailableWorkflows = (workflows: Workflow[]) => {
    return (dispatch) => {
        dispatch({ type: RECEIVE_AVAILABLE_WORKFLOWS, payload: { workflows } });
    };
};

export const rejectTask = (workflow: Workflow, data: object, note?: string) => {
    return dispatch => {
        //dispatch({ type: REQUEST_REJECT_TASK });
        WorkflowApi.completeTask(workflow, WorkflowAction.reject, undefined, data, note).then(
            result => {
                toast.success(`Rejected workitem ${workflow.workitem_id}`);
                dispatch({ type: RECEIVE_REJECT_TASK, payload: { taskResult: result } });
            },
            error => {
                dispatch(
                    displayMessageBox({
                        title: 'Reject Task Failed',
                        message: `Failed to reject Workitem ${workflow.workitem_id
                            }: ${mdmErrorToText(error)}`,
                        stack: error && error.stack,
                    }),
                );
                dispatch({ type: RECEIVE_REJECT_TASK, payload: null });
            },
        );
    };
};

export const releaseTask = (database, workitemId, nodeId) => {
    return dispatch => {
        //dispatch({ type: REQUEST_RELEASE_TASK });
        WorkflowApi.releaseTask(database, workitemId, nodeId).then(
            result => {
                toast.success(`Released workitem ${workitemId}`);
                dispatch({
                    type: RECEIVE_RELEASE_TASK,
                    payload: { workflowMatching: { databaseName: database, workitemId, nodeId } },
                });
            },
            error =>
                dispatch(
                    displayMessageBox({
                        title: 'Release Task Failed',
                        message: `Failed to release Workitem ${workitemId}: ${mdmErrorToText(
                            error,
                        )}`,
                        stack: error && error.stack,
                    }),
                ),
        );
    };
};

export const saveTask = (workitem: Workflow, recordId: string, data: object, note?: string) => {
    return dispatch => {
        //dispatch({ type: REQUEST_SAVE_TASK });
        WorkflowApi.completeTask(workitem, WorkflowAction.save, recordId, data, note).then(
            result => {
                toast.success(`Saved workitem ${workitem.workitem_id}`);
                dispatch({ type: RECEIVE_SAVE_TASK, payload: { taskResult: result } });
            },
            error => {
                dispatch(
                    displayMessageBox({
                        title: 'Save Task Failed',
                        message: `Failed to save Workitem ${workitem.workitem_id}: ${mdmErrorToText(
                            error,
                        )}`,
                        stack: error && error.stack,
                    }),
                );
                dispatch({ type: RECEIVE_SAVE_TASK, payload: null });
            },
        );
    };
};

export const clearTaskResult = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_TASK_RESULT });
    };
};

export const abandonTask = (database: string, workitemId: number) => {
    return dispatch => {
        WorkflowApi.abandonTask(database, workitemId).then(
            result => {
                toast.success(`Removed workitem ${workitemId}`);
                dispatch({ type: RECEIVE_ABANDONED_TASK, payload: { workitemId } });
            },
            error => {
                dispatch(
                    displayMessageBox({
                        title: 'Release Task Failed',
                        message: `Failed to abandon Workitem ${workitemId}: ${mdmErrorToText(
                            error,
                        )}`,
                        stack: error && error.stack,
                    }),
                );
            },
        );
    };
}

//////////////////////////////////////

export const fetchStartableWorkflows = (database, userName) => {
    return (dispatch) => {
        //dispatch({ type: REQUEST_STARTABLE_WORKFLOWS });
        if (!database)
            dispatch({ type: RECEIVE_STARTABLE_WORKFLOWS, payload: { workflows: [] } });
        else
            WorkflowApi.getStartableWorkflows(database, userName).then(
                (workflows: any[]) => {
                    const wfs = workflows.filter(wf => wf.ui_visible);
                    dispatch({ type: RECEIVE_STARTABLE_WORKFLOWS, payload: { workflows: wfs } });
                },
                (error) => {
                    error = error.error || error;
                    dispatch(displayMessageBox({
                        title: "Fetch Workflows Failed",
                        message: `Failed to fetch startable workflows: ${mdmErrorToText(error)}`,
                        stack: error && error.stack,
                    }));
                }
            );
    };
};

export const startWorkflow = (database, workitem_id) => {
    //console.log("workitem_id: ", workitem_id);
    return (dispatch) => {
        //dispatch({ type: REQUEST_START_WORKFLOW });
        WorkflowApi.startWorkflow(database, workitem_id).then(
            (result) => dispatch({ type: RECEIVE_START_WORKFLOW, payload: { taskResult: result } }),
            (error) => dispatch(displayMessageBox({
                title: "Start Workflow Failed",
                message: `Failed to start workflows: ${mdmErrorToText(error)}`,
                stack: error && error.stack,
            }))
        );
    };
};
