import * as React from 'react';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { RecordsTableRecordFieldCell } from 'components/RecordsTable/RecordsTableRecordFieldCell';
import { RecordsTableRecordFieldView } from 'components/RecordsTable/RecordsTableRecordField';

require('../WorkflowMatch.scss');

import { getMatchGroupColorClass, GroupType } from '../WorkflowMatchConstants';
import { MDMRecord, Schema } from 'types';
import { displayField, sortRecordFields } from 'utils';
import { formatDateTimeDisplay } from 'helpers/date-format';
import { createColumnHelper } from '@tanstack/react-table';

export interface WorkflowMatchTableBaseProps {
    uniqueId?: string;
    hiddenFields: string[];
    records: MDMRecord[];
    schema: Schema;
}
export interface WorkflowMatchTableProps extends WorkflowMatchTableBaseProps {
    customTableProps?: object;
    groupColumns?: any[];
    groupType?: GroupType;
    onTableSortChange?: (type: GroupType) => void;
    showSubTables?: boolean;
    rowsDraggable?: boolean;
    defaultPageSize?: number;
}

export const WorkflowMatchTable: React.FC<WorkflowMatchTableProps> = (props) => {
    const { defaultPageSize, groupColumns, groupType = GroupType.CUSTOM, showSubTables, records, schema, customTableProps, onTableSortChange, rowsDraggable, uniqueId } = props;

    const getTrProps = (_state, rowinfo) => {
        return { rowinfo };
    };

    const getColumns = (schema) => {
        const colHelper = createColumnHelper();
        let fields = (groupColumns || []);
        // non-table fields in schema order
        schema && schema.fields
            .filter(f => f.datatype.type !== 'table' && f.display)
            .forEach((f, index) => {
                const customClass = index === 0 ? 'workflow-match-sizing-handles-first' : 'workflow-match-sizing-handles';
                fields.push(
                    colHelper.accessor(f.field, {
                        header: f.field,
                        // className: 'workflow-match-table-td',
                        enableSorting: true,
                        cell: ({ cell }) => {
                            let value = cell.getValue();

                            value = formatDateTimeDisplay(value, f)

                            return (
                                <div className={`'workflow-match-table-td' ${customClass}`}>
                                    {value}
                                </div>
                            );
                        }}),
                );
            });

        // table fields
        schema && schema.fields
            .filter(f => f.datatype.type === 'table' && displayField(f))
            .forEach((f, index) => {
                const customClass = index === 0 ? 'workflow-match-sizing-handles-first' : 'workflow-match-sizing-handles';
                console.log('f', f);
                fields.push(
                    //colHelper.display({
                    colHelper.accessor(f.field, {
                        header: f.field,
                        id: f.field,
                        // className: 'workflow-match-table-td',
                        enableSorting: true,
                        sortingFn: sortRecordFields,
                        cell: ({ cell, row }) => {
                            return (
                                <div className={customClass}>
                                    <RecordsTableRecordFieldCell records={row.original[f.field] || []} schema={f.schema} />
                                </div>
                            );
                        }}));
            });

        if (fields.length > 1) {
            const last: any = fields.pop();
            fields.push(last);
        }

        console.log('fields', fields);

        return fields;
    }

    const getSubTables = (schema, parentRecord) => {
        const subtables = schema?.fields
            .filter(f => f.datatype.type === 'table' && displayField(f))
            .map((field, index) => {
                const subRecords = parentRecord[field.field] || [];
                const subSchema = field.schema;

                if (!subRecords.length) {
                    return null;
                }

                return <div key={`${parentRecord?._id}-${index}`} className="subtable-wrapper">
                    <span className="bp5-text-muted text-heavy">Nested Table: {field.field} </span>
                    <RecordsTableRecordFieldView schema={subSchema} records={subRecords} />
                </div>
            });

            return subtables;
    }

    const onSortedChange = (newSorted, column, shiftKey) => {
        const groupType = column.id as keyof GroupType;
        onTableSortChange && onTableSortChange(GroupType[groupType]);
    };

    return (
        <ReactTable
            defaultPageSize={defaultPageSize}
            idField={schema?.primary_key}
            rowsDraggable={rowsDraggable}
            className="workflow-match-table workflow-match-table-scroll-content"
            columns={getColumns(schema)}
            data={records}
            defaultSorted={[{ id: GroupType[groupType], desc: true }]}
            // getTheadProps={() => ({ className: 'workflow-match-table-header' })}
            // getTheadThProps={() => ({ className: 'workflow-match-table-header-th' })}
            // getTrProps={getTrProps}
            // getResizerProps={(state, rowInfo, column, instance) => ({
            //     className: 'workflow-match-table-resizer'
            // })}
            // minRows={0}
            showPagination={false}
            // onSortedChange={onSortedChange}
            SubComponent={
                showSubTables ?
                    ({row}) => {
                        return (
                            <div className="wf-match-subtables-container">
                                {getSubTables(schema, row.original)}
                            </div>
                        )
                    } : null
            }
        />
    );
};

export const WorkflowMatchTableGroup: React.FC<WorkflowMatchTableProps> = props => {
    const colHelper = createColumnHelper<any>();
    const customGroups = props.records.reduce((acc, cur) => acc.includes(cur._group) ? acc : [...acc, cur._group], []);
    return <WorkflowMatchTable {...props} groupColumns={[
        colHelper.accessor('_group', {
            header: 'Group',
            // className: 'workflow-match-table-td-group',
            // headerClassName: 'workflow-match-table-th-group',
            size: 100,
            id: GroupType[GroupType.CUSTOM].toString(),
            cell: ({ row }) => <div className={`workflow-match-group-cell ${getMatchGroupColorClass(customGroups.indexOf(row.original._group) + 1)}`} />,
            enableResizing: true
        }),
    ]}
    />;
};

export const WorkflowMatchTableGroups: React.FC<WorkflowMatchTableProps> = props => {
    const colHelper = createColumnHelper<any>();

    const customGroups = props.records.reduce((acc, cur) => {
        const group = cur._group[GroupType.CUSTOM];
        return acc.includes(group) ? acc : [...acc, group];
    }, []);
    const getColumn = (type: GroupType, header: string) =>
    (colHelper.display({
        header,
        // className: 'workflow-match-table-td-group',
        // headerClassName: 'workflow-match-table-th-group',
        id: GroupType[type].toString(),
        enableResizing: true,
        cell: ({ row }) => (
            <div className={`workflow-match-group-cell ${getMatchGroupColorClass(type === GroupType.CUSTOM ?
            customGroups.indexOf(row.original._group[type]) + 1 : row.original._group[type])}`} />
        )
    }));
    const getColumns = () =>
        [
            getColumn(GroupType.ACCEPTED, 'ACCEPTED'),
            getColumn(GroupType.PROPOSED, 'PROPOSED'),
            getColumn(GroupType.CUSTOM, 'CUSTOM')
        ];

    return <WorkflowMatchTable {...props} groupColumns={getColumns()} />;
};