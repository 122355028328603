import * as React from 'react';
import { connect } from 'react-redux';
import { Classes, Dialog, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from '../Shared';
import { DatabaseApi } from '../../api'
import { showToaster } from '../../actions';
import { Intent } from '../../types';

export interface DialogEditDatabaseProps {
    currentEditDb?: { name: string, description: string };
    showToaster: (i: Intent, m: string) => void;
    closeDialog: () => void;
    queryDatabases: () => void;
    onChange: (key: string, value: string) => void;
}

export class DatabaseEditDialogBase extends React.Component<DialogEditDatabaseProps> {

    updateDatabase = () => {
        const { closeDialog, queryDatabases, currentEditDb } = this.props;
        DatabaseApi.update(currentEditDb.name, currentEditDb.description).then(() => {
            queryDatabases();
            closeDialog();
        }).catch(err => {
            console.error(err);
            showToaster(Intent.ERROR, "Error updating database.")
        })
    }

    render() {
        const { closeDialog, currentEditDb, onChange } = this.props;

        return (
            <Dialog isOpen={!!currentEditDb} icon={IconNames.EDIT} title={`Edit Database`} onClose={closeDialog}>
                <div className={Classes.DIALOG_BODY}>
                    {currentEditDb && <FormGroup label="Description" labelFor="descinput">
                        <input
                            id="descinput"
                            className={`${Classes.INPUT} ${Classes.FILL}`}
                            name="dbDesc"
                            onChange={(e) => { onChange("description", e.target.value) }}
                            value={currentEditDb.description}
                        />
                    </FormGroup>}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={this.updateDatabase} value="Submit" />
                        <Button onClick={closeDialog} value="Cancel" icon={IconNames.CROSS} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const DatabaseEditDialog: any = connect(
    (state: any) => ({}),
    { showToaster }
)(DatabaseEditDialogBase);