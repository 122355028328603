
import * as React from 'react';
import { RecordHistoryEntry } from './RecordHistoryEntry';

const RecordHistoryEntriesDay: React.FC<any> = ({ primaryKey, date, historyEntries, currentTable }) => (
    <div className="history-entries">
        <div style={{ display: 'block' }} className="history-date">
            {date}
        </div>
        {historyEntries.map((history, index) => (
            <RecordHistoryEntry
                key={`history-entry-${index}`}
                id={index}
                primaryKey={primaryKey}
                currentTable={currentTable}
                {...history.historyEntry}
            />
        ))}
    </div>
);

export default RecordHistoryEntriesDay;