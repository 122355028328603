// For Sorting Due Dates, so that the most overdue items (oldest) appear first, and nulls at the bottom when in ASCENDING sort.
export const sortDatesNullsFirst = (a, b) => {
    if (a === null || a === undefined || a === '') {
        return 1;
    }

    if (b === null || b === undefined || b === '') {
        return -1;
    }

    const a1 = new Date(a).getTime();
    const b1 = new Date(b).getTime();

    if (a1 > b1)
        return 1;
    else if (a1 < b1)
        return -1;
    else
        return 0;
};

export const sortTimestamp = (a, b) => Date.parse(a) - Date.parse(b);