import * as React from 'react';
import { Button, FormGroup, HTMLSelect, Classes, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';


export interface PermNewGroupProps {
    availableGroupNames: string[];
    doneFn: (name: string) => void;
    isOpen: boolean;
}

interface PermNewGroupState {
    name: string;
}

export class PermNewGroupDialog extends React.Component<PermNewGroupProps, PermNewGroupState> {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.availableGroupNames[0],
        };
    }

    onCancelClick = () => {
        this.props.doneFn(null);
    }

    onOkClick = () => {
        this.props.doneFn(this.state.name);
    }

    onNameChange = (name:string) => {
        if(name) {
            this.setState({name: name});
        }
    }

    render() {
        return (
            <Dialog
                icon={IconNames.CONFIRM}
                isOpen={this.props.isOpen}
                onClose={this.onCancelClick}
                title={`Create Permission Group`}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup label="Enter the name of the new group">
                        <HTMLSelect
                            defaultValue={this.state.name}
                            fill={true}
                            onChange={ (e) => this.onNameChange(e.target.value) }
                            options={this.props.availableGroupNames}
                        />
                    </FormGroup>
                 </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent={Intent.PRIMARY}
                            onClick={ () => {this.onOkClick();} }
                            text="Create" />
                        <Button intent={Intent.DANGER} onClick={ () => {this.onCancelClick();} } text="Cancel" />
                    </div>
                </div>
            </Dialog>);
    }
}