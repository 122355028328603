import { AppThunk } from '../MDMStore';
import { DatabaseApi } from 'api';
import { SET_LICENSE_INFO, LicenseInfo } from './types';
import { AuthConfig } from 'store/auth/types';

// TODO: TYPE licenseInfo
export const setLicenseInfo = (licenseInfo: LicenseInfo): AppThunk => {
    return dispatch => {
        dispatch({ type: SET_LICENSE_INFO, payload: { licenseInfo } });
    };
};
