/**
 * @format
 */
import * as moment from 'moment';
import * as React from 'react';
import {
    Checkbox, Classes, Dialog, FormGroup, HTMLSelect,
    IDialogProps, IOptionProps, Label, NumericInput
} from '@blueprintjs/core';
import { TimePicker, TimePrecision } from '@blueprintjs/datetime';

import { Button, Checklist } from 'components/Shared';

import {
    Subscription,
    SubscriptionDeliveryType,
    SubscriptionDeliveryDescription,
} from 'types';

export interface SubscriptionDialogProps {
    enabled: boolean;
    isOpen: boolean;
    dialogTitle: string;
    subscription: Subscription;
    onSubscriptionChange: (key: string, value: any) => void;
    onEnableChange: (value: boolean) => void;
    onClose?: (e?: React.SyntheticEvent<any>) => void;
    onCancel?: (e?: React.SyntheticEvent<HTMLButtonElement | MouseEvent>) => void;
    onSave?: (edit: boolean) => void;
}

const getDateFromTimeString = (timeStr: string): Date => {
    const d = new Date();
    const parts = timeStr.match(/(\d+):(\d+):(\d+)/);
    if (parts && parts.length === 4) {
        d.setHours(parseInt(parts[1], 10), parseInt(parts[2], 10), parseInt(parts[3], 10));
    }
    return d;
};
const getTimeStringFromDate = (date: Date): string => {
    const hhmmss = moment(date);
    return hhmmss.format("HH:mm:ss");
};

// Dialog is view only if the subscription is enabled
export const SubscriptionDialog: React.FC<IDialogProps & SubscriptionDialogProps> = (
    props: IDialogProps & SubscriptionDialogProps,
) => {
    const isEdit: boolean = props.subscription.subscription_id !== undefined;
    const enabled = isEdit ? props.subscription.state.enabled : false;
    const enable = isEdit ? (<div className="view-edit-title text-small" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>SUBSCRIPTION IS {
        enabled ? "ENABLED" : "DISABLED"
    }</div>) :
        (
            <Checkbox
                checked={props.enabled}
                className="view-edit-title"
                id="enablecheck"
                label="ENABLED"
                onChange={e => props.onEnableChange((e.target as any).checked)}
            />
        );
    const disableReasonDiv = !isEdit || enabled ? null : (
        <div className="view-disable-reason text-small" style={{ paddingTop: '0', paddingBottom: '1rem' }}>Reason: {props.subscription.state.disabled_reason}</div>
    );
    const doneButtons = enabled ?
        <div className="view-title-buttons">
            <Button id="save-subscription-btn" onClick={props.onCancel} value="DONE" />
        </div>
        :
        <div className="view-title-buttons">
            <Button className="edit-view-button" onClick={props.onCancel} value="CANCEL" />
            <Button id="save-subscription-btn" onClick={e => props.onSave(isEdit)} value="DONE" />
        </div>;
    // Show file dialog if you are type file
    // add format pulldown when user can choose something other then CSV
    let options = null;

    const getDeliveryOptions = (): (string | number | IOptionProps)[] => {

        let deliveryOptions = [
            {
                value: SubscriptionDeliveryType.webservice,
                label: SubscriptionDeliveryDescription[
                    SubscriptionDeliveryType.webservice
                ]
            },
            {
                value: SubscriptionDeliveryType.file,
                label: SubscriptionDeliveryDescription[
                    SubscriptionDeliveryType.file
                ]
            },
            {
                value: SubscriptionDeliveryType.azurequeue,
                label: SubscriptionDeliveryDescription[
                    SubscriptionDeliveryType.azurequeue
                ]
            },
            {
                value: SubscriptionDeliveryType.azuretopic,
                label: SubscriptionDeliveryDescription[
                    SubscriptionDeliveryType.azuretopic
                ]
            },
        ];

        if (process.env.SUBSCRIPTION_MOCK) {
            deliveryOptions = [
                ...deliveryOptions,
                {
                    value: SubscriptionDeliveryType.mock,
                    label: SubscriptionDeliveryDescription[
                        SubscriptionDeliveryType.mock
                    ]
                },
                {
                    value: SubscriptionDeliveryType.countmock,
                    label: SubscriptionDeliveryDescription[
                        SubscriptionDeliveryType.countmock
                    ]
                }

            ];
        }

        return deliveryOptions;
    }

    if (props.subscription.delivery.type === SubscriptionDeliveryType.file) {
        // may have null bits in file
        options = (<div>
            <FormGroup
                className="file-option-title"
                label="TARGET FOLDER"
                labelFor="subscription-file-target-txt"
            >
                <input
                    onChange={e =>
                        props.onSubscriptionChange('delivery.file.target_folder', e.target.value)
                    }
                    id="subscription-file-target-txt"
                    value={props.subscription.delivery.file && props.subscription.delivery.file.target_folder || ''}
                    type="text"
                    className={`${Classes.INPUT} ${Classes.FILL} ${Classes.LARGE}`}
                    disabled={enabled}
                />
            </FormGroup>
            <FormGroup
                className="file-option-title"
                label="FILE PATTERN"
                labelFor="subscription-file-pattern-txt"
            >
                <input
                    onChange={e => props.onSubscriptionChange('delivery.file.pattern', e.target.value || 'data.csv')}
                    id="subscription-file-pattern-txt"
                    value={props.subscription.delivery.file && props.subscription.delivery.file.pattern}
                    type="text"
                    className={`${Classes.INPUT} ${Classes.FILL} ${Classes.LARGE}`}
                    disabled={enabled}
                />
            </FormGroup>
            <FormGroup
                className="file-option-title"
                label="WRITE FREQUENCY (Minutes)"
                labelFor="subscription-file-frequency-txt"
            >
                <NumericInput
                    allowNumericCharactersOnly={true}
                    id="file-write-frequency"
                    large={true}
                    min={1}
                    max={1440}
                    placeholder={'File write frequency (minutes)'}
                    value={props.subscription.delivery.file && props.subscription.delivery.file.frequency || 60}
                    onValueChange={props.onSubscriptionChange.bind(
                        null,
                        'delivery.file.frequency',
                    )}
                    disabled={enabled}
                />
                <Checkbox
                    checked={props.subscription.delivery.file && props.subscription.delivery.file.with_csv_header}
                    id="write-header"
                    label="WRITE A HEADER ROW"
                    onChange={e =>
                        props.onSubscriptionChange(
                            'delivery.file.with_csv_header',
                            (e.target as any).checked,
                        )
                    }
                    disabled={enabled}
                />
            </FormGroup>
        </div>);
    }
    else if (props.subscription.delivery.type === SubscriptionDeliveryType.mock ||
        props.subscription.delivery.type === SubscriptionDeliveryType.webservice) {
        options = <FormGroup
            className="view-edit-title"
            label="WEB SERVICE URL"
            labelFor="webserviceinput">
            <input
                onChange={e =>
                    props.onSubscriptionChange('delivery.webservice.url', e.target.value)
                }
                id="subscription-weburl-txt"
                value={props.subscription.delivery.webservice && props.subscription.delivery.webservice.url || ''}
                type="text"
                className={`${Classes.INPUT} ${Classes.FILL} ${Classes.LARGE}`}
                disabled={enabled}
            />
        </FormGroup>;
    }
    else if (props.subscription.delivery.type === SubscriptionDeliveryType.azurequeue) {
        options = (<div>
            <FormGroup
                className="azure-connstr-title"
                label="CONNECTION STRING"
                labelFor="azure-connstr-txt"
            >
                <input
                    onChange={e =>
                        props.onSubscriptionChange('delivery.azure.connection_string', e.target.value)
                    }
                    id="azure-connstr-txt"
                    value={props.subscription.delivery.azure && props.subscription.delivery.azure.connection_string || ''}
                    type="text"
                    className={`${Classes.INPUT} ${Classes.FILL} ${Classes.LARGE}`}
                    disabled={enabled}
                />
            </FormGroup>
            <FormGroup
                className="azure-queue-title"
                label="QUEUENAME"
                labelFor="azure-queue-txt"
            >
                <input
                    onChange={e => props.onSubscriptionChange('delivery.azure.queue', e.target.value || 'myqueue')}
                    id="azure-queue-txt"
                    value={props.subscription.delivery.azure && props.subscription.delivery.azure.queue}
                    type="text"
                    className={`${Classes.INPUT} ${Classes.FILL} ${Classes.LARGE}`}
                    disabled={enabled}
                />
            </FormGroup>
        </div>);
    }
    else if (props.subscription.delivery.type === SubscriptionDeliveryType.azuretopic) {
        options = (<div>
            <FormGroup
                className="azure-connstr-title"
                label="CONNECTION STRING"
                labelFor="azure-connstr-txt"
            >
                <input
                    onChange={e =>
                        props.onSubscriptionChange('delivery.azure.connection_string', e.target.value)
                    }
                    id="azure-connstr-txt"
                    value={props.subscription.delivery.azure && props.subscription.delivery.azure.connection_string || ''}
                    type="text"
                    className={`${Classes.INPUT} ${Classes.FILL} ${Classes.LARGE}`}
                    disabled={enabled}
                />
            </FormGroup>
            <FormGroup
                className="azure-topic-title"
                label="TOPICNAME"
                labelFor="azure-topic-txt"
            >
                <input
                    onChange={e => props.onSubscriptionChange('delivery.azure.topic', e.target.value || 'mytopic')}
                    id="azure-topic-txt"
                    value={props.subscription.delivery.azure && props.subscription.delivery.azure.topic}
                    type="text"
                    className={`${Classes.INPUT} ${Classes.FILL} ${Classes.LARGE}`}
                    disabled={enabled}
                />
            </FormGroup>
        </div>);
    }

    return (
        <Dialog
            title={props.dialogTitle}
            isOpen={props.isOpen}
            onClose={props.onClose}
            style={{ backgroundColor: 'white', borderRadius: '0px' }}
        >
            {
                props.subscription &&
                <>
                    <div className={Classes.DIALOG_BODY} style={{ padding: '1rem' }}>
                        <div className="subscription-dialog-description">
                            <input
                                type="text"
                                id="subscription-description-txt"
                                placeholder={'Enter description'}
                                style={{ border: 'none', outline: 'none' }}
                                value={props.subscription.description}
                                onChange={e => props.onSubscriptionChange('description', e.target.value)}
                                disabled={enabled}
                            />
                        </div>
                        <div className={'subscription-dialog-divider'} />
                        {enable}
                        {disableReasonDiv}
                        <FormGroup
                            className="view-edit-title"
                            label="DELIVERY TYPE"
                            labelFor="deliveryselect"
                        >
                            <HTMLSelect
                                fill={true}
                                large={true}
                                value={props.subscription.delivery.type}
                                onChange={e =>
                                    props.onSubscriptionChange('delivery.type', e.target.value)
                                }
                                disabled={enabled}
                                options={getDeliveryOptions()}
                            />
                        </FormGroup>
                        {options}
                        <FormGroup
                            className="view-edit-title"
                            label="RETRY ON DELIVERY FAILURE START/MAX INTERVAL(SECONDS)"
                            labelFor="retryinput"
                        >
                            <div className="subscription-numeric-container">
                                <NumericInput
                                    allowNumericCharactersOnly={false}
                                    className="subscription-numeric"
                                    fill={true}
                                    large={true}
                                    value={props.subscription.delivery.failure_strategy.retry_seconds_start}
                                    onValueChange={value =>
                                        props.onSubscriptionChange(
                                            'delivery.failure_strategy.retry_seconds_start',
                                            value,
                                        )
                                    }
                                    disabled={enabled}
                                />
                                <NumericInput
                                    allowNumericCharactersOnly={true}
                                    fill={true}
                                    large={true}
                                    value={props.subscription.delivery.failure_strategy.retry_seconds_max}
                                    onValueChange={value =>
                                        props.onSubscriptionChange(
                                            'delivery.failure_strategy.retry_seconds_max',
                                            value,
                                        )
                                    }
                                    disabled={enabled}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup
                            className="view-edit-title"
                            label="AGE OUT WINDOW START/END TIMES"
                            labelFor="ageoutinput"
                        >
                            <div className="subscription-numeric-container">
                                <TimePicker
                                    precision={TimePrecision.SECOND}
                                    value={getDateFromTimeString(
                                        props.subscription.delivery.age_out.window_start,
                                    )}
                                    maxTime={getDateFromTimeString('23:59:59')}
                                    minTime={getDateFromTimeString('00:00:00')}
                                    onChange={(newTime: Date) =>
                                        props.onSubscriptionChange(
                                            'delivery.age_out.window_start',
                                            getTimeStringFromDate(newTime),
                                        )
                                    }
                                    disabled={enabled}
                                />
                                <TimePicker
                                    precision={TimePrecision.SECOND}
                                    value={getDateFromTimeString(
                                        props.subscription.delivery.age_out.window_end,
                                    )}
                                    onChange={(newTime: Date) =>
                                        props.onSubscriptionChange(
                                            'delivery.age_out.window_end',
                                            getTimeStringFromDate(newTime),
                                        )
                                    }
                                    disabled={enabled}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup
                            className="view-edit-title"
                            label="MAX CHANGES PER MESSAGE"
                            labelFor="changesinput"
                        >
                            <NumericInput
                                allowNumericCharactersOnly={true}
                                id="changesinput"
                                large={true}
                                min={2}
                                max={60}
                                value={props.subscription.delivery.max_changes}
                                onValueChange={props.onSubscriptionChange.bind(
                                    null,
                                    'delivery.max_changes',
                                )}
                                disabled={enabled}
                            />
                        </FormGroup>
                        <FormGroup
                            className="view-edit-title"
                            contentClassName="view-edit-title"
                            label="NOTIFICATION OPTIONS"
                        >
                            <Checkbox
                                checked={props.subscription.options.delete_full_record}
                                id="deletecheck"
                                label="RETURN FULL RECORD ON DELETE"
                                onChange={e =>
                                    props.onSubscriptionChange(
                                        'options.delete_full_record',
                                        (e.target as any).checked,
                                    )
                                }
                                disabled={enabled}
                            />
                            <Checkbox
                                checked={props.subscription.options.modify_old_record}
                                id="modifycheck"
                                label="RETURN OLD RECORD ON MODIFY"
                                onChange={e =>
                                    props.onSubscriptionChange(
                                        'options.modify_old_record',
                                        (e.target as any).checked,
                                    )
                                }
                                disabled={enabled}
                            />
                            <Checkbox
                                checked={props.subscription.options.modify_new_record}
                                id="newcheck"
                                label="RETURN NEW RECORD ON MODIFY"
                                onChange={e =>
                                    props.onSubscriptionChange(
                                        'options.modify_new_record',
                                        (e.target as any).checked,
                                    )
                                }
                                disabled={enabled}
                            />
                            <Checkbox
                                checked={props.subscription.options.modify_diff}
                                id="diffcheck"
                                label="RETURN DIFF ON MODIFY"
                                onChange={e =>
                                    props.onSubscriptionChange('options.modify_diff', (e.target as any).checked)
                                }
                                disabled={enabled}
                            />
                        </FormGroup>
                    </div>
                    <div className="subscription-dialog-footer">
                        {doneButtons}
                    </div>
                </>

            }
        </Dialog>
    );
};
