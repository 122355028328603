/**
 * Created by nbamford on 1/29/2017.
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { claimTask, discardAvailableTask as discardTask, fetchAvailableWorkflows } from 'store/workflow/actions';
import { MDMWrapper } from 'components/Shared';
import { Workflow, Workitem } from 'types';
import WorkflowAvailableTable from './WorkflowAvailableTable';
import { WorkitemEditModal } from '../WorkitemEditModal/WorkitemEditModal';

export interface WorkflowAvailableContainerProps {
    canEditWorkitems: boolean;
    available: Workitem[];
    claimTask: (dbName: string, workItemId: string, taskID: string) => void;
    discardTask: (workitem: Workflow, notes?: string) => void;
    dbName: string;
    fetchAvailableWorkflows: (dbName: string, username: string) => void;
    username: string;
}

export interface WorkflowAvailableContainerState {
    currentEditWorkitem: Workitem;
}

class WorkflowAvailableContainerBase extends React.Component<WorkflowAvailableContainerProps, WorkflowAvailableContainerState> {
    constructor(props) {
        super(props);
        this.state = {
            currentEditWorkitem: null
        };
    }

    loadData = () => {
        const { dbName, username } = this.props;
        dbName && this.props.fetchAvailableWorkflows(dbName, username);
    }

    componentDidUpdate(prevProps) {
        const { dbName } = this.props;
        if (prevProps.dbName !== dbName)
            this.loadData();
    }

    claimTask = (workitem_id, nodeId) => {
        this.props.claimTask(this.props.dbName, workitem_id, nodeId);
    }

    discardTask = (workitem_id, nodeId) => {
        const workitem: Workflow = {
            changesetId: null,
            continue: false,
            databaseName: this.props.dbName,
            formId: null,
            node_id: nodeId,
            schema: null,
            tableName: null,
            workitem_id: workitem_id,
            variables: {}
        }
        this.props.discardTask(workitem);
    }

    editWorkitem = (workitem: Workitem) => this.setState({ currentEditWorkitem: workitem })

    render() {
        const { currentEditWorkitem } = this.state;
        return (
            <div>
                <MDMWrapper title="Select a Workitem to Claim" documentationPath="workflow_console.html">

                    <WorkitemEditModal 
                        isOpen={!!currentEditWorkitem} 
                        workitem={currentEditWorkitem} 
                        onClose={() => this.setState({currentEditWorkitem: null})}
                    />

                    <WorkflowAvailableTable
                        canEditWorkitems={this.props.canEditWorkitems}
                        workflows={this.props.available}
                        claimTask={this.claimTask}
                        discardTask={this.discardTask}
                        editTask={this.editWorkitem}
                    />
                </MDMWrapper>
            </div>
        );
    }
}

export const WorkflowAvailableContainer = connect(
    (state: any) => ({
        canEditWorkitems: state.auth.canEditWorkitems,
        available: state.workflow.available,
        dbName: state.database.selected,
        username: state.username,
    }), {
    fetchAvailableWorkflows,
    claimTask,
    discardTask
})(WorkflowAvailableContainerBase as any);
