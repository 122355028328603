import * as React from 'react';
import { useState } from 'react';
import { EventItem, Pagination } from 'rp-mdm-common';
import { useQueryClient } from '@tanstack/react-query';
import { AuditFilter, EventFilter, useAuditQuery, useEventQuery } from 'api';
import { MDMWrapper } from 'components/Shared';
import LogsTable from './LogsTable';
import LogFilterForm from './LogFilterForm';
import useDebounce from '../../utils/useDebounce';

const LogScreen = () => {
    const [queryType, setQueryType] = useState<'audit' | 'event'>('audit')
    const [filters, setFilters] = useState<AuditFilter | EventFilter>({ timestamp_min: Date.now() - 1000 * 60 * 60 * 24 * 7 });
    const debouncedFilters = useDebounce(filters)
    const [pagination, setPagination] = useState<Pagination>();
    const queryClient = useQueryClient();
    const { data: audits } = useAuditQuery('audit-query-table', { filters: debouncedFilters, pagination, type: queryType }, {
        initialData: [],
        enabled: queryType === 'audit'
    });

    const { data: events } = useEventQuery('event-query-table', { filters: debouncedFilters, pagination, type: queryType }, {
        initialData: [],
        enabled: queryType === 'event'
    });

    const onPaginate = (newPagination?: Pagination ) => setPagination(newPagination);

    const onFilterChange = (newFilters: Partial<AuditFilter | EventFilter>) => {
        setFilters(oldFilters => ({ ...oldFilters, ...newFilters }));
    }
    
    const onTypeChange = (newType: 'audit' | 'event') => {
        if (newType === 'audit') {
            setFilters(currentFilters => {
                // @ts-ignore
                const { severity = undefined, ...filtersWithoutSeverity } = currentFilters;

                return filtersWithoutSeverity;
            });
        }

        setQueryType(newType);
        queryClient.invalidateQueries(['audit-query-table', 'event-query-table']);
    }

    return (
        <MDMWrapper title="Logs" documentationPath="index.html">
            <LogFilterForm
                onFilterChange={onFilterChange}
                currentType={queryType}
                onTypeChange={onTypeChange} 
            />
            <LogsTable
                logs={queryType === 'audit' ? audits : events}
                paginate={onPaginate}
            />
        </MDMWrapper>
    );
};

export default LogScreen;