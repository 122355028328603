import * as React from 'react';
import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { OperationSummary } from 'types';

import './changesets.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetOperationsSummary } from 'api';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export interface ChangesetSummaryTableProps {
    changesetId: number;
    
}
export const ChangesetSummaryTable: React.FC<ChangesetSummaryTableProps> = ({changesetId}) => {
    const dbName = useSelector<RootState, string>(state => state.database.selected);

    const { data = [], isLoading } = useGetOperationsSummary('changeset_summary', {
        dbName,
        changeset: changesetId,
    }, {
        enabled: !!changesetId
    });

    const columnHelper = createColumnHelper<OperationSummary>();
    const columns = [
        columnHelper.accessor('table', {
            header: 'Table',
            Cell: ({ cell }) => cell.value,
        }),
        columnHelper.accessor('operation', {
            header: 'Operation',
            Cell: ({ cell }) => cell.value,
        }),
        columnHelper.accessor('count', {
            header: 'Count',
            minSize: 50,
            Cell: ({ cell }) => cell.value,
        }),
    ];

    return (
        <div className="changeset-summary-container">
            <div
                aria-label="changeset-summary-title"
                className="changeset-summary-title"
            >
                    {`Changeset ${changesetId} Details`}
            </div>
            
            <ReactTable
                data={data}
                columns={columns}
                className="-striped"
                showPagination={false}
                minRows={1}
                loading={isLoading}
            />
        </div>
    );
};