import { RECEIVE_COMPUTED_VIEW_VALIDATION, RECEIVE_COMPUTED_VIEWS, CLEAR_COMPUTED_VIEWS } from './types';

const defaultState = {
    computedViews: [],
    validation: {},
};

export const viewReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_COMPUTED_VIEWS:
            return { ...state, computedViews: action.payload.computedViews };
        case CLEAR_COMPUTED_VIEWS:
            return { ...state, computedViews: [] };
        case RECEIVE_COMPUTED_VIEW_VALIDATION:
            return { ...state, validation: action.payload.validation };
        default:
            return state;
    }
};
