import { axiosPost, axiosGet } from '../helpers/axiosUtils';
import { LogUrl } from '../proxies/ajax/LogUrl';
import { AuditItem, EventItem, Pagination } from 'rp-mdm-common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useDebounce } from 'helpers/hooks';


export interface EventFilter {
    timestamp_min?: number; // milliseconds
    timestamp_max?: number; // milliseconds
    service?: string;
    severity?:string; // comma separated list of "info" | "warning" | "error" | "fatal";
    metric?: string;
};



export interface AuditFilter {
    timestamp_min?: number; // milliseconds
    timestamp_max?: number; // milliseconds
    area?: string;
    action?: string;
    target?: string;
    username?: string;
    ip_address?: string;
    message?: string;
}

// export interface ListOfStatusItem {
//  records?: LogStatusItem[];
// }

export class LogApi {
    static getVersion() {
        return axiosGet(LogUrl.version);
    }

    static ping() {
        return axiosGet(LogUrl.ping);
    }

    static addEvent(records: EventItem[]) {
        const body = {
            payload: { records }
        };
        return axiosPost(LogUrl.addEvent, body).then(() => true);
    }

    /**
     * Convenience function for adding a single event
     */
    static add1Event(record: EventItem) {
        const body = {
            payload: { records: [record] }
        };
        return axiosPost(LogUrl.addEvent, body).then(() => true);
    }

    static clearEvent() {
        // clear is only available when the event service is run in a debug mode
        return axiosPost(LogUrl.clearEvent, {}).then((payload) => payload.success);
    }

    // do we want pagination?
    static queryEvent(filter: EventFilter, pagination?: Pagination) {
        const body = {
            payload: filter
        };
        return axiosPost(LogUrl.queryEvent, body, null, pagination).then(payload => {
            if (!payload || !payload.records)
                return [];
            // what about payload.truncated?  Its true it meant the filter didn't limit it PLUS there are
            // more records available do we want to remove nulls? for now, say yes
            const records = payload.records.map((record) => {
                Object.keys(record).forEach((k) => {
                    const v = record[k];
                    record[k] = ((v === null) ? '' : v);
                });
                return record;
            });
            return records;
        });
    }

    static addAudit(records: AuditItem[]) {
        const body = {
            payload: { records }
        };
        return axiosPost(LogUrl.addAudit, body).then(() => true);
    }

    /**
     * Convenience function for adding a single audit item
     */
    static add1Audit(record: AuditItem) {
        const body = {
            payload: { records: [record] }
        };
        return axiosPost(LogUrl.addAudit, body).then(() => true);
    }

    static clearAudit() {
        // clear is only available when the event service is run in a debug mode
        return axiosPost(LogUrl.clearAudit, {}).then((payload) => payload.success);
    }

    // do we want pagination?
    static queryAudit(filter: AuditFilter, pagination?: Pagination) {
        const body = {
            payload: filter
        };
        return axiosPost(LogUrl.queryAudit, body, null, pagination).then(payload => {
            if (!payload || !payload.records)
                return [];
            // what about payload.truncated?  Its true it meant the filter didn't limit it PLUS there are
            // more records available do we want to remove nulls? for now, say yes
            const records = payload.records.map((record) => {
                Object.keys(record).forEach((k) => {
                    const v = record[k];
                    record[k] = ((v === null) ? '' : v);
                });
                return record;
            });
            return records;
        });
    }
}

type AuditQueryHookParams = { filters: AuditFilter; pagination?: Pagination; };
type EventQueryHookParams = { filters: EventFilter; pagination?: Pagination; };

export const useAuditQuery = (key: string, params: AuditQueryHookParams, options?: Partial<UseQueryOptions<AuditItem[]>>) => {
    return useQuery<AuditItem[]>([key, params], async ({ queryKey }): Promise<AuditItem[]> => {
        const [ , queryParams] = queryKey;
        const { filters, pagination } = queryParams as AuditQueryHookParams;
        const result = await LogApi.queryAudit(filters, pagination);

        return result;
    }, options);
};

export const useEventQuery = (key: string, params: EventQueryHookParams, options?: Partial<UseQueryOptions<EventItem[]>>) => {
    return useQuery<EventItem[]>([key, params], async ({ queryKey }): Promise<EventItem[]> => {
        const [ , queryParams] = queryKey;
        const { filters, pagination } = queryParams as EventQueryHookParams;
        const result = await LogApi.queryEvent(filters, pagination);

        return result;
    }, options);
};