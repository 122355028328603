/**
 * @format
 */
import * as React from 'react';
import classNames from 'classnames';
import { Button as BPButton, IconName, Intent } from '@blueprintjs/core';

export interface ButtonProps {
    /** The classname of the button (combines with bp3-button) */
    className?: string;
    /** Determines the buttons `disabled` html attribute */
    disabled?: boolean;
    /** The icon for the button. (You can also call this via className) */
    icon?: IconName;
    /** HTML Id of the button */
    id?: string;
    /** Blueprintjs Intent enum */
    intent?: Intent;
    minimal?: boolean;
    /** onClick event of the button */
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    /** a style object for the button */
    style?: object;
    /** HTML tabindex */
    tabIndex?: number;
    /** The Button's title over hover */
    title?: string;
    /** The inner text of the button. (Note there wil be a space before it) */
    value?: any;
}

/**
 * Button is a pure component used for most UI purposes.
 */
export class Button extends React.PureComponent<React.PropsWithChildren<ButtonProps>> {
    // TODO- why does this function even exist?
    public componentDidUpdate() {
        return false;
    }

    private onClick = (e?: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        this.props.onClick(e);
    }

    private getIntent(intent): string {
        if (intent == null) return 'primary';
        if (typeof intent === 'string') return intent;
        return Intent[intent].toLowerCase();
    }

    private getStyles(
        styles,
    ): React.StyleHTMLAttributes<{ [prop: string]: string }> | { [prop: string]: string } {
        const base = { marginBottom: '.25rem', marginRight: '.25rem', marginLeft: '.25rem' };
        if (styles == null) return base;
        return { ...base, ...styles };
    }

    public render() {
        const { disabled, intent, id, title, icon, minimal, style, tabIndex, onClick, value, ...rest } = this.props;
        // This is a kinda half-assed fix because blueprint pads their icons against text, but we have icon only buttons
        // and the padding makes the icon off center.
        const className = this.props.value ? '' : 'mdm-button';
        return (
            <BPButton
                style={this.getStyles(style)}
                id={id}
                disabled={disabled}
                icon={icon}
                intent={intent || Intent.PRIMARY}
                minimal={minimal}
                title={title}
                className={classNames(this.props.className, className)}
                tabIndex={tabIndex}
                onClick={onClick ? this.onClick : null}
                {...rest}
            >
                {this.props.children}
                {this.props.value || ''}
            </BPButton>
        );
    }
}
