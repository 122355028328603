/**
 * @format
 * Created by nbamford on 1/29/2017.
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { fetchAssignedWorkflows, continueTask, discardAssignedTask as discardTask, releaseTask } from 'store/workflow/actions';
import { MDMWrapper } from 'components/Shared';
import { Workflow, Workitem } from 'types';
import WorkflowAssignedTable from './WorkflowAssignedTable';
import { WorkitemEditModal } from '../WorkitemEditModal/WorkitemEditModal';

export interface WorkflowAssignedProps {
    continueTask: (dbName: string, workitem: string, node_id: string) => void;
    discardTask: (workitem: Workflow, button:HTMLButtonElement, notes?: string) => void;
    releaseTask: (dbName: string, workitem: string, node_id: string) => void;
    fetchAssignedWorkflows: (dbName: string, username: string) => void;
    dbName: string;
    username: string;
    assigned: Workitem[];
    canEditWorkitems: boolean;
}

export interface WorkflowAssignedState {
    currentEditWorkitem: Workitem;
}

class WorkflowAssignedContainerBase extends React.Component<WorkflowAssignedProps, WorkflowAssignedState> {
    constructor(props) {
        super(props);
        this.state = {
            currentEditWorkitem: null
        };
    }

    loadData = () => {
        const { dbName, username } = this.props;
        dbName && this.props.fetchAssignedWorkflows(dbName, username);
    }

    continueTask = (workitem_id, nodeId) => {
        this.props.continueTask(this.props.dbName, workitem_id, nodeId);
    }

    discardTask = (workitem_id, nodeId) => {
        const workitem: Workflow = {
            changesetId: null,
            continue: false,
            databaseName: this.props.dbName,
            formId: null,
            node_id: nodeId,
            schema: null,
            tableName: null,
            workitem_id: workitem_id,
            variables: {}
        }
        this.props.discardTask(workitem, null);
    }

    releaseTask = (workitem_id, nodeId) => {
        this.props.releaseTask(this.props.dbName, workitem_id, nodeId);
    }

    editWorkitem = (workitem: Workitem) => this.setState({ currentEditWorkitem: workitem })

    render() {
        const { currentEditWorkitem } = this.state;

        return (
            <div>
                <MDMWrapper title="Workitems Assigned to Me" documentationPath="workflow_console.html">

                    <WorkitemEditModal
                        isOpen={!!currentEditWorkitem}
                        workitem={currentEditWorkitem}
                        onClose={() => this.setState({ currentEditWorkitem: null })}
                    />

                    <WorkflowAssignedTable
                        canEditWorkitems={this.props.canEditWorkitems}
                        workflows={this.props.assigned}
                        continueTask={this.continueTask}
                        discardTask={this.discardTask}
                        releaseTask={this.releaseTask}
                        editTask={this.editWorkitem}
                    />
                </MDMWrapper>
            </div>
        );
    }
}

export const WorkflowAssignedContainer = connect(
    (state: any) => ({
        assigned: state.workflow.assigned,
        dbName: state.database.selected,
        username: state.username,
        canEditWorkitems: state.auth.canEditWorkitems
    }),
    {
        fetchAssignedWorkflows,
        continueTask,
        discardTask,
        releaseTask
    },
)(WorkflowAssignedContainerBase as any);
