/**
 * Created by nbamford on 1/16/2017.
 *
 * UI routines will invoke the /ajax (same origin) calls
 */
//import { displayError } from 'api';
import { axiosPost } from 'helpers';
import {
    History,
    Process,
    ProcessDef,
    WorkitemQueryFilter,
    WorkitemQueryInclude,
    Workflow,
    WorkflowAction,
    WorkflowDef,
} from 'types';
import { WorkflowUrl } from '../proxies/ajax/WorkflowUrl';
import { displayError } from './displayError';
import { Pagination } from 'rp-mdm-common';
//import { encode } from 'punycode';

export class WorkflowApi {
    static noDatabasePromise() {
        return new Promise((resolve, reject) => {
            reject("No database name was given");
        });
    }

    static getAssignedWorkflows(database, username, showError = true): Promise<any> {
        if (!database) return WorkflowApi.noDatabasePromise();
        const url = WorkflowUrl.taskAssigned;
        // limit the maximum workitems returned to 100
        // This should be revisited
        return axiosPost(url, { payload: {
            database: database, username: username,
            pagination: { start: 0, count: 100 }
        } }, 'tasks', null, showError);
    }

    static getAvailableWorkflows(database, username, showError = true): Promise<any> {
        if (!database) return WorkflowApi.noDatabasePromise();
        const url = WorkflowUrl.taskAvailable;    
        // limit the maximum workitems returned to 100
        // This should be revisited
        return axiosPost(url, { payload: {
            database: database, username: username,
            pagination: { start: 0, count: 100 }
        } }, 'tasks', null, showError);
    }

    static getStartableWorkflows(database, username) {
        if (!database) return WorkflowApi.noDatabasePromise();
        const url = WorkflowUrl.workflowStartable;
        return axiosPost(url, { payload: { database: database, username: username } }, 'workflows');
    }

    static getStartedWorkflows(database: string, username?: string) {
        if (!database) return WorkflowApi.noDatabasePromise();
        const url = WorkflowUrl.workflowStarted;
        return axiosPost(url, { payload: { database: database, username: username } }, 'workitems');
    }

    static startWorkflow(database, workflow_id) {
        const url = WorkflowUrl.workflowStart;
        const payload = {
            database,
            workflow_id,
            continue_wait_ms: 5000
        };
        return axiosPost(url, { payload });
    }
    static abandonTask(database: string, workitem_id: number) {
        if (!database) return WorkflowApi.noDatabasePromise();
        const url = WorkflowUrl.taskAbandon;
        const payload = {
            database,
            workitem_id
        };

        return axiosPost(url,{ payload });
    }

    static continueTask(database, workitem_id, node_id) {
        const url = WorkflowUrl.taskContinue;
        const payload = {
            database,
            workitem_id,
            continue_wait_ms: 5000,
            node_id
        };
        return axiosPost(url, { payload });
    }

    static claimTask(database, workitem_id, node_id) {
        const url = WorkflowUrl.taskClaim;
        const payload = {
            database,
            workitem_id,
            continue_wait_ms: 5000,
            node_id
        };
        return axiosPost(url, { payload });
    }

    static queryTasks(dbName: string, filter: WorkitemQueryFilter, include: WorkitemQueryInclude, usePagination: boolean = true) {
        const payload = { database: dbName, filter, include };
        return axiosPost(WorkflowUrl.taskQuery, { payload }, 'workitems', usePagination ? null : { start: 0, count: 10000 }).catch(err => {
            displayError('Query Tasks Failed', `Failed to query Tasks`, err);
            throw err;
        });
    }

    static updateDueDate(database: string, workitemId: number, dueDate: Date) {
        const payload = {
            database,
            workitem_id: workitemId,
            due_date: dueDate.toISOString()
        };
        return axiosPost(WorkflowUrl.workitemUpdate, { payload });
    }

    static assignTask(database: string, workitemId: number, nodeId: string, assignee: string) {
        const payload = {
            database,
            workitem_id: workitemId,
            node_id: nodeId,
            assignee
        };
        return axiosPost(WorkflowUrl.taskAssign, { payload });
    }

    static releaseTask(database, workitem_id, node_id) {
        const url = WorkflowUrl.workflowRelease;
        const payload = {
            database,
            workitem_id,
            continue_wait_ms: 5000,
            node_id
        };
        return axiosPost(url, { payload });
    }

    static completeTask(workflow: Workflow, action: WorkflowAction, recordId?: string, data?: any, note?: string) {
        const url = WorkflowUrl.taskComplete;
        // // all of this is temporary
        // // the record data should be in a record subdocument
        const record = data && data.hasOwnProperty('record') && data.record || {};

        const variables: { primary_key?: string } = {};

        if (recordId != null)
            variables.primary_key = recordId;

        const options = {
            database: workflow.databaseName,
            workitem_id: workflow.workitem_id,
            continue_wait_ms: 5000,
            node_id: workflow.node_id,
            action: action,
            record: record,
            form_id: workflow.formId,
            changeset_id: workflow.changesetId,
            table: workflow.tableName,
            note: note,
            variables: variables,
            // match_groups:
        };

        // Notes come in as data[$notes] sometimes.  Seems hack
        if (data && data.hasOwnProperty('$notes')) {
            options.note = data.$notes;
        }

        return axiosPost(url, { payload: options });
    }

    static completeMatchTask(workflow: Workflow, action: WorkflowAction, matchGroups: string[][], note?: string) {
        const url = WorkflowUrl.taskComplete;
        // all of this is temporary
        const options = {
            workitem_id: workflow.workitem_id,
            node_id: workflow.node_id,
            form_id: workflow.formId,
            database: workflow.databaseName, // because inconsistent naming
            changeset_id: workflow.changesetId,
            table: workflow.tableName,
            action: action,
            continue_wait_ms: 5000,
            note: note,
            match_groups: null as any
            //'variables': context.variables
        };

        if (note != null)
            options.note = note;
        if (matchGroups != null)
            options.match_groups = matchGroups;

        return axiosPost(url, { payload: options });
    }

    static readWorkitemState(database: string, workitem_id: number): Promise<History[]> {
        const url = WorkflowUrl.readWorkitemState;
        const payload = {
            database: database,
            workitem_id: workitem_id
        };

        return axiosPost(url, { payload }, 'history');
    }

    static recordActivity(database: string, changeset: any, table: string, key: string): Promise<History[]> {
        const url = WorkflowUrl.recordActivity;
        const payload = {
            database: database,
            changeset: changeset === 'current' ? undefined : changeset,
            table: table,
            record_key: key
        };

        return axiosPost(url, { payload });
    }

    static createWorkflow(dbName: string, processId: number, description: string, completionHours: number, variables: any, uiVisible: boolean): Promise<WorkflowDef[]> {
        const payload = {
            variables,
            database: dbName,
            process_id: processId,
            ui_visible: uiVisible,
            completion_hours: completionHours,
            description
        };

        return axiosPost(WorkflowUrl.workflowCreate, { payload });
    }

    static updateWorkflow(dbName: string, processId: number, workflowId: number, description: string, completionHours: number, variables: any, uiVisible: boolean): Promise<boolean> {
        const payload = {
            variables,
            database: dbName,
            workflow_id: workflowId,
            process_id: processId,
            ui_visible: uiVisible,
            completion_hours: completionHours,
            description
        };

        return axiosPost(WorkflowUrl.workflowUpdate, { payload }).then(() => true);
    }

    static queryWorkflows(dbName: string, processId?: number): Promise<WorkflowDef[]> {
        const payload = {
            database: dbName,
            process_id: processId
        };

        return axiosPost(WorkflowUrl.workflowQuery, { payload }, 'workflows').catch(err => {
            displayError('Query Workflows Failed', `Failed to query workflows`, err);
            throw err;
        });
    }

    static deleteWorkflow(dbName: string, workflowId: number): Promise<WorkflowDef[]> {
        const payload = {
            database: dbName,
            workflow_id: workflowId
        };

        return axiosPost(WorkflowUrl.workflowDelete, { payload });
    }

    // Process CRUD

    static processCreate(process: ProcessDef): Promise<Process> {
        const payload = {
            database: process.database,
            description: process.description,
            process: process.process,
        };

        return axiosPost(WorkflowUrl.processCreate, { payload });
    }

    static processDelete(process_id: number): Promise<boolean> {
        const payload = { process_id };
        return axiosPost(WorkflowUrl.processDelete, { payload }).then(() => true);
    }

    static processModify(process: ProcessDef): Promise<boolean> {
        const payload = {
            process: process.process,
            database: process.database,
            process_id: process.process_id,
            description: process.description,
        };

        return axiosPost(WorkflowUrl.processModify, { payload }).then(() => true);
    }

    static processRead(process_id: number, database: string): Promise<ProcessDef> {
        const payload = { process_id, database };
        return axiosPost(WorkflowUrl.processRead, { payload });
    }

    /*
     *  query all available processes.
     *  dbName (optional) will limit to processes for a particular database
     *  Only returns process_id and description
     */
    static queryProcesses(dbName?: string): Promise<Process[]> {
        const payload = dbName ? { database: dbName } : {};
        return axiosPost(WorkflowUrl.processQuery, { payload }, 'processes').catch(err => {
            displayError('Query Processes Failed', `Failed to query Processes`, err);
            throw err;
        });
    }

    static queryWorkItems(dbName: string, filter: WorkitemQueryFilter) {
        const payload = { database: dbName, filter };
        return axiosPost(WorkflowUrl.workitemQuery, { payload }, 'workitems').catch(err => {
            displayError('Query Workitems Failed', `Failed to query Workitems`, err);
            throw err;
        });
    }
}