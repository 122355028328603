import * as React from 'react'
import { Tooltip } from '@blueprintjs/core';
import './InfoTooltip.scss'

interface InfoTooltipProps {
    tooltipText: string;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = (props: InfoTooltipProps) => {
    return (
        <Tooltip
            content={props.tooltipText}
            className="tooltipInfo"
        >
            <span className="tooltip-target">?</span>
        </Tooltip>
    )
}