import React, { FC, useMemo } from 'react';
import { Column } from 'react-table';
import { Pagination } from 'rp-mdm-common';
import { ButtonGroup } from '@blueprintjs/core';
import * as R from 'ramda';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { Button } from 'components/Shared';
import { displayField } from 'utils';
import { TooltipCell } from 'components/Shared/ReactTable/TooltipCell';
import { createColumnHelper } from '@tanstack/react-table';

export interface ErasureRecordsTableProps {
    defaultPageSize?: number;
    records: object[];
    groups?: boolean;
    schema?: any;
    openMaskDialog: (id) => void;
    openPurgeDialog: (id) => void;
    tooltipText?: string;
    noDataText: string;
    paginate: (pagination: Pagination) => void;
    loading?: boolean;
}

const ErasureRecordsTable: FC<ErasureRecordsTableProps> = ({
    records,
    schema,
    openMaskDialog,
    openPurgeDialog,
    defaultPageSize,
    noDataText,
    paginate,
    loading,
}) => {
    const colHelper = createColumnHelper<any>();
    const getPrimaryKey = () => {
        return schema.primary_key;
    }

    const markPIIFields = (records: any[]): any[] => {
        const mark = '********';
        const piiFields = schema.fields
            .filter(f => f.pii || (f.schema && f.schema.fields.some(g => g.pii)))
            .reduce((acc, cur) => {
                cur.pii
                    ? (acc[cur.field] = mark)
                    : cur.schema.fields
                        .filter(f => f.pii)
                        .forEach(f => (acc = R.assocPath([cur.field, f.field], mark, acc)));
                return acc;
            }, {});
        const maskFunc = R.curry((piiFields, record) => R.mergeDeepRight(record, piiFields))(
            piiFields,
        );
        return R.map(r => (r.rpmdm_mask && r.rpmdm_mask.masked ? maskFunc(r) : r), records);
    }

    const createData = () => {
        const data = records && records.length && records.map(recordObject => {
            const id = recordObject[getPrimaryKey()];
            recordObject['actions'] = (
                <ButtonGroup>
                    <Button onClick={e => openMaskDialog(id)} minimal={true} style={{ margin: 0 }} value='Mask' />
                    <Button onClick={e => openPurgeDialog(id)} minimal={true} style={{ margin: 0 }} value='Purge' />
                </ButtonGroup>
            );

            return recordObject;
        });

        return data;
    }

    const formatStandardColumns = (schema) => {
        return schema.fields
            .filter(f => f.datatype.type !== 'table' && f.display)
            .map((f) => {
                return colHelper.accessor(f.field, {
                    header: f.field,
                    // className: 'erasure-match-table-td',
                    enableSorting: true,
                    cell: row => <TooltipCell value={row.getValue()} />
                })
            })
    }

    const formatTableColumns = (schema) => {
        return schema.fields
            .filter(f => f.datatype.type === 'table' && displayField(f))
            .map((fieldObj) => {
                return colHelper.accessor(fieldObj.field, {
                    header: fieldObj.field,
                    // className: 'erasure-match-table-td',
                    enableSorting: true,
                    cell: row => {
                        const nested = row.getValue();
                        let rows = (<div>no value</div>);

                        // Someone knows how to do this better;
                        // foreach object make a div for each key
                        if (nested && nested.length) {
                            const keys = Object.keys(nested[0]);
                            rows = nested.map((r, index, arr) => (
                                <div key={`out${index}`} className="erasure-match-nested-row">
                                    {keys.map((k, index2, arr2) => (
                                        <div key={`in${index2}`} className="erasure-match-nested-column">{r[k]}</div>
                                    ))}
                                </div>
                            ));
                        }

                        return (<div className="erasure-match-nested">
                            {rows}
                        </div>);
                    }
                });
            }
            );
    }

    const formatMatchColumn = (schema) => {
        const onlyOne = schema.matching.match_types.length == 1;
        return schema.matching.match_types.map((type: any, index) => {

            return colHelper.accessor(`matchid${index}`, {
                header: onlyOne ? "GROUP ID" : `${type.type} GROUP ID`,
                cell: row => <TooltipCell value={row.getValue()} />
            });
        });
    }

    const getColumns = () => {
        
        let fields = [colHelper.display({
            id: 'actions',
            header: '',
            // width: 128,
            cell: ({ row }) => (
                <ButtonGroup>
                    <Button
                        onClick={e => openMaskDialog(row.original[schema.primary_key])}
                        minimal={true}
                        style={{ margin: 0 }}
                        value='Mask'
                    />
                    <Button
                        onClick={e => openPurgeDialog(row.original[schema.primary_key])}
                        minimal={true}
                        style={{ margin: 0 }}
                        value='Purge'
                    />
                </ButtonGroup>
            )
        })];

        if (schema && schema.fields) {
            fields = fields.concat(formatMatchColumn(schema));
            fields = fields.concat(formatStandardColumns(schema));
            fields = fields.concat(formatTableColumns(schema));

            if (fields.length > 1) {
                const last = fields.pop();
                // last.Cell = row => <div className="erasure-match-sizing-handles-last">{row.value}</div>
                fields.push(last);
            }
        }

        return fields;
    };

    const columns = useMemo(() => getColumns(), [schema]);


    const generateTable = () => {
        const data = createData();
        if (schema && Array.isArray(data)) {

            const dataWithPIIFields = markPIIFields(data);

            return (
                <ReactTable
                    resultCount={dataWithPIIFields.length}
                    paginate={paginate}
                    loading={loading}
                    data={dataWithPIIFields}
                    columns={columns}
                    defaultPageSize={defaultPageSize || 20}
                    emptyText={noDataText}
                    // minRows={1}
                />
            );
        } else {
            return (
                <div style={{
                    margin: '1rem',
                    textAlign: 'center',
                }}>
                    No data found. You may want to restructure your query.
                </div>
            );
        }
    }

    return (
        <div className='records-table-container' id='records-table'>
            {generateTable()}
        </div>
    );
}

export default ErasureRecordsTable;