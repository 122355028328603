import * as React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Classes, FormGroup } from '@blueprintjs/core';

import { UserApi } from 'api';
import { showToaster } from 'actions/ActionCreators'
import { setUserSettings } from 'store/user/actions'
import { UserSettings as Settings } from 'store/user/types';
import { USER_SETTING_KEY } from 'constants/settings';
import { Intent } from 'types';
import { AppThunk } from 'store';
import './WorkflowWidgets.scss';

export interface WorkflowWidgetsProps {
    tableName: string;
    workitemId: number;
    settings: Settings;
    showToaster?: showToaster;
    setUserSettings: (settings: { [key: string]: any }) => AppThunk;
}

export interface WorkflowWidgetsState {
    loading: boolean;
}

export class WorkflowWidgetsBase extends React.Component<WorkflowWidgetsProps, WorkflowWidgetsState> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    changeSettings = (claimNextAssigned: boolean) => {
        const { settings, setUserSettings, showToaster } = this.props;
        const newSettings = { ...settings, claimNextAssigned };
        const stringSettings = JSON.stringify(newSettings);

        this.setState({ loading: true }, () => {
            UserApi.setUserState(USER_SETTING_KEY, stringSettings, null)
                .then(res => {
                    setUserSettings(newSettings);
                    this.setState({ loading: false })
                }).catch(e => {
                    console.error(e);
                    this.setState({ loading: false })
                    showToaster(Intent.ERROR, `Something went wrong, please try again in a few minutes`);
                })
        })
    }

    renderCheckbox = () => {
        const { loading } = this.state;
        const { settings } = this.props;

        if (!settings) {
            return null;
        }

        return <FormGroup
            label="Start next"
            inline={true}
            className={`${Classes.TEXT_MUTED} wf-checkbox-widget text-medium wf-widget-label`}
            contentClassName="checkbox"
        >
            <Checkbox
                disabled={loading}
                checked={settings.claimNextAssigned}
                onChange={e => this.changeSettings((e.target as any).checked)}
            />
        </FormGroup>
    }

    render() {
        const { tableName, workitemId } = this.props;
        const tableInfo = tableName ?
            <div className="wf-widget">
                <span className={`${Classes.TEXT_MUTED} text-medium wf-widget-label`}>Table:</span>
                <span>{tableName}</span>
            </div> : null;

        return (
            <div className="fill-width flex-row flex-center-vertically">
                <div className="wf-widget">
                    <span className={`${Classes.TEXT_MUTED} text-medium wf-widget-label`}>Workitem ID:</span>
                    <span>{workitemId}</span>
                </div>
                {tableInfo}
                {this.renderCheckbox()}
            </div>
        )
    }
}

export const WorkflowWidgets = connect(
    (state: any) => ({ settings: state.user.settings, }),
    { setUserSettings, showToaster },
)(WorkflowWidgetsBase);