import * as React from "react";
import { Collapse, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Button } from "components/Shared";
import * as moment from "moment";
import RecordHistoryValueArray from "./RecordHistoryValueArray";
import { formatDateTimeDisplay } from "helpers/date-format";

export type RecordHistoryEntryProps = {
    primaryKey?: string;
    record: any;
    timestamp: number;
    id: string;
    currentTable?: any;
}

export const RecordHistoryEntry: React.FC<RecordHistoryEntryProps> = ({ primaryKey, record, timestamp, id, currentTable }) => {
    const [isOpen, setIsOpen] = React.useState(false)

    const toggleRecordView = (e: any) => setIsOpen(val => !val);

    const renderRecordDetails = () => {
        const recordCopy = { ...record };
        let primaryValue = null;
        if (primaryKey) {
            primaryValue = recordCopy[primaryKey]
            delete recordCopy[primaryKey]
        }
        const keys = Object.keys(recordCopy);

        return (
            <div className="ReactTable">
                <table className='rt-table'>
                    <tbody className="rt-tbody">
                        {!!primaryKey && <tr className="rt-tr">
                            <td className="rt-td"><strong>{primaryKey}</strong></td>
                            <td className="rt-td">{primaryValue}</td>
                        </tr>}
                        {keys.map(key => {
                            const original = record[key];
                            let value = original

                            if (Array.isArray(original) && original.length) {
                                value = <RecordHistoryValueArray data={original} />   
                            }

                            value = formatDateTimeDisplay(value, currentTable.fields.find(f => f.field === key));

                            if (typeof original === 'object' && !Array.isArray(original) && original !== null) {
                                value = <pre>{JSON.stringify(original, null, 2)}</pre>;
                            }


                            return (
                                <tr key={`property-${key}`} className="rt-tr">
                                    <td className="rt-td"><strong>{key}</strong></td>
                                    <td className="rt-td">
                                        {value}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className="history-entry">
            <div className="history-name">
                Record {id + 1} -&nbsp;
                <span className="history-type">
                    {moment(new Date(timestamp)).format('MMM Do, YYYY @ hh:mm:ss A')}
                </span>
            </div>
            
            <Button
                intent={Intent.PRIMARY}
                icon={isOpen ? IconNames.EYE_OFF : IconNames.EYE_OPEN}
                className="history-record-button"
                onClick={toggleRecordView}
                small
            >
                
                {isOpen ? 'Close' : 'View'} Record
            </Button>
            <Collapse className="history-record-collapse" isOpen={isOpen}>
                {renderRecordDetails()}
            </Collapse>
        </div>
    )
}

// export default RecordHistoryEntry