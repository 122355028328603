
import { ITreeNode } from "@blueprintjs/core";
import { v4 as uuidv4 } from 'uuid';

import { IPermNode } from './IPermNode';


export class PermWorkflowNode implements IPermNode {
    id:string;
    name: JSX.Element|string;
    type:string;
    isOpen: false;
    canBeSelected: boolean;
    isLeaf: boolean;
    openIcon: string;
    closedIcon: string;
    children: IPermNode[];
    parent: IPermNode;
    process_id: number|string;
    workflow_id: number;
    description: string;

    // Parent is a workflow
    constructor(name: JSX.Element|string, workflow_id:number, description:string, parent:IPermNode) {
        this.id = uuidv4();
        this.name = name;
        this.type = 'workflow';
        this.isOpen = false;
        this.canBeSelected = true;
        this.isLeaf = true;
        this.openIcon = 'exchange';
        this.closedIcon = 'exchange'; // unused
        this.children = [];
        this.parent = parent;
        this.workflow_id = workflow_id;
        this.process_id = parent.myName();
        this.description = description;
    }

    async getChildrenFn() {
        return await false;
    }

    fullNameFn() {
        // My name is ugly
        const procName = this.parent && this.parent.myName() || '';
        const dbName = this.parent && this.parent.parent && this.parent.parent.fullNameFn() || '';
        return `${dbName}.${procName}.${this.myName()}`;
    }

    myName() {
        return this.workflow_id.toString();
    }

    header() {
        return `Workflow: ${this.description}`;
    }

    getBPNode() {
        return {
            id: this.id,
            hasCaret: false,
            isExpanded: false,
            label: this.name,
            iconName: this.openIcon,
        } as ITreeNode;
    }
}
