import * as React from 'react';
import { connect } from 'react-redux';
import { Dialog, Button, Classes, Intent, FormGroup, InputGroup, FileInput, Icon } from '@blueprintjs/core';
import { Process, ProcessDef, Intent as customIntent } from '../../../types';
import { WorkflowApi } from '../../../api';
import { showToaster } from '../../../actions';
import { IconNames } from '@blueprintjs/icons';
import ReactBpmn from 'react-bpmn';

export interface ProcessEditModalProps {
    dbName: string;
    isEdit: boolean;
    process: Process;
    closeModal: () => void;
    showToaster: showToaster;
    onSave: (newProcess: ProcessDef) => void;
}

export interface ProcessEditModalState {
    processDetails: ProcessDef;
    uploadFieldText: string;
    viewing: boolean;
    edited: boolean;
}

export class ProcessEditModalBase extends React.Component<ProcessEditModalProps, ProcessEditModalState> {

    DEFAULT_FILE_TEXT = "Choose a BPMN file...";
    viewer;

    constructor(props) {
        super(props);
        this.state = {
            processDetails: null,
            uploadFieldText: this.DEFAULT_FILE_TEXT,
            viewing: false,
            edited: false,
        }
    }

    componentDidMount() {
        const { process, dbName, isEdit } = this.props;
        if (isEdit) {
            WorkflowApi.processRead(process.process_id, dbName).then(processDetails => {
                this.setState({ processDetails });
            }).catch(e => console.error(e))
        } else {
            // its a NEW process without an ID
            const newProcess: ProcessDef = {
                process: "",
                variables: [],
                database: dbName,
                process_id: process.process_id,
                description: process.description,
            }

            this.setState({ processDetails: newProcess });
        }
    }

    onInputChange = (key: string, value) => {
        const { processDetails } = this.state;
        const editedProcess = { ...processDetails, [key]: value };
        this.setState({ processDetails: editedProcess, edited: true });
    }

    downloadXml = () => {
        const { processDetails } = this.state;

        if (processDetails) {
            const element = document.createElement("a");
            const file = new Blob([processDetails.process],
                { type: 'text/plain;charset=utf-8' });
            element.href = URL.createObjectURL(file);
            element.download = processDetails.description + ".xml";
            document.body.appendChild(element);
            element.click();
        }
    }

    handleFileUpload = (file) => {
        let fileReader = new FileReader();

        fileReader.onloadend = (event) => {
            let buffer = event.target.result;
            this.setState({
                processDetails: { ...this.state.processDetails, process: buffer.toString() },
                uploadFieldText: file.name,
                edited: true
            }, () => {
                this.props.showToaster(customIntent.INFO, `Process BPMN contents updated.`);
            })
        };

        fileReader.readAsText(file);
    }

    generateFormBody = () => {
        const { processDetails, uploadFieldText } = this.state;

        if (!processDetails)
            return <span>loading...</span>

        return (
            <div className="flex-column workflow-detail-form">
                <FormGroup
                    label="Description"
                    inline={true}
                    className={`${Classes.TEXT_MUTED} mdm-wf-form-group`}
                    contentClassName="pr-input"
                >
                    <InputGroup
                        id={processDetails.process_id + "-description"}
                        value={processDetails.description}
                        name="description"
                        onChange={e => this.onInputChange('description', e.target.value)}
                    />
                </FormGroup>

                <br />

                <span className={`bp5-label ${Classes.TEXT_MUTED}`}>Process BPMN</span>

                <textarea
                    className="bp5-input .modifier xml-textarea"
                    placeholder="Please IMPORT a valid BPMN file."
                    value={processDetails.process}
                    spellCheck="false"
                    dir="auto"
                    disabled
                />

                <br />

                <div className="flex-space-evenly">
                    <Button
                        id="preview-button"
                        icon="eye-open"
                        disabled={!processDetails.process}
                        onClick={() => { this.setState({ viewing: true }) }}
                    >
                        View
                    </Button>

                    <FileInput
                        buttonText="Import"
                        text={uploadFieldText}
                        inputProps={{ accept: ".xml,.bpmn" }}
                        onInputChange={(e: any) => this.handleFileUpload(e.target.files[0])}
                    />

                    <Button icon="download" onClick={() => this.downloadXml()}>Export</Button>
                </div>
            </div>
        )
    }

    generateDialogBody = () => {
        const { processDetails, viewing } = this.state;

        if (viewing && processDetails.process)
            return (
                <div className="flex-column workflow-detail-form">
                    <ReactBpmn
                        diagramXML={processDetails.process}
                        onError={(e) => { console.error("error in diagram!", e) }}
                    />

                    <div>
                        <Button id="cancel-preview-button" icon="eye-off" onClick={() => { this.setState({ viewing: false }) }} >
                            Stop View
                        </Button>
                    </div>
                </div>
            )

        return this.generateFormBody()

    }

    render() {
        const { processDetails, viewing, edited, uploadFieldText } = this.state;
        const { process, closeModal, onSave, isEdit } = this.props;
        const { process_id } = process;

        let title: string;

        if (viewing) {
            title = isEdit ? `Viewing process #${process_id}` : 'Viewing New Process'
        } else {
            title = isEdit ? `Editing process #${process_id}` : 'New Process'
        }

        return (
            <Dialog
                title={title}
                icon={viewing ? IconNames.EYE_OPEN : IconNames.BUILD}
                className="pr-modal"
                onClose={closeModal}
                isOpen={!!process}
                canOutsideClickClose={false}
            >
                <div className={Classes.DIALOG_BODY}>
                    {this.generateDialogBody()}
                </div>

                {
                    (uploadFieldText !== this.DEFAULT_FILE_TEXT && isEdit) &&
                    <div className="info-text flex-center process-editor-warning-message">
                        <Icon icon={IconNames.ISSUE} className="info-icon" />
                        This will override the previous process.
                    </div>
                }

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            id='pr-modal-save'
                            icon={IconNames.FLOPPY_DISK}
                            className="user-save-button"
                            onClick={() => onSave(processDetails)}
                            disabled={!processDetails || viewing || !processDetails.process || !edited}
                            value="Save"
                            text={isEdit ? "Edit" : "Save"}
                        />
                        <Button
                            id='pr-modal-close'
                            icon={IconNames.CROSS}
                            onClick={closeModal}
                            intent={Intent.DANGER}
                            disabled={viewing}
                            value="Cancel"
                            text="Cancel"
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
}

export const ProcessEditModal = connect((state: any) => ({}), { showToaster })(ProcessEditModalBase as any);