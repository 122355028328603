/**
 * @format
 */
import * as React from 'react';
import { HTMLSelect, Collapse } from '@blueprintjs/core';
import { match } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { Button, CustomSelect } from 'components/Shared';

import { QueryInclude, History, Schema, Intent } from 'types';
import { Pagination } from 'rp-mdm-common';

import { DialogEditRecord } from './DialogEditRecord';
import { mdmErrorToText } from 'helpers';
import { MDMWrapper } from 'components/Shared';
import { RecordHistoryDialog } from './RecordHistoryDialog';
import RecordActivityDialog from './RecordActivityDialog';
import { displayError, getSchema, inRoles, TableApi, RecordApi, createChangeset, commitChangeset, discardChangeset } from '../../api';
// import { FrontSideRecordController } from 'controllers/FrontSideRecordController';

import { hasPIIFields } from 'utils';
import RecordsQueryBuilder from './RecordsQueryBuilder';
import RecordsMainRecordsTable from './RecordsMainRecordsTable';
import { RECORD_QUERY_MAX } from "../../UiConstants";

import {
    displayMessageBox,
    showToaster,
    getRecordsHistory,
    getRecordActivity,
} from 'actions';

import './RecordsMain.scss';
import { IconNames } from '@blueprintjs/icons';
import ImportDataDialog from './ImportDataDialog';

export interface IRecordsMainProps {
    changeset: any;
    dbName: string;
    getRecordsHistory: Function;
    getRecordActivity?: Function;
    history: any;
    match: match;
    username: string;
    queryChangesets: (dbName: string, filter: any) => any;
    showToaster: (i: Intent, m: string) => void;
}

export interface IRecordsMainState {
    include: QueryInclude;
    changesets: any[];
    currentRecordID: any;
    fields: any[];
    masked: boolean;
    start: number;
    records: object[];
    schema: Schema;
    count: number;
    isImportDataDialogOpen: boolean;
    isRecordHistoryDialogOpen: boolean;
    recordHistory: History[];
    isRecordActivityDialogOpen: boolean;
    recordActivity: History[];
    tables: string[];
    toggleQueryRecordForm: boolean;
    queryCount: number;
    loading: boolean;
    importing: boolean;
    recordsToUpload: number;
    uploadedRecords: number;
    isQueryFilterOpen: boolean;
}

export class RecordsMain extends React.Component<IRecordsMainProps, IRecordsMainState> {

    // Refers to DialogEditRecord
    private dialogEditRecord: DialogEditRecord;

    private getMaxRecords() {
        return RECORD_QUERY_MAX;
    }

    constructor(props) {
        super(props);
        this.state = {
            include: {
                conflict: true,
                match: true,
                version: false,
                mask: false,
            },
            changesets: [],
            currentRecordID: -1,
            fields: [],
            masked: false,
            schema: null,
            start: 0,
            isRecordHistoryDialogOpen: false,
            isImportDataDialogOpen: false,
            recordHistory: [],
            isRecordActivityDialogOpen: false,
            recordActivity: [],
            // Testing may not initialize config.  A problem for another day
            count: this.getMaxRecords(),
            records: [],
            tables: [],
            toggleQueryRecordForm: false,
            queryCount: 0,
            loading: false,
            importing: false,
            recordsToUpload: 0,
            uploadedRecords: 0,
            isQueryFilterOpen: false
        };
    }

    private async getTableData(dbName: string, tableName: string, changesetID: string) {
        const tables = await TableApi.listTables(dbName, changesetID);
        this.setQueryCount(0);
        if (tableName) {
            const schema = await getSchema(dbName, changesetID, tableName);
            this.setState({ tables, schema, records: [] }, () =>
                this.getRecords(dbName, tableName)
            );
        } else {
            // just set the tables in state
            this.setState({ tables: tables });
        }
    }

    public componentDidMount() {
        this.getTableData(this.props.dbName, (this.props.match.params as any).table, this.props.changeset.changeset);
    }

    public componentDidUpdate(prevProps: IRecordsMainProps) {
        if (prevProps.dbName !== this.props.dbName) {
            this.setState({ records: [] }, () => {
                this.getTableData(this.props.dbName, null, this.props.changeset.changeset);
                this.props.history.push(`/records`);
            })
        }
        else {
            let prevParams:any = prevProps.match.params;
            let myParams:any = this.props.match.params;
            if (prevProps.changeset.changeset !== this.props.changeset.changeset ||
                // try to see if the table name changes
                prevParams?.table !== myParams?.table
            ) {
                this.getTableData(this.props.dbName, (this.props.match.params as any).table, this.props.changeset.changeset);
            }
        }
    }

    displayRecordsError = displayError('Query Records Failed', 'Failed to find(query) records');

    private cleanFilter = (original) => {
        let filter = { ...original };
        if (filter.fields) {
            filter.fields = filter.fields.filter(f => f.field !== "" && f.operation !== "");
            if (filter.fields.length == 0) delete filter.fields;
        }
        return filter;
    }

    private getRecords = async (dbName: string, table: string, pagination?: Pagination) => {
        if (dbName && table) {
            this.setState({ loading: true }, () => {
                const fields = this.state.fields.filter(field => !field.field.startsWith("$match."));
                const matchFields = this.state.fields.filter(field => field.field.startsWith("$match."));
                // you can only look for ONE match group id and only EQUALS and only ONE value
                let filter = matchFields.length > 0 ?
                    {
                        fields: fields, masked: this.state.masked,
                        match: { type: matchFields[0].field.substring(7), group: matchFields[0].values[0] }
                    }
                    :
                    { fields: fields, masked: this.state.masked };

                // ignore invalid filters
                filter = this.cleanFilter(filter);
                const queryParams = {
                    database: dbName,
                    errorHandler: this.displayRecordsError,
                    table: table,
                    changeset: this.props.changeset.changeset,
                    include: this.state.include,
                    filter: filter,
                }

                RecordApi.queryRecords(queryParams, pagination)
                    .then(records => {
                        if (records.length >= this.getMaxRecords())
                            showToaster(
                                Intent.PRIMARY,
                                `Only the first ${this.getMaxRecords().toLocaleString()} records will be shown. Please refine results by editing your query.`
                            );
                        this.setState({ records: records, loading: false });
                    }).catch(err => {
                        const errorMessage = mdmErrorToText(err);
                        this.props.showToaster(Intent.ERROR, errorMessage);
                    })
            })
        }
    }

    private getRecordsInForm = async (fields, masked: boolean) => {
        this.setState(
            {
                fields: fields,
                masked: masked,
                loading: true,
                include: { ...this.state.include, mask: masked },
            },
            () => this.getRecords(this.props.dbName, (this.props.match.params as any).table),
        );
    }

    private paginateCurrentRecords = (pagination: Pagination) => {
        const { dbName, match } = this.props;
        this.getRecords(dbName, (match.params as any).table, pagination)
    }

    private updateQueryFilterOpen = (value?: boolean) => {
        if(value === true || value === false) {
            this.setState({isQueryFilterOpen: value });
            return value;
        }
        return this.state.isQueryFilterOpen;
    }

    private onTableChange = (option) => {
        const table = (this.props.match.params as any).table; 
        if(option.value && option.value !== table) {
            this.setQueryCount(0);
            this.setState({ fields: []});
            this.updateQueryFilterOpen(false);
            this.props.history.push(`/records/${option.value}`);
        }
    }

    private editRecord = id => {
        let record;
        if (id != null)
            record = this.state.records.find(
                rec => rec[(this.state.schema as any).primary_key] === id,
            );
        this.dialogEditRecord.open(record);
    }

    private saveRecord = record => {
        const table = (this.props.match.params as any).table;
        const options = {
            databaseName: this.props.dbName,
            changesetId: this.props.changeset.changeset,
            tableName: table,
            item: record,
        }

        RecordApi.upsertRecord(options)
            .then(() => {
                this.getRecords(this.props.dbName, table);
                this.props.showToaster(Intent.SUCCESS, "Saved Record");
            })
            .catch((error) => {
                error = error.error || error;
                this.props.displayMessageBox({
                    title: 'Save Record Failed',
                    message: `Failed to save record: ${mdmErrorToText(error)}`,
                    stack: error && error.stack
                });
            });
    }

    private deleteRecord = id => {
        if (confirm(`Delete Record ${id}?`)) {
            const options = {
                databaseName: this.props.dbName,
                changesetId: this.props.changeset.changeset,
                tableName: (this.props.match.params as any).table,
                primary: id,
            };

            RecordApi.deleteRecord(options)
                .then(() => {
                    this.getRecords(this.props.dbName, (this.props.match.params as any).table);
                    this.props.showToaster(Intent.SUCCESS, "Deleted record");
                })
        }
    }

    private toggleImportDataDialog = () => this.setState({
        isImportDataDialogOpen: !this.state.isImportDataDialogOpen
    })

    private toggleRecordHistoryDialog = e =>
        this.setState({ isRecordHistoryDialogOpen: !this.state.isRecordHistoryDialogOpen })

    private toggleRecordActivityDialog = e =>
        this.setState({ isRecordActivityDialogOpen: !this.state.isRecordActivityDialogOpen })

    private markPIIFields(records: any[]): any[] {
        const mark = '********';
        const piiFields = this.state.schema.fields
            .filter(f => f.pii || (f.schema && f.schema.fields.some(g => g.pii)))
            .reduce((acc, cur) => {
                cur.pii
                    ? (acc[cur.field] = mark)
                    : cur.schema.fields
                        .filter(f => f.pii)
                        .forEach(f => (acc = R.assocPath([cur.field, f.field], mark, acc)));
                return acc;
            }, {});
        const maskFunc = R.curry((piiFields, record) => R.mergeDeepRight(record, piiFields))(
            piiFields,
        );
        return R.map(r => (r.rpmdm_mask && r.rpmdm_mask.masked ? maskFunc(r) : r), records);
    }

    setQueryCount = (newCount: number) => {
        if (newCount !== this.state.queryCount)
            this.setState({ queryCount: newCount })
    }

    onClickRecordActivity = (id: string) => {
        const { getRecordActivity, dbName, changeset, match } = this.props;

        getRecordActivity(
            dbName,
            changeset.id,
            (match.params as any).table,
            id,
        ).then(activityRes => {
            console.log('activityRes', activityRes);
            this.setState({
                isRecordActivityDialogOpen: true,
                currentRecordID: id,
                recordActivity: activityRes?.payload ?? [],
            });
        }).catch(e => {
            console.error(e);
        });
    }

    onClickRecordHistory = (id: string) => {
        const { getRecordsHistory, dbName, changeset, match } = this.props;

        getRecordsHistory(
            dbName,
            changeset.id,
            (match.params as any).table,
            id,
        ).then(h => {
            this.setState({
                isRecordHistoryDialogOpen: true,
                currentRecordID: id,
                recordHistory: h.payload,
            });
        }).catch(e => {
            console.error(e);
        });
    }

    openImportDialog = () => {
        this.toggleImportDataDialog()
    }

    onImportData = async (data: any) => {
        const { dbName, match, username, showToaster } = this.props;
        let { changeset } = this.props;
        const table = (match.params as any).table;

        this.setState({ importing: true, recordsToUpload: data.length })
        if (changeset.changeset === 'current') {
            changeset = await createChangeset(dbName, `Importing data into ${table}`, username);
        }


        const options: {
            databaseName: string,
            changesetId: string,
            tableName: string,
            item?: any,
        } = {
            databaseName: dbName,
            changesetId: changeset.changeset,
            tableName: table,
        }


        const uploadRecords = async (records: any[]) => {
            // remove 30 records from the array
            const recordsToUpload = records.splice(0, 10000);
            
            await RecordApi.upsertRecords({
                ...options,
                records: recordsToUpload,
            });

            this.setState({ uploadedRecords: this.state.uploadedRecords + recordsToUpload.length })

            if (records.length) return uploadRecords(records);

            await commitChangeset(dbName, changeset);
            return Promise.resolve();
        }

        try {
            await uploadRecords(data)
            this.toggleImportDataDialog()
            showToaster(Intent.SUCCESS, 'Data imported successfully')
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            await discardChangeset(dbName, changeset);
        } finally {
            this.setState({ importing: false, recordsToUpload: 0, uploadedRecords: 0 })
        }
    }

    openExportDialog = () => {
        alert('export data');
    }

    public render() {
        const isDba = Boolean(inRoles(['dba']));
        const editRecs = (isDba && this.props.changeset.status === 'pending');

        // who should be able to create records? isDba? or isDba && this.props.changeset.status === 'pending'?
        const createButton = (this.props.match.params as any).table && editRecs ?
            <Button
                id="btnCreateRecord"
                key={1}
                value="CREATE RECORD"
                onClick={this.editRecord}
            /> : null;

        const importDataButton = isDba ?
            <Button
                id="btnImportData"
                key={2}
                value="IMPORT DATA"
                onClick={this.openImportDialog}
            /> : null;


        //console.debug('History', this.state.history);
        const containsPIIFields = hasPIIFields(this.state.schema);
        const records = containsPIIFields
            ? this.markPIIFields(this.state.records)
            : this.state.records;

        return (
            <MDMWrapper title="Data" buttons={[createButton, importDataButton]} documentationPath="query_records_in_table.html">
                <DialogEditRecord
                    tableName={(this.props.match.params as any).table}
                    ref={c => (this.dialogEditRecord = c)}
                    schema={this.state.schema}
                    saveRecord={this.saveRecord}
                />
                <RecordHistoryDialog
                    title="Record History"
                    isOpen={this.state.isRecordHistoryDialogOpen}
                    onClose={this.toggleRecordHistoryDialog}
                    history={this.state.recordHistory}
                    recordKey={this.state.currentRecordID}
                    currentTable={this.state.schema}
                />
                <RecordActivityDialog
                    title="Record Activity"
                    isOpen={this.state.isRecordActivityDialogOpen}
                    onClose={this.toggleRecordActivityDialog}
                    history={this.state.recordActivity}
                />

                <RecordsQueryBuilder
                    onTableChange={this.onTableChange}
                    currentTable={(this.props.match.params as any).table}
                    queryRecords={this.getRecordsInForm}
                    schema={this.state.schema}
                    tables={this.state.tables}
                    updateQueryFilterOpen={this.updateQueryFilterOpen}
                />

                <ImportDataDialog
                    isLoading={this.state.importing}
                    isOpen={this.state.isImportDataDialogOpen}
                    closeDialog={this.toggleImportDataDialog}
                    onSave={this.onImportData}
                    recordsToUploadCount={this.state.recordsToUpload}
                    uploadedRecords={this.state.uploadedRecords}
                />

                <RecordsMainRecordsTable
                    data={records}
                    currentTable={(this.props.match.params as any).table}
                    dbName={this.props.dbName}
                    changeset={this.props.changeset}
                    loading={this.state.loading}
                    paginate={(pagination: Pagination) => this.paginateCurrentRecords(pagination)}
                    editRecs={editRecs}
                    noDataText={(this.props.match.params as any).table ? 'No data found' : ''}
                    schema={this.state.schema}
                    onClickRecordEdit={id => this.editRecord(id)}
                    onClickRecordDelete={id => this.deleteRecord(id)}
                    onClickRecordHistory={this.onClickRecordHistory}
                    onClickRecordActivity={this.onClickRecordActivity}
                />
            </MDMWrapper>
        );
    }
}

export const RecordsMainContainer = connect(
    (state: any) => {
        const model = state?.login?.data?.model || {};
        const fullName = model.fullName || 'Unknown';

        return {
            dbName: state.database.selected,
            changeset: state.changeset,
            username: fullName,
        };
    },
    {
        displayMessageBox,
        getRecordsHistory,
        getRecordActivity,
        showToaster,
    },
)(RecordsMain);