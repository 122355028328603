import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, Drawer, Elevation, Icon, Intent, Tag } from '@blueprintjs/core';

import { ChangesetFilterForm } from './ChangesetFilterForm';
import { getOperationsSummary, useQueryChangesets } from 'api';
import { ChangesetSummaryTable } from './ChangesetSummaryTable';
import { doCommit, doDiscard, MDMWrapper } from 'components/Shared';
// import { MDMStore } from 'store';
// import { logout } from 'actions/login';

import { Changeset, ChangesetFilter, ChangesetStatus, OperationSummary } from 'types';
import { abandonTask } from 'store/workflow/actions';
import { Pagination } from 'rp-mdm-common';
import { RootState } from 'store';
import { formatTimeHHMM } from 'utils';
import { IconNames } from '@blueprintjs/icons';
import { useQueryClient } from '@tanstack/react-query';

interface ChangesetMainContainerProps {
    changeset: Changeset;
    dbName: string;
}

interface ChangesetMainContainerState {
    changesets: Changeset[];
    filter: ChangesetFilter;
    showAdvancedFilter: boolean;
    showDetails: boolean;
    details: OperationSummary[];
    detailsId: number;
}

const ChangesetMainForm = () => {
    const dbName = useSelector<RootState, string>(state => state.database.selected);
    const changeset = useSelector<RootState>(state => state.changeset);
    const [filter, setFilter] = React.useState<ChangesetFilter>({});
    const [showAdvancedFilter, setShowAdvancedFilter] = React.useState<boolean>(false);
    const [showDetails, setShowDetails] = React.useState<boolean>(false);
    const [details, setDetails] = React.useState<OperationSummary[]>([]);
    const [detailsId, setDetailsId] = React.useState<number>(-1);
    const [pagination, setPagination] = React.useState<Pagination>({ count: 10, start: 0 });
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { data: changesets, isLoading } = useQueryChangesets('changeset-main', {
        dbName,
        filter,
        pagination,
    });

    const onClose = () => setShowDetails(false);
    
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(filter => ({
            ...filter,
            [event.target.name]: event.target.value?.length ? event.target.value: undefined
        }));
    }

    const handleFilterDateChange = (date: { start: string, end: string }) => {
        setFilter(filter => ({
            ...filter,
            'create_timestamp': date
        }));
    }

    const handleFilterStatusChange = (status: ChangesetStatus) => {
        setFilter(filter => ({
            ...filter,
            'status': status?.length ? status : undefined
        }));
    }

    const handleShowAdvancedFilterChange = () => {
        setShowAdvancedFilter(showAdvancedFilter => !showAdvancedFilter);
    }

    const handleShowChangesetHistory = (changesetId: number) => {
        setShowDetails(true);
        setDetailsId(changesetId);
    }

    const getIntent = (status: ChangesetStatus) => {
        switch (status) {
            case ChangesetStatus.ERROR:
                return Intent.DANGER;
            case ChangesetStatus.COMMITTED:
                return Intent.SUCCESS;
            case ChangesetStatus.QUEUED:
                return Intent.PRIMARY;
            case ChangesetStatus.COMMITING:
                return Intent.WARNING;
            default:
                return Intent.NONE;
        }
    };

    const onDiscardTask = (workitem_id: number) => {
        dispatch(abandonTask(null, dbName, workitem_id));
    }

    const renderCards = () => {
        if (isLoading) {
            const skeleton = [];

            for(let i = 0;  pagination.count > i; i++) {
                skeleton.push(
                    <Card key={`changeset-card-skel-${i}`} className="mdm-changeset-card bp5-skeleton" style={{ height: 185 }} />
                );
            }

            return skeleton;
        }

        if (!changesets?.length) {
            return (
                <div>
                    <h5 className="bp5-heading">No changesets found</h5>
                </div>
            );
        }


        return changesets.map(change => {
            const onShowHistory = () => handleShowChangesetHistory(change.changeset);
            const onCommit = async () => {
                await doCommit(change, dbName);
                // If I have an associated workitem I need to discard that too
                if(change.workitem_id) {
                    onDiscardTask(change.workitem_id)
                }
                queryClient.invalidateQueries(['changeset-main']);
            };
            const onDiscard = async () => {
                await doDiscard(change, dbName);
                queryClient.invalidateQueries(['changeset-main']);
                if(change.workitem_id) {
                    onDiscardTask(change.workitem_id);
                }
            };

            return (
                <Card
                    className="mdm-changeset-card"
                    key={change.changeset}
                    elevation={Elevation.TWO}
                >
                    <div className="card-header">
                        <div className="card-header-title">
                            <h5 className="bp5-heading">
                                {change.changeset} - {change.description} 
                            </h5>
                        </div>
                    </div>
                    <div className="card-content">
                        <div className="card-content-text">
                            <div className="tag-row">
                                <Tag
                                    minimal={true}
                                    icon={IconNames.TAG}
                                    intent={Intent.PRIMARY}
                                >
                                    {change.category}
                                </Tag>
                                <Tag aria-label="status-tag" intent={getIntent(change.status)}>
                                    {change.status}
                                </Tag>
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                <div style={{ flex: 1 }}>
                                    <div>Created By: {change.create_user}</div>
                                    <div>Created On: {change.create_timestamp ? formatTimeHHMM(change.create_timestamp) : ''}</div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div>Commited By: {change?.commit_user ?? ''}</div>
                                    <div>Commited On: {change.commit_timestamp ? formatTimeHHMM(change.commit_timestamp) : ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div>
                            <Button
                                aria-label={`History ${change.changeset}`}
                                className='history-button'
                                icon={IconNames.CALENDAR}
                                minimal={true}
                                onClick={onShowHistory}>
                                &nbsp; History
                            </Button>
                        </div>
                        <div>
                            <Button
                                aria-label={`Commit ${change.changeset}`}
                                className='history-button'
                                icon={IconNames.TICK}
                                minimal={true}
                                disabled={change.status !== 'pending'}
                                onClick={onCommit}>
                                &nbsp; Commit
                            </Button>
                        </div>
                        <div>
                            <Button
                                aria-label={`Discard ${change.changeset}`}
                                className='history-button'
                                icon={IconNames.TRASH}
                                minimal={true}
                                disabled={change.status !== 'pending'}
                                onClick={onDiscard}>
                                &nbsp; Discard
                            </Button>
                        </div>
                    </div>
                </Card>
            )
        })
    }

    const onPreviousPage = () => setPagination(pagination => ({ ...pagination, start: pagination.start - pagination.count }));
    const onNextPage = () => setPagination(pagination => ({ ...pagination, start: pagination.start + pagination.count }));
    const changePageSize = (count: number) => setPagination(pagination => ({ ...pagination, count }));
    React.useEffect(() => {
        setPagination(pagination => ({ ...pagination, start: 0 }));
    }, [filter]);

    return (
        <MDMWrapper title="Changesets" documentationPath='viewing_changesets.html?q=changeset'>
            <div className='changesets-tools-wrapper'>
                <Drawer isOpen={showDetails} onClose={onClose}>
                    <ChangesetSummaryTable changesetId={detailsId} />
                </Drawer>
                <ChangesetFilterForm
                    filter={filter}
                    showAdvancedFilter={showAdvancedFilter}
                    handleFilterChange={handleFilterChange}
                    handleFilterDateChange={handleFilterDateChange}
                    handleFilterStatusChange={handleFilterStatusChange}
                    handleShowAdvancedFilterChange={handleShowAdvancedFilterChange}
                />
            </div>

            <div className="changeset-paginator">
                <div className="flex-center bp5-text-muted">
                        Items per page {" "}
                    <select className="paginator-input" value={pagination.count} onChange={(e) => setPagination(p => ({
                        ...p,
                        count: parseInt(e.target.value)
                    }))}>
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="margin-left flex-end">
                    <Button className="component-pagination-button" onClick={onPreviousPage} disabled={pagination.start === 0}>
                        <Icon icon={IconNames.CHEVRON_LEFT} />
                    </Button>
                    <Button className="component-pagination-button button-margin-left" onClick={onNextPage} disabled={(changesets?.length ?? 0) < 10}>
                        <Icon icon={IconNames.CHEVRON_RIGHT} />
                    </Button>
                </div>
            </div>
            <div className="mdm-changeset-cards">
                {renderCards()}
            </div>
            <div className="changeset-paginator">
                <div className="flex-center bp5-text-muted">
                        Items per page {" "}
                    <select className="paginator-input" value={pagination.count} onChange={(e) => setPagination(p => ({
                        ...p,
                        count: parseInt(e.target.value)
                    }))}>
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="margin-left flex-end">
                    <Button className="component-pagination-button" onClick={onPreviousPage} disabled={pagination.start === 0}>
                        <Icon icon={IconNames.CHEVRON_LEFT} />
                    </Button>
                    <Button className="component-pagination-button button-margin-left" onClick={onNextPage} disabled={(changesets?.length ?? 0) < 10}>
                        <Icon icon={IconNames.CHEVRON_RIGHT} />
                    </Button>
                </div>
            </div>
        </MDMWrapper>
    );
}

// export class ChangesetMainContainer extends React.Component<ChangesetMainContainerProps, ChangesetMainContainerState> {

//     constructor(props) {
//         super(props);
//         this.state = {
//             changesets: [],
//             filter: {},
//             showAdvancedFilter: false,
//             showDetails: false,
//             details: null,
//             detailsId: -1
//         };
//     }

//     componentDidMount = () => {
//         this.filterAndQueryChangesets();
//     }

//     componentDidUpdate = (prevProps, prevState) => {
//         if (this.props.dbName !== prevProps.dbName || this.state.filter !== prevState.filter ||
//             this.state.showAdvancedFilter !== prevState.showAdvancedFilter || this.props.changeset !== prevProps.changeset) {
//             this.filterAndQueryChangesets();
//         }
//     }

//     filterAndQueryChangesets = (pagination?: Pagination) => {
//         // Only include "advanced" filter elements if UI is showing
//         const newFilter = Object.assign({}, this.state.showAdvancedFilter ?
//             this.state.filter : { status: this.state.filter.status });

//         queryChangesets(this.props.dbName, newFilter, null, pagination)
//             .then(changesets => {
//                 this.setState({ changesets: changesets })
//             });
//     }

//     handleFilterDateChange = (dateObj) => {
//         const { filter } = this.state;
//         this.setState({ filter: { ...filter, create_timestamp: dateObj } });
//     }

//     // SELECT * FROM ChangeSets WHERE STATUS=myStatusFilter LIMIT PageLimit
//     handleFilterStatusChange = (status) => {
//         this.setState({ filter: { status: status === '' ? undefined : status } });
//         this.filterAndQueryChangesets();
//     }

//     handleFilterChange = (event) => {
//         const newFilter = { ...this.state.filter, [event.target.name]: event.target.value };
//         this.setState({ filter: newFilter });
//     }

//     handleShowAdvancedFilterChange = () => {
//         this.setState({ showAdvancedFilter: !this.state.showAdvancedFilter, filter: { status: this.state.filter.status } });
//     }

//     handleShowChangesetHistory = (changesetId: number) =>
//         getOperationsSummary(this.props.dbName, changesetId).then(ops => this.setState({ showDetails: true, details: ops, detailsId: changesetId }))

//     render() {
//         return (
//             <MDMWrapper title="Changesets" documentationPath='viewing_changesets.html?q=changeset'>
//                 <div className='changesets-tools-wrapper'>
//                     <Drawer isOpen={this.state.showDetails} onClose={() => this.setState({ showDetails: false })}>
//                         <ChangesetSummaryTable changesetId={this.state.detailsId} operations={this.state.details} />
//                     </Drawer>
//                     <ChangesetFilterForm
//                         {...this.state}
//                         handleFilterChange={e => this.handleFilterChange(e)}
//                         handleFilterDateChange={this.handleFilterDateChange}
//                         handleFilterStatusChange={this.handleFilterStatusChange}
//                         handleShowAdvancedFilterChange={() => this.handleShowAdvancedFilterChange()}
//                     />
//                 </div>
//                 <ChangesetsTable
//                     changesets={this.state.changesets}
//                     dbName={this.props.dbName}
//                     onShowHistory={this.handleShowChangesetHistory}
//                     paginate={this.filterAndQueryChangesets}
//                 />
//             </MDMWrapper>
//         );
//     }
// }
// // tslint:disable-next-line:no-default-export
// export default connect(
//     (state: any) => ({ changeset: state.changeset, dbName: state.database.selected })
// )(ChangesetMainContainer);


export default ChangesetMainForm;