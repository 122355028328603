import * as React from 'react';
import './PageNotFound.scss'
import { withRouter, RouteComponentProps } from "react-router-dom";

export class PageNotFound extends React.Component<RouteComponentProps> {

    render() {
        const { history } = this.props;
        return (
            <div className="not-found-message-container">
                <div className="not-found-message flex-column">
                    <span className="code flex-center">404</span>
                    <span className="message flex-center">Page not found</span>

                    <button
                        type="button"
                        className="minimal-button"
                        onClick={() => history.push('/') }
                    >
                            Go Home
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(PageNotFound)