import * as React from 'react';
import { Collapse, EditableText, Icon } from '@blueprintjs/core';

import { History } from 'types';
import { NoteBox } from './NoteBox';
import { IconNames } from '@blueprintjs/icons';

require('./NotesTable.scss');

export interface NotesTableProps {
    history: History[];
    note: string;
    updateNote: (newValue: string) => void;
}
export interface NotesTableState {
    isOpen: boolean;
}

export class NotesTable extends React.Component<NotesTableProps, NotesTableState> {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }
    // TBD. Do I need a componentDidMount() to force logout?  Probably not
    private getNotes(history: History[]): JSX.Element[] {
        return history
            .filter(h => h.note)
            .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
            .map((h, i) => <NoteBox key={i} {...h} />);
    }
    render() {
        const notes = this.getNotes(this.props.history);
        return <div className="notes-container">
            <div className="notes-header">
                <div className="header-content">
                    {`Comments - ${notes.length}`}
                </div>
                <Icon icon={this.state.isOpen ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT}
                    iconSize={20} onClick={e => this.setState({ isOpen: !this.state.isOpen })}
                />
            </div>
            <div className="notes-body">
                <div className="note-entry">
                    <EditableText onChange={(e) => this.props.updateNote(e)} onCancel={(val) => this.props.updateNote(val)}
                        multiline={true} minLines={2} placeholder={'Write a comment'} value={this.props.note} />
                </div>
                <Collapse isOpen={this.state.isOpen}>
                    {notes}
                </Collapse>
            </div>
        </div>;
    }
}
