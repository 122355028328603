export const RECEIVE_ABANDONED_TASK = 'RECEIVE_ABANDONED_TASK';
export const REQUEST_APPROVE_TASK = 'REQUEST_APPROVE_TASK';
export const RECEIVE_APPROVE_TASK = 'RECEIVE_APPROVE_TASK';
export const RECEIVE_ASSIGNED_WORKFLOWS = 'RECEIVE_ASSIGNED_WORKFLOWS';
export const REQUEST_AVAILABLE_WORKFLOWS = 'REQUEST_AVAILABLE_WORKFLOWS';
export const RECEIVE_AVAILABLE_WORKFLOWS = 'RECEIVE_AVAILABLE_WORKFLOWS';
export const RECEIVE_CLAIM_TASK = 'RECEIVE_CLAIM_TASK';
export const REQUEST_COMPLETE_TASK = 'REQUEST_COMPLETE_TASK';
export const RECEIVE_COMPLETE_TASK = 'RECEIVE_COMPLETE_TASK';
export const REQUEST_CONTINUE_TASK = 'REQUEST_CONTINUE_TASK';
export const RECEIVE_CONTINUE_TASK = 'RECEIVE_CONTINUE_TASK';
export const RECEIVE_DISCARD_ASSIGNED_TASK = 'RECEIVE_DISCARD_ASSIGNED_TASK';
export const RECEIVE_DISCARD_AVAILABLE_TASK = 'RECEIVE_DISCARD_AVAILABLE_TASK';
export const REQUEST_REJECT_TASK = 'REQUEST_REJECT_TASK';
export const RECEIVE_REJECT_TASK = 'RECEIVE_REJECT_TASK';
export const RECEIVE_RELEASE_TASK = 'RECEIVE_RELEASE_TASK';
export const REQUEST_SAVE_TASK = 'REQUEST_SAVE_TASK';
export const RECEIVE_SAVE_TASK = 'RECEIVE_SAVE_TASK';
export const REQUEST_SUBMIT_MATCH_OVERRIDE = 'REQUEST_SUBMIT_MATCH_OVERRIDE';
export const REQUEST_START_WORKFLOW = 'REQUEST_START_WORKFLOW';
export const RECEIVE_START_WORKFLOW = 'RECEIVE_START_WORKFLOW';
export const REQUEST_STARTED_WORKFLOWS = 'REQUEST_STARTED_WORKFLOWS';
export const REQUEST_STARTABLE_WORKFLOWS = 'REQUEST_STARTABLE_WORKFLOWS';
export const RECEIVE_STARTABLE_WORKFLOWS = 'RECEIVE_STARTABLE_WORKFLOWS';
export const CLEAR_WORKFLOWS = 'CLEAR_WORKFLOWS';
export const CLEAR_TASK_RESULT = 'CLEAR_TASK_RESULT';