
import { ITreeNode } from "@blueprintjs/core";

import { v4 as uuidv4 } from 'uuid';
import { IPermNode } from './IPermNode';


export class PermSubscriptionNode implements IPermNode {
    id:string;
    name: JSX.Element|string;
    type:string;
    isOpen: false;
    canBeSelected: boolean;
    isLeaf: boolean;
    openIcon: string;
    closedIcon: string;
    children: IPermNode[];
    parent: IPermNode;
    subscription_id: number;
    description: string;

    constructor(name: JSX.Element|string, subscription_id:number, description:string, parent:IPermNode) {
        this.id = uuidv4();
        this.name = name;
        this.type = 'subscription';
        this.isOpen = false;
        this.canBeSelected = true;
        this.isLeaf = true;
        this.openIcon = 'envelope';
        this.closedIcon = 'envelope'; // unused
        this.children = [];
        this.parent = parent;
        this.subscription_id = subscription_id;
        this.description = description;
    }

    async getChildrenFn() {
        return await false;
    }

    fullNameFn() {
        //const parentName = this.parent ? this.parent.fullNameFn() : "";
        //return parentName ? `${parentName}.${String(this.subscription_id)}` : String(this.subscription_id);
        return `${this.parent.fullNameFn()}.${this.subscription_id.toString()}`;
    }

    myName() {
        return this.subscription_id.toString();
    }

    header() {
        return `Subscription: ${this.description}`;
    }

    getBPNode() {
        return {
            id: this.id,
            hasCaret: false,
            isExpanded: false,
            label: this.name,
            iconName: this.openIcon,
        } as ITreeNode;
    }
}
