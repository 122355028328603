import { REQUEST_QUERY_DATABASES, RECEIVE_QUERY_DATABASES, DATABASE_DELETED, SET_DBNAME } from './types';
import { SET_CHANGESET } from '../../constants';
import { displayMessageBox } from "actions/ActionCreators";
import { DatabaseApi, UserApi } from 'api';
import { MDMStore } from 'store';

export const setDatabase = (newDbName: string) => {
    return ((dispatch, getState) => {
        // ignore requests to change the dbname to the same dbname
        const { dbName } = getState();
        if (newDbName !== dbName) {
            const expiry = new Date();
            expiry.setDate(expiry.getDate() + 10000);
            UserApi.setUserState('current_db', newDbName || '', expiry)
                .then((res) => {
                    const changeset = { changeset: 'current' };
                    dispatch({ type: SET_CHANGESET, changeset: changeset });
                    dispatch({ type: SET_DBNAME, dbName: newDbName });
                });
        }
    });
};

export const queryDatabases = () => {
    return dispatch => {
        dispatch({ type: REQUEST_QUERY_DATABASES });
        DatabaseApi.list().then(
            (result: any) => {
                dispatch({ type: RECEIVE_QUERY_DATABASES, payload: result || [] });
            },
            err => {
                err = err.error || err;
                // If you have messages
                let message = 'Unable to connect to the database';
                if (err && err.messages) {
                    message = err.messages.join("\n");
                }
                else if (err.response_error) {
                    message = err.response_error;
                }
                else if (typeof err === 'string') {
                    message = err;
                }
                const errMsg = "Couldn't query databases: " + message;
                dispatch(displayMessageBox({
                    type: 'err',
                    title: "Cannot Query Databases",
                    message: message,
                    stack: err && err.stack
                }));
                dispatch({ type: RECEIVE_QUERY_DATABASES, payload: [] });
            }
        );
    };
};

export const deleteDatabase = (dbName) => {
    return dispatch => {
        DatabaseApi.deleteDb(dbName).then(
            () => {
                dispatch({ type: DATABASE_DELETED, payload: dbName });
            },
            error => {
                error = error.error || error.response_error || error;
                MDMStore.dispatch<any>(displayMessageBox({
                    title: 'Delete Database Failed',
                    message: `Failed to delete database ${dbName}`,
                }));
            }
        );
    };
};
