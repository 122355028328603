import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IdleTimerProvider } from 'react-idle-timer';
import { Spinner } from "@blueprintjs/core";

import { TopBar } from 'components/TopBar';
import { DialogChooseDatabase, DialogCreateDatabase } from 'components/Shared';
import { inRoles } from 'api';
import { MDMRoutes } from './MDMRoutes';
import { SideNavMenu } from 'components/SideNav';
import { MessageBox } from 'components/MessageBox';
import { UserStatus } from 'helpers';
import { PeriodicFetch } from './PeriodicFetch';
import { Intent } from 'types';
import { queryDatabases } from 'store/database/actions';
import { logout, showToaster } from 'actions';
import { setUserGroups, setUserSettings } from '../../store/user/actions';
import { loadPermissions } from 'store/permissions/actions';
import { UserApi } from '../../api/UserApi';
import { DatabaseApi } from '../../api/DatabaseApi';
import { generateMenu } from 'components/SideNav/Menu';
import { setBadgeFetchFlag } from 'actions/systemSettings';
import { WorkitemRedirectHandler } from '../Workflow/WorkitemRedirectHandler';
import { LicenseForm } from './LicenseForm';

import { REFRESH_BADGES_KEY, USER_SETTING_KEY } from '../../constants/settings';
import { setLicenseInfo } from 'store/license/actions';

export interface MDMContainerProps {
    history: any;
}


const MDMContainer: React.FC<MDMContainerProps> = ({ history }) => {
    const {
        databases,
        queriedDatabases,
        dbName,
        loginData,
        messageboxoptions,
        username,
        systemUsername,
        idleLogoutMilliseconds,
        authConfigLoaded,
        groups,
        licenseInfo,
    } = useSelector((state: any) => ({
        databases: state.database.all,
        queriedDatabases: state.database.queriedAll,
        dbName: state.database.selected,
        loginData: state.login.data,
        messageboxoptions: state.messageboxoptions.options,
        username: state.username,
        groups: state.user.groups,
        systemUsername: state.auth.config?.systemUsername,
        idleLogoutMilliseconds: state.auth.config?.idleLogoutMilliseconds,
        authConfigLoaded: !!state.auth.config,
        licenseInfo: state.license,
    }));
    const [logoutMilliseconds, setLogoutMilliseconds] = React.useState(1000 * 60 * 30);
    const canCreate = React.useMemo(() => inRoles(['dba', 'steward']), [groups]);
    const dispatch = useDispatch();
    const showChooseDB = React.useMemo(() => {
        return (dbName === null || !databases.some(db => db.database === dbName));
    }, [dbName, databases]); 
  
    const handleOnActive = React.useCallback(() => UserStatus.setIdle(false), []);

    const handleOnIdle = React.useCallback(() => {
        UserStatus.setIdle(true);
        dispatch(logout("You were logged out due to inactivity"));
    }, []);
  
    const getPermissions = React.useCallback(() => {
        if (dbName) {
            dispatch(loadPermissions(dbName));
        }
    }, [dbName]);
    
    const getUserGroups = React.useCallback(async () => {
        try {
            const groups = await UserApi.getUserGroups(username);
            dispatch(setUserGroups(groups));
        } catch (error) {
            console.error(error);
        }
    }, [username]);

    const getLicenseInfo = React.useCallback(async () => {
        try {
            const licenseInfo = await DatabaseApi.getLicenseInfo(false);
            dispatch(setLicenseInfo(licenseInfo));
            const { licensed, remainingDays } = licenseInfo;
            if (licensed) {
                if (remainingDays <= 10) {
                    showToaster(Intent.WARNING, `Your MDM License will expire in ${remainingDays}.`)
                }
                dispatch(queryDatabases());
            }
        } catch (error) {
            console.error(error);
        }
    }, []);
    
    const getBadgeRefreshFlag = React.useCallback(async () => {
        try {
            const fetchedVal = await UserApi.getSystemUserState(REFRESH_BADGES_KEY, systemUsername);
            const badgeFlag = fetchedVal === 'true' || !fetchedVal;
            dispatch(setBadgeFetchFlag(badgeFlag));
        } catch (error) {
            console.error(error || "Couldnt get badge refresh flag.");
        }
    }, [systemUsername]);
    
    const getUserSettings = React.useCallback(async () => {
        try {
            const fetchedVal = await UserApi.getUserState(USER_SETTING_KEY);

            if (fetchedVal) {
                try {
                    const settings = JSON.parse(fetchedVal);
                    dispatch(setUserSettings(settings));
                } catch (e) {
                    console.error("No valid settings structure: ", e)
                }
            }
        } catch (error) {
            console.error(error || "Couldnt user settings.");
        }
    }, []);

    React.useEffect(() => {
        getPermissions();
    }, [dbName, getPermissions]);

    React.useEffect(() => {
        if (!!loginData) {
            getPermissions();
            getUserGroups();
            getLicenseInfo();
            getBadgeRefreshFlag();
            getUserSettings();
        }
    }, [loginData, getPermissions, getUserGroups, getLicenseInfo, getBadgeRefreshFlag, getUserSettings]);

    React.useEffect(() => {
        let logoutMilliseconds: number;
        if (!idleLogoutMilliseconds) return;

        if(typeof idleLogoutMilliseconds === 'string'){
            logoutMilliseconds = parseInt(idleLogoutMilliseconds);
        } else {
            logoutMilliseconds = idleLogoutMilliseconds;
        }

        setLogoutMilliseconds(logoutMilliseconds);  
    }, [idleLogoutMilliseconds])


    if (!loginData) {
        return <Redirect to="/login" />;
    }


    if (!licenseInfo.licensed || !queriedDatabases || !authConfigLoaded) {
        return (
            <div className="app fill">
                <TopBar history={history} minimal />
                <MessageBox {...messageboxoptions} />
                {
                    !licenseInfo.loading &&
                    <LicenseForm isPage={false} />
                }
                {
                    licenseInfo.loading &&
                    <div className="fill flex-center">
                        <Spinner />
                    </div>
                }
            </div>
        )
    }


    return (
        <IdleTimerProvider
            timeout={logoutMilliseconds}
            onIdle={handleOnIdle}
            onActive={handleOnActive}
            onAction={handleOnActive}
            startOnMount
        >
            <div id="app" className="app">
                {licenseInfo.data ?
                    <LicenseForm isPage={false} /> :
                        licenseInfo.loading &&
                        <div className="fill flex-center">
                            <Spinner />
                        </div>
                }
                <PeriodicFetch />
                <WorkitemRedirectHandler history={history} />
                <MessageBox {...messageboxoptions} />
                <DialogCreateDatabase />
                <DialogChooseDatabase
                    id="choosedatabasedlg"
                    canCreate={canCreate}
                    show={showChooseDB}
                />
                <TopBar history={history} />
                <div className='mdm-flex-container'>
                    <SideNavMenu history={history} menu={generateMenu()} />
                    <MDMRoutes />
                </div>
            </div>
      </IdleTimerProvider>
    );
};
  
export default MDMContainer;