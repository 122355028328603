import * as React from 'react';
import { Icon, Intent, Position } from '@blueprintjs/core';

import { Button } from 'components/Shared/Button';
import { WFNotesTable } from 'components/NotesTable/WFNotesTable';
import { History, Schema, Workflow } from 'types';
import { WorkflowWidgets } from '../WorkflowWidgets';
import { Tooltip2 } from '@blueprintjs/popover2';

require('helpers/styles/workflow.scss');
require('./WorkflowNote.scss');

export interface WorkflowNoteFormProps {
    history: History[];
    workflow: Workflow;
    completeTask: (note: string) => void;
    discardTask: (note: string) => void;
    saveTask: (note: string) => void;
}
export interface WorkflowNoteFormState {
    note: string;
}

export class WorkflowNoteForm extends React.Component<WorkflowNoteFormProps, WorkflowNoteFormState> {
    constructor(props: WorkflowNoteFormProps) {
        super(props);
        this.state = {
            note: ''
        };
    }

    componentDidUpdate(prevProps: WorkflowNoteFormProps, prevState) {
    }

    private onNoteChange = (newNote: string) => {
        this.setState({ note: newNote });
    }

    private completeTask = () => {
        this.props.completeTask(this.state.note);
    }

    private discardTask = () => {
        this.props.discardTask(this.state.note);
    }

    private saveTask = () => {
        this.props.saveTask(this.state.note);
        // Reset the note to empty. Doesn't make sense to leave it.
        this.setState({ note: '' });
    }

    render() {
        const { workflow } = this.props;

        return (
            <div className="workflow-wrapper">
                <div className="workflow-menu-wrapper">
                    <div className="workflow-menu">
                        <div className="workflow-title">
                            Send a Note
                            <Tooltip2 content="Documentation" position={Position.RIGHT}>
                                <div className="mdm-wrapper-docs-button flex-center">
                                    <Icon icon='help' onClick={() => { window.open(`/mdm-help/workflow.html`, 'mdm-help'); }} color="#636567" iconSize={20} />
                                </div>
                            </Tooltip2>
                        </div>
                        <div className="workflow-title-buttons">
                            <Button value="SAVE" onClick={this.saveTask} />
                            <Button value="DISCARD" intent={Intent.DANGER} onClick={this.discardTask} />
                            <Button value="SUBMIT" intent={Intent.SUCCESS} onClick={this.completeTask} />
                        </div>
                    </div>
                    <WorkflowWidgets tableName={workflow.tableName} workitemId={workflow.workitem_id} />
                </div>
                <WFNotesTable history={this.props.history} note={this.state.note} updateNote={this.onNoteChange} sortForward={true} />
            </div>
        );
    }
}