import * as React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast, ToastOptions, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Intent } from 'types';

export interface ToastHandlerProps {
    showToaster?: any;
}

export interface ToastHandlerState {
    toastErrorQueue: string[];
}

export class ToastHandler extends React.Component<ToastHandlerProps, ToastHandlerState> {
    constructor(props) {
        super(props);
        this.state = {
            toastErrorQueue: []
        };
    }

    spamLimit = 3;
    queueLength = 10;

    updateErrorQueue = (newMessage: string) => {
        const { toastErrorQueue } = this.state;
        let newQueue = [...toastErrorQueue];
        newQueue.push(newMessage);
        if (newQueue.length > this.queueLength) {
            newQueue.shift();
        }
        this.setState({ toastErrorQueue: newQueue })
    }

    checkIsSpam = (newMessage: string): boolean => {
        const { toastErrorQueue } = this.state;
        if (toastErrorQueue.length >= this.spamLimit) {
            const lastThreeLogs = toastErrorQueue.slice(toastErrorQueue.length - this.spamLimit, toastErrorQueue.length);
            const foundDifferent = lastThreeLogs.find(logMessage => logMessage !== newMessage)
            return !foundDifferent
        }
        return false;
    }

    showToast = (message: string, intent?: Intent, position?: ToastPosition) => {
        let pos = position || toast.POSITION.BOTTOM_RIGHT

        let toastOptions: ToastOptions = {
            position: pos as ToastPosition
        }

        switch (intent) {
            case Intent.SUCCESS:
                toast.success(message, toastOptions);
                break;
            case Intent.WARNING:
                toast.warn(message, toastOptions);
                break;
            case Intent.ERROR:
            case Intent.DANGER:
                const isSpam = this.checkIsSpam(message);
                if (!isSpam) {
                    toast.error(message, toastOptions);
                    this.updateErrorQueue(message)
                }
                break;
            default:
                toast.info(message, toastOptions);
        }

    }

    componentDidUpdate(prevProps) {
        let { showToaster } = this.props;

        if (!showToaster || !prevProps.showToaster)
            return;

        let { intent, message, position, timestamp } = showToaster;

        if (prevProps.showToaster.timestamp != timestamp)
            this.showToast(message, intent as Intent, position as ToastPosition);
    }

    render() {
        return <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar
            closeOnClick
            limit={5}
            draggable
        />
    }
};

export const mapStateToProps = state => ({ showToaster: state.showtoaster });

export default connect(mapStateToProps, null)(ToastHandler);
