import * as React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror17';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';

import { Button } from 'components/Shared';
import './View.scss';

export interface ViewEditFormProps {
    error: string;
    readOnly: boolean;
    status: string;
    updateViewJSON: (JSON: string) => void;
    validateViewJSON: () => void;
    viewJSON: string;
}

export const ViewEditForm: React.FC<ViewEditFormProps> = (props) => {
    const errClass = props.error ? 'view-edit-json-footer-status-error' : '';
    const status = props.error || props.status;
    const footer = props.readOnly ? null :
        <div className="view-edit-json-footer">
            <div className={errClass}>{status}</div>
            <div className="view-edit-json-footer-buttons">
                <Button disabled={props.readOnly} value="CLEAR" onClick={() => { props.updateViewJSON(''); }} />
                <Button value="VALIDATE" onClick={props.validateViewJSON} />
            </div>
        </div>;

    return (
        <div className="view-form-container">
            <div className="view-edit-title">{props.readOnly ? '' : 'ENTER IN YOUR JSON AND VALIDATE'}</div>
            <div className="view-edit-json-container">
                <div className="view-edit-json-header">
                    JSON
                </div>
                <div className="view-edit-json-body">
                    <CodeMirror
                        value={props.viewJSON}
                        onBeforeChange={(editor, data, value) => props.updateViewJSON(value)}
                        data-name="viewJSON"
                        options={{ lineNumbers: true, mode: "application/json", readOnly: props.readOnly, lineWrapping: true }}
                    />
                </div>
                {footer}
            </div>
        </div>
    );
};
