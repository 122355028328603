/**
 * @format
 */
import { createStore, applyMiddleware, compose } from 'redux';
import { persistCombineReducers, persistStore, Persistor } from 'redux-persist';

import { Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';

import reduxLogger from 'redux-logger';
import { reducers } from '../reducers';
import storageSession from 'redux-persist/lib/storage/session';
import { storeRehydrated } from 'actions/login';

const config = {
    key: 'root',
    storage: storageSession,
    whitelist: [
        'changeset',
        'computedViews',
        'currentWorkflow',
        'database',
        'menu',
        'roles',
        'userRoles',
        'routing',
        'username',
        'user',
        'workflow',
        'login',
        'auth',
        'permissions',
        'license'
    ],
};

const appReducer = persistCombineReducers(config, reducers);

export type RootState = ReturnType<typeof appReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

const middlewares = [thunk];
if (process.env.NODE_ENV === `development`) {
    middlewares.push(reduxLogger);
}

export const MDMStore = createStore(
    appReducer,
    undefined,
    compose(applyMiddleware(...middlewares)),
);

// utility for testing
export const configStore = (preloadedState: RootState) => {
    return createStore(
        appReducer,
        preloadedState,
        compose(applyMiddleware(...middlewares)),
    );
};

let thePersistor: Persistor;
// Note, the rehydrate callback here is essential to check for user logged in state!
export const persistor = (): Persistor => {
    if (!thePersistor) refreshPersistor();
    return thePersistor;
};
export const refreshPersistor = () => {
    thePersistor = persistStore(MDMStore, null, () => MDMStore.dispatch<any>(storeRehydrated()));
    if (!thePersistor)
        alert("Unable to restore persistor! Things will not go well.");
};

// tslint:disable-next-line:no-default-export
//export default MDMStore;
