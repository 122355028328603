import * as React from 'react';
import { Popover, PopoverInteractionKind } from '@blueprintjs/core';

import { Field, Schema } from 'types';
import { toTitlecase } from 'utils';
import * as moment from 'moment';
import { formatDateTimeDisplay } from 'helpers/date-format';

export interface RecordsTableColumn {
    Header: string;
    accessor?: string;
}

export interface IReactTableBaseProps {
    data: object[];
    defaultPageSize?: number;
    pageSize?: number;
    minRows?: number;
    noDataText?: string;
}
export interface IReactTableProps extends IReactTableBaseProps {
    columns: RecordsTableColumn[];
}

export type GetCellFunc = (tooltipText: string, field: Field, cell: any, row: any) => JSX.Element;

export interface IBaseRecordsTableProps extends IReactTableBaseProps {
    getCell?: GetCellFunc;
    // columns can be completely overriden outside of the table props, like 
    // {...getTableProps(),
    // columns={etc}
    // }
    // If specified in getTableProps like 
    //{...getTableProps({
    //    getColumns: () => {etc}
    //})}
    // The returned array is concatenated to the base columns
    getColumns?: (getCell: GetCellFunc, schema: Schema, tooltipText?: string) => RecordsTableColumn[];
    schema: Schema;
    tooltipText?: string;
}
export interface IRecordsTableProps extends IBaseRecordsTableProps {
    children: (state: any) => false | JSX.Element;
}

export class RecordsTable extends React.PureComponent<IRecordsTableProps> {

    private getCell(tooltipText: string, field: Field, cell: any) {
        let tooltip = tooltipText || cell.value;

        const isTable = !!field?.schema?.fields?.length;
        const isLink = field?.link_display_field && field?.links_to;
        if (isTable || isLink) {
            return <div>{cell.value}</div>
        }

        let val = cell.value;

        
        val =  formatDateTimeDisplay(val, field);


        return <Popover popoverClassName="bp5-popover-content-sizing" interactionKind={PopoverInteractionKind.HOVER} hoverOpenDelay={80} hoverCloseDelay={80} content={tooltip}>
            <div>{val}</div>
        </Popover>;
    }

    private getColumns(getCell: GetCellFunc, schema: Schema, tooltipText?: string): RecordsTableColumn[] {
        if (!schema) return [{ Header: '', id: 'no-schema' }];
        let result = [];

        // TODO accessor to one row after debugging
        if (schema.matching && schema.matching.enabled && schema.matching.display) {
            const onlyOne = schema.matching.match_types.length == 1;
            result = schema.matching.match_types.map((type: any, index) => {
                // default gets to be named
                return ({
                    Header: onlyOne ? "GROUP ID" : toTitlecase(type.type) + " GROUP ID",
                    id: `matchid${index}`,
                    accessor: (row: any) => {
                        return (row.rpmdm_match && row.rpmdm_match[type.type] && row.rpmdm_match[type.type].group && row.rpmdm_match[type.type].group.id) || ''
                    },
                    Cell: ({ cell, row }) => this.getCell(tooltipText, type, cell)
                });
            });
        }

        return result.concat(schema.fields
            .filter(field => field.schema ? field.schema.fields.some(f => f.display) : field.display)
            .map((field, index) => {
                return ({
                    id: `${index}-${field.field}`,
                    Header: field.field,
                    accessor: field.field,
                    Cell: ({ cell, row }) => getCell(tooltipText, field, cell, row)
                })
            }));
    }
    private getTableProps = (props: IRecordsTableProps): IReactTableProps => {
        const { getColumns, data, defaultPageSize, noDataText, schema, tooltipText } = props;
        const getCell = props.getCell || this.getCell;
        return {
            columns: getColumns ? this.getColumns(getCell, schema, tooltipText).concat(getColumns(getCell, schema, tooltipText)) :
                this.getColumns(getCell, schema, tooltipText),
            data: data,
            defaultPageSize: defaultPageSize || 20,
            minRows: 1,
            noDataText: noDataText || 'No data found'
        };
    }
    private getStateAndHelpers() {
        return {
            getTableProps: this.getTableProps
        };
    }
    public render() {
        return this.props.children(this.getStateAndHelpers());
    }
}
