import { createSelector } from 'reselect';

const assignedWorkitemsSelector = state => state.assigned;

export const getAssignedWorkitems = createSelector(
    assignedWorkitemsSelector,
    workitems => workitems.map(item => {
        return (
            Object.assign({}, item, {start_time: new Date(item.start_timestamp).toLocaleString()})
        );
    })
);
