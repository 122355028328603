export const SET_LICENSE_INFO = 'SET_LICENSE_INFO';
export const CLEAR_LICENSE_INFO = 'CLEAR_LICENSE_INFO';

export interface LicenseInfo {
    licensed: boolean;
    remainingDays: number;
}

export interface LicenseState extends LicenseInfo {
    loading: boolean;
}