import * as React from 'react';
import { connect } from 'react-redux';

import { completeMatchTask, discardAssignedTask as discardTask } from '../../../store/workflow/actions';
import { displayError, WorkflowApi, RecordApi } from '../../../api';
import { getCurrentWorkflow } from '../../../store/workflow/selectors';
import { WorkflowMatchManualForm } from './WorkflowMatchManualForm';

import { FilterField, History, MDMRecord, QueryInclude, Workflow, WorkflowAction } from 'types';

export interface WorkflowMatchNewMainPropTypes {
    currentWorkflow: Workflow;
    dbName: string;
    completeMatchTask: (workflow: Workflow, action: WorkflowAction, matchGroups: string[][], note: string, button:HTMLButtonElement) => void;
    discardTask: (workflow: Workflow, button:HTMLButtonElement, note: string) => void;
    history: any;
}
interface WorkflowMatchNewMainState {
    matchRecords: object;
    queryRecords: MDMRecord[];
    workitemHistory: History[];
}
export class WorkflowMatchNewMainBase extends React.Component<WorkflowMatchNewMainPropTypes, WorkflowMatchNewMainState> {

    options: QueryInclude;

    constructor(props) {
        super(props);
        this.state = {
            queryRecords: [],
            matchRecords: {},
            workitemHistory: []
        };
        // what records we are going to see
        this.options = {
            match: true,
            conflict: true,
            version: false,
        };
    }

    componentDidMount() {
        WorkflowApi.readWorkitemState(this.props.dbName, this.props.currentWorkflow.workitem_id)
            .then(history => this.setState({ workitemHistory: history }))
            .catch(error => displayError(
                'Read Task State Failed',
                `Failed to read state for Workitem ${this.props.currentWorkflow.workitem_id}`,
                error
            ));
    }

    completeTask = (matchGroups: string[][], note: string, button:HTMLButtonElement) => {
        this.props.completeMatchTask(this.props.currentWorkflow, WorkflowAction.submit, matchGroups, note, button);
    }

    discardTask = (note: string, button:HTMLButtonElement) => {
        this.props.discardTask(this.props.currentWorkflow, button, note);
    }

    displayRecordsError = displayError('Get Records Failed', 'Failed to find(query) records');
    queryRecords = async (filterFields: FilterField[]) => {
        const workflow = this.props.currentWorkflow;
        const records = RecordApi.queryRecords({
            changeset: workflow.changesetId,
            database: workflow.databaseName,
            errorHandler: this.displayRecordsError,
            filter: { fields: filterFields },
            include: this.options,
            table: workflow.tableName,
           
        }, { start: 0, count: 1000 });
        this.setState({ queryRecords: await records });
    }

    displayMatchRecordsError = displayError('Get Match Records Failed', 'Failed to find(query) records');
    /**
     * Add a record as well as all records in its group to the collection of selected records.
     * Remember we use a map (by PK) instead of an array so that we can't get duplicates.
     * @param record
     */
    addMatchRecord = async id => {

        const workflow = this.props.currentWorkflow;
        const variables = workflow.variables;
        const primaryKey = workflow.schema.primary_key;

        const record = this.state.queryRecords.find(r => r[primaryKey] === id);
        if (record == null)
            return;

        // look for a matchtype
        const matchTypeType = variables['match_type'] || "default";
        const matchType = record.rpmdm_match && record.rpmdm_match[matchTypeType];
        if (matchType) {
            const filter = {
                match: { type: matchTypeType, group: matchType.group ? matchType.group.id : null },
                // If a record has no group then search by record id instead of match group
                fields: matchType.group ? [] :
                    [{ "field": primaryKey, "operation": '=', "values": [record[primaryKey].toString()] }]
            };
            // The initial record will come back in this query, so we don't need to add it again
            const records = await RecordApi.queryRecords({
                changeset: workflow.changesetId,
                database: workflow.databaseName,
                errorHandler: this.displayMatchRecordsError,
                filter: filter,
                include: this.options,
                primary_key: primaryKey,
                table: workflow.tableName,
            }, { start: 0, count: 1000 });
            const newMatchRecords = records.reduce((acc, cur) => {
                acc[cur[primaryKey]] = cur;
                return acc;
            }, { ...this.state.matchRecords } as object);
            this.setState({ matchRecords: newMatchRecords });
        }
        else {
            // add the single record
            this.setState({ matchRecords: { ...this.state.matchRecords, [record[primaryKey]]: record } });
        }
    }

    clearMatchRecords = () => this.setState({ matchRecords: {} });

    render() {
        const workflow = this.props.currentWorkflow;
        return (
            <WorkflowMatchManualForm
                history={this.state.workitemHistory}
                matchRecords={this.state.matchRecords}
                queryRecords={this.queryRecords}
                schema={workflow.schema}
                workflow={workflow}
                queryRecordsResult={this.state.queryRecords}
                addMatchRecord={this.addMatchRecord}
                clearMatchRecords={this.clearMatchRecords}
                completeTask={this.completeTask}
                discardTask={this.discardTask}
            />
        );
    }
}
export const WorkflowMatchManualContainer = connect(
    (state: any) => ({ currentWorkflow: getCurrentWorkflow(state), dbName: state.database.selected }),
    { completeMatchTask, discardTask }
)(WorkflowMatchNewMainBase);
