import React, { FC, useMemo, useState } from 'react';
import { Position, Button, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { Popover2 } from '@blueprintjs/popover2';

import { ConfirmDialog } from '../../Shared/ConfirmDialog';
import { formatTimeHHMM, sortDatesNullsFirst } from 'utils';
import { inSystemGroup } from '../../../api';
import { Workitem } from 'types';
import { TooltipCell } from 'components/Shared/ReactTable/TooltipCell';
import ReactTable from 'components/Shared/ReactTable/CustomReactTable';

export interface WorkflowAvailableTableProps {
    canEditWorkitems: boolean;
    workflows: Workitem[];
    claimTask: (workitem_id: string, node_id: string) => void;
    discardTask: (workitem_id: string, node_id: string) => void;
    editTask: (workitem: Workitem) => void;
}

export interface WorkflowAvailableTableState {
    showDeleteWarning: boolean;
    workitemId?: string;
    nodeId?: string;
}

const WorkflowAvailableTable: FC<WorkflowAvailableTableProps> = ({
    canEditWorkitems,
    workflows,
    claimTask,
    discardTask,
    editTask
}) => {
    const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
    const [workitemId, setWorkitemId] = useState<string>();
    const [nodeId, setNodeId] = useState<string>();
    const getActionCell = ({ row }) => {
        // can I discard an unclaimed task?
        const canDiscard: boolean = inSystemGroup();
        const canEdit = canEditWorkitems;

        const workitem = row.original;

        const actions = [
            <MenuItem
                key={`action-${workitem.workitem_id}-claim`}
                icon={IconNames.ARROW_DOWN}
                onClick={e => claimTask(workitem.workitem_id, workitem.node_id)}
                text='Claim Workitem'
            />
        ];

        if (canEdit) {
            actions.push(
                <MenuItem
                    key={`action-${workitem.workitem_id}-edit`}
                    icon={IconNames.EDIT}
                    onClick={e => { editTask(workitem) }}
                    text='Edit Workitem'
                />
            );
        }

        if (canDiscard) {
            actions.push(
                <MenuItem
                    key={`action-${workitem.workitem_id}-discard`}
                    text='Discard Workitem'
                    icon={IconNames.DELETE}
                    onClick={e => {
                        setWorkitemId(`${workitem.workitem_id}`);
                        setNodeId(workitem.node_id);
                        setShowDeleteWarning(true);
                    }}
                />
            );
        }

        return (
            <Popover2
                className="popover-menu"
                position={Position.BOTTOM_RIGHT}
                content={
                    <div className="popover-menu-wrapper">
                        {actions}
                    </div>
                }
                minimal
            >
                <Button icon='menu' intent='none'></Button>
            </Popover2>
        );
    };

    const getColumns = () => {
        const colHelper = createColumnHelper();
        return [
            colHelper.accessor('workitem_id', {
                header: 'Workitem Id',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('workflow_description', {
                header: 'Workflow',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('node_name', {
                header: 'Task',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('last_user', {
                header: 'Last User',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('variables.table', {
                header: 'Table',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('start_timestamp', {
                header: 'Start Time',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.display({
                header: 'Actions',
                enableResizing: true,
                enableSorting: false,
                cell: getActionCell
            })
        ];
    }

    const columns = useMemo(() => getColumns(), []);

    return (
        <>
            {
                showDeleteWarning &&
                <ConfirmDialog
                    body={`Are you sure you want to discard workflow #${workitemId}?`}
                    title='Discard Task'
                    cancelClick={() => setShowDeleteWarning(false)}
                    okClick={() => {
                        setShowDeleteWarning(false);
                        discardTask(workitemId, nodeId);
                    }}
                />
            }
            <ReactTable
                resultCount={workflows.length}
                columns={columns}
                data={workflows}
            />
        </>
    );
}

export default WorkflowAvailableTable;