import { TableUrl } from '../proxies/ajax/TableUrl';
import { axiosGet, axiosPost } from '../helpers/axiosUtils';
// import { mdmErrorToText } from '../helpers';
import { displayError } from './displayError';
// import { MDMStore } from '../store';
import { CHANGESET_CURRENT, Schema, Table } from 'types';
import { useMutation, useQuery } from '@tanstack/react-query';
// import { emitWarning } from 'process';

export type ExportTableRecord = {
    database: string;
    error: string;
    ready: number;
    table: string;
    timestamp: number;
    uuid: string;
}

export type ExportTableQueryResponse = {
    exports: ExportTableRecord[]
}

export class TableApi {
    static createTable(dbName: string, description: string, table: string, schema: Schema): Promise<number> {
        const body = {
            payload: {
                auto_commit: true,
                database: dbName,
                description: description,
                table: table,
                schema: schema
            }
        };

        //console.log("calling create in table...");

        return axiosPost(TableUrl.create, body, 'changeset').catch(err => {
            displayError('Create table failed', `Failed to create table ${table}`, err);
            throw err;
        });
    }

    static deleteTable(dbName: string, table: string): Promise<number> {
        const body = {
            payload: {
                auto_commit: true,
                database: dbName,
                table: table,
            }
        };

        return axiosPost(TableUrl.delete, body, 'changeset').catch(err => {
            displayError('Delete table failed', `Failed to delete table ${table}`, err);
            throw err;
        });
    }

    static exportTable(dbName: string, table: string): Promise<string> {
        const body = {
            payload: {
                database: dbName,
                table: table,
            }
        };

        return axiosPost(TableUrl.export, body, 'uuid').catch(err => {
            displayError('Export table failed', `Failed to export table ${table}`, err);
            throw err;
        });
    }

    static exportTableQuery(database: string, table: string, uuid: string) : Promise<ExportTableQueryResponse> {
        const body = {
            payload: {
                database,
                table,
                uuid
            }
        };

        // We probably need the error string as well since things could go badly and it NEVER be ready
        return axiosPost(TableUrl.export_query, body).catch(err => {
            displayError('Export query test failed', 'Failed to test for exported table query', err);
        });
    }

    static exportTableGet(uuid: string) : Promise<string> {
        console.log("Calling " + TableUrl.export_get);

        // We probably need the error string as well since things could go badly and it NEVER be ready
        return axiosGet(`${TableUrl.export_get}/${uuid}`).catch(err => {
            displayError('Export get failed', 'Failed to get exported table data', err);
        });
    }

    static expungeTable(dbName: string, table: string): Promise<boolean> {
        const body = {
            payload: {
                database: dbName,
                table: table,
            }
        };

        return axiosPost(TableUrl.expunge, body, 'success')
            .then(() => true)
            .catch(err => {
                displayError('Expunge table failed', `Failed to expunge table ${table}`, err);
                throw err;
            });
    }

    static listTables(dbName: string, changeset: number | string): Promise<string[]> {
        const changesetId: number = (changeset === CHANGESET_CURRENT ? undefined : changeset as number);
        const body = {
            payload: {
                database: dbName,
                changeset: changesetId
            }
        };

        return axiosPost(TableUrl.list, body, 'tables')
            .catch(err => {
                displayError('List Tables Failed', `Failed to list tables for database ${dbName}`, err);
                throw err;
            });
    }

    static modifyTable(dbName: string, description: string, table: string, schema: Schema): Promise<number> {
        const body = {
            payload: {
                auto_commit: true,
                database: dbName,
                description: description,
                table: table,
                schema: schema
            }
        };

        return axiosPost(TableUrl.modify, body, 'changeset')
            .catch(err => {
                displayError('Modify table failed', `Failed to modify table ${table}`, err);
                throw err;
            });
    }

    static queryTables(dbName: string, changeset: number | string, tableName?: string, showError: boolean = true): Promise<Table[]> {
        const changesetId: number = (changeset === CHANGESET_CURRENT ? undefined : changeset as number);
        const body = {
            payload: {
                database: dbName,
                changeset: changesetId,
                table: tableName
            }
        };

        return axiosPost(TableUrl.query, body, 'tables')
            .catch(err => {
                if (showError) {
                    displayError('Query Tables Failed', `Failed to query tables for database ${dbName}`, err);
                }
                throw err;
            });
    }

    static queryTableVersions(dbName: string, changeset: number | string, tableName?: string): Promise<Table[]> {
        const changesetId: number = (changeset === CHANGESET_CURRENT ? undefined : changeset as number);
        const body = {
            payload: {
                database: dbName,
                changeset: changesetId,
                table: tableName
            }
        };

        return axiosPost(TableUrl.queryVersions, body)
            .then(result => {
                return result;
            })
            .catch(err => {
                displayError('Query Tables Failed', `Failed to query table version`, err);
                throw err;
            });
    }

    static truncateTable(dbName: string, table: string): Promise<boolean> {
        const body = {
            payload: {
                database: dbName,
                table: table,
            }
        };

        return axiosPost(TableUrl.truncate, body, 'success')
            .catch(err => {
                displayError('Truncate table failed', `Failed to truncate table ${table}`, err);
                throw err;
            });
    }
}

export const useGetTablesList = (key: string, params: any, options = {}) => {
    return useQuery(
        [key, params],
        async ({ queryKey }) => {
            const [, queryParams] = queryKey;
            const { dbName, changeset } = queryParams;
            const res = await TableApi.listTables(dbName, changeset);

            return res;
        },
        options
    );
}

export const useGetTables = (key: string, params: any, options = {}) => {
    return useQuery(
        [key, params],
        async ({ queryKey }) => {
            const [, queryParams] = queryKey;
            const { dbName, changeset } = queryParams;
            const res = await TableApi.queryTables(dbName, changeset);

            return res;
        },
        options
    );
}

export const useExportReadyTables = (key: string, params: any, options = {}) => {
    return useQuery(
        [key, params],
        async ({ queryKey }) => {
            const [, queryParams] = queryKey;
            const { dbName = null, table = null, uuid = null } = queryParams;

            const res = await TableApi.exportTableQuery(dbName, table, uuid);

            return res;
        },
        options
    )
} 

export const useDeleteTableMutation = (options = {}) => {
    return useMutation(async ({
        table,
        dbName
    } : {
        table: string,
        dbName: string
    }) => {
        return TableApi.deleteTable(dbName, table)
    }, options);
}

export const useTruncateTableMutation = (options = {}) => {
    return useMutation(async ({
        table,
        dbName
    } : {
        table: string,
        dbName: string
    }) => {
        return TableApi.truncateTable(dbName, table)
    }, options);
}

export const useExpungeTableMutation = (options = {}) => {
    return useMutation(async ({
        table,
        dbName
    } : {
        table: string,
        dbName: string
    }) => {
        return TableApi.expungeTable(dbName, table)
    }, options);
}

export const useImportTableMutation = (options = {}) => {
    return useMutation(async ({
        dbName,
        description,
        tableName,
        jsonContents
    } : {
        dbName: string,
        description: string,
        jsonContents: Schema,
        tableName: string
    }) => {
        return TableApi.createTable(dbName, description, tableName, jsonContents);
    }, options);
}

export const useDownloadExportMutation = (options = {}) => {
    return useMutation(async (uuid: string) => {
        return TableApi.exportTableGet(uuid);
    })
}