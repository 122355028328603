import * as React from 'react';
import { ReactNode } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alignment, Button, Tooltip, Position, Divider, Icon, IconName, Popover, Menu as BlueprintMenu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames'
import { CustomIconName } from '../../types'
import CustomIcon from '../Shared/CustomIcon/CustomIcon'
import { Menu } from './Menu'
import { toggleMenu } from 'actions';
import './SideNav.scss';
import { Badge } from 'components/Shared/Badge';
import { DASHBOARD_ENABLED } from '../../UiConstants'
import { inRoles } from 'api';

const versionInfo = require('../../../version_info.json');
const pkgInfo = require('../../../package.json');

const redpointLogo = require('../../../images/redpoint-logo.svg');
const redpointLogoSmall = require('../../../images/redpoint-logo-small.svg');

interface SideNavMenuProps {
    history: any;
    isMinimized: boolean;
    toggleMenu: toggleMenu;
    menu: Menu;
    totalAssignedWorkflows?: number;
    totalAvailableWorkflows?: number;
}

export class SideNavMenuBase extends React.Component<SideNavMenuProps> {
    constructor(props) {
        super(props);
    }

    generateSectionHeader = (title?: string, iconObject?: ReactNode): ReactNode => {
        const { isMinimized } = this.props;

        if (!iconObject || !title)
            return null;

        if (isMinimized)
            return (
                <div className="sidenav-link-header flex-center">
                    {iconObject}
                </div>
            )

        return (
            <div className="sidenav-link-header expanded-header-padding flex-center-vertically">
                {iconObject}
                <span className="expanded-header-padding">
                    {title}
                </span>
            </div>
        )
    }

    generateBadge = (path: string) => {
        const { totalAssignedWorkflows, totalAvailableWorkflows } = this.props;
        if (path === '/wf/assigned' || path === '/wf/available') {
            return <Badge displayValue={path === '/wf/assigned' ? totalAssignedWorkflows : totalAvailableWorkflows} />
        }
        return null;
    }

    generateInfoLabel = () => {
        const { isMinimized } = this.props;
        if (isMinimized) {
            return (
                <div key='info-container'>
                    <NavLink
                        to="/help"
                        role="presentation"
                        className="sidenav-link flex-center"
                        onClick={(e) => e.preventDefault()}
                    >
                        <div className="icon-container">
                            <Icon icon="info-sign" iconSize={20} color="#2b88b7"/>
                        </div>
                    </NavLink>
                </div>
            )
        }

        return (
            <NavLink
                to="/help"
                className="sidenav-link expanded-link-padding"
                onClick={(e) => e.preventDefault()}
            >
                <div className="flex-center-vertically info-option-width">
                    <div className="icon-container">
                        <Icon icon="info-sign" iconSize={20} color="#2b88b7" />
                    </div>
                    <span className="expanded-link-padding">
                        &nbsp;{"Help"}
                    </span>
                </div>
            </NavLink>
        )
    }

    generateInfoSidenavButton = () => {
        const { history } = this.props
        return (
            <Popover
                position={Position.TOP_RIGHT}
                content={(
                    <BlueprintMenu>
                        <MenuItem
                            onClick={() => window.open('/mdm-help/index.html', 'mdm-help')}
                            text={"Documentation"}
                        />
                        <MenuDivider />
                        <MenuItem
                            onClick={() => history.push('/version')}
                            text={"About MDM"}
                        />
                        <MenuDivider />
                        <MenuItem
                            onClick={() => window.open('https://support.redpointglobal.com', 'redpoint-support')}
                            text={"Redpoint Support"}
                        />
                    </BlueprintMenu>
                )}
                minimal
            >
                {this.generateInfoLabel()}
            </Popover>
        )
    }

    generateLink = (name: string, path: string, icon: CustomIconName | IconName): ReactNode => {
        const { isMinimized } = this.props;

        if (isMinimized) {
            return (
                <div key={`${path}-container`}>
                    <Tooltip
                        content={name}
                        position={Position.RIGHT}
                        key={path}
                        className="tooltip"
                    >
                        <NavLink
                            activeClassName="active"
                            role="presentation"
                            to={`${path}`}
                            className="sidenav-link flex-center"
                        >
                            <div className="icon-container">
                                <Route path={path} children={({ match }) =>
                                    <CustomIcon icon={icon as CustomIconName} focused={!!match} />} />
                            </div>
                        </NavLink>
                    </Tooltip>

                    <div key={`${path}-badge`} className="minimized-badge">
                        {this.generateBadge(path)}
                    </div>
                </div>
            )
        }

        return (
            <NavLink
                activeClassName="active"
                key={path}
                to={`${path}`}
                className="sidenav-link expanded-link-padding"
                onClick={(e) => {
                    if (path === '/help') {
                        e.preventDefault();
                        window.open('/mdm-help/index.html', 'mdm-help');
                    }
                }}
            >
                <div className="flex-center-vertically">
                    <div className="icon-container">
                        <Route path={path} children={({ match }) =>
                            <CustomIcon icon={icon as CustomIconName} focused={!!match} />} />
                    </div>
                    <span className="expanded-link-padding">
                        &nbsp;{name}
                    </span>

                    <div className="auto-left-margin">
                        {this.generateBadge(path)}
                    </div>
                </div>
            </NavLink>
        )
    }

    generateSideNavMenu = (): ReactNode[] => {
        const { menu } = this.props;

        return menu.map((menuSection, index) => {
            const iconObject = menuSection.icon ?
                <CustomIcon icon={menuSection.icon} headerIcon /> :
                null

            const sectionHeader = this.generateSectionHeader(menuSection.title, iconObject)

            const sectionLinks = menuSection.paths.map(link => {
                const { authorizedRoles, name, path, icon } = link;
                if (authorizedRoles && !inRoles(authorizedRoles))
                    return null;
                return this.generateLink(name, path, icon)
            }).filter(link => link)

            if (!sectionLinks.length)
                return null;

            return (
                <li key={index}>
                    {sectionHeader}
                    {sectionLinks}
                    {index !== menu.length - 1 && <Divider className="padded-divider" />}
                </li>
            );
        });
    }

    generateMinimizeIcon = () => {
        const { isMinimized } = this.props;

        let arrowIcon: JSX.Element = isMinimized ?
            <CustomIcon icon={CustomIconName.ARROW_RIGHT} focused /> :
            <CustomIcon icon={CustomIconName.ARROW_LEFT} focused />

        const arrowContainer: JSX.Element = (
            <div className="arrow-container flex-center">
                {arrowIcon}
            </div>
        )

        return (
            <Button
                alignText={Alignment.LEFT}
                fill={false}
                onClick={this.toggleMinimization}
                className="minimize-button flex-center"
                icon={arrowContainer}
                minimal={true}
                small={true}
            />
        )
    }

    generateRedpointLogo = () => {
        const { isMinimized } = this.props;

        const logo = isMinimized ?
            <img className="company-logo-small" src={redpointLogoSmall} alt={'Small Redpoint Logo'} /> :
            <img className="company-logo" src={redpointLogo} alt={'Redpoint Logo'} />
        return (
            <>
                <div className="flex-center">
                    {logo}
                </div>
                <div title={versionInfo.branch + " " + versionInfo.hash} className="about-row flex-center">
                    <NavLink
                        role="presentation"
                        to="/version"
                        className="version-link-container"
                    >

                        <span className="version-link-container">
                            MDM Version {pkgInfo.version}
                        </span>
                    </NavLink>
                </div>
            </>
        )
    }

    private toggleMinimization = e => this.props.toggleMenu(this.props.isMinimized);

    render() {
        return (
            <div
                id="sidebar-wrapper"
                className={classNames('sidebar', this.props.isMinimized ? 'minimized' : 'expanded')}
            >
                <ul className="sidebar-nav">
                    {this.generateMinimizeIcon()}
                    {this.generateSideNavMenu()}
                </ul>
                <div>
                    <Divider className="padded-divider" />
                    {this.generateInfoSidenavButton()}
                    <Divider className="padded-divider" />
                    {this.generateRedpointLogo()}
                </div>
            </div>
        );
    }
}

export const mapStateToProps = state => ({
    isMinimized: state.menu,
    totalAssignedWorkflows: state.periodicData.totalAssignedWorkflows,
    totalAvailableWorkflows: state.periodicData.totalAvailableWorkflows,
});

export const SideNavMenu = connect(mapStateToProps, { toggleMenu })(SideNavMenuBase as any);
