
import * as React from 'react';
import { Button, Classes, Dialog, Icon, Intent, Text, } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';

export interface ConfirmProps {
    title: string;
    body: string;
    buttonName?: string;
    intent?: Intent;
    cancelClick: () => void;
    okClick: (event?: React.MouseEvent<HTMLElement>) => void;
    isOpen?: boolean;
    forDeletion?: boolean;
    isLoading?: boolean;
}


export class ConfirmDialog extends React.Component<ConfirmProps> {

    constructor(props) {
        super(props);
    }

    render() {
        const { isOpen, intent, buttonName, title, cancelClick, body, okClick, forDeletion, isLoading } = this.props

        const confirmIntent = forDeletion ? Intent.DANGER : intent || Intent.PRIMARY;
        const cancelIntent = forDeletion ? Intent.NONE : Intent.DANGER;

        return (
            <Dialog
                icon={IconNames.CONFIRM}
                isOpen={isOpen !== undefined ? isOpen : true}
                onClose={cancelClick}
                title={title}
            >
                <div className={Classes.DIALOG_BODY}>{body}</div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            disabled={isLoading}
                            intent={confirmIntent}
                            onClick={okClick}
                            text={buttonName || "Ok"} />
                        <Button
                            disabled={isLoading}
                            intent={cancelIntent}
                            onClick={cancelClick}
                            text="Cancel"
                        />
                    </div>
                </div>
            </Dialog>
        );
    }

}