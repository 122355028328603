import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames';
import * as moment from 'moment';
import { Button, Icon, MenuItem, Spinner, SpinnerSize } from '@blueprintjs/core';
import { TimePicker, TimePrecision } from '@blueprintjs/datetime';
import { DateInput3 as DateInput } from '@blueprintjs/datetime2';

import { DataTypes, Field, Schema, Intent } from 'types';
import { RecordsTableEditRecordField } from 'components/RecordsTable';
import { RecordApi, TableApi, inSystemGroup } from 'api';
import { mdmErrorToText } from 'helpers';
import { showToaster } from 'actions';
import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { getPermissionsByLoggedUserGroups } from 'store/permissions/selectors';
import { Select } from '@blueprintjs/select';
import './EditRecordForm.scss';
import { createColumnHelper } from '@tanstack/react-table';

export type UpdateCb = (name: string, value: any) => void;
export interface EditRecordFormProps {
    tableName: string;
    schema: Schema;
    record: object;
    modifiedRecord: object;
    updateFieldCb: UpdateCb;
}
interface PendingLinkResult {
    searchTerm: string;
    field: string;
    linkedField: string;
    linkedTable: string;
}
interface LinkResult {
    schema: Schema;
    record: any;
    loading: boolean;
}

const EnumSelect = Select.ofType<string>();

export const EditRecordForm: React.FC<EditRecordFormProps> = (props) => {
    const { schema, tableName, updateFieldCb } = props;

    const dispatch = useDispatch()

    const dbName = useSelector((state: any) => state.database.selected);
    const changeset = useSelector((state: any) => state.changeset.changeset);
    const permissions = useSelector((state: any) => getPermissionsByLoggedUserGroups(state));

    const userState = useSelector((state: any) => state.user);

    const isSystem = inSystemGroup();
    const canQueryTable = isSystem || permissions.includes('query_table');
    const canQueryRecord = isSystem || permissions.includes('query_record');

    const lookupEnabled = canQueryTable && canQueryRecord;

    const [autofilledIds, setAutofilledIds] = useState([]);
    const [checkingPk, setCheckingPk] = useState(null);
    const [pkValid, setPkValid] = useState(null);
    const [newPk, setNewPk] = useState(null);

    const [pendingLinkedFields, setPendingLinkedFields] = useState<PendingLinkResult[]>([]);
    const [linkToResults, setLinkToresults] = useState<{ [field: string]: LinkResult }>({});
    const [tablePks, setTablePks] = useState(null);

    useEffect(() => {
        if (lookupEnabled) {
            let schemaTables = [];
            schema.fields.forEach((f: Field, i) => {
                if (f.links_to) {
                    schemaTables.push(f.links_to);
                }
            });
            schemaTables = [...new Set(schemaTables)];

            if (schemaTables.length) {
                const getAllPks = async () => {
                    const pkPromises = schemaTables.map(table => {
                        return TableApi.queryTables(dbName, 'current', table)
                            .then(tables => {
                                const table = tables[0];
                                const pkFieldName = table.schema.primary_key;
                                const pkField = table.schema.fields.find(field => field.field === pkFieldName);
                                return { table: table.table, pkField: pkFieldName, pkType: pkField.datatype.type, schema: table.schema };
                            })
                    })
                    const pks = await Promise.all(pkPromises);
                    setTablePks(pks)
                }
                getAllPks();
            } else {
                setTablePks([]);
            }
        }
    }, [])

    const baseQueryParams = {
        database: dbName,
        errorHandler: null,
        table: null, // change table
        changeset: changeset,
        include: { conflict: true, match: true, version: false, mask: false, },
        filter: {
            // change field and value
            fields: [{ field: null, operation: "=", values: [newPk] }],
            masked: false
        },
    }

    const searchByFieldAndPk = async (linkToData: PendingLinkResult) => {
        const { linkedField, linkedTable, searchTerm, field } = linkToData;

        if (!searchTerm) {
            return;
        }

        let loadingLinkResults = JSON.parse(JSON.stringify(linkToResults))
        loadingLinkResults[linkToData.field] = { ...loadingLinkResults[linkToData.field], loading: true };
        setLinkToresults(loadingLinkResults);

        let linkedTablePkData = tablePks.find(tablePk => tablePk.table === linkedTable);
        const linkedTablePk = linkedTablePkData.pkField;

        let pkSearchQueryParams = JSON.parse(JSON.stringify(baseQueryParams));
        pkSearchQueryParams.table = linkedTable;
        pkSearchQueryParams.filter.fields = [{ field: linkedTablePk, operation: "=", values: [searchTerm] }];

        let fieldSearchQueryParams = JSON.parse(JSON.stringify(baseQueryParams));
        fieldSearchQueryParams.table = linkedTable;
        fieldSearchQueryParams.filter.fields = [{ field: linkedField, operation: "contains", values: [searchTerm] }];

        const searchResults = await Promise.all([
            RecordApi.queryRecords(pkSearchQueryParams, null, false).then(records => {
                return records?.length ? records[0] : null;
            }).catch(err => null), // error gets logged by axios
            RecordApi.queryRecords(fieldSearchQueryParams, null, false).then(records => {
                if (records?.length) {
                    const exactMatch = records.find(record => {
                        record[linkedField].toLowerCase() == searchTerm.toLowerCase();
                    })
                    return exactMatch || records[0];
                }
                return null;
            }).catch(err => null), // error gets logged by axios
        ]);

        // return only the one we want, PK takes priority
        const closestResult = searchResults[0] || searchResults[1];

        let linkResults = { ...linkToResults };
        const tableObj = tablePks.find(tableObj => tableObj.table === linkedTable);
        linkResults[linkToData.field] = { record: closestResult, schema: tableObj.schema, loading: false };
        setLinkToresults(linkResults);
        return closestResult;
    }

    useEffect(() => {
        if (lookupEnabled) {
            const delayDebounceFn = setTimeout(() => {
                if (pendingLinkedFields.length) {
                    pendingLinkedFields.forEach(linkToData => {
                        linkToData.field
                        searchByFieldAndPk(linkToData);
                    });
                    setPendingLinkedFields([]);
                }
            }, 700)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [JSON.stringify(pendingLinkedFields)])

    useEffect(() => {
        if (canQueryRecord) {
            const delayDebounceFn = setTimeout(() => {
                if (newPk !== null) {
                    const queryParams = {
                        database: dbName,
                        errorHandler: null,
                        table: tableName,
                        changeset: changeset,
                        include: { conflict: true, match: true, version: false, mask: false, },
                        filter: {
                            fields: [{ field: schema.primary_key, operation: "=", values: [newPk] }],
                            masked: false
                        },
                    }
                    RecordApi.queryRecords(queryParams)
                        .then(records => {
                            setPkValid(!records.length);
                            setCheckingPk(false);
                        }).catch(err => {
                            setCheckingPk(false);
                            const errorMessage = mdmErrorToText(err);
                        })
                }
            }, 700)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [newPk])

    const generateLinkedFieldResult = (fieldName: string) => {
        if (!linkToResults[fieldName]) {
            return null;
        }

        const { record, schema, loading } = linkToResults[fieldName];

        if (loading) {
            return (<div className="fill-width pk-warning-container text-small text-heavy"> Finding a match... <Spinner size={SpinnerSize.SMALL} /> </div>);
        }

        if (!record) {
            return (<div className="fill-width pk-warning-container pk-invalid"> No matching fields </div>);
        }

        let columns = []
        let columnHelper = createColumnHelper<Field>();
        schema.fields.forEach(field => {
            if (field.display && !field.links_to && field.datatype.type !== 'table') {
                columns.push(columnHelper.accessor('field', {
                    header: field.field,
                    cell: ({ cell }) => cell.getValue()
                }));
            }
        });

        return (<>
            <div className="link-table-container fill-width">
                <ReactTable
                    showPagination={false}
                    columns={columns}
                    data={[record]}
                />
            </div>
            <div className="fill-width flex-center">
                <Button
                    intent='none'
                    text="Link field"
                    onClick={() => {
                        const fieldPkValue = record[schema.primary_key];
                        updateFieldCb(fieldName, fieldPkValue);

                        // remove the result if we click the link button.
                        let newLinkToResults = { ...linkToResults }
                        delete newLinkToResults[fieldName];
                        setLinkToresults(newLinkToResults);
                        dispatch(showToaster(Intent.SUCCESS, `Field linked to key ${fieldPkValue}`));
                    }}
                />
            </div>
        </>)
    }

    const makeRows = ({ record, modifiedRecord, schema, updateFieldCb }:
        { record: object, modifiedRecord: object, schema: Schema, updateFieldCb: UpdateCb }): JSX.Element[] => {
        if (!schema) {
            return [];
        }

        return schema.fields.map((f: Field, i) => {
            const edit = !!record;
            const readOnly = edit && (f.field === schema.primary_key);
            const isPrimaryKey = f.field === schema.primary_key;
            const modified = edit && record[f.field] !== modifiedRecord[f.field];
            const subField = f.datatype.type === DataTypes.table;
            const values = f.values || [];
            // Add the modified class if we're editing and the field value has changed
            const inputClass = classNames('bp5-input', 'bp5-fill', {
                'modified-value': modified,
                'cell-value': !modified,
            });
            const dateInputClass = classNames('bp5-dateinput', 'bp5-input', 'bp5-fill', {
                'modified-value': modified,
                'cell-value': !modified,
            });
            const timeInputClass = classNames('bp5-timepicker-input', 'bp5-input', 'bp5-fill', {
                'modified-value': modified,
                'cell-value': !modified,
            });

            // Sending in an empty array in the case of a sub-table that is undefined
            const generateInputField = () => {
                let inputType;

                if (subField) {
                    return <RecordsTableEditRecordField
                        index={i}
                        key={`sub-field-${i}`}
                        field={f}
                        records={record ? record[f.field] : []}
                        modifiedRecords={modifiedRecord[f.field] || []}
                        updateFieldCb={updateFieldCb}
                    />
                }

                const dataType = f?.datatype?.type;
                const dataSize = f?.datatype?.size;

                // link-to field input here
                if (f?.links_to) {
                    return (
                        <input
                            key={`input-${i}`}
                            className={inputClass}
                            name={f.field}
                            type={'text'}
                            dir="auto"
                            value={modifiedRecord[f.field] != null ? modifiedRecord[f.field] : ''}
                            onChange={e => {
                                const fieldExists = pendingLinkedFields.find(val => val.field === f.field);
                                let linkFields = [...pendingLinkedFields];
                                if (fieldExists) {
                                    linkFields = pendingLinkedFields.map(val => val.field === f.field ? { ...val, searchTerm: e.target.value } : val);
                                } else {
                                    linkFields.push({ searchTerm: e.target.value, field: f.field, linkedTable: f.links_to, linkedField: f.link_display_field });
                                }
                                setPendingLinkedFields(linkFields);
                                return updateFieldCb(e.target.name, e.target.value);
                            }}
                        />
                    )
                }

                if (dataType === 'date' || dataType === 'datetime') {
                    inputType = dataType === 'datetime' ? 'datetime-local' : dataType;
                    // const timeFieldId = `${f.field}-${i}`;
                    let format = 'YYYY-MM-DD';
                    let displayFormat = "MM/DD/YYYY";
                    let timePickerProps = undefined;
                    const props: {
                        className: string;
                        timePrecision?: 'minute' | 'second';
                    } = {
                        className: dateInputClass
                    }

                    if (dataType === 'datetime') {
                        props.timePrecision = 'second';
                        format = 'YYYY-MM-DDTHH:mm:ss';
                        displayFormat = "MM/DD/YYYY hh:mm:ss A";
                        timePickerProps = { useAmPm: true };
                    }

                    return (
                        <DateInput
                            key={`input-${i}`}
                            className={dateInputClass}
                            disabled={readOnly}
                            inputProps={{
                                name: f.field,
                            }}
                            formatDate={date => moment(date).format(displayFormat) as string}
                            parseDate={date => moment(date, format).toDate()}
                            timePickerProps={timePickerProps}
                            value={modifiedRecord[f.field] != null ? moment(modifiedRecord[f.field]).toDate().toString() : new Date().toString()}
                            // onClick={e => {
                            //     if (!(autofilledIds as string[]).includes(timeFieldId)) {
                            //         setAutofilledIds([...autofilledIds, timeFieldId]);
                            //         if (dataType === 'datetime') {
                            //             const currentTime = moment();
                            //             const defaultTime = currentTime.startOf('minute').format(format);
                            //             updateFieldCb(f.field, defaultTime);
                            //         } else {
                            //             const currentTime = moment();
                            //             const defaultTime = currentTime.format(format);
                            //             updateFieldCb(f.field, defaultTime);
                            //         }
                            //     }
                            // }}
                            showActionsBar
                            onChange={date => {
                                const dateTimeVal = date != null ? moment(date).format(format) : null;
                                updateFieldCb(f.field, dateTimeVal);    // this.props.updateFieldCb(f.field, formatDateTimeDisplay(date, field, true) as string)
                            }}
                            {...props}
                        />
                    );
                }

                if (dataType === 'time') {
                    return (
                        <TimePicker
                            key={`input-${i}`}
                            className={timeInputClass}
                            disabled={readOnly}
                            data-testid={f.field}
                            // dir="auto"
                            precision={TimePrecision.SECOND}
                            useAmPm
                            value={modifiedRecord[f.field] != null ? moment(modifiedRecord[f.field], "HH:mm:ss").toDate() : new Date()}
                            onChange={date => {
                                const dateTimeVal = date != null ? moment(date).format('HH:mm:ss') : new Date();
                                updateFieldCb(f.field, dateTimeVal);
                            }}
                        />
                    );

                }

                if (dataType === 'boolean') {
                    return (
                        <select
                            key={`input-${i}`}
                            className='workitem-select-field'
                            value={modifiedRecord[f.field] != null ? modifiedRecord[f.field] : ''}
                            name={f.field}
                            id={f.field}
                            onChange={e => {
                                let changedValue = e.target.value;
                                return updateFieldCb(e.target.name, changedValue);
                            }}
                        >
                            <option value="">Select Value...</option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                        </select>
                    )
                }

                if (dataType == 'money' || dataType == 'float' || dataType == 'integer') {
                    const step = dataType === 'integer' ? 1 : null;
                    const inputValue = modifiedRecord[f.field] != null ? modifiedRecord[f.field] : '';

                    return (
                        <input key={`input-${i}`} className={inputClass} readOnly={readOnly} name={f.field} type="text" step={step} dir="auto" pattern="^[0-9+\.]*$"
                            value={inputValue}
                            onChange={e => {
                                if (isPrimaryKey) {
                                    setCheckingPk(true);
                                    setNewPk(e.target.value);
                                }
                                return updateFieldCb(e.target.name, e.target.value);
                            }}
                        />
                    )
                }

                // if data type is text and has values, render a select
                if (dataType === 'text' && values.length > 0) {
                    return (
                        <EnumSelect
                            items={f.values}
                            itemRenderer={(item, { handleClick, modifiers }) => {
                                return (
                                    <MenuItem
                                        active={modifiers.active}
                                        key={`${f.field}-${item}`}
                                        onClick={handleClick}
                                        text={item}
                                    />
                                );
                            }}
                            className='workitem-select-field'
                            filterable={false}
                            onItemSelect={(value) => updateFieldCb(f.field, value)}
                            popoverProps={{ minimal: true }}
                        >
                            <Button
                                text={modifiedRecord[f.field] || 'Select Value...'}
                                rightIcon="double-caret-vertical"
                            // disabled={readOnly}
                            />
                        </EnumSelect>
                    );
                }

                return (
                    <input
                        key={`input-${i}`}
                        className={inputClass}
                        readOnly={readOnly}
                        name={f.field}
                        type={'text'}
                        dir="auto"
                        maxLength={dataSize}
                        value={modifiedRecord[f.field] != null ? modifiedRecord[f.field] : ''}
                        onChange={e => {
                            if (isPrimaryKey) {
                                setCheckingPk(true);
                                setNewPk(e.target.value);
                            }
                            return updateFieldCb(e.target.name, e.target.value)
                        }}
                    />
                )
            }

            const generatePkWarning = () => {
                if (isPrimaryKey) {
                    if (checkingPk) {
                        return (<div className="fill-width pk-warning-container text-small text-heavy"> Validating... <Spinner size={SpinnerSize.SMALL} /> </div>);
                    } else if (pkValid === true) {
                        return (<div className="fill-width pk-warning-container pk-valid"> Primary key is unique </div>);
                    } else if (pkValid === false) {
                        return (<div className="fill-width pk-warning-container pk-invalid"> Primary key not unique </div>);
                    }
                }
            }

            return (
                <div className="record-row" key={i}>
                    <div className="field-label">{f.field}{!f.nullable && <span className="required-asterisc">*</span>}</div>
                    {generateInputField()}
                    {canQueryRecord && generatePkWarning()}
                    {lookupEnabled && f.links_to && generateLinkedFieldResult(f.field)}
                </div>
            );
        })
    }

    return (
        <div className="record-container">
            <div className="record-content">
                {tablePks === null && lookupEnabled && <div className="fill flex-center"><Spinner /></div>}
                {(tablePks !== null || !lookupEnabled) && makeRows(props)}
            </div>
        </div>
    )
}