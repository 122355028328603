import * as React from 'react';
import { connect } from 'react-redux';
import { queryDatabases, setDatabase } from 'store/database/actions';
import { Menu, MenuItem, MenuDivider } from "@blueprintjs/core";
import { showToaster } from 'actions';
import { Intent } from '../../types'

export interface DatabaseChangeProps {
    databases: any[];
    setDatabase: (dispatch: any) => void;
    showToaster: showToaster;
}

export interface DatahaseChangeState {}

export class DatabaseChangeMenuBase extends React.Component<DatabaseChangeProps, DatahaseChangeState> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    generateMenuItems = () => {
        const { databases, setDatabase, showToaster } = this.props;
        return databases.map((database, i) => {
            const divider = i < databases.length - 1 ? <MenuDivider key={`divider-${i}`} /> : null;
            const currentDbName = database.database;

            return (
                <React.Fragment key={`container-${i}`}>
                    <MenuItem 
                        key={`database-${i}`}
                        onClick={() => {
                            setDatabase(currentDbName)
                            showToaster(Intent.INFO, `Database changed to: ${currentDbName}.`)
                        }} 
                        text={currentDbName}
                    />
                    {divider}
                </React.Fragment>
            )
        })
    }

    render() {

       return (
        <Menu>
            {this.generateMenuItems()}
        </Menu>
        );
    }
}

// tslint:disable-next-line:no-default-export
export const DatabaseChangeMenu = connect(
    (state: any) => ({
        databases: state.database.all,
        dbName: state.database.selected,
        changeset: state.changeset,
        username: state.username,
        userRoles: state.user.roles,
        login: state.login,
    }),
    { showToaster, setDatabase, queryDatabases },
)(DatabaseChangeMenuBase);
