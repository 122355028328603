import { SHOW_TOASTER } from '../constants';
import { toast, ToastPosition } from "react-toastify";
import { MDMToasterProps } from 'types';

const defaultState = {
    timestamp: -1,
    intent: null, message: '',
    position: toast.POSITION.BOTTOM_RIGHT as ToastPosition
};

export const showtoaster = (state = defaultState, action): MDMToasterProps => {
    switch (action.type) {
        case SHOW_TOASTER:
                return action.payload;
        default:
            return state;
    }
};
