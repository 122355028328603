/**
 * @format
 */
import * as React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import './ErrorBoundary.scss';

interface ErrorBoundaryState {
    hasError: boolean;
}

interface ErrorBoundaryProps {
    content?: React.ReactNode;
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    public componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.error(error, info);
    }

    public render(): any {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    {this.props.content ? (
                        this.props.content
                    ) : (
                        <>
                            <h4 className="error-header">
                                <Icon  icon={IconNames.ERROR} /> Something went wrong!
                            </h4>
                            <p>Please try</p>
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>Reloading the page.</li>
                                <li>Logging out, then back in.</li>
                                <li>Contacting support</li>
                            </ul>
                        </>
                    )}
                </div>
            );
        }
        return this.props.children;
    }
}
