/**
 * @format
 */
import * as React from 'react';
import { Classes, Dialog, HTMLSelect, Label } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { connect } from 'react-redux';

import { Button } from 'components/Shared';
import { Database } from 'types';
import { logout, showCreateDatabase } from 'actions';
import { setDatabase } from 'store/database/actions';


export interface DialogChooseDatabaseProps {
    canCreate?: boolean;
    databases?: Database[];
    setDatabase?: (newDbName: string) => void;
    showCreateDatabase: (show: boolean) => void;
    // queryDatabases?: () => void;
    logout?: logout;
    show: boolean;
}
export interface DialogChooseDatabaseState {
    show: boolean;
    newDbName: string;
}
export class DialogChooseDatabaseBase extends React.Component<
    DialogChooseDatabaseProps,
    DialogChooseDatabaseState
    > {
    constructor(props) {
        super(props);
        this.state = { show: props.show, newDbName: this.getDatabaseName(props) };
    }
    openDialog = () => {
        this.setState({ show: true });
    }

    close = () => {
        this.setState({ show: false });
    }

    getDatabaseName(props): string {
        return props.databases.length > 0 ? props.databases[0].database : undefined;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show || this.getDatabaseName(prevProps) !== this.getDatabaseName(this.props))
            this.setState({ show: this.props.show || false, newDbName: this.getDatabaseName(this.props) });
    }

    onFieldChange = () => {
        return event => {
            const newState = {
                ...this.state,
                [event.target.name]: event.target.value,
            };
            this.setState(newState);
        };
    }
    setDatabase = () => {
        this.close();
        this.props.setDatabase(this.state.newDbName);
    }

    cancel = () => {
        this.close();
        this.props.logout();
    }

    render() {
        const createButton =
            this.props.databases.length === 0 && this.props.canCreate ? (
                <Button
                    icon={IconNames.PLUS}
                    onClick={e => this.props.showCreateDatabase(true)}
                    value="Create Database"
                />
            ) : (
                    <div />
                );
        return (
            <Dialog isOpen={this.state.show} icon={IconNames.DATABASE} title="Choose Database">
                <div className={Classes.DIALOG_BODY}>
                    <Label>
                        Please choose a database to continue
                        <HTMLSelect
                            fill={true}
                            name="newDbName"
                            onChange={this.onFieldChange()}
                            options={this.props.databases.map(db => db.database)}
                            value={this.state.newDbName}
                        />
                    </Label>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            disabled={!this.state.newDbName}
                            id="setdatabase-button"
                            onClick={this.setDatabase}
                            value="Set Database"
                        />
                        {createButton}
                        <Button onClick={this.cancel} value="Cancel" />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const DialogChooseDatabase: any = connect((state: any) => ({ databases: state.database.all }), {
    setDatabase,
    showCreateDatabase,
    logout,
})(DialogChooseDatabaseBase as any);
