import { defaultDashboardLayout } from './defaultDashboardLayout';
import { SET_DASHBOARD_LAYOUT, SET_USER_GROUPS, SET_USER_ROLES, SET_USER_SETTINGS, SET_PERMISSIONS, CLEAR_ROLES, CLEAR_GROUPS, CLEAR_USER_DATA, UserActionTypes, UserState } from './types';
import { defaultUserSettings } from './constants';

const defaultState: UserState = {
    username: '',
    dashboardLayout: null,
    settings: defaultUserSettings,
    groups: [],
    roles: []
};

export const userReducer = (state = defaultState, action: UserActionTypes): UserState => {
    switch (action.type) {
        case SET_USER_GROUPS:
            return { ...state, groups: action.payload.groups };
        case SET_DASHBOARD_LAYOUT:
            return { ...state, dashboardLayout: action.payload.newLayouts };
        case SET_USER_ROLES:
            return { ...state, roles: action.payload.roles };
        case SET_USER_SETTINGS:
            return { ...state, settings: action.payload.settings };
        case SET_PERMISSIONS:
            return { ...state, permissions: action.payload.permissions };
        case CLEAR_USER_DATA: {
            return defaultState;
        }
        default:
            return state;
    }
};
