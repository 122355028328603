import { commitChangeset, createChangeset, discardChangeset, displayError } from 'api';
import { MDMStore } from 'store';
import { setChangeset, showToaster } from 'actions';
import { DateToYYYYMMDDHHMM } from '../../utils';
import { CHANGESET_CURRENT, Changeset, Intent } from 'types';

export const doCommit = async (changeset: Changeset, dbName: string) => {
    const res = await commitChangeset(dbName, changeset);
    if (res) {
        MDMStore.dispatch<any>(
            showToaster(
                Intent.SUCCESS,
                `Commited changeset ${changeset.changeset}`,
            ),
        );
    }

    MDMStore.dispatch<any>(setChangeset(CHANGESET_CURRENT));
};
export const doDiscard = async (changeset: Changeset, dbName: string) => {
    const res = await discardChangeset(dbName, changeset);
    if (res) {
        MDMStore.dispatch<any>(
            showToaster(
                Intent.SUCCESS,
                `Discarded changeset ${changeset.changeset}`,
            ),
        );
    }
    MDMStore.dispatch<any>(setChangeset(CHANGESET_CURRENT));
};
export const doCreate = (dbName: string, username: string) => {
    const description = `${username}-${DateToYYYYMMDDHHMM()}`;
    createChangeset(dbName, description, username)
        .then((changeset: Changeset) => {
            MDMStore.dispatch<any>(
                showToaster(
                    Intent.SUCCESS,
                    `Created changeset ${changeset.changeset}`,
                ),
            );
            MDMStore.dispatch<any>(setChangeset(changeset.changeset));
        });
};