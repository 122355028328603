import * as React from 'react';
import { withRouter, RouteComponentProps } from "react-router-dom";

class ScrollToTopOnMount extends React.Component<React.PropsWithChildren<RouteComponentProps>> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTopOnMount)