import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';
import { Tooltip, Divider } from '@blueprintjs/core';
import { WorkflowDef, ProcessDef } from '../../../types';
import { toTitlecase } from '../../../utils';
import { WorkflowFormMultiSelect } from './WorkflowFormMultiSelect'
import { TooltipTexts } from './TooltipTexts'

export interface ClaimGroupEditProps {
    workflow: WorkflowDef;
    groups?: string[];
    processDetails: ProcessDef;
    onInputChange: (key: string, value: any) => void;
}
export const ClaimGroupEdit: React.FC<ClaimGroupEditProps> = ({ workflow, groups = [], processDetails, onInputChange }) => {
    if(!processDetails)
        return <span>loading...</span>

    const generateGroupInput = (fieldName: string, title: string, items: string[], selectedItems: string[], tooltip?: string): React.ReactNode => {
        const onGroupSelect = (newGroup: string) => {
            const newClaimGroups = [...selectedItems, newGroup].filter(item => item).join(',')
            let allVars = {...workflow.variables};
            allVars[fieldName] = newClaimGroups;

            onInputChange("variables", allVars);
        }

        const onGroupRemove = (removedGroup: string) => {
            const newClaimGroups = selectedItems.filter(item => item && item !== removedGroup).join(',')
            let allVars = {...workflow.variables};

            if(newClaimGroups)
                allVars[fieldName] = newClaimGroups;
            else if(allVars[fieldName])
                delete allVars[fieldName]

            onInputChange("variables", allVars);
        }

        return (
            <WorkflowFormMultiSelect
                key={title}
                label={title}
                items={items}
                selectedItems={selectedItems}
                tooltip={tooltip}
                onSelect={onGroupSelect}
                onRemove={onGroupRemove}
            />
        )
    }

    const mapClaimGroups = (claimGroups) => {
        let claimGroupEntries = [];

        for (let [key, value] of Object.entries(claimGroups)) {
            let tokens = key.split('$');
            const title = toTitlecase(tokens[0]);
            const groupInput = generateGroupInput(key, title, groups, value.toString().split(','))
            claimGroupEntries.push(groupInput);
        }

        return claimGroupEntries;
    }

    const { variables } = workflow;
    let claimGroups = {};

    // Populate ALL available claim groups
    for (let detailVar of processDetails.variables) {
        if(detailVar.variable.endsWith('$claim_groups'))
            claimGroups[detailVar.variable] = '';
    }

    // Set the value for the existing claim groups
    for (let [key, value] of Object.entries(variables)) {
        if(key.endsWith('$claim_groups'))
            claimGroups[key] = value;
    }

    if(!Object.keys(claimGroups).length)
        return null;

    return (
        <div className="flex-column workflow-detail-form">
            <Divider className="wf-detail-divider"/>
            <div>
                <div className="text-medium claim-groups-title">
                    Claim Groups
                    <Tooltip content={TooltipTexts.claimGroups} className="tooltip">
                        <span className="tooltip-target">?</span>
                    </Tooltip>
                </div>
                {mapClaimGroups(claimGroups)}
            </div>
        </div>
    )
}
