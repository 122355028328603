import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux'
import ChangesetMainContainer from 'components/ChangesetMain/ChangesetMainForm';
import { DatabaseMainContainer } from 'components/DatabaseMain/DatabaseMainForm';
import { Dashboard } from 'components/Dashboard';
import { Erasure } from 'components/Erasure';
import { Group } from 'components/Group';
import { LogDisplay } from 'components/Log';
import PermissionsContainer from 'components/Permissions/PermissionsContainer';
import { RecordsMainContainer } from 'components/RecordsMain';
import { TableEditForm } from 'components/TableEdit/TableEditForm';
import TablesMainForm from 'components/TablesMain/TablesMainForm';
import { User } from 'components/User';
import { VerifyErasure } from 'components/VerifyErasure';
import { ViewContainer } from 'components/ComputedViews/ViewContainer';
import { ViewEditContainer } from 'components/ComputedViews/ViewEditContainer';
import { VersionScreen } from 'components/Version';
import { WorkflowContainer } from 'components/Workflow';
import { DebugConfig } from 'components/DebugConfig';
import { UserSettings } from 'components/UserSettings';
import { RouteAuth, RouteAuthWrapper } from 'components/Shared/RouteAuth'
import { DASHBOARD_ENABLED } from '../../UiConstants'
import { AuthorizedRoles } from '../../constants';
import PageNotFound from './PageNotFound';
import { LicenseForm } from './LicenseForm';
import LogScreen from 'components/Log/LogScreen';

interface MDMRoutesProps { }

export class MDMRoutesBase extends React.PureComponent<MDMRoutesProps> {
    render() {
        const defaultRoute = DASHBOARD_ENABLED ?
            <Route exact path='/' component={({ history }) => <Dashboard history={history} />} /> :
            <Route exact path='/' component={({ history }) => <WorkflowContainer history={history} />} />

        return (
            <div className={`mdm-well`}>
                <Switch>
                    {defaultRoute}
                    <Route path='/wf' component={({ history }) =>
                        <RouteAuthWrapper allowedRoles={AuthorizedRoles.workflow}>
                            <WorkflowContainer history={history} />
                        </RouteAuthWrapper>}
                    />
                    <Route path='/license' component={() =>
                        <RouteAuthWrapper allowedRoles={[]}>
                            <LicenseForm isPage={true} />
                        </RouteAuthWrapper>}
                    />
                    <Route exact path='/changesets' component={RouteAuth(ChangesetMainContainer, AuthorizedRoles.steward)} />
                    <Route exact path='/databases' component={RouteAuth(DatabaseMainContainer, AuthorizedRoles.steward)} />
                    <Route exact path='/records/:table?' component={RouteAuth(RecordsMainContainer, AuthorizedRoles.steward)} />
                    <Route exact path='/table' component={RouteAuth(TableEditForm, AuthorizedRoles.steward)} />
                    <Route exact path='/table/:tableName' component={RouteAuth(TableEditForm, AuthorizedRoles.steward)} />
                    <Route exact path='/tables' component={RouteAuth(TablesMainForm, AuthorizedRoles.steward)} />
                    <Route exact path='/views/edit/:viewID?' component={RouteAuth(ViewEditContainer, AuthorizedRoles.steward)} />
                    <Route exact path='/views/view/:viewID' component={RouteAuth(ViewEditContainer, AuthorizedRoles.steward)} />
                    <Route exact path='/views' component={RouteAuth(ViewContainer, AuthorizedRoles.steward)} />
                    <Route exact path='/groups' component={RouteAuth(Group, AuthorizedRoles.management)} />
                    <Route exact path='/users' component={({ history }) => <User history={history} />} />
                    <Route exact path='/permissions' component={RouteAuth(PermissionsContainer, AuthorizedRoles.management)} />
                    <Route exact path='/logs' component={RouteAuth(LogScreen, AuthorizedRoles.management)} />
                    <Route exact path='/erasure' component={RouteAuth(Erasure, AuthorizedRoles.compliance)} />
                    <Route exact path='/verify' component={RouteAuth(VerifyErasure, AuthorizedRoles.compliance)} />
                    <Route exact path='/version' component={VersionScreen} />
                    <Route exact path='/dashboard' component={Dashboard} />
                    <Route exact path='/log' component={LogDisplay} />
                    <Route exact path='/settings/:username' component={UserSettings} />
                    <Route exact path='/debug' component={DebugConfig} />
                    <Route component={PageNotFound} />
                </Switch>
            </div>);
    }
}

export const mapStateToProps = state => ({});

export const MDMRoutes = connect((mapStateToProps))(MDMRoutesBase as any);