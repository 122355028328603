export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_PERMISSIONS_LOADING = 'SET_PERMISSIONS_LOADING';
export const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS';

export interface PermissionsState {
    loading: boolean;
    hasFetched: boolean;
    permissions: Permission[];
}

interface Permission {
    group: string;
    inherited: boolean;
    perms: string[];
}

interface SetPermissions {
    type: typeof SET_PERMISSIONS;
    payload: { permissions: Permission[] };
}

interface SetPermissionsLoading {
    type: typeof SET_PERMISSIONS_LOADING;
}

interface ClearPermissions {
    type: typeof CLEAR_PERMISSIONS;
}

export type PermissionActionTypes = SetPermissions | SetPermissionsLoading | ClearPermissions;
