import React, { useMemo } from 'react';
import { Popover, Position, Button, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { ConfirmDialog } from '../../Shared/ConfirmDialog';
import { Workitem } from 'types';
import { TooltipCell } from 'components/Shared/ReactTable/TooltipCell';
import './WorkflowAssigned.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { DateFormatEnum, customDateFormat } from 'helpers/date-format';


export interface WorkflowAssignedTableProps {
    canEditWorkitems: boolean;
    workflows: Workitem[];
    continueTask: (workitem_id: string, node_id: string) => void;
    discardTask: (workitem_id: string, node_id: string) => void;
    releaseTask: (workitem_id: string, node_id: string) => void;
    editTask: (workitem: Workitem) => void;
}

export interface WorkflowAssignedTableState {
    showDeleteWarning: boolean;
    workitemId?: string;
    nodeId?: string;
}


const WorkflowAssignedTable: React.FC<WorkflowAssignedTableProps> = ({
    canEditWorkitems,
    continueTask,
    discardTask,
    workflows,
    releaseTask,
    editTask
}) => {
    const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
    const [workitemId, setWorkitemId] = React.useState<string>();
    const [nodeId, setNodeId] = React.useState<string>();

    const canEdit = canEditWorkitems;

    const getColumns = () => {
        const colHelper = createColumnHelper<Workitem>();

        return [
            colHelper.accessor('workitem_id', {
                header: 'Workitem Id',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('workflow_description', {
                header: 'Workflow',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('node_name', {
                header: 'Task',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('last_user', {
                header: 'Last User',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('variables.table', {
                header: 'Table',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
            }),
            colHelper.accessor('start_timestamp', {
                header: 'Start Time',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={customDateFormat(cell.getValue(), DateFormatEnum.timestampDisplay, DateFormatEnum.timestamp)} />
            }),
            colHelper.accessor('due_date', {
                header: 'Due Date',
                enableResizing: true,
                cell: ({ cell }) => <TooltipCell value={customDateFormat(cell.getValue(), DateFormatEnum.timestampDisplay, DateFormatEnum.timestamp)} />
            }),
            colHelper.display({
                header: 'Actions',
                enableResizing: true,
                cell: ({ row }) => {
                    const workitem = row.original;
                    const actionsMenu = <div className="popover-menu-wrapper">
                        <MenuItem
                            icon={IconNames.ARROW_RIGHT}
                            onClick={() => continueTask(`${workitem.workitem_id}`, workitem.node_id)}
                            text='Continue Workitem'
                        />
                        <MenuItem
                            icon={IconNames.RESET}
                            onClick={() => releaseTask(`${workitem.workitem_id}`, workitem.node_id)}
                            text='Release Workitem'
                        />
                        {
                            canEdit &&
                            <MenuItem
                                icon={IconNames.EDIT}
                                onClick={() => { editTask(workitem) }}
                                text='Edit Workitem'
                            />
                        }
                        <MenuItem
                            text='Discard Workitem'
                            icon={IconNames.DELETE}
                            onClick={() => {
                                setWorkitemId(`${workitem.workitem_id}`);
                                setNodeId(workitem.node_id);
                                setShowDeleteWarning(true);
                            }}
                        />
                    </div>

                    return (
                        <Popover
                            className="popover-menu"
                            position={Position.BOTTOM_RIGHT}
                            content={actionsMenu}
                            minimal
                        >
                            <Button icon='menu' intent='none'></Button>
                        </Popover>
                    )
                },
            })
        ]
    }

    const columns = useMemo(() => getColumns(), []);
    const data = useMemo(() => workflows, [workflows]);

    return (
        <div>
            {
                showDeleteWarning &&
                <ConfirmDialog
                    body={`Are you sure you want to discard workflow #${workitemId}?`}
                    title='Discard Task'
                    cancelClick={() => setShowDeleteWarning(false)}
                    okClick={() => {
                        setShowDeleteWarning(false);
                        discardTask(workitemId, nodeId);
                    }}
                />
            }
            <ReactTable
                resultCount={data.length}
                columns={columns}
                data={data}
                idField='workitem_id'
                showPageSizeOptions={true}
            />
        </div>
    );
}

export default WorkflowAssignedTable;