import * as React from 'react';
import { Icon, IconSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { GroupType } from '../WorkflowMatchConstants';
import { WorkflowMatchTable, WorkflowMatchTableProps } from './WorkflowMatchTable';
import { createColumnHelper } from '@tanstack/react-table';

export const WorkflowMatchTableDraggable: React.FC<WorkflowMatchTableProps> = props => {
    const colHelper = createColumnHelper();
    const getColumns = (): any[] =>
        [colHelper.display({
            header: '',
            // className: 'workflow-match-drag-td',
            // headerClassName: 'workflow-match-drag-th',
            size: 35,
            id: 'match-handle',
            enableResizing: false,
            cell: () => <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} size={IconSize.LARGE} />
        })];
    return <WorkflowMatchTable showSubTables={true} {...props} groupColumns={getColumns()} groupType={GroupType.CUSTOM} />;
};
