import { Database } from "types";

export const DATABASE_DELETED = 'DELETE_DATABASE';
export const REQUEST_QUERY_DATABASES = 'REQUEST_QUERY_DATABASES';
export const RECEIVE_QUERY_DATABASES = 'RECEIVE_QUERY_DATABASES';
export const SET_DBNAME = 'SET_DBNAME';

export interface DatabaseState {
    all: Database[];
    selected: string;
    queriedAll: boolean;
}