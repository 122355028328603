/**
 * Given a typical MDM error object (or a string or an array of strings)
 * try and deal with the inconsistency of this input and convert it to a printable string (or possibly html if you
 * replace the separator with something like "<br />" or "</li><li>" or the like)
 *
 * @param error
 * @param separator
 * @return {*}
 */
export const mdmErrorToText = (err) => {
    const error = (err && err.error) || err;

    if (!error)
        return '';

    if (typeof error === 'string') {
        return error || 'An unspecified error occurred';
    }
    // Give people the first message as the message
    else if (error && Array.isArray(error.messages) && error.messages.length > 0 && typeof error.messages[0] === 'string') {
        return error.messages[0];
    }
    // Exception object has a singular message vs an array
    else if (error && typeof error.message === "string") {
        return error.message;
    }

    let text = '';

    const stackTrace = error.stack || error.stack;

    if (stackTrace) {
        // clip to 100 characters
        let stackPortion = stackTrace.substring(0, 100);
        const newlinePos = stackPortion.search("\n");
        if (newlinePos > 0)
            stackPortion = stackPortion.substring(0, newlinePos);

        text = stackPortion;
    }

    if (error.original_message) {
        text = `${error.original_message}\n${text}`;
    }

    return text;
};
