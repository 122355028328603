import { ToastPosition } from "react-toastify";
import { Action } from '.';
import {
    RECEIVE_CREATE_DATABASE,
    DISPLAY_MESSAGEBOX,
    RECEIVE_GROUPS,
    RECEIVE_ROLES,
    SET_CHANGESET,
    SET_USERNAME,
    SHOW_CREATE_DATABASE,
    SHOW_TOASTER,
    USER_ROLES,
    SET_CURRENT_TABLE,
    SET_PERIODIC_DATA
} from '../constants';

import { displayError, getSchema, queryChangesets } from 'api';
import { DatabaseApi } from '../api/DatabaseApi';
import { mdmErrorToText } from "helpers";
import { queryDatabases, setDatabase } from 'store/database/actions';
import { ChangesetFilter, ErrorHandler, Intent } from 'types';
import { GroupApi } from '../api/GroupApi';
import { UserApi } from '../api/UserApi';

export type setPeriodicData = (totalAssignedWorkflows: number, totalAvailableWorkflows: number) => (d: Function) => Action<SET_PERIODIC_DATA>;
export const setPeriodicData = (totalAssignedWorkflows: number, totalAvailableWorkflows: number) => {
    return (dispatch) => dispatch({ type: SET_PERIODIC_DATA, payload: { totalAssignedWorkflows, totalAvailableWorkflows } });
};

export type showToaster = (i: Intent, m: string, p?: ToastPosition) => (d: Function) => any;
export const showToaster = (intent: Intent, message: string, position?: ToastPosition) => {
    return (dispatch) => dispatch({ type: SHOW_TOASTER, payload: { timestamp: Date.now(), intent: intent, message: message, position: position } });
};

export const displayMessageBox = (options, show = true) => {
    return (dispatch => {
        // Not sure why show doesn't show
        const fullOptions = { ...options, show: show };
        //dispatch({ type: DISPLAY_MESSAGEBOX, show: display, options: options });
        dispatch({ type: DISPLAY_MESSAGEBOX, options: fullOptions });
    });
};

export const showCreateDatabase = (show: boolean) => {
    return (dispatch) => dispatch({ type: SHOW_CREATE_DATABASE, payload: show });
};

export const createDatabase = (dbName: string, desc: string) => {
    return (dispatch) => {
        DatabaseApi.create(dbName, desc).then(
            res => {
                dispatch({ type: RECEIVE_CREATE_DATABASE, payload: true });
                dispatch(setDatabase(dbName));
                // TODO: this is whack, and this whole thing probably shouldn't even be associated with the store
                dispatch(queryDatabases());
            },
            error => {
                console.error("error in create database: ", error);
                error = error.error || error.response_error || error;
                dispatch(displayMessageBox({
                    title: 'Create Database Failed',
                    message: `Failed to create database ${dbName}: ${mdmErrorToText(error)}`,
                }));
                dispatch({ type: RECEIVE_CREATE_DATABASE, payload: false });
            }
        );
    };
};

/** eventually replace constants with call to server */
export const getRoles = () => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_ROLES,
            payload: USER_ROLES
        });
    };
};
export const setChangeset = (changesetId: number | string) => {
    return (dispatch, getState) => {
        if (changesetId === 'current') {
            const changeset = { changeset: 'current' };
            dispatch({ type: SET_CHANGESET, changeset: changeset });
        } else {
            const state = getState();
            const filter: ChangesetFilter = { changeset: changesetId as number };
            queryChangesets(state.database.selected, filter, displayError('Query Changeset Failed', `Failed to query changeset ${changesetId}`))
                .then((changesets) => {
                    // TODO. Querying a non-existent changeset will return null but what should we do with that?
                    const changeset = (changesets && changesets[0]) || { changeset: 'current' };
                    dispatch({ type: SET_CHANGESET, changeset: changeset });
                });
        }
    };
};

//
export const setCurrentTable = (table: string) => {
    return dispatch => {
        dispatch({ type: SET_CURRENT_TABLE, table: table });
    };
};

export const setUsername = username => {
    return (dispatch => {
        dispatch({ type: SET_USERNAME, username: username || null });
    });
};

export type getGroups = () => () => Action<'RECEIVE_GROUPS'>;
export const getGroups = () => {
    return dispatch => {
        return GroupApi.listAllGroups()
            .then(groups => {
                dispatch({
                    type: RECEIVE_GROUPS,
                    groups
                });
            })
            .catch(err => dispatch(
                displayMessageBox({
                    title: 'Server Call Failed',
                    message: 'Couldn\'t retrieve groups  :' + mdmErrorToText(err),
                })
            ));
    };
};