import * as React from 'react';
import { ReactNode } from 'react';
import { connect } from 'react-redux';

import { IconNames } from '@blueprintjs/icons';
import { Icon, Menu, MenuDivider, MenuItem, Popover, Position } from '@blueprintjs/core';
import { ChangesetHistoryDialog } from './ChangesetHistoryDialog';
import { ChangesetMenu } from './ChangesetMenu';
import { DatabaseChangeMenu } from './DatabaseChangeMenu';
import { logout } from 'actions/login';
import { DateToYYYYMMDDHHMM, TimeStringToYYYYMMDDHHMM } from '../../utils';
import { getLatestChangeset, queryChangesets } from 'api';
import { CHANGESET_CURRENT, Changeset, ChangesetStatus, CustomIconName } from 'types';
import CustomIcon from '../Shared/CustomIcon/CustomIcon';
import { queryDatabases } from 'store/database/actions';
import { setChangeset } from 'actions';
import './TopBar.scss';

const productLogo = require('../../../images/product-logo.svg')

export interface TopBarProps {
    history: any;
    changeset: Changeset;
    dbName: string;
    username?: string;
    logout?: Function;
    login?: any;
    setChangeset: (changeset: number | string) => void;
    queryDatabases?: (start: number, count: number) => void;
    minimal?: boolean;
}
export interface TopBarState {
    csDate: Date;
    datePickerDialogOpen: boolean;
    online: boolean;
    pendingID: number;
}

export class TopBarBase extends React.Component<TopBarProps, TopBarState> {
    start: number;
    count: number;

    constructor(props) {
        super(props);

        this.start = 0;
        this.count = 100;

        this.state = {
            csDate: new Date(),
            datePickerDialogOpen: false,
            online: true,
            pendingID: undefined
        };
    }

    handleOffline = () => {
        const status = navigator.onLine;
        this.setState({
            online: status,
        });
    }

    componentDidMount() {
        window.addEventListener('online', this.handleOffline);
        window.addEventListener('offline', this.handleOffline);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleOffline);
        window.removeEventListener('offline', this.handleOffline);
    }

    // If this user has any pending changesets, find the first ID (for "my uncommitted changes" changeset menu option)
    findPendingChangesetId = (): void => {
        queryChangesets(this.props.dbName, { create_user: this.props.username, status: ChangesetStatus.PENDING })
            .then((changesets: Changeset[]) => {
                let id;
                if (changesets.length) {
                    const pending = changesets.filter(
                        (cs: Changeset) => cs.category === 'data' && cs.status === 'pending',
                    );
                    // sort descending
                    pending.sort((a: any, b: any) => {
                        const aDate = new Date(a.create_timestamp);
                        const bDate = new Date(b.create_timestamp);
                        return bDate.getTime() - aDate.getTime();
                    });
                    id = pending.length ? pending[0].changeset : undefined;
                }
                this.setState({ pendingID: id });
            });
    }

    handleChangesetDateChange = (newDate: Date) => {
        this.setState({ csDate: newDate });
    }

    handleChangesetHistoryDialogClose = () => {
        this.setState({ datePickerDialogOpen: false });
        (this.state.csDate == null) ? Promise.resolve(null) : getLatestChangeset(this.props.dbName, this.state.csDate)
            .then((changeset: Changeset) => {
                this.props.setChangeset(changeset ? changeset.changeset : CHANGESET_CURRENT);
            });
    }

    generateDatabaseWidget = (): ReactNode => {
        const { dbName, queryDatabases } = this.props;

        return (
            <Popover
                position={Position.BOTTOM_RIGHT}
                onOpening={() => queryDatabases(this.start, this.count)}
                content={<DatabaseChangeMenu />}
                minimal
            >
                <div className="flex-center-vertically widget">
                    <CustomIcon icon={CustomIconName.DATABASE} />
                    <span>{`Database: ${dbName}`}</span>
                    <CustomIcon aria-label="db-menu-dropdown" className="small-icon" icon={CustomIconName.CHEVRON_DOWN} />
                </div>
            </Popover>
        )
    }

    generateChangesetWidget = (username: string): ReactNode => {
        const { dbName, changeset } = this.props;
        // changeset -- for noting the current active changeset we are using

        const changesetString = changeset.changeset as any === CHANGESET_CURRENT ? 'Current data' :
            changeset.status === ChangesetStatus.COMMITTED ? ` History ${DateToYYYYMMDDHHMM(this.state.csDate)}` :
                changeset.status === ChangesetStatus.PENDING ? ` Changes ${TimeStringToYYYYMMDDHHMM(changeset.create_timestamp)}` :
                    'Unknown View';

        const notPending = changeset.status === ChangesetStatus.COMMITTED || changeset.changeset as any === CHANGESET_CURRENT;

        const changesetMenu = (
            <ChangesetMenu
                changeset={changeset}
                dbName={dbName} username={username}
                onHistorySelect={() => this.setState({ datePickerDialogOpen: true })}
                pendingChangesetId={this.state.pendingID}
            />
        )

        return (
            <Popover
                position={Position.BOTTOM_RIGHT}
                onOpening={() => this.findPendingChangesetId()}
                content={changesetMenu}
                minimal
            >
                <div className="flex-center-vertically widget">
                    <CustomIcon icon={CustomIconName.WRITE} />
                    <span className={`${notPending ? '' : 'topbar-pending'}`}>
                        {changesetString}
                    </span>
                    <CustomIcon className="small-icon" icon={CustomIconName.CHEVRON_DOWN} />
                </div>
            </Popover>
        )
    }

    generateUserMenuWidget = (fullName: string) => {
        const { logout, history } = this.props;
        const { username, minimal } = this.props;
        return (
            <Popover
                aria-label="user-menu-dropdown"
                className="user-menu-popover"
                position={Position.BOTTOM_LEFT}
                minimal={true}
            >
                <div className="flex-center-vertically widget user-menu-widget">
                    <CustomIcon icon={CustomIconName.PERSON} />
                    <span className="topbar-fullname">
                        {fullName}
                    </span>
                    <CustomIcon className="small-icon" icon={CustomIconName.CHEVRON_DOWN} />
                </div>

                <Menu>
                    {!minimal && <MenuItem icon={IconNames.COG} onClick={() => { history.push(`/settings/${username}`) }} text="Settings" />}
                    {!minimal && <MenuDivider key="divider" />}
                    <MenuItem icon={IconNames.LOG_OUT} onClick={() => logout()} text="Logout" />
                </Menu>
            </Popover>
        )
    }

    // comment out unused function
    // generateLogoutButton = (username: string): ReactNode => {
    //     return (
    //         <a
    //             role="presentation"
    //             className="logout-link"
    //             onClick={() => this.props.logout()}
    //         >
    //             <div className="flex-center-vertically fit-height widget">
    //                 <CustomIcon icon={CustomIconName.CIRCLE_ARROW_RIGHT} focused />
    //                 <span>Logout {username}</span>
    //             </div>
    //         </a>
    //     )
    // }

    generateOfflineWarning = () => {
        if (this.state.online) return null;

        return (
            <div className="flex-center-vertically fit-height offline">
                <Icon icon={IconNames.OFFLINE} />
                <span>Offline</span>
            </div>
        )
    }

    render() {
        const { minimal, login } = this.props;
        const model = login?.data?.model || {};
        const fullName = model.fullName || 'Unknown';

        return (
            <div className="heading topbar-container">
                <ChangesetHistoryDialog date={this.state.csDate} isOpen={this.state.datePickerDialogOpen} handleDateChange={this.handleChangesetDateChange}
                    onClose={this.handleChangesetHistoryDialogClose} />

                <div className="logo-container flex-center" title="RedPoint Master Data Management">
                    <img className="logo" src={productLogo} alt="Product Logo" />
                </div>

                <div className="fill-width topbar-text-elements">
                    <h5 className="product-title">
                        Master Data Management
                    </h5>
                    <div className="topbar-widgets">
                        {!minimal && this.generateOfflineWarning()}
                        {!minimal && this.generateDatabaseWidget()}
                        {!minimal && this.generateChangesetWidget(fullName)}
                        {this.generateUserMenuWidget(fullName)}
                    </div>
                </div>
            </div>
        );
    }
}

// tslint:disable-next-line:no-default-export
export const TopBar = connect(
    (state: any) => ({
        dbName: state.database.selected,
        changeset: state.changeset,
        username: state.username,
        userRoles: state.user.roles,
        login: state.login,
    }),
    { logout, setChangeset, queryDatabases },
)(TopBarBase);
