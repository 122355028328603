import { SET_PERMISSIONS, SET_PERMISSIONS_LOADING, CLEAR_PERMISSIONS, PermissionsState, PermissionActionTypes } from './types';

const defaultState: PermissionsState = {
    hasFetched: false,
    loading: false,
    permissions: []
};

export const permReducer = (state = defaultState, action: PermissionActionTypes): PermissionsState => {
    switch (action.type) {
        case SET_PERMISSIONS:
            return { ...state, permissions: action.payload.permissions, loading: false, hasFetched: true };
        case SET_PERMISSIONS_LOADING:
            return { ...state, loading: true, hasFetched: true };
        case CLEAR_PERMISSIONS:
            return { ...state, permissions: [], loading: false, hasFetched: false };
        default:
            return state;
    }
};
