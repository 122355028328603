import * as React from 'react';
import { connect } from 'react-redux';

import { inRoles } from 'api';
import { Button } from '../Shared';
import DatabasesTable from './DatabasesTable';
import { displayMessageBox, showCreateDatabase, showToaster } from '../../actions/ActionCreators';
import { MDMWrapper } from '../../components/Shared';
import { queryDatabases } from 'store/database/actions';
import { DatabaseEditDialog } from './DatabaseEditDialog';
import { Intent } from '../../types';

interface CurrentEditDb {
    name: string;
    description: string;
}

export interface DatabaseMainContainerProps {
    databases: object[];
    queryDatabases: (start: number, count: number) => void;
    showCreateDatabase: (show: boolean) => void;
    showToaster?: (i: Intent, m: string) => void;
    username: string;
    userRoles: string[];
}
export interface DatabaseMainContainerState {
    canEdit: boolean;
    currentEditDb?: CurrentEditDb | null;
}

class DatabaseMainContainerBase extends React.Component<DatabaseMainContainerProps, DatabaseMainContainerState> {
    start: number;
    count: number;

    constructor(props) {
        super(props);
        this.start = 0;
        this.count = 100;
        this.state = { canEdit: false };
    }

    componentDidMount() {
        this.queryDatabases();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const canEdit = inRoles(['dba', 'steward']);
        return { canEdit }
    }

    queryDatabases = () => this.props.queryDatabases(this.start, this.count);

    setCurrentEditDb = (name: string, description: string) => this.setState({
        currentEditDb: { name, description }
    })

    onDbEdit = (key: string, value: any) => this.setState({ currentEditDb: { ...this.state.currentEditDb, [key]: value } })

    render() {
        const { currentEditDb } = this.state;
        const createButton = this.state.canEdit ?
            <Button className="minimal-button" id="btnCreateDatabase" key={0} value="Create Database" onClick={() => this.props.showCreateDatabase(true)} />
            :
            null;
        return (
            <>
                <MDMWrapper buttons={[createButton]} title="Databases" documentationPath="create_database.html">
                    <DatabasesTable
                        databases={this.props.databases}
                        canEdit={this.state.canEdit}
                        setCurrentEditDb={this.setCurrentEditDb}
                    />
                </MDMWrapper>
                <DatabaseEditDialog
                    currentEditDb={currentEditDb}
                    onChange={this.onDbEdit}
                    queryDatabases={this.queryDatabases}
                    closeDialog={() => this.setState({ currentEditDb: null })}
                />
            </>
        );
    }
}
export const DatabaseMainContainer = connect(
    (state: any) => ({ dbName: state.database.selected, databases: state.database.all, username: state.username, userRoles: state.user.roles }),
    { displayMessageBox, queryDatabases, showCreateDatabase, showToaster }
)(DatabaseMainContainerBase);