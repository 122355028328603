
import * as React from 'react';
import { Icon, Intent, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from 'components/Shared';
import { displayField } from '../../../utils';
import { MatchGroupContainer } from './WorkflowMatchGroup/WorkflowMatchGroupContainer';
import { NotesTable } from 'components/NotesTable/NotesTable';
import { QueryRecordsForm } from 'components/QueryRecordsForm';
import { QueryRecordsTable } from 'components/SelectRecords/QueryRecordsTable';
import { WorkflowMatchTableGroup } from './WorkflowMatchGroup/WorkflowMatchTable';
import { WorkflowWidgets } from '../WorkflowWidgets'
import { MatchRecord } from './WorkflowMatchConstants';
import { FilterField, History, MDMRecord, Schema, Workflow } from 'types';

//import { THUMBS_DOWN } from '@blueprintjs/icons/lib/esm/generated/iconNames';


export interface WorkflowMatchManualFormProps {
    addMatchRecord: (id: string) => void;
    clearMatchRecords: () => void;
    completeTask: (groups: string[][], note: string, button:HTMLButtonElement) => void;
    discardTask: (note: string, button:HTMLButtonElement) => void;
    history: History[];
    matchRecords: object;
    queryRecords: (filterFields: FilterField[]) => Promise<any>;
    queryRecordsResult: MDMRecord[];
    schema: Schema;
    workflow: Workflow;
}
export interface WorkflowMatchManualFormState {
    customRecords: MatchRecord[];
    grouperShowing: boolean;
    note: string;
}

export class WorkflowMatchManualForm extends React.PureComponent<WorkflowMatchManualFormProps, WorkflowMatchManualFormState> {

    hiddenFields: string[];

    constructor(props: WorkflowMatchManualFormProps) {
        super(props);
        this.hiddenFields = ['_id', '_group'];
        this.state = { customRecords: this.makeCustomRecords(props.workflow, props.matchRecords), grouperShowing: false, note: '' };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.matchRecords !== this.props.matchRecords)
            this.setState({ customRecords: this.makeCustomRecords(this.props.workflow, this.props.matchRecords) });
    }
    // Map the records to
    // a) only contain display fields or tables with displayable fields or primary_key
    // b) with an _id field containing their primary key, and
    // c) a _group field that contains an incremental counter that conforms to group.id in the rpmdm_match subdocument if it exists
    makeCustomRecords = (workflow: Workflow, records: object): MatchRecord[] => {
        const variables = workflow.variables;
        const primaryKey = workflow.schema.primary_key;

        let groupCounter = 1;
        const groups = {};
        const matchTypeType = variables['match_type'] || "default";

        const matchRecords = Object.values(records).map(rec => {
            let groupId = groupCounter;
            const matchType = rec.rpmdm_match && rec.rpmdm_match[matchTypeType];
            if (matchType && matchType.group) {
                const group = matchType.group.id;
                if (!(group in groups)) {
                    groups[group] = groupId;
                    ++groupCounter;
                }
                groupId = groups[group];
            }
            else {
                // all by myself
                ++groupCounter;
            }
            return ({
                ...workflow.schema.fields.reduce((acc, cur) => {
                    return displayField(cur) || cur.field === primaryKey ? { ...acc, [cur.field]: rec[cur.field] } : acc;
                }, {}), _id: rec[primaryKey], _group: groupId
            });
        });

        return (matchRecords as any);
    }

    setRecordCustomGroup = (recordId, newGroupId) => {
        const primaryKey = this.props.workflow.schema.primary_key;

        const records = this.state.customRecords.map(r => r[primaryKey].toString() === recordId.toString() ?
            { ...r, _group: newGroupId } : r);
        this.setState({ customRecords: records });
    }

    // Turn the records custom groups into a map from group # to an array of record primary keys,
    // then return the values (an array of group arrays containing primary keys)
    buildCustomGroups = (): string[][] => {
        const primaryKey = this.props.workflow.schema.primary_key;
        const groups = this.state.customRecords.reduce((acc, cur) => {
            const group = cur._group as number;
            acc[group] = group in acc ? [...acc[group], cur[primaryKey]] : [cur[primaryKey]];
            return acc;
        }, {});
        return Object.values(groups);
    }

    clearMatchRecords = () => this.props.clearMatchRecords();
    completeTask = (button) => this.props.completeTask(this.buildCustomGroups(), this.state.note, button);
    discardTask = (button) => this.props.discardTask(this.state.note, button);
    hideGrouper = () => this.setState({ grouperShowing: false });
    showGrouper = () => this.setState({ grouperShowing: true });
    onNoteChange = (newNote: string) => this.setState({ note: newNote });
    onSelect = (id: string) => this.props.addMatchRecord(id);

    getButtons = (submitDisabled: boolean) =>
        [this.state.grouperShowing ?
            <Button value="BACK" key="workflowback" intent={Intent.PRIMARY} onClick={this.hideGrouper} /> :
            []
        ].concat(
            [
                <Button value="DISCARD" key="workflowdiscard" intent={Intent.DANGER} onClick={(e) => this.discardTask(e.currentTarget)} />,
                <Button value="SUBMIT" key="workflowsubmit" intent={Intent.SUCCESS} 
                    onClick={ (e) => this.completeTask(e.currentTarget) } disabled={submitDisabled} />,
            ])

    // We only want to display records that aren't in the match records
    queryRecordsCurrent = () =>
        this.props.queryRecordsResult.filter(r => !(this.state.customRecords.map(cr => cr._id).includes(r[this.props.workflow.schema.primary_key])))

    render() {
        const { grouperShowing } = this.state;
        const { workflow, queryRecordsResult } = this.props;
        // Don't allow a user to move to the next stage until they have chosen at least 2 records.
        // There is nothing break apart or put together until that state is met
        const continueDisabled = (this.state.customRecords.length < 2);

        return (
            <div className="workflow-match-wrapper">
                <div className="workflow-match-menu-wrapper">
                    <div className="workflow-menu">
                        {/* fixed title; don't allow submit from 1st screen */}
                        <div className="workflow-title">Manual Match
                            <Tooltip content="Documentation" position={Position.RIGHT}>
                                <div className="mdm-wrapper-docs-button flex-center">
                                    <Icon icon='help' onClick={() => { window.open(`/mdm-help/workflow_review_manual_match_steward.html?q=manual match`, 'mdm-help'); }} color="#636567" iconSize={20} />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="workflow-title-buttons">
                            {this.getButtons(!grouperShowing)}
                        </div>
                    </div >

                    <WorkflowWidgets tableName={workflow.tableName} workitemId={workflow.workitem_id} />

                    {
                        grouperShowing &&
                        <div className="workflow-match-custom-instruction">
                            <Icon icon={IconNames.ISSUE} className="workflow-match-custom-icon" />
                            Drag Records Between Groups
                        </div>
                    }
                </div>
                <div className="workflow-match">
                    {grouperShowing ?
                        (
                            <MatchGroupContainer
                                rowsDraggable={true}
                                records={this.state.customRecords}
                                schema={this.props.workflow.schema}
                                hiddenFields={this.hiddenFields}
                                setCustomGroup={this.setRecordCustomGroup} />
                        ) :
                        (
                            <div>
                                <QueryRecordsForm schema={this.props.workflow.schema} queryRecords={this.props.queryRecords} initialOpenState={true}
                                    initialField={true} />

                                {
                                    !!queryRecordsResult.length &&
                                    <div className="workflow-match-custom-instruction">
                                        <Icon icon={IconNames.ISSUE} className="workflow-match-custom-icon" />
                                        Double click a record in the table below to select it for matching.
                                    </div>
                                }
                                <QueryRecordsTable
                                    data={this.queryRecordsCurrent()}  
                                    onDoubleClick={this.onSelect}
                                    schema={this.props.workflow.schema} />
                                <div className="workflow-menu" style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                                    <div className="workflow-title">Selected Records</div>
                                    <div className="workflow-title-buttons">
                                        <Button value="CONTINUE" key="workflowcontinue" disabled={continueDisabled} intent={Intent.PRIMARY} onClick={this.showGrouper} />
                                        <Button value="CLEAR" key="workflowclear" intent={Intent.PRIMARY} onClick={this.clearMatchRecords} />
                                    </div>
                                </div>
                                <WorkflowMatchTableGroup
                                    defaultPageSize={1000}
                                    showSubTables={true}
                                    hiddenFields={this.hiddenFields}
                                    records={this.state.customRecords}
                                    schema={this.props.workflow.schema}
                                    customTableProps={{ noDataText: 'No records selected' }}
                                />
                            </div>
                        )
                    }
                    <NotesTable history={this.props.history || []} note={this.state.note} updateNote={this.onNoteChange} />
                </div>
            </div>
        );
    }
}