import * as React from 'react';
import { connect } from 'react-redux';
import { Classes, ITreeNode, Tooltip, Tree } from "@blueprintjs/core";

import { displayMessageBox } from '../../actions/ActionCreators';
import { IPermNode } from './IPermNode';
import { MDMWrapper } from 'components/Shared';
import { PermDetail } from './PermDetail';
import { PermSystemNode } from './PermSystemNode';
import { PermApi } from '../../api/PermApi';
import { queryDatabases } from 'store/database/actions';

require('./Permissions.scss');
require('../../helpers/styles/react-treeview.scss');

interface PermissionsProps {
    changeset: string | number;
    dbName: string;
    databases: object[];
    systemGroupName?: string;
    queryDatabases: (start: number, count: number) => any;
}

interface PermissionInfo {
    type: string; // table|column|...
    name: string; // db.tab.col
    groups: any[];
}

interface PermissionsState {
    activeNode: IPermNode;
    activeNodePermissions: PermissionInfo;
    count: number;
    dbName: string;
    root: IPermNode; // but only one
    start: number;
}

export class PermissionsContainer extends React.Component<PermissionsProps, PermissionsState> {


    constructor(props) {
        super(props);


        // Does the permissions Service belong in the state or just in the object directly?

        this.state = {
            activeNode: undefined,
            activeNodePermissions: {} as PermissionInfo,
            count: 100,
            dbName: '',
            root: new PermSystemNode(this.props.databases, this.props.changeset),
            start: 0,
        };
    }

    componentDidMount() {
        this.queryDatabases();
    }

    // static getDerivedStateFromProps(nextProps) {
    //     return { root: new PermSystemNode(nextProps.databases, nextProps.changeset) };
    // }
    componentDidUpdate(prevProps) {
        const { databases, changeset } = this.props;
        if (databases.length !== prevProps.databases.length ||
            changeset !== prevProps.changeset) {
            this.setState({ root: new PermSystemNode(databases, changeset) });
        }
    }

    queryDatabases = () => {
        this.props.queryDatabases(this.state.start, this.state.count);
    }

    // Someone has modified the permissions for the active node
    refreshGroups = (node: IPermNode) => {
        const sysGroup = this.props.systemGroupName || 'system';
        //const node = this.state.activeNode;
        PermApi.QueryEffective({ group: undefined, type: node.type, entity: node.fullNameFn() })
            .then((result: any) => {
                const groups = result.groups.filter(g => g.group !== sysGroup)
                    .sort((a: any, b: any) => a.group.localeCompare(b.group));
                const newPermissions: PermissionInfo = {
                    type: node.type,
                    name: node.fullNameFn(),
                    groups: groups,
                };
                this.setState({ activeNode: node, activeNodePermissions: newPermissions });
            })
            .catch(err => {
                console.error("error is: ", err)
                alert("Unable to reach the permissions service.\n\n" + JSON.stringify(err, null, 2));
            });
    }

    nodeFromId = (id: string | number, node: IPermNode) => {
        if (node.id === id) {
            return node;
        }
        let found = undefined;
        node.children.forEach((n) => {
            if (!found) {
                found = this.nodeFromId(id, n);
            }
        });
        return found;
    }

    handleNodeClick = (nodeData: ITreeNode, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
        //console.log(`Clicked on Node ${nodeData.label}`);
        const node: IPermNode = this.nodeFromId(nodeData.id, this.state.root);

        // currently we only refresh if you have changed active nodes
        if (node.canBeSelected) {
            this.setState({ activeNode: node });

            const entityType = node.type;
            const fullname = node.fullNameFn();

            if (this.state.activeNodePermissions.type !== entityType || this.state.activeNodePermissions.name !== fullname) {
                this.refreshGroups(node);
            }

        }
        else {
            if (node.isOpen = !node.isOpen) {
                node.getChildrenFn().then(() => { this.setState(this.state); });
            }
            else {
                this.setState(this.state);
            }
        }
    }

    handleNodeCollapse = (nodeData: ITreeNode) => {
        const node: IPermNode = this.nodeFromId(nodeData.id, this.state.root);
        if (!node.isLeaf) {
            node.isOpen = false;
            this.setState(this.state);
        }
    }

    handleNodeExpand = (nodeData: ITreeNode) => {
        const node: IPermNode = this.nodeFromId(nodeData.id, this.state.root);
        if (!node.isLeaf) {
            node.isOpen = true;
            node.getChildrenFn().then(() => {
                this.setState(this.state,
                    () => console.log("this.state after mutation", this.state));
            });
        }
    }

    render() {
        const systemNode = this.state.root.getBPNode();

        const treeMap = (
            <Tree
                contents={[systemNode]}
                onNodeClick={this.handleNodeClick}
                onNodeCollapse={this.handleNodeCollapse}
                onNodeExpand={this.handleNodeExpand}
            />
        );

        return (
            <MDMWrapper title="Permission Management" documentationPath="permissions_and_entities.html">
                <div className="perm-main">
                    <div className="perm-tree">{treeMap}</div>
                    <div className="perm-body">
                        <PermDetail
                            systemGroupName={this.props.systemGroupName || 'system'}
                            node={this.state.activeNode}
                            groups={this.state.activeNodePermissions && this.state.activeNodePermissions.groups}
                            refreshFn={this.refreshGroups}
                        />
                    </div>
                </div>
            </MDMWrapper>
        );
    }
}

// tslint:disable-next-line:no-default-export
export default connect(
    (state: any) => ({
        systemGroupName: state.auth.config?.systemGroupName,
        changeset: state.changeset.changeset,
        databases: state.database.all,
        dbName: state.database.selected,
    }),
    { displayMessageBox, queryDatabases }
)(PermissionsContainer);
