


export const toTitlecase = ( value:string):string => {
    let tokens = value.split(/[ _]+/);
    tokens = tokens.map(token => token.charAt(0).toUpperCase() + token.slice(1));
    const titleCased = tokens.join(' ').replace(/([A-Z])/g, ' $1').trim();
    return titleCased;
};

