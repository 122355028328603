// Original regex: '^[\\p{L}_][\\p{L}\\p{Nd}_]*$'
// temporarily using ascii regex:
const regExp = new RegExp('^[a-zA-Z_][A-Za-z0-9_]*$');

/**
 * Return true if the input string is a valid MDM identifier
 *  UNICODE Letter or Underscore followed by any number of UNICODE letters, numbers or underscores
 * @param {string} value
 * @return {string}
 */
export const isMDMName = (value: string): boolean => {
    return regExp.test(value);
};