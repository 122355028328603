/**
 * I'm sad we don't have the pipeline operator yet.
 * Usage:
 * 
 * ```
 * const add2 = x => x + 2;
 * const subtract4 = x => x - 5;
 * 
 * 
 * const f = pipe(add2, subtract5);
 * f(10) // 7
 * 
 * pipe(add2, subtract5)(10) // also 7
 * 
 * @param functions  
 */
export const pipe = (...functions) => input => [...functions].reduce(
    (acc, fn) => fn(acc),
    input
);
