/**
 * A quick implementation of node's deep-equal.
 *
 * We skip type checks here under the auspice that
 * we can esnure types via Typescript.
 * @param a object
 * @param b object
 */
export const deepEqual = (a:object & { prototype?: any }, b:object & { prototype?: any }) => {
    let i:number;
    let key:number | string;

    if (a == null || b == null) return false;

    if (a.prototype !== b.prototype) return false;

    const ka = Object.keys(a);
    const kb = Object.keys(b);

    // tslint:disable-next-line:triple-equals
    if (ka.length != kb.length) return false;

    ka.sort();
    kb.sort();

    for (i = ka.length - 1; i >= 0; i--) {
        // tslint:disable-next-line:triple-equals
        if (ka[i] != kb[i]) return false;
    }

    for (i = ka.length - 1; i >= 0; i--) {
        key = ka[i];
        if (!deepEqual(a[key], b[key])) return false;
    }

    return typeof a === typeof b;
};