import workflow from '../../../../images/workflow.svg'
import dataHub from '../../../../images/data-hub.svg'
import settings from '../../../../images/settings.svg'
import compliance from '../../../../images/compliance.svg'

import { CustomIconName } from '../../../types'

// These icons dont follow the same styles that any other (different color & size)
// The mappings are the one provided in the secion headers of the SideNav menu

export function getHeaderIcon(iconName) {
    switch(iconName) {
        case CustomIconName.SWAP: return workflow;
        case CustomIconName.GRAPH: return dataHub;
        case CustomIconName.COG: return settings;
        case CustomIconName.BADGE: return compliance;
        default: return null;
    }
}
