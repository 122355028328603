/* Paths the web service (or a user task) should call to get data from the services
 *  Back end services could/should call the services directly
 *  This means we have only one exposed service for these activities
 */
export class ChangesetUrl {
    public static commit    = '/ajax/changeset/commit';
    public static create    = '/ajax/changeset/create';
    public static discard   = '/ajax/changeset/discard';
    public static getLatest = '/ajax/changeset/getlatest';
    public static query     = '/ajax/changeset/query';
    public static summarize = '/ajax/changeset/summarize';
    //public static waitCommit = '/ajax/changeset/wait_commit';
}