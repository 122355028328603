export const addCommasToNumber = (num): string => {
    const stringified = num.toString();
    const tokens = stringified.split('.');
    tokens[0] = tokens[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return tokens.join('.');
};

export const removeCommasFromNumber = (num): string => {
    const stringified = num.toString();
    const tokens = stringified.split('.');
    tokens[0] = tokens[0].replace(/,/g, '');
    return tokens.join('.');
};
