import * as React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from '../Shared';
import { isMDMName } from '../../utils';
import { normalizeId, arraySwap } from '../../utils';
import { TableEditField } from './TableEditField';

import { DataTypes, Field, Schema, Table } from 'types';

export interface TableEditSubTabProps {
    index: number;
    originalFields: Field[];
    piiUse?: object;
    schema: Schema;
    tables: Table[];
    update: (newFields: Field[]) => void;
}
export class TableEditSubTab extends React.Component<TableEditSubTabProps> {

    doInnerFieldsUpdate = (field: Field, innerFields: Field[]) => {
        const newField = { ...field, schema: { ...field.schema, fields: innerFields } };
        const newFields = [...this.props.schema.fields.slice(0, this.props.index), newField, ...this.props.schema.fields.slice(this.props.index + 1)];
        this.props.update(newFields);
    }
    onFieldUpdate = (index, updatedField: Field) => {
        const field = this.props.schema.fields[this.props.index];
        const innerFields = [...field.schema.fields.slice(0, index), updatedField, ...field.schema.fields.slice(index + 1)];
        this.doInnerFieldsUpdate(field, innerFields);
    }
    onFieldDelete = (index) => {
        const field = this.props.schema.fields[this.props.index];
        const innerFields = field.schema.fields.filter((f, i) => f && i !== index);
        this.doInnerFieldsUpdate(field, innerFields);
    }
    onFieldMove = (direction, index) => {
        const field = this.props.schema.fields[this.props.index];
        const last = field.schema.fields.length - 1;

        const other = (direction === 'up' && index !== 0) ?
            // swap the index and index - 1
            index - 1 :
            (direction === 'down' && index !== last) ?
                index + 1 : false;

        if (other !== false) {
            const innerFields = arraySwap(field.schema.fields, index, other);
            this.doInnerFieldsUpdate(field, innerFields);
        }
    }

    getNestedFields = (newName) => {
        if (!isMDMName(newName)) {
            return 'Use a valid MDM identifier';
        }

        const { schema, index } = this.props;
        const field = schema.fields[index];
        const nestedFields = field.schema.fields;
        const normalizedName = normalizeId(newName);
        const repeatCount = nestedFields.filter(field => normalizeId(field.field) === normalizedName).length;

        if (repeatCount > 1) {
            return 'Field already exists.'
        }

        return null;
    }

    /**
     * Add a field to a sub document.  This duplicates the TableEditMainTab::newField and should be merged
     */
    newField = () => {
        const field = this.props.schema.fields[this.props.index];
        const fieldNumber = field.schema.fields.reduce((last, f) => {
            let val = last;
            if (f.field.startsWith('newfield')) {
                const test = Number(f.field.substring('newfield'.length));
                if (test !== NaN && test + 1 > val) {
                    val = test + 1;
                }
            }
            return val;
        }, 1);
        const innerFields = field.schema.fields.concat({
            field: `newfield${fieldNumber}`, nullable: !!this.props.originalFields, pii: field.pii, index: false, display: true, datatype: { type: DataTypes.text, size: 64, signed: true }
        });
        this.doInnerFieldsUpdate(field, innerFields);
    }

    render() {
        const self = this; // I could prolly
        const index = this.props.index;

        // We are a subdocument. Need to find my fields
        const field = self.props.schema.fields[index];
        const fields = field.schema.fields;
        const subfields = self.props.originalFields && self.props.originalFields[field.field] && self.props.originalFields[field.field].schema.fields;

        const lastFieldIndex = fields.length - 1;
        const rows = fields.map((f, i) => {
            // You can't uncheck nullable if you are a new field or the field is already nullable
            let notNullable = false;
            if (subfields) {
                if (subfields[f.field]) {
                    notNullable = subfields[f.field].nullable;
                }
                else {
                    notNullable = false;
                }
            }
            const piiDisabled = field.pii || this.props.piiUse.hasOwnProperty(`${field.field}.${f.field}`);

            const fieldError = this.getNestedFields(f.field);

            return <TableEditField
                key={i}
                field={f}
                idPrefix={`table-create-sub-${index}-`}
                index={i}
                last={i === lastFieldIndex}
                notNullable={notNullable}
                onChange={self.onFieldUpdate}
                onDelete={self.onFieldDelete}
                onMove={self.onFieldMove}
                originalField={subfields && subfields[f.field]}
                piiDisabled={piiDisabled}
                cantDelete={piiDisabled}
                tables={self.props.tables}
                subField={true}
                isPrimaryKey={false}
                fieldError={fieldError}
            />;
        });
        return (
            <div>
                <HTMLTable bordered={true} striped={true} style={{ width: "100%" }}>
                    <thead><tr>
                        <th>Field</th>
                        <th>Nullable</th>
                        <th>PII</th>
                        <th>Index</th>
                        <th>Display</th>
                        <th>Type</th>
                        <th>Details</th>
                        <th></th>
                        <th>Actions</th>
                    </tr></thead>
                    <tbody>
                        {rows}
                        <tr>
                            <td colSpan={9}>
                                <Button style={{ marginLeft: '75px' }} className='toolbar-narrow' onClick={self.newField} title='Add a field to table' icon={IconNames.PLUS} />
                            </td>
                        </tr>
                    </tbody>
                </HTMLTable>
            </div>);
    }
}
