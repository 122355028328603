import { systemSettingsActionTypes } from '../reducers/systemSettings';

export const setBadgeFetchFlag = (fetchBadges: boolean) => {
    return dispatch => {
        dispatch({ type: systemSettingsActionTypes.SET_BADGE_FETCH_FLAG, payload: { fetchBadges } });
    };
};

export const setTracePostsFlag = (tracePosts: boolean) => {
    return dispatch => {
        dispatch({ type: systemSettingsActionTypes.SET_TRACE_FLAG, payload: { tracePosts } });
    };
};
