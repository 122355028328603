/**
 * Created by jgoldstein on 4/26/2017.
 */
import { RECEIVE_ROLES } from '../constants';

export const roles = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_ROLES:
            return action.payload.roles;
        default:
            return state;
    }
};