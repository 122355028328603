import * as React from 'react';
import { connect } from 'react-redux';
import { Dialog, Button, Classes, Intent, FormGroup, InputGroup, FileInput } from '@blueprintjs/core';
import { Intent as customIntent } from '../../types';
import { WorkflowApi } from '../../api';
import { showToaster } from '../../actions';
import { IconNames } from '@blueprintjs/icons';
import './TableImportDialog.scss'

export interface TableImportProps {
    isOpen: boolean;
    closeDialog: () => void;
    showToaster: showToaster;
    onSave: (name: string, description: string, jsonContents: object) => void;
}

export interface TableImportState {
    name: string;
    description: string;
    uploadStringContents: string;
    fileName: string;
    warningText: string;
}

const initialState = {
    name: '',
    description: '',
    uploadStringContents: '',
    fileName: '',
    warningText: '',
}

export class TableImportDialogBase extends React.Component<TableImportProps, TableImportState> {
    constructor(props) {
        super(props);
        this.state = { ...initialState }
    }

    handleFileUpload = (file) => {
        let fileReader = new FileReader();

        fileReader.onloadend = (event) => {
            let buffer = event.target.result;
            this.setState({
                uploadStringContents: buffer.toString(),
                fileName: file.name
            })
        };

        fileReader.readAsText(file);
    }

    jsonFromString(jsonString: string) {
        try {
            let parsed = JSON.parse(jsonString);
            if (parsed && typeof parsed === "object") {
                return parsed;
            }
            return null;
        }
        catch (e) {
            return null;
        }
    };

    generateDialogBody = (jsonContents: object | null) => {
        const { fileName, name, description } = this.state;

        return (
            <div className="flex-column workflow-detail-form">
                <FormGroup
                    label="Table Name*"
                    inline={true}
                    className={`${Classes.TEXT_MUTED}`}
                    contentClassName="pr-input"
                >
                    <InputGroup
                        value={name}
                        name="name"
                        onChange={e => this.setState({ name: e.target.value })}
                    />
                </FormGroup>

                <FormGroup
                    label="Description"
                    inline={true}
                    className={`${Classes.TEXT_MUTED}`}
                    contentClassName="pr-input"
                >
                    <InputGroup
                        value={description}
                        name="description"
                        onChange={e => this.setState({ description: e.target.value })}
                    />
                </FormGroup>

                <br />

                <span className={`bp3-label ${Classes.TEXT_MUTED}`}>Select JSON Schema</span>
                <FileInput
                    buttonText="Browse"
                    text={fileName}
                    inputProps={{ accept: ".json" }}
                    onInputChange={(e: any) => this.handleFileUpload(e.target.files[0])}
                />

                {this.renderWarnings(jsonContents)}
            </div>
        )
    }

    renderWarnings = (jsonContents: object | null) => {
        let warnings = []
        const { name, fileName } = this.state;

        if (fileName && !jsonContents) {
            warnings.push(
                <span
                    key="warn-not-json"
                    className={`bp3-labe warn-label`}>
                    File is not a valid JSON format
                </span>
            )
        }
        if (!name && jsonContents) {
            warnings.push(
                <span
                    key="warn-not-json"
                    className={`bp3-labe warn-label`}>
                    Please provide a name
                </span>
            )
        }

        return warnings;
    }

    closeAndClearDialog = () => {
        const { closeDialog, onSave, isOpen } = this.props;
        this.setState(initialState, () => closeDialog());
    }

    render() {
        const { uploadStringContents, name, description } = this.state;
        const { onSave, isOpen } = this.props;
        const jsonContents = this.jsonFromString(uploadStringContents);

        return (
            <Dialog
                title="Import Table Schema"
                icon={IconNames.IMPORT}
                className="table-import-modal"
                onClose={this.closeAndClearDialog}
                isOpen={isOpen}
                canOutsideClickClose={false}
            >
                <div className={Classes.DIALOG_BODY}>
                    {this.generateDialogBody(jsonContents)}
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            id='pr-modal-save'
                            icon={IconNames.FLOPPY_DISK}
                            className="user-save-button"
                            onClick={() => onSave(name, description, jsonContents)}
                            disabled={!jsonContents || !name}
                            value="Save"
                            text="Import"
                        />
                        <Button
                            id='pr-modal-close'
                            icon={IconNames.CROSS}
                            onClick={this.closeAndClearDialog}
                            intent={Intent.DANGER}
                            value="Cancel"
                            text="Cancel"
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
}

export const TableImportDialog = connect((state: any) => ({}), { showToaster })(TableImportDialogBase as any);