/**
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { Overview } from './Overview';
import { Stack } from './Stack';
import { getPermissionsByLoggedUserGroups } from 'store/permissions/selectors';
import { Button, Icon, Position, Tooltip, Intent } from '@blueprintjs/core';

import './Dashboard.scss';

export interface DashboardProps {
    dbName: string;
    fullName?: string;
    username?: string;
    history: any;
    permissions: string[];
    permissionsLoading: boolean;
}
export interface DashboardState {
    count: number;
    onLine: boolean;
}

class DashboardBase extends React.Component<DashboardProps, DashboardState> {
    constructor(props) {
        super(props);
        this.state = {
            count: 100,
            onLine: true,
            permissions: []
        }
    }

    public render() {
        const { permissions, permissionsLoading, fullName, history } = this.props;
        return (
            <div className="mdm-container">
                <div className='dashboard-title'>Welcome Back {fullName}.

                    <Tooltip content="Documentation" position={Position.RIGHT}>
                        <div className="mdm-wrapper-docs-button flex-center">
                            <Icon icon='help' onClick={() => { window.open(`/mdm-help/dashboard.html?q=dashboard`, 'mdm-help'); }} color="#636567" iconSize={20} />
                        </div>
                    </Tooltip>
                </div>
                <Overview permissionsLoading={permissionsLoading} permissions={permissions} />
                <Stack permissionsLoading={permissionsLoading} history={history} permissions={permissions} />
            </div>
        );
    }
}

export const Dashboard = connect(
    (state: any) => ({
        permissions: getPermissionsByLoggedUserGroups(state),
        permissionsLoading: state.permissions.loading,
        dbName: state.database.selected,
        fullName: state.login && state.login.data && state.login.data.model && state.login.data.model.fullName || 'no name',
        username: state.username,
    })
)(DashboardBase);