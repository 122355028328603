import * as R from 'ramda';

import { displayMessageBox } from 'actions/ActionCreators';
import { MDMStore } from 'store';
import { mdmErrorToText } from 'helpers';

// note: leaving mdmerrortotext out because the java server errors are so
// esoteric and long and don't seem to have any information not contained in the stack.
export const displayError = R.curry((title: string, uiMessage: string, error: any) => {
    error = error.error || error;
    const serverMessage = mdmErrorToText(error);
    const message = serverMessage ? `${uiMessage}: ${serverMessage}` : uiMessage;
    const stackTrace = error ? error.stack || error.stack : null;

    MDMStore.dispatch<any>(displayMessageBox({
        title: title,
        message: `${message}`,
        stack: stackTrace
    }));
});