import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Popover, Position, PopoverInteractionKind, Spinner, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { displayError, RecordQueryParams, RecordApi, TableApi } from 'api';
import { showToaster } from '../../actions';
// import { mdmErrorToText } from 'helpers';
import { Intent } from 'types';

export interface RecordsLinkFieldCellProps {
    linkDisplayField: string;
    linksTo: string;
    dbName: string;
    changeset: any;
    value: string;
}
export const RecordsLinkFieldCell: React.FC<RecordsLinkFieldCellProps> = (props): JSX.Element => {
    const { linkDisplayField, linksTo, dbName, changeset, value } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [records, setRecords] = useState(null);

    const dispatch = useDispatch()

    const displayRecordsError = displayError('Query Records Failed', 'Failed to find(query) records');

    const getPrimaryKey = async () => {
        return TableApi.queryTables(dbName, changeset.changeset, linksTo, false)
            .then(tables => {
                console.log("tables response: ", tables)
                const table = tables.find(t => t.table === linksTo);
                return table.schema.primary_key;
            })
    }

    const queryRecords = async (pkField: string) => {
        const queryParams: RecordQueryParams = {
            database: dbName,
            table: linksTo,
            changeset: changeset.changeset,
            include: {
                match: true,
                conflict: true,
                version: false,
                mask: false,
            },
            filter: {
                fields: [{ field: pkField, values: [value.toString()], operation: "=" }],
                masked: false
            },
            errorHandler: displayRecordsError
        }

        RecordApi.queryRecords(queryParams)
            .then(records => {
                console.log("queried records: ", records)
                setRecords(records);
                setIsLoading(false);
            }).catch(err => {
                console.error(err);
                setIsLoading(false);
                dispatch(showToaster(Intent.WARNING, "Could not query records."));
            })
    }

    useEffect(() => {
        if (isOpen && !records) {
            getPrimaryKey().then(pkField => {
                queryRecords(pkField);
            }).catch(err => {
                console.error(err);
                dispatch(showToaster(Intent.WARNING, "Could query linked table."));
                setIsLoading(false);
            })
        }
    }, [isOpen])

    const renderLinkedRecord = () => {
        if (!records?.length) {
            return <div>No Records</div>;
        }

        return (records && records[0][linkDisplayField]);
    }

    return (
        <Popover
            position={Position.RIGHT}
            interactionKind={PopoverInteractionKind.CLICK}
            hoverOpenDelay={80}
            hoverCloseDelay={80}
            onOpened={() => {
                setIsOpen(true);
            }}
            onClose={() => setIsOpen(false)}
            content={
                <div className="linked-record-popover-contents">
                {
                    isLoading ?
                        <Spinner size={20} /> :
                        renderLinkedRecord()
                }
            </div>
            }
        >
            {
                (value !== null && value !== "" && value !== undefined) ?
                    <Button className='link-field-button' minimal={true} icon={IconNames.LINK}
                        onClick={e => { }}>{value}</Button> :
                    <div></div>
            }
            
        </Popover >
    )
}