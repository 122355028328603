import { SET_PERIODIC_DATA, CLEAR_PERIODIC_DATA } from '../constants';

export interface PeriodicDataProps {
    totalAssignedWorkflows: number;
    totalAvailableWorkflows: number;
}

const defaultState = {
    totalAssignedWorkflows: 0,
    totalAvailableWorkflows: 0,
};

export const periodicData = (state = defaultState, action): PeriodicDataProps => {
    switch (action.type) {
        case SET_PERIODIC_DATA:
            return action.payload;
        case CLEAR_PERIODIC_DATA:
            return defaultState;
        default:
            return state;
    }
};
