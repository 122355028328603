import React, { FC, useMemo } from 'react';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { Button } from 'components/Shared';
import { IBaseRecordsTableProps, RecordsTable } from 'components/RecordsTable';
import { RecordsTableRecordFieldCell, RecordsTableRecordFieldCellProps } from 'components/RecordsTable/RecordsTableRecordFieldCell';
import { RecordsLinkFieldCell } from 'components/RecordsTable/RecordsLinkFieldCell';
import { DataTypes, Field, Schema, Changeset } from 'types';
import { Pagination } from 'rp-mdm-common';
import { IconNames } from '@blueprintjs/icons';
import './RecordsMain.scss';
import { sortRecordFields, toTitlecase } from 'utils';
import { Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { formatDateTimeDisplay } from 'helpers/date-format';
import { createColumnHelper } from '@tanstack/react-table';

export interface IRecordsMainRecordsTableProps extends IBaseRecordsTableProps {
    schema: Schema;
    editRecs: boolean;
    loading?: boolean;
    dbName: string;
    changeset: Changeset;
    currentTable?: string | null;
    onClickRecordEdit: (id: string) => void;
    onClickRecordDelete: (id: string) => void;
    onClickRecordHistory: (id: string) => void;
    onClickRecordActivity: (id: string) => void;
    paginate: (pagination: Pagination) => void;
}

export interface IRecordsMainRecordsTableState {
    tableRecords?: { schema: Schema, records: object[] };
}

const RecordsMainRecordsTable: FC<IRecordsMainRecordsTableProps> = ({
    editRecs,
    loading,
    schema,
    data,
    defaultPageSize,
    tooltipText,
    dbName,
    changeset,
    paginate,
    currentTable,
    onClickRecordActivity,
    onClickRecordDelete,
    onClickRecordEdit,
    onClickRecordHistory,
}) => {
    const renderCell =  ({ row, cell }) => {
        const field = schema.fields.find(f => f.field === cell.column.columnDef.accessorKey);
        let tooltip = tooltipText || cell.getValue();

        const isTable = !!field?.schema?.fields?.length;
        const isLink = field?.link_display_field && field?.links_to;
        let val = cell.getValue();

        if (isTable || isLink) {
            return <div>{val}</div>
        }
        
        val =  formatDateTimeDisplay(val, field);

        return (
            <Popover
                // popoverClassName="bp5-popover-content-sizing"
                interactionKind={PopoverInteractionKind.HOVER}
                hoverOpenDelay={80}
                hoverCloseDelay={80}
                content={
                    <div className="p-2">
                        {tooltip}
                    </div>
                }
            >
                <div>{val}</div>
            </Popover>
        );
    }

    const getColumns = () => {
        const colHelper = createColumnHelper();
        if (!schema) return [colHelper.display({ header: '', id: 'no-schema' })];
        let result = [];

        // TODO accessor to one row after debugging
        if (schema.matching && schema.matching.enabled && schema.matching.display) {
            const onlyOne = schema.matching.match_types.length == 1;
            
            result = schema.matching.match_types.map((type: any, index) => {
                // default gets to be named
                return colHelper.accessor(
                    (row: any) => {
                        return (row.rpmdm_match && row.rpmdm_match[type.type] && row.rpmdm_match[type.type].group && row.rpmdm_match[type.type].group.id) || ''
                    },
                    {
                    // Header: onlyOne ? "GROUP ID" : toTitlecase(type.type) + " GROUP ID",
                    header: onlyOne ? "GROUP ID" : toTitlecase(type.type) + " GROUP ID",
                    id: `matchid${index}`,
                    cell: renderCell
                });
            });
        }

        const cols = result.concat(schema.fields
            .filter(field => field.schema ? field.schema.fields.some(f => f.display) : field.display)
            .map((field, index) => {
                return colHelper.accessor(field.field, {
                    id: `${index}-${field.field}`,
                    header: field.field,
                    cell: renderCell,
                    sortingFn: sortRecordFields
                        
                })
            }));

        cols.push(colHelper.display({
            header: 'History',
            cell: ({ row }) => (
                <Button
                    className='history-button'
                    minimal={true}
                    icon={IconNames.CALENDAR}
                    onClick={e => onClickRecordHistory(row.original[schema.primary_key])}
                >
                    &nbsp; History
                </Button>
            ),
        }));

        cols.push(colHelper.display({
            header: 'Activity',
            cell: ({ row }) => (
                <Button
                    className='activity-button'
                    minimal={true}
                    icon={IconNames.HISTORY}
                    onClick={e => onClickRecordActivity(row.original[schema.primary_key])}
                >
                    &nbsp; Activity
                </Button>
            ),
        }));

        if (editRecs) {
            cols.push(colHelper.display({
                header: 'Edit',
                cell: ({ row }) => (
                    <Button
                        minimal={true}
                        icon={IconNames.EDIT}
                        onClick={e => onClickRecordEdit(row.original[schema.primary_key])}
                    >
                        Edit
                    </Button>
                ),
            }));

            cols.push(colHelper.display({
                header: 'Delete',
                cell: ({ row }) => (
                    <Button
                        minimal={true}
                        icon={IconNames.TRASH}
                        onClick={e => onClickRecordDelete(row.original[schema.primary_key])}
                    >
                        &nbsp; Delete
                    </Button>
                ),
            }));
        }



        return cols;
    };

    const getRecordsData = () => {
        if (!schema) return [];
        return data.map((recordObject: any, index) => {
            const id = recordObject[schema.primary_key];
            const isMasked = recordObject.rpmdm_mask && recordObject.rpmdm_mask.masked;
            const rec: any = schema.fields
                .reduce((acc: object, cur: Field) => {
                    if (cur.datatype.type === DataTypes.table) {
                        return ({
                            ...acc,
                            [cur.field]: (
                                <RecordsTableRecordFieldCell
                                    key={`record-cell-${index}`}
                                    records={recordObject[cur.field] || []}
                                    schema={cur.schema}
                                />
                            )
                        })
                    }

                    if (cur.links_to && cur.link_display_field) {
                        return ({
                            ...acc,
                            [cur.field]: (
                                <RecordsLinkFieldCell
                                    key={`record-link-cell-${index}`}
                                    dbName={dbName}
                                    changeset={changeset}
                                    value={recordObject[cur.field]}
                                    // schema={cur.schema}
                                    linksTo={cur.links_to}
                                    linkDisplayField={cur.link_display_field}
                                />
                            )
                        })
                    }

                    return ({ ...acc, [cur.field]: recordObject[cur.field] })
                }, {});

            if (recordObject.rpmdm_match) {
                rec.rpmdm_match = recordObject.rpmdm_match;
            }

            return rec;
        });
    };

    const recordData = useMemo(() => getRecordsData(), [currentTable, schema, data, dbName, changeset]);

    const columns = useMemo(() => getColumns(), [currentTable, schema, data]);

    return (
        <div className='records-table-container' id='records-table'>
            <ReactTable
                data={recordData}
                resultCount={recordData.length}
                refreshTag={currentTable}
                columns={columns}
                loading={loading}
                defaultPageSize={defaultPageSize}
                emptyText='No data found'
                paginate={paginate}
                showPageSizeOptions={true}
            />
        </div>
    );
};

export default RecordsMainRecordsTable;