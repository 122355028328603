/**
 * @format
 */
import * as React from 'react';

import { Classes, Dialog, FormGroup, HTMLSelect, Intent, Label } from '@blueprintjs/core';
import { Button, ConfirmationInput } from 'components/Shared';
import { displayField } from 'utils';
import { Field, QueryFilter, Schema } from 'types';
import { IconNames } from '@blueprintjs/icons';

export interface ErasureDialogProps {
    isOpen: boolean;
    onClose: any;
    onMaskPurge: (recordIDs: string[]) => void;
    primaryKey: string;
    queryRecords: (filter: QueryFilter) => Promise<any[]>;
    record: any;
    schema: Schema;
    type: ErasureDialogType;
}
export interface ErasureDialogState {
    canConfirm: boolean;
    filterTypes: string[];
    recordKeys: any[];
    matchedRecords: any[];
}
export enum ErasureDialogType {
    Mask,
    Purge
}
export class ErasureDialog extends React.Component<ErasureDialogProps, ErasureDialogState> {
    constructor(props) {
        super(props);
        const keys = [];
        props.record && keys.push(props.record[props.primaryKey]);
        this.state = {
            canConfirm: false,
            filterTypes: [],
            matchedRecords: [],
            recordKeys: keys
        };
    }

    private onSuccess = e => this.setState({ canConfirm: true });
    private onError = e => this.setState({ canConfirm: false });

    setFilteredMatchings = () => {
        const { record, primaryKey } = this.props;
        if (!record) return null;
        // we must retrieve matching records if the match subdocument:
        //  a) exists, and
        //  b) contains any non-null group ids
        const matches = record.rpmdm_match && Object.entries(record.rpmdm_match);
        const filterTypes = matches ? matches.filter(m => (m[1] as any).group).map(n => n[0]) : [];
        const keys = [];
        keys.push(record[primaryKey]);
        this.setState({ filterTypes, recordKeys: keys, matchedRecords: [] });
    }

    componentDidUpdate(prevProps) {
        const { record } = this.props;
        if (record !== prevProps.record)
            this.setFilteredMatchings();
    }

    componentDidMount() {
        this.setFilteredMatchings();
    }

    private maskOrPurgeRecords = () => {
        this.props.onMaskPurge(this.state.recordKeys);
    }

    private rowSelect = (e: string) => {
        const keys = this.state.recordKeys.includes(e) ? this.state.recordKeys.filter(k => k !== e) :
            [...this.state.recordKeys, e];
        this.setState({ recordKeys: keys });
    }

    // private filterRecord(record) {
    //     if (record == null) return {};
    //     return Object.keys(record)
    //         .filter(k => k !== 'actions' && k !== 'rpmdm_match')
    //         .reduce((prev, curr) => {
    //             return { ...prev, [curr]: record[curr] };
    //         }, {});
    // }

    private onFilterTypeChange = (e: React.FormEvent<HTMLSelectElement>) => {
        // filter out the original record (has the primary key value in this.props.primaryKey)
        const type = e.currentTarget.value;
        type && this.props.queryRecords(
            { match: { type: type, matched: true, group: this.props.record.rpmdm_match[type].group.id } }
        )
            .then(res => this.setState({ matchedRecords: res.filter(r => r[this.props.primaryKey] !== this.props.record[this.props.primaryKey]) }));
    }
    private confirmEnterPressed = () => {
        if (this.state.canConfirm)
            this.maskOrPurgeRecords();
    }
    // TODO - give this a return type
    private getHeaderRow = () => {
        const { schema } = this.props;

        // this code is essentially a duplicate of the WorkflowMatchTable getColumns
        let headers: JSX.Element[] = [];
        let index = 1;
        if (schema && schema.fields) {
            // non-table fields in schema order
            schema && schema.fields.filter(f => f.datatype.type !== 'table' && f.display).forEach(f => {
                headers.push(<div className="td" key={index}>{f.field}</div>);
                ++index;
            });
            // table fields
            schema && schema.fields.filter(f => f.datatype.type === 'table' && displayField(f)).forEach(f => {
                headers.push(<div className="td" key={index}>{f.field}</div>);
                ++index;
            });
        }
        return headers;
    }

    private getTableRow(record: Field) {
        //return Object.values(record).map((v, i) => <div className="td" style={{justifyContent: 'center'}}key={i}><span>{v}</span></div>);
        const { schema } = this.props;

        // this code is essentially a duplicate of the WorkflowMatchTable getColumns
        let row: JSX.Element[] = [];
        let index = 1;
        if (schema && schema.fields) {
            // non-table fields in schema order
            schema && schema.fields.filter(f => f.datatype.type !== 'table' && f.display).forEach(f => {
                row.push(<div className="td" style={{ justifyContent: 'center' }} key={index}>{record[f.field]}</div>);
                ++index;
            });
            // table fields
            schema && schema.fields.filter(f => f.datatype.type === 'table' && displayField(f)).forEach(f => {
                let values = (<div className="td" style={{ justifyContent: 'center' }} key="sub1">no value</div>); // default if no values

                const nested = record[f.field];
                // Only showing the 1st value of each record
                if (nested && nested.length) {
                    const keys = f.schema.fields.filter(inner => inner.display).map(f => f.field).slice(0, 3); // TODO read slice size from config
                    values = nested.slice(0, 3).map((r, index2) => {
                        let nestedValues: string[] = [];
                        keys.forEach(k => nestedValues.push(r[k]));
                        return <div style={{ width: "100%" }} key={`sub${index2}`}>{nestedValues.join(" / ")}</div>
                    });
                }
                row.push(<div className="td" style={{ justifyContent: 'center' }} key={index}>{values}</div>);
                ++index;
            });
        }
        return row;
    }

    public render() {
        const { isOpen, onClose } = this.props;
        //const record = this.filterRecord(this.props.record);
        const record = this.props.record;
        const tableHeader = this.getHeaderRow();
        const recordRow = this.getTableRow(record);
        const matchRows = this.state.matchedRecords ?
            this.state.matchedRecords.map((r, i) => {
                const pk = r[this.props.primaryKey];
                const classname = this.state.recordKeys.includes(pk) ? "mask-record-table-selected-row" : "";
                return <div className={`tr ${classname}`} role="button" onClick={() => this.rowSelect(pk)} key={pk}>{this.getTableRow(r)}</div>;
            }) : null;

        const filters = [{ value: "", label: "Please select a match type..." }].concat(
            this.state.filterTypes.map((t, i) => ({ value: t, label: t })));

        const filterTypes = this.state.filterTypes.length > 0 ?
            <FormGroup inline={true} label="Record matches found">
                <HTMLSelect
                    defaultValue=""
                    onChange={this.onFilterTypeChange}
                    options={filters}
                />
            </FormGroup> : null;

        return (
            <Dialog
                icon={IconNames.ERASER}
                className="mask-dialog"
                isOpen={isOpen}
                onClose={onClose}
                style={{ width: '50%' }}
                title={`${this.props.type === ErasureDialogType.Mask ? 'Mask' : 'Purge'} Records`}
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>{`${this.props.type === ErasureDialogType.Mask ? 'Mask' : 'Purge'}`} the record selected?</p>
                    <div style={{ flex: '1', display: 'flex', overflow: 'auto', marginBottom: '1rem' }}>
                        <div className="mask-table">
                            <div className="tr th">
                                {tableHeader}
                            </div>
                            <div className="tr mask-record-table-record-row">
                                {recordRow}
                            </div>
                            {matchRows.length ? <div className="tr td mask-record-table-mask-title">Select matched records to
                                {`${this.props.type === ErasureDialogType.Mask ? ' mask' : ' purge'}`}</div> : null}
                            {matchRows}
                        </div>
                    </div>
                    {filterTypes}
                    <ConfirmationInput
                        caseSensitive={false}
                        onSuccess={this.onSuccess}
                        onError={this.onError}
                        enterPressed={this.confirmEnterPressed}
                    />
                </div>

                <div
                    className={Classes.DIALOG_FOOTER}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Button intent={Intent.DANGER} onClick={onClose} value="Cancel" />
                    <Button
                        value="Confirm"
                        disabled={!this.state.canConfirm}
                        onClick={this.maskOrPurgeRecords}
                    />
                </div>
            </Dialog>
        );
    }
}
