/**
 * @format
 */
import * as React from 'react';
import { Button as BPButton, Classes, FormGroup, IDialogProps, InputGroup, Dialog, Intent, Tooltip } from '@blueprintjs/core';

import { Button } from '../Shared';
import { IconNames } from '@blueprintjs/icons';

export interface UserPasswordModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (newPassword: string) => void;
}

export interface UserPasswordModalState {
    confirmPassword: string;
    password: string;
    passwordVisible: boolean;
}

export class UserPasswordModal extends React.Component<
    UserPasswordModalProps & IDialogProps,
    UserPasswordModalState
> {
    constructor(props) {
        super(props);
        this.state = {
            confirmPassword: '',
            password: '',
            passwordVisible: false,
        };
    }

    private onInput = e => {
        const newState = { ...this.state, [e.target.name]: e.target.value };
        this.setState(newState);
    }
    private onShowPassword = () => {
        const newState = { ...this.state, passwordVisible: !this.state.passwordVisible };
        this.setState(newState);
    }
    private onClose = () => {
        this.setState({
            password: '',
            confirmPassword: '',
            passwordVisible: false,
        });
    }
    public render() {
        const { isOpen, onClose, onSave } = this.props;
        const isItSaveable = this.state.password === this.state.confirmPassword;
        const passwordDisabled = false;
        // @type {{search: React.CSSProperties}}
        const lockButton =
            <Tooltip content={`${isItSaveable ? "Hide" : "Show"} Password`} disabled={passwordDisabled}>
                <BPButton
                    disabled={passwordDisabled}
                    icon={this.state.passwordVisible ? IconNames.EYE_OFF : IconNames.EYE_OPEN}
                    minimal={true}
                    onClick={this.onShowPassword}
                />
            </Tooltip>;

        return (
            <Dialog
                isOpen={isOpen}
                onClose={e => {
                    this.onClose();
                    onClose();
                }}
                title="Set Password"
            >
                <div className={Classes.DIALOG_BODY}>
                     <FormGroup
                        label="New Password"
                        labelFor="password-input"
                        inline={true}
                        className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                        contentClassName="user-dialog-input"
                    >
                        <InputGroup
                            id="password-input"
                            onChange={this.onInput}
                            name="password"
                            rightElement={lockButton}
                            type={this.state.passwordVisible ? 'text' : 'password'}
                            value={this.state.password}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Confirm Password"
                        labelFor="confirm-input"
                        helperText={isItSaveable ? "" : "Passwords do not match"}
                        inline={true}
                        intent={isItSaveable ? undefined : Intent.DANGER}
                        className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                        contentClassName="user-dialog-input"
                    >
                        <InputGroup
                            id="confirm-input"
                            intent={isItSaveable ? undefined: Intent.DANGER}
                            onChange={this.onInput}
                            name="confirmPassword"
                            type={this.state.passwordVisible ? 'text' : 'password'}
                            value={this.state.confirmPassword}
                        />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            id="user-password-save-btn"
                            icon={IconNames.FLOPPY_DISK}
                            disabled={!isItSaveable}
                            onClick={() => onSave(this.state.password)}
                            value="Set"
                        />
                        <Button icon={IconNames.CROSS} onClick={onClose} intent={'danger'} value="Cancel" />
                    </div>
                </div>
            </Dialog>
        );
    }
}
