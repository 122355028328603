/**
 * @format
 */
import * as React from 'react';

import { Alert, Button, Classes, Dialog, IDialogProps, Intent, Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as moment from 'moment';

//import { History } from 'types';
import { groupBy } from 'utils';
import RecordHistoryEntriesDay from './RecordHistoryEntriesDay';

import './RecordDialog.scss';
import "../../components/Shared/ReactTable/react-table-6.scss";
import "../../components/Shared/ReactTable/react-table-overrides.scss";
import "../../components/Shared/ReactTable/react-table-7-paginator.scss";

export interface RecordHistoryDialogProps {
    title: string;
    history: any[];
    isOpen: boolean;
    onClose: (e: any) => void;
    recordKey: any;
    currentTable?: any;
}

export interface RecordHistoryDialogState {
    showMaskAlert: boolean;
    showPurgeAlert: boolean;
    showConfirmReminder: boolean;
}

const formatDateLong = (date: string): string => moment(new Date(date)).format('MMMM Do, YYYY');

export const RecordHistoryDialog: React.FC<RecordHistoryDialogProps> = ({ isOpen, onClose, history, title, currentTable }) => {
    const consolidateHistory = history => {
        const historyWithDates = history
            .sort((a, b) => {
                const aTime = moment(new Date(a.timestamp));
                const bTime = moment(new Date(b.timestamp));
                if (aTime.diff(bTime) < 0) return 1;
                if (aTime.diff(bTime) > 0) return -1;
                return 0;
            })
            .map((historyEntry) => {
                const date = formatDateLong(historyEntry.timestamp);
                return {
                    date,
                    historyEntry,
                };
            });

        const consolidatedHistoryEntries = groupBy(historyWithDates, 'date');

        return Object.keys(consolidatedHistoryEntries).map((key, index) => {
            return (
                <RecordHistoryEntriesDay
                    key={index}
                    date={key}
                    historyEntries={consolidatedHistoryEntries[key]}
                    primaryKey={currentTable.type === 'versioned' ? currentTable.primary_key : null}
                    currentTable={currentTable}
                />
            );
        });
    }

    return (
        <Dialog
            icon={IconNames.EYE_OPEN}
            className="record-history-dialog"
            isOpen={isOpen}
            onClose={onClose}
            title={title}
        >
            <div className={`${Classes.DIALOG_BODY} record-history-dialog-inner`}>
                {history.length ? consolidateHistory(history) : (
                    <span className="record-history-none">
                        There are no entries to display.
                    </span>
                )}
            </div>
        </Dialog>
    )
}

// export default RecordHistoryDialog;