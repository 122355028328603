import * as React from 'react';
import { Dialog, Button, Classes, Intent, Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { Process, WorkflowDef, ProcessDef, Table } from '../../../types';
import { WorkflowApi } from '../../../api';
import { WorkflowDetailForm } from './WorkflowDetailForm';
import { ClaimGroupEdit } from './ClaimGroupEdit'
import { IconNames } from '@blueprintjs/icons';

export interface WorkflowEditModalProps {
    isOpen: boolean;
    workflow: WorkflowDef;
    process: Process;
    processes: Process[];
    groups: string[];
    dbName: string;
    closeModal: () => void;
    onSave: (newWorkflow: WorkflowDef) => void;
    onInputChange: (key: string, value: any) => void;
    tables: Table[];
}

export interface WorkflowEditModalState {
    processDetails: ProcessDef;
}

export class WorkflowEditModal extends React.Component<WorkflowEditModalProps, WorkflowEditModalState> {

    constructor(props) {
        super(props);
        this.state = {
            processDetails: null,
        }
    }

    componentDidMount() {
        const { process, dbName } = this.props;
        WorkflowApi.processRead(process.process_id, dbName).then(processDetails => {
            this.setState({ processDetails });
        });
    }

    componentDidUpdate(prevProps) {
        const { process, dbName, workflow } = this.props;

        if (prevProps.workflow && workflow && prevProps.workflow.process_id !== workflow.process_id) {
            this.setState({ processDetails: null }, () => {
                WorkflowApi.processRead(process.process_id, dbName).then(processDetails => {
                    this.setState({ processDetails });
                });
            })
        }
    }

    generateSaveButton = () => {
        const { workflow, onSave } = this.props;
        const { processDetails } = this.state;

        const hasDatabase = !!workflow.database
        const hasStartGroups = !!(workflow.variables && workflow.variables.start_groups)
        const hasTable = !!(workflow.variables && workflow.variables.table)
        //const hasCompletion = workflow.completion_hours !== null && workflow.completion_hours >= 0;
        // completion hours and having a table are optional
        const submittable = hasDatabase && hasStartGroups /* && hasTable && hasCompletion */;

        if (submittable)
            return (
                <Button
                    id='wf-modal-save'
                    icon={IconNames.FLOPPY_DISK}
                    className="user-save-button"
                    onClick={() => onSave(workflow)}
                    disabled={!processDetails}
                    value="Save"
                    text="Save"
                />
            )

        let popoverContent = (
            <div>
                {!hasDatabase && <span className="display-block">*Please provide a Database.</span>}
                {!hasStartGroups && <span className="display-block">*Please provide a Start Group.</span>}
                {/* {!hasTable && <span className="display-block">*Warning - no Table was provided.</span>} */}
            </div>
        );

        return (
            <Popover
                className="wf-submit-popover"
                content={popoverContent}
                interactionKind={PopoverInteractionKind.CLICK}
                popoverClassName="bp3-popover-content-sizing"
            >
                <Button
                    id='wf-modal-save'
                    icon={IconNames.FLOPPY_DISK}
                    className="user-save-button"
                    onClick={() => { }}
                    value="Save"
                    text="Save"
                />
            </Popover>
        )
    }

    render() {
        const { closeModal, isOpen, workflow, process, processes, groups, tables, onInputChange } = this.props;
        const { processDetails } = this.state;
        const { workflow_id } = workflow;
        const title = workflow_id ? `Editing workflow #${workflow_id}` : 'New Workflow'

        return (
            <Dialog
                title={title}
                icon={IconNames.BUILD}
                className="wf-modal"
                onClose={closeModal}
                isOpen={isOpen}
                canOutsideClickClose={false}
                isCloseButtonShown={false}
            >
                <div className={Classes.DIALOG_BODY}>
                    <WorkflowDetailForm tables={tables} groups={groups} workflow={workflow} process={process} processes={processes} editable={true} onInputChange={onInputChange} />
                    <ClaimGroupEdit workflow={workflow} groups={groups} processDetails={processDetails} onInputChange={onInputChange} />
                </div>

                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        {this.generateSaveButton()}
                        <Button
                            id='wf-modal-close'
                            icon={IconNames.CROSS}
                            onClick={closeModal}
                            intent={Intent.DANGER}
                            value="Cancel"
                            text="Cancel"
                        />
                    </div>
                </div>
            </Dialog>
        )
    }

}
