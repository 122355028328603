import * as React from 'react';
import { Classes, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Draggable } from "react-beautiful-dnd";

import { Button } from 'components/Shared';
import { Field } from 'types';
import { getHeaders, RecordsTableRecordField, getRow } from './RecordsTableRecordField';
import { moveArrayItem } from 'utils';
import { UpdateCb } from 'components/EditRecordForm/EditRecordForm';

require('./RecordsTable.scss');

export interface RecordsTableEditRecordFieldProps {
    index: number;
    field: Field;
    records: object[];
    modifiedRecords: object[];
    updateFieldCb: UpdateCb;
}
export interface RecordsTableRecordFieldState {
    editRowIndex: number;
    editRow?: object;
}

export class RecordsTableEditRecordFieldBase extends React.PureComponent<RecordsTableEditRecordFieldProps, RecordsTableRecordFieldState> {

    constructor(props: RecordsTableEditRecordFieldProps) {
        super(props);
        this.state = { editRowIndex: -1 };
    }

    private getEmptyRecord(): object {
        return this.props.field.schema.fields.reduce((acc, cur) => ({ ...acc, [cur.field]: undefined }), {});
    }

    componentDidUpdate(prevProps: RecordsTableEditRecordFieldProps) {
        // If a row was added, open it in edit mode-- record is undefined for cancel operation
        if (this.props.modifiedRecords.length > prevProps.modifiedRecords.length)
            this.editRow(undefined, this.props.modifiedRecords.length - 1);
    }

    // Update the table field
    private updateField = (value: object[]) =>
        this.props.updateFieldCb(this.props.field.field, value)

    // Update a subfield within the table
    private updateSubfield = (name: string, value: string, index: number) =>
        this.updateField([...this.props.modifiedRecords.slice(0, index),
        { ...this.props.modifiedRecords[index], [name]: value },
        ...this.props.modifiedRecords.slice(index + 1)
        ])

    private addRow = () => {
        this.updateField(this.props.modifiedRecords.concat(this.getEmptyRecord()));
        this.setState({ editRowIndex: this.props.modifiedRecords.length - 1, editRow: undefined },);
    }

    private deleteRow = (index: number) =>
        this.updateField(this.props.modifiedRecords.filter((_f, i) => i !== index))

    private editRow = (record: object, index: number) =>
        this.setState({ editRowIndex: index === this.state.editRowIndex ? -1 : index, editRow: record && { ...record } })

    private moveRow = (fromIndex: number, toIndex: number) => {
        if(fromIndex >= 0 && toIndex < this.props.modifiedRecords.length){
            const movedItem = moveArrayItem(this.props.modifiedRecords, fromIndex, toIndex);
            this.setState({ editRow: undefined, editRowIndex: -1 }, () => this.updateField(movedItem));
        }
    }

    // This is a little tricky-- the array spread operator returns nothing for an empty array, so the array is initialized
    // with this.state.editRow if it's defined, otherwise, the spread returns nothing.
    private revertRowEdit = () => {
        this.updateField([...this.props.modifiedRecords.slice(0, this.state.editRowIndex),
        ...(this.state.editRow ? [this.state.editRow] : []), ...this.props.modifiedRecords.slice(this.state.editRowIndex + 1)]);
        this.setState({ editRow: undefined, editRowIndex: -1 });
    }

    private getTableHeaders(field: Field): JSX.Element[] {
        return ([<div className="button-group-base td" key='buttons' />].concat((getHeaders(field.schema))));
    }

    private getEditRow(record: object, index: number): JSX.Element[] {
        return Object.keys(record).map((k, i) =>
            <div className="td" key={`edit-row-td-${i}]`}><input className={Classes.INPUT} autoFocus={i === 0 ? true : false} value={record[k] || ''} placeholder={k}
                onChange={e => this.updateSubfield(k, e.target.value, index)}></input></div>);
    }

    // Return either a row of inputs or a draggable display row prefixed with their appropriate buttons
    private getTableRow = (record: object, index: number): JSX.Element => {
        const isEdit = this.state.editRowIndex === index;
        const row: JSX.Element = (
            <>
                <div key={index} className="button-group-items" >
                    <Tooltip content={`${isEdit ? 'Ok' : 'Edit'}`} >
                        <Button className="records-table-button" icon={isEdit ? IconNames.TICK : IconNames.EDIT}
                            minimal={true} onClick={() => this.editRow(record, index)} />
                    </Tooltip>
                    <Tooltip content={`${isEdit ? 'Cancel' : 'Delete'}`}>
                        <Button className="records-table-button" icon={isEdit ? IconNames.CROSS : IconNames.TRASH}
                            minimal={true} onClick={() => isEdit ? this.revertRowEdit() : this.deleteRow(index)} />
                    </Tooltip>
                </div>
                {isEdit ? this.getEditRow(record, index) : getRow(record, index)}
            </>
        );

        return (
            <Draggable
                key={`drag-${index}`}
                index={index}
                draggableId={`drag-id-${index}`}
                isDragDisabled={isEdit}
                children={(provided) => (
                    <div
                        className="tr fill-width"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {row}
                    </div>
                )}
            />
        )
    }

    render() {
        return (
            <div style={{ width: "100%" }}><div className="scroll-container">
                <RecordsTableRecordField
                    onDrag={this.moveRow}
                    index={this.props.index}
                    columns={this.getTableHeaders(this.props.field)}
                    getRow={this.getTableRow}
                    records={this.props.modifiedRecords}
                />
            </div>
                <div className="add-record-row" key="addrow" role="button" onClick={this.addRow}>
                    <div className="add-record-icon bp5-icon-plus" />
                    <div>Record</div>
                </div>
            </div>
        )
    }
}
export const RecordsTableEditRecordField = RecordsTableEditRecordFieldBase;