import * as React from 'react';
import { Alert, Icon, Intent as BlueprintIntent, Menu, MenuItem, Popover, Position, OverlayToaster, ToastProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from '../Shared';
import { GroupDialog } from './GroupDialog';
import { displayMessageBox, getRoles, getGroups, showToaster } from '../../actions/ActionCreators';
import { MDMWrapper } from 'components/Shared';
import { MDMStore as store } from '../../store/MDMStore';
import { mdmErrorToText } from 'helpers';
import { GroupApi } from '../../api/GroupApi';
import { inSystemGroup } from 'api';
import { Group as GroupType, Groups, Roles } from '../../types';
import { connect } from 'react-redux';
import { Intent } from 'types';

export interface GroupProps {
    showToaster?: showToaster;
    getRoles?: () => Roles;
    getGroups?: () => any;
    groups?: Groups;
    roles?: Roles;
    everyoneGroupName?: string;
    systemGroupName?: string;
}

export interface GroupState {
    currentGroup: GroupType;
    groupToDelete: GroupType;
    isDialogOpen: boolean;
    isEdit: boolean;
    isItSaveable: boolean;
    isUniqueGroupName: boolean;
    groupNameHelp: string;
}

// No idea if this is the right way or what.  Took this from a Blueprint 5.x example
const toaster = OverlayToaster.createAsync({
    position: Position.TOP,
});

export class GroupBase extends React.Component<GroupProps, GroupState> {
    constructor(props) {
        super(props);
        this.state = {
            groupToDelete: null,
            currentGroup: {
                group: '',
                description: '',
                roles: [],
                rolesShadow: '',
            },
            isDialogOpen: false,
            isEdit: false,
            isItSaveable: false,
            isUniqueGroupName: true,
            groupNameHelp: '',
        };
    }

    // Blueprint 3.x - Keep the old way for referencing existing behavior
    // private toaster: Toaster;
    // private refHandlers = {
    //     toaster: (ref: Toaster) => (this.toaster = ref),
    // };

    public componentDidMount() {
        this.props.getRoles();
        this.props.getGroups();
    }

    private showToast = async (props: ToastProps) => {
        (await toaster).show(props);
    }

    private deleteGroup = group => {
        // keep the deleted group in state so we can restore it
        this.setState({ currentGroup: group, groupToDelete: null }, () => {
            GroupApi.deleteGroup(group.group)
                .then(() => {
                    this.props.getGroups();
                    this.showToast({
                        intent: BlueprintIntent.PRIMARY,
                        message: (
                            <span>
                                Group Deleted.{' '}
                                <a role="link" onClick={this.undoDeleteGroup} style={{ textDecoration: 'underline' }}>
                                    Undo
                                </a>
                            </span>
                        ),
                    });
                })
                .catch(err => {
                    store.dispatch<any>(
                        displayMessageBox({
                            title: 'Server Call Failed',
                            message: "Couldn't delete group: " + mdmErrorToText(err),
                        })
                    );
                })
        })

    }

    private undoDeleteGroup = () => {
        this.saveGroup(true);
    }

    private saveGroup = (isUndeleting: boolean = false) => {
        const { showToaster } = this.props;
        GroupApi.upsertGroup(this.state.currentGroup.group, this.state.currentGroup.description, this.state.currentGroup.roles)
            .then(() => {
                if (!isUndeleting) {
                    showToaster(Intent.SUCCESS, 'Group restored.');
                } else {
                    showToaster(Intent.SUCCESS, `Group ${this.state.isEdit ? 'modified' : 'created'}.`);
                }

                this.setState({ isDialogOpen: false });
                this.props.getGroups();
            })
            .catch(err => {
                store.dispatch<any>(
                    displayMessageBox({
                        title: 'Server Call Failed',
                        message: mdmErrorToText(err),
                    })
                );

                this.setState({ isDialogOpen: false });
                this.props.getGroups();
            })

        return true;
    }

    private getRoleDisplay(roleID) {
        const role = this.props.roles.find(r => r.id === roleID);
        return role ? role.display : roleID;
    }

    private renderGroups() {
        const {systemGroupName, everyoneGroupName} = this.props;

        return this.props.groups.map((item, index) => (
            <div key={index} className="management-item groups-item">
                <div className="management-name group-name">{item.group}</div>
                <div title={item.description} className="management-description group-description">
                    {item.description}
                </div>
                <div className="management-roles group-roles">
                    {item.roles && item.roles.length ? (
                        <div className="management-role-text group-role-text">roles</div>
                    ) : null}
                    {item.roles
                        ? item.roles.map((role, index) => (
                            <div key={index} className="management-role group-role">
                                {this.getRoleDisplay(role)}
                            </div>
                        ))
                        : null}
                </div>

                {inSystemGroup() && item.group !== everyoneGroupName && item.group !== systemGroupName ? (
                    <div className="management-actions group-actions">
                        <Popover position={Position.BOTTOM_RIGHT}
                            content={
                                <Menu>
                                    <MenuItem
                                        icon={IconNames.EDIT}
                                        onClick={e =>
                                            this.setState({
                                                isDialogOpen: true,
                                                isEdit: true,
                                                currentGroup: item,
                                            })
                                        }
                                        text="Edit"
                                    />
                                    <MenuItem
                                        icon={IconNames.TRASH}
                                        // onClick={() => this.deleteGroup(item)}
                                        onClick={() => this.setState({ groupToDelete: item })}
                                        text="Delete"
                                    />
                                </Menu>
                            }
                        >
                            <Icon icon={IconNames.MORE} />
                        </Popover>
                    </div>
                ) : null}
            </div>
        ));
    }

    private determineIfSaveable() {
        const hasGroupName = this.state.currentGroup.group !== '';
        const isUniqueGroupName =
            this.state.isEdit ||
            this.props.groups.find(g => g.group === this.state.currentGroup.group) == null;
        return hasGroupName && isUniqueGroupName && !this.state.groupNameHelp;
    }

    private determineIfUniqueGroupName = () => {
        const isUniqueGroupName =
            this.state.isEdit ||
            this.props.groups.find(g => g.group === this.state.currentGroup.group) == null;
        this.setState({ isUniqueGroupName: isUniqueGroupName });
    }

    private toggleDialog = () =>
        this.setState({
            currentGroup: {
                group: '',
                description: '',
                roles: [],
                rolesShadow: '',
            },
            isDialogOpen: !this.state.isDialogOpen,
            isEdit: false,
        })

    public render() {
        const wrapperButtons = [];
        const { groupToDelete } = this.state;

        if (inSystemGroup())
            wrapperButtons.push(
                <Button
                    className="minimal-button"
                    key={0}
                    value="Create Group"
                    onClick={this.toggleDialog}
                />
            )

        return (
            <MDMWrapper title="Group Management" buttons={wrapperButtons} documentationPath="group_roles.html">
                <Alert
                    icon={IconNames.TRASH}
                    intent={Intent.DANGER}
                    className="alert-confirm-delete"
                    isOpen={!!groupToDelete}
                    confirmButtonText="Delete Group"
                    cancelButtonText="Cancel"
                    onConfirm={() => this.deleteGroup(groupToDelete)}
                    onCancel={() => this.setState({ groupToDelete: null })}
                >
                    <p className="alert-text">
                        Are you sure you want to delete group <br />
                        {`"${groupToDelete && groupToDelete.group}"?`}
                    </p>
                </Alert>

                <div className="management-list groups-list">
                    {this.props.groups ? this.renderGroups() : null}
                </div>
                <GroupDialog
                    isEdit={this.state.isEdit}
                    isOpen={this.state.isDialogOpen}
                    onClose={this.toggleDialog}
                    onCancel={this.toggleDialog}
                    onSave={this.saveGroup}
                    checkboxes={this.props.roles}
                    group={this.state.currentGroup}
                    isItSaveable={this.determineIfSaveable()}
                    onInput={(key, value) => {
                        if (key === 'group') {
                            this.setState(
                                {
                                    currentGroup: { ...this.state.currentGroup, [key]: value },
                                    groupNameHelp: (value.length > 0 && !value.match(/^[a-zA-Z_][a-zA-Z_0-9]*$/)) && 'letters, underscore and numbers (2nd char+) only',
                                },
                                this.determineIfUniqueGroupName,

                            )
                        }
                        else {
                            this.setState(
                                {
                                    currentGroup: { ...this.state.currentGroup, [key]: value },
                                },
                                this.determineIfUniqueGroupName,
                            )
                        }
                    }
                    }
                    isUniqueGroupName={this.state.isUniqueGroupName}
                    groupNameHelp={this.state.groupNameHelp}
                    onChange={(event: any, key, value) => {
                        let roles;
                        const role =
                            event.target.checked && !this.state.currentGroup.roles.includes(value)
                                ? value
                                : null;
                        if (!event.target.checked)
                            roles = this.state.currentGroup.roles.filter(v => v !== value);
                        this.setState({
                            currentGroup: {
                                ...this.state.currentGroup,
                                roles: role ? [...this.state.currentGroup.roles, role] : roles,
                            },
                        });
                    }}
                />
            </MDMWrapper>
        );
    }
}

const mapStateToProps = state => ({
    roles: state.roles,
    groups: state.groups,
    systemGroupName: state.auth.config?.systemGroupName,
    everyoneGroupName: state.auth.config?.everyoneGroupName
});
const mapDispatchToProps = { displayMessageBox, getRoles, getGroups, showToaster };

export const Group: any = connect(mapStateToProps, mapDispatchToProps)(GroupBase as any)