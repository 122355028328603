import React, { FC } from 'react';

export interface ResizerProps {
    header: {
        getResizeHandler: () => any;
        column: {
            getIsResizing: () => boolean;
        };
    };

    state: {
        columnSizingInfo: {
            deltaOffset: number;
        };
    };

}

const Resizer: FC<ResizerProps> = ({ state, header }) => {
    return (
        <div
            {...{
                onMouseDown: header.getResizeHandler(),
                onTouchStart: header.getResizeHandler(),
                className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
            }}
        />
    );
};

export default Resizer;