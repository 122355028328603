import * as React from 'react';
import { Button, H5, Intent, Switch, Tag } from "@blueprintjs/core";
import './Badge.scss';

export interface BadgeProps {
    displayValue?: number;
}

export class Badge extends React.Component<BadgeProps> {
    public render() {
        const { displayValue } = this.props;

        if(!displayValue) 
            return null;

        return (
            <Tag className="mdm-badge" round>
                <div className="min-width-container">
                    <span className="badge-value" title={`${displayValue} records`}>
                        {displayValue}
                    </span>
                </div>
            </Tag>
        )
    }
}
