import * as React from 'react';
import { Checkbox } from '@blueprintjs/core';

require('./Checklist.scss');

export interface ChecklistProps {
    checkboxes: { [prop:string]: any }[];
    checkboxAccessor?: string;
    checkboxDisplayAccessor?: string;
    checkFunction: (item: ChecklistProps['checkboxes']) => boolean;
    onChange?: (e?:React.SyntheticEvent<HTMLInputElement>, key?:number, value?:string) => void;
    renderCheckboxes?: (props:ChecklistProps) => React.ReactNode[];
}

export const Checklist = (props:ChecklistProps) => {
    const checkboxAccessor = props.checkboxAccessor == null ? 'name' : props.checkboxAccessor;
    const checkboxDisplayAccessor = props.checkboxDisplayAccessor == null ? checkboxAccessor : props.checkboxDisplayAccessor;
    const renderCheckboxes = (props) => {
        return props.checkboxes.map((item, index) => <li key={index} className='checklist-item'>
            <Checkbox
                checked={props.checkFunction.call(this, item)}
                onChange={ e => props.onChange.call(this, e, index, (e.target as any).name)}
                name={item[checkboxAccessor]}
            >
                {item[checkboxDisplayAccessor]}
            </Checkbox>
        </li>);
    };

    return (
        <ul className='checklist'>
            {
                props.renderCheckboxes == null ?
                renderCheckboxes(props) :
                props.renderCheckboxes(props)
            }
        </ul>
    );
};