import { RECEIVE_RECORDS_HISTORY } from '../constants';

export const recordHistory = (state = [], action) => {
    switch(action.type) {
        case RECEIVE_RECORDS_HISTORY:
            return action.payload;
        default:
            return state;
    }
};
