import { useDispatch, useSelector } from 'react-redux';
import React, { FC, MouseEvent, useEffect, useState } from 'react';

import { claimTask, discardAssignedTaskAction } from 'store/workflow/actions';
import { OptionProps } from '@blueprintjs/core';
import { MDMWrapper } from '../../../components/Shared';
import { WorkflowSearchTable } from './WorkflowSearchTable';
import { useCompleteTask, WorkflowApi } from '../../../api'
import { WorkitemQueryBuilder } from './WorkItemQueryBuilder'
import { WorkitemEditModal } from '../WorkitemEditModal/WorkitemEditModal';
import { Process, Workflow, WorkflowAction, WorkflowDef, Workitem, WorkitemQueryFilter } from '../../../types';
import { ConfirmDialog } from '../../Shared/ConfirmDialog';
import WorkitemHistoryModal from './WorkitemHistoryModal'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { displayMessageBox } from 'actions';
import { mdmErrorToText } from 'helpers';

export interface WorkflowSearchProps {
    canEditWorkitems: boolean;
    available: Workitem[];
    dbName: string;
    username: string;
    started: object[];
    discardAssignedTask: (workitem: Workflow, button:HTMLButtonElement, notes?: string) => void;
    claimTask: (database: string, workItemId: string, taskID: string, button:HTMLButtonElement) => void;
}

export interface WorkflowSearchState {
    workflowDropdownoptions: OptionProps[];
    workitems: Workitem[];
    loading: boolean;
    queriedOnce: boolean;
    queryOnFinished: boolean;
    processes: Process[];
    currentEditWorkitem: Workitem;
    currentDeleteWorkitem: Workitem;
    currentHistoryWorkitem: Workitem;
    filter: WorkitemQueryFilter;
}


export const WorkflowSearch: FC<WorkflowSearchProps> = (props) => {
    const qc = useQueryClient();
    const {
        canEditWorkitems,
        dbName,
        username,
        available,
    } = useSelector((state: any) => ({
        canEditWorkitems: state.auth.canEditWorkitems,
        dbName: state.database.selected,
        username: state.username,
        available: state.workflow.available,
    }));
    const dispatch = useDispatch();
    const [currentEditWorkitem, setCurrentEditWorkitem] = useState<Workitem | null>(null);
    const [currentDeleteWorkitem, setCurrentDeleteWorkitem] = useState<Workitem | null>(null);
    const [currentHistoryWorkitem, setCurrentHistoryWorkitem] = useState<Workitem | null>(null);
    const [filter, setFilter] = useState<WorkitemQueryFilter>({ finished: false });

    const { data: workitems, refetch: refetchItems, isLoading } = useQuery<Workitem[]>({
        queryKey: ['workitems', dbName, filter],
        queryFn: async () => {
            const data = await WorkflowApi.queryTasks(dbName, filter, { variables: true, history: true })

            return data;
        },
        initialData: [],
    })

    const { data: processes, refetch: refetchProcesses } = useQuery({
        queryKey: ['processes', dbName],
        queryFn: async () => {
            return await WorkflowApi.queryProcesses(dbName)
        },
        initialData: [],
    })

    const { data: workflows, refetch: refetchWorkflows } = useQuery({
        queryKey: ['workflows', dbName],
        queryFn: async () => {
            const data = await WorkflowApi.queryWorkflows(dbName)

            const workflowOptions = data.map((workflow: WorkflowDef) => {
                const label = `${workflow.workflow_id} : ${workflow.description}`;
                const value = workflow.description;
                return { label, value }
            })

            return workflowOptions;
        },
        initialData: [],
    })

    const { mutateAsync: discardTask, isLoading: discarding } = useCompleteTask();

    const refreshData = () => {
        refetchWorkflows();
        refetchProcesses();
    }

    const onDiscardTask = async (workitem: Workflow) => {
        discardTask({
            workflow: workitem,
            action: WorkflowAction.discard
        }, {
            onSuccess: (d, { workflow } ) => {
                toast.success(`Discarded workitem ${workflow.workitem_id}`);
                dispatch(discardAssignedTaskAction({
                    databaseName: dbName,
                    workitemId: workflow.workitem_id,
                    nodeId: workflow.node_id
                }))
                qc.invalidateQueries(['workitems']);
            },
            onError: (error) => {
                dispatch(
                    displayMessageBox({
                        title: 'Discard Task Failed',
                        message: `Failed to discard Workitem ${workitem.workitem_id}: ${mdmErrorToText(error)}`,
                        stack: (error as Error)?.stack,
                    }),
                );
            }
        });
    }

    const matchProcessDescription = (processId: number): string => {
        const process = (processes ?? []).find(process => process.process_id === processId);
        return process ? process.description : '';
    }

    const myClaimTask = (database:string, workitemId:number, nodeId:number, button?:HTMLButtonElement) => {
         // JWG no idea why calling the actions.ts function directly is never dispatched. It used to work.
         // Original version had <WorkflowSearchTable ... claimTask={claimTask} ...   and a knowledgeable person should fix this
         dispatch(claimTask(database, workitemId, nodeId, button));
    }

    const generateTable = () => {
        if ((!workitems?.length) || !processes?.length)
            return null;

        const formattedWorkitems = workitems
            .map(workitem => {
                return ({
                    ...workitem,
                    process_label: `${workitem.process_id}: ${matchProcessDescription(workitem.process_id)}`,
                    workflow_label: `${workitem.workflow_id}: ${workitem.workflow_description || 'unnamed'}`,
                })
            })

        return (
            <WorkflowSearchTable
                loggedUsername={username}
                deleteTask={deleteWorkitem}
                editTask={editWorkitem}
                viewWhatWasDone={viewWhatWasDone}
                canEditWorkitems={canEditWorkitems}
                claimTask={myClaimTask}
                workitems={formattedWorkitems}
                loading={isLoading}
                finished={filter.finished}
                availableWorkitems={available}
            />
        );
    }


    const editWorkitem = (workitem: Workitem) => setCurrentEditWorkitem(workitem);
    const deleteWorkitem = (workitem: Workitem) => setCurrentDeleteWorkitem(workitem);
    const viewWhatWasDone = (workitem: Workitem) => setCurrentHistoryWorkitem(workitem);

    const onConfirmDelete = (e: MouseEvent<HTMLElement>) => {
        setCurrentDeleteWorkitem(null);
        const workitem: Workflow = {
            changesetId: null,
            continue: false,
            databaseName: dbName,
            formId: null,
            node_id: currentDeleteWorkitem.node_id,
            schema: null,
            tableName: null,
            workitem_id: currentDeleteWorkitem.workitem_id,
            variables: {}
        }
        onDiscardTask(workitem);
    }

    useEffect(() => {
        if (dbName) {
            refreshData();
        }
    }, [dbName]);

    return (
        <MDMWrapper title="Workitem Search" documentationPath="search-for-workitem.html">
            <WorkitemHistoryModal
                workitem={currentHistoryWorkitem}
                onClose={() => setCurrentHistoryWorkitem(null)}
            />
            <WorkitemEditModal
                isOpen={!!currentEditWorkitem}
                workitem={currentEditWorkitem}
                onClose={() => setCurrentEditWorkitem(null)}
                saveCallback={refetchItems}
            />
            <ConfirmDialog
                isOpen={!!currentDeleteWorkitem}
                body={`Are you sure you want to discard workitem #${currentDeleteWorkitem?.workitem_id}?`}
                title='Discard Task'
                cancelClick={() => setCurrentDeleteWorkitem(null)}
                okClick={onConfirmDelete}
                isLoading={discarding}
            />
            <WorkitemQueryBuilder
                workflowDropdownoptions={workflows}
                queryWorkitems={setFilter}
            />
            {generateTable()}
        </MDMWrapper>
    );
}