/**
 * @format
 */
import * as React from 'react';
import { Classes, Dialog, FormGroup, InputGroup, DialogProps, Intent } from '@blueprintjs/core';
import { Group as GroupType } from 'types';

import { Button, Checklist } from 'components/Shared';
import { IconNames } from '@blueprintjs/icons';

export interface GroupDialogProps {
    checkboxes?: any[];
    isEdit: boolean;
    isOpen: boolean;
    isItSaveable?: boolean;
    group: GroupType;
    onInput: (target: string, value: any) => void;
    onChange?: (e?: React.SyntheticEvent<HTMLInputElement>, key?: number, value?: string) => void;
    onClose?: (e?: React.SyntheticEvent<any>) => void;
    onCancel?: (e?: React.SyntheticEvent<HTMLButtonElement | MouseEvent>) => void;
    onSave?: (...a: any[]) => void;
    isUniqueGroupName?: boolean;
    groupNameHelp?: string;
}

export const GroupDialog: React.FC<DialogProps & GroupDialogProps> = (
    props: DialogProps & GroupDialogProps,
) => {
    let helperText = (!props.isUniqueGroupName && "Group name must be unique") || props.groupNameHelp;

    return (
        <Dialog
            icon={IconNames.GROUP_OBJECTS}
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={`${props.isEdit ? 'Edit' : 'Create'} Group`}
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    inline={true}
                    helperText={helperText}
                    intent={props.isUniqueGroupName ? undefined : Intent.DANGER}
                    label="Group Name"
                    labelFor="group-input"
                    labelInfo="(required)"
                    className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                    contentClassName="group-dialog-input"
                >
                    <InputGroup
                        onChange={(e: any) =>
                            props.onInput.call(this, e.target.name, e.target.value)
                        }
                        disabled={props.isEdit}
                        id="group-input"
                        name="group"
                        value={props.group.group}
                        type="text"
                    />
                </FormGroup>
                <FormGroup
                    inline={true}
                    label="Group Description"
                    labelFor="description-input"
                    className={`${Classes.TEXT_MUTED} mdm-dialog-form-group`}
                    contentClassName="group-dialog-input"
                >
                    <InputGroup
                        id="description-input"
                        onChange={(e: any) =>
                            props.onInput.call(this, e.target.name, e.target.value)
                        }
                        name="description"
                        value={props.group.description}
                        type="text"
                        maxLength={140}
                    />
                </FormGroup>
                <div className="group-roles-wrapper">
                    <FormGroup className={Classes.TEXT_MUTED}>Group Roles</FormGroup>
                    <Checklist
                        onChange={props.onChange}
                        checkboxes={props.checkboxes}
                        checkboxAccessor="id"
                        checkboxDisplayAccessor="display"
                        checkFunction={checkbox => props.group.roles.includes(checkbox['id'])}
                    />
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        icon={IconNames.FLOPPY_DISK}
                        disabled={!props.isItSaveable}
                        onClick={props.onSave}
                        value="Save"
                    />
                    <Button icon={IconNames.CROSS} onClick={props.onCancel} intent="danger" value="Cancel" />
                </div>
            </div>
        </Dialog>
    );
};
