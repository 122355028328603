import { RecordFields } from 'types';

export enum GroupType {
    ACCEPTED,
    PROPOSED,
    CUSTOM
}
type MatchRecordBase<T> = RecordFields<T> & {
    _group: number | number[];
    _id: string;
};
export type MatchRecord = MatchRecordBase<string>;
export const getMatchGroupColorClass = (group: number) => `group${((group + 19) % 20) + 1}`;
export const MATCH_RECORD_ROW ='match_record_row';