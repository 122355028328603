import { Action } from './Action';
import { USER_LOGGING_IN, USER_LOGGED_IN, USER_LOGGED_OUT, STORE_REHYDRATED, CLEAR_PERIODIC_DATA, RESET_CHANGESET } from '../constants';
import { CLEAR_PERMISSIONS } from '../store/permissions/types';
import { CLEAR_USER_DATA } from '../store/user/types';
import { CLEAR_LICENSE_INFO } from '../store/license/types';
import { CLEAR_WORKFLOWS } from '../store/workflow/types';
import { LogApi } from '../api';
import { setUsername } from './ActionCreators';
import { setDatabase } from 'store/database/actions';
import { setUserRoles } from '../store/user/actions';
import { persistor, MDMStore as store } from 'store/MDMStore';
import { UserStatus } from 'helpers';

export type login = (d: object) => (d: Function) => Action<USER_LOGGED_IN>;
export const login = data => dispatch => {
    UserStatus.clearLogoutMessage();

    dispatch({
        type: USER_LOGGING_IN
    });
    dispatch(setUsername(data.name));
    dispatch({
        type: USER_LOGGED_IN,
        payload: data
    });
    dispatch(setDatabase(data.model.currentDb || ''));
    dispatch(setUserRoles(data.model.roles));
    // successful login pushes you to the root page
    data.push('/');
};

export type logout = (logoutMessage?: string, timeout?: boolean) => (d: Function) => Action<USER_LOGGED_OUT>;
export const logout = (logoutMessage?: string, timeout: boolean = false) => dispatch => {

    if (logoutMessage) {
        UserStatus.setLogoutMessage(logoutMessage);
    }

    dispatch({ type: RESET_CHANGESET });
    dispatch({ type: CLEAR_LICENSE_INFO });
    dispatch({ type: CLEAR_PERMISSIONS });
    dispatch({ type: CLEAR_USER_DATA });
    dispatch({ type: CLEAR_WORKFLOWS });
    dispatch({ type: CLEAR_PERIODIC_DATA });
    dispatch({ type: USER_LOGGED_OUT });
    // We log out 3 ways: User choice, User inactivity, Service restart
    const username = store.getState().username || "(null)";
    // clear out persisted store state
    persistor().purge();
    if(username !== '(null)') {  // ignore extra logouts
        try {
            if(timeout) {
                LogApi.add1Audit({ area: "user", action: "timeout", username: username, message: `${username}' was logged out due to inactivity` });
            }
            // else {
            //  normal logout is audited by authentication service
            //     LogApi.add1Audit({ area: "user", action: "logout", username: username, message: `${username}' was logged out` });
            // }
        } catch(ignored) {
            console.error("logging service is unavailable");
        }
    }
};
export const storeRehydrated = () => dispatch => {
    dispatch({ type: STORE_REHYDRATED });
};
