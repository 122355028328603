import * as React from 'react';
import { Card, Divider } from '@blueprintjs/core';
import { Process, WorkflowDef, Table } from '../../../types';
import { WorkflowDetailForm } from './WorkflowDetailForm'
import { ClaimGroupDisplay } from './ClaimGroupDisplay'

export interface WorkflowDetailProps {
    workflow: WorkflowDef;
    process: Process;
    processes: Process[];
    groups: string[];
    deleteWorkflow: () => void;
    editWorkflow: () => void;
    tables: Table[];
}

export const WorkflowDetail: React.FC<WorkflowDetailProps> = ({ workflow, process, processes, deleteWorkflow, editWorkflow, groups, tables }) => {
    const { workflow_id, description } = workflow;

    return (
        <Card className="wf-card" key={`card-${workflow_id}`}>
            <div className="flex-column" id="wf-detail-card">
                <div className="flex-space-between">
                    <div className="text-medium">{`${workflow_id}: ${description}`}</div>

                    <div className="wf-detail-links">
                        <a role="button" id="wf-edit-btn" onClick={e => editWorkflow()}>Edit</a>|
                        <a role="button" id="wf-delete-btn" onClick={e => deleteWorkflow()}> Delete</a>
                    </div>
                </div>
                <Divider className="wf-detail-divider" />
            </div>

            <WorkflowDetailForm tables={tables} groups={groups} workflow={workflow} process={process} processes={processes} editable={false} onInputChange={() => { }} />
            <ClaimGroupDisplay workflow={workflow} groups={groups} />
        </Card>
    )
}
