import { DatabaseUrl } from '../proxies/ajax/DatabaseUrl';
import { axiosPost, axiosGet } from 'helpers';
import { displayError } from './displayError';

export class DatabaseApi {

    static getVersion() {
        return axiosGet(DatabaseUrl.version);
    }

    static ping() {
        return axiosGet(DatabaseUrl.ping);
    }

    /**
     * List all databases
     */
    static list = (): Promise<any> => axiosPost(DatabaseUrl.list, {}, 'databases').catch(err => {
        displayError('Database List Failed', `Failed to list databases`, err);
        throw err;
    })

    /**
     * Create a new database
     */
    static create = (name: string, desc: string): Promise<any> => {
        const body = { payload: { database: name, description: desc, permissions: [] } };
        return axiosPost(DatabaseUrl.create, body).then(() => true).catch(err => {
            displayError('Create Database Failed', `Failed to create dashboard`, err);
            throw err;
        });
    }

    /**
     * Update a database
     * As of now, only the description is really editable.
     */
    static update = (name: string, description: string): Promise<any> => {
        const body = { payload: { database: name, description } };
        return axiosPost(DatabaseUrl.update, body).then(() => true).catch(err => {
            displayError('Update Database Failed', `Failed to update database`, err);
            throw err;
        });
    }

    /**
     * Delete database with databaseName
     * "delete" is a reserved word, so deleteDb was used.
     */
    static deleteDb = (name: string): Promise<any> => {
        const body = { payload: { database: name } };
        return axiosPost(DatabaseUrl.deleteDb, body).then(() => true).catch(err => {
            displayError('Delete Database Failed', `Failed to delete database`, err);
            throw err;
        });
    }

    /**
     * Gets whether or not the license is expired
     */
    static getLicenseInfo = (reload: boolean): Promise<any> => {
        const body = { payload: { reload } };
        return axiosPost(DatabaseUrl.license, body).then((res) => {
            return res;
        }).catch(err => {
            displayError('Checking License Failed', `Failed to check how much time there is left of your current license.`, err);
            throw err;
        });
    }
}
