import * as React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { useExportReadyTables } from 'api';
import { Button } from 'components/Shared';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { TableUrl } from 'proxies/ajax/TableUrl';
import { set } from 'lodash';
import { ProgressBar, Spinner, SpinnerSize } from '@blueprintjs/core';

const TableExportDownloadButton = ({ table }) => {
    const [isExporting, setIsExporting] = React.useState<boolean>(false);
    const dbName = useSelector<RootState, string>(state => state.database.selected);
    const isSystem = useSelector<RootState, boolean>(state => {
        const { systemGroupName } = state.auth.config;
        const { groups } = state.user;

        return groups.indexOf(systemGroupName) > -1;
    })
    const { data = {} as any } = useExportReadyTables(
        'export-ready-tables',
        { dbName, table },
        { refetchInterval: 5000, enabled: isSystem },
    );

    const getLatestExport = () => {
        if (!data?.exports?.length) return null;

        const [latestExport] = data?.exports?.sort((a, b) => {
            if (a.timestamp < b.timestamp) return 1;
            if (a.timestamp > b.timestamp) return -1;
            return 0;
        })

        return latestExport;
    }

    const getLabel = () => {
        const latestExport = getLatestExport();

        if (!latestExport) return '';

        return `${latestExport.table}-${latestExport.timestamp}.txt`;
    }

    const onClick = async () => {
        if (!data?.exports) {
            toast.error("There's no export ready for this table");
            return;
        }

        const latestExport = getLatestExport()

        if (!('Blob' in window)) {
            alert('We cannot export your schema. Your browser does not support the HTML5 Blob.');
            return;
        }

        setIsExporting(true);

        const link = document.createElement('a');
        link.href = `${TableUrl.export_get}/${latestExport.uuid}?filename=${getLabel()}`;

        document.body.appendChild(link)

        link.click();

        link.remove();
        setIsExporting(false);
    }

    React.useEffect(() => {
        if (getLatestExport()?.ready === 0 && !isExporting) {
            setIsExporting(true);
        }

        if (getLatestExport()?.ready === 1 || getLatestExport()?.error?.length > 0) {
            setIsExporting(false);
        }
    }, [data, isExporting])


    if (isExporting && !getLatestExport()?.error) {
        return (
            <div style={{
                display: 'inline-flex',
                verticalAlign: 'middle',
                marginRight: '0.25rem',
                marginLeft: '0.25rem',
                width: 30,
                justifyContent: 'center',
            }}>
                <Spinner size={SpinnerSize.SMALL} intent="primary" />
            </div>
        );
    }

   
    const getTitle = () => {
        const latestExport = getLatestExport();

        if (!latestExport) return '';

        if (latestExport.error ) {
            return `Failed to export: ${latestExport.error}`;

        }
        if (latestExport.ready === 0) {
            return  `Exporting ${getLabel()}...`;
        }

        if (latestExport.ready === 1) {
            return `Download ${getLabel()}`;
        }

        return 'Export';
    }

    return (
        <Button
            disabled={!isSystem || getLatestExport()?.error?.length > 0 || getLatestExport()?.ready !== 1}
            intent={getLatestExport()?.error ? 'danger' : 'primary'}
            className='export-button'
            icon={IconNames.DOWNLOAD}
            minimal
            title={getTitle()}
            onClick={onClick}

        />
    )
};

export default TableExportDownloadButton;