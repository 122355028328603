import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { Position, Button, MenuItem } from '@blueprintjs/core';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { formatTimeHHMM, sortDatesNullsFirst } from '../../../utils';
import { Workitem } from '../../../types';
import { TooltipCell } from 'components/Shared/ReactTable/TooltipCell';
import { inSystemGroup } from 'api';
import { createColumnHelper } from '@tanstack/react-table';
import { Popover2 } from '@blueprintjs/popover2';

export interface IWorkflowSearchTableProps {
    loggedUsername: string;
    canEditWorkitems: boolean;
    availableWorkitems: Workitem[];
    claimTask: (database: string, workitemId: string, nodeId: string) => void;
    workitems: Workitem[];
    finished: boolean;
    loading: boolean;
    editTask: (workitem: Workitem) => void;
    deleteTask: (workitem: Workitem) => void;
    viewWhatWasDone: (workitem: Workitem) => void;
}

export const WorkflowSearchTable: React.FC<IWorkflowSearchTableProps> = ({ workitems, finished, loading, claimTask, availableWorkitems, editTask, deleteTask, canEditWorkitems, loggedUsername, viewWhatWasDone }) => {
    const colHelper = createColumnHelper<Workitem>();
    const renderCell = ({ cell }) => <TooltipCell value={cell.getValue()} />
    const renderTimeCell = ({ cell }) => <TooltipCell value={formatTimeHHMM(cell.getValue())} />
    const baseWorkitemColumns = [
        colHelper.accessor('workitem_id', {
            header: 'Workitem Id',
            enableResizing: true,
            enableSorting: true,
            cell: renderCell,
        }),
        colHelper.accessor('workflow_description', {
            header: 'Workflow',
            enableResizing: true,
            enableSorting: true,
            cell: renderCell,
        }),
        colHelper.accessor('started_by_user', {
            header: 'Started By User',
            enableResizing: true,
            enableSorting: true,
            cell: renderCell,
        }),
        colHelper.accessor('process_label', {
            header: 'Process',
            enableResizing: true,
            enableSorting: true,
            cell: renderCell,
        }),
        colHelper.accessor('start_timestamp',   {
            header: 'Start Time',
            enableResizing: true,
            enableSorting: true,
            cell: renderTimeCell,
            // sortMethod: (a, b) => {
            //     const aMillis = Date.parse(a);
            //     const bMillis = Date.parse(b);
            //     return aMillis - bMillis;
            // },
        }),
        colHelper.accessor('due_date', {
            header: 'Due Date',
            enableResizing: true,
            enableSorting: true,
            cell: renderTimeCell,
            // sortMethod: sortDatesNullsFirst,
        }),
    ];

    let ongoingWorkitemColumns = [
        ...baseWorkitemColumns,
        colHelper.accessor('assigned_user', {
            header: 'Assigned User',
            enableResizing: true,
            cell: renderCell,
        }),
        colHelper.display({
            header: 'Actions',
            enableResizing: true,
            enableSorting: false,
            cell: ({ cell, row }) => {
                const workitem = row.original;
                const isAvailable = availableWorkitems.find(foundWorkitem => foundWorkitem.workitem_id === workitem.workitem_id);

                const actionsMenu = <div className="popover-menu-wrapper">
                    {
                        canEditWorkitems &&
                        <MenuItem icon={IconNames.EDIT} onClick={e => editTask(workitem)} text='Edit Workitem' />
                    }
                    {
                        (workitem.assigned_user === loggedUsername || inSystemGroup()) &&
                        <MenuItem icon={IconNames.DELETE} onClick={e => deleteTask(workitem)} text='Delete Workitem' />
                    }
                    {
                        !!isAvailable &&
                        <MenuItem
                            icon={IconNames.ARROW_DOWN}
                            onClick={e => claimTask(workitem.database, `${workitem.workitem_id}`, workitem.node_id)}
                            text='Claim' />
                    }
                    <MenuItem icon={IconNames.HISTORY} onClick={e => viewWhatWasDone(workitem)} text='Workitem Activity' />
                </div>

                return (
                    <Popover2
                        className="popover-menu"
                        position={Position.BOTTOM_RIGHT}
                        content={actionsMenu}
                        minimal
                    >
                        <Button icon='menu' intent='none'></Button>
                    </Popover2>
                )
            }
        })
    ]

    const finishedWorkitemColumns = [
        ...baseWorkitemColumns,
        colHelper.accessor('end_timestamp', {
            header: 'End Time',
            enableResizing: true,
            cell: renderTimeCell,
            enableSorting: true,
            // sortMethod: (a, b) => {
            //     const aMillis = Date.parse(a);
            //     const bMillis = Date.parse(b);
            //     return aMillis - bMillis;
            // },
        }),
        colHelper.accessor('end_status', {
            header: 'End Status',
            enableResizing: true,
            cell: renderCell,
        }),
        colHelper.display({
            header: 'Actions',
            enableResizing: true,
            enableSorting: false,
            cell: ({ cell, row }) => {
                const workitem = row.original;
                return (
                    <div className="workflow-buttons">
                        <a role="button" data-toggle="tooltip" title={'What was done'} data-value={cell.getValue()} onClick={e => viewWhatWasDone(workitem)}>
                            <span className="bp3-button bp3-icon-history" />
                        </a>
                    </div>
                )
            }
        })
    ]

    // wrong logic: workitems can change independently of number of items
    // const data = React.useMemo(
    //     () => workitems,
    //     [workitems.length]
    // )

    const columns = React.useMemo(
        () => finished ? finishedWorkitemColumns : ongoingWorkitemColumns,
        [finished]
    )

    return (
        <ReactTable
            loading={loading}
            columns={columns}
            data={workitems}
            resultCount={workitems.length}
        />
    )
}
