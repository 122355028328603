import * as React from 'react';
import { Popover, PopoverInteractionKind } from '@blueprintjs/core';

interface TooltipCellProps {
    value: any;
    tooltipText?: string;
    contentClass?: string;
}

export class TooltipCell extends React.PureComponent<TooltipCellProps> {
    render() {
        const { tooltipText, value, contentClass } = this.props;
        let tooltip = tooltipText || value;
        return (
            <Popover position="bottom" popoverClassName="bp5-popover-content-sizing" interactionKind={PopoverInteractionKind.HOVER} hoverOpenDelay={80} hoverCloseDelay={80} content={tooltip}>
                <span>{value}</span>
            </Popover>
        )
    }
}
