declare var errorThrown:any;

export function messageFromJQXHR(jqXHR, errorThrown) {
    let msg = '';
    if (jqXHR.status === 0) {
        msg = 'Not connected. Verify Network.';
    } else if (jqXHR.status === 404) {
        msg = 'Requested page not found. [404]';
    } else if (jqXHR.status === 500) {
        msg = 'Internal Server Error [500].';
    } else if (errorThrown === 'parsererror') {
        msg = 'Requested JSON parse failed.';
    } else if (errorThrown === 'timeout') {
        msg = 'Time out error.';
    } else if (errorThrown === 'abort') {
        msg = 'Ajax request aborted.';
    } else {
        msg = `Uncaught Error. ${jqXHR.responseText}: ${errorThrown ? errorThrown.message : ''}`;
    }
    return msg;
}
