import * as React from 'react';
import { Menu, MenuItem, MenuDivider } from "@blueprintjs/core";
import { IconNames } from '@blueprintjs/icons';

import { setChangeset } from 'actions';
import { doCommit, doCreate, doDiscard } from 'components/Shared';
import { MDMStore } from 'store';
import { CHANGESET_CURRENT, Changeset, ChangesetStatus } from 'types';

export interface ChangesetMenuProps {
    changeset: Changeset;
    dbName: string;
    onHistorySelect: () => void;
    pendingChangesetId: number;
    username: string;
}
export const ChangesetMenu: React.FC<ChangesetMenuProps> = ({ changeset, dbName, onHistorySelect, pendingChangesetId, username }:
    { changeset: Changeset, dbName: string, onHistorySelect: any, pendingChangesetId: number, username: string }) =>
    <Menu>
        {
            changeset.changeset as any !== CHANGESET_CURRENT ?
                <MenuItem
                    icon={IconNames.ANNOTATION}
                    onClick={() => MDMStore.dispatch<any>(setChangeset(CHANGESET_CURRENT))}
                    text="Current"
                />
                : null
        }
        <MenuItem icon={IconNames.CALENDAR} onClick={onHistorySelect} text="History..." />
        {changeset.status !== ChangesetStatus.PENDING && !pendingChangesetId ?
            <MenuItem icon={IconNames.NEW_OBJECT} onClick={() => doCreate(dbName, username)} text="New" /> :
            null
        }
        {changeset.status !== ChangesetStatus.PENDING && pendingChangesetId ?
            <MenuItem onClick={() => MDMStore.dispatch<any>(setChangeset(pendingChangesetId))} text="My uncommitted changes" /> :
            null
        }
        {changeset.status === ChangesetStatus.PENDING ?
            [<MenuDivider key="divider" />,
            <MenuItem key="commit" icon={IconNames.SAVED} text="Commit changes" onClick={() => doCommit(changeset, dbName)} />,
            <MenuItem key="discard" icon={IconNames.DELETE} text="Discard changes" onClick={() => doDiscard(changeset, dbName)} />] :
            null
        }
    </Menu>;