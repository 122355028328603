
import { ITreeNode } from "@blueprintjs/core";
import { v4 as uuidv4 } from 'uuid';

import { IPermNode } from './IPermNode';
import { PermDatabaseNode } from './PermDatabaseNode';


export class PermSystemNode implements IPermNode {
    id:string;
    name:string;
    type:string;
    isOpen: boolean;
    canBeSelected: boolean;
    isLeaf: boolean;
    openIcon: string;
    closedIcon: string;
    children: IPermNode[];

    // TODO consider making the database :string[]
    constructor(databases: any[], changeset:string|number) {
        this.id = uuidv4();
        this.name = 'system';
        this.type = 'system';
        this.isOpen = true;
        this.canBeSelected = true;
        this.isLeaf = false;
        this.openIcon = 'folder-open';
        this.closedIcon = 'folder-close';
        this.children = databases.map( db => {
            return new PermDatabaseNode(db.database, changeset);
        });
    }

    // We only get our children via our constructor
    async getChildrenFn() {
        return await false;
    }

    fullNameFn() {
        return 'system'; // maybe '' would be better?
    }

    myName() {
        return 'system';  // maybe '' would be better?
    }

    getBPNode() {
        const childrenBP = this.children.map( child => child.getBPNode() );
        return {
            id: this.id,
            isExpanded: this.isOpen,
            hasCaret: true,
            iconName: this.isOpen ? this.openIcon : this.closedIcon,
            label: this.name,
            childNodes: childrenBP
        } as ITreeNode;
    }

    header() {
        return 'System';
    }
}
