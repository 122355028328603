import * as React from 'react';
import { connect } from 'react-redux';
import { OptionProps, Switch, Popover, PopoverPosition, RadioGroup, Radio } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Button } from '../../Shared';
import { QueryRow, EndStatus } from './QueryRow'
import { showToaster } from 'actions';
import { Intent } from 'types';
import './QueryBuilder.scss';

export interface WorkitemQueryBuilderProps {
    queryWorkitems: (filters: object) => void,
    showToaster?: showToaster,
    workflowDropdownoptions: any[],
}

interface Filter {
    name: string,
    value: string | boolean | CustomTimestamp
}

interface CustomTimestamp {
    start: string,
    end: string
}

export interface WorkitemQueryBuilderState {
    queryOnFinished: boolean,
    endTimestamp: CustomTimestamp,
    filters: Filter[],
    dropdownFilters: OptionProps[],
    submissionErrors: any[]
}


export class WorkitemQueryBuilderBase extends React.Component<WorkitemQueryBuilderProps, WorkitemQueryBuilderState> {

    private baseFilters: OptionProps[] = [
        { value: 'started_by_user', label: "Started By Username", disabled: false },
        { value: 'assigned', label: "Is Assigned", disabled: false }, //bool
        { value: 'start_timestamp', label: "Start Timestamp", disabled: false }, //timestamp
        { value: 'workflow_contains', label: "Contains in Description", disabled: false },
        { value: 'workflow_description', label: "Match Workflow Description", disabled: false },
        { value: 'due_date', label: "Due Date", disabled: false }, //timestamp
    ]

    private activeFilters: OptionProps[] = [
        { value: 'assigned_to_user', label: "Assigned To Username", disabled: false },
        { value: 'available_to_user', label: "Available To Username", disabled: false },
    ]
    private finishedFilters: OptionProps[] = [
        { value: 'end_timestamp', label: "End Timestamp", disabled: false }, //timestamp
        { value: 'end_status', label: "End Status", disabled: false },
    ]

    constructor(props) {
        super(props);

        this.state = {
            filters: [],
            queryOnFinished: false,
            endTimestamp: null,
            submissionErrors: [],
            // append the active filters since "queuryOnFinished" defaults to false
            dropdownFilters: [...this.baseFilters, ...this.activeFilters]
        }
    }

    validateTimestamp = (customTimestamp, prettyName): string[] => {
        let errors = []
        const startDateValid = !!customTimestamp.start && !isNaN(new Date(customTimestamp.start).getTime())
        const endDateValid = !!customTimestamp.end && !isNaN(new Date(customTimestamp.end).getTime())

        if (!startDateValid)
            errors.push(`Please provide a valid START to ${prettyName}.`)

        if (!endDateValid)
            errors.push(`Please provide a valid END to ${prettyName}.`)

        return errors;
    }

    getFilterPrettyName = (filterName: string): string => {
        const { dropdownFilters } = this.state;
        const dropdownFilter = dropdownFilters.find(dropdownFilter => dropdownFilter.value === filterName);
        return dropdownFilter.label;
    }

    executeQuery = () => {
        const { queryWorkitems } = this.props;
        const { filters, queryOnFinished } = this.state;

        // Construct "filter" object to be used in request
        let requestFilter = {};
        let errors = []

        // Handle REQUIRED filter
        requestFilter['finished'] = queryOnFinished;

        // Handle OPTIONAL filters
        filters.map(filter => {
            const value = filter.value as any
            if (value.start === null || value.end === null) {
                const prettyName = this.getFilterPrettyName(filter.name)
                const dateErrors = this.validateTimestamp(value, prettyName);
                errors = errors.concat(dateErrors);

            }
            requestFilter[filter.name] = filter.value;
        })

        if (!errors.length) {
            queryWorkitems(requestFilter);
            this.setState({ submissionErrors: [] })
        } else {
            this.setState({ submissionErrors: errors })
        }
    }

    setFinishedFilters = () => {
        const { filters, dropdownFilters } = this.state;
        let updatedDropdownFilters = dropdownFilters
            .filter(dropdownFilter => !this.finishedFilters.find(finishedFilter => finishedFilter.value === dropdownFilter.value));
        updatedDropdownFilters = updatedDropdownFilters.concat(this.activeFilters);

        const updatedFilters = filters.filter(filter => !this.finishedFilters.find(finishedFilter => finishedFilter.value === filter.name));

        this.setState({ dropdownFilters: updatedDropdownFilters, filters: updatedFilters, queryOnFinished: false });
    }

    setActiveFilters = () => {
        const { filters, dropdownFilters } = this.state;
        let updatedDropdownFilters = dropdownFilters
            .filter(dropdownFilter => !this.activeFilters.find(activeFilter => activeFilter.value === dropdownFilter.value));
        updatedDropdownFilters = updatedDropdownFilters.concat(this.finishedFilters);

        const updatedFilters = filters.filter(filter => !this.activeFilters.find(activeFilter => activeFilter.value === filter.name));

        this.setState({ dropdownFilters: updatedDropdownFilters, filters: updatedFilters, queryOnFinished: true });
    }

    generateRequiredFilters = () => {
        const { queryOnFinished } = this.state;
        return (
            <div className="required-filters">
                <div className="query-switch">
                    <Switch
                        className="text-heavy text-small"
                        checked={queryOnFinished}
                        label="Query finished workitems."
                        onChange={() => {
                            const newQueryingState = !queryOnFinished;
                            if (newQueryingState) {
                                this.setActiveFilters()
                            } else {
                                this.setFinishedFilters()
                            }
                            this.setState({ queryOnFinished: newQueryingState })
                        }}
                    />
                </div>
            </div>
        )
    }

    generateFinishedInputField = () => {
        const { queryOnFinished } = this.state;
        return (
            <div className="query-radios">
                <RadioGroup
                    className="radio-group"
                    label="Query workitems that are:"
                    onChange={(event) => {
                        const newQueryingState = event.currentTarget.value === 'true';
                        if (newQueryingState) {
                            this.setActiveFilters()
                        } else {
                            this.setFinishedFilters()
                        }
                        this.setState({ queryOnFinished: newQueryingState })
                    }}
                    selectedValue={queryOnFinished.toString()}
                    inline
                >
                    <Radio className="query-radio" label="Active" value="false" />
                    <Radio className="query-radio" label="Finished" value="true" />
                </RadioGroup>
            </div>
        )
    }

    generateOptionalFilters = () => {
        const { workflowDropdownoptions } = this.props
        const { filters, dropdownFilters } = this.state;

        if (!Object.keys(filters).length)
            return null;

        return (
            <table id="filters-table" className="fill-width">
                <thead>
                    <tr>
                        <th>Query By</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody className="query-form-tbody">
                    {filters.map((filter, idx) => {
                        return (
                            <QueryRow
                                executeQuery={this.executeQuery}
                                key={`${idx}-${filter.name}`}
                                value={filter.value}
                                dropdownValue={filter.name}
                                dropdownFilters={dropdownFilters}
                                onRemove={this.removeFilter}
                                onValueChange={this.updateFilterValue}
                                onDropdownChange={this.updateFilterName}
                                workflowDropdownoptions={workflowDropdownoptions}
                            />
                        );
                    })}
                </tbody>
            </table>
        )
    }

    // IF workflow_description exists in state.filters, we CANNOT have a workflow_contains filter.
    isWorkflowDescription = () => !!this.state.filters.find(filter => filter.name === 'workflow_description');

    // IF workflow_contains exists in state.filters, we CANNOT have a workflow_description filter.
    isWorkflowContains = () => !!this.state.filters.find(filter => filter.name === 'workflow_contains');

    getAvailableFilter = (): OptionProps => {
        const hasWfDescription = this.isWorkflowDescription()
        const hasWfContains = this.isWorkflowContains()
        return this.state.dropdownFilters.find(filter => {
            // validate if we already have a workflow_contains or workflow_description since we CANNOT have both.
            if (hasWfDescription) {
                return (!filter.disabled && filter.value !== 'workflow_contains')
            } else if (hasWfContains) {
                return (!filter.disabled && filter.value !== 'workflow_description')
            }
            return !filter.disabled
        });
    }

    toggleDropdownFilter = (filterValue: string, enabled: boolean): OptionProps[] => {
        const { dropdownFilters } = this.state;
        return dropdownFilters.map(filter => {
            if (filter.value === filterValue)
                return { ...filter, disabled: !enabled };
            return filter;
        })
    }

    getDefaultValue = (filterName) => {
        switch (filterName) {
            case 'assigned':
            case 'finished':
                return false;
            case 'start_timestamp':
            case 'end_timestamp':
            case 'due_date':
                return { start: null, end: null };
            case 'end_status':
                return EndStatus.SUCCESS;
            default:
                return ''
        }
    }

    appendFilter = () => {
        const { filters } = this.state;
        const availableFilter = this.getAvailableFilter();
        if (!availableFilter)
            return null;

        const updatedDropdownFilters = this.toggleDropdownFilter(availableFilter.value.toString(), false);
        const defaultValue = this.getDefaultValue(availableFilter.value);
        const updatedFilters = [...filters, { name: availableFilter.value, value: defaultValue } as Filter];
        this.setState({ filters: updatedFilters, dropdownFilters: updatedDropdownFilters, submissionErrors: [] });
    }

    removeFilter = (filterName: string) => {
        const { filters } = this.state;
        const updatedDropdownFilters = this.toggleDropdownFilter(filterName, true);
        const updatedFilters = filters.filter(filter => filter.name !== filterName);
        this.setState({ filters: updatedFilters, dropdownFilters: updatedDropdownFilters, submissionErrors: [] });
    }

    updateFilterName = (prevName: string, newName: string) => {
        const { showToaster } = this.props;
        const { dropdownFilters, filters } = this.state;

        // validate if we already have a workflow_contains or workflow_description since we CANNOT have both.
        const hasWfDescription = this.isWorkflowDescription()
        const hasWfContains = this.isWorkflowContains()

        // if we have workflow_description, dont allow a change to workflow_contains UNLESS we're changing from that workflow_description
        if (hasWfDescription && newName === 'workflow_contains' && prevName !== 'workflow_description') {
            return showToaster(Intent.INFO,
                `"Contains In Description" cannot be added if "Match Whole Description" exists.`
            );
        }

        // if we have workflow_contains, dont allow a change to workflow_description UNLESS we're changing from that workflow_contains
        if (hasWfContains && newName === 'workflow_description' && prevName !== 'workflow_contains') {
            return showToaster(Intent.INFO,
                `"Match Whole Description" cannot be added if "Contains In Description" exists.`
            );
        }

        const updatedDropdownFilters = dropdownFilters.map(dropdownFilters => {
            if (dropdownFilters.value === prevName)
                return { ...dropdownFilters, disabled: false };
            if (dropdownFilters.value === newName)
                return { ...dropdownFilters, disabled: true };
            return dropdownFilters;
        })

        const updatedFilters = filters.map(filter => {
            if (filter.name === prevName) {
                const defaultValue = this.getDefaultValue(newName);
                return { name: newName, value: defaultValue };
            }
            return filter;
        })

        this.setState({ filters: updatedFilters, dropdownFilters: updatedDropdownFilters });
    }

    updateFilterValue = (name: string, newValue: any) => {
        const { filters } = this.state;
        const updatedFilters = filters.map(filter => filter.name === name ? { ...filter, value: newValue } : filter);
        this.setState({ filters: updatedFilters });
    }

    generateErrorSection = () => {
        const { submissionErrors } = this.state;
        if (!submissionErrors.length)
            return null;

        return (
            <div className="flex-column flex-center">
                {submissionErrors.map(errMessage =>
                (
                    <div className="full-width">
                        <span className="text-small text-error">*{errMessage}</span>
                    </div>
                )
                )}
            </div>
        )
    }

    generateSearchButton = () => {
        const { submissionErrors } = this.state;

        const popoverContent = submissionErrors.length ?
            <div className="flex-column flex-center submussion-error-section">
                {submissionErrors.map((errMessage, i) => {
                    return (
                        <div className="fill-width">
                            <span key={`submission-err-${i}`} className="text-small text-heavy text-error">
                                *{errMessage}
                            </span>
                        </div>
                    )
                })}
            </div> :
            null

        return (
            <Popover
                isOpen={!!submissionErrors.length}
                content={popoverContent}
                hoverOpenDelay={0}
                defaultIsOpen
                position={PopoverPosition.BOTTOM}
            >
                <div className="flex-center-vertically fill-height">
                    <Button
                        className="minimal-button search-button"
                        onClick={this.executeQuery}
                    >
                        Search
                    </Button>
                </div>
            </Popover>
        )
    }

    render() {
        return (
            <div className="query-form-panel-body" >

                {this.generateFinishedInputField()}
                {this.generateOptionalFilters()}

                < div className="query-buttons-container flex-space-between" >
                    <Button
                        className="add-query-button"
                        title="Add query term"
                        onClick={this.appendFilter}
                        icon={IconNames.PLUS}
                        minimal
                    >
                        &nbsp;&nbsp;Add Filter
                    </Button>
                    {this.generateSearchButton()}
                </div>
            </div >
        );
    }
}

export const WorkitemQueryBuilder = connect(
    (state: any) => ({}), { showToaster })(WorkitemQueryBuilderBase);