/**
 * @format
 */
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState, ReactNode } from 'react';
import { Checkbox, Icon, OptionProps, Popover, PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, CustomSelect } from 'components/Shared';
import { DataTypes, FilterField, Schema, SchemaType } from 'types';
import { hasPIIFields } from 'utils';
import { IconNames } from '@blueprintjs/icons';

import { toTitlecase } from 'utils';

import './QueryRecordsForm.scss';


export interface QueryRecordsFormProps {
    initialOpenState?: boolean;
    showMaskedRecordsCheckbox?: boolean;
    initialField?: boolean;
    queryRecords: (filter: FilterField[], showMasked?: boolean) => void;
    updateCount?: (newCount: number) => void;
    schema: Schema;
    showMasked?: boolean
    needsQuery?: boolean
    table?: string
}

export interface QueryRecordsFormState {
    fields: FilterField[];
    masked: boolean;
    open: boolean;
}

const getNestedField = (schema: Schema, field: string) => {
    const fields = field.split('.');
    let currentSchema = schema;
  
    for (let i = 0; i < fields.length; i++) {
        const fieldSchema = currentSchema.fields.find(f => f.field === fields[i]);
        if (fieldSchema.datatype.type === 'table' /* && i < fields.length - 1 */) {   // why would you ignore the last field -- and also not explain why you are doing this as if it is intuitive and obvious
            currentSchema = fieldSchema.schema;
        } else {
            return fieldSchema;
        }
    }
  
    return null;
};
  
  
const validateInrange = (value: string): string | boolean => {
    // regex to validate inrange values only 2 numbers separated by comma
    let isValid: boolean = /^(-?\d+\s*,\s*-?\d+)$/.test(value);

    if (!isValid) {
        return 'Invalid value. Must be a range of two numbers separated by commas';
    }

    const [val1, val2] = value.split(',');

    // In range is for numeric values only so you have to do numeric comparison
    // otherwise 2 is bigger than 10
    if (Number(val1) > Number(val2)) {
        return 'First value should be less than the second value';
    }

    return true;
}

const validateOneOf = (value: string, type: DataTypes): string | boolean => {
    // return early if datatype is not text, integer, money, float
    if (!([DataTypes.text, DataTypes.integer, DataTypes.money, DataTypes.float].includes(type))) {
        return false;
    }
    let isValid = false;
    const values = value.split(',').filter(v => v.trim().length > 0);

    // if type is text, just check if there are values
    if (type === DataTypes.text) {
        isValid = values.length > 0;
    }

    // if type is integer, money, float, check if all values are numbers
    if ([DataTypes.integer, DataTypes.money, DataTypes.float].includes(type)) {
        isValid = values.every(v => !isNaN(Number(v)));

        if (!isValid) {
            return 'Invalid values. All values must be a valid number';
        }
    }

    return isValid;
}
  

export const QueryRecordsForm: FC<QueryRecordsFormProps> = ({
    schema,
    updateCount,
    showMasked,
    showMaskedRecordsCheckbox,
    initialField,
    queryRecords,
    table
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ReactElement<any, any> => {
    const operations = [
        { value: '=', label: "Equal to" },
        { value: '<>', label: "Not equal to" },
        { value: '>', label: "Greater than" },
        { value: '<', label: "Lesser than" },
        { value: '>=', label: "Greater or equal than" },
        { value: '<=', label: "Lesser or equal than" },
        { value: 'inrange', label: "In range" },
        { value: 'startswith', label: "Starts with" },
        { value: 'endswith', label: "Ends with" },
        { value: 'contains', label: "Contains" },
        { value: 'oneof', label: "One of" },
        { value: 'regex', label: "Regular expression matching" },
    ];
    const [isMasked, setIsMasked] = useState(false);

    const mapSchema = (schema: Schema): OptionProps[] => { 
        if (!schema) return [];
        const result = [];

        const addFieldToResult = (field: string, label: string) => {
            result.push({ label, value: field });
        }

        schema.fields
            .filter(f => f.datatype.type !== 'table')
            .sort((a, b) => a.field.toLowerCase().localeCompare(b.field.toLowerCase()))
            .forEach((f) => addFieldToResult(f.field, f.field));

        schema.fields
            .filter(f => f.datatype.type === 'table')
            .sort((a, b) => a.field.toLowerCase().localeCompare(b.field.toLowerCase()))
            .forEach((f) => {
                f.schema.fields
                    .sort((a, b) => a.field.toLowerCase().localeCompare(b.field.toLowerCase()))
                    .forEach((g) => addFieldToResult(`${f.field}.${g.field}`, `${f.field}.${g.field}`));
            });

        if (schema.matching?.enabled && schema.matching.display) {
            schema.matching.match_types.forEach((matchType: { type: string }) => {
                const name = `${toTitlecase(matchType.type)} Group ID`;
                result.push({ label: name, value: `MATCHGROUP_${matchType.type}_ID` });
            });
        }

        return result;
    }

    const fieldOptions = useMemo(() => mapSchema(schema), [schema]);

    const formSchema = yup.object().shape({
        fields: yup.array().of(
          yup.object().shape({
            field: yup.string().required('Please select a field').min(1),
            operation: yup.string().required('Please select an operation').min(1),
            values: yup.string().test('values-test', ``, function (value) {
                const { field, operation } = this.parent;
                if (!field) {
                    return true;
                }

                if (field.startsWith("$match.")) {
                    return true;
                }

                const fieldSchema = getNestedField(schema, field);

                let isValid = false;
                const type = fieldSchema.datatype.type;
                switch (type) {
                    case DataTypes.text:
                        isValid = value.trim().length > 0;
                        break;
                    case DataTypes.integer:
                    case DataTypes.money:
                    case DataTypes.float:
                        isValid = !isNaN(Number(value));
                        
                    break;
                    case DataTypes.date:
                    case DataTypes.datetime:
                    case DataTypes.time:
                        isValid = !isNaN(Date.parse(value));
                        break;
                    case DataTypes.boolean:
                        isValid = value === 'true' || value === 'false';
                        break;
                    case DataTypes.binary:
                        // Add your validation logic for binary type
                        isValid = true;
                        break;
                    default:
                        isValid = true;
                        break;
                }

                if (operation === 'inrange') {
                    const result = validateInrange(value);

                    if (typeof result === 'string') {
                        return this.createError({ message: result });
                    }

                    isValid = result;
                }

                if (operation === 'oneof') {
                    const result = validateOneOf(value, type);

                    if (typeof result === 'string') {
                        return this.createError({ message: result });
                    }

                    isValid = result;
                }

                if (!isValid) {
                    return this.createError({ message: `Invalid value. Must be a valid ${type}` });;
                }

                return true;
            })
          })
        )
      });

    const { control, handleSubmit, watch, formState: { errors }, reset, trigger } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            fields: [
                { field: '', operation: '', values: '' }
            ]
        },
        reValidateMode: 'onChange',
        mode: 'all',
        shouldFocusError: false,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fields',
    });

    const queryValues = watch('fields', []);

    const isMatchField = (field: string) => field.startsWith('$match.');

    const filterOperations = (field: string) => {
        if (!field) {
            return operations;
        }

        const fieldSchema = getNestedField(schema, field);

        if (!fieldSchema) {
            return operations;
        }

        if (isMatchField(field)) {
            return [{ value: '=', label: "Equal to" }];
        }

        return operations.filter(op => {
            switch (op.value) {
                case '>':
                case '<':
                case '>=':
                case '<=':
                case 'inrange':
                    return fieldSchema.datatype.type === DataTypes.integer || fieldSchema.datatype.type === DataTypes.money || fieldSchema.datatype.type === DataTypes.float;
                case 'startswith':
                case 'endswith':
                case 'contains':
                case 'regex':
                    return fieldSchema.datatype.type === DataTypes.text;
                case 'oneof':
                    return fieldSchema.datatype.type === DataTypes.text || fieldSchema.datatype.type === DataTypes.integer || fieldSchema.datatype.type === DataTypes.money || fieldSchema.datatype.type === DataTypes.float;
                default:
                    return true;
            }
        });
    }

    const getOperations = useCallback((idx: number) => {
        if (queryValues.length === 0) return operations;

        const field = queryValues[idx].field;
        return filterOperations(field);
    }, [queryValues]);

    useEffect(() => {
       if(updateCount) updateCount(fields.length);
    }, [queryValues, updateCount]);

    useEffect(() => {
        if (initialField) {
            if(fields.length) {
                //console.log("use effect initialField called");
                reset(); // fields = []
                remove(); // clear the associated form
            }
            if (fields.length === 0) {
                append({ field: '', operation: '', values: '' });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //console.log("table changed to " + table);
        reset();
        remove();
        if(updateCount) updateCount(0);
    }, [table])

    const generateTable = () => {
        if (!fields || !fields.length) {
            return (
                <div className="no-data-text-container">
                    <span className="text-medium">No queries added to the data</span>
                </div>
            )
        }

        return (
            <table className="fill-width">
                <thead>
                    <tr>
                        <th>Query field</th>
                        <th>Operation</th>
                        <th>Values</th>
                    </tr>
                </thead>
                <tbody className="query-form-tbody">
                    {fields.map((field, idx) => (
                        <tr key={field.id}>
                            <td className="select-td">
                                <Controller
                                    control={control}
                                    name={`fields.${idx}.field`}
                                    render={({ field }) => (
                                        <>
                                            <CustomSelect
                                                invalid={!!errors.fields?.[idx]?.field}
                                                placeholder="Choose a field..."
                                                onChange={(item) => {
                                                    field.onChange(item.value);
                                                    if (queryValues[idx].values) {
                                                        trigger(`fields.${idx}.values`);
                                                    }
                                                }}
                                                value={field.value}
                                                options={fieldOptions}
                                                fill
                                            />
                                            <span className="text-error text-xs">{errors.fields?.[idx]?.field?.message}</span>
                                        </>
                                    )}
                                />
                            </td>
                            <td className="operation-td">
                                <Controller
                                    control={control}
                                    name={`fields.${idx}.operation`}
                                    render={({ field }) => (
                                        <>
                                            <CustomSelect
                                                invalid={!!errors.fields?.[idx]?.operation}
                                                placeholder="Choose an operation..."
                                                onChange={item => {
                                                    field.onChange(item.value)
                                                    if (queryValues[idx].values) {
                                                        trigger(`fields.${idx}.values`);
                                                    }
                                                }}
                                                value={field.value}
                                                name="operation"
                                                options={getOperations(idx)}
                                                fill
                                            />
                                            <span className="text-error text-xs">{errors.fields?.[idx]?.operation?.message}</span>
                                        </>
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    control={control}
                                    name={`fields.${idx}.values`}
                                    render={({ field }) => { 
                                        let hasError = false;
                                        const fld = schema?.fields?.find(f => f.field === fields[idx].field);
                                        const hasValues = fld?.values?.length > 0;
                                        if (errors.fields?.[idx]?.values) {
                                            hasError = true;
                                        }

                                        if (hasValues) {
                                            return (
                                                <CustomSelect 
                                                    placeholder="Choose a value..."
                                                    onChange={selectedOption => field.onChange(selectedOption.value)}
                                                    value={field.value}
                                                    name="values"
                                                    options={fld.values.map(v => ({label: v, value: v} as OptionProps))}
                                                    fill
                                                />
                                            )
                                        }

                                        return (
                                            <>
                                                <input
                                                    className={`w-full rounded ${hasError ? 'has-error' : ''}`}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    // on enter
                                                    onKeyDown={e => { if (e.key == 'Enter') handleSubmit(query) }}
                                                />
                                                <span className="text-error text-xs">{errors.fields?.[idx]?.values?.message}</span>
                                            </>
                                            
                                        )
                                    }}
                                />

                            </td>
                            <td className="delete-row">
                                <Button
                                    onClick={() => remove(idx)}
                                    icon={IconNames.CROSS}
                                    minimal
                                    className="border-none"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    const onCancel = () => {
        reset();
    }

    const checkIfHardSearch = () => {
        let indexed = 0;
        let filters = 0

        queryValues.forEach((searchField) => {
    
            if (searchField?.field !== '') {
                if (searchField?.field?.startsWith("$match.")) {
                    ++filters;
                    ++indexed;
                }
                else if (searchField.field.includes(".")) {
                    const parts = searchField.field.split(".");
                    if (parts.length === 2) {
                        const field = schema.fields.find(f => f.field === parts[0]);
                        const subField = field.schema.fields.find(f => f.field === parts[1]);
                        ++filters;
                        indexed += (subField && subField.index) ? 1 : 0;
                    }
                }
                else {
                    const field = schema.fields.find(f => f.field === searchField.field);
                    ++filters;
                    indexed += (field && field.index) ? 1 : 0;
                }
            }
        })

        return filters > 0 && indexed === 0;
    }

    const query = (values: { fields: { field: string, operation: string, values: string }[] }) => { 
        queryRecords(
            values.fields.filter(field => {
                return field.field && field.operation;
            }).map(values => ({
                field: values.field,
                operation: values.operation,
                values: values.values.split(',').map(v => v.trim())
            } as FilterField)),
            showMasked ? isMasked : false,
        );
    }

    const countValidQueryFields = () => queryValues.filter(field => field.field && field.operation).length

    const onGenerateSearchButton = () => {
        const isHardSearch = checkIfHardSearch();
        const hasValidQueries = countValidQueryFields() > 0;

        if (isHardSearch) {
            const popoverContent = (
                <span className="popover-content">
                    This search is unindexed and may be slow or timeout
                </span>
            )

            return (
                <Popover
                    interactionKind={PopoverInteractionKind.HOVER}
                    position={PopoverPosition.BOTTOM}
                    content={popoverContent}
                    hoverOpenDelay={0}
                    defaultIsOpen
                    usePortal={false}
                    enforceFocus={false}
                >
                    <Button
                        className="minimal-button search-button"
                        onClick={handleSubmit(query)}
                        disabled={!hasValidQueries}
                    >
                        <Icon icon={IconNames.WARNING_SIGN} />
                        Search
                    </Button>
                </Popover>
            )
        }

        return (
            <Button
                className="minimal-button search-button"
                onClick={handleSubmit(query)}
                disabled={Object.keys(errors).length !== 0}
            >
                Search
            </Button>
        );
    }

    const generateMaskedOption = (): ReactNode => {
        if (!showMaskedRecordsCheckbox || !showMasked || !hasPIIFields(schema))
            return null;

        return (
            <Checkbox
                className="masked-records-checkbox"
                checked={isMasked}
                label="Include masked records"
                onChange={() => setIsMasked(!isMasked)}
            />
        )
    }

    return (
        <div className="query-form-panel-body">

            {generateMaskedOption()}
            {generateTable()}
            

            <div className="query-buttons-container flex-space-between">
                <Button
                    className="add-query-button"
                    title="Add query term"
                    onClick={() => append({ field: '', operation: '', values: '' })}
                    icon={IconNames.PLUS}
                    minimal
                >
                    &nbsp;&nbsp;Add Query
                </Button>

                <div className="flex-center-vertically fill-height">
                    <Button
                        className="cancel-button"
                        title="Cancel"
                        onClick={onCancel}
                        minimal
                    >
                        Cancel
                    </Button>

                    <div className='flex flex-col items-center'>
                        {onGenerateSearchButton()}
                        {errors.fields && <span className="text-error text-xs">Please enter a valid query</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}
