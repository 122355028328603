/**
 * @format
 */
import * as React from 'react';
import { Checkbox, FormGroup, HTMLSelect, Intent, TextArea, Classes } from '@blueprintjs/core';
import { InfoTooltip } from './InfoTooltip';

/**
 * MDMFormGroup
   Simple label - text field with (external) validation

 Input Properties
    id - string -> FormGroup controlId
    label - ControlLabel text
    errortext - fixed string for when the input is invalid.  Using the helpblock for this
    maxlength - integer
    placeholder

    type - FormControl type. Defaults to text. Allow text, email, password, dropdown
    validate - returns { ok, error }
    value - initial value
    values - array of { id, value } for dropdown
 Read functions
     value() - its current value
     isValid() - true if the current value is a valid value.

 TODO -
     FormGroup props:  bsSize
     FormControl props: support textarea (which will need rows and cols)
     consider adding a warning state to isValid rather than just true/false.
     allow something other than sm={2/10} for column Sizes
     allow dynamic text for helptext. Maybe this.props.helpFn(isValid)?
     allow isValid to modify the value so to prevent things from getting into a bad
       state. E.g. in a number form ignore input of not [-0-9]
 */
export interface MDMFormGroupProps {
    /** If this is a textarea the row count. Default is 5 */
    rows?: number;
    /** So that you can reference the dom elemeent.  Not sure we need this in React */
    id: string;
    /** if true, do not allow the user to alter the content. In some cases we display using different tags than when that element is interactive */
    readOnly?: boolean;
    /** The element is of the form: column1 (width 2) column2 (width 10) where the first column contains this
     * text label
     */
    label: string;
    /** if this is a simple text input then you can limit the number of characters the user may enter into the field */
    maxLength?: number;
    /** What to display if value is empty or null */
    placeholder?: string;
    /** Tooltip info to show next to label */
    tooltipText?: string;
    /** default is text. Can be dropdown, text, textarea. Untested: email, password */
    type?: string;
    /** A function(newval, arg) return {ok,error} to call whenever the value changes */
    validate?: (key: string, validateArg: any) => { ok: boolean, error: string };
    /** A value passed to the validate function */
    validateArg?: any;
    /** A function(newval) return void to call whenever the select changes */
    onSelect?: (newValue: string) => void;
    /** The current value of the element */
    value: any;
    /** For dropdowns a list of all the possible values the field may take */
    values?: { id: string, value: string }[];
    /** true means user cannot interact with it. only used on displaybox */
    disabled?: boolean;
}
export interface MDMFormGroupState {
    isValid: boolean;
    errortext: string;
    rows: number;
    value: any;
    disabled: boolean;
    validate: (key: string, validateArg: any) => { ok: boolean, error: string };
}
export class MDMFormGroup extends React.Component<MDMFormGroupProps, MDMFormGroupState> {
    public renderFn: Function;

    constructor(props) {
        super(props);
        this.state = {
            errortext: '',
            isValid: true,
            rows: this.props.rows || 5,
            value: this.props.value || '',
            validate:
                this.props.validate ||
                (() => {
                    return { ok: true, error: '' };
                }),
            disabled: this.props.disabled || false
        };
        this.setRenderFn(this.props.type);
    }

    setRenderFn(type: string) {
        // change to array indexed by props.type?
        switch (this.props.type) {
            case 'dropdown': this.renderFn = this.dropdownRender; break;
            case 'enablebox': this.renderFn = this.enableRender; break;
            case 'displaybox': this.renderFn = this.displayRender; break;
            // textbox? textarea?
            default: this.renderFn = this.textRender; break;

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.type !== this.props.type)
            this.setRenderFn(this.props.type);
    }

    onChangeFn = event => {
        const valid = this.state.validate(event.target.value, this.props.validateArg);
        this.setState({ isValid: valid.ok, errortext: valid.error });
    }

    onSelectFn = event => {
        const key = event.target.value;
        const newValue = this.props.values.find(v => v.id === key);
        const valid = this.state.validate(newValue ? key : '0', this.props.validateArg);
        this.props.onSelect && this.props.onSelect(newValue.value);
        this.setState({ isValid: valid.ok, errortext: valid.error });
    }

    baseRender = (children: JSX.Element) => {
        const { tooltipText, label } = this.props;
        const { isValid, errortext } = this.state;

        const helpText = this.state.isValid ? '' : this.state.errortext;
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, fontWeight: 700 }}>
                    {this.props.label || ''}
                    {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
                </div>
                <div style={{ flex: 5 }}>
                    <FormGroup
                        helperText={helpText}
                        intent={this.state.isValid ? Intent.NONE : Intent.DANGER}
                    >
                        {children}
                    </FormGroup>
                </div>
            </div>
        );
    }

    enableRender = () =>
        this.baseRender(<Checkbox id={this.props.id} checked={this.props.value} onChange={e => this.props.validate('', (e.target as any).checked)}>
            Enable DM Matching Support
        </Checkbox>)

    displayRender = () =>
        this.baseRender(<Checkbox id={this.props.id} disabled={this.props.disabled} checked={this.props.value} onChange={e => this.props.validate('', (e.target as any).checked)}>
            Display Match Group IDs
        </Checkbox>)

    textRender = () => {
        const rows = this.props.type === 'textarea' ? this.state.rows : undefined;
        const component = this.props.type === 'textarea' ?
            <TextArea id={this.props.id} fill={true} minLength={rows} onChange={!this.props.readOnly && this.onChangeFn} value={this.props.value}
                placeholder={this.props.placeholder} disabled={this.props.readOnly} maxLength={this.props.maxLength} /> :
            <input id={this.props.id} className={Classes.INPUT} placeholder={this.props.placeholder} value={this.props.value}
                maxLength={this.props.maxLength} disabled={this.props.readOnly} onChange={!this.props.readOnly && this.onChangeFn} />;

        // TODO investigate margin-top style requirement
        return this.baseRender(component);
    }

    // ReadOnly Dropdown now renders as a dropdown with one choice
    dropdownRender = () => {
        const { value, values, readOnly, placeholder } = this.props;

        const choices = readOnly ? [value] :
            values.map(v => v.value);

        const selectedValue = value || '';
        if (!selectedValue)
            choices.unshift({ id: "", value: placeholder || "<Please Select>" })

        return this.baseRender(
            <HTMLSelect
                onChange={this.onSelectFn}
                value={selectedValue}
                options={choices}
            />
        );
    }

    render() {
        return this.renderFn();
    }
}
