export class ViewUrl {
    public static create                = '/ajax/view/create';
    public static modify                = '/ajax/view/modify';
    public static delete                = '/ajax/view/delete';
    public static validate              = '/ajax/view/validate';
    public static query                 = '/ajax/view/query';

    public static subscriptionCreate    = '/ajax/view/subscription/create';
    public static subscriptionDelete    = '/ajax/view/subscription/delete';
    public static subscriptionEnable    = '/ajax/view/subscription/enable';
    public static subscriptionModify    = '/ajax/view/subscription/modify';
    public static subscriptionQuery     = '/ajax/view/subscription/query';
}