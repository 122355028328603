import dashboard from '../../../../images/focused-dashboard.svg'
import plus from '../../../../images/focused-plus.svg'
import newDocument from '../../../../images/focused-new-document.svg'
import layer from '../../../../images/focused-layer.svg'
import layers from '../../../../images/focused-layers.svg'
import database from '../../../../images/focused-database.svg'
import table from '../../../../images/focused-table.svg'
import eyeOpen from '../../../../images/focused-eye-open.svg'
import eraser from '../../../../images/focused-eraser.svg'
import person from '../../../../images/focused-person.svg'
import people from '../../../../images/focused-people.svg'
import key from '../../../../images/focused-key.svg'
import stackedChart from '../../../../images/focused-stacked-chart.svg'
import tickEraser from '../../../../images/focused-tick-eraser.svg'
import arrowRight from '../../../../images/focused-arrow-right.svg'
import arrowLeft from '../../../../images/focused-arrow-left.svg'
import exchange from '../../../../images/focused-exchange.svg'
import write from '../../../../images/focused-write.svg'
import circleArrowRight from '../../../../images/focused-circle-arrow-right.svg'
import chevronDown from '../../../../images/focused-chevron-down.svg'
import flowArrow from '../../../../images/focused-flow-arrow.svg'
import process from '../../../../images/focused-process.svg'
import logs from '../../../../images/focused-event-log-icon.svg'

import { CustomIconName } from '../../../types'
import { CUBE } from '@blueprintjs/icons/lib/esm/generated/iconContents'

export function getFocusedIcon(iconName) {
    switch(iconName) {
        case CustomIconName.PLUS: return plus;
        case CustomIconName.NEW_DOCUMENT: return newDocument;
        case CustomIconName.LAYER: return layer;
        case CustomIconName.LAYERS: return layers;
        case CustomIconName.DATABASE: return database;
        case CustomIconName.TABLE: return table;
        case CustomIconName.EYE_OPEN: return eyeOpen;
        case CustomIconName.ERASER: return eraser;
        case CustomIconName.PERSON: return person;
        case CustomIconName.PEOPLE: return people;
        case CustomIconName.KEY: return key;
        case CustomIconName.DASHBOARD: return dashboard;
        case CustomIconName.STACKED_CHART: return stackedChart;
        case CustomIconName.TICK_ERASER: return tickEraser;
        case CustomIconName.ARROW_RIGHT: return arrowRight;
        case CustomIconName.ARROW_LEFT: return arrowLeft;
        case CustomIconName.EXCHANGE: return exchange;
        case CustomIconName.WRITE: return write;
        case CustomIconName.CIRCLE_ARROW_RIGHT: return circleArrowRight;
        case CustomIconName.CHEVRON_DOWN: return chevronDown;
        case CustomIconName.FLOW_ARROW: return flowArrow;
        case CustomIconName.PROCESS: return process;
        case CustomIconName.LOGS: return logs;
        default: return dashboard
    }
}
