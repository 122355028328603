import * as React from "react";

export default function useDebounce(value: any, delay: number = 500) {
  const [debouncedValue, setDebouncedValue] = React.useState<any>(value);

  React.useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
