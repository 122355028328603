import axios from 'axios';
import { showToaster } from 'actions/ActionCreators';
import { MDMStore } from '../store/MDMStore';
import { Intent } from '../types';
import { mdmErrorToText } from 'helpers';
import { Pagination } from 'rp-mdm-common';
// import { WebServerConfig } from '../../WebServerConfig';
// import { config } from 'process';
// const webServerConfig = WebServerConfig.getInstance();
// webServerConfig;
// import { getLogger } from 'log4js';
// const logger = getLogger('MDM Server');

/**
 * Important things going on here.
 * assume we are (mostly) given post_data which has a payload subdocument
 * add pagination if needed
 * The server expects contentType of 'application/json' for it to interpret the body as JSON
 * @param url
 * @param post_data
 * @param token - authentication token which we stuff into the header
 * @param propertyName = A property inside the resulting payload you want to return, to avoid returning the whole response payload.
 * @param showFailureMessage = Property to show the error message toast in the UI when call fails.
 */

// (url: string, postData: any, propertyName?: string, pagination?: Pagination, showFailureMessage = false)
export const axiosPost = async (url: string, postData: any, propertyName?: string, pagination?: Pagination, showFailureMessage = true, withCredentials = false) => {
    let postBody = postData;
    if (typeof postData !== 'object') {
        postBody = JSON.parse(postData);
    }

    if (!postBody.pagination) {
        postBody.pagination = pagination || { start: 0, count: 100 };
    }

    const options = {
        headers: {
            'Content-Type': 'application/json',

            // other thing
            'Access-Control-Allow-Origin': '*'
        }
    };

    // someday change over to bearer tokens instead of in the message. It would be better.
    // if(token) {
    //     options['authorization'] = `Bearer ${token}`;
    // }


    // if (withCredentials) {
    //     options['withCredentials'] = true;
    // }

    //
    // I could neither include the config here or log4js so I went primitive and used environment variables
    // and console logging. Someone who understands can fix this.

    // if (config.trace_messages) {
    if (process.env.TRACE_MESSAGE) {
        const docRequest = {
            "uri": url,
            "posted_data": postBody || 'NO DATA',
            "dt_out": new Date()
        };
        // logger.debug(("Request ############################################################# ");
        console.log("Request ############################################################# ");
        console.log(JSON.stringify(docRequest, null, 2));
    }

    return axios.post(url, postBody, options)
        .then((response) => {
            const { config, data, headers, request, status } = response;

            if (!data.success) {
                const requestError = new Error(mdmErrorToText(data.error));
                requestError.stack = data.error.stack;
                throw (requestError);
            }

            if (headers['set-cookie']) {
                // @ts-ignore
                axios.defaults.headers.cookie = headers['set-cookie'];
            }

            //
            if (process.env.TRACE_MESSAGE) {
                console.log("Response #############################################################");
                console.log(JSON.stringify(response.data, null, 2));
            }

            // DATA ERROR
            if (propertyName)
                return data.payload[propertyName];
            else
                return data?.payload || data;
        })
        .catch((error) => {
            if (process.env.TRACE_MESSAGE) {
                console.log("Error Response #############################################################");
                console.log(JSON.stringify(error, null, 2));
            }
            let errorMessage = error.message || error;
            const pathTokens = url.split('/');
            const pathPortion = pathTokens.length >= 2 ?
                `${pathTokens[pathTokens.length - 1]}/${pathTokens[pathTokens.length - 2]}` :
                url;

            if (showFailureMessage) {
                MDMStore.dispatch<any>(showToaster(Intent.DANGER, `Call to ${pathPortion} failed. ${errorMessage}.`));
            }

            if (error.response) {
                // Some custom messages for common codes:
                if (error.response.status === 404) {
                    errorMessage = 'Requested page not found. [404]';
                } else if (error.response.status === 500) {
                    errorMessage = 'Internal Server Error [500].';
                }
            }

            throw new Error(errorMessage);
        });
};

export function axiosGet(url: string) {

    const options = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios.get(url, options)
        .then(res => res?.data?.payload || res?.data || res)
        .catch(error => {
            const errorMessage = error.message || error;
            const pathTokens = url.split('/');
            const pathPortion = pathTokens.length >= 2 ?
                `${pathTokens[pathTokens.length - 1]}/${pathTokens[pathTokens.length - 2]}` :
                url;

            MDMStore.dispatch<any>(showToaster(Intent.DANGER, `GET request to ${pathPortion} failed. ${errorMessage}.`));
            throw new Error(errorMessage || 'Post response had unknown format');
        });
}
