export const emptyTaskActionResult = {
    database: null,
    form: null,
    state: null,
    variables: {},
    record: null,
    schema: null,
    records: null,
    workitem_id: null,
    node_id: null,
    old_record: null,
    accepted_match_groups: null,
    proposed_match_groups: null,
    continue: false
};