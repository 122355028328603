import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useIdleTimerContext } from 'react-idle-timer';
import { Dialog, Button, Classes, Intent, FileInput, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { IconNames } from '@blueprintjs/icons';
import { isEqual, reduce } from 'lodash'
import * as LineNavigator from 'line-navigator'
import { RootState } from 'store';
import { Schema, Table, Intent as MDMIntent } from 'types';
import { commitChangeset, createChangeset, useGetTables, useImportTableMutation, useUpsertRecords } from '../../api';
import { showToaster } from '../../actions';
import '../TablesMain/TableImportDialog.scss'

export interface TableImportProps {
    isOpen: boolean;
    closeDialog: () => void;
    showToaster: showToaster;
    onSave: (name: string, description: string, jsonContents: object) => void;
    recordsToUploadCount: number;
    uploadedRecords: number;
}
export interface TableImportState {
    name: string;
    description: string;
    uploadStringContents: string;
    fileName: string;
    warningText: string;
}

const ImportDataDialog = ({ closeDialog, onSave, isOpen, isLoading, recordsToUploadCount, uploadedRecords }) => {
    const dbName = useSelector<RootState>(state => state.database.selected)
    const changeset: any = useSelector<RootState>(state => state.changeset)
    const username = useSelector<RootState>(state => {
        const model = state?.login?.data?.model || {};
        const fullName = model.fullName || 'Unknown';

        return fullName;
    })

    const { activate } = useIdleTimerContext();

    const dispatch = useDispatch();
    const [schema, setSchema] = React.useState<any>(null);
    const [isImporting, setIsImporting] = React.useState<boolean>(false);
    const [fileNavigator, setFileNavigator] = React.useState<any>(null)
    const [fileName, setFileName] = React.useState<string>('');
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [importProg, setImportProg] = React.useState<number>(0);
    const [tables, setTables] = React.useState<Table[]>([])
    const [shouldCreateTable, setShouldCreateTable] = React.useState<boolean>(false);
    const rq = useQueryClient();
    const getTables = useGetTables('import-data-table-list', {
        dbName
    }, {
        onSuccess: (data) => {
            setTables(data);

            if (selectedTable) {

                const newSelected = data.find(t => t.table === selectedTable.table);

                if (newSelected) {
                    setSelectedTable(newSelected);

                    return;
                }

                setSelectedTable(null);
            }
        },
    });
    const { mutateAsync: createTable, isLoading: isCreatingTable } = useImportTableMutation({
        onSuccess: () => {
            rq.invalidateQueries(['import-data-table-list', { dbName }])
            setShouldCreateTable(false);
        }
    })
    const { mutateAsync: importData } = useUpsertRecords()

    const [selectedTable, setSelectedTable] = React.useState<Table>(null);

    const removeEmptyOrNull = (obj) => {
        // remove internal_field prop for each prop
        Object.keys(obj).forEach(k => {
            if (obj[k] && typeof obj[k] === 'object') {
                delete obj[k].internal_field;
            }
        })

        Object.keys(obj).forEach(k => (obj[k] && typeof obj[k] === 'object') && removeEmptyOrNull(obj[k]) ||
            (!obj[k] && obj[k] !== undefined) && delete obj[k]
        );
        return obj;
    };

    const handleFileUpload = (file: File) => {
        setFileName(file.name)
        const navigator = new LineNavigator(file, { chunkSize: 1024 * 300 });

        setFileNavigator(navigator)

        navigator.readLines(0, 1, (err, index, line, isEof, progress) => {
            if (err) {
                setErrorMessage('There was an error reading the file. Please try again.')
                return;
            }


            setSchema(JSON.parse(line))

        });
    }

    const closeAndClearDialog = () => {
        setFileName('')
        setSchema(null)
        setErrorMessage('')
        setImportProg(0)
        setIsImporting(false)
        closeDialog()
    }

    const renderErrors = () => {
        return (
            <span
                key="warn-not-json"
                className="bp5-labe warn-label"
            >
                {errorMessage}
            </span>
        )
    };

    const getButtonLabel = (): string => {
        if (!selectedTable) return 'Select Table';
        if (shouldCreateTable && selectedTable) return `Create "${selectedTable.table}" Table`;

        return `"${selectedTable.table}" Table`;
    }

    const onCreateTable = async () => {
        try {
            await createTable({
                dbName: dbName as string,
                tableName: selectedTable.table,
                jsonContents: schema as Schema,
                description: `Create ${selectedTable.table} table`
            })
        }
        catch (e) {
        }
    }

    const generateDialogFooter = (): React.ReactNode => {

        if (shouldCreateTable) {
            return (
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        id='pr-modal-save'
                        icon={IconNames.FLOPPY_DISK}
                        intent={Intent.PRIMARY}
                        className="user-save-button"
                        onClick={onCreateTable}
                        loading={isCreatingTable}
                        disabled={!!errorMessage.length || isCreatingTable || isLoading}
                        value="Save"
                        text="Create Table"
                    />
                    <Button
                        id='pr-modal-close'
                        icon={IconNames.CROSS}
                        onClick={() => {
                            setShouldCreateTable(false)
                            setSelectedTable(null)
                        }}
                        intent={Intent.DANGER}
                        loading={isCreatingTable}
                        disabled={isCreatingTable || isLoading}
                        value="Cancel"
                        text="Cancel"
                    />
                </div>
            );
        }

        return (
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                    id='pr-modal-save'
                    icon={IconNames.FLOPPY_DISK}
                    className="user-save-button"
                    onClick={onImport}
                    intent={Intent.PRIMARY}
                    disabled={!selectedTable || !!errorMessage.length || isImporting || isLoading}
                    value="Save"
                    text="Import"
                />
                <Button
                    id='pr-modal-close'
                    icon={IconNames.CROSS}
                    onClick={closeAndClearDialog}
                    intent={Intent.DANGER}
                    disabled={isImporting || isLoading}
                    value="Cancel"
                    text="Cancel"
                />
            </div>
        );
    }

    const generateDialogBody = (): React.ReactNode => {

        if (shouldCreateTable) {
            return (
                <div className="flex-column workflow-detail-form">
                    <span className={`bp5-label ${Classes.TEXT_MUTED}`}>This will create a table named {selectedTable?.table}</span>
                    <span className={`bp5-label ${Classes.TEXT_MUTED}`}>Do you want to proceeed?</span>
                </div>
            )

        }

        return (
            <div className="flex-column workflow-detail-form">
                <span className={`bp5-label ${Classes.TEXT_MUTED}`}>Select JSON File to import</span>
                <FileInput
                    buttonText="Browse"
                    text={fileName ?? 'Select File...'}
                    inputProps={{ accept: ".json,.txt" }}
                    onInputChange={(e: any) => handleFileUpload(e.target.files[0])}
                />
                <span className={`bp5-label`}>Select Table to import to</span>
                <Select
                    value={selectedTable}
                    items={tables}
                    itemRenderer={(item, { handleClick }) => {
                        return (
                            <MenuItem
                                key={item.table}
                                text={item.table}
                                onClick={handleClick}
                            />
                        )
                    }}
                    onItemSelect={(table) => {
                        setSelectedTable(table)
                        if (!table.schema) {
                            setShouldCreateTable(true)
                        }
                        if (table.schema) setShouldCreateTable(false)
                    }}
                    noResults={<MenuItem disabled={true} text="No results." />}
                    createNewItemPosition='first'
                    createNewItemFromQuery={(query) => {
                        return { database: dbName, table: query } as Table;
                    }}
                    createNewItemRenderer={(query, active, handleClick) => {
                        return (
                            <MenuItem
                                active={active}
                                icon="add"
                                key="add"
                                onClick={handleClick}
                                text={`Create "${query}"`}
                            />
                        )
                    }}

                >
                    <Button>{getButtonLabel()}</Button>
                </Select>

                {isImporting && <span className="bp5-label">Imported <i>{importProg}%</i>...</span>}
                {renderErrors()}
            </div>
        )
    }

    const onImport = async () => {
        let newChangeSet = null;
        if (!selectedTable) {
            setErrorMessage('Please select a table to import to')
            return;
        }
        setIsImporting(true)
        const fileSchemaFiltered = removeEmptyOrNull(schema.fields);
        const schemaFiltered = removeEmptyOrNull(selectedTable?.schema.fields);

        const diff = reduce(
            schemaFiltered,
            function (result, value, key) {
                return isEqual(value, fileSchemaFiltered[key]) ?
                    result : result.concat(key);
            }
            , []);

        if (diff.length) {
            setErrorMessage(`The schema of the file you are trying to import does not match the schema of the table. Please check the following fields: ${diff.join(', ')}`)

            return;
        }

        const processLines = function (err, index, lines, isEof, progress) {
            if (err) {
                setErrorMessage('There was an error reading the file. Please try again.')
                setIsImporting(false);
                return;
            }

            // "activates" the timer to prevent the user from timing out
            activate()

            importData({
                databaseName: dbName as string,
                changesetId: newChangeSet?.changeset,
                tableName: selectedTable.table,
                records: lines.filter((line: string) => line.length).map((line: string) => JSON.parse(line)) as any[]
            }).then(() => {
                setImportProg(progress)

                if (isEof) {
                    setIsImporting(false);
                    closeAndClearDialog();
                    commitChangeset(dbName as string, newChangeSet).then((res) => {
                        rq.invalidateQueries(['table-list']);
                        dispatch(showToaster(MDMIntent.SUCCESS, 'Imported data successfully'));
                    })
                } else {
                    fileNavigator.readSomeLines(index + lines.length, processLines);
                }
            });
        }


        if (changeset.changeset === 'current') {
            newChangeSet = await createChangeset(dbName as string, `Importing data to ${selectedTable.table}`, username as string)
        }

        fileNavigator.readSomeLines(2, processLines)
    }

    return (
        <Dialog
            title="Import Data Dialog"
            icon={IconNames.IMPORT}
            className="table-import-modal"
            onClose={closeAndClearDialog}
            isOpen={isOpen}
            canOutsideClickClose={false}
            style={{ maxWidth: 300 }}
        >
            <div className={Classes.DIALOG_BODY}>
                {generateDialogBody()}
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                {generateDialogFooter()}
            </div>
        </Dialog>
    )
}

export default ImportDataDialog