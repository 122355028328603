import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { displayMessageBox, showToaster } from 'actions';
import { clearTaskResult } from 'store/workflow/actions';
import { getCurrentWorkflow } from 'store/workflow/selectors';
import { UserSettings } from 'store/user/types';
import { setCanEditWorkitems } from 'store/auth/actions';
import {
    WorkflowAssignedContainer,
    WorkflowAvailableContainer,
    WorkflowStartableContainer,
    WorkflowSearch,
    WorkflowEditor,
    ProcessEditor,
    WorkitemTaskContainer,
} from 'components/Workflow';
import { fetchAvailableWorkflows, fetchAssignedWorkflows, continueTask, setAssignedWorkflows, setAvailableWorkflows, setContinueTask } from 'store/workflow/actions';
import { PermApi, WorkflowApi } from '../../api';
import { setPeriodicData } from '../../actions'
import { Intent, Workflow } from 'types';

export interface WorkflowContainerProps {
    available: Workflow[];
    assigned: Workflow[];
    currentWorkflow: Workflow;
    dbName: string;
    history: any;
    settings: UserSettings;
    username: string;
    showToaster?: showToaster;
    lastUpdated: number | null;
    displayMessageBox: (options) => void;
    continueTask: (database: string, workitemId: number, nodeId: string) => void;
    clearTaskResult: () => void;
    fetchAvailableWorkflows: (dbName: string, username: string) => void;
    fetchAssignedWorkflows: (dbName: string, username: string) => void;
    setCanEditWorkitems: (canEdit: boolean) => void;

    setPeriodicData?: setPeriodicData;
    setAssignedWorkflows: (workflows: Workflow[]) => void;
    setAvailableWorkflows: (workflows: Workflow[]) => void;
    setContinueTask: (taskResult: any) => void;
}

export interface WorkflowContainerState {
    canEditWorkitems: boolean;
}

export class WorkflowContainerBase extends React.PureComponent<WorkflowContainerProps, WorkflowContainerState> {

    constructor(props) {
        super(props);
        this.state = {
            canEditWorkitems: false
        }
    }

    setModifyWorkitemPermission = async () => {
        const { dbName, setCanEditWorkitems } = this.props;
        try {
            const permissionResponse = await PermApi.Validate({ type: "database", entity: dbName, permission: "modify_workflow" });
            setCanEditWorkitems(!!permissionResponse?.allow)
        } catch (err) {
            console.error(err);
        }
    }

    continueNext = async (assignedWorkflows: Workflow[]) => {
        const { dbName, setContinueTask, showToaster } = this.props;
        for (const assigned of assignedWorkflows) {
            try {
                const taskResult = await WorkflowApi.continueTask(dbName, assigned.workitem_id, assigned.node_id);
                showToaster(Intent.INFO, `Next assigned task (${assigned.workitem_id}) started automatically.`);
                return setContinueTask(taskResult);
            } catch (err) {
                console.error(`Workitem ${assigned.workitem_id} was not claimable, attempting to claim next`);
                console.error(err);
            }
        }
    }

    render() {
        return (
            <div style={{ height: "100%" }}>
                <Route exact path='/' component={WorkflowAssignedContainer} />
                <Route exact path='/wf/assigned' component={WorkflowAssignedContainer} />
                <Route exact path='/wf/available' component={WorkflowAvailableContainer} />
                <Route exact path='/wf/search' component={WorkflowSearch} />
                <Route exact path='/wf/workfloweditor' component={WorkflowEditor} />
                <Route exact path='/wf/processeditor' component={ProcessEditor} />
                <Route exact path='/wf/start' component={WorkflowStartableContainer} />
                <Route exact path='/wf/workitemTask' component={WorkitemTaskContainer} />
            </div>
        );
    }
}
export const WorkflowContainer: any = connect(
    (state: any) => ({
        currentWorkflow: getCurrentWorkflow(state),
        lastUpdated: state.workflow.lastUpdated,
        username: state.username,
        dbName: state.database.selected,
        settings: state.user.settings,
        available: state.workflow.available,
        assigned: state.workflow.assigned,
    }), {
    continueTask,
    setContinueTask,
    clearTaskResult,
    displayMessageBox,
    fetchAvailableWorkflows,
    fetchAssignedWorkflows,
    showToaster,
    setPeriodicData,
    setAssignedWorkflows,
    setAvailableWorkflows,
    setCanEditWorkitems
})(WorkflowContainerBase as any);