import * as React from 'react';
import { IPermNode } from './IPermNode';
import { PermDetails } from './PermDetails';

export interface PermDetailProps {
    groups: any[];
    node: IPermNode;
    systemGroupName: string;
    refreshFn: (node: IPermNode) => void;
}

export const PermDetail: React.FC<PermDetailProps> = ({ groups, node, refreshFn, systemGroupName }) => {
    return node && groups ?
        <PermDetails
            systemGroupName={systemGroupName}
            node={node}
            groups={groups}
            header={node.header()}
            refreshFn={refreshFn} />
        :
        <div><h4>Please Select An Element</h4></div>;
};