import { ChangesetUrl } from '../proxies/ajax/ChangesetUrl';
import { displayError } from '../api/displayError';
import { axiosPost } from 'helpers';
import { Changeset, ChangesetFilter, ChangesetStatus, ErrorHandler, OperationSummary } from 'types';
import { Pagination } from 'rp-mdm-common';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

/**
 * commit a pending changeset
 *
 */
export const commitChangeset = async (dbName: string, changeset: Changeset) => {
    let res: any; //empty response is success
    try {
        const status = changeset.status;
        // You can't discard a committed (or even committing) changeset
        // Not sure about conflicted but right now no.
        if (status !== ChangesetStatus.PENDING)
            throw new Error(`A changeset in the ${status} state cannot be committed`);

        const data = {
            database: dbName,
            changeset: changeset.changeset
        };
        // Error will throw from axiosPost, otherwise success
        res = await axiosPost(ChangesetUrl.commit, { payload: data }, null, null, false);
    } catch (err) {
        displayError('Commit Changeset Failed', `Failed to commit changeset ${changeset.changeset}`, err);
    }
    return res;
};

/**
 * create a changeset
 *  returns the changesetId
 */
export const createChangeset = async (dbName: string, description: string, userName: string): Promise<Changeset> => {
    let changeset: Changeset;
    try {
        const data = {
            category: 'data',
            database: dbName,
            description: description,
            username: userName,
        };
        // Error will throw from axiosPost, otherwise success
        changeset = await axiosPost(ChangesetUrl.create, { payload: data }, null, null, false);
    } catch (err) {
        displayError('Create Changeset Failed', `Failed to create changeset`, err);
    }
    return changeset;
};

/**
 * discard a pending changeset
 *
 */
export const discardChangeset = async (dbName: string, changeset: Changeset) => {
    let res: any; // empty response is success
    try {
        const status = changeset.status;

        // You can't discard a committed (or even committing) changeset
        // Not sure about conflicted but right now no.
        if (status !== ChangesetStatus.PENDING)
            throw new Error(`A changeset in the ${status} state cannot be discarded`);

        const data = {
            database: dbName,
            changeset: changeset.changeset
        };
        // Error will throw from axiosPost, otherwise success
        res = await axiosPost(ChangesetUrl.discard, { payload: data }, null, null, false);
    } catch (err) {
        displayError('Discard Changset Failed', `Failed to discard changeset ${changeset.changeset}`, err);
    }
    return res;
};

/**
 * query changesets
 *
 */
export const queryChangesets = async (dbName: string, filter: ChangesetFilter, errorHandler?: ErrorHandler, pagination?: Pagination): Promise<Changeset[]> => {
    let changesets: Changeset[] = [];
    try {

        const data = {
            database: dbName,
            filter: filter
        };
        // Error will throw from axiosPost, otherwise success
        const res = await axiosPost(ChangesetUrl.query, { payload: data }, null, pagination);
        changesets = res.changesets;
    } catch (err) {
        displayError('Query Changsets Failed', `Failed to query changesets with filter ${JSON.stringify(filter)}`, err);
    }
    return changesets;
};

export const useQueryChangesets = (key, params, options = {}) => {
    return useQuery([key, params], async ({ queryKey }) => {
        const [, queryParams] = queryKey;
        const { dbName, filter, pagination } = queryParams;
        const res = await queryChangesets(dbName, filter, null, pagination);

        return res;
    }, options);
}

export const getLatestChangeset = async (dbName: string, date: Date, errorHandler?: ErrorHandler): Promise<Changeset> => {
    let changeset: Changeset;
    try {

        const data = {
            database: dbName,
            timestamp: date.getTime()
        };
        // Error will throw from axiosPost, otherwise success
        changeset = await axiosPost(ChangesetUrl.getLatest, { payload: data }, null, null, false);
    } catch (err) {
        displayError('Historic View Not Found', `Unable to find historic view ${date ? date.toISOString() : ''}`);
    }
    return changeset;
};

export const getOperationsSummary = async (dbName: string, changeset: number, errorHandler?: ErrorHandler): Promise<OperationSummary[]> => {
    let summary: OperationSummary[];
    try {
        const data = {
            database: dbName,
            changeset: changeset
        };
        // Error will throw from axiosPost, otherwise success
        const res = await axiosPost(ChangesetUrl.summarize, { payload: data }, null, null, false);
        summary = res.operations;
    } catch (err) {
        displayError('Changeset Summary Not Found', `Unable to retrieve summary for changeset ${changeset}`, err);
    }
    return summary;
};


export const useGetOperationsSummary = (
    key: string,
    params: { dbName: string; changeset: number },
    options = {},
) => {
    return useQuery<OperationSummary[]>([key, params], async ({ queryKey }) => {
        const [, queryParams] = queryKey;
        const { dbName, changeset } = queryParams as any;
        const res = await getOperationsSummary(dbName, changeset);

        return res;
    }, options);
}