/**
 * Created by nbamford on 1/29/2017.
 */
import { connect } from 'react-redux';
import * as React from 'react';

import { approveTask, rejectTask, discardAssignedTask as discardTask } from '../../../store/workflow/actions';
import { displayError, WorkflowApi } from 'api';
import { getReviewWorkflow } from '../../../store/workflow/selectors';
import { History, ReviewWorkflow, Workflow } from 'types';
import { WorkflowNoteReviewForm } from './WorkflowNoteReviewForm';

export interface WorkflowNoteReviewContainerPropTypes {
    history: any;
    workflow: ReviewWorkflow;
    approveTask: (workflow: Workflow, button:HTMLButtonElement, recordId: string, data: object) => void;
    rejectTask: (workflow: Workflow, button:HTMLButtonElement, data:object) => void;
    discardTask: (workflow: Workflow, button:HTMLButtonElement, note:string) => void;
}
export interface WorkflowNoteReviewContainerState {
    workitemHistory: History[];
}

export class WorkflowNoteReviewContainerBase extends React.Component<WorkflowNoteReviewContainerPropTypes,
    WorkflowNoteReviewContainerState> {

    constructor(props) {
        super(props);
        this.state= { workitemHistory: []};
    }

    componentDidMount() {
        WorkflowApi.readWorkitemState(this.props.workflow.workflow.databaseName, this.props.workflow.workflow.workitem_id)
        .then(history => this.setState({workitemHistory: history}))
        .catch(error  => displayError(
            'Read Task State Failed',
            `Failed to read state for Workitem ${this.props.workflow.workflow.workitem_id}`,
            error
        ));
    }

    approveTask = (button:HTMLButtonElement, note?: string) => {
        const workflow = this.props.workflow.workflow;
        const data = {};
        if (note)
            data['$notes'] = note;
        this.props.approveTask(workflow, button, null, data);
    }
    rejectTask = (button:HTMLButtonElement, note?: string) => {
        const workflow = this.props.workflow.workflow;
        const data = {};
        if (note)
            data['$notes'] = note;
        this.props.rejectTask(workflow, button, data);
    }
    discardTask = (button:HTMLButtonElement, note: string) => {
        //const data = {};
        /* if (note)
            data['$notes'] = note; */
        const workflow = this.props.workflow.workflow;
        this.props.discardTask(workflow, button, note);
    }
    render() {
        const {workflow} = this.props;
        return (
            <div>
                <WorkflowNoteReviewForm workflow={workflow.workflow} oldRecord={workflow.oldRecord} newRecord={workflow.record} schema={workflow.workflow.schema}
                    history={this.state.workitemHistory} approveTask={this.approveTask} discardTask={this.discardTask} rejectTask={this.rejectTask} />
            </div>
        );
    }
}
//asNotes={true}notes={this.props.workitemHistory} getButtons={this.getButtons}
export const WorkflowNoteReviewContainer = connect(
    (state: any) => ({ workflow: getReviewWorkflow(state) }),
    { approveTask, rejectTask, discardTask }
)(WorkflowNoteReviewContainerBase as any);

