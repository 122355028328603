import React, { FC } from 'react';
import { Classes, Dialog } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import ReactTable from 'components/Shared/ReactTable/CustomReactTable';
import { formatTimeHHMM } from '../../utils';
import { History } from '../../types';
import { TooltipCell } from 'components/Shared/ReactTable/TooltipCell';
import './RecordDialog.scss';
import { createColumnHelper } from '@tanstack/react-table';

export interface RecordActivityDialogProps {
    title: string;
    history: History[];
    isOpen: boolean;
    onClose: (e: any) => void;
}
export interface RecordActivityDialogState {
    showConfirmReminder: boolean;
}

export interface HistoryEntryState {
    isOpen: boolean;
}

const RecordActivityDialog: FC<RecordActivityDialogProps> = ({
    title,
    history,
    isOpen,
    onClose,
}) => {
    const colHelper = createColumnHelper<History>();

    const columns = [
        colHelper.accessor('timestamp', {
            header: 'Timestamp',
            enableResizing: true,
            cell: ({ cell }) => <div>{formatTimeHHMM(cell.getValue())}</div>
        }),
        colHelper.accessor(row => row.record.message, {
            header: 'Message',
            enableResizing: true,
            cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
        }),
        colHelper.accessor(row => row.record.username, {
            header: 'Username',
            enableResizing: true,
            cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
        }),
        colHelper.accessor(row => row.record.action, {
            header: 'Action',
            enableResizing: true,
            cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
        }),
        colHelper.accessor(row => row.record.note, {
            header: 'Note',
            enableResizing: true,
            cell: ({ cell }) => <TooltipCell value={cell.getValue()} />
        })
    ];
    
    return (
        <Dialog
            icon={IconNames.HISTORY}
            className="record-history-dialog large-dialog"
            isOpen={isOpen}
            onClose={onClose}
            title={title}
        >
            <div className={`${Classes.DIALOG_BODY} record-history-dialog-inner`}>
                <ReactTable
                    data={history}
                    columns={columns}
                    showPageSizeOptions={false}
                    showPaginationTop={false}
                />
            </div>
        </Dialog>
    );
}

export default RecordActivityDialog;