import * as React from 'react';
import { ITreeNode, Tooltip } from "@blueprintjs/core";

import { displayMessageBox } from '../../actions/ActionCreators';
import { v4 as uuidv4 } from 'uuid';
import { IPermNode } from './IPermNode';
import { MDMStore } from 'store';
import { mdmErrorToText } from "helpers";
import { WorkflowDef } from 'types';
import { PermWorkflowNode } from './PermWorkflowNode';
import { WorkflowApi } from '../../api';


export class PermProcessNode implements IPermNode {
    id:string;
    name: JSX.Element|string;
    type:string;
    isOpen: false;
    canBeSelected: boolean;
    isLeaf: boolean;
    openIcon: string;
    closedIcon: string;
    children: IPermNode[];
    parent: IPermNode;
    process_id: number;
    description: string;

    constructor(name: JSX.Element|string, process_id:number, description:string, parent:IPermNode) {
        this.id = uuidv4();
        this.name = name;
        this.type = 'process';
        this.isOpen = false;
        this.canBeSelected = true;
        this.isLeaf = false;
        this.openIcon = 'layer';
        this.closedIcon = 'layer'; // unused
        this.children = [];
        this.parent = parent; // database
        this.process_id = process_id;
        this.description = description;
    }

    async getChildrenFn() {
        const self = this;

        try {
            // workflows are by database and processId
            const result:WorkflowDef[] = await WorkflowApi.queryWorkflows(this.parent.fullNameFn(), this.process_id);

            const workflows = result.filter( workflow => {
                return workflow.process_id === self.process_id;
            }).map( workflow => {
                let label:string|JSX.Element = `${workflow.workflow_id}: ${workflow.description}`;
                if(workflow.description.length > 24) {
                    label = (<Tooltip content={workflow.description}>
                        {`${workflow.workflow_id}: ${workflow.description.substring(0, 24)}`}
                        </Tooltip>);
                }
                return new PermWorkflowNode(
                    label,
                    workflow.workflow_id,
                    workflow.description,
                    this
                );
            });
            self.children =  workflows.sort((a, b) => a.workflow_id - b.workflow_id);
        }
        catch(error) {
            error = error.error || error;
                MDMStore.dispatch<any>(displayMessageBox({
                    title: 'List Workflows Failed',
                    message: `Failed to list workflows: ${ mdmErrorToText(error) }`,
                    stack: error && error.stack,
                }));
        }
        return true;
    }

    // The name of a process is its process_id
    fullNameFn() {
        return `${this.parent.fullNameFn()}.${String(this.process_id)}`;
        //return this.myName();
    }

    myName() {
        return this.process_id.toString();
    }

    getBPNode() {
        const childrenBP = this.children.map( child => child.getBPNode() );
        return {
            id: this.id,
            hasCaret: true,
            isExpanded: this.isOpen,
            label: this.name,
            iconName: this.openIcon,
            childNodes: childrenBP,
        } as ITreeNode;
    }

    header() {
        return `Process: ${this.description}`;
    }
}
